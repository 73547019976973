app.controller('bomboraConfigCtrl', ['$scope', '$http', '_', '$state', 'bomboraConfigSrvc', 'internalConfigSrvc', function ($scope, $http, _, $state, bomboraConfigSrvc, internalConfigSrvc) {

    $scope.saveChanges = function() {
        bomboraConfigSrvc.setTopicLimit($scope.data.bombora);
        bomboraConfigSrvc.setCanConfigureThreshold($scope.data.bombora).then(function() {
            $scope.data.saved = "Saved!";
        });
    };

    $scope.orgChanged = function () {
        $state.current.data.loading = true;
        $scope.data.saved = "";
        $scope.data.bombora = {orgUuid: $scope.configs.orgUuid};
        bomboraConfigSrvc.getConfiguredLimitThreshold($scope.data.bombora).then(function (response) {
            _.merge($scope.data.bombora, response.data);
            $state.current.data.loading = false;
        });
    };

    $scope.toggleActiveOrgs = function () {
        $scope.configs.orgs = $scope.configs.activeOrgsOnly ? $scope.configs.orgsActive : $scope.configs.orgsAll;
    };

    (function init() {
        $scope.configs = {
            orgId: 0,
            orgUuid: "0",
            activeOrgsOnly: true
        };
        $scope.data.saved = "";

        internalConfigSrvc.getInternalConfig().success(function (data) {
            var active = [];

            data.orgs.forEach(function (item) {
                if (item.active) {
                    active.push(item);
                }
            });
            $scope.configs.orgs = [{ id: 0, uuid: "0", name: "--Select an org--" }].concat(active);
            $scope.configs.orgsActive = [{ id: 0, uuid: "0", name: "--Select an org--" }].concat(active);
            $scope.configs.orgsAll = [{ id: 0, uuid: "0", name: "--Select an org--" }].concat(data.orgs);
        });
    })();
}]);
