import * as COMPONENT_HTML from './component.html';
import './component.less';
import {IDirective, IScope,} from 'angular';

class NoteCtrl {
    static $inject: Array<string> = ['$scope'];

    private show: boolean;

    public constructor(private $scope: IScope) {
        this.initialize();
        return;
    }

    private initialize(): void {
        this.$scope.$ctrl.show = true;
    }

    private hide(): void {
        this.$scope.$ctrl.label = '';
        this.$scope.$ctrl.show = false;
    }

    private is(level: string): boolean {
        return this.$scope.$ctrl.level === level;
    }
}

export class Note implements IDirective {
    public restrict = 'E';
    public controller: any = NoteCtrl;
    public controllerAs = '$ctrl';
    public bindToController = true;
    public template: any = COMPONENT_HTML;
    public scope: IScope = {
        label: '=',
        level: '='
    };

    public constructor() {
        return;
    }

    public static Factory(): IDirective {
        return new Note();
    }

    public link = (scope: IScope) => {
        scope.$watch('$ctrl.label', (value) => {
            scope.$ctrl.show = !!value;
        });
    };
}
