import * as COMPONENT_HTML from './component.html';
import {IDirective, IScope} from 'angular';

class SettingsButtonCtrl {
    static $inject: Array<string> = ['$scope'];

    public constructor(private $scope: IScope) {
        return;
    }

    public click(event: any): void {
        this.$scope.$ctrl.changeFn()(event);
    }
}

export class SettingsButton implements IDirective {
    public restrict = 'E';
    public controller: any = SettingsButtonCtrl;
    public controllerAs = '$ctrl';
    public bindToController = true;
    public template: any = COMPONENT_HTML;
    public scope: IScope = {
        label: '=',
        changeFn: '&'
    };

    public constructor() {
        return;
    }

    public static Factory(): IDirective {
        return new SettingsButton();
    }
}

