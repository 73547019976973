app.controller('stagesSnapshotWidgetCtrl', ['$scope', '$state', '$rootScope', 'utilities', '$templateCache', '$compile', '$timeout', 'api', 'revenueWaterfall', 'widgets', 'personaDefaults', '_', function ($scope, $state, $rootScope, utilities, $templateCache, $compile, $timeout, api, revenueWaterfall, widgets, personaDefaults, _) {
    var $tile = $scope.$tile,
        stagesCall;

    this.getData = function () {
        if (stagesCall) { stagesCall.abort(); }

        $scope.data.tScope = $scope.$new();
        $scope.data.tooltip = $compile($templateCache.get('chartTooltip.html'))($scope.data.tScope);
        $scope.select_options = utilities.formatCohorts($rootScope.rawCohorts, ['all', 'custom', 'ago', 'year', 'quarter', 'toDate', 'lastFull']);
        $scope.chart = revenueWaterfall.getChart($scope);
        $scope.widget.query = _.defaults($scope.widget.query, personaDefaults.getQueryDefinitions($scope.widget).query);
        $scope.widget.data = _.defaults($scope.widget.data, {});

        if (!$scope.widget.query) {
            $tile.endLoading();
            return;
        }

        (stagesCall = api.getter({
            url: 'flat_stage_progression',
            params: angular.copy($scope.widget.query),
            paramsNotToUpdate: 'all',
            skipFilters: true,
        })).then(function (data) {
            $tile.loading(30);
            revenueWaterfall.formatData(data, $scope);
            $scope.widget.data.lastQuery = angular.copy($scope.widget.query);
        }, function (data) {
            if (!data.aborted) {
                $tile.failedLoading();
            }
        }).finally(function () {
            $tile.loading(100);
            $tile.endLoading();
            $scope.$emit("widgetLoadingDone");
        });
    };

    (function init(ctrl) {
        $tile.startLoading();
        $tile.loading(10);

        $scope.utils = utilities;
        $scope.data = {
            stages: [],
            largest: 0
        };
        $scope.data.tScope = $scope.$new();
        $scope.data.tooltip = $compile($templateCache.get('chartTooltip.html'))($scope.data.tScope);
        $scope.select_options = utilities.formatCohorts($rootScope.rawCohorts, ['all', 'custom', 'ago', 'year', 'quarter', 'toDate', 'lastFull']);
        $scope.chart = revenueWaterfall.getChart($scope);
        const params = _.defaults($scope.widget.query, personaDefaults.getQueryDefinitions($scope.widget).query);
        $scope.widget.query = widgets.addFilters(params, $scope.widget.data.filters, $scope.widget.data.accountLists);
        $scope.widget.data = _.defaults($scope.widget.data, {});

        $scope.$on('$destroy', function () {
            if (stagesCall) { stagesCall.abort(); }
            $scope.data.tScope.$destroy();
        });

        $scope.$on('filtersChanged', function () {
            ctrl.getData();
        });

        $scope.$on('angular-chart-rendered', function (event, options, instance) {
            $scope.data.chartResizer = instance;
            widgets.resizeChart($scope.data.chartResizer);
        });

        $scope.$on('gridster-item-resize', function () {
            $timeout(function () {
                widgets.resizeChart($scope.data.chartResizer);
            }, 500, false);
        });

        $scope.$on('$windowResizeEnd', function () {
            $timeout(function () {
                widgets.resizeChart($scope.data.chartResizer, $scope);
            }, 10, false);
        });

        $tile.listeners.push($scope.$on('widgetUpdate', function () {
            if (!angular.equals(angular.copy($scope.widget.query), $scope.widget.data.lastQuery)) {
                $tile.startLoading();
                $tile.loading(10);
                ctrl.getData();
            }
        }));
    })(this);
}]);
