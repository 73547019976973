app.controller('marketoIntegrationCtrl', ['$http', 'api', '$scope', '$rootScope', '$state', '$q', '$b', 'noty', function ($http, api, $scope, $rootScope, $state, $q, $b, noty) {

    function getMarketoIntegration() {
        $state.current.data.loading = true;
        api.get('config/get_marketo_tokens', {}, false, true).then(function (data) {
            if (data.data.length > 0) {
                $scope.data.marketoToken = data.data[0];
            }
            $state.current.data.loading = false;
        });
    }

    $scope.addToken = function () {
        // eslint-disable-next-line no-unused-vars
        api.set('config/add_marketo_token', { instance: $scope.data.new.marketo_instance, id: $scope.data.new.client_id, secret: $scope.data.new.client_secret }, false, true).then(function (data) {
            getMarketoIntegration();
        });
    };

    $scope.removeToken = function () {
        $b.confirm({
            text: 'Are you sure you want to remove this token?  The instance information will not be saved.',
            callback: function () {
                $state.current.data.loading = true;
                // eslint-disable-next-line no-unused-vars
                api.set('config/remove_marketo_token', {}, false, true).then(function (data) {
                    $scope.data.marketoToken = { marketo_instance: null, client_id: null, client_secret: null };
                    $state.current.data.loading = false;
                });
                noty.growl("Token successfully removed", 'information', true);
            }
        });
    };


    (function init() {
        $scope.data.new = { marketo_instance: null, client_id: null, client_secret: null };
        getMarketoIntegration();
    })();
}]);
