import { IDirective, IScope, } from 'angular';

import * as am4charts from '@amcharts/amcharts4/charts';

import { IKpiResponse, } from 'src/shared/models/scorecard/kpi-response/model';
import { ITrendingKpiQuery, } from '../trending-kpis-widget/component';
import { TrendingKpiChartsService, SVRC_TRENDING_KPI_CHARTS, } from '../../services/kpi-charts.service';
import * as ComponentTemplate from './component.html';
import * as CONSTANTS from '../../constants';
import './component.less';

export class TrendingKpiAreaChartController {
    static $inject: string[] = [
        '$scope',
        SVRC_TRENDING_KPI_CHARTS,
    ];

    public chart: am4charts.XYChart;
    public kpiFormat: string;
    public kpiTypeFormatDescriptor: string;
    public query: ITrendingKpiQuery;
    public queryId: string;
    public responseData: IKpiResponse;

    public retrieveGoalComparison = (targetGoal: number, segmentTotal: number) => (segmentTotal / targetGoal);
    public hasMetTargetGoal = (targetGoal: number, segmentTotal: number) => (segmentTotal / targetGoal) > 1;

    constructor(
        public $scope: IScope,
        public trendingKpiChartsService: TrendingKpiChartsService,
    ) {
        this.initialize();
    }

    private initialize(): void {
        this.queryId = this.$scope.ctrl.id;

        this.trendingKpiChartsService.changeChartContainerSize('area-tile-container', '66%', 'height');
        this.setupWatches();
    }

    private setupWatches(): void {
        this.setupWidgetQueryWatch();
        this.setupKPIDataWatch();
        this.setupOnDestroy();
    }

    private setupWidgetQueryWatch(): void {
        this.$scope.$watch('ctrl.widgetQuery', (value: ITrendingKpiQuery) => {
            if (value) {
                this.query = value;
                this.kpiFormat = CONSTANTS.KPI_TYPE_FORMAT_MAP[this.query.kpi];
                this.kpiTypeFormatDescriptor = this.kpiFormat === CONSTANTS.KPIFormat.Number ?
                    CONSTANTS.KPI_FORMAT_TYPE_NUMBER_DESCRIPTOR_MAP[this.query.kpi]
                    : '';
                if (this.responseData) {
                    this.initializeChart();
                }
            }
        });
    }

    private setupKPIDataWatch(): void {
        this.$scope.$watch('ctrl.kpiData', (value: IKpiResponse) => {
            if (value) {
                this.responseData = value;
                if (this.query) {
                    this.initializeChart();
                }
            }
        });
    }

    private initializeChart(): void {
        this.chart = this.trendingKpiChartsService.generateAreaGraph(
            this.queryId,
            this.responseData.dateIntervals,
            this.query.trendingChartStyle,
            this.query.kpi,
            this.query.interval,
            this.query.groupBy,
        );
    }

    private setupOnDestroy() {
        this.$scope.$on('$destroy', () => {
            this.cleanupChart();
        });
    }

    private cleanupChart() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
}

export class TrendingKpiAreaChart implements IDirective {
    public restrict = 'E';
    public controller: any = TrendingKpiAreaChartController;
    public controllerAs = 'ctrl';
    public bindToController = true;
    public template: any = ComponentTemplate;
    public scope: IScope = {
        widgetQuery: '=',
        kpiData: '=',
        id: '=',
    };

    public constructor() {
        return;
    }

    public static Factory(): IDirective {
        return new TrendingKpiAreaChart();
    }
}
