import { module as angularModule } from 'angular';

import { IScorecardAggregate } from '../../models';

import { APP_BRIGHTFUNNEL } from '../../../constants';
import { IDateCohortResponse } from '../../models/scorecard/date-cohort-response/model';
import { IKpiResponse } from 'src/shared/models/scorecard/kpi-response/model';
import * as CONSTANTS from '../constants';
import { ITrendingKpiQuery } from '../components/trending-kpis-widget/component';

const app = angularModule(APP_BRIGHTFUNNEL);

export class TrendingKpisService {

    public getDateCohorts(): Promise<IDateCohortResponse> {
        return this.$http.post('/v1/get_date_cohorts', {})
            .then((resp: { data: any[] }) => resp.data);
    }

    public getScorecardAggregates(): Promise<IScorecardAggregate[]> {
        return this.$http.post('/v1/get_scorecard_aggregates', {})
            .then((resp: { data: any[] }) => resp.data);
    }

    public getKpiData(params): Promise<IKpiResponse> {
        return this.$http.post('/v1/get_scorecard_kpi_trends', params)
            .then((resp: { data: any[] }) => resp.data);
    }

    public buildKpiRequest(query: ITrendingKpiQuery) {
        const queryCustomStartDate = +(new Date(query.customStartDate));
        const queryCustomEndDate = +(new Date(query.customEndDate));
        const customDatesSelected = (
            query.customStartDate
            && query.customEndDate
            && (
                queryCustomStartDate !== query.cohort.startDate
                || queryCustomEndDate !== query.cohort.endDate
            )
        );
        const canGroupbyFirmographics = [
            CONSTANTS.VISUALIZATION_TRENDING,
            CONSTANTS.VISUALIZATION_DONUT,
        ].indexOf(query.visualization) > -1;

        return {
            scorecardAggregateIds: query.accountLists.map((id) => +(id)),
            cohort: customDatesSelected ? this.buildCustomCohort(queryCustomStartDate, queryCustomEndDate) : query.cohort,
            type: query.kpi,
            progression: query.progression,
            interval: query.interval,
            trend: query.trendingType,
            group: query.groupBy,
            rollingTime: 90,
            firmographic: canGroupbyFirmographics ? query.firmographic : CONSTANTS.KPI_FIRMOGRAPHIC_NONE,
        };
    }

    private buildCustomCohort = (startDate: number, endDate: number) => ({
        cohort: 'custom',
        name: 'Custom Range',
        startDate: startDate,
        endDate: endDate,
    })

    constructor(public $http) { }
}

export const SRVC_TRENDING_KPIS = 'TrendingKpisService';
app.service(SRVC_TRENDING_KPIS, TrendingKpisService);
