app.controller('globalFiltersModalCtrl', ['$scope', 'api', 'utilities', 'navigation', 'widgets', '$filter', 'filters', '$state', '$q', '_', function ($scope, api, utilities, navigation, widgets, $filter, filtersSrv, $state, $q, _) {
    // This controller controls global filters when you first make a tile and on the widget
    // When you FIRST make a tile, there is no parent, thus this is how i figure out if i am handling
    // either filters on the widget or filters when a user first makes a widget.
    $scope.$parentState = $scope.$modal.parent;

    const widgetScope = $scope.$modal.parent || $scope;
    const parentParams = widgetScope.widget.query || {};
    // eslint-disable-next-line no-unused-vars
    const widgetState = $state.get(widgetScope.widget.state);

    $scope.filters = widgetScope.widget.data.filters;
    $scope.accountLists = widgetScope.widget.data.accountLists;

    filtersSrv.initializeFilters($scope.filters, filtersSrv.makeSelectedFilterMap(parentParams), "sync");
    filtersSrv.initializeFolders($scope.accountLists, filtersSrv.getListIdsFromParams(parentParams), "sync");

    $scope.data = {
        filterContainer: $scope.filters,
        oneAtATime: true
    };

    $scope.data.savedFilters = angular.copy(filtersSrv.getSavedFilters());

    //copy saved filters on WIDGET.
    // look at parent and see if any filters were applied
    if ($scope.$parentState) {
        _.forEach($scope.$modal.parent.widget.data.savedFilters, function (v) {
            if (v.applied) {
                $scope.data.preset = _.find(filtersSrv.getSavedFilters(), { name: v.name });
            }
        });
    }

    //resetting saved filters
    $scope.data.savedFilters = _.forEach($scope.data.savedFilters, function (savedFilter) {
        if (savedFilter.applied) {
            savedFilter.applied = false;
        }
    });

    if ($scope.data.preset) {
    // eslint-disable-next-line no-unused-vars
        _.forEach($scope.data.savedFilters, function (o, i) {
            if (o.name === $scope.data.preset.name) {
                o.applied = true;
            }
        });
    }

    // eslint-disable-next-line no-unused-vars
    var bounceSearch = _.debounce(function (searchTerm, ov) {
        $scope.$evalAsync(filtersSrv.searchFilter($scope.filters, $scope.accountLists, searchTerm, $scope.data));
    }, 500);

    $scope.$watch('data.searchTerm', bounceSearch);

    $scope.clearAll = function () {
        filtersSrv.initializeFilters($scope.data.filterContainer, {}, "save");
        filtersSrv.initializeFolders($scope.accountLists, [], "save");
        $scope.data.selectedSavedFilter = null;
    };

    $scope.selectSavedFilter = function (savedFilter) {
        if (savedFilter) {
            filtersSrv.selectSavedFilter(savedFilter.filters, $scope.data.filterContainer);
            filtersSrv.setSelectedFilter(savedFilter);
            filtersSrv.initializeFolders($scope.accountLists, savedFilter.list_ids, "save");
            $scope.data.enableSaveArrow = true;
        } else {
            $scope.clearAll();
            $scope.toggleSaveButtons(false);
        }
        $scope.data.selectedSavedFilter = savedFilter;
    };

    $scope.finish = function () {
        $scope.$modal.mergeFilters().then(function () {
            $scope.$modal.closeModal();
        });
    };

    $scope.selectAllInFolder = function (folder, container) {
        $scope.accountLists.selected += filtersSrv.selectAllInFolder(folder, container);
    };

    $scope.selectList = function (list, folder, container) {
        var totalSelected = filtersSrv.selectList(list, folder, container);
        folder.selected += totalSelected;
        $scope.accountLists.selected += totalSelected;
    };

    $scope.hasAccountLists = function () {
        return filtersSrv.hasAccountLists($scope.accountLists.folders);
    };

    $scope.filterToggled = function (data, field, table, container) {
        filtersSrv.filterToggled(data, field, table, container);
    };

    $scope.fieldToggled = function (field, table, container) {
        filtersSrv.fieldToggled(field, table, container);
    };

    $scope.$modal.mergeFilters = function () {
        var defer = $q.defer(),
            // eslint-disable-next-line no-unused-vars
            widgetState = $state.get(widgetScope.widget.state);

        for (var i = 0; i < $scope.data.savedFilters.length; i++) {
            $scope.data.savedFilters[i] = _.omit($scope.data.savedFilters[i], ['filters', 'label', 'modified_date', 'private', 'user']);
        }

        widgetScope.widget.data.savedFilters = $scope.data.savedFilters;

        filtersSrv.reconcileFilterState($scope.filters, "applied", "selected");
        filtersSrv.reconcileFolderState($scope.accountLists, "applied", "selected");
        widgetScope.widget.query = utilities.removeFilterValues(widgetScope.widget.query);
        var filterParams = filtersSrv.getFiltersAsParams($scope.filters);
        if ($scope.accountLists) {
            var selectedLists = filtersSrv.getSelectedAccountLists($scope.accountLists.folders);
            if (selectedLists.length > 0) {
                filterParams.al = selectedLists.join(",");
            }
        }
        if (filterParams && Object.keys(filterParams).length) {
            angular.extend(widgetScope.widget.query, filterParams);
        }
        defer.resolve();
        return defer.promise;
    };
}]);
