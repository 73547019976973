app.directive('chartImpact', ['Colors', '$timeout', '$state', '_', 'userModels', function (Colors, $timeout, $state, _, userModels) {
    // Return our directive description object // that will create the directive
    return {
        restrict: 'E',
        require: 'ngModel',
        scope: {
            'data': '=ngModel'
        },
        // eslint-disable-next-line no-unused-vars
        link: function (scope, element, attrs, ctrl) {
            scope.userModels = userModels;

            var chartOffsetTop = null, // this is the distance between the chart top and the document element top
                barElement,
                barsArea,
                chartArea,
                bars = [],
                parentWidth,
                viewport,
                chartHeight,
                xAxis = d3.svg.axis(),
                xAxisElement,
                yAxis = d3.svg.axis(),
                yAxisElement,
                yLabel,
                // eslint-disable-next-line no-unused-vars
                chartTitle,
                legend,
                chartTitleElement,
                yScale,
                xScale,
                chartType,
                blue = '#005589',
                orange = '#D88219',
                oliveGreen = '#97CD78',
                yAxisFormat = scope.data.config.yAxis.format,
                percentFormat = d3.format('.2f'),
                /*==========  keep track of all of the positioning  ==========*/
                margins = {
                    container: {
                        top: scope.data.title ? 50 : 7,
                        left: 0
                    },
                    yAxisElement: {
                        top: 0,
                        left: scope.data.config.yAxis.label ? 70 : 45
                    },
                    barsArea: {
                        top: 0,
                        left: scope.data.config.yAxis.label ? 90 : 65
                    }
                },
                reportLabels = {
                    tooltip: {
                        deal: 'Total Revenue',
                        oppty: 'Total Pipeline Created'
                    },
                    legend: {
                        deal: 'Revenue',
                        oppty: 'Pipeline Created'
                    }
                },
                reportColors = {
                    deal: oliveGreen,
                    oppty: orange,
                    influenced: blue,
                };
            /*=============================================
            =  init chart and find chart size
            =============================================*/
            $timeout(function () {
                // get the offset top reading
                // the chart is 140px from the document's top
                chartOffsetTop = element ? element.offset().top : 140;

                chartSizeCalc();
                _initChart();
            }, 0);

            /*=============================================
            =  listen for changes in the chart and resize or update
            =============================================*/
            scope.$watch('data', function (data) {
                if (data.data) {
                    chartTitle = data.title;
                    chartType = data.type;
                    bars = [];
                    data.data.slice(1, data.data.length)
                        // eslint-disable-next-line no-unused-vars
                        .forEach(function (element, index, array) {
                            bars.push({
                                index: index,
                                label: element[0],
                                predicted: element[1],
                                actual: element[2],
                                marketing: element[3]
                            });
                        });
                    _updateChart();
                }
            }, true);

            scope.$on('$windowResizeEnd', function () {
                _resize();
            });

            scope.$on('navToggled', function () {
                _resize();
            });

            scope.$on('bfFooterOpening', function () {
                _resize();
            });

            scope.$on('bfFooterClosing', function () {
                $timeout(function () {
                    _resize();
                }, 300);
            });

            // eslint-disable-next-line no-unused-vars
            scope.createTitleLabel = function (userModels, query, platform) {
                var titleString;

                titleString = query.opptyType === 'deal' ? 'Marketing Revenue Influenced' : 'Marketing Pipeline Influenced';
                titleString += query.period === 'quarter' ? ' by Quarter' : ' by Month';
                return titleString;
            };

            scope.createYLabel = function (query) {
                var yLabelString = 'Marketing Influence';
                yLabelString += query.opptyType === 'deal' ? ' Revenue' : ' Pipeline';
                return yLabelString;
            };

            var tooltip = d3.select('body').append('div')
                .attr({ class: 'tooltip vlc' })
                .style({
                    display: 'none',
                    width: '260px'
                });

            /*=============================================
            =  calculate the correct chart size
            =============================================*/
            function chartSizeCalc() {
                var footerOpen = element.parents('.module').find('.collapsable-footer .collapsable-footer-content').hasClass('collapsable-open'),
                    percentage = footerOpen ? .7 : 1,
                    offTheBottom = element.parents('.module').find('.collapsable-footer').length ? (element.parents('.module').find('.collapsable-footer-toggle').outerHeight() - 20) + 95 : 95;

                /*==========  window height minus top edge of chart element minus gap and padding for bottom  ==========*/
                viewport = ($(window).height() - chartOffsetTop) - offTheBottom;
                chartHeight = percentage * viewport >= 350 ? percentage * viewport : 350;
                chartHeight = scope.data.type === 'impact' ? chartHeight - 50 : chartHeight - 120;

                const width = element[0].clientWidth - 40;
                parentWidth = width < 0 ? 300 : width; // need to be fixed because of md-card container
            }

            /*=============================================
            =  initial set up with placeholders
            =============================================*/
            function _initChart() {
                chartArea = d3.select(element[0]).append("svg")
                    .attr('preserveAspectRatio', 'none')
                    .attr('width', parentWidth)
                    .attr("height", (scope.data.type == 'impact' ? chartHeight + 50 : chartHeight + 120))
                    .append("g")
                    .attr({
                        class: 'container',
                        transform: 'translate(' + margins.container.left + ',' + margins.container.top + ')'
                    });

                xAxisElement = chartArea.append('g')
                    .attr({
                        class: 'x axis',
                        transform: 'translate(' + [90, chartHeight - 30] + ')'
                    });

                yAxisElement = chartArea.append("g")
                    .attr({
                        class: 'y axis',
                        transform: 'translate(' + margins.yAxisElement.left + ',' + margins.yAxisElement.top + ')'
                    });

                barsArea = chartArea
                    .append('g')
                    .attr({
                        class: 'bars-area',
                        transform: 'translate(' + margins.barsArea.left + ',' + margins.barsArea.top + ')'
                    });

                chartTitleElement = chartArea
                    .append('text')
                    .attr({
                        class: 'chart-title',
                        dy: 10
                    });

                yLabel = chartArea
                    .append('text')
                    .attr({
                        class: 'yLabel',
                        dy: 10
                    })
                    .style({
                        'text-anchor': 'middle'
                    });

                legend = chartArea.append('g')
                    .attr({ class: 'legend' });

                scope.$emit('chartInitDone');

            }

            /*=============================================
            =  add data and init listeners
            =============================================*/
            function _updateChart() {
                _populateLegend();
                _setScales();
                _setAxis();
                _setLabels();
                _listen();
            }

            /*=============================================
            =  listen for when new elements are added
            =============================================*/
            function _listen() {
                barElement = barsArea
                    .selectAll('.bar-element')
                    .data(bars);
                barElement
                    .transition()
                    .duration(500)
                    .attr({
                        transform: function (d, i) {
                            return 'translate(' + [xScale.rangeBand() * i, 0] + ')';
                        }
                    }
                    );
                var newBarElement = barElement
                    .enter()
                    .append('g');
                newBarElement
                    .attr({
                        class: 'bar-element',
                        'bar-id': function (d, i) {
                            return i;
                        },
                        transform: function (d, i) {
                            return 'translate(' + [200 + xScale.rangeBand() * i, 0] + ')';
                        }
                    })
                    .transition()
                    .duration(500)
                    .attr({
                        transform: function (d, i) {
                            return 'translate(' + [xScale.rangeBand() * i, 0] + ')';
                        }
                    })
                ;
                barElement
                    .exit()
                    .transition()
                    .duration(500)
                    .attr({
                        transform: function (d, i) {
                            return 'translate(' + [xScale.rangeBand() * i, 0] + ')';
                        }
                    })
                    .style({
                        opacity: 0
                    })
                    .each('end', function () {
                        this.remove();
                    });

                barElement.selectAll('rect')
                    .datum(function () {
                        return d3.select(this.parentNode).datum();
                    })
                    .transition()
                    .duration(500)
                    .attr({
                        // eslint-disable-next-line no-unused-vars
                        width: function (d, i) {
                            return d3.select(this).classed('interaction-area') ? xScale.rangeBand() - 1 : xScale.rangeBand() / 4;
                        },
                        // eslint-disable-next-line no-unused-vars
                        height: function (d, i) {
                            var height;
                            if (d3.select(this).classed('bar-actual')) {
                                height = chartHeight - yScale(d.actual) - 30;
                            }
                            else if (d3.select(this).classed('bar-marketingSourced')) {
                                height = chartHeight - yScale(d.marketing) - 30;
                            }
                            else if (d3.select(this).classed('interaction-area')) {
                                height = chartHeight - 30;
                            }

                            return height;
                        },
                        // eslint-disable-next-line no-unused-vars
                        fill: function (d, i) {
                            if (d3.select(this).classed('bar-actual')) {
                                var scopeData = scope.data,
                                    dataQuery = scopeData.query,
                                    type = dataQuery ? dataQuery.opptyType : '';
                                if (scopeData && dataQuery && type) {
                                    return reportColors[type];
                                }
                            }
                            return blue;
                        },
                        // eslint-disable-next-line no-unused-vars
                        x: function (d) {
                            var xPosition;
                            if (d3.select(this).classed('bar-actual')) {
                                xPosition = chartType === 'impact'
                                    ? (xScale.rangeBand() - xScale.rangeBand() / 4) / 2 + xScale.rangeBand() / 8 + 1
                                    : (xScale.rangeBand() - xScale.rangeBand() / 4) / 2 - xScale.rangeBand() / 8 - 1;
                            }
                            else if (d3.select(this).classed('bar-marketingSourced')) {
                                xPosition = (xScale.rangeBand() - xScale.rangeBand() / 4) / 2 + xScale.rangeBand() / 8 + 2;
                            }
                            else if (d3.select(this).classed('interaction-area')) {
                                xPosition = 0;
                            }

                            return xPosition;
                        },
                        y: function (d) {
                            var yPosition;
                            if (d3.select(this).classed('bar-actual')) {
                                yPosition = yScale(d.actual);
                            }
                            else if (d3.select(this).classed('bar-marketingSourced')) {
                                yPosition = yScale(d.marketing);
                            }
                            else if (d3.select(this).classed('interaction-area')) {
                                yPosition = 0;
                            }

                            return yPosition;
                        }
                    });

                newBarElement.append('rect')
                    .attr({
                        width: xScale.rangeBand() - 1,
                        height: chartHeight - 30,
                        class: 'interaction-area'
                    })
                    .style({
                        fill: 'rgba(0,0,0,0)'
                    })
                    .on('mouseenter', function () {
                        d3.select(this)
                            .transition()
                            .duration(500)
                            .style({
                                fill: 'rgba(0,0,0,0.02)'
                            });
                    })
                    .on('mouseleave', function () {
                        d3.select(this)
                            .transition()
                            .duration(500)
                            .style({
                                fill: 'rgba(0,0,0,0)'
                            });
                    });

                // eslint-disable-next-line no-unused-vars
                newBarElement.each(function (d, i) {
                    if (d.actual > 0) {
                        d3.select(this)
                            .append('rect')
                            .attr({
                                class: 'bar-actual',
                                'element-id': function (d, i) {
                                    return i;
                                },
                                // eslint-disable-next-line no-unused-vars
                                fill: function (d, i) {
                                    var scopeData = scope.data,
                                        dataQuery = scopeData.query,
                                        type = dataQuery ? dataQuery.opptyType : '';
                                    if (scopeData && dataQuery && type) {
                                        return reportColors[type];
                                    }
                                },
                                width: xScale.rangeBand() / 4,
                                height: 0,
                                y: chartHeight - 30,
                                // eslint-disable-next-line no-unused-vars
                                x: function (d, i) {
                                    return chartType === 'impact'
                                        ? (xScale.rangeBand() - xScale.rangeBand() / 4) / 2 + xScale.rangeBand() / 8 + 1
                                        : (xScale.rangeBand() - xScale.rangeBand() / 4) / 2 - xScale.rangeBand() / 8 - 1;
                                }
                            })
                            .transition()
                            .delay(500)
                            .duration(500)
                            .attr({
                                width: xScale.rangeBand() / 4,
                                // eslint-disable-next-line no-unused-vars
                                height: function (d, i) {
                                    return chartHeight - yScale(d.actual) - 30;
                                },
                                y: function (d) {
                                    return yScale(d.actual);
                                }
                            });

                    }
                    if (chartType === 'total-revenue') {
                        var miRect = d3.select(this).append('rect').attr({
                            class: 'bar-marketingSourced',
                            'element-id': function (d, i) {
                                return i;
                            },
                            // eslint-disable-next-line no-unused-vars
                            fill: function (d) {
                                return blue;
                            },
                            width: xScale.rangeBand() / 4,
                            height: 0,
                            y: chartHeight - 30,
                            // eslint-disable-next-line no-unused-vars
                            x: function (d, i) {
                                return (xScale.rangeBand() - xScale.rangeBand() / 4) / 2 + xScale.rangeBand() / 8 + 2;
                            }
                        });

                        miRect.transition()
                            .delay(500)
                            .duration(500)
                            .attr({
                                width: xScale.rangeBand() / 4,
                                // eslint-disable-next-line no-unused-vars
                                height: function (d, i) {
                                    return chartHeight - yScale(d.marketing) - 30;
                                },
                                y: function (d) {
                                    return yScale(d.marketing);
                                }
                            });
                        if (scope.$parent.platform === 'full') {
                            miRect.on('click', function () {
                                var models = {
                                    sourced: 'sourced',
                                    last: 'last',
                                    even: 'even',
                                    custom: 'custom',

                                };
                                $state.go('app.discover.revenueAndPipeline.attributionTrends',
                                    {
                                        model: models[scope.data.query.modelType],
                                        type: (scope.data.query.opptyType == 'oppty' ? 'pipeline' : 'revenue')
                                    },
                                    { inherit: true });
                            })
                                .style({ cursor: "pointer" });
                        }
                    }
                });

                barElement
                    .on("mouseenter", function (d) {
                        tooltip
                            .style({ display: 'block' });

                        tooltip
                            .transition()
                            .duration(200)
                            .style("opacity", .9);

                        if (chartType == 'total-revenue') {
                            if (!d.predicted) {
                                var influenceLine = d.marketing > 0 && d.actual > 0
                                    ? '<p><span class="tooltip-label">% Marketing Influenced:</span><span class="tooltip-value">' + percentFormat((d.marketing / d.actual) * 100) + '%</span></p>'
                                    : '';

                                if (scope.data.query) {
                                    tooltip.html("<p class='tooltip-title'>" + d.label + "</p>"
                                        + "<p><span class='tooltip-label'>" + reportLabels.tooltip[scope.data.query.opptyType] + ":</span><span class='tooltip-value'>" + yAxisFormat(d.actual) + "</span></p>"
                                        + "<p><span class='tooltip-label'>Marketing Influenced:</span><span class='tooltip-value'>" + yAxisFormat(d.marketing) + "</span></p>"
                                        + influenceLine
                                    );
                                }
                            }
                        }
                    })
                    // eslint-disable-next-line no-unused-vars
                    .on("mousemove", function (d) {
                        tooltip
                            .style("left", (d3.event.pageX - 125) + "px")
                            .style("top", (d3.event.pageY + 40) + "px");
                    })
                    // eslint-disable-next-line no-unused-vars
                    .on("mouseleave", function (d) {
                        tooltip
                            .transition()
                            .duration(100)
                            .style("opacity", 0)
                            .each('end', function () {
                                d3.select(this).style({ display: 'none' });
                            });
                    });
            }

            /*=============================================
            =  set scales
            =============================================*/
            function _setScales() {
                xScale = d3.scale.ordinal()
                    .domain(_.range(bars.length))
                    .rangeBands([0, parentWidth - margins.yAxisElement.left]);

                yScale = d3.scale.linear()
                    .domain([0, d3.max(bars, function (d) {
                        return Math.max(d.predicted, d.actual, d.marketing);
                    })])
                    .range([chartHeight - 30, 30])
                    .nice();

                xAxis.scale(xScale)
                    .orient('bottom')
                    .tickFormat(function (d, i) {
                        return bars[i].label;
                    });

                yAxis.scale(yScale)
                    .ticks(10)
                    .orient("left")
                    .tickSize(-parentWidth + 20)
                    // eslint-disable-next-line no-unused-vars
                    .tickFormat(function (d, i) {
                        return yAxisFormat(d);
                    });
            }

            function _setAxis() {
                xAxisElement
                    .attr({
                        transform: 'translate(' + [90, chartHeight - 30] + ')'
                    });

                xAxisElement
                    .transition()
                    .duration(500)
                    .call(xAxis)
                    .selectAll('text')
                    .attr({
                        transform: 'rotate(-45)',
                        x: -10
                    })
                    .style({
                        'text-anchor': 'end'
                    });

                yAxisElement
                    .transition()
                    .duration(500)
                    .call(yAxis)
                    .selectAll('.tick line')
                    .transition()
                    .duration(500)
                    .attr({
                        x2: parentWidth
                    });
            }

            function _setLabels() {
                chartTitleElement.text(scope.createTitleLabel(scope.userModels, scope.data.query, scope.$parent.platform));

                yLabel
                    .text(scope.createYLabel(scope.data.query))
                    .attr({
                        transform: 'translate(' + [0, yScale.range()[0] / 2] + ') rotate(-90)'
                    });
            }

            /*=============================================
            =  legend populating
            =============================================*/

            function _populateLegend() {
                var legendItems = function () {
                    var itemsToReturn = [];
                    if (scope.data.query) {
                        var type = scope.data.query.opptyType;
                        var items = [
                            { label: 'Predicted', color: 'rgb(127, 127, 127)' },
                            { label: reportLabels.legend[type], color: reportColors[type] },
                            { label: 'Marketing Influenced', color: reportColors.influenced }
                        ];
                        itemsToReturn.push(items[1]);
                        if (chartType === 'total-revenue') {
                            itemsToReturn.push(items[2]);
                        }
                    }

                    return itemsToReturn;
                }();

                var nextOffset = 0;

                legend.selectAll('g').remove();

                var legendElement = legend
                    .selectAll('g')
                    .data(legendItems)
                    .enter()
                    .append('g');

                legendElement
                    .append('text')
                    .attr({ x: 20, y: 20 })
                    .text(function (d) {
                        return d.label;
                    });

                legendElement
                    .append('rect')
                    .attr({
                        width: 10,
                        height: 10,
                        y: 10
                    })
                    .style({
                        fill: function (d) {
                            return d.color;
                        }
                    });

                legendElement
                    .attr({
                        // eslint-disable-next-line no-unused-vars
                        transform: function (d, i) {
                            var thisOffset = nextOffset;
                            nextOffset = this.getBBox().width + thisOffset + 25;
                            return 'translate(' + [thisOffset, 0] + ')';
                        }
                    });

                legend.attr({
                    transform: function () {
                        return 'translate(' + [(((parentWidth) / 2) - (this.getBBox().width / 2)), chartHeight + 50 / 1.2] + ')';
                    }
                });
            }

            /*=============================================
            =  resize calculator
            =============================================*/
            function _resize() {

                //------------ calculate the new sizes and range based on new height -------------//
                chartSizeCalc();

                _setScales();
                _setAxis();
                _populateLegend();

                //------------ canvas -------------//
                d3.select(chartArea.node().parentNode).attr({
                    width: parentWidth,
                    height: scope.data.type === 'impact' ? chartHeight + 50 : chartHeight + 120
                });

                //------------ Axises -------------//
                d3.selectAll('.y.axis .tick')
                    // eslint-disable-next-line no-unused-vars
                    .attr('transform', function (d, i) {
                        return 'translate(' + [0, yScale(d)] + ')';
                    });

                d3.selectAll('.y.axis .tick').select('line')
                    .attr({
                        x2: parentWidth
                    });

                //------------ bars -------------//
                d3.selectAll('.bar-element')
                    .attr({
                        transform: function (d, i) {
                            return 'translate(' + [xScale.rangeBand() * i, 0] + ')';
                        }
                    });

                d3.selectAll('.bar-element').select('.bar-actual')
                    .attr({
                        width: function () {
                            return chartType === 'otd'
                                ? xScale.rangeBand() / 2
                                : xScale.rangeBand() / 4;
                        },
                        // eslint-disable-next-line no-unused-vars
                        height: function (d, i) {
                            return chartHeight - yScale(d.actual) - 30;
                        },
                        y: function (d) {
                            return yScale(d.actual);
                        },
                        // eslint-disable-next-line no-unused-vars
                        x: function (d, i) {
                            return chartType === 'impact'
                                ? (xScale.rangeBand() - xScale.rangeBand() / 4) / 2 + xScale.rangeBand() / 8 + 1
                                : (xScale.rangeBand() - xScale.rangeBand() / 4) / 2 - xScale.rangeBand() / 8 - 1;
                        }
                    });

                d3.selectAll('.bar-element').select('.bar-marketingSourced')
                    .attr({
                        width: function () {
                            return xScale.rangeBand() / 4;
                        },
                        // eslint-disable-next-line no-unused-vars
                        height: function (d, i) {
                            return chartHeight - yScale(d.marketing) - 30;
                        },
                        y: function (d) {
                            return yScale(d.marketing);
                        },
                        // eslint-disable-next-line no-unused-vars
                        x: function (d, i) {
                            return (xScale.rangeBand() - xScale.rangeBand() / 4) / 2 + xScale.rangeBand() / 8 + 2;
                        }
                    });

                //------------ labels -------------//

                var rotate = '';
                d3.selectAll('.bar-label').each(function () {
                    if (chartType == 'total-revenue' || xScale.rangeBand() <= this.getBBox().width) {
                        rotate = ' rotate(-90)';
                    }
                });

                d3.selectAll('.bar-label')
                    .attr({
                        transform: function () {
                            return 'translate(' + [(xScale.rangeBand() - (xScale.rangeBand() / 4)) / 2 + xScale.rangeBand() / 8 + 1, chartHeight - 15] + ')' + rotate;
                        }
                    });

                if (scope.data.config.yAxis.label) {
                    yLabel
                        .attr({
                            transform: function () {
                                return 'translate(' + [0, yScale.range()[0] / 2] + ') rotate(-90)';
                            }
                        });
                }
            }

            scope.$on('$destroy', function () {
                tooltip.remove();
            });
        }
    };
}]);
