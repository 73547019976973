import { ISelectOption } from '../models';
import { ITrendingKpiHeaderFormat, ITrendingKpiTitle } from './interfaces/trending-kpi.interface';

// Date Range Toggle Options
export const DATE_RANGE_TOGGLE_COHORT = 'cohort';
export const DATE_RANGE_TOGGLE_CUSTOM = 'custom';

export const SELECT_ALL_KEY = 'selectAll';
export const SELECT_ALL_LABEL = 'Select All';

// Visualizations
export const VISUALIZATION_NUMBER = 'number';
export const VISUALIZATION_GAUGE = 'gauge';
export const VISUALIZATION_DONUT = 'donut';
export const VISUALIZATION_PARETO = 'pareto';
export const VISUALIZATION_TRENDING = 'trending';

export type TrendingKPIVisualizationTypes =
  typeof VISUALIZATION_NUMBER
  | typeof VISUALIZATION_GAUGE
  | typeof VISUALIZATION_DONUT
  | typeof VISUALIZATION_PARETO
  | typeof VISUALIZATION_TRENDING
;

// KPIs
export const KPI_ACCOUNTS = 'ACCOUNT';
export const KPI_OPPTYS_WON = 'OPPTY_WON';
export const KPI_OPPTYS_CREATED = 'OPPTY_CREATED';
export const KPI_OPPTYS_CLOSED = 'OPPTY_CLOSED';
export const KPI_PIPELINE_CREATED = 'PIPELINE';
export const KPI_REVENUE_WON = 'REVENUE';
export const KPI_WIN_RATE = 'WIN_RATE';
export const KPI_DEAL_VELOCITY = 'DEAL_VELOCITY';
export const KPI_AVG_DEAL_SIZE = 'AVG_DEAL_SIZE';

export type TrendingKpiTypes =
  typeof KPI_ACCOUNTS
  | typeof KPI_OPPTYS_CREATED
  | typeof KPI_OPPTYS_CLOSED
  | typeof KPI_OPPTYS_WON
  | typeof KPI_PIPELINE_CREATED
  | typeof KPI_REVENUE_WON
  | typeof KPI_WIN_RATE
  | typeof KPI_DEAL_VELOCITY
  | typeof KPI_AVG_DEAL_SIZE
;

// Trending Types
export const TRENDING_TYPE_SNAPSHOT = 'SNAPSHOT';
export const TRENDING_TYPE_CUMULATIVE = 'CUMULATIVE';
export const TRENDING_TYPE_NET = 'NET';

export type TrendingKPIsTrendingTypes =
  typeof TRENDING_TYPE_SNAPSHOT
  | typeof TRENDING_TYPE_CUMULATIVE
  | typeof TRENDING_TYPE_NET
;

// PROGRESSIONS
export const PROGRESSION_TARGET_ACCOUNTS = 'LIST';
export const PROGRESSION_ENGAGED_ACCOUNTS = 'ENGAGED';
export const PROGRESSION_ACCOUNTS_WITH_NEW_OPPTYS = 'OPPORTUNITY';
export const PROGRESSION_WON_ACCOUNTS = 'WON';

export type TrendingKPIsProgressionTypes =
  typeof PROGRESSION_TARGET_ACCOUNTS
  | typeof PROGRESSION_ENGAGED_ACCOUNTS
  | typeof PROGRESSION_ACCOUNTS_WITH_NEW_OPPTYS
  | typeof PROGRESSION_WON_ACCOUNTS
;

// Intervals
export const INTERVAL_WEEKS = 'WEEKS';
export const INTERVAL_MONTHS = 'MONTHS';
export const INTERVAL_QUARTERS = 'QUARTERS';

export type TrendingKPIsIntervalTypes =
  typeof INTERVAL_WEEKS
  | typeof INTERVAL_MONTHS
  | typeof INTERVAL_QUARTERS
;

// KPI Grouping
export const KPI_GROUPING_NONE = 'NONE';
// export const KPI_GROUPING_METRIC = 'METRIC';
export const KPI_GROUPING_ACCOUNT_LIST = 'SCORECARD_AGGREGATE';
export const KPI_GROUPING_ENGAGEMENT = 'ACCOUNT_ENGAGEMENT';
export const KPI_GROUPING_IMPRESSIONS = 'IMPRESSIONS';
export const KPI_GROUPING_FIRMOGRAPHICS = 'FIRMOGRAPHICS';

export type TrendingKPIsGroupingTypes =
//   typeof KPI_GROUPING_METRIC
  typeof KPI_GROUPING_NONE
  | typeof KPI_GROUPING_ACCOUNT_LIST
  | typeof KPI_GROUPING_ENGAGEMENT
  | typeof KPI_GROUPING_IMPRESSIONS
  | typeof KPI_GROUPING_FIRMOGRAPHICS
;

// KPI Firmographics
export const KPI_FIRMOGRAPHIC_NONE = 'NONE';
export const KPI_FIRMOGRAPHIC_REVENUE = 'REVENUE';
export const KPI_FIRMOGRAPHIC_EMPLOYEE = 'EMPLOYEE';
export const KPI_FIRMOGRAPHIC_INDUSTRY = 'INDUSTRY';

export type TrendingKPIsFirmographicsTypes =
    typeof KPI_FIRMOGRAPHIC_NONE |
    typeof KPI_FIRMOGRAPHIC_REVENUE |
    typeof KPI_FIRMOGRAPHIC_EMPLOYEE |
    typeof KPI_FIRMOGRAPHIC_INDUSTRY
;

// Chart Style
export const CHART_STYLE_LINE = 'line';
export const CHART_STYLE_COLUMN = 'column';
export const CHART_STYLE_STACKED_AREA = 'stackedArea';
export const CHART_STYLE_STACKED_COLUMN = 'stackedColumn';

export type TrendingKPIsChartStyleTypes =
  typeof CHART_STYLE_LINE
  | typeof CHART_STYLE_COLUMN
  | typeof CHART_STYLE_STACKED_AREA
  | typeof CHART_STYLE_STACKED_COLUMN
;

export const SELECT_OPTIONS: Record<string, ISelectOption[]> = {
    visualizations: [
        { key: VISUALIZATION_NUMBER, label: 'Number' },
        { key: VISUALIZATION_GAUGE, label: 'Gauge' },
        { key: VISUALIZATION_DONUT, label: 'Donut' },
        { key: VISUALIZATION_PARETO, label: 'Trending Goal (Pareto Chart)' },
        { key: VISUALIZATION_TRENDING, label: 'Trending Chart' },
    ],
    kpis: [
        { key: KPI_ACCOUNTS, label: 'Accounts' },
        { key: KPI_OPPTYS_WON, label: 'Opportunities Won'},
        { key: KPI_OPPTYS_CREATED, label: 'Opportunities Created' },
        { key: KPI_OPPTYS_CLOSED, label: 'Deals Closed' },
        { key: KPI_PIPELINE_CREATED, label: 'Pipeline Created' },
        { key: KPI_REVENUE_WON, label: 'Revenue Won' },
        { key: KPI_WIN_RATE, label: 'Win-Rate' },
        { key: KPI_DEAL_VELOCITY, label: 'Deal Velocity' },
        { key: KPI_AVG_DEAL_SIZE, label: 'Average Deal Size' },
    ],
    progressions: [
        { key: PROGRESSION_TARGET_ACCOUNTS, label: 'Target Accounts' },
        { key: PROGRESSION_ENGAGED_ACCOUNTS, label: 'Engaged Accounts' },
        { key: PROGRESSION_ACCOUNTS_WITH_NEW_OPPTYS, label: 'Accounts with New Opportunities' },
        { key: PROGRESSION_WON_ACCOUNTS, label: 'Won Accounts' },
    ],
    trendingTypes: [
        { key: TRENDING_TYPE_SNAPSHOT, label: 'Snapshot' },
        { key: TRENDING_TYPE_CUMULATIVE, label: 'Cumulative' },
        { key: TRENDING_TYPE_NET, label: 'Net-New' },
    ],
    progression: [
        { key: PROGRESSION_TARGET_ACCOUNTS, label: 'Target Accounts' },
        { key: PROGRESSION_ENGAGED_ACCOUNTS, label: 'Engaged Accounts' },
        { key: PROGRESSION_ACCOUNTS_WITH_NEW_OPPTYS, label: 'Accounts with New Opportunities' },
        { key: PROGRESSION_WON_ACCOUNTS, label: 'Won Accounts' },
    ],
    intervals: [
        { key: INTERVAL_WEEKS, label: 'Weekly' },
        { key: INTERVAL_MONTHS, label: 'Monthly' },
        { key: INTERVAL_QUARTERS, label: 'Quarterly' },
    ],
    groupByOptions: [
        { key: KPI_GROUPING_NONE, label: 'None' },
        { key: KPI_GROUPING_ACCOUNT_LIST, label: 'Account List' },
        { key: KPI_GROUPING_ENGAGEMENT, label: 'Engagement' },
        // { key: KPI_GROUPING_IMPRESSIONS, label: 'Impressions' },
        { key: KPI_GROUPING_FIRMOGRAPHICS, label: 'Firmographics' },
    ],
    firmographicOptions: [
        { key: KPI_FIRMOGRAPHIC_EMPLOYEE, label: 'Employee' },
        { key: KPI_FIRMOGRAPHIC_INDUSTRY, label: 'Industry' },
        { key: KPI_FIRMOGRAPHIC_REVENUE, label: 'Revenue' },
    ],
    chartStyles: [
        { key: CHART_STYLE_LINE, label: 'Line' },
        { key: CHART_STYLE_COLUMN, label: 'Column' },
        { key: CHART_STYLE_STACKED_AREA, label: 'Stacked Area' },
        { key: CHART_STYLE_STACKED_COLUMN, label: 'Stacked Column' },
    ],
};

// Interval Human Readable Map
export const INTERVAL_HUMAN_READABLE_MAP: Record<string, string> = {
    [INTERVAL_WEEKS]: 'week',
    [INTERVAL_MONTHS]: 'month',
    [INTERVAL_QUARTERS]: 'quarter',
};

// Kpi Human Readable Map
export const KPI_HUMAN_READABLE_MAP: Record<string, string> = {
    [KPI_ACCOUNTS]: 'Accounts',
    [KPI_OPPTYS_WON]: 'Opportunities Won',
    [KPI_OPPTYS_CREATED]: 'Opportunities Created',
    [KPI_OPPTYS_CLOSED]: 'Deals Closed',
    [KPI_PIPELINE_CREATED]: 'Pipeline Created',
    [KPI_REVENUE_WON]: 'Revenue Won',
    [KPI_WIN_RATE]: 'Win-Rate',
    [KPI_DEAL_VELOCITY]: 'Deal Velocity',
    [KPI_AVG_DEAL_SIZE]: 'Average Deal Size',
};

// Progression Human Readable Map
export const PROGERSSION_HUMAN_READABLE_MAP: Record<string, string> = {
    [PROGRESSION_ACCOUNTS_WITH_NEW_OPPTYS]: 'Accounts with New Opportunities',
    [PROGRESSION_TARGET_ACCOUNTS]: 'Target Accounts',
    [PROGRESSION_ENGAGED_ACCOUNTS]: 'Engaged Accounts',
    [PROGRESSION_WON_ACCOUNTS]: 'Won Accounts',
};

// Group by Human Readabke Map
export const GROUPY_BY_HUMAN_READBLE_MAP: Record<string, string> = {
    [KPI_GROUPING_NONE]: 'None',
    [KPI_GROUPING_ACCOUNT_LIST]: 'Account List',
    [KPI_GROUPING_ENGAGEMENT]: 'Engagement',
    [KPI_GROUPING_IMPRESSIONS]: 'Impressions',
    [KPI_GROUPING_FIRMOGRAPHICS]: 'Firmographics',
};

// Chart Style Human Readable Map
export const CHART_STYLE_HUMAN_READABLE_MAP: Record<string, string> = {
    [CHART_STYLE_COLUMN]: 'Column',
    [CHART_STYLE_LINE]: 'Line',
    [CHART_STYLE_STACKED_AREA]: 'Stacked Area',
    [CHART_STYLE_STACKED_COLUMN]: 'Stacked Column',
};

// Query Keys Const
export const QUERY_KEY_ACCOUNT_LISTS = 'accountLists';
export const QUERY_KEY_COHORT = 'cohort';
export const QUERY_KEY_CUSTOM_END_DATE = 'customEndDate';
export const QUERY_KEY_CUSTOM_START_DATE = 'customStartDate';
export const QUERY_KEY_GAUGE_RANGE_MIN = 'gaugeRangeMin';
export const QUERY_KEY_GAUGE_RANGE_MAX = 'gaugeRangeMax';
export const QUERY_KEY_GAUGE_RANGE_RED_YELLOW = 'gaugeRangeRedYellow';
export const QUERY_KEY_GAUGE_RANGE_YELLOW_GREEEN = 'gaugeRangeYellowGreen';
export const QUERY_KEY_GROUP_BY = 'groupBy';
export const QUERY_KEY_INTERVAL = 'interval';
export const QUERY_KEY_KPI = 'kpi';
export const QUERY_KEY_PROGRESSION = 'progression';
export const QUERY_KEY_TRENDING_TYPE = 'trendingType';
export const QUERY_KEY_TRENDING_CHART_STYLE = 'trendingChartStyle';
export const QUERY_KEY_VISUALIZATION = 'visualization';
export const QUERY_KEY_TRENDING_GOAL = 'trendingGoal';

// Human Readable Query Keys Map
export const QUERY_KEYS_HUMAN_READABLE_MAP: Record<string, string> = {
    [QUERY_KEY_ACCOUNT_LISTS]: 'Account List',
    [QUERY_KEY_COHORT]: 'Cohort',
    [QUERY_KEY_CUSTOM_END_DATE]: 'Custom End Date',
    [QUERY_KEY_CUSTOM_START_DATE]: 'Custom Start Date',
    [QUERY_KEY_INTERVAL]: 'Interval',
    [QUERY_KEY_GAUGE_RANGE_MIN]: 'Gauge Range Min',
    [QUERY_KEY_GAUGE_RANGE_MAX]: 'Gauge Range Max',
    [QUERY_KEY_GAUGE_RANGE_RED_YELLOW]: 'Gauge Range Red Yellow',
    [QUERY_KEY_GAUGE_RANGE_YELLOW_GREEEN]: 'Gauge Range Yellow Green',
    [QUERY_KEY_GROUP_BY]: 'Group By',
    [QUERY_KEY_KPI]: 'Kpi',
    [QUERY_KEY_PROGRESSION]: 'Progression',
    [QUERY_KEY_TRENDING_TYPE]: 'Trending Type',
    [QUERY_KEY_TRENDING_CHART_STYLE]: 'Trending Chart Style',
    [QUERY_KEY_VISUALIZATION]: 'Visualization',
    [QUERY_KEY_TRENDING_GOAL]: 'Trending Goal',
};

// Descriptors for Kpi number format type
export const KPI_FORMAT_TYPE_NUMBER_DESCRIPTOR_MAP: Record<string, string > = {
    [KPI_ACCOUNTS]: '',
    [KPI_OPPTYS_CLOSED]: '',
    [KPI_OPPTYS_CREATED]: '',
    [KPI_DEAL_VELOCITY]: 'Days',
};

// Kpi Type Format As
export enum KPIFormat {
    Currency = 'CURRENCY',
    Number = 'NUMBER',
    Percentage = 'PERCENTAGE',
}

export const KPI_TYPE_FORMAT_MAP: Record<string, string> = {
    [KPI_ACCOUNTS]: KPIFormat.Number,
    [KPI_OPPTYS_CLOSED]: KPIFormat.Number,
    [KPI_OPPTYS_CREATED]: KPIFormat.Number,
    [KPI_PIPELINE_CREATED]: KPIFormat.Currency,
    [KPI_REVENUE_WON]: KPIFormat.Currency,
    [KPI_WIN_RATE]: KPIFormat.Percentage,
    [KPI_AVG_DEAL_SIZE]: KPIFormat.Currency,
    [KPI_DEAL_VELOCITY]: KPIFormat.Number,
};

// KPI Type Value Axis Map
export const KPI_TYPE_VALUE_AXIS_MAP: Record<TrendingKpiTypes, string> = {
    [KPI_ACCOUNTS]: '#,###',
    [KPI_OPPTYS_CREATED]: '#,###',
    [KPI_OPPTYS_CLOSED]: '#,###',
    [KPI_OPPTYS_WON]: '#,###',
    [KPI_PIPELINE_CREATED]: '$#,###',
    [KPI_REVENUE_WON]: '$#,###',
    [KPI_WIN_RATE]: '#,###.0%',
    [KPI_DEAL_VELOCITY]: '#,###.',
    [KPI_AVG_DEAL_SIZE]: '$#,###',
};

// Donut Chart Kpi Label Map
export const DONUT_CHART_KPI_LABEL_MAP: Record<string, string> = {
    [KPI_ACCOUNTS]: 'Accounts',
    [KPI_OPPTYS_CLOSED]: 'Deals Closed',
    [KPI_OPPTYS_CREATED]: 'Opportunities Created',
    [KPI_PIPELINE_CREATED]: 'Pipeline',
    [KPI_REVENUE_WON]: 'Revenue',
};

export const TRENDING_KPIS_MISSING_DESCRIPTOR_BACKFILL = 'n/a';

// New KPI chart UI feature
export const TRENDING_KPI_NEW_UI_TITLE: ITrendingKpiTitle[] = [{
    type: KPI_ACCOUNTS,
    progression: PROGRESSION_TARGET_ACCOUNTS,
    label: 'Target Accounts'
}, {
    type: KPI_ACCOUNTS,
    progression: PROGRESSION_ENGAGED_ACCOUNTS,
    label: 'Engaged Accounts'
}, {
    type: KPI_ACCOUNTS,
    progression: PROGRESSION_ACCOUNTS_WITH_NEW_OPPTYS,
    label: 'Accounts with New Opportunities'
}, {
    type: KPI_ACCOUNTS,
    progression: PROGRESSION_WON_ACCOUNTS,
    label: 'Won Accounts'
}, {
    type: KPI_OPPTYS_WON,
    label: 'Opportunities Won'
}, {
    type: KPI_OPPTYS_CREATED,
    label: 'Opportunities Created'
}, {
    type: KPI_OPPTYS_CLOSED,
    label: 'Opportunities Closed'
}, {
    type: KPI_PIPELINE_CREATED,
    label: 'Pipeline Created'
}, {
    type: KPI_REVENUE_WON,
    label: 'Revenue Won'
}, {
    type: KPI_WIN_RATE,
    label: 'Win-Rate'
}, {
    type: KPI_DEAL_VELOCITY,
    label: 'Deal Velocity (days)'
}, {
    type: KPI_AVG_DEAL_SIZE,
    label: 'Average Deal Size'
}];

export const TRENDING_KPI_NEW_UI_HEADER: ITrendingKpiHeaderFormat[] = [{
    type: KPI_ACCOUNTS,
    format: KPIFormat.Number,
    round: 0
}, {
    type: KPI_OPPTYS_WON,
    format: KPIFormat.Number,
    round: 0
}, {
    type: KPI_OPPTYS_CREATED,
    format: KPIFormat.Number,
    round: 0
}, {
    type: KPI_OPPTYS_CLOSED,
    format: KPIFormat.Number,
    round: 0
}, {
    type: KPI_PIPELINE_CREATED,
    format: KPIFormat.Currency,
    round: 0
}, {
    type: KPI_REVENUE_WON,
    format: KPIFormat.Currency,
    round: 0
}, {
    type: KPI_WIN_RATE,
    format: KPIFormat.Percentage,
    round: 0
}, {
    type: KPI_DEAL_VELOCITY,
    format: KPIFormat.Number,
    round: 0
}, {
    type: KPI_AVG_DEAL_SIZE,
    format: KPIFormat.Currency,
    round: 0
}];
