app.controller('sfdcIntegrationCtrl', ['$http', 'api', '$scope', '$rootScope', '$state', '$q', 'noty', '$filter', '_', function ($http, api, $scope, $rootScope, $state, $q, noty, $filter, _) {
    $scope.addField = function (field) {
        field.adding = true;
        $scope.data.addToPending = true;
        var newField = { active: 0, custom_field: 1, label: field.label, name: field.name, populated: 0, row_id: null, approved: 0, currency: field.currency };
        api.get('sfdc/add_schema_config', { field_name: newField.name, field_label: newField.label, object: $scope.query.currentObject, currency_field: newField.currency }, false, true).then(function (data) {
            field.row_id = data.data;
            field.pending = 1;
            field.inSchemaConfig = 1;
            field.adding = false;
            $scope.data.pendingFields.push(field);
            getSchemaConfig().then(function () {
                noty.growl('Field pending approval...', 'information', true);
            });
        });
    };

    $scope.isActive = function (object) {
        return $scope.query.currentObject == object;
    };

    $scope.updateField = function (field) {
        // eslint-disable-next-line no-unused-vars
        api.set('sfdc/update_schema_config', field, false, true).then(function (data) {
            noty.growl('Field successfully updated', 'success');
        });
    };

    $scope.approveField = function (row_id) {
        var item, field;
        $state.current.data.loading = true;
        // eslint-disable-next-line no-unused-vars
        api.set('sfdc/approve_config', { rowId: row_id }, false, true).success(function (data) {
            for (item in $scope.data.pendingFields) {
                if ($scope.data.pendingFields[item].row_id == row_id) {
                    $scope.data.pendingFields.splice(item, 1);
                }
            }
            for (field in $scope.data.sfdcFields[$scope.query.type]) {
                if ($scope.data.sfdcFields[$scope.query.type][field].row_id == row_id) {
                    $scope.data.sfdcFields[$scope.query.type][field].pending = 0;
                    $scope.data.sfdcFields[$scope.query.type][field].approved = 1;
                    $scope.data.sfdcFields[$scope.query.type][field].active = 1;
                }
            }
            $state.current.data.loading = false;
        });
    };

    $scope.refreshFields = function refreshFields(object) {
        $state.current.data.loading = true;
        api.set('sfdc/refresh_sfdc_fields', { object: object }).success(function () {
            noty.growl('SFDC fields successfully refreshed', 'success');
            $scope.loadObject(object);
        }).error(function () {
            noty.growl('Oops! Looks like something went wrong while refreshing your SFDC fields.', 'error');
        });
    };

    $scope.loadObject = function (object) {
        $state.current.data.loading = true;
        api.get('sfdc/fetch_all_sfdc_fields', { object: object }, false, true).then(function (data) {
            $scope.data.sfdcFields = data.data;
            $scope.query.field = data.data[0];
            angular.forEach($scope.data.sfdcFields, function (field) {
                var f = _.find($scope.data.schemaConfig[object], { name: field.name });
                if (f) { field.inSchemaConfig = 1; field.approved = f.approved; field.active = f.active; field.row_id = f.row_id; }
            });
            $scope.data.sfdcFields = formatFields($scope.data.sfdcFields);
            $state.current.data.loading = false;
        });

        api.get('sfdc/fetch_pending_approval', {}, false, true).then(function (data) {
            $scope.data.pendingFields = data.data;
        });
    };

    function formatFields(data) {
        var formattedData = {};
        angular.forEach($scope.data.sortingMethods, function (method) {
            if (method.field == 'all') {
                formattedData[method.field] = data;
            }
            else if (method.field == 'to_fetch') {
                formattedData[method.field] = $filter('filter')(data, function (field) {
                    return !field.inSchemaConfig;
                });
            }
            else if (method.field == 'approved') {
                formattedData[method.field] = $filter('filter')(data, function (field) {
                    return field.approved;
                });
            } else { //pending
                formattedData[method.field] = $filter('filter')(data, function (field) {
                    return field.inSchemaConfig && !field.approved;
                });
            }
        });
        return formattedData;
    }

    function getSchemaConfig() {
        var defer = $q.defer();
        $scope.data.addToPending = false;
        api.get('sfdc/load_schema_config', {}, false, true).then(function (data) {
            $scope.data.schemaConfig = data.data;
            $scope.loadObject($scope.query.currentObject);
        });
        defer.resolve();
        return defer.promise;
    }

    function getAllObjects() {
        api.get('sfdc/get_parent_objects', {}, false, true).then(function (data) {
            $scope.data.sfdcObjects = data.data;
        });
    }

    (function init() {
        $state.current.data.loading = true;
        $scope.data = { sfdcFields: [], sfdcObjects: [], schemaConfig: [], addToPending: false };
        $scope.data.sortingMethods = [
            {
                label: 'All',
                field: 'all'
            },
            {
                label: 'To Fetch',
                field: 'to_fetch'
            },
            {
                label: 'Approved',
                field: 'approved'
            },
            {
                label: 'Pending',
                field: 'pending'
            }
        ];
        $scope.query = {
            loaded: [],
            field: null,
            isAdmin: $rootScope.userData.permissions.org_admin,
            currentObject: 'Account',
            type: $scope.data.sortingMethods[0].field
        };
        getAllObjects();
        getSchemaConfig();
    })();
}]);
