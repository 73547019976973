import {
    ACTIVE_ORGS_ONLY_LABEL,
    BUTTON_LABEL,
    INTEGRATIONS_LABEL,
    NOTY_ERROR,
    NOTY_SUCCESS,
    NOTY_SUCCESS_MESSAGE,
    ORGANIZATION_LABEL
} from './constants/constants';
import {OrganizationsSrvc} from './services/organizations-srvc';
import {TokenManagerSrvc} from './services/token-manager-srvc';
import {IOrgChanged, OrgConfigElement, ServiceDescriptor} from './services/interfaces';

export class TokenManagerCtrl {
    static $inject = ['$scope', 'organizationsSrvc', 'tokenManagerSrvc', 'noty'];

    constructor(
        public $scope: any,
        private organizationsSrvc: OrganizationsSrvc,
        private tokenManagerSrvc: TokenManagerSrvc,
        private noty: any
    ) {
        this.initialize();
        this.fetchOrgs();
        this.$scope.loading = true;
    }

    public save = () => {
        this.tokenManagerSrvc.setIntegrationsVisibility(
            {
                orgUuid: (this.$scope.orgSelector.selectedOrg as OrgConfigElement).uuid,
                cards: this.$scope.cards
            }).then(() => {
            this.noty.growl(NOTY_SUCCESS_MESSAGE, NOTY_SUCCESS);
        });
    }

    public orgChanged: IOrgChanged = (elem: OrgConfigElement) => {
        this.$scope.loading = true;
        this.$scope.orgSelector.selectedOrg = elem;
        this.$scope.cards.length = 0;
        this.fetchIntegrations().then((response) => {
            for (const service of response.integrations) {
                const serviceDescriptor: ServiceDescriptor = service.service;
                this.$scope.cards.push({
                    id: serviceDescriptor.serviceId,
                    label: serviceDescriptor.vendorName,
                    img: service.image,
                    value: service.visible
                });
            }
            this.$scope.loading = false;
        }, this.errorHandler);
    }

    private initialize(): void {
        this.$scope.orgSelector = {
            activeOrgsLabel: ACTIVE_ORGS_ONLY_LABEL,
            orgLabel: ORGANIZATION_LABEL,
            changeFn: this.orgChanged,
            selectedOrg: null
        };
        this.$scope.integrations = {
            label: INTEGRATIONS_LABEL
        };
        this.$scope.cards = [];
        this.$scope.button = {
            label: BUTTON_LABEL,
            changeFn: this.save
        };
        this.$scope.ctrl = this;
    }

    private errorHandler = (error: any) => {
        this.noty.growl(JSON.stringify(error), NOTY_ERROR);
        return;
    }

    private fetchOrgs(): void {
        this.organizationsSrvc.getOrgs().then((response) => {
            this.$scope.orgSelector.orgs = response;
            this.$scope.loading = false;
        }, this.errorHandler);
    }

    private fetchIntegrations(): Promise<any> {
        return this.tokenManagerSrvc.getIntegrationsVisibility({
            orgUuid:
            (this.$scope.orgSelector.selectedOrg as OrgConfigElement).uuid
        }).catch(this.errorHandler);
    }
}
