// Reports Endpoints on Terminus Hub
export enum TerminusHubEndpoints {
    AccountHub = '/list-analysis',
    AdvertisingInsights = '/ad-insights',
    AnalyzeMarketingImpact = '/discover/revenue-and-pipeline/marketing-impact',
    AttributionByCampaignType = '/discover/revenue-and-pipeline/attribution-by-quarter',
    AnalyzeAttributionByCampaignType = '/analyze/campaigns/attribution-by-quarter',
    MarketingImpact = '/discover/revenue-and-pipeline/marketing-impact',
    Scorecard = '/scorecard',
    OpportunityInsight = '/analyze/opportunities/list-analysis',
    OpportunitySpecificJourney = '/analyze/opportunities/list-analysis/opportunity-specific/attribution/total-touches',
    OpportunityTouches = '/analyze/opportunities/list-analysis/all-opptys-touches',
    CampaignTrends = '/analyze/campaigns/trending-analysis',
    CampaignSpecific = '/analyze/campaigns/list-analysis/campaign-specific/attribution/leads',
    WebActivitiesTrending = '/analyze/digital-attribution/web-activity-trending',
    SalesforceCompare = '/analyze/campaigns/list-analysis/campaign-specific/sfdc',
    StagesSnapshot = '/discover/stage-progression/stages-snapshot',
    CampaignAnalyticsGroups = '/analyze/campaigns/list-analysis/campaign-groups',
    CampaignAnalyticsCampaigns = '/analyze/campaigns/list-analysis/campaigns',
    WebActivitiesSource = '/analyze/digital-attribution/web-activity/channel-group',
    WebActivitiesMediums = '/analyze/digital-attribution/web-activity/channel-medium',
    WebActivitiesCampaign = '/analyze/digital-attribution/web-activity/channel-name',
    WebActivitiesSpecific = '/analyze/digital-attribution/web-activity/channel-asset-specific/attribution/leads',
    CohortedWaterfall = '/discover/stage-progression/attribution',
    CohortedWaterfallDetails = '/discover/stage-progression/details',
    ProgressionByQuarter = '/discover/stage-progression/trending',
    ProgressionDetails = '/discover/stage-progression/trending-details',
    Dashboard = '/dashboards',
    AccountEngagement = '/settings/account-engagement',
    CampaignScoring = '/settings/campaign-scoring',
    StageInfluenceCampaign = '/analyze/campaigns/stage-influence/campaign',
    StageInfluenceCampaignType = '/analyze/campaigns/stage-influence/campaign-type',
    HubspotConfig = '/settings/self-configure/integrations/hubspot',
    SegmentBuilder = '/segment-builder',
}

// Report Names
export enum TerminusHubReports {
    AccountHub = 'app.analyze.accounts.attribution',
    AdvertisingInsights = 'app.measurementStudio.advertisingInsights',
    AnalyzeMarketingImpact = 'app.analyze.revenueAndPipeline.marketingImpact',
    AttributionByCampaignType = 'app.discover.revenueAndPipeline.attributionByQuarter',
    AnalyzeAttributionByCampaignType = 'app.analyze.campaigns.attributionByQuarter',
    MarketingImpact = 'app.discover.revenueAndPipeline.marketingImpact',
    Scorecard = 'app.analyze.accounts.scorecard',
    OpportunityInsight = 'app.analyze.opportunities.listAnalysis',
    OpportunityJourney = 'app.analyze.opportunities.opportunitySpecific.attribution',
    OpportunityTouches = 'app.analyze.opportunities.allOpptysTouches',
    CampaignTrends = 'app.analyze.campaigns.trendingAnalysis',
    CampaignSpecific = 'app.analyze.campaigns.campaignSpecific.attribution',
    WebActivitiesTrending = 'app.analyze.webTracking.webActivityTrending',
    SalesforceCompare = 'app.analyze.campaigns.campaignSpecific.sfdc',
    StagesSnapshot = 'app.discover.stageProgression.stagesSnapshot',
    CampaignAnalyticsGroups = 'app.analyze.campaigns.listAnalysis.campaignGroups',
    CampaignAnalyticsCampaigns = 'app.analyze.campaigns.listAnalysis.campaigns',
    WebActivitiesSource = 'app.analyze.webTracking.webActivity.channel',
    WebActivitiesMediums = 'app.analyze.webTracking.webActivity.channelMedium',
    WebActivitiesCampaign = 'app.analyze.webTracking.webActivity.channelAsset',
    WebActivitiesSpecific = 'app.analyze.webTracking.channelAssetSpecific.attribution',
    CohortedWaterfall = 'app.discover.stageProgression.attribution',
    CohortedWaterfallDetails = 'app.discover.stageProgression.details',
    ProgressionByQuarter = 'app.discover.stageProgression.trending',
    ProgressionDetails = 'app.discover.stageProgression.trendingDetails',
    Dashboard = 'app.dashboard',
    AccountEngagement = 'app.settings.selfConfigure.accountEngagement',
    CampaignScoring = 'app.settings.attributionWeight',
    StageInfluence = 'app.analyze.campaigns.stageInfluence',
    HubspotConfig = 'app.settings.selfConfigure.hubspot',
    SegmentBuilder = 'app.segmentBuilder',
}

export const TERMINUS_HUB_ENDPOINT_MAP = {
    [TerminusHubReports.AccountHub]: TerminusHubEndpoints.AccountHub,
    [TerminusHubReports.AdvertisingInsights]: TerminusHubEndpoints.AdvertisingInsights,
    [TerminusHubReports.AnalyzeMarketingImpact]: TerminusHubEndpoints.AnalyzeMarketingImpact,
    [TerminusHubReports.MarketingImpact]: TerminusHubEndpoints.MarketingImpact,
    [TerminusHubReports.Scorecard]: TerminusHubEndpoints.Scorecard,
    [TerminusHubReports.AnalyzeAttributionByCampaignType]: TerminusHubEndpoints.AnalyzeAttributionByCampaignType,
    [TerminusHubReports.AttributionByCampaignType]: TerminusHubEndpoints.AttributionByCampaignType,
    [TerminusHubReports.OpportunityInsight]: TerminusHubEndpoints.OpportunityInsight,
    [TerminusHubReports.OpportunityJourney]: TerminusHubEndpoints.OpportunitySpecificJourney,
    [TerminusHubReports.OpportunityTouches]: TerminusHubEndpoints.OpportunityTouches,
    [TerminusHubReports.CampaignTrends]: TerminusHubEndpoints.CampaignTrends,
    [TerminusHubReports.CampaignSpecific]: TerminusHubEndpoints.CampaignSpecific,
    [TerminusHubReports.WebActivitiesTrending]: TerminusHubEndpoints.WebActivitiesTrending,
    [TerminusHubReports.SalesforceCompare]: TerminusHubEndpoints.SalesforceCompare,
    [TerminusHubReports.StagesSnapshot]: TerminusHubEndpoints.StagesSnapshot,
    [TerminusHubReports.CampaignAnalyticsGroups]: TerminusHubEndpoints.CampaignAnalyticsGroups,
    [TerminusHubReports.CampaignAnalyticsCampaigns]: TerminusHubEndpoints.CampaignAnalyticsCampaigns,
    [TerminusHubReports.WebActivitiesSource]: TerminusHubEndpoints.WebActivitiesSource,
    [TerminusHubReports.WebActivitiesMediums]: TerminusHubEndpoints.WebActivitiesMediums,
    [TerminusHubReports.WebActivitiesCampaign]: TerminusHubEndpoints.WebActivitiesCampaign,
    [TerminusHubReports.WebActivitiesSpecific]: TerminusHubEndpoints.WebActivitiesSpecific,
    [TerminusHubReports.CohortedWaterfall]: TerminusHubEndpoints.CohortedWaterfall,
    [TerminusHubReports.CohortedWaterfallDetails]: TerminusHubEndpoints.CohortedWaterfallDetails,
    [TerminusHubReports.ProgressionByQuarter]: TerminusHubEndpoints.ProgressionByQuarter,
    [TerminusHubReports.ProgressionDetails]: TerminusHubEndpoints.ProgressionDetails,
    [TerminusHubReports.Dashboard]: TerminusHubEndpoints.Dashboard,
    [TerminusHubReports.AccountEngagement]: TerminusHubEndpoints.AccountEngagement,
    [TerminusHubReports.CampaignScoring]: TerminusHubEndpoints.CampaignScoring,
    [TerminusHubReports.StageInfluence]: TerminusHubEndpoints.StageInfluenceCampaign,
    [TerminusHubReports.HubspotConfig]: TerminusHubEndpoints.HubspotConfig,
    [TerminusHubReports.SegmentBuilder]: TerminusHubEndpoints.SegmentBuilder,
};

// TODO: Remove to use just one environment variable
export enum TerminusSubDomains {
    Localhost = 'localhost:4500',
    LocalhostHub = 'localhost:4300',
    BrightfunnelDev = 'dev.brightfunnel.com',
    BrightfunnelProd = 'app.brightfunnel.com',
    BrightfunnelHubDev = 'dev-hub.brightfunnel.com',
    BrightfunnelHubProd = 'hub.brightfunnel.com',
    TerminusPlatformDev = 'studio.terminusplatform.ninja',
    TerminusPlatformProd = 'studio.terminusplatform.com',
    TerminusPlatformHubDev = 'measurement.terminusplatform.ninja',
    TerminusPlatformHubProd = 'measurement.terminusplatform.com',
    DynamicSubdomainPlatformDev = '{{subdomain}}.terminusplatform.ninja',
    DynamicSubdomainPlatformProd = '{{subdomain}}.terminusplatform.com'
}

// TODO: Remove to use just one environment variable
export const TERMINUS_GOTO_MAP = {
    [TerminusSubDomains.Localhost]: TerminusSubDomains.LocalhostHub,
    [TerminusSubDomains.LocalhostHub]: TerminusSubDomains.Localhost,
    [TerminusSubDomains.BrightfunnelDev]: TerminusSubDomains.BrightfunnelHubDev,
    [TerminusSubDomains.BrightfunnelProd]: TerminusSubDomains.BrightfunnelHubProd,
    [TerminusSubDomains.BrightfunnelHubDev]: TerminusSubDomains.BrightfunnelDev,
    [TerminusSubDomains.BrightfunnelHubProd]: TerminusSubDomains.BrightfunnelProd,
    [TerminusSubDomains.TerminusPlatformDev]: TerminusSubDomains.DynamicSubdomainPlatformDev,
    [TerminusSubDomains.TerminusPlatformProd]: TerminusSubDomains.DynamicSubdomainPlatformProd,
    [TerminusSubDomains.TerminusPlatformHubDev]: TerminusSubDomains.DynamicSubdomainPlatformDev,
    [TerminusSubDomains.TerminusPlatformHubProd]: TerminusSubDomains.DynamicSubdomainPlatformProd,
};
