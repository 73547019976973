import { module as angularModule } from 'angular';
import { APP_BRIGHTFUNNEL } from '../../constants';
import { AccountEngagementSrvc } from '../config/self-config/services/account-engagement-srvc';

const app = angularModule(APP_BRIGHTFUNNEL);

export class AccountEngagementCtrl {
    static $inject: string[] = [
        '$scope',
        '$http',
        '_',
        '$state',
        'accountEngagementSrvc',
        'topicsSrvc',
        'noty',
    ];

    public includeText: string;
    public token: string;
    public toggleSignalMetricTag: string;
    public search = '';

    public kpi = {
        operator: {
            operator: 'or'
        },
        campaignResponse: {
            enabled: true,
            people: 1,
            responses: 1,
            includeType: 'all',
            type: [],
            rollingTime: '90'
        },
        intent: {
            enabled: true,
            disableSwitch: false,
            topic: [],
            rollingTime: '90'
        },
        webActivity: {
            enabled: true,
            type: 'pageViews',
            pageViews: 1,
            spikeModels: [],
            rollingTime: '90'
        },
    };

    public defaults = {
        includeTypes: [
            { label: 'Include all campaign types', value: 'all' },
            { label: 'Include Some', value: 'include' },
            { label: 'Exclude Some', value: 'exclude' }
        ],
        campaignTypes: [],
        operators: [
            { label: 'All (AND)', value: 'and' },
            { label: 'Any (OR)', value: 'or' }
        ],
        activeBomboraTopics: [],
        inactiveBomboraTopics: [],
        topics: [],
        engagementTypes: [
            { label: 'Engagement Spike', value: 'spike' },
            { label: 'Page Views', value: 'pageViews' }
        ],
        spikeModels: [],
    };

    configUpdated = (configPath, configValue) => {
        this.accountEngagementSrvc.sendConfigUpdate(configPath.configType, configPath.configName, configPath.fieldType, configValue);
    };

    error = () => {
        this.$state.current.data.loading = false;
        this.noty.growl('Error loading configuration', 'error');
    };

    includeUpdated = (configPath, configValue) => {
        this.includeText = `Campaign types to ${configValue}:`;
        this.configUpdated(configPath, configValue);
    };

    getInactiveTopics = () => {
        return this.accountEngagementSrvc.getIntentTopics().then((data) => {
            data.map((topic) => {
                if (!this.defaults.activeBomboraTopics.find((item) => {
                    return item.value === topic.value;
                })) {
                    topic.type = 'Inactive Topics';
                    this.defaults.inactiveBomboraTopics.push(topic);
                }
            });
            this.token = null;
            this.populateTopicList();
            return;
        });
    };

    populateTopicList = () => {
        this.defaults.topics = [
            ...this.defaults.activeBomboraTopics,
            ...this.defaults.inactiveBomboraTopics,
        ];
    };

    isBomboraEnabled(): boolean {
        if (!this.accountEngagementSrvc.isBomboraEnabled()) {
            this.kpi.intent.enabled = false;
            this.kpi.intent.disableSwitch = true;
            return false;
        }
        return true;
    }

    initializeConfig = () => {
        this.token = null;
        this.toggleSignalMetricTag = 'accountEngagementToggleSignals';

        this.$state.current.data.loading = true;

        this.accountEngagementSrvc.getConfigs(this.kpi).then(() => {
            this.includeText = `Campaign types to ${this.kpi.campaignResponse.includeType}:`;
            this.accountEngagementSrvc.getCampaignTypes().then((types) => {
                this.defaults.campaignTypes = types;
                this.accountEngagementSrvc.getSpikeModels().then((models) => {
                    this.defaults.spikeModels = models;
                    if (this.isBomboraEnabled()) {
                        this.topicsSrvc.loadTopics(this.$scope.userData.organizationUuid).then((data) => {
                            data.topics.map((topic) => {
                                topic.type = 'Active Topics';
                                this.defaults.activeBomboraTopics.push(topic);
                            });
                            this.getInactiveTopics().catch(this.error);
                        }, this.error);
                    }
                    this.$state.current.data.loading = false;
                }, this.error);
            }, this.error);
        }, this.error);
    }

    constructor (
        public $scope,
        public $http,
        public _,
        public $state,
        public accountEngagementSrvc: AccountEngagementSrvc,
        public topicsSrvc,
        public noty,
    ) {
        this.initializeConfig();
    }
}

export const CTRL_ACCOUNT_ENGAGEMENT = 'accountEngagementCtrl';
app.controller(CTRL_ACCOUNT_ENGAGEMENT, AccountEngagementCtrl);
