/* eslint-disable no-undef */
app.controller('topRightCtrl', ['$scope', '$rootScope', '$state', 'widgets', 'utilities', '_', 'exportService', 'navigation', function ($scope, $rootScope, $state, widgets, utilities, _, exportService, navigation) {
    $scope.formatTitle = function (title) {
        if ($state.includes('app.settings')) {
            var parent = navigation.getSettingParent();
            if (parent) {
                return parent.config.data.title;
            } else {
                return title;
            }
        } else if ($state.includes('app.dashboard')) {
            return widgets.returnDashName();
        } else {
            return title;
        }
    };

    $scope.getTitleLength = function() {
        return widgets.getTitleLength();
    };

    $scope.deleteDashboard = function () {
        widgets.deleteDashboard($state.params.id);
    };

    $scope.addTile = function () {
        widgets.addTile($state.params.id);
    };

    $scope.exportTile = function (widget) {
        var individual_tile = $('.gridster-item#' + widget.id + ' .box');
        exportService.expFunc(individual_tile);
    };

    $scope.changeDashboardName = function (nv) {
        widgets.changeDashboardName(nv);
    };

    //this is for the dashboard page
    $scope.getDash = function () {
        $scope.data = {
            dashboard: widgets.getDashboard($state.params.id)
        };
        if ($scope.data.dashboard) {
            return $scope.data.dashboard.name;
        }
    };

    $scope.useNewDashboardVisual = $rootScope.userData.platform !== 'standard';

    function checkScroll() {
        var windowHeight = document.getElementById("content").clientHeight,
            scrollHeight = document.getElementById("content").scrollHeight,
            headerHeight = $('#new-top-right');

        if (headerHeight) {
            headerHeight = headerHeight.outerHeight();
        }

        if ((scrollHeight - windowHeight) <= headerHeight) {
            $rootScope.$broadcast('scrollpointOff');
        } else {
            $rootScope.$evalAsync(function () {
                $rootScope.$broadcast('scrollpointOn');
            });
        }
    }

    /* FUNCTIONS FOR EDITING TILE ON PAGE */

    $scope.updateTile = function () {
        $rootScope.$broadcast('updateTileFromPage');
    };

    $scope.resetPage = function () {
        $rootScope.$broadcast('resetPage');
    };

    //this function is used for the alert box that appears when a user is editing the tile on the page
    $scope.getDashboard = function () {
        widgets.getDashboards().then(function () {
            widgets.getTile($state.params.dashboard.split('_')[0], $state.params.dashboard.split('_')[1]).then(function (data) {
                $scope.dash = data.dashboard.name;
                $scope.tile = data.tile.name;
            });
        });
    };

    (function init() {
        $scope.$on('$windowResize', checkScroll);
        $scope.$on('checkScroll', checkScroll);
    })();

}]);

