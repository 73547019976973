import { IDirective, IScope, } from 'angular';

import * as am4charts from '@amcharts/amcharts4/charts';

import { ITrendingKpiQuery, } from '../trending-kpis-widget/component';
import { IKpiResponse, } from 'src/shared/models/scorecard/kpi-response/model';
import { TrendingKpiChartsService, SVRC_TRENDING_KPI_CHARTS, } from '../../services/kpi-charts.service';
import * as CONSTANTS from '../../constants';
import * as ComponentTemplate from './component.html';
import './component.less';

export class TrendingKpiDonutChartController {
    static $inject: string[] = [
        '$scope',
        SVRC_TRENDING_KPI_CHARTS,
    ];

    public chart: am4charts.PieChart;
    public formatAsCurrency: boolean;
    public query: ITrendingKpiQuery;
    public queryId: string;
    public responseData: IKpiResponse;
    public titleLabel: string;

    constructor (
        private $scope: IScope,
        private trendingKpiChartsService: TrendingKpiChartsService,
    ) {
        this.initialize();
    }

    private initialize(): void {
        this.queryId = this.$scope.ctrl.id;

        this.trendingKpiChartsService.changeChartContainerSize('trending-kpi-donut-chart', '50%', 'height');
        this.setupWatches();
    }

    private setupWatches(): void {
        this.setupWidgetQueryWatch();
        this.setupKPIDataWatch();
        this.setupOnDestroy();
    }

    private setupWidgetQueryWatch(): void {
        this.$scope.$watch('ctrl.widgetQuery', (value: ITrendingKpiQuery) => {
            if (value) {
                this.query = value;
                this.titleLabel = CONSTANTS.DONUT_CHART_KPI_LABEL_MAP[this.query.kpi];
                this.formatAsCurrency = CONSTANTS.KPI_TYPE_FORMAT_MAP[this.query.kpi] === CONSTANTS.KPIFormat.Currency;
                if (this.responseData) {
                    this.initializeChart();
                }
            }
        });
    }

    private setupKPIDataWatch(): void {
        this.$scope.$watch('ctrl.kpiData', (value: IKpiResponse) => {
            if (value) {
                this.responseData = value;
                if (this.query) {
                    this.initializeChart();
                }
            }
        });
    }

    private initializeChart(): void {
        this.chart = this.trendingKpiChartsService.generateDonutGraph(
            this.queryId,
            this.responseData.totalsInterval.segments,
            this.query.kpi,
        );
    }

    private setupOnDestroy() {
        this.$scope.$on('$destroy', () => {
            this.cleanupChart();
        });
    }

    private cleanupChart() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
}

export class TrendingKpiDonutChart implements IDirective {
    public restrict = 'E';
    public controller: any = TrendingKpiDonutChartController;
    public controllerAs = 'ctrl';
    public bindToController = true;
    public template: any = ComponentTemplate;
    public scope: IScope = {
        widgetQuery: '=',
        kpiData: '=',
        id: '=',
    };

    public constructor() {
        return;
    }

    public static Factory(): IDirective {
        return new TrendingKpiDonutChart();
    }
}
