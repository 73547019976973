export class ListItem {
    public constructor(private _id: string, private _label: string) {
    }

    get id(): string {
        return this._id;
    }

    get label(): string {
        return this._label;
    }
}
