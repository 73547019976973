/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
// eslint-disable-next-line no-undef
app.service('bfChart', ['$compile', '$timeout', '$q', 'Colors', function ($compile, $timeout, $q, Colors) {
    var that = this;

    this.defaults = function () {
        var defaults = {
            container: '#content',
            data: null,
            title: null,
            orientation: 'vertical',
            axis: {
                y: {
                    label: {
                        text: 'Y-axis label'
                    }
                },
                x: {
                    label: {
                        text: 'X-axis label'
                    },
                }
            },
            margin: {
                left: 48,
                top: 0,
                right: 0,
                bottom: 0
            },
            width: '100%',
            minHeight: 240,
            colors: Colors.scale('20'),
            horizontalLegendColumnWidth: 100
        };

        return defaults;
    };
    this.legendUtils = {
        legendItemHeight: 34,
        columnWidth: 0,
        rowsCount: 0,
        align: 'center',
        position: 'bottom',
        debug: false,
        shape: 'circle',
        mouseenter: function (object, model) {
            if (typeof model.legend.mouseenter === 'function') {
                model.legend.mouseenter(object, model);
            }
        },
        click: function (object, model) {
            if (!this.pin) { return; }
            if (typeof model.legend.click === 'function') {
                model.legend.click(object, model);
            }

        },
        mouseleave: function (object, model) {
            if (typeof model.legend.mouseleave === 'function') {
                model.legend.mouseleave(object, model);
            }

        },
        createPointer: function (legendItem, model, shape) {
            var legendUtils = this;
            if (shape === 'square') {
                legendItem
                    .append('rect')
                    .attr({
                        x: legendUtils.legendItemHeight / 2 - legendUtils.legendItemHeight / 8,
                        y: legendUtils.legendItemHeight / 2,
                        width: 0,
                        height: 0
                    })
                    .style({
                        fill: function (d) {
                            return model.colors(d.index);
                        }
                    })
                    .transition()
                    .delay(function (d, i) {
                        return 1000 + 50 * i;
                    })

                    .attr({
                        x: legendUtils.legendItemHeight / 4,
                        y: legendUtils.legendItemHeight / 2 - legendUtils.legendItemHeight / 8,
                        width: legendUtils.legendItemHeight / 4,
                        height: legendUtils.legendItemHeight / 4
                    });

            }
            else if (shape === 'circle' || !shape) {
                legendItem
                    .append('circle')
                    .style({
                        // eslint-disable-next-line no-unused-vars
                        fill: function (d, i) {
                            return model.colors(d.index);
                        }
                    })
                    .attr(
                        {
                            r: 0,
                            cx: 15,
                            cy: legendUtils.legendItemHeight / 2,
                            opacity: 1
                        }
                    )
                    .transition()
                    .delay(function (d, i) {
                        return 1000 + 50 * i;
                    })
                    .attr(
                        {
                            r: 4
                        }
                    );
            }
        },
        // eslint-disable-next-line no-unused-vars
        createLabel: function (legendItem, model, align) {
            var legendUtils = this;
            legendItem
                .append("text")
                .attr({
                    x: 25,
                    y: 20
                })
                .style({
                    opacity: 0
                })
                // eslint-disable-next-line no-unused-vars
                .text(function (d, i) { return d.label; })
                .each(legendUtils.wrap)
                .transition()
                .delay(function (d, i) {
                    return 500 + 50 * i;
                })
                .style(
                    {
                        opacity: 1
                    });
        },
        createRectangle: function (legendItem, model, align) {
            legendItem
                .append('rect')
                .style({
                    fill: function (d) {
                        return model.colors(d.index);
                    }
                })
                .attr({
                    class: 'legend-background-rect',
                    width: function () {
                        if (align === 'center' && that.legendUtils.position !== 'right') {
                            var bbox = this.parentNode.getBBox();
                            return bbox.width + 40;
                        }
                        else {
                            return that.legendUtils.columnWidth;
                        }
                    },
                    height: function () {
                        var bbox = this.parentNode.getBBox();
                        that.legendUtils.legendItemHeight = bbox.height + 20;
                        return bbox.height + 20;
                    }

                });
            if (legendItem.datum().wrapped) {
                addTooltip(legendItem);
            }
            function addTooltip(legendItem) {
                "use strict";
                var rect = legendItem.select('rect');
                rect
                    .attr({
                        dy: 0,
                        tooltip: function () {
                            return rect.datum().label;
                        },
                        "tooltip-placement": "top",
                        "tooltip-append-to-body": "true"
                    });
                $compile(rect.node())(that.legendUtils.scope);
            }
        },
        wrap: function () {
            var wrapped = false;
            if (that.legendUtils.align !== 'center' || that.legendUtils.position === 'right') {
                // eslint-disable-next-line no-undef
                var self = d3.select(this),
                    textLength = self.node().getComputedTextLength(),
                    text = self.text();
                while (textLength > (that.legendUtils.columnWidth - 2 * 20) && text.length > 0) {
                    wrapped = true;
                    text = text.slice(0, -1);
                    self.text(text + '…');
                    textLength = self.node().getComputedTextLength();
                }
                d3.select(this).datum(function () {
                    "use strict";
                    var datum = d3.select(this).datum();
                    datum.wrapped = wrapped;
                    return datum;
                });
            }
        }
    };

    this.newChart = function (scope, elem, model) {
        var defer = $q.defer();
        $timeout(function () {
            /*==========  setup defaults with model  ==========*/
            var defaults = that.defaults();
            angular.extend(defaults, model);
            angular.extend(model, defaults);

            /*==========  setup events  ==========*/
            that.setEvents(scope, elem, model);

            /*==========  build basic chart components that would be used in any kind of chart  ==========*/
            that.buildShell(scope, elem, model);

            that.userFunctions(scope, elem, model, false);

            defer.resolve(model);
        }, 0);
        return defer.promise;
    };

    this.userFunctions = function (scope, elem, model, resize) {
        var init = function () {
            var defer = $q.defer;
        };
        if (model.init && angular.isFunction(model.init) && !resize)
            model.init(scope, elem, model);

        if (model.update && angular.isFunction(model.update))
            model.update(scope, elem, model);

        if (!model.listening) {
            scope.$watch(function () { return model.data; }, function (nv, ov) {
                if (nv != ov && !model.ignore) {
                    if (model.beforeUpdate && angular.isFunction(model.beforeUpdate)) {
                        model.beforeUpdate(scope, elem, model);
                    }
                    if (model.update && angular.isFunction(model.update)) {
                        that.refresh(scope, elem, model);
                    }
                }

            }, true);
            model.listening = true;
        }
    };

    this.buildShell = function (scope, elem, model) {
        that.dimensions(scope, elem, model);

        //------------ add placeholder for tooltip -------------//
        var tooltip = $('<div class="tooltip" class="hidden"></div>');
        if (model.id) {
            tooltip.addClass(model.id);
        }

        $('body').append(tooltip);
        if (!model.tooltip) { model.tooltip = {}; }
        model.tooltip.elem = model.id ? d3.select('.tooltip.' + model.id) : d3.select('.tooltip');

        /*==========  put in svg elem  ==========*/
        model.svg = d3.select(elem[0])
            .append('svg')
            .attr('width', model.adjustedWidth)
            .attr('height', model.adjustedHeight);

        /*==========  put container inside of svg  ==========*/

        model.svg.append('g')
            .attr('class', 'chartArea')
            .attr('transform', 'translate(' + model.margin.left + ',' + model.margin.top + ')');

        model.chartArea = d3.select('.chartArea');

        /*==========  x-axis container  ==========*/

        model.chartArea.append('g')
            .attr('class', 'x axis')
            .attr('transform', 'translate(0,' + model.height + ')');

        model.axis.x.elem = d3.select('.x.axis');

        /*==========  y-axis container  ==========*/

        model.chartArea.append("g")
            .attr("class", "y axis");

        model.axis.y.elem = d3.select('.y.axis');

        /*==========  labels  ==========*/

        model.axis.y.label.elem = model.svg.append('text')
            .attr('class', 'y-label')
            .attr('text-anchor', 'middle')
            .attr('transform', 'translate(19,' + (model.height / 2) + ') rotate(-90)')
            .text((angular.isFunction(model.axis.y.label.text) ? model.axis.y.label.text(model) : model.axis.y.label.text));

        model.axis.x.label.elem = model.svg.append('text')
            .attr('class', 'x-label')
            .attr('text-anchor', 'middle')
            .attr('transform', 'translate(' + [model.width / 2, model.viewPort - 20] + ')')
            .text((angular.isFunction(model.axis.x.label.text) ? model.axis.x.label.text(model) : model.axis.x.label.text));

        /*==========  legend  ==========*/
        if (model.legend) {
            buildLegend(scope, model);
            /* Color scale is in model.colors */
        }
        /*==========  header  ==========*/

        if (model.title) {
            model.header = model.svg.append('text')
                .attr('class', 'chartHeader')
                .attr('text-anchor', 'middle')
                .attr('transform', 'translate(' + (model.width / 2) + ',15)')
                .text((angular.isFunction(model.title) ? model.title(model) : model.title));
        }
    };

    this.setEvents = function (scope, elem, model) {

        scope.$on('$windowResizeEnd', function () {
            resize();
        });

        scope.$on('navToggled', function () {
            resize();
        });

        scope.$on('bfFooterOpening', function () {
            resize();
        });

        scope.$on('refreshChart', function () {
            that.refresh(scope, elem, model);
        });

        scope.$on('bfFooterClosing', function () {
            $timeout(function () {
                resize();
            }, 300);
        });

        if (model.resizing && angular.isFunction(model.resizing)) {
            scope.$on('$windowResize', function () {
                model.resizing(scope, elem, model);
            });
        }

        scope.$on('$destroy', function () {
            model.tooltip.elem.remove();
        });

        function resize() {
            that.dimensions(scope, elem, model);

            if (model.resize && angular.isFunction(model.resize)) {
                model.resize(scope, elem, model);
            } else {
                that.refresh(scope, elem, model);
            }
        }
    };

    this.refresh = function (scope, elem, model) {
        elem.html('');
        that.buildShell(scope, elem, model);
        that.userFunctions(scope, elem, model, true);
    };

    this.parentWidth = function (elem, model) {
        return elem.parent().width();
    };

    this.parentHeight = function (elem) {
        return elem.height();
    };

    this.dimensions = function (scope, elem, model) {
        //scope.$apply(function(){
        model.viewPort = model.setHeight ? model.setHeight : that.viewPort(elem);

        if (model.minHeight && model.viewPort < model.minHeight) {
            model.viewPort = model.minHeight;
        }
        model.width = that.parentWidth(elem) - model.margin.left - model.margin.right;
        if (model.legend) {
            if (!model.legend.width) {
                if (model.legend.position === 'right') {
                    model.legend.width = '25%';
                }
                else if (model.legend.position === 'bottom') {
                    model.legend.width = '80%';
                }
            }
            var legendWidth;
            if (typeof model.legend.width === 'string') {
                legendWidth = (that.parentWidth(elem) / 100) * parseInt(model.legend.width);
            }
            else if (typeof model.legend.width === 'number') {
                legendWidth = model.legend.width;
            }
            if (model.legend.position === 'right') {
                model.width = model.width - legendWidth;
            }

        }
        model.height = (model.viewPort - model.margin.top - model.margin.bottom);

        model.adjustedWidth = model.width + model.margin.left + model.margin.right;
        if (model.legend) {
            if (!model.legend.width) {
                model.legend.width = '25%';
            }
            var legendWidth;
            if (typeof model.legend.width === 'string') {
                legendWidth = (that.parentWidth(elem) / 100) * parseInt(model.legend.width);
            }
            else {
                legendWidth = model.legend.width;
            }
            model.adjustedWidth = model.adjustedWidth + legendWidth;
        }

        model.adjustedHeight = model.height + model.margin.top + model.margin.bottom;
    };

    this.viewPort = function (elem) {
        var h = $(window).height(),
            y = elem.offset().top,
            footer = elem.parents('#bottom-right-bottom').find('.collapsable-footer'),
            hasfooter = footer.length ? true : false,
            windowPadding = parseInt(elem.parents('#content').css('paddingTop')),
            viewPortPadding = parseInt(elem.parents('#bottom-right').css('paddingTop')),
            nvp = parseInt(elem.parents('#bottom-right-bottom').css('paddingTop')),
            footerHeight = hasfooter ? footer.find('.collapsable-footer-toggle').outerHeight() : 0,
            bottom = windowPadding + footerHeight + nvp,
            viewPort = (h - y - bottom);
        return viewPort;
    };
    function buildLegend(scope, model) {
        var element = model.svg, options = model.legend;
        var horizontalLegendColumnWidth = options.horizontalLegendColumnWidth ? options.horizontalLegendColumnWidth : that.defaults().horizontalLegendColumnWidth;
        element.select('.legend').remove();
        var legend = element.append("g")
                .attr({
                    class: 'legend-area-wrapper',
                    transform: 'translate(0,0)'
                }),
            legendArea;
        that.legendUtils.scope = scope;
        that.legendUtils.shape = options.shape === 'square' ? 'square' : 'circle';
        that.legendUtils.position = options.position === 'right' ? 'right' : 'bottom';
        that.legendUtils.pin = !!options.pin;
        if (options.position === 'right') {
            var legendClipWrapper = legend.append('g')
                .attr({
                    class: 'legend-clip-wrapper',
                    'clip-path': 'url(#legend-clip)',
                    transform: 'translate(0,40)'
                });
            legendArea = legendClipWrapper.append('g')
                .attr({
                    class: 'legend-area',
                    transform: 'translate(0,0)'
                });

            legend.call(buildVerticalLegend, element, options);
        }
        else if (options.position === 'bottom') {
            that.legendUtils.align = options.align || 'center';
            legendArea = legend.append('g')
                .attr({
                    class: 'legend-area',
                    transform: 'translate(0,0)'
                });

            legend.call(buildHorizontalLegend, element, options);
        }
        function buildHorizontalLegend(legendElement, parentElement, options) {
            var legendItems = options.series();
            legendItems.map(function (item, i) {
                legendItems[i] = { label: item, index: i };
            });
            var labelsElements = [];
            var totalLabel = legendItems.join('');
            var totalLabelElement = legendArea.append('text').text(totalLabel);
            var totalLabelsWidth = totalLabelElement.node().getBBox().width + 40 * legendItems.length;
            totalLabelElement.remove();
            var parentWidth = parentElement.node().clientWidth,
                nextHorizontalOffset = 0,
                thisHorizontalOffset = 0,
                legendRow = 0,
                currentRowWidth = 0,
                legendItemRow;
            that.legendUtils.rowsCount = parseInt(totalLabelsWidth / parentWidth) + 1;
            if (options.width) {
                var percentage = parseInt(options.width);
                parentWidth = parentElement.node().clientWidth / 100 * percentage;
            }
            legend.attr({
                transform: function () {
                    return 'translate(' + [(parentElement.node().clientWidth - parentElement.node().clientWidth / 100 * percentage) / 2, 9] + ')';
                }
            });
            //var largestElementWidth = _getLargestElementWidth();
            that.legendUtils.columnWidth = _getColumnWidthFromSettings();

            var legendItem = legendArea
                .selectAll('g')
                .data(legendItems)
                .enter()
                .append(createLegendItem);
            legendItem.remove();

            var legendItemInRow = legendArea
                .selectAll('g')
                .data(labelsElements)
                .enter()
                .append(createLegendItemInRows);

            function _getLargestElementWidth() {
                var maxWidth = 0;
                var estimatorWrapper = model.svg.append('g')
                    .attr({ class: 'labels-estimate-wrapper' })
                    .each(function () {
                    });
                estimatorWrapper.selectAll('.legend-label')
                    .data(legendItems)
                    .enter()
                    .append('text')
                    .text(function (d, i) { return d.label; })
                    .each(function () {
                        var w = this.getBBox().width;
                        if (maxWidth < w) { maxWidth = w; }
                    });
                estimatorWrapper.remove();

                return maxWidth;
            }

            function _getColumnWidthFromSettings() {
                if (options.columns && that.legendUtils.align === 'columns') {
                    columnWidth = parentWidth / options.columns;
                }
                else if (that.legendUtils.align === 'columns') {
                    var columnsNumber = Math.floor(parentWidth / horizontalLegendColumnWidth);
                    var columnWidth = parentWidth / columnsNumber;
                }
                else if (that.legendUtils.align === 'center') {
                    // calculate widths dynamically for each legend item
                    return 0;

                }
                return columnWidth;
            }

            function createLegendItem(d, i) {
                var legendItem = d3.select(this)
                    .append('g')
                    .attr({
                        class: 'legend-element'
                    })
                    .datum(d);
                legendItem.call(that.legendUtils.createLabel.bind(that.legendUtils), model, that.legendUtils.align);
                legendItem.call(that.legendUtils.createPointer.bind(that.legendUtils), model, that.legendUtils.shape);
                legendItem.call(that.legendUtils.createRectangle.bind(that.legendUtils), model, that.legendUtils.align);
                legendItem
                    .attr({
                        transform: function (d, i) {
                            var thisWidth = that.legendUtils.columnWidth > 0 ? that.legendUtils.columnWidth : d3.select(this).node().getBBox().width;
                            thisHorizontalOffset = nextHorizontalOffset;
                            if (currentRowWidth + thisWidth <= parentWidth) {
                                currentRowWidth += thisWidth;
                                nextHorizontalOffset = thisWidth + thisHorizontalOffset;
                            }
                            else {
                                thisHorizontalOffset = 0;
                                nextHorizontalOffset = thisWidth;
                                currentRowWidth = thisWidth;
                                legendRow += 1;
                            }


                            return 'translate(' + [thisHorizontalOffset, that.legendUtils.legendItemHeight * i] + ')';
                        }
                    });
                if (!labelsElements[legendRow]) { labelsElements.push([]); }
                labelsElements[legendRow].push({
                    label: d.label,
                    index: d.index,
                    x: thisHorizontalOffset,
                    width: legendItem.node().getBBox().width

                });
                that.legendUtils.rowsCount = legendRow + 1;
                return legendItem.node();
            }

            function createLegendItemInRows(d, i) {
                var legendItemRow = d3.select(this).append('g')
                    .attr({
                        class: 'legend-row'
                    });
                var legendItem = legendItemRow
                    .selectAll('.legend-element')
                    .data(d)
                    .enter()
                    .append('g')
                    .attr({
                        class: 'legend-element'
                    });
                legendItem.each(function () {
                    "use strict";
                    d3.select(this).call(that.legendUtils.createLabel.bind(that.legendUtils), model, that.legendUtils.align);
                    d3.select(this).call(that.legendUtils.createPointer.bind(that.legendUtils), model, that.legendUtils.shape);
                    d3.select(this).call(that.legendUtils.createRectangle.bind(that.legendUtils), model, that.legendUtils.align);

                });

                legendItem
                    .attr({
                        transform: function (d, i) {
                            return 'translate(' + [d.x, 0] + ')';
                        }
                    });
                legendItem
                    .on('mouseenter', function () { that.legendUtils.mouseenter(this, model); })
                    .on('click', function () { that.legendUtils.click(this, model); })
                    .on('mouseleave', function () { that.legendUtils.mouseleave(this, model); });
                legendItemRow.attr({
                    transform: function () {
                        if (that.legendUtils.align === 'center') {
                            return 'translate(' + [(parentWidth - this.getBBox().width) / 2, that.legendUtils.legendItemHeight * i] + ')';
                        }
                        else {
                            return 'translate(' + [0, that.legendUtils.legendItemHeight * i] + ')';
                        }
                    }
                });
                return legendItemRow.node();
            }

            legend.attr({
                transform: function () {
                    var horizontalOffset = (model.svg.node().getBBox().width - d3.select(this).node().getBBox().width);
                    return 'translate(' + [horizontalOffset, parseInt(model.svg.attr('height')) + 20] + ')';
                }
            });
            d3.select(model.svg.node().parentNode).style({
                height: function () {
                    return ((parseInt(model.svg.attr('height')) + parseInt(legend.node().getBBox().height) + 20)) + 'px';
                }
            });
        }

        function buildVerticalLegend(legendElement, parentElement, options) {
            var legendItems = options.series();
            legendItems.map(function (item, i) {
                legendItems[i] = { label: item, index: i };
            });
            var itemsCount = legendItems.length;
            var defs,
                containerWidth = parentElement.node().parentNode.clientWidth,
                containerHeight = parentElement.node().parentNode.clientHeight,
                legendElementHeight = 30,
                paging = Math.floor(containerHeight / legendElementHeight),
                legendWidth,
                shift = 0;

            d3.select('.legend-area-controls').remove();

            if (typeof model.legend.width === 'string') {
                legendWidth = (containerWidth / 100) * parseInt(model.legend.width);
                itemsCount > paging ? that.legendUtils.columnWidth = legendWidth / 2 : that.legendUtils.columnWidth = legendWidth / 1;
            }
            else {
                legendWidth = model.legend.width;
            }

            legendElement
                .attr('transform', function () {
                    return 'translate(' + [containerWidth - legendWidth, 0] + ')';
                });

            var legendAreaControls = this.append('g')
                .attr({
                    class: 'legend-area-controls'
                })
                .style(
                    {
                        display: 'block'
                    }
                );
            legendAreaControls.attr({
                transform: 'translate(' + [legendWidth / 2, 0] + ')'
            });

            var legendItemsInColumn = itemsCount > paging * 2 ? Math.floor(itemsCount / 2) : paging - 1;
            itemsCount <= paging * 2
                ? legendAreaControls.style({ display: 'none' })
                : legendAreaControls.style({ display: 'block' });

            // if (d3.select('defs').empty()) {
            defs = d3.select(legendElement.node().parentNode).insert("defs");
            // }

            d3.select('#legend-clip').remove();
            var columns = itemsCount > paging ? 2 : 1,
                legendArea = legendElement.select('.legend-area'),
                legendClip = defs.append("clipPath")
                    .attr({ id: 'legend-clip' })
                    .append("rect")
                    .attr({
                        id: 'clip-rect',
                        x: 0,
                        y: 0,
                        width: legendWidth,
                        height: containerHeight - 80
                    });

            legendArea.attr({ transform: 'translate(0, 0)' });

            var legendAreaUpButton = legendAreaControls.append('g').attr({ class: 'up-button' });
            legendAreaUpButton.attr({ transform: 'translate(' + [0, 20] + ')' });
            legendAreaUpButton.append('circle').attr({
                cx: 0,
                cy: 0,
                r: 10
            });
            legendAreaUpButton.append('polygon')
                .attr(
                    {
                        fill: '#fff',
                        points: '-0.002,4.503 8.094,4.503 4.776,1.198 5.481,0.496 10.002,5 5.481,9.504 4.776,8.802 8.094,5.497 -0.002,5.497',
                        transform: 'translate(-5,5) rotate(-90)'

                    }
                );

            var legendAreaDownButton = legendAreaControls.append('g').attr({ class: 'down-button' });
            legendAreaDownButton.attr({ transform: 'translate(' + [0, containerHeight - 20] + ')' });
            legendAreaDownButton.append('circle').attr({
                cx: 0,
                cy: 0,
                r: 10
            });
            legendAreaDownButton.append('polygon')
                .attr(
                    {
                        fill: '#fff',
                        points: '-0.002,4.503 8.094,4.503 4.776,1.198 5.481,0.496 10.002,5 5.481,9.504 4.776,8.802 8.094,5.497 -0.002,5.497',
                        transform: 'translate(5,-5) rotate(90)'

                    }
                );

            legendAreaDownButton
                .on('click', _scroll)
                .on('mouseenter', function () {
                    d3.select(this).select('circle')
                        .transition()
                        .duration(100)
                        .attr({ r: 12 });
                })
                .on('mouseleave', function () {
                    d3.select(this).select('circle')
                        .transition()
                        .duration(100)
                        .attr({ r: 10 });

                });
            legendAreaUpButton
                .on('click', _scroll)
                .on('mouseenter', function () {
                    d3.select(this).select('circle')
                        .transition()
                        .duration(100)
                        .attr({ r: 12 });
                })
                .on('mouseleave', function () {
                    d3.select(this).select('circle')
                        .transition()
                        .duration(100)
                        .attr({ r: 10 });

                });

            legendArea.selectAll('.legend-element').remove();
            var legendItem = legendArea
                .selectAll('.legend-element')
                .data(legendItems)
                .enter()
                .append('g')
                .attr('class', 'legend-element')
                .attr('element-id', function (d, i) { return d.id; })
                .attr('transform', function (d, i) {
                    var horizontalShift = 0,
                        verticalShift = 0;
                    if (columns == 2 && i > legendItemsInColumn) {
                        horizontalShift = that.legendUtils.columnWidth;
                        verticalShift = (legendItemsInColumn + 1) * legendElementHeight;
                    }
                    return 'translate(' + [horizontalShift, legendElementHeight * i - verticalShift] + ')';

                })
                .on('mouseenter', function () { that.legendUtils.mouseenter(this, model); })
                .on('click', function () { that.legendUtils.click(this, model); })
                .on('mouseleave', function () { that.legendUtils.mouseleave(this, model); });

            function _scroll() {
                var button = this;
                legendArea
                    .transition()
                    .duration(200)
                    .attr({
                        transform: function () {
                            var maxShift = (legendItemsInColumn * legendElementHeight - legendClip.attr('height')) / legendElementHeight + 1;
                            d3.select(button).classed('up-button') ? shift++ : shift--;
                            if (maxShift <= (shift + 1) * -1) {
                                shift = -maxShift;
                            }
                            if (-shift <= 0) {
                                shift = 0;
                            }
                            var y = _getXYFromTranslate(d3.select(this).attr('transform'))[1];
                            return 'translate(' + [0, shift * legendElementHeight] + ')';
                        }
                    });
            }
            legendItem.each(function () {
                "use strict";
                d3.select(this).call(that.legendUtils.createLabel.bind(that.legendUtils), model, that.legendUtils.align);
                d3.select(this).call(that.legendUtils.createPointer.bind(that.legendUtils), model, that.legendUtils.shape);
                d3.select(this).call(that.legendUtils.createRectangle.bind(that.legendUtils), model, that.legendUtils.align);
            });

            function _mouseenter(e) {
                if (typeof model.legend.mouseenter === 'function') {
                    model.legend.mouseenter(e, d3.select(this).datum(), model);
                }
            }

            function _click(e) {
                if (!options.pin) { return; }
                if (typeof model.legend.click === 'function') {
                    model.legend.click(this, d3.select(this).datum(), model);
                }
            }

            function _mouseleave(e) {
                if (typeof model.legend.mouseleave === 'function') {
                    model.legend.mouseleave(this, d3.select(this).datum(), model);
                }
            }

            function _getXYFromTranslate(translateString) {
                var split = translateString.split(",");
                var x = split[0] ? ~~split[0].split("(")[1] : 0;
                var y = split[1] ? ~~split[1].split(")")[0] : 0;
                return [x, y];
            }
        }
    }
}]);
