app.controller("campaignPerformanceListWidgetCtrl", [
    "$scope",
    "api",
    "utilities",
    "$filter",
    "$rootScope",
    "models",
    "$parse",
    "_",
    "campaign",
    "revenueWaterfall",
    "$templateCache",
    "$compile",
    "$timeout",
    "cohorts",
    "campaignPerformance",
    "$state",
    "personaDefaults",
    "widgets",
    'influenceTypes',
    function (
        $scope,
        api,
        utilities,
        $filter,
        $rootScope,
        models,
        $parse,
        _,
        campaign,
        revenueWaterfall,
        $templateCache,
        $compile,
        $timeout,
        cohorts,
        campaignPerformance,
        $state,
        personaDefaults,
        widgets,
        influenceTypes
    ) {
        var $tile = $scope.$tile,
            dataCall;

        $scope.useNewInfluenceType = influenceTypes.useNewInfluenceType;

        function costColumnExplanation() {
            var prefix = "ranged." + $scope.widget.query.model + ".",
                identifiers = ["cpl", "cpo", "cpd"];

            identifiers.map(function (col) {
                angular.forEach($scope.data.fields, function (field) {
                    if (_.includes(field, prefix + col)) {
                        field.label = field.label.concat(" (All Time Cost)");
                    }
                });
            });
        }

        //returns lead touches, unique leads touched (for multi), & unique accounts
        function generateUngatedFields() {
            return $filter("filter")($scope.data.fields, function (field) {
                return field.ungatedTile;
            });
        }

        this.getData = function () {
            getDataWithFilter($scope.widget.query.gf);
        };

        function getDataWithFilter(groupFilter) {
            if (dataCall) {
                dataCall.abort();
            }

            //@nlepisto - workaround since I removed model from unique opptys and unique deals
            //example: ranged.even.opptys_unique is now just ranged.opptys_unique
            if ($scope.widget.query.field && $scope.widget.query.field.indexOf("opptys_unique") >= 0) {
                $scope.widget.query.field = "ranged.opptys_unique";
            }
            if ($scope.widget.query.field && $scope.widget.query.field.indexOf("deals_unique") >= 0) {
                $scope.widget.query.field = "ranged.deals_unique";
            }

            $scope.data.fieldInfo = _.find($scope.data.fields, {
                field: $scope.widget.query.field
            });

            var url = $scope.widget.webActivity ? "channel_performance" : "campaign_performance";
            // below if is whether or not you need the secondary call
            if ($scope.data.fieldInfo.lazy_load && !($scope.data.fieldInfo.lazy_group_only && $scope.widget.query.camp == "campaigns") && !$scope.data.fieldInfo.ignoreLazy) {
                //campaign_perf_stages for lazy loaded stage columns, campaign_performance for everything else (even lazy_group_only columns), don't make call for cost columns
                url = $scope.widget.webActivity ? $scope.data.fieldInfo.webTrackInfoUrl : $scope.data.fieldInfo.url;
            }

            (dataCall = api.getter({
                url,
                params: $scope.widget.query,
                paramsNotToUpdate: 'all',
                skipFilters: true,
            })).then(
                function (data) {
                    var formattedData = widgets.formatWidgetListData(data, $scope.data.fieldInfo, $scope.widget.query.camp, $scope, groupFilter, $scope.widget.query.model);
                    $scope.sortedData = widgets.sortListData(formattedData, "value");
                    $scope.data.data = widgets.numberOfTopNResults($scope.sortedData, $scope.widget);
                    $scope.data.campaignGroups = _.uniq(
                        _.map(_.values(data.data.groups), function (o) {
                            return _.values(o)[0].revenue_group;
                        })
                    ); //this gets you the campaign groups to filter on the campaigns tile and channel assets tile

                    //this gets the correct total value - I.E. if we're dealing with unique datasets or total influenced datasets, we use data.data.total.column, otherwise we sum based on the data
                    if (($scope.widget.query.field.indexOf("total_influenced_") >= 0 || $scope.widget.query.field.indexOf("_unique") >= 0) && data.data.total) {
                        _.forOwn(data.data.total, function (value, key) {
                            //this is the case where a total is based on the model
                            if (key == $scope.widget.query.model) {
                                _.forOwn(data.data.total[key], function (v, k) {
                                    if ($scope.widget.query.field.indexOf(k) >= 0) {
                                        $scope.data.total = v;
                                        return false;
                                    }
                                });
                            }

                            //this is the case where a total is model agnostic
                            if ($scope.widget.query.field.indexOf(key) >= 0 && $scope.widget.query.model.indexOf(key) == -1) {
                                $scope.data.total = value;
                                return false;
                            }
                        });
                    } else {
                        $scope.data.total = _.sumBy($scope.data.data, function (o) {
                            return o.value;
                        });
                    }

                    $scope.widget.data.lastQuery = angular.copy($scope.widget.query);
                    $tile.loading(100);
                },
                function (data) {
                    if (!data.aborted) {
                        $tile.failedLoading();
                    }
                }
            ).finally(
                function () {
                    if ($tile.isLoading()) {
                        $tile.endLoading();
                    }
                    $scope.$emit("widgetLoadingDone");
                }
            );
        }

        $scope.modelChanged = function () {
            var orgType = $scope.platform;

            $scope.data.fields = _.filter(campaignPerformance.headers($scope, $state, $scope.widget.webActivity), function (field) {
                return (_.contains(field.orgTypes, orgType) && ((field.field.indexOf($scope.widget.query.model + ".") > -1 && !field.noTile) || (field.model_field_override && _.includes(field.model, $scope.widget.query.model))));
            });
            if ($scope.widget.key == "ungatedCampaigns") {
                $scope.data.fields = generateUngatedFields();
                _.forEach($scope.data.fields, function (fld) {
                    if (fld.ungatedLabel) {
                        fld.label = fld.ungatedLabel;
                    } //change lead touches to page visits
                });
            }
            if (!$scope.widget.webActivity && ($scope.widget.query.model == "sourced" || $scope.widget.query.model == "last")) {
                costColumnExplanation();
            }

            if (!$scope.widget.query.field || !_.find($scope.data.fields, {
                field: $scope.widget.query.field
            })
            ) {
                var f = _.find($scope.data.fields, {
                    is_default: true
                });

                if (f) {
                    $scope.widget.query.field = f.field;
                }
            }
        };

        $scope.useTypeFilteredAttr = influenceTypes.useTypeFilteredAttr;

        $scope.typeAttrModelFilter = influenceTypes.typeAttrModelFilter;

        (function init(ctrl) {
            $tile.startLoading();
            $tile.loading(10);
            $scope.platform = $rootScope.userData.platform;
            $scope.data = {
                models: campaignPerformance.getAttrModels(),
                dataSets: campaignPerformance.dataSets,
                cohorts: utilities.formatCohorts(cohorts, ["all", "ago", "year", "quarter", "custom", "toDate", "lastFull"]),
                influence: influenceTypes.typeMappings(),
            };

            if ($scope.widget.webActivity) {
                $scope.data.dataSets = _.filter($scope.data.dataSets, function (o) {
                    return o.key !== "campaignCreatedDate";
                });
            }

            const params = _.defaults($scope.widget.query, personaDefaults.getQueryDefinitions($scope.widget).query);
            $scope.widget.query = widgets.addFilters(params, $scope.widget.data.filters, $scope.widget.data.accountLists);
            $scope._ = _;

            $scope.widget.data = _.defaults($scope.widget.data, {});

            if ($scope.widget.query.camp == "group") {
                $scope.widget.query.camp = "groups"; //deals with legacy data. should remove in future
            }

            if (_.includes($scope.widget.state, "app.analyze.campaigns.listAnalysis.campaigns") || _.includes($scope.widget.state, "app.analyze.webTracking.webActivity.channelAssets")) {
                $scope.widget.query.camp = "campaigns";
            }

            if (
                !_.isDefined(
                    _.find($scope.data.models, {
                        key: $scope.widget.query.model
                    })
                )
            ) {
                $scope.widget.query.model = "even";
            }

            if ($scope.widget.settings.campaign_group) {
                $scope.widget.query.groupName = "ungated web touch";
                $scope.widget.query.allWebActivity = true;
            }

            influenceTypes.setDefaultInfluenceType($scope.widget, influenceTypes.useNewInfluenceType);

            $scope.$watch("widget.query.model", $scope.modelChanged);
            if (!$scope.widget.query.field) {
                $scope.modelChanged();
            } else {
                var orgType = $scope.platform;

                //clean this up later
                $scope.data.fields = _.filter(campaignPerformance.headers($scope, $state, $scope.widget.webActivity), function (field) {
                    return ((field.field.indexOf($scope.widget.query.model + ".") > -1 && !field.noTile) || (field.model_field_override && _.includes(field.model, $scope.widget.query.model)) && _.contains(field.orgTypes, orgType));
                });
                if ($scope.widget.key == "ungatedCampaigns") {
                    $scope.data.fields = generateUngatedFields();
                    _.forEach($scope.data.fields, function (fld) {
                        if (fld.ungatedLabel) {
                            fld.label = fld.ungatedLabel;
                        } //change lead touches to page visits
                    });
                }
                if (!$scope.widget.webActivity && ($scope.widget.query.model == "sourced" || $scope.widget.query.model == "last")) {
                    costColumnExplanation();
                }
                $scope.data.field = $scope.widget.query.field;
            }

            $tile.listeners.push(
                $scope.$on("widgetUpdate", function () {
                    if (!angular.equals(angular.copy($scope.widget.query), $scope.widget.data.lastQuery)) {
                        $tile.startLoading();
                        $tile.loading(10);
                        ctrl.getData($scope.widget.query.gf);
                    }
                })
            );

            $scope.$on("updateListResults", function () {
                $scope.data.data = widgets.numberOfTopNResults($scope.sortedData, $scope.widget);
            });

            $scope.$on("$destroy", function () {
                if (dataCall) { dataCall.abort(); }
            });
        })(this);
    }
]);
