import * as COMPONENT_HTML from './component.html';
import {IDirective, IScope} from 'angular';

import {OrganizationElements, OrgConfigElement} from '../../services/interfaces';

class OrganizationSelectorCtrl {
    static $inject: Array<string> = ['$scope'];

    private orgsToSelect: OrganizationElements;
    private orgSelected: OrgConfigElement;
    private orgList: OrgConfigElement[];
    private activeOrgsOnly: boolean;

    public constructor(private $scope: IScope) {
        this.orgSelected = null;
        this.activeOrgsOnly = true;
        this.orgList = null;

        this.$scope.$watch('$ctrl.orgs', (newVal: OrganizationElements) => {
            this.orgsToSelect = newVal;
            this.updateOrgs();
            if (this.orgList) {
                this.orgSelected = this.orgList[0];
            }
        });
    }

    private updateOrgs(): void {
        if (this.orgsToSelect) {
            if (this.activeOrgsOnly) {
                this.orgList = this.orgsToSelect.orgsActive;
            } else {
                this.orgList = this.orgsToSelect.orgsAll;
            }
        }
    }

    public toggleActiveOrgs(): void {
        this.activeOrgsOnly = !this.activeOrgsOnly;
        this.updateOrgs();
    }

    public change(param: OrgConfigElement): void {
        this.$scope.$ctrl.changeFn()(param);
    }
}

export class OrganizationSelector implements IDirective {
    public restrict = 'E';
    public controller: any = OrganizationSelectorCtrl;
    public controllerAs = '$ctrl';
    public bindToController = true;
    public template: any = COMPONENT_HTML;
    public scope: IScope = {
        orgLabel: '=',
        activeOrgsLabel: '=',
        orgs: '=',
        changeFn: '&'
    };

    public constructor() {
        return;
    }

    public static Factory(): IDirective {
        return new OrganizationSelector();
    }
}

