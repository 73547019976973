app.controller('cacheConfigCtrl',
    ['$scope', '$http', '$state', 'noty', 'internalConfigSrvc',
        function ($scope, $http, $state, noty, internalConfigSrvc) {

            function refresh_organizations() {
                internalConfigSrvc.getInternalConfig().success(function (data) {
                    var active = [];

                    data.orgs.forEach(function (item) {
                        if (item.active) {
                            active.push(item);
                        }
                    });
                    $scope.$parent.configs.orgs = [{id: 0, uuid: "0", name: "--Select an org--"}].concat(active);
                    $scope.$parent.configs.orgsActive = [{id: 0, uuid: "0", name: "--Select an org--"}].concat(active);
                    $scope.$parent.configs.orgsAll = [{id: 0, uuid: "0", name: "--Select an org--"}].concat(data.orgs);
                });
            }

            $scope.flush_cache = function (cache) {
                $state.current.data.loading = true;
                let params = `orgId=${$scope.query.orgId}`;

                $http.post("admin/" + cache, params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).success(function (data) {
                    noty.growl(data, 'success');
                    // eslint-disable-next-line no-unused-vars
                }).error(function (data, status) {
                    noty.growl(data, 'error');
                }).finally(function () {
                    $state.current.data.loading = false;
                });
            };

            (function init() {

                $scope.options = {
                    cacheTypes: [
                        {display: "Reload All Org Cache", url: "load_cache_for_one_org"},
                        {display: "Flush All Org Cache", url: "flush_org_cache"},
                        {display: "Flush Configuration Cache", url: "flush_configuration_cache"},
                        {display: "Flush Campaign Performance Cache", url: "flush_campaign_performance_cache"},
                        {display: "Flush Campaign Trending Cache", url: "flush_campaign_trending_cache"},
                        {display: "Flush Account List Cache", url: "flush_account_list_cache"},
                        {display: "Flush Revenue Waterfall Cache", url: "flush_rev_wf_cache"},
                        {display: "Flush Historical Averages Cache", url: "flush_hist_averages_cache"}
                    ]
                };

                $scope.query = {orgId: 0};

                refresh_organizations();
            })();
        }]);
