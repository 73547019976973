app.controller('revWaterfallTrendingCtrl', ['$scope', 'api', '$q', '$state', '$filter', 'utilities', '$rootScope', '_', 'revenueWaterfall', 'terminusHubService', function ($scope, api, $q, $state, $filter, utilities, $rootScope, _, revenueWaterfall, terminusHubService) {
    var dataCall;

    $scope.sort = _.curry(function (i, value) {
        return value.conversion[i].count;
    });

    $scope.syncCampaigns = function () {
        //------------ make sure you don't have a mismatch of campaigns to campaign groups -------------//
        if ($scope.query.campaignGroup && $scope.query.campaignGroup.length) {
            angular.forEach($scope.query.campaign, function (campaign, i) {
                var camp = angular.findWhere($scope.data.campaigns, { campaign_id: campaign });
                if (!_.contains($scope.query.campaignGroup, camp.revenue_group)) {
                    $scope.query.campaign.splice(i, 1);
                }
            });
        }
    };

    $scope.exportField = function (row, i) {
        return row.conversion[i].count;
    };

    function convertRams(rams) {
        if (rams.campaignId && rams.campaignId.length) {
            delete rams.campaignGroup;
            if (_.isArray(rams.campaignId)) {
                rams.campaignId.forEach(function (camp, index) {
                    if (camp.id && camp.name) {
                        $scope.data.filteredCampaigns.push(camp.name);
                        rams.campaignId[index] = camp.id;
                    }
                });
            }
        }
    }

    // eslint-disable-next-line no-unused-vars
    $scope.getTrend = function (detail) {
        if (dataCall) { dataCall.abort(); }

        /*==========  now get the initial state data and calculate totals  ==========*/
        $state.current.data.loading = true;

        var params = angular.copy($scope.query);
        convertRams(params);

        (dataCall = api.get('lead_conversion', params)).then(function (data) {
            angular.forEach(data.data, function (row, i) {
                createQuarters();
                var cohorts = utilities.createMonthCohorts(row.cohort, $scope.data.limit.length - 1);
                var cohortObj = _.find($scope.data.timePeriods, { key: row.cohort });
                var fullConversions = [];
                var index = 0;
                angular.forEach(cohorts, function (cohort) {
                    var oldCohort = row.conversion[index];
                    if (oldCohort && oldCohort.quarter == cohort) {
                        fullConversions.push(oldCohort);
                        index++;
                    } else {
                        fullConversions.push({ quarter: cohort, count: 0 });
                    }
                });
                row.conversion = fullConversions;
                angular.forEach(row.conversion, function (conversion, ii) {
                    if (i === 0 && $scope.data.limit[ii].total > 0) {
                        $scope.data.limit[ii].total = 0;
                    }
                    $scope.data.limit[ii].total = $scope.data.limit[ii].total + conversion.count;
                });
                row.total = utilities.sumObjects(row.conversion, null, ['quarter']);
                if (cohortObj && cohortObj.start_date) {
                    row.timestamp = _.clone(cohortObj.start_date);
                }
            });
            /*==========  format data for the chart  ==========*/

            $scope.chart.minHeight = (40 * (1 + $scope.query.quarters)) + $scope.chart.margin.top + $scope.chart.margin.bottom;

            //$scope.chart.title = 'Which Quarter ' + $scope.query.cohort + ' "'+ $scope.query.startStage + '" Leads Converted to "'+ $scope.query.endStage + '" Leads';
            $scope.chart.data = $scope.data.limit.map(function (v, i) {
                // eslint-disable-next-line no-unused-vars
                return data.data.map(function (vc, ii) {
                    return {
                        total: vc.lead_total,
                        count: vc.conversion[i].count,
                        y: Number($filter('number')(((vc.conversion[i].count / vc.lead_total) * 100), 2)),
                        x: vc.conversion[i].quarter
                    };
                });
            });

            $scope.data.data = data.data;
            $state.current.data.loading = false;
        });
    };

    $scope.disabledChoices = function ($item, $model, direction) {
        // eslint-disable-next-line no-unused-vars
        var reduce = $filter('filter')($scope.data.stages, function (s, i) {
            if (direction == 'end') {
                return s.sequence <= $item.sequence;
            } else {
                return s.sequence >= $item.sequence;
            }
        });

        $scope.data.disabled[direction] = reduce;
    };

    $scope.disabledCheck = function (direction, stage) {
        if (stage && $scope.data.disabled[direction]) {
            return _.contains($scope.data.disabled[direction], stage);
        } else {
            return false;
        }
    };

    $scope.highlight = function (r, i) {
        // var ri = _.findIndex($scope.data.data, function(sr) {
        //         return sr.cohort == r.cohort;
        //     }),
        //     ele = $('stacked-bar').find('.chartArea .barbox').eq(i).find('.bar').eq(ri);
        // d3.select(ele[0]).each(function(d, i){
        //     d3.select(ele[0]).on('mouseover')(d);
        // });
        if ($scope.data.chartInstance) {
            $scope.data.chartInstance.tooltip.show({ x: (i + 1) });
        }
    };

    $scope.unhighlight = function (ri, i) {
        var ele = $('stacked-bar').find('.chartArea .barbox').eq(i).find('.bar').eq(ri);
        // eslint-disable-next-line no-unused-vars
        d3.select(ele[0]).each(function (d, i) {
            d3.select(ele[0]).on('mouseout')(d);
        });
    };

    $scope.exportData = function () {
        $state.current.data.loading = true;

        var data = {
            title: 'Lead Conversion Trend',
            // eslint-disable-next-line no-unused-vars
            headers: $scope.data.limit.map(function (h, i) {
                return h.header;
            }),
            rows: []
        };

        data.headers.unshift('Created');

        // eslint-disable-next-line no-unused-vars
        angular.forEach($scope.data.data, function (row, i) {
            // eslint-disable-next-line no-unused-vars
            var r = row.converstion.map(function (d, i) {
                return String(d.count);
            });

            r.unshift(row.cohort);
            data.rows.push(r);
        });

        $state.current.data.loading = false;
        return [data];
    };

    $scope.reduceCampaigns = function (groups, campaigns) {
        return groups && groups.length ? $filter('filter')(campaigns, function (campaign) {
            return _.contains(groups, campaign.revenue_group);
        }) : campaigns;
    };

    $scope.searchCampaigns = revenueWaterfall.searchCampaigns;

    $scope.detailLink = function (cohort, quarter) {
        var params = angular.copy($scope.query);
        params.quarter = quarter;
        params.cohort = cohort;
        // added these encodeURI here b/c some of the stages (eg. org46) have % in them. probably better way to do this in the shref
        // I dont know where the shref function is defined. Looks like utilities but I Dont see it in there????
        // so I just hacked it here for a quick fix. this would be good for a FE unit test once fixed in shref
        params.startStage = encodeURIComponent(params.startStage);
        params.endStage = encodeURIComponent(params.endStage);
        convertRams(params);
        const isEnabled = terminusHubService.isFeatureEnabled('feature-progression_details-migrated');
        return isEnabled
            ? terminusHubService.getTerminusHubUrlByReportName('app.discover.stageProgression.trendingDetails') +
                '?' + Object.keys(params).reduce((prev, key) => params[key] ? prev.concat(key + '=' + params[key]) : prev, []).join('&')
            : $scope.shref('app.discover.stageProgression.trendingDetails', params);
    };

    $scope.$on('filtersChanged', function () {
        $scope.getTrend();
    });

    $scope.$on('$destroy', function () {
        if (dataCall) { dataCall.abort(); }
    });

    function createQuarters() {
        $scope.data.limit = [{ header: 'Same Quarter', total: 0 }];
        for (var i = 1; i < $scope.query.quarters; i++) {
            $scope.data.limit.push({ header: 'Q+' + i, total: 0 });
        }
    }

    (function init() {
        $state.current.data.loading = true;

        $scope.query = angular.copy($state.params);

        $scope.data = {
            data: null,
            disabled: {
                start: [],
                end: []
            },
            timePeriods: utilities.formatCohorts($rootScope.rawCohorts, ['quarter']),
            cohorts: null,
            stages: null,
            limit: [{ header: 'Same Quarter', total: 0 }],
            campaigns: []
        };

        $scope.query.cohort = $scope.rawCohorts.quarter.current;

        $scope.data.stages = $scope.wfStages.filter(function (s) {
            return s.type != null && s.type.indexOf("funnel") > -1;
        });

        $scope.data.campaignGroups = _.without($rootScope.revenueGroups, "", null);

        $scope.data.filteredCampaigns = [];

        $scope.query.quarters = $scope.rawCohorts.quarter.past.length > 6 ? 4 : $scope.rawCohorts.quarter.past.length;
        $scope.data.quarters = [];
        for (var i = 1; i < $scope.rawCohorts.quarter.past.length; i++) {
            $scope.data.quarters.push(i);
        }
        createQuarters();

        $scope.chart = {
            id: 'cr',
            data: $scope.data ? $scope.data.data : null,
            title: 'Timing of Lead Conversion By Quarter',
            legend: {
                position: 'right',
                width: '20%',
                //horizontalLegendColumnWidth: 200,
                //columns: 5,
                //shape: 'square',
                pin: true,
                // eslint-disable-next-line no-unused-vars
                series: function (chart) {
                    var series = $scope.data.limit.map(function (d) {
                        return d.header;
                    });
                    return series;
                }
            },
            tooltip: {
                content: function (chart, d) {
                    var stage = angular.findWhere($scope.data.stages, { stage: $scope.query.startStage });
                    return '<p class="tooltip-title">' + d.y + ' - ' + d.legend + '</p>' +
                        '<center>' +
                        '<strong>' + d.count + ' (' + d.x + '%)</strong> ' + (stage.applies_to == 'lead' ? 'Leads' : 'Opps') + ' that entered ' +
                        '<strong>"' + $scope.query.startStage + '"</strong> ' +
                        'in <strong>' + d.y + '</strong> converted to ' +
                        '<strong>"' + $scope.query.endStage + '"</strong> in <strong>' + d.legend + '</strong>' +
                        '</center>';
                }
            },
            axis: {
                x: {
                    label: { text: 'Percent Converted' },
                    domain: function (chart) {
                        /*==========  get the max x axis value to define the scale which is a domain + range  ==========*/
                        chart.axis.x.max = d3.max(chart.data, function (group) {
                            return d3.max(group, function (d) {
                                return d.x + d.x0;
                            });
                        });

                        return [0, chart.axis.x.max];
                    },
                    tick: {
                        // eslint-disable-next-line no-unused-vars
                        format: function (d, i) {
                            return d + '%';
                        }
                    }
                },
                y: {
                    label: { text: 'Leads Entered Stage' },
                    domain: function (chart) {
                        /*==========  define the Y axis scale ==========*/
                        // eslint-disable-next-line no-unused-vars
                        chart.axis.y.months = chart.data[0].map(function (c, i) {
                            return c.y;
                        });

                        return chart.axis.y.months;
                    }
                }
            },
            margin: {
                left: 45,
                top: 0,
                right: 0,
                bottom: 45
            },
            minHeight: 280,
            // eslint-disable-next-line no-unused-vars
            barValues: function (d, i) {
                if (d.x && $scope.chart.axis.x.scale(d.x) > 38) return d.x + '%';
            },
            //------------ stacked format gets called before each update -------------//
            updateStart: function (chart) {
                if (!chart.data[0][0].legend) {
                    chart.stack(chart.data);

                    /*==========  now reformat the data to get the correct values  ==========*/
                    // IF you want to make changes to the data and you don't want it to trigger and update of the chart set and then unset an "ignore" property on the model
                    chart.data = chart.data.map(function (group, ii) {
                        return group.map(function (conversion, i) {
                            return {
                                x: conversion.y,
                                y: $scope.data.data[i].cohort,
                                x0: conversion.y0,
                                total: conversion.total,
                                count: conversion.count,
                                legend: chart.legend.series()[ii]
                            };
                        });
                    });
                }
            }
        };

        var quarters = $scope.rawCohorts.quarter;
        //adding first of past for tests
        $scope.query.cohort = quarters.current.key ? quarters.current.key : quarters.past.reverse()[0].key;
        if ($state.params.startStage === undefined) {
            $scope.query.startStage = $scope.data.stages[0].stage != 'In Marketo' ? $scope.data.stages[0].stage : $scope.data.stages[1].stage;
        }
        $scope.query.endStage = $scope.data.stages[$scope.data.stages.length - 1].stage;

        $scope.disabledChoices(($scope.data.stages[0].stage != 'In Marketo' ? $scope.data.stages[0].stage : $scope.data.stages[1]), $scope.query.startStage, 'end');
        $scope.disabledChoices($scope.data.stages[$scope.data.stages.length - 1], $scope.query.endStage, 'start');

        $scope.getTrend();
    })();
}]);

app.controller('revWaterfallTrendingDetailsCtrl', ['$scope', 'api', '$q', '$state', '$filter', 'utilities', '$rootScope', '_', function ($scope, api, $q, $state, $filter, utilities, $rootScope, _) {
    var dataCall,
        names = [];

    function getDetails() {
        if (dataCall) { dataCall.abort(); }
        $state.current.data.loading = true;
        $scope.data.cohorts = ["Any"];
        (dataCall = api.get("lead_conversion_details", $scope.query)).then(function (data) {
            $scope.data.cohortData = data.data.details;
            $scope.data.startStage = data.data.startStage;
            $scope.data.endStage = data.data.endStage;
            var rowsAll = [];
            for (var n in $scope.data.cohortData) {
                $scope.data.cohorts.push(n);
                rowsAll = rowsAll.concat($scope.data.cohortData[n]);
            }
            $scope.data.cohortData.Any = rowsAll;
            $state.current.data.loading = false;

            if ($scope.query.campaignId) {
                if (_.isArray($scope.query.campaignId)) {
                    $scope.query.campaignId.forEach(function (id) {
                        api.get('campaign_names_like', { likeName: id }, false).then(function (d) {
                            if (d.data && d.data.length) {
                                names.push(d.data[0].name);
                                $scope.data.campaignNames = formatNames(names);
                            }
                        });
                    });
                } else {
                    api.get('campaign_names_like', { likeName: $scope.query.campaignId }, false).then(function (d) {
                        if (d.data && d.data.length) {
                            names.push(d.data[0].name);
                            $scope.data.campaignNames = formatNames(names);
                        }
                    });
                }
            }

            if ($scope.query.campaignGroup) {
                $scope.data.groupNames = formatNames($scope.query.campaignGroup);
            }
        });
    }

    function formatNames(data) {
        if (_.isArray(data)) {
            var last = data.length - 1;
            if (data.length > 1) { data[last] = "and " + data[last]; }
            return data.join(', ');
        } else {
            return data;
        }
    }

    $scope.$on('filtersChanged', function () {
        getDetails();
    });

    $scope.$on('$destroy', function () {
        if (dataCall) { dataCall.abort(); }
    });

    $scope.changeStage = function () {
        if ($scope.query.quarter) {
            utilities.queryString({ quarter: $scope.query.quarter });
        }
    };

    (function init() {
        $scope.query = angular.copy($state.params);
        $scope.data = {
            cohortData: {},
            cohorts: ["Any"]
        };
        $scope.utils = utilities;
        getDetails();

        if ($scope.query.campaignId) {
            $scope.$watch('data.campaignNames', function (newVal) {
                if (newVal) {
                    $state.current.data.loading = false;
                }
            });
        }

        if ($scope.query.campaignGroup) {
            $scope.$watch('data.groupNames', function (newVal) {
                if (newVal) {
                    $state.current.data.loading = false;
                }
            });
        }
    })();
}]);
