app.directive('lineChart', ['bfChart', function (bfChart) {
    return {
        restrict: 'EA',
        require: 'ngModel',
        link: function (scope, elem, attrs) {
            var model = scope.$eval(attrs.ngModel);

            if (!model || angular.isArray(model.data) && !model.data.length) return;

            model.margin = {
                top: 50,
                left: 115,
                right: 10,
                bottom: 50
            };

            // eslint-disable-next-line no-unused-vars
            model.init = function (scope, elem, chart) {
                /**
                * WHEN: This fires after the basic chart elements have events have been added to the page
                * Init function only fires once period and should be used to modify or set up variables uses in your modal. This function does not rerun when lazy reload takes place.
                *
                **/
            };

            model.update = function (scope, elem, chart) {
                /* from 0 to the largest x value */
                chart.axis.x.scale = d3.scale[chart.axis.x.type]()
                    /* domain is basically the data for the actual values that will go in the axis */
                    .domain(chart.axis.x.domain(chart))
                /* now define the range which is issentially it's size on the screen */
                    // eslint-disable-next-line no-unexpected-multiline
                    [(chart.axis.x.type == 'linear' ? 'range' : 'rangeRoundBands')]([0, chart.width]);

                chart.axis.y.scale = d3.scale[chart.axis.y.type]()
                    .domain(chart.axis.y.domain(chart))
                    .range([0, chart.height], 0)
                    .nice();

                /*==========  define the axis with d3  ==========*/
                chart.axis.x.axis = d3.svg.axis()
                    .scale(chart.axis.x.scale)
                    .orient('bottom')
                    .tickSize(-chart.height);

                if (chart.axis.x.numTicks)
                    chart.axis.x.axis.ticks(chart.axis.x.numTicks);

                if (chart.axis.x.axis.tickFormat)
                    chart.axis.x.axis.tickFormat(chart.axis.x.tick.format);


                chart.axis.y.axis = d3.svg.axis()
                    .scale(chart.axis.y.scale)
                    .orient('left')
                    .tickSize(-chart.width);

                // if(chart.axis.y.numTicks)
                //     chart.axis.y.axis.ticks(chart.axis.y.numTicks);

                if (chart.axis.y.axis.tickFormat)
                    chart.axis.y.axis.tickFormat(chart.axis.y.tick.format);

                /*==========  now attach the scales we've made to their respective axis elements  ==========*/
                chart.axis.x.elem.call(chart.axis.x.axis);
                chart.axis.y.elem.call(chart.axis.y.axis);

                chart.line = d3.svg.line()
                    // assign the X function to plot our line as we wish
                    .x(function (d, i) {
                        // verbose logging to show what's actually being done
                        // console.log('Plotting X value for data point: ' + d + ' using index: ' + i + ' to be at: ' + chart.axis.x.scale(i) + ' using our xScale.');
                        // return the X coordinate where we want to plot this datapoint
                        chart.chartArea.append('circle')
                            .attr({
                                "class": "circle",
                                "cx": chart.axis.x.scale(i),
                                "cy": chart.axis.y.scale(d),
                                "r": 6,
                                "fill": "#FFFFFF",
                                // eslint-disable-next-line no-unused-vars
                                "stroke": function (d) {
                                    if (chart.strokeColor) {
                                        return chart.strokeColor;
                                    } else {
                                        return chart.colors.range()[1];
                                    }
                                },
                                "stroke-width": 3
                            })
                            .on('mouseover', function () {
                                var xPos = (chart.axis.x.scale(i) + (elem.offset().left + chart.margin.left)) - 100;
                                var yPos = chart.axis.y.scale(d) + (elem.offset().top + chart.margin.top + 20);

                                chart.tooltip.elem
                                    .style('left', xPos + 'px')
                                    .style('top', yPos + 'px')
                                    .html(chart.tooltip.content(chart, chart.data[i]));

                                //number "stage" leads or opt created in cohort converted to "ending stage" in same quarter

                                chart.tooltip.elem.style('display', 'block').style('opacity', '1');
                            })
                            .on('mouseout', function () {
                                chart.tooltip.elem.style('display', 'none').style('opacity', '0');
                            });

                        return chart.axis.x.scale(i);
                    })
                    .y(function (d) {
                        // verbose logging to show what's actually being done
                        // console.log('Plotting Y value for data point: ' + d + ' to be at: ' + chart.axis.y.scale(d) + " using our yScale.");
                        // return the Y coordinate where we want to plot this datapoint
                        return chart.axis.y.scale(d);
                    });

                chart.chartArea.append("path")
                    .attr("class", "line")
                    .style({
                        "stroke-width": 3,
                        // eslint-disable-next-line no-unused-vars
                        "stroke": function (d) {
                            if (chart.strokeColor) {
                                return chart.strokeColor;
                            } else {
                                return chart.colors.range()[1];
                            }
                        }
                    })
                    // eslint-disable-next-line no-unused-vars
                    .attr("d", function (d) {
                        var sub = chart.data.map(function (dc) {
                            return dc.total;
                        });
                        return chart.line(sub);
                    });

                chart.axis.y.elem.selectAll('text')
                    .attr("x", "-12px");

                chart.axis.x.elem.selectAll('text')
                    .attr("y", "10px");
            };

            bfChart.newChart(scope, elem, model);

            scope.$on('$destroy', function () {
                model.tooltip.elem.remove();
            });
        }
    };
}]);
