app.controller('userConfigCtrl', ['$scope', '$http', '$window', '$location', 'utilities', '$cookies', 'api', "$state", '_', 'noty', function ($scope, $http, $window, $location, utilities, $cookies, api, $state, _, noty) {
    $scope.cookies = $cookies;
    var loadLeadCompaniesCall;
    var loadLeadDomainsCall;
    var globalPropertyCall;
    var findOlfCountCall;
    const forbiddenKeys = [' ', ';', ','];

    $scope.statuses = {
        EXISTS: 'This user was already added',
        BADFORMAT: 'This is not valid email format',

    };

    $scope.demo = $cookies.get('BrightFunnelDemo') ? angular.fromJson($cookies.get('BrightFunnelDemo')) : { companyName: 'Acme Inc.' };

    // eslint-disable-next-line no-unused-vars
    function hideFeaturesAndPagesInGroup() {
        for (var p in $scope.configs.page) {
            $scope.configs.page[p].show = true;
        }
        for (var f in $scope.configs.feature) {
            $scope.configs.feature[f].show = true;
        }
        angular.forEach($scope.data.featureGroups[$scope.data.selectedFeatureGroupKey], function (f) {
            $scope.configs[f.type][f.child].show = false;
        });
    }

    function refresh_organizations() {
        $http.get('admin/organizations').success(function (data) {
            let enabledOrgs = [];
            data.forEach(function (org) {
                if(org.enabled) {
                    enabledOrgs.push(org);
                }
            });
            $scope.orgs.organizations = enabledOrgs;
        });
    }

    function applyMask(tab) {
        if ((tab == "permission" || tab == "users" || tab == "organization") && window.location.href.indexOf("stage.brightfunnel") > 1) {
            $scope.data.mask[tab] = true;
        } else if ((tab == "dataCycle" || tab == "olf" || tab == "waterfall") && $scope.data.stageActive && window.location.href.indexOf("app.brightfunnel") > 1) {
            $scope.data.mask[tab] = true;
        }
    }

    $scope.updateUserEnabled = function (user) {
        var url = "admin/update_user_enabled?";
        url += utilities.param.toQueryString({ username: user.username, enabled: user.enabled });
        $http.get(url);

    };

    $scope.updateUserLocked = function (user) {
        var url = "admin/update_user_locked?";
        url += utilities.param.toQueryString({ username: user.username, locked: user.locked });
        $http.get(url);

    };

    $scope.update_user = function (user) {
        $scope.user_updates[user.user_id] = user;
    };

    $scope.format_pasted = function (event) {
        event.preventDefault();
        const data = event.originalEvent.clipboardData.getData('text/plain');
        $scope.newUser.email = $scope.newUser.email + '\n' + replace_delimiters(data);
    };

    $scope.format_delimiters = function ($event) {
        if (forbiddenKeys.includes($event.key)) {
            $event.preventDefault();
        }
    };

    function replace_delimiters(email_list) {
        forbiddenKeys.forEach(key => {
            email_list = email_list.replaceAll(key, '\n');
        });
        return email_list.replaceAll('\n\n', '\n');
    }

    $scope.get_entered_users = () => {
        return $scope.newUser.email.split('\n').filter(item => !!item).length;
    };

    $scope.get_ignored_users_amount = (ignoredUsers) => {
        return Object.values(ignoredUsers).length;
    };

    $scope.add_user = function() {
        let url = "BFAdmin/add_bulk_users";
        if($scope.newUser.orgId === 0) {
            noty.growl("Select an Organization for the new User", "error");
        }
        if($scope.newUser.email === "") {
            noty.growl("Enter an Email for the new User", "error");
        }

        $http.post(url, {
            requestedUsers: $scope.newUser.email.split('\n').filter(item => !!item),
            organizationId: $scope.newUser.orgId,
            areAdministrationUsers: $scope.create_admins
        }).success(function (data) {
            if(!data.errorMessage) {
                $scope.addedUsers = data['successfullyCreatedUsers'];
                $scope.ignoredUsers = data['failedCreatedUsers'];
            } else {
                $scope.addedUsers = [];
                $scope.ignoredUsers = null;
                noty.growl(data.errorMessage, "error");
            }
        });
    };

    $scope.close_success = () => {
        $scope.addedUsers = [];
    };

    $scope.close_ignored = () => {
        $scope.ignoredUsers = null;
    };

    $scope.clear_user_updates = function () {
        $scope.user_updates = {};
        $http.get('admin/users').success(function (data) {
            $scope.users = data;
            angular.forEach($scope.users, function (u) { u.updated_org_id = u.org_id; });
        });
    };

    $scope.apply_user_updates = function () {
        var updates = [];
        // eslint-disable-next-line no-unused-vars
        angular.forEach($scope.user_updates, function (user, key) {
            updates.push({ user_id: user.user_id, org_id: user.updated_org_id });
        }
        );
        $http.post("admin/assign_user_to_org", updates).success($scope.clear_user_updates);
    };

    $scope.hasUpdates = function () {
        var value = !($.isEmptyObject($scope.user_updates));
        return value;
    };

    (function init() {
        $scope.query = {
            orgId: 0,
            pageId: "app.campaigns.attribution",
            featureId: "ceff.model.toggle"
        };
        $scope.data = {
            featureGroups: {},
            selectedFeatureGroupKey: "",
            showEditFeatureGroup: false,
            mask: { permission: false, dataCycle: false, users: false, organization: false },
            stageActive: false,
            newAccount: "",
            ignoreType: "domain",
            ignoreOptions: ['domain', 'account', 'company'],
            domain: [],
            company: [],
            marketingRoles: [
                { value: "", ui: "" },
                { value: "demand generation", ui: "Demand Generation" },
                { value: "marketing operations", ui: "Marketing Operations" },
                { value: "field marketing", ui: "Field Marketing" },
                { value: "sales manager", ui: "Sales Manager" },
                { value: "executive", ui: "Executive" },
                { value: "other marketing", ui: "Other Marketing" }
            ],
            activeOrgsOnly: true
        };

        $scope.configs = {
            page: {},
            feature: {
                "ceff.model.toggle": { key: "ceff.model.toggle", name: "Campaign Effectiveness Model Toggle", show: true },
                "tot.rev.predict": { key: "tot.rev.predict", name: "Total Revenue Predictions", show: true },
                "waterfallStages": { key: "waterfallStages", name: "Waterfall Stages", show: true },
                "disableInsights": { key: "disableInsights", name: "Insights", show: true },
                "path2Sale.touchesByCamp": { key: "path2Sale.touchesByCamp", name: "Path To Sale Campaign Touches", show: true },
                "dashboard beta": { key: "dashboard.beta", name: "dashboard beta", show: true },
                "newNav": { key: "new.nav", name: "New Navigation", show: true }
            },
            org_influence_rules: { cio: [], lto: [] },
            defaultCampaignDateTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
            campaignDateTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
            opptyAmountType: ['ARR', 'MRR'],
            pipePostTouchType: ['ON', 'OFF'],
            extraCampaignDateTypes: { 16: ["Last Member Status Change"] }
        };


        function reduce() {
            // eslint-disable-next-line no-unused-vars
            var m = _.flatten($scope.subnav.map(function (c) {
                return c.children;
            }));
        }

        reduce();


        $scope.orgs = {
            new_organization_name: '',
            organization: []
        };

        angular.forEach(_.compact(_.flatten($scope.subnav.map(function (c) {
            return c.children;
        }))), function (s) {

            pushName(s);
            if (s.tabs) {
                // eslint-disable-next-line no-unused-vars
                angular.forEach(s.tabs, function (v, i) {
                    pushName(v);
                });
            }

            function pushName(obj) {
                $scope.configs.page[obj.name] = {
                    key: obj.name,
                    name: (obj.config.data.title ? obj.config.data.title : obj.config.data.staticTitle) + (obj.config.data.subtitle ? ': ' + obj.config.data.subtitle : ''),
                    show: true
                };
            }
        });
        // adding on manually that is not in the subnav
        $scope.configs.page["app.campaign.sfdc"] = { key: "app.campaign.sfdc", name: "Campaign SFDC data", show: true };


        $http.get('admin/internal_config').success(function (data) {
            var active = [];
            data.orgs.forEach(function (item) {
                if (item.active) {
                    active.push(item);
                }
            });
            $scope.configs.orgs = [{ id: 0, name: "--Select an org--" }].concat(active);
            $scope.configs.orgsActive = [{ id: 0, name: "--Select an org--" }].concat(active);
            $scope.configs.orgsAll = [{ id: 0, name: "--Select an org--" }].concat(data.orgs);
            $scope.configs.influence_rules = data.influence_rules;
        });

        (globalPropertyCall = api.get("globalProperty", { name: "stage.active" }, false, true)).then(function (data) {
            $scope.data.stageActive = data.data[0].value == "true";
        });

        $scope.$on('$destroy', function () {
            if (loadLeadCompaniesCall) { loadLeadCompaniesCall.abort(); }
            if (loadLeadDomainsCall) { loadLeadDomainsCall.abort(); }
            if (findOlfCountCall) { findOlfCountCall.abort(); }
            if (globalPropertyCall) { globalPropertyCall.abort(); }
        });

        applyMask("users");
        refresh_organizations();
        $scope.clear_user_updates();
        $scope.show_unassigned_users = true;
        $scope.create_admins = false;
        $scope.newUser = {email: "", orgId:0};

    })();
}]);
