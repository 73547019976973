app.controller('opptyAnalysisListWidgetCtrl', ['$scope', '$state', 'api', 'utilities', '$filter', '$rootScope', '$parse', '_', 'opptyAnalysis', 'personaDefaults', 'widgets', '$stateParams', function ($scope, $state, api, utilities, $filter, $rootScope, $parse, _, opptyAnalysis, personaDefaults, widgets, $stateParams) {
    var $tile = $scope.$tile,
        opptyCall;

    this.getData = function () {
        if (opptyCall) { opptyCall.abort(); }

        $scope.data = opptyAnalysis.getData();
        $scope.data.fieldInfo = _.find($scope.data.fields, { field: 'revenue' }); //we'll need to make 'revenue' value dynamic if we ever want to show more than just top revenue field in oppty list tile
        $scope.data.filter = $scope.data.fieldInfo.cellFilter; //this will need to be changed to reflect what the field is
        if (!_.find($scope.data.models, { name: 'No Marketing Influence', key: "none" })) {
            $scope.data.models.push({ name: 'No Marketing Influence', key: "none" });
        }
        $scope.widget.query = _.defaults($scope.widget.query, personaDefaults.getQueryDefinitions($scope.widget).query);
        $scope.widget.data = _.defaults($scope.widget.data, {});
        opptyAnalysis.initCheck($scope.widget.query);

        $scope._ = _;

        (opptyCall = api.getter({
            url: 'oppty_perf',
            params: $scope.widget.query,
            paramsNotToUpdate: 'all',
            skipFilters: true,
        })).then(
            function (data) {
                var currentModel = $scope.widget.query.model ? $scope.widget.query.model : 'all',
                    normalizedData = opptyAnalysis.normalizeWidgetData(data.data.oppty_data, currentModel, $scope.data.fieldInfo.field, 'opptys'), //normalize the data to be structured the same when sent to formatWidgetListData
                    formattedData = widgets.formatWidgetListData(normalizedData, $scope.data.fieldInfo, 'opptys', $scope, null, currentModel);

                $scope.sortedData = widgets.sortListData(formattedData, 'value');
                $scope.data.data = widgets.numberOfTopNResults($scope.sortedData, $scope.widget);
                $scope.data.totalRecordCount = data.data.oppty_count;
                $scope.widget.data.lastQuery = angular.copy($scope.widget.query);

                $tile.loading(100);
            }, function (data) {
                if (!data.aborted) {
                    $tile.failedLoading();
                }
            }
        ).finally(
            function () {
                if ($tile.isLoading()) {
                    $scope.query = $scope.widget.query;
                    $tile.endLoading();
                }
                $scope.$emit("widgetLoadingDone");
            }
        );
    };

    (function init(ctrl) {
        //data init
        $scope.data = opptyAnalysis.getData();
        $scope.selectors =
            {
                dateLabel: 'Date Field',
                dateOptions: [
                    {name: "Created", value: "created"},
                    {name: "Closed", value: "closed"}
                ],
                statusLabel: 'Status',
                statusOptions: [
                    {name: "Open", value: "open"},
                    {name: "Closed", value: "closed"},
                    {name: "Open or Closed", value: "any"}
                ],
                wonLabel: 'Closed',
                wonOptions: [
                    {name: "Won", value: "won"},
                    {name: "Lost", value: "lost"},
                    {name: "Won or Lost", value: "any"}
                ]
            };

        $scope.data.fieldInfo = _.find($scope.data.fields, { field: 'revenue' }); //we'll need to make 'revenue' value dynamic if we ever want to show more than just top revenue field in oppty list tile
        $scope.data.filter = $scope.data.fieldInfo.cellFilter; //this will need to be changed to reflect what the field is
        if (!_.find($scope.data.models, { name: 'No Marketing Influence', key: "none" })) {
            $scope.data.models.push({ name: 'No Marketing Influence', key: "none" });
        }
        const params = _.defaults($scope.widget.query, personaDefaults.getQueryDefinitions($scope.widget).query);
        // Api has been updated to paginate through back end
        params.fld = 'revenue';
        params.dir = 'd';
        params.tbl = 'oppty';
        // NOTE: Update query object with data, which was in query params
        // (terminus-hub Add to Dashboard functionality)
        $scope.widget.query = widgets.addFilters(params, $scope.widget.data.filters, $scope.widget.data.accountLists);
        if ($stateParams.routeId && $stateParams.routeId === 'app.analyze.opportunities.listAnalysis') {
            Object.keys($scope.widget.query).forEach(key => {
                // update only properties, which are related to marketing influence data
                if ($scope.widget.query.hasOwnProperty(key)
                    && $stateParams.hasOwnProperty(key)
                    && $stateParams[key]) {
                    $scope.widget.query[key] = $stateParams[key];
                }
            });
            // NOTE: add mandatory params which are not in $scope.widget.query for some reason
            $scope.widget.query.wonType = $stateParams.wonType;
            $scope.widget.query.model = $stateParams.model;
        }
        $scope.widget.data = _.defaults($scope.widget.data, {});

        opptyAnalysis.initCheck($scope.widget.query);

        $scope._ = _;

        $tile.listeners.push($scope.$on('widgetUpdate', function () {
            if (!angular.equals(angular.copy($scope.widget.query), $scope.widget.data.lastQuery)) {
                ctrl.getData();
            }
        }));

        $scope.$on("updateListResults", function () {
            $scope.data.data = widgets.numberOfTopNResults($scope.sortedData, $scope.widget);
        });

        $scope.$on('$destroy', function () {
            if (opptyCall) { opptyCall.abort(); }

            //when leaving this controller, you want to remove the no marketing influence from the attribution models
            var nmi = _.find($scope.data.models, { key: "none" });
            if (nmi) {
                $scope.data.models.pop();
            }
        });
    })(this);
}]);

