/**
 * Controller for the frame directive.
 */
(function () {
    'use strict';

    angular
        .module('bombora')
        .directive('headerFrame', function () {
            return {
                restrict: 'E',
                template: require('./frame.html'),
                controller: frameCtrl,
                controllerAs: "$ctrl",
                bindToController: true,
                scope: {
                    title: '='
                },
                transclude: true
            };
        });

    frameCtrl.$inject = [];

    /**
     * Controller for the directive.
     */
    function frameCtrl() { }

})();
