app.controller('accountsCtrl', ['$scope', 'api', 'utilities', '$state', '$filter', 'cohorts', 'account', '_', function ($scope, api, utilities, $state, $filter, cohorts, account, _) {
    //------------------------ scope methods ------------------------//
    $scope.runAllQuery = function () {
        $scope.$broadcast('getData');
    };

    $scope.registerChanges = function (nv, ov) {
        if (!angular.equals(nv.query.metric, ov.query.metric)) {
            $scope.$broadcast('metricChanged');
            utilities.queryString({ metric: nv.query.metric });
        }
    };

    $scope.picks = function () {
        return $scope.data.chartPickTrending;
    };

    $scope.abs = function (num) {
        return Math.abs(num);
    };

    $scope.getTime = function () {
        var t = _.find($scope.data.cohorts, { key: $scope.query.cohort }).value;
        return _.contains(t, 'one ') ? t.replace('one ', '') : t;
    };

    $scope.isInit = function () {
        return $scope.data.init;
    };

    //------------------------ private methods ------------------------//

    //------------------------ init ------------------------//
    (function init() {
        $state.current.data.loading = true;
        $scope.data = {
            chartTitles: {
                percentage: '% Engaged by Marketing',
                average: 'Avg % Contacts Engaged by Marketing',
                contacts: 'Unique Engaged Contacts',
                activity: 'Marketing Engagements',
                engaged: 'Accounts Engaged by Marketing'
            },
            cohorts: utilities.formatCohorts(cohorts, ['all', 'ago', 'custom', 'year', 'quarter', 'toDate', 'lastFull']),
            filters: account.filters(),
            chartPickTrending: [{
                label: 'Marketing Engagement',
                key: 'activity'
            }, {
                label: 'Unique Engaged Contacts',
                key: 'contacts'
            }, {
                label: 'Accounts Engaged by Marketing',
                key: 'engaged'
            }, {
                label: '% Engaged by Marketing',
                key: 'percentage'
            }, {
                label: 'Avg % Contacts Engaged by Marketing',
                key: 'average'
            }],
        };
        $scope.utils = utilities;

        if ($state.current.name == "app.leadsAndOpps.accounts.trending") {
            $scope.data.cohorts = utilities.formatCohorts(cohorts, ['ago', 'custom', 'year', 'quarter', 'toDate', 'lastFull']);
        } else {
            $scope.data.cohorts = utilities.formatCohorts(cohorts, ['all', 'ago', 'custom', 'year', 'quarter', 'toDate', 'lastFull']);
        }
        $scope.query = {
            cohort: 'quarter2Date',
            account_filter: 'all',
            metric: 'percentage',
            startDate: moment(new Date()).subtract(1, 'week')._d.getTime(),
            endDate: new Date().getTime()
        };
        $scope._ = _;

        utilities.combine($scope.query, $state.params);

        $scope.query.withSalesActivity = $scope.orgConfig.salesActivity.enabled;

        $scope.data.init = true;
        $state.current.data.loading = false;
    })();
}]);
