app.controller('revByChannelByQtrCtrl', ['$scope', '$rootScope', '$http', '$window', '$location', '$state', '$filter', 'utilities', 'api', 'insights', 'colorMap', 'cohorts', '_', 'models', 'revAttribution', 'influenceTypes', function ($scope, $rootScope, $http, $window, $location, $state, $filter, utilities, api, insights, colorMap, cohorts, _, modelTypes, revAttribution, influenceTypes) {
    $scope.utilities = utilities;

    var models = $scope.orgConfig.attributionModels;
    let dataCall;

    $scope.models = {
        revenue: [],
        pipeline: []
    };

    $scope.getGroupColor = function (name) {
        return colorMap(name);
    };

    $scope.runAllQuery = function () {
        $scope.query.dataSet = ($scope.query.revenueType === 'pipeline' ? "opptyCreatedDate" : "opptyCloseDate");
        if ($scope.query.revenueType === 'pipeline') {
            $scope.query.influenceType = 'pre';
        }
        utilities.queryString({
            dataSet: $scope.query.dataSet,
            influenceType: $scope.query.influenceType,
        });
        get_campaign_influenced_revenue_data();
    };

    $scope.cohortDrop = { width: '280px', escapeMarkup: function (m) { return m; } };

    function calculateAndDrawChart(campaignInfluenceData, campaignTotalData) {
        if ($scope.data.revenueType === 'revenue') {
            campaignInfluenceData = campaignInfluenceData.filter(function (r) {
                if (!r.type) {
                    return false;
                }
                r['deals_unique'] = campaignTotalData.groups[r.type]['deals_unique'];
                return r.sourced_revenue > 0 || r.sourced_deals > 0 || r.ti_revenue > 0
                    || r.ti_deals > 0 || r.ewi_revenue > 0 || r.ewi_deals > 0 || r.ci_revenue > 0
                    || r.la_revenue > 0 || r.la_deals > 0;
            });

            var maxSourcedChannel = "";
            var maxSourced = -Infinity;
            for (var i = 0; i < campaignInfluenceData.length; i++) {
                if (campaignInfluenceData[i].sourced_revenue > maxSourced) {
                    maxSourcedChannel = campaignInfluenceData[i].type;
                    maxSourced = campaignInfluenceData[i].sourced_revenue;
                }
            }
            $scope.maxChannel = [maxSourcedChannel, maxSourced];

        }

        if ($scope.data.revenueType === 'pipeline') {
            campaignInfluenceData = campaignInfluenceData.filter(function (r) {
                if (!r.type) {
                    return false;
                }
                r['opptys_unique'] = campaignTotalData.groups[r.type]['opptys_unique'];
                return r.sourced_p_revenue > 0 || r.sourced_p_opptys > 0 || r.ti_p_revenue > 0
                    || r.ti_p_opptys > 0 || r.ewi_p_revenue > 0 || r.ewi_p_opptys > 0 || r.ci_p_revenue > 0
                    || r.la_p_revenue > 0 || r.la_opptys > 0;
            });

            maxSourcedChannel = "";
            maxSourced = -Infinity;
            for (i = 0; i < campaignInfluenceData.length; i++) {
                if (campaignInfluenceData[i].sourced_p_revenue > maxSourced) {
                    maxSourcedChannel = campaignInfluenceData[i].type;
                    maxSourced = campaignInfluenceData[i].sourced_p_revenue;
                }
            }
            $scope.maxChannel = [maxSourcedChannel, maxSourced];
        }


        $scope.campaign_influence = $filter('orderBy')(campaignInfluenceData, 'type');

        $scope.show_campaigns = [];
        for (i = 0; i < $scope.campaign_influence.length; i += 1) {
            $scope.show_campaigns.push(false);
        }
        calc_totals_annotate_percents($scope.campaign_influence);
        if ($scope.query.revenueType === 'revenue') {
            $scope.private.draw_rcbq_chart_booked();
        } else if ($scope.query.revenueType === 'pipeline') {
            $scope.private.draw_rcbq_chart_pipeline();
        }
    }
    //Reformat data from the campaign_performance api call to look like the old campaign_influence_by_qtr call.

    function get_campaign_influenced_revenue_data() {
        if (dataCall) { dataCall.abort(); }

        $state.current.data.loading = true;

        //add fields to query for campaign_performance
        $scope.cohort_name = _.find($scope.data.cohorts, { key: $scope.query.cohort }).value;
        //this method gets called each time the tab is switched, so this switches as necessary.

        const params = angular.copy($scope.query);
        const apiUrl = 'campaign_performance';
        const apiUrl2 = 'campaign_total_amount';
        delete params.search;

        (dataCall = api.get([
            {
                api: apiUrl,
                params: params,
            },
            {
                api: apiUrl2,
                params: params,
            }
        ])).then(function (data) {
            $scope.campaign_performance = data[apiUrl].data;
            $scope.campaignTotal = data[apiUrl2].data;
            $scope.data.revenueType = $scope.query.revenueType;

            calculateAndDrawChart(revAttribution.process_campaign_performance_data(data[apiUrl].data, $scope.query), data[apiUrl2].data);
        });
    }

    $scope.show_campaigns_for_row = function (row) {
        if (row.campaigns) {
            row.campaigns.length = 0;
        }
        const name = row.type;
        //Get data from saved campaign_performance call.
        const list = $scope.campaign_performance.groups[name].list;
        const influenceList = $scope.campaignTotal.campaigns;

        let data = [];
        //create an object for each campaign
        angular.forEach(list, function (elem) {
            const cur = {};

            //campaign data
            cur.id = elem.campaign_id;
            cur.name = elem.campaign_name;
            cur.created_date = elem.campaign_created_date;

            //revenue attribution fields
            ["sourced", "last", "even", "custom"].forEach(function (type) {
                if ($scope.data.revenueType === 'pipeline') {
                    cur[type] = elem[type].pipeline;
                } else {
                    cur[type] = elem[type].revenue;
                }
            });

            //Opportunities
            if ($scope.data.revenueType === 'pipeline') {
                cur.sourced_count = elem.sourced.opptys;
                cur.attributed_count = elem.even.opptys;
                cur.opptys_unique = influenceList[elem.campaign_id].opptys_unique;
            }
            // Deals
            else {
                cur.sourced_count = elem.sourced.deals;
                cur.attributed_count = elem.even.deals;
                cur.deals_unique = influenceList[elem.campaign_id].deals_unique;
            }
            if (cur.attributed_count !== 0) data.push(cur);
        });
        row.campaigns = data;
    };

    var calc_totals_annotate_percents = function (data) {
        var sums = {
            'sourced_revenue': 0.0,
            'ti_revenue': 0.0,
            'ewi_revenue': 0.0,
            'sourced_deals': 0.0,
            'ti_deals': 0.0,
            'ewi_deals': 0.0,
            'sourced_p_revenue': 0.0,
            'sourced_p_opptys': 0.0,
            'ti_p_revenue': 0.0,
            'ti_p_opptys': 0.0,
            'ewi_p_revenue': 0.0,
            'ewi_p_opptys': 0.0,
            'ci_p_revenue': 0.0,
            'ci_revenue': 0.0,
            'la_p_revenue': 0.0,
            'la_revenue': 0.0,
            'la_opptys': 0.0,
            'la_deals': 0.0,
            'opptys_unique': 0.0,
            'deals_unique': 0.0,
        };

        for (var i = 0; i < data.length; i++) {
            sums.sourced_revenue += (data[i].sourced_revenue) ? parseFloat(data[i].sourced_revenue) : 0;
            sums.sourced_deals += (data[i].sourced_deals) ? parseFloat(data[i].sourced_deals) : 0;
            sums.ti_revenue += (data[i].ti_revenue) ? parseFloat(data[i].ti_revenue) : 0;
            sums.ti_deals += (data[i].ti_deals) ? parseFloat(data[i].ti_deals) : 0;
            sums.ewi_revenue += (data[i].ewi_revenue) ? parseFloat(data[i].ewi_revenue) : 0;
            sums.ci_revenue += (data[i].ci_revenue) ? parseFloat(data[i].ci_revenue) : 0;
            sums.ewi_deals += (data[i].ewi_deals) ? parseFloat(data[i].ewi_deals) : 0;
            sums.la_revenue += (data[i].la_revenue) ? parseFloat(data[i].la_revenue) : 0;
            sums.la_deals += (data[i].la_deals) ? parseFloat(data[i].la_deals) : 0;

            sums.sourced_p_revenue += (data[i].sourced_p_revenue) ? parseFloat(data[i].sourced_p_revenue) : 0;
            sums.sourced_p_opptys += (data[i].sourced_p_opptys) ? parseFloat(data[i].sourced_p_opptys) : 0;
            sums.ti_p_revenue += (data[i].ti_p_revenue) ? parseFloat(data[i].ti_p_revenue) : 0;
            sums.ti_p_opptys += (data[i].ti_p_opptys) ? parseFloat(data[i].ti_p_opptys) : 0;
            sums.ewi_p_revenue += (data[i].ewi_p_revenue) ? parseFloat(data[i].ewi_p_revenue) : 0;
            sums.ewi_p_opptys += (data[i].ewi_p_opptys) ? parseFloat(data[i].ewi_p_opptys) : 0;
            sums.ci_p_revenue += (data[i].ci_p_revenue) ? parseFloat(data[i].ci_p_revenue) : 0;
            sums.la_p_revenue += (data[i].la_p_revenue) ? parseFloat(data[i].la_p_revenue) : 0;
            sums.la_opptys += (data[i].la_opptys) ? parseFloat(data[i].la_opptys) : 0;

            sums.opptys_unique += (data[i].opptys_unique) ? parseFloat(data[i].opptys_unique) : 0;
            sums.deals_unique += (data[i].deals_unique) ? parseFloat(data[i].deals_unique) : 0;
        }
        data.totals = sums;
        for (i = 0; i < data.length; i++) {
            data[i].s_rev_percent = (sums.sourced_revenue) ? data[i].sourced_revenue / sums.sourced_revenue : 0;
            data[i].ti_rev_percent = (sums.ti_revenue) ? data[i].ti_revenue / sums.ti_revenue : 0;
            data[i].ewi_rev_percent = (sums.ewi_revenue) ? data[i].ewi_revenue / sums.ewi_revenue : 0;
            data[i].ci_rev_percent = (sums.ci_revenue) ? data[i].ci_revenue / sums.ci_revenue : 0;
            data[i].la_rev_percent = (sums.la_revenue) ? data[i].la_revenue / sums.la_revenue : 0;

            data[i].s_p_rev_percent = (sums.sourced_p_revenue) ? data[i].sourced_p_revenue / sums.sourced_p_revenue : 0;
            data[i].ti_p_rev_percent = (sums.ti_p_revenue) ? data[i].ti_p_revenue / sums.ti_p_revenue : 0;
            data[i].ewi_p_rev_percent = (sums.ewi_p_revenue) ? data[i].ewi_p_revenue / sums.ewi_p_revenue : 0;
            data[i].ci_p_rev_percent = (sums.ci_p_revenue) ? data[i].ci_p_revenue / sums.ci_p_revenue : 0;
            data[i].la_p_rev_percent = (sums.la_p_revenue) ? data[i].la_p_revenue / sums.la_p_revenue : 0;
        }
    };

    $scope.private = {
        draw_rcbq_chart_booked: function () {
            var options = {
                title: (
                    $scope.query.cohort === '*NONE*'
                        ? _.find($scope.data.cohorts, { key: $scope.query.cohort }).value + ' '
                        : ''
                )
                    + "Revenue Percentage by Campaign Type"
                    + (
                        $scope.query.cohort !== 'time' && $scope.query.cohort !== '*NONE*'
                            ? " for " + _.find($scope.data.cohorts, { key: $scope.query.cohort }).value
                            : $scope.query.cohort !== '*NONE*'
                                ? " between " + moment($scope.query.startDate).calendar() + " and " + moment($scope.query.endDate).calendar()
                                : ""
                    ),
                isStacked: true,
                vAxis: { format: "#,###%" }
            };

            var ci = $scope.campaign_influence.slice();
            ci.sort(function (ci1, ci2) { return ci2.s_rev_percent - ci1.s_rev_percent; });
            var chart_data = _.filter(angular.copy($scope.models.revenue), function (o) {
                return o.key;
            });

            var stacks_labels = [];
            // eslint-disable-next-line no-unused-vars
            angular.forEach(chart_data, function (v, i) {
                v.values = [];
            });

            var row = [];
            for (var i = 0; i < ci.length; i++) {
                row = _.omitBy(ci[i], function (val) {
                    return angular.isNumber(val) && val < 0;
                });
                stacks_labels.push(row.type);

                chart_data.forEach(function (v) {
                    if (v.key === 'sourced') {
                        v.values.push({ percentage: row.s_rev_percent, amount: row.sourced_revenue });
                    } else if (v.key === 'last') {
                        v.values.push({ percentage: row.la_rev_percent, amount: row.la_revenue });
                    } else if (v.key === 'even') {
                        v.values.push({ percentage: row.ewi_rev_percent, amount: row.ewi_revenue });
                    } else if (v.key === 'custom') {
                        v.values.push({ percentage: row.ci_rev_percent, amount: row.ci_revenue });
                    }
                });
            }
            //draw_chart_with_options(chart_data, "rcbq_chart", options);
            chart_data.forEach(function (v, i) {
                chart_data[i].values.reverse();
            });

            var data = {
                data: chart_data,
                stacks: stacks_labels.reverse(),
                page_title: options.title,
                colorMap: colorMap
            };

            $scope.$broadcast('draw-rcbq-chart', data);
            insights.set($scope.cohort_name + $scope.maxChannel[0], 'During ' + $scope.cohort_name + ', ' + $scope.maxChannel[0] + ' sourced the most revenue.');
            $state.current.data.loading = false;
        },
        draw_rcbq_chart_pipeline: function () {
            var options = {
                title: (
                    $scope.query.cohort === '*NONE*'
                        ? _.find($scope.data.cohorts, { key: $scope.query.cohort }).value + ' '
                        : ''
                )
                    + "Pipeline Percentage by Campaign Type"
                    + (
                        $scope.query.cohort !== 'time' && $scope.query.cohort !== '*NONE*'
                            ? " for " + _.find($scope.data.cohorts, { key: $scope.query.cohort }).value
                            : $scope.query.cohort !== '*NONE*'
                                ? " between " + moment($scope.query.startDate).calendar() + " and " + moment($scope.query.endDate).calendar()
                                : ""
                    ),
                isStacked: true,
                vAxis: { format: "#,###%" }
            };

            var ci = $scope.campaign_influence.slice();
            ci.sort(function (ci1, ci2) { return ci2.s_p_rev_percent - ci1.s_p_rev_percent; });

            var chart_data = _.filter(angular.copy($scope.models.pipeline), function (o) {
                return o.key;
            });
            var stacks_labels = [];
            // eslint-disable-next-line no-unused-vars
            angular.forEach(chart_data, function (v, i) {
                v.values = [];
            });
            var row = [];
            for (var i = 0; i < ci.length; i++) {
                row = _.omitBy(ci[i], function (val) {
                    return angular.isNumber(val) && val < 0;
                });
                stacks_labels.push(row.type);
                chart_data.forEach(function (v) {
                    if (v.key === 'sourced') {
                        v.values.push({ percentage: row.s_p_rev_percent, amount: row.sourced_p_revenue });
                    } else if (v.key === 'last') {
                        v.values.push({ percentage: row.la_p_rev_percent, amount: row.la_p_revenue });
                    } else if (v.key === 'even') {
                        v.values.push({ percentage: row.ewi_p_rev_percent, amount: row.ewi_p_revenue });
                    } else if (v.key === 'custom') {
                        v.values.push({ percentage: row.ci_p_rev_percent, amount: row.ci_p_revenue });
                    }
                });
            }

            chart_data.forEach(function (v, i) {
                chart_data[i].values.reverse();
            });

            var data = {
                data: chart_data,
                stacks: stacks_labels.reverse(),
                page_title: options.title,
                colorMap: colorMap
            };
            $scope.$broadcast('draw-rcbq-chart', data);
            insights.set($scope.cohort_name + $scope.maxChannel[0], 'During ' + $scope.cohort_name + ', ' + $scope.maxChannel[0] + ' sourced the most pipeline');
            $state.current.data.loading = false;
            // draw_chart_with_options(chart_data, "rcbq_chart", options);
        }
    };

    $scope.$on('filtersChanged', function () {
        get_campaign_influenced_revenue_data();
    });

    $scope.useNewInfluenceType = influenceTypes.useNewInfluenceType;

    $scope.displayInfluenceTypeOption = (revenueType) => $scope.useNewInfluenceType() && revenueType === 'revenue';

    (function init() {
        // Initialization code begins here
        $scope.loaded = true;
        $scope.startDatepicker = {};
        $scope.endDatepicker = {};

        $scope.data = {
            influence: influenceTypes.typeMappings(),
            models: modelTypes,
            tableData: [],
            revenueType: 'revenue',
            cohorts: utilities.formatCohorts(cohorts, ['all', 'year', 'quarter', 'ago', 'toDate', 'lastFull', 'custom']),
            headers: [{
                displayName: function () {
                    return 'Campaign Type';
                },
                field: function () {
                    return 'label';
                },
                link: function (row) {
                    return $scope.shref('app.analyze.campaigns.listAnalysis.campaigns', {
                        dataSet: $scope.data.revenueType === 'pipeline' ? "opptyCreatedDate" : "opptyCloseDate",
                        cohort: $scope.query.cohort,
                        gf: row.label,
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        influenceType: $scope.query.influenceType,
                    });
                }
            }],
            expandedHeaders: [{
                displayName: function () {
                    return 'Campaign Name';
                },
                field: () => 'name',
                link: function (row) {
                    return $scope.shref('app.analyze.campaigns.campaignSpecific.attribution.leads', {
                        name: row.name,
                        campaignId: row.id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.data.revenueType === 'pipeline' ? "opptyCreatedDate" : "opptyCloseDate",
                        tab: $scope.data.revenueType === 'pipeline' ? "opptys" : "deals",
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        influenceType: $scope.query.influenceType,
                    });
                }
            }]
        };

        $scope.chart = {
            config: {
                data: {
                    format: function (d) {
                        return $filter('nrFormat')(d, true);
                    }
                }
            }
        };

        if (_.find(modelTypes, { key: 'sourced' })) {
            $scope.models.revenue.push({ key: 'sourced', title: models.sourced.custom_name, tooltip: "Attribution by the first marketing activity from a specific campaign/type." });
            $scope.models.pipeline.push({ key: 'sourced', title: models.sourced.custom_name, tooltip: "Attribution by the first marketing activity from a specific campaign/type." });

            $scope.data.headers.push({
                displayName: function () {
                    return models.sourced.custom_name;
                },
                field: function () {
                    return $scope.data.revenueType === 'pipeline' ? 'sourced_p_revenue' : 'sourced_revenue';
                },
                filter: 'bfCurrency',
                tooltip: function () {
                    var t = $scope.data.revenueType === 'pipeline' ? 'Pipeline' : 'Revenue';
                    return models.sourced.custom_name + " " + t + " is fully attributed to the Campaign with the first touch on the Opportunity. Only touches before the Opportunity Created Date are valid for Sourced Revenue.";
                }
            });

            $scope.data.expandedHeaders.push({
                displayName: function () {
                    return models.sourced.custom_name;
                },
                field: () => 'sourced',
                filter: 'bfCurrency'
            });
        }

        if (_.find(modelTypes, { key: 'last' })) {
            $scope.models.revenue.push({ key: 'last', title: models.last.custom_name, tooltip: "Attribution by the last marketing activity prior to conversion" });
            $scope.models.pipeline.push({ key: 'last', title: models.last.custom_name, tooltip: "Attribution by the last marketing activity prior to conversion" });

            $scope.data.headers.push({
                displayName: function () {
                    return models.last.custom_name;
                },
                field: function () {
                    return $scope.data.revenueType === 'pipeline' ? 'la_p_revenue' : 'la_revenue';
                },
                filter: 'bfCurrency',
                tooltip: function () {
                    var t = $scope.data.revenueType === 'pipeline' ? 'Pipeline' : 'Revenue';
                    return models.last.custom_name + " " + t + " is fully attributed to the Campaign with the last touch before the Opportunity Created Date.";
                }
            });

            $scope.data.expandedHeaders.push({
                displayName: function () {
                    return models.last.custom_name;
                },
                field: () => 'last',
                filter: 'bfCurrency'
            });
        }

        if (_.find(modelTypes, { key: 'even' })) {
            $scope.models.revenue.push({ key: 'even', title: models.even.custom_name, tooltip: "Attribution by analyzing all marketing activities on leads/contacts and opportunities, regardless of sequence." });
            $scope.models.pipeline.push({ key: 'even', title: models.even.custom_name, tooltip: "Attribution by analyzing all marketing activities on leads/contacts and opportunities, regardless of sequence." });

            $scope.data.headers.push({
                displayName: function () {
                    return models.even.custom_name;
                },
                field: function () {
                    return $scope.data.revenueType === 'pipeline' ? 'ewi_p_revenue' : 'ewi_revenue';
                },
                filter: 'bfCurrency',
                tooltip: function () {
                    var t = $scope.data.revenueType === 'pipeline' ? 'Pipeline' : 'Revenue';
                    return models.even.custom_name + " " + t + " is equally distributed across all Campaigns with touches on the Opportunity.";
                }
            });

            $scope.data.expandedHeaders.push({
                displayName: function () {
                    return models.even.custom_name;
                },
                field: () => 'even',
                filter: 'bfCurrency'
            });
        }

        if (_.find(modelTypes, { key: 'custom' })) {
            if ($rootScope.userData.organizationId === 160 || $rootScope.userData.organizationId === 84 || $rootScope.userData.organizationId === 129) {
                $scope.models.revenue.push({ key: 'custom', title: models.custom.custom_name, tooltip: "Attribution is set using a Custom Fit model designed specifically for this organization." });
                $scope.models.pipeline.push({ key: 'custom', title: models.custom.custom_name, tooltip: "Attribution is set using a Custom Fit model designed specifically for this organization." });
            }
            else {
                $scope.models.revenue.push({ key: 'custom', title: models.custom.custom_name, tooltip: "Attribution by associating weights to each marketing activity. The first and last marketing campaign activity are given higher weights (40% each, 80% total), and all activites in between comprise the remaining 20%." });
                $scope.models.pipeline.push({ key: 'custom', title: models.custom.custom_name, tooltip: "Attribution by associating weights to each marketing activity. The first and last marketing campaign activity are given higher weights (40% each, 80% total), and all activites in between comprise the remaining 20%." });
            }

            $scope.data.headers.push({
                displayName: function () {
                    return models.custom.custom_name;
                },
                field: function () {
                    return $scope.data.revenueType === 'pipeline' ? 'ci_p_revenue' : 'ci_revenue';
                },
                filter: 'bfCurrency',
                tooltip: function () {
                    var t = $scope.data.revenueType === 'pipeline' ? 'Pipeline' : 'Revenue';
                    return models.custom.custom_name + " " + t + " is attributed to Campaign with the first touch on the Opportunity, 40% is attributed to the Campaign with the last touch, and 20% is distributed evenly across all Campaigns with touches in between.";
                }
            });

            $scope.data.expandedHeaders.push({
                displayName: function () {
                    return models.custom.custom_name;
                },
                field: () => 'custom',
                filter: 'bfCurrency'
            });
        }

        if (_.find(modelTypes, { key: 'sourced' })) {
            $scope.models.revenue.push({ key: false, title: "Sourced Deals", tooltip: "First touches on all opportunities within the given date cohort." });
            $scope.models.pipeline.push({ key: false, title: "Sourced Opportunities", tooltip: "First touches on all opportunities within the given date cohort." });

            $scope.data.headers.push({
                displayName: function () {
                    if ($scope.data.revenueType === 'pipeline') {
                        return 'Sourced Opportunities';
                    }
                    else {
                        return 'Sourced Deals';
                    }
                },
                link: function (row) {
                    return $scope.shref('app.analyze.opportunities.listAnalysis', {
                        dateType: $scope.data.revenueType === 'pipeline' ? 'created' : 'closed',
                        type: $scope.data.revenueType,
                        wonOnly: $scope.data.revenueType !== 'pipeline',
                        wonType: $scope.data.revenueType === 'pipeline' ? 'any': 'won',
                        cohort: $scope.query.cohort,
                        closedType: $scope.data.revenueType === 'pipeline' ? 'any' : 'won',
                        sourceCampaignGroup: row.type,
                        model: 'sourced',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                    });
                },
                field: function () {
                    return $scope.data.revenueType === 'pipeline' ? 'sourced_p_opptys' : 'sourced_deals';
                },
                tooltip: function () {
                    if ($scope.data.revenueType === 'pipeline') {
                        return "Count of Opportunities where the Campaign is the first touch before the Opportunity Created Date. [Only applicable to Sourced Revenue model]";
                    } else {
                        return "Opportunity is considered sourced if the Campaign is the first marketing touch before the Opportunity Created Date.";
                    }
                }
            });

            $scope.data.expandedHeaders.push({
                displayName: function () {
                    if ($scope.data.revenueType === 'pipeline') {
                        return 'Sourced Opportunities';
                    }
                    else {
                        return 'Sourced Deals';
                    }
                },
                link: function (row) {
                    var url = $scope.data.revenueType === 'pipeline' ? 'app.analyze.campaigns.campaignSpecific.attribution.opportunities' : 'app.analyze.campaigns.campaignSpecific.attribution.deals';
                    return $scope.shref(url, {
                        name: row.name,
                        campaignId: row.id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.data.revenueType === 'pipeline' ? "opptyCreatedDate" : "opptyCloseDate",
                        modelType: "sourced",
                        tab: $scope.data.revenueType === 'pipeline' ? "opptys" : "deals",
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        influenceType: $scope.query.influenceType,
                    });
                },
                field: () => 'sourced_count'
            });
        }

        if (
            !influenceTypes.useNewInfluenceType()
            && (
                _.find(modelTypes, { key: 'even' })
                || _.find(modelTypes, { key: 'custom' })
            )
        ) {
            $scope.models.revenue.push({ key: false, title: "Deal Touches", tooltip: "Evenly weighted touches on all opportunities within the given date cohort." });
            $scope.models.pipeline.push({ key: false, title: "Opportunity Touches", tooltip: "Evenly weighted touches on all opportunities within the given date cohort." });

            $scope.data.headers.push({
                displayName: function () {
                    if ($scope.data.revenueType === 'pipeline') {
                        return 'Opportunity Touches';
                    }
                    else {
                        return 'Deal Touches';
                    }
                },
                link: function (row) {
                    return $scope.shref('app.analyze.opportunities.listAnalysis', {
                        dateType: $scope.data.revenueType === 'pipeline' ? 'created' : 'closed',
                        type: $scope.data.revenueType,
                        wonOnly: $scope.data.revenueType !== 'pipeline',
                        wonType: $scope.data.revenueType === 'pipeline' ? 'any': 'won',
                        cohort: $scope.query.cohort,
                        closedType: $scope.data.revenueType === 'pipeline' ? 'any' : 'won',
                        influencedCampaignGroup: row.type,
                        model: 'even',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                    });
                },
                field: function () {
                    return $scope.data.revenueType === 'pipeline' ? 'ti_p_opptys' : 'ti_deals';
                },
                tooltip: function () {
                    if ($scope.data.revenueType === 'pipeline') {
                        return "Count of all Opportunities with valid touches by the Campaign. [Only applicable to multi-touch models]";
                    } else {
                        return "All valid touches on Opportunities by the Campaigns.";
                    }
                }
            });

            $scope.data.expandedHeaders.push({
                displayName: function () {
                    if ($scope.data.revenueType === 'pipeline') {
                        return 'Opportunity Touches';
                    }
                    else {
                        return 'Deal Touches';
                    }
                },
                link: function (row) {
                    var url = $scope.data.revenueType === 'pipeline' ? 'app.analyze.campaigns.campaignSpecific.attribution.opportunities' : 'app.analyze.campaigns.campaignSpecific.attribution.deals';
                    return $scope.shref(url, {
                        name: row.name,
                        campaignId: row.id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.data.revenueType === 'pipeline' ? "opptyCreatedDate" : "opptyCloseDate",
                        modelType: "even",
                        tab: $scope.data.revenueType === 'pipeline' ? "opptys" : "deals",
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate
                    });
                },
                field: () => 'attributed_count'
            });
        }


        if (influenceTypes.useNewInfluenceType()) {
            $scope.models.revenue.push({ key: false, title: "Deal Influenced", tooltip: "" });
            $scope.models.pipeline.push({ key: false, title: "Opportunity Influenced", tooltip: "" });

            $scope.data.headers.push({
                displayName: function () {
                    if ($scope.data.revenueType === 'pipeline') {
                        return 'Opportunity Influenced';
                    }
                    else {
                        return 'Deal Influenced';
                    }
                },
                link: function (row) {
                    return $scope.shref('app.analyze.opportunities.listAnalysis', {
                        dateType: $scope.data.revenueType === 'pipeline' ? 'created' : 'closed',
                        type: $scope.data.revenueType,
                        wonOnly: $scope.data.revenueType !== 'pipeline',
                        wonType: $scope.data.revenueType === 'pipeline' ? 'any': 'won',
                        cohort: $scope.query.cohort,
                        closedType: $scope.data.revenueType === 'pipeline' ? 'any' : 'closed',
                        influencedCampaignGroup: row.type,
                        model: $scope.query.influenceType,
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                    });
                },
                field: function () {
                    return $scope.data.revenueType === 'pipeline' ? 'opptys_unique' : 'deals_unique';
                },
                tooltip: function () {
                    if ($scope.data.revenueType === 'pipeline') {
                        return "Count of all Opportunities with influenced by the Campaign. [Only applicable to multi-touch models]";
                    } else {
                        return "All valid touches on Opportunities by the Campaign.";
                    }
                }
            });

            $scope.data.expandedHeaders.push({
                displayName: function () {
                    if ($scope.data.revenueType === 'pipeline') {
                        return 'Opportunity Influenced';
                    }
                    else {
                        return 'Deal Influenced';
                    }
                },
                link: function (row) {
                    var url = $scope.data.revenueType === 'pipeline' ? 'app.analyze.campaigns.campaignSpecific.attribution.opportunities' : 'app.analyze.campaigns.campaignSpecific.attribution.deals';
                    return $scope.shref(url, {
                        name: row.name,
                        campaignId: row.id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.data.revenueType === 'pipeline' ? "opptyCreatedDate" : "opptyCloseDate",
                        modelType: "even",
                        tab: $scope.data.revenueType === 'pipeline' ? "opptys" : "deals",
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        influenceType: $scope.query.influenceType,
                        wonType: $scope.data.revenueType === 'pipeline' ? 'any' : 'won',
                        type: $scope.data.revenueType,
                    });
                },
                field: () => $scope.data.revenueType === 'pipeline' ? 'opptys_unique' : 'deals_unique',
            });
        }

        $scope.query = {
            cohort: 'quarter2Date',
            grp: 'only',
            revenueType: 'revenue',
            dataSet: 'opptyCloseDate',
            startDate: moment(new Date()).subtract(1, 'week')._d.getTime(),
            endDate: new Date().getTime(),
            influenceType: 'any',
        };

        $scope.$on('$destroy', function () {
            if (dataCall) { dataCall.abort(); }
        });

        utilities.combine($scope.query, $state.params);

        influenceTypes.setDefaultInfluenceType($scope);
        get_campaign_influenced_revenue_data();
    })();
}]);
