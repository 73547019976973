app.controller('waterfallConfigCtrl', ['$scope', '$http', '$window', '$location', 'utilities', '$cookies', 'api', "$state", '_', function ($scope, $http, $window, $location, utilities, $cookies, api, $state, _) {
    $scope.cookies = $cookies;
    var loadLeadCompaniesCall;
    var loadLeadDomainsCall;
    var globalPropertyCall;
    var findOlfCountCall;

    $scope.demo = $cookies.get('BrightFunnelDemo') ? angular.fromJson($cookies.get('BrightFunnelDemo')) : { companyName: 'Acme Inc.' };

    function orderStages(waterfall) {
        var s = { funnel: [], disqualified: [], trash: [] };

        waterfall.bf_stages.forEach(function (stage) {
            /*var idx = waterfall.sf_stages[stage.applies_to].indexOf(stage.stage);
            if(idx > -1){
                waterfall.sf_stages[stage.applies_to].splice(idx, 1);
            }*/
            delete waterfall.sf_stages[stage.applies_to][stage.stage];

            stage.mappings.forEach(function (mappping) {
                delete waterfall.sf_stages[stage.applies_to][mappping.name];
                /*idx = waterfall.sf_stages[stage.applies_to].indexOf(mappping);
                if(idx > -1){
                   waterfall.sf_stages[stage.applies_to].splice(idx, 1);
                }*/
            });
            if (stage.type.indexOf("funnel") > -1) {
                s.funnel.push(stage);
            } else {
                s.disqualified.push(stage);
            }
        });
        s.funnel.sort(function (a, b) {
            return a.sequence - b.sequence;
        });
        waterfall.bf_stages = s;

        //delete below and replace with .values code when supported
        var temp = [];
        for (var n in waterfall.sf_stages.lead) {
            temp.push(waterfall.sf_stages.lead[n]);
        }
        waterfall.sf_stages.lead = temp;
        var temp2 = [];
        // eslint-disable-next-line no-redeclare
        for (var n in waterfall.sf_stages.oppty) {
            temp2.push(waterfall.sf_stages.oppty[n]);
        }
        waterfall.sf_stages.oppty = temp2;

        //.values() will be supported in FF47 and Chrome51
        //waterfall.sf_stages.lead = waterfall.sf_stages.lead.values();
        //waterfall.sf_stages.oppty = waterfall.sf_stages.oppty.values();

        return waterfall;
    }

    // eslint-disable-next-line no-unused-vars
    function refresh_organizations() {
        $http.get('admin/organizations').success(function (data) {
            $scope.orgs.organizations = data;
        });
    }

    function applyMask(tab) {
        if ((tab == "permission" || tab == "users" || tab == "organization") && window.location.href.indexOf("stage.brightfunnel") > 1) {
            $scope.data.mask[tab] = true;
        } else if ((tab == "dataCycle" || tab == "olf" || tab == "waterfall") && $scope.data.stageActive && window.location.href.indexOf("app.brightfunnel") > 1) {
            $scope.data.mask[tab] = true;
        }
    }

    $scope.orgChanged = function () {
        if (loadLeadCompaniesCall) { loadLeadCompaniesCall.abort(); }
        if (loadLeadDomainsCall) { loadLeadDomainsCall.abort(); }
        // eslint-disable-next-line no-unused-vars
        var waterfallStagesCall;
        $state.current.data.loading = true;
        $scope.data.company = [];
        $scope.data.domain = [];
        $scope.data.olf = null;
        (waterfallStagesCall = api.get('load_waterfall', { orgId: $scope.query.orgId }, false, true)).then(function (data) {
            $scope.data.waterfall = orderStages(data.data.waterfall);
            $scope.getEditable().then(function (data) {
                $scope.data.waterfall.show_sf_stages = data.data.waterfall.show_sf_stages;
            });
            $state.current.data.loading = false;
        });
    };

    $scope.getEditable = function () {
        return api.get('load_config', { editable: true, orgid: $scope.query.orgId }, false, true);
    };

    $scope.addStage = function () {
        if ($scope.data.newStage.length > 0) {
            $scope.data.waterfall.bf_stages.disqualified.push({ stage: $scope.data.newStage, applies_to: "lead", sequence: null, type: "disqualified", id: null, in_camp_perf: false });
        }
    };

    $scope.makeStart = function (stage) {
        $scope.data.waterfall.bf_stages.funnel.forEach(function (item) {
            item.type = "funnel";
        });
        stage.type = "funnel,default_start_stage";
    };

    $scope.syncStages = function () {
        $state.current.data.loading = true;
        // eslint-disable-next-line no-unused-vars
        api.set('admin/sync_waterfall_stages', { orgId: $scope.query.orgId }, true).success(function (data) {
            $scope.orgChanged();
            $state.current.data.loading = false;
        });
    };

    $scope.saveStages = function () {
        $state.current.data.loading = true;
        Object.keys($scope.data.waterfall.bf_stages).forEach(function (key) {
            var sequence = 1;
            var prevStage = null;
            var startStage = null;
            $scope.data.waterfall.bf_stages[key].forEach(function (stage) {
                if (key == "funnel") {
                    stage.sequence = sequence;
                    sequence++;
                    if (stage.type.indexOf("start_stage") == -1) {
                        stage.type = "funnel";
                    } else {
                        startStage = stage;
                    }
                    if (prevStage != null && prevStage.applies_to == "lead" && stage.applies_to == "oppty") {
                        if (prevStage == startStage) {
                            startStage.type = "funnel,last_lead_stage,default_start_stage";
                        } else {
                            startStage.type = "funnel,default_start_stage";
                            prevStage.type = "funnel,last_lead_stage";
                        }
                    }
                    prevStage = stage;
                } else if (key == "disqualified") {
                    stage.sequence = null;
                    stage.type = "disqualified";

                } else {
                    stage.mappings.forEach(function (mapping) {
                        $scope.data.waterfall.sf_stages[stage.applies_to].push(mapping);
                    });
                }
            });
        });
        api.set('admin/update_waterfall_stages', { stages: $scope.data.waterfall, orgId: $scope.query.orgId }, true).success(function (data) {
            $scope.data.waterfall.bf_stages = data;
            $state.current.data.loading = false;
        });
    };

    $scope.clear_user_updates = function () {
        $scope.user_updates = {};
        $http.get('admin/users').success(function (data) {
            $scope.users = data;
            angular.forEach($scope.users, function (u) { u.updated_org_id = u.org_id; });
        });
    };

    $scope.updateOrgConf = function (save, module, field, type, value) {
        var params = { value: value, module: module, field: field, type: type, orgId: $scope.query.orgId };
        if (save) {
            // eslint-disable-next-line no-unused-vars
            api.set("admin/update_org_config", params, false).then(function (data) {
            });
        } else {
            params.delete = true;
            // eslint-disable-next-line no-unused-vars
            api.set("admin/update_org_config", params, false).then(function (data) {
                if (module == "olf" && type == "score_threshold") {
                    $scope.data.olf.score_threshold = 0;
                }
            });
        }
    };

    (function init() {
        $scope.query = {
            orgId: 0,
            pageId: "app.campaigns.attribution",
            featureId: "ceff.model.toggle"
        };
        $scope.data = {
            featureGroups: {},
            selectedFeatureGroupKey: "",
            showEditFeatureGroup: false,
            mask: { permission: false, dataCycle: false, users: false, organization: false },
            stageActive: false,
            newAccount: "",
            ignoreType: "domain",
            ignoreOptions: ['domain', 'account', 'company'],
            domain: [],
            company: [],
            marketingRoles: [
                { value: "", ui: "" },
                { value: "demand generation", ui: "Demand Generation" },
                { value: "marketing operations", ui: "Marketing Operations" },
                { value: "field marketing", ui: "Field Marketing" },
                { value: "sales manager", ui: "Sales Manager" },
                { value: "executive", ui: "Executive" },
                { value: "other marketing", ui: "Other Marketing" }
            ],
            activeOrgsOnly: true
        };
        $scope.configs = {
            page: {},
            feature: {
                "ceff.model.toggle": { key: "ceff.model.toggle", name: "Campaign Effectiveness Model Toggle", show: true },
                "tot.rev.predict": { key: "tot.rev.predict", name: "Total Revenue Predictions", show: true },
                "waterfallStages": { key: "waterfallStages", name: "Waterfall Stages", show: true },
                "disableInsights": { key: "disableInsights", name: "Insights", show: true },
                "path2Sale.touchesByCamp": { key: "path2Sale.touchesByCamp", name: "Path To Sale Campaign Touches", show: true },
                "dashboard beta": { key: "dashboard.beta", name: "dashboard beta", show: true }
            },
            org_influence_rules: { cio: [], lto: [] },
            defaultCampaignDateTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
            campaignDateTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
            opptyAmountType: ['ARR', 'MRR'],
            pipePostTouchType: ['ON', 'OFF'],
            extraCampaignDateTypes: { 16: ["Last Member Status Change"] }
        };

        $scope.query.orgId = $scope.userData.organizationId;
        $scope.orgChanged();

        function reduce() {
            // eslint-disable-next-line no-unused-vars
            var m = _.flatten($scope.subnav.map(function (c) {
                return c.children;
            }));
        }

        reduce();


        $scope.orgs = {
            new_organization_name: '',
            organization: []
        };

        angular.forEach(_.compact(_.flatten($scope.subnav.map(function (c) {
            return c.children;
        }))), function (s) {

            pushName(s);
            if (s.tabs) {
                // eslint-disable-next-line no-unused-vars
                angular.forEach(s.tabs, function (v, i) {
                    pushName(v);
                });
            }

            function pushName(obj) {
                $scope.configs.page[obj.name] = {
                    key: obj.name,
                    name: (obj.config.data.title ? obj.config.data.title : obj.config.data.staticTitle) + (obj.config.data.subtitle ? ': ' + obj.config.data.subtitle : ''),
                    show: true
                };
            }
        });
        // adding on manually that is not in the subnav
        $scope.configs.page["app.campaign.sfdc"] = { key: "app.campaign.sfdc", name: "Campaign SFDC data", show: true };


        api.get('internal_config', null, false, true).then(function (data) {
            var active = [];
            if (data.data.auth) {
                data.data.orgs.forEach(function (item) {
                    if (item.active) {
                        active.push(item);
                    }
                });
            }
            $scope.configs.orgs = [{ id: 0, name: "--Select an org--" }].concat(active);
            $scope.configs.orgsActive = [{ id: 0, name: "--Select an org--" }].concat(active);
            $scope.configs.orgsAll = [{ id: 0, name: "--Select an org--" }].concat(data.data.orgs);
            $scope.configs.influence_rules = data.data.influence_rules;
        });

        (globalPropertyCall = api.get("globalProperty", { name: "stage.active" }, false, true)).then(function (data) {
            $scope.data.stageActive = data.data[0].value == "true";
        });

        $scope.$on('$destroy', function () {
            if (loadLeadCompaniesCall) { loadLeadCompaniesCall.abort(); }
            if (loadLeadDomainsCall) { loadLeadDomainsCall.abort(); }
            if (findOlfCountCall) { findOlfCountCall.abort(); }
            if (globalPropertyCall) { globalPropertyCall.abort(); }
        });
        applyMask("waterfall");
        //		 refresh_organizations();
        //		$scope.clear_user_updates();
        $scope.show_unassigned_users = true;

    })();
}]);
