import { module as angularModule } from 'angular';

import { ISelectOption } from '../../../models';
import { APP_BRIGHTFUNNEL } from '../../../../constants';
import * as CONSTANTS from '../../constants';
import {
    TrendingKpisService,
    SRVC_TRENDING_KPIS,
} from '../../services/service';
import {
    WidgetQueryStateService,
    SRVC_WIDGET_QUERY_STATE,
} from '../../services/query.service';
import { IDateCohort } from 'src/shared/models/scorecard/date-cohort/model';
import {
    SRVC_WIDGET_RESPONSE_STATE,
    WidgetResponseStateService,
} from '../../services/kpi-response.service';
import { SRVC_TERMINUS_HUB, TerminusHubService } from '../../../terminus-hub/terminus-hub.service';

const app = angularModule(APP_BRIGHTFUNNEL);

export interface IScope {
    _: any;
    $emit: any;
    $tile: any;
    $watch: (a: string, b: (newVal: any, oldVal: any) => void, deepWatch?: boolean) => void;
    $watchGroup: (a: string[], b: (newVal: any, oldVal: any, scope: any) => void, deepWatch?: boolean) => void;
    $on: (a: string, b: () => void) => void;
    selectOptions: Record<string, ISelectOption[]>;
    widget: any;
}

export interface ITrendingKpiQuery {
    visualization: CONSTANTS.TrendingKPIVisualizationTypes;
    gaugeRangeMin: number | null;
    gaugeRangeRedYellow: number | null;
    gaugeRangeYellowGreen: number | null;
    gaugeRangeMax: number | null;
    kpi: CONSTANTS.TrendingKpiTypes;
    trendingType: CONSTANTS.TrendingKPIsTrendingTypes;
    progression: CONSTANTS.TrendingKPIsProgressionTypes;
    interval: CONSTANTS.TrendingKPIsIntervalTypes;
    groupBy: CONSTANTS.TrendingKPIsGroupingTypes;
    firmographic: CONSTANTS.TrendingKPIsFirmographicsTypes;
    trendingChartStyle: CONSTANTS.TrendingKPIsChartStyleTypes;
    accountLists: string[];
    cohort: IDateCohort;
    customStartDate: any;
    customEndDate: any;
    trendingGoal: number;
}

export abstract class BaseWidgetController {
    public abstract getData(): void;
}

export class TrendingKpisWidgetController extends BaseWidgetController {

    public getData() {
        const storedQuery = this.widgetQueryStateService.widgetQueries[this.$scope.widget.queryId];
        const isQueryValid = storedQuery.valid;
        const query = Object.keys(storedQuery.query).length ? storedQuery.query : this.$scope.widget.query;

        if (query && isQueryValid) {
            const params = this.trendingKpisService.buildKpiRequest(query as ITrendingKpiQuery);
            this.$scope.$tile.startLoading();
            this.$scope.$tile.loading(30);
            if (!this.loadingTrendingKpi) {
                this.loadingTrendingKpi = true;
                this.trendingKpisService.getKpiData(params).then((resp) => {
                    this.widgetResponseStateService.kpiResponses[this.$scope.widget.queryId] = this._.cloneDeep(resp);
                    this.$scope.$tile.loading(100);
                }).finally(() => {
                    this.$scope.$emit('widgetLoadingDone');
                    this.$scope.$tile.endLoading();
                    this.loadingTrendingKpi = false;
                });
            }
        }
    }

    public dateRangeToggleCohort = CONSTANTS.DATE_RANGE_TOGGLE_COHORT;
    public dateRangeToggleCustom = CONSTANTS.DATE_RANGE_TOGGLE_CUSTOM;

    static $inject: string[] = [
        '_',
        '$scope',
        '$parse',
        '$timeout',
        'utilities',
        SRVC_TRENDING_KPIS,
        SRVC_WIDGET_QUERY_STATE,
        SRVC_WIDGET_RESPONSE_STATE,
        SRVC_TERMINUS_HUB,
    ];

    public selectOptions: Record<string, ISelectOption[]>;
    public accountLists: ISelectOption[] = [];
    public dateCohorts: any[] = [];
    public query: ITrendingKpiQuery;
    public trendingKpisWidgetSetupForm: {
        $valid: boolean;
    };

    public dateRangeToggle = CONSTANTS.DATE_RANGE_TOGGLE_COHORT;
    public range = {
        options: {
            'min-date': new Date(),
            'year-format': '\'yy\'',
            'starting-day': 1
        },
        startDatepicker: {},
        endDatepicker: {},
        showWeeks: true,
        startingDay: 1,
        isRangeValid: false,
    };

    public loadingScorecardAggregates = false;
    public loadingDateCohorts = false;
    public loadingTrendingKpi = false;

    public get isGoalOptional() {
        return true;
    }

    public get shouldShowGroupBy() {
        return [
            CONSTANTS.VISUALIZATION_DONUT,
            CONSTANTS.VISUALIZATION_TRENDING,
        ].indexOf(this.query.visualization) > -1;
    }

    public get shouldShowInterval() {
        return this.query.visualization !== CONSTANTS.VISUALIZATION_DONUT;
    }

    public get shouldShowRanges() {
        return this.query.visualization === CONSTANTS.VISUALIZATION_GAUGE;
    }

    public get shouldCurrencySymbol() {
        return [
            CONSTANTS.KPI_PIPELINE_CREATED,
            CONSTANTS.KPI_REVENUE_WON,
        ].indexOf(this.query.kpi) > -1;
    }

    public get shouldShowPercentSymbol() {
        return [
            CONSTANTS.KPI_WIN_RATE,
        ].indexOf(this.query.kpi) > -1;
    }

    public get shouldShowRollingWindow() {
        return this.query.visualization === CONSTANTS.VISUALIZATION_DONUT
            && this.query.groupBy === CONSTANTS.KPI_GROUPING_IMPRESSIONS;
    }

    public get shouldShowTrendingChartStyles() {
        return this.query.visualization === CONSTANTS.VISUALIZATION_TRENDING;
    }

    public get shouldShowTrendingGoal(): boolean {
        if (this.query.visualization === CONSTANTS.VISUALIZATION_DONUT) {
            return true;
        }

        return [
            CONSTANTS.VISUALIZATION_PARETO,
            CONSTANTS.VISUALIZATION_TRENDING,
        ].indexOf(this.query.visualization) > -1;
    }

    public get shouldShowTrendingType() {
        return this.query.visualization === CONSTANTS.VISUALIZATION_TRENDING;
    }

    public get shouldShowFirmographicOptions() {
        return this.query.groupBy === CONSTANTS.KPI_GROUPING_FIRMOGRAPHICS;
    }

    public get isRangeMinValid() {
        return this.query.gaugeRangeMin < this.query.gaugeRangeRedYellow;
    }

    public get shouldShowRangeMinError() {
        return !!this.query.gaugeRangeMin
            && !!this.query.gaugeRangeRedYellow
            && !this.isRangeMinValid;
    }

    public get isRangeRedYellowValid() {
        return this.query.gaugeRangeRedYellow > this.query.gaugeRangeMin
            && this.query.gaugeRangeRedYellow < this.query.gaugeRangeYellowGreen;
    }

    public get shouldShowRangeRedYellowError() {
        return !!this.query.gaugeRangeMin
            && this.query.gaugeRangeRedYellow
            && this.query.gaugeRangeYellowGreen
            && !this.isRangeRedYellowValid;
    }

    public get isRangeYellowGreenValid() {
        return this.query.gaugeRangeYellowGreen > this.query.gaugeRangeRedYellow
            && this.query.gaugeRangeYellowGreen < this.query.gaugeRangeMax;
    }

    public get areAllRangesValid() {
        return this.isRangeMinValid
            && this.isRangeRedYellowValid
            && this.isRangeYellowGreenValid
            && this.isRangeMaxValid;
    }

    public get shouldShowRangeYellowGreenError() {
        return !!this.query.gaugeRangeRedYellow
            && !!this.query.gaugeRangeYellowGreen
            && !!this.query.gaugeRangeMax
            && !this.isRangeYellowGreenValid;
    }

    public get isRangeMaxValid() {
        return this.query.gaugeRangeMax > this.query.gaugeRangeYellowGreen;
    }

    public get shouldShowRangeMaxError() {
        return !!this.query.gaugeRangeYellowGreen
            && !!this.query.gaugeRangeMax
            && !this.isRangeMaxValid;
    }

    public get isFormValid() {
        return false;
    }

    public get loadingData() {
        return this.loadingScorecardAggregates
            || this.loadingDateCohorts;
    }

    public get filteredKpis () {
        return [
            CONSTANTS.VISUALIZATION_DONUT,
            CONSTANTS.VISUALIZATION_PARETO
        ].indexOf(this.query.visualization) > -1 ?
            this.selectOptions.kpis.filter((x) =>
                [
                    CONSTANTS.KPI_WIN_RATE,
                    CONSTANTS.KPI_DEAL_VELOCITY,
                    CONSTANTS.KPI_AVG_DEAL_SIZE,
                ].indexOf(x.key) === -1
            ) :
            this.selectOptions.kpis;
    }

    public get isDateRangeValid() {
        return (
            Object.prototype.toString.call(this.query.customStartDate) === '[object Date]'
            && Object.prototype.toString.call(this.query.customEndDate) === '[object Date]'
            && this.query.customStartDate <= this.query.customEndDate
        );
    }

    public isMetricDisabled(metric) {
        return this.query.visualization === CONSTANTS.VISUALIZATION_PARETO
            && metric === CONSTANTS.KPI_ACCOUNTS;
    }

    public isTrendingStyleDisabled(chartStyle) {
        return this.query.groupBy === CONSTANTS.KPI_GROUPING_NONE
            && [
                CONSTANTS.CHART_STYLE_STACKED_AREA,
                CONSTANTS.CHART_STYLE_STACKED_COLUMN,
            ].indexOf(chartStyle) > -1;
    }

    public isTrendingTypeOptionDisabled(typeOption) {
        return typeOption === CONSTANTS.TRENDING_TYPE_SNAPSHOT
            && this.query.kpi !== CONSTANTS.KPI_ACCOUNTS
            && (
                this.query.progression === CONSTANTS.PROGRESSION_TARGET_ACCOUNTS
                || this.query.progression === CONSTANTS.PROGRESSION_ENGAGED_ACCOUNTS
            );
    }

    public setQueryDefaults(overrides: Partial<ITrendingKpiQuery>) {
        this.query = {
            visualization: CONSTANTS.VISUALIZATION_TRENDING,
            gaugeRangeMin: null,
            gaugeRangeRedYellow: null,
            gaugeRangeYellowGreen: null,
            gaugeRangeMax: null,
            kpi: CONSTANTS.KPI_PIPELINE_CREATED,
            trendingType: CONSTANTS.TRENDING_TYPE_CUMULATIVE,
            progression: CONSTANTS.PROGRESSION_TARGET_ACCOUNTS,
            groupBy: CONSTANTS.KPI_GROUPING_ACCOUNT_LIST,
            firmographic: CONSTANTS.KPI_FIRMOGRAPHIC_NONE,
            interval: CONSTANTS.INTERVAL_WEEKS,
            trendingChartStyle: CONSTANTS.CHART_STYLE_STACKED_AREA,
            accountLists: [],
            cohort: null,
            customEndDate: null,
            customStartDate: null,
            trendingGoal: null,
            ...overrides
        };
    }

    clearGaugeRanges() {
        this.query.gaugeRangeMin = null;
        this.query.gaugeRangeRedYellow = null;
        this.query.gaugeRangeYellowGreen = null;
        this.query.gaugeRangeMax = null;
    }

    handleGroupByLogic() {
        if (
            this.query.firmographic === CONSTANTS.KPI_FIRMOGRAPHIC_NONE
            && this.query.groupBy === CONSTANTS.KPI_GROUPING_FIRMOGRAPHICS
        ) {
            this.query.firmographic = CONSTANTS.KPI_FIRMOGRAPHIC_REVENUE;
        } else if (this.query.groupBy !== CONSTANTS.KPI_GROUPING_FIRMOGRAPHICS) {
            this.query.firmographic = CONSTANTS.KPI_FIRMOGRAPHIC_NONE;
        }

        if (
            this.query.groupBy === CONSTANTS.KPI_GROUPING_NONE
            && [
                CONSTANTS.CHART_STYLE_STACKED_AREA,
                CONSTANTS.CHART_STYLE_STACKED_COLUMN,
            ].indexOf(this.query.trendingChartStyle) > -1
        ) {
            this.query.trendingChartStyle = CONSTANTS.CHART_STYLE_LINE;
        }
    }

    handleMetricLogic() {
        if (
            this.query.visualization === CONSTANTS.VISUALIZATION_PARETO
            && this.query.kpi === CONSTANTS.KPI_ACCOUNTS
        ) {
            this.query.kpi = CONSTANTS.KPI_OPPTYS_WON;
        }
    }

    handleTrendingTypeLogic() {
        if (
            [
                CONSTANTS.VISUALIZATION_NUMBER,
                CONSTANTS.VISUALIZATION_GAUGE,
                CONSTANTS.VISUALIZATION_DONUT,
            ].indexOf(this.query.visualization as string) > -1
        ) {
            this.query.trendingType = this.query.kpi === CONSTANTS.KPI_ACCOUNTS ?
                CONSTANTS.TRENDING_TYPE_SNAPSHOT :
                CONSTANTS.TRENDING_TYPE_CUMULATIVE;
            if (
                [
                    CONSTANTS.KPI_WIN_RATE,
                    CONSTANTS.KPI_DEAL_VELOCITY,
                    CONSTANTS.KPI_AVG_DEAL_SIZE,
                ].indexOf(this.query.kpi) > -1
                && [
                    CONSTANTS.VISUALIZATION_DONUT,
                    CONSTANTS.VISUALIZATION_PARETO,
                ].indexOf(this.query.visualization) > -1
            ) {
                this.query.kpi = this.filteredKpis[0].key as CONSTANTS.TrendingKpiTypes;
            }
        } else if (this.query.visualization === CONSTANTS.VISUALIZATION_PARETO) {
            this.query.trendingType = CONSTANTS.TRENDING_TYPE_NET;
        }
    }

    public initializingDateRangeToggle = true;

    convertToUtc(timestamp: number) {
        const now = new Date(timestamp);
        return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    }

    setupDateCohortWatch() {
        this.$scope.$watch('ctrl.dateRangeToggle', (val: string) => {
            if (this.initializingDateRangeToggle) {
                this.initializingDateRangeToggle = false;
            } else if (val === this.dateRangeToggleCustom && this.query.cohort) {
                this.query.customStartDate = this.convertToUtc(this.query.customStartDate || this.query.cohort.startDate);
                this.query.customEndDate = this.convertToUtc(this.query.customEndDate || this.query.cohort.endDate);
            } else if (val === this.dateRangeToggleCohort) {
                this.query.customStartDate = null;
                this.query.customEndDate = null;
            }
        });
    }

    setupGroupbyWatch() {
        this.$scope.$watch('ctrl.query.groupBy', () => {
            this.handleGroupByLogic();
        });
    }

    setupKpiWatch() {
        this.$scope.$watch('ctrl.query.kpi', () => {
            this.handleTrendingTypeLogic();
        });
    }

    setupProgressionWatch() {
        this.$scope.$watch('ctrl.query.progression', () => {
            this.handleTrendingTypeLogic();
        });
    }

    setupQueryServiceSubscription() {
        this.$scope.$watch('ctrl.query', (updatedQuery: ITrendingKpiQuery) => {
            this.$timeout(() => {
                if (this.query.customStartDate) {
                    this.dateRangeToggle = this.dateRangeToggleCustom;
                }
                const queryFromService = this.widgetQueryStateService.widgetQueries[this.$scope.widget.queryId];
                if (!!updatedQuery && (!queryFromService || !this._.isEqual(queryFromService.query, updatedQuery))) {
                    this.truncateNumberInputs();
                    let valid = !!this.trendingKpisWidgetSetupForm
                        && this.trendingKpisWidgetSetupForm.$valid
                        && !this.loadingData;
                    if (this.dateRangeToggle === CONSTANTS.DATE_RANGE_TOGGLE_CUSTOM) {
                        valid = valid && this.isDateRangeValid;
                    }
                    if (this.shouldShowRanges) {
                        valid = valid && this.areAllRangesValid;
                    }
                    this.widgetQueryStateService.widgetQueries[this.$scope.widget.queryId] = {
                        query: this._.cloneDeep(this.query),
                        valid,
                    };

                    this.$timeout(() => {
                        this.getData();
                    }, 1000);
                }
            }, 0);
        }, true);
    }

    setupVisualizationQueryWatch() {
        this.$scope.$watch('ctrl.query.visualization', (updatedVisual, prevVisual) => {
            if (
                prevVisual === CONSTANTS.VISUALIZATION_GAUGE
                && updatedVisual !== CONSTANTS.VISUALIZATION_GAUGE
            ) {
                this.clearGaugeRanges();
            }
            this.handleMetricLogic();
            this.handleTrendingTypeLogic();
        });

    }

    truncateNumber(number: number) {
        return parseFloat(
            parseFloat(number.toString()).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
        );
    }

    truncateNumberInputs() {
        if (this.query.gaugeRangeMin != null) {
            this.query.gaugeRangeMin = this.truncateNumber(this.query.gaugeRangeMin);
        }
        if (this.query.gaugeRangeRedYellow != null) {
            this.query.gaugeRangeRedYellow = this.truncateNumber(this.query.gaugeRangeRedYellow);
        }
        if (this.query.gaugeRangeYellowGreen != null) {
            this.query.gaugeRangeYellowGreen = this.truncateNumber(this.query.gaugeRangeYellowGreen);
        }
        if (this.query.gaugeRangeMax != null) {
            this.query.gaugeRangeMax = this.truncateNumber(this.query.gaugeRangeMax);
        }
        if (this.query.trendingGoal != null) {
            this.query.trendingGoal = this.truncateNumber(this.query.trendingGoal);
        }
    }

    setupOnDestroy() {
        this.$scope.$on('$destroy', () => {
            this.widgetQueryStateService.clearQuery(this.$scope.widget.queryId);
        });
    }

    setupWatches() {
        this.setupDateCohortWatch();
        this.setupGroupbyWatch();
        this.setupKpiWatch();
        this.setupProgressionWatch();
        this.setupOnDestroy();
        this.setupQueryServiceSubscription();
        this.setupVisualizationQueryWatch();
    }

    onSelectAccountList(_, key) {
        if (key === CONSTANTS.SELECT_ALL_KEY) {
            this.query.accountLists = this.accountLists
                .filter((x) => x.key !== CONSTANTS.SELECT_ALL_KEY)
                .map((x) => x.key);
        }
    }

    loadScorecardAggregates() {
        this.loadingScorecardAggregates = true;
        this.trendingKpisService.getScorecardAggregates()
            .then((accountLists) => {
                this.accountLists = [
                    { key: CONSTANTS.SELECT_ALL_KEY, label: CONSTANTS.SELECT_ALL_LABEL },
                ].concat(accountLists
                    .map(({ aggregateId, aggregateName }) =>
                        ({ key: aggregateId.toString(), label: aggregateName})
                    ));

                if (!this.query.accountLists.length) {
                    this.query.accountLists = accountLists.map((x) => x.aggregateId.toString());
                }
            }).finally(() => {
                this.loadingScorecardAggregates = false;
            });
    }

    loadDateCohorts() {
        this.loadingDateCohorts = true;
        this.trendingKpisService.getDateCohorts().then((resp) => {
            const allTimeGrouping = resp.specialCohorts.filter((x) => x.cohort === 'all');
            const toDateGrouping = resp.specialCohorts.filter((x) => x.name.toLowerCase().indexOf('to date') > -1);
            const lastFullGrouping = resp.specialCohorts.filter((x) => x.name.toLowerCase().indexOf('last full') > -1);
            const excludeFrom = [
                ...allTimeGrouping.map((x) => x.cohort),
                ...toDateGrouping.map((x) => x.cohort),
                ...lastFullGrouping.map((x) => x.cohort),
                'yearRoundDown',
            ];
            const agoFromTodayGrouping = resp.specialCohorts
                .filter((x) => excludeFrom.indexOf(x.cohort) === -1);
            this.dateCohorts = [
                ...allTimeGrouping,
                ...toDateGrouping.map((x) => ({ ...x, grouping: 'To Date' })),
                ...lastFullGrouping.map((x) => ({ ...x, grouping: 'Last Full' })),
                ...agoFromTodayGrouping.map((x) => ({ ...x, grouping: 'Ago From Today' })),
                ...resp.quarters.map((x) => ({ ...x, grouping: 'Quarters' })),
                ...resp.years.map((x) => ({ ...x, grouping: 'Years' })),
            ].map((x) => ({ ...x, name: x.name.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()) }));

            if (!this.query.cohort) {
                this.query.cohort = this.dateCohorts.find((x) => x.cohort === 'days90');
            }
        }).finally(() => {
            this.loadingDateCohorts = false;
        });
    }

    loadData() {
        this.loadScorecardAggregates();
        this.loadDateCohorts();
        this.getData();
    }

    openCalendar($event, model) {
        $event.preventDefault();
        $event.stopPropagation();
        return model.opened = true;
    }

    constructor(
        public _: any,
        public $scope: IScope,
        public $parse,
        public $timeout,
        public utilities,
        public trendingKpisService: TrendingKpisService,
        public widgetQueryStateService: WidgetQueryStateService,
        public widgetResponseStateService: WidgetResponseStateService,
        private terminusHubService: TerminusHubService,
    ) {
        super();
        $scope.widget.queryId = $scope.widget.queryId ? $scope.widget.queryId : utilities.generateId();

        this.widgetQueryStateService.widgetQueries[$scope.widget.queryId] = {
            query: $scope.widget.query || {},
            valid: !!$scope.widget.query,
        };
        this.selectOptions = CONSTANTS.SELECT_OPTIONS;

        if (this.selectOptions.visualizations) {
            this.selectOptions.visualizations = this.selectOptions.visualizations.filter(item => {
                return item.key !== CONSTANTS.VISUALIZATION_NUMBER;
            });
        }
        this.setQueryDefaults($scope.widget.query || {});
        this.setupWatches();
        this.loadData();
    }
}

export const CTRL_TRENDING_KPIS_WIDGET = 'trendingKPIsWidgetCtrl';
app.controller(CTRL_TRENDING_KPIS_WIDGET, TrendingKpisWidgetController);
