import * as COMPONENT_HTML from './component.html';
import './component.less';
import {IDirective, IScope,} from 'angular';

class RadioChoiceCtrl {
    static $inject: Array<string> = [];

    public constructor() {
        return;
    }
}

export class RadioChoice implements IDirective {
    public restrict = 'E';
    public controller: any = RadioChoiceCtrl;
    public controllerAs = '$ctrl';
    public bindToController = true;
    public template: any = COMPONENT_HTML;
    public scope: IScope = {
        list: '=',
        selected: '=',
        changed: '&',
    };

    public constructor() {
        return;
    }

    public static Factory(): IDirective {
        return new RadioChoice();
    }
}
