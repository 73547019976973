(function () {
    'use strict';

    app.directive('configPanel', function () {
        return {
            restrict: 'E',
            template: require('./panel.html'),
            controller: panelCtrl,
            controllerAs: "$ctrl",
            bindToController: true,
            scope: {
                title: '='
            },
            transclude: true
        };
    });

    panelCtrl.$inject = [];

    /**
     * Controller for the directive.
     */
    function panelCtrl() { }

})();
