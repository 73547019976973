// eslint-disable-next-line no-unused-vars
app.controller('userProfileCtrl', ['$rootScope', '$scope', '$http', '$window', '$location', 'utilities', 'api', '$q', '$filter', '$b', '$state', function ($rootScope, $scope, $http, $window, $location, utilities, api, $q, $filter, $b, $state) {

    $scope.updateUserPermission = function (key) {
        $rootScope.$broadcast('detailChanged', "user permissions changed");
        $scope.userData.permissions[key] = $scope.data.validUserPermission[key];
        var url = "update_user_permission";
        api.get(url, { level: key, add: $scope.userData.permissions[key] }, true, true);
    };

    (function init() {
        $scope.data = { validUserPermission: {} };
        Object.keys($scope.userData.permissions).forEach(function (key) {
            if (key != "org_admin" && key != "bf_admin") {
                $scope.data.validUserPermission[key] = $scope.userData.permissions[key];
            }
        });
    })();

}]);
