/* eslint-disable no-unused-vars */
app.controller('globalFiltersCtrl', ['$scope', '$filter', 'filters', '$state', '$http', '$q', 'api', '_', '$b', '$rootScope', '$window', '$timeout', 'hotkeys', 'noty', '$localStorage', function ($scope, $filter, filtersSrv, $state, $http, $q, api, _, $b, $rootScope, $window, $timeout, hotkeys, noty, $storage) {

    var lock = { block: true };

    $scope.openAccordion = function (e, type) {
        e.stopPropagation();
        $scope.toggleSmPanel();
        if (type === "account_list") {
            $rootScope.accountLists.open = true;
        } else {
            _.forEach($scope.data.filterContainer.tables, function (filter) {
                if (filter.table === type) {
                    filter.open = true;
                } else {
                    filter.open = false;
                }
            });
        }
    };

    $scope.resize = function () {
        $($window).resize();
    };

    $scope.deleteFilter = function () {
        $b.confirm({
            text: 'Are you sure you want to delete this filter?',
            callback: function () {
                var params = {
                    name: $scope.data.selectedSavedFilter.name
                };
                $scope.data.loading = true;
                api.set('filters/delete_saved_filter', params);
                var index = _.findIndex($scope.data.savedFilters, function (o) { return o.name === $scope.data.selectedSavedFilter.name; });
                $scope.data.savedFilters.splice(index, 1);
                $scope.data.loading = false;
                $scope.clearAll();
                $scope.toggleSaveButtons(false);
                $scope.data.selectedSavedFilter = null;
                noty.growl('The filter has been deleted.', 'information', true);
            }
        });
    };

    $scope.toggleSummary = function (e) {
        if (e) {
            e.stopPropagation();
        }
        $scope.data.summaryOpen = !$scope.data.summaryOpen;
    };

    /* function to close smaller panel*/
    $scope.toggleSmPanel = function (e, fromSaveIcon) {
        if (e) {
            e.stopPropagation();
        }
        if ($scope.data.init) {
            $scope.data.summaryOpen = false;
            $storage.panel.sm = !$storage.panel.sm;
            $storage.panel.isSmPanelCollapsed = !$storage.panel.isSmPanelCollapsed;
            $scope.data.isSmPanelCollapsed = $storage.panel.isSmPanelCollapsed;
            $scope.data.toggleModel.small = $storage.panel.sm;
            $storage.panel.showSavedReports = fromSaveIcon;
        } else {
            $scope.data.init = true;
            $rootScope.$evalAsync(function () {
                $scope.resize();
            });
        }
        $rootScope.$broadcast('largePanelOpened', $storage.panel.sm);
        $scope.data.showSavedReports = $storage.panel.showSavedReports;
    };

    /* function to close larger panel */
    $scope.togglePanel = function () {
        if ($scope.data.init) {
            $storage.panel.big = !$storage.panel.big;
            $scope.data.toggleModel.big = $storage.panel.big;
            $storage.panel.isCollapsedHorizontal = !$storage.panel.isCollapsedHorizontal;
            $scope.data.isCollapsedHorizontal = $storage.panel.isCollapsedHorizontal;
            $storage.panel.showSavedReports = $scope.data.showSavedReports;
        } else {
            $scope.data.init = true;
            $rootScope.$evalAsync(function () {
                $scope.resize();
            });
        }
    };

    $scope.applyFilters = function () {
        filtersSrv.reconcileFilterState($scope.data.filterContainer, "applied", "selected");
        filtersSrv.reconcileFolderState($rootScope.accountLists, "applied", "selected");
        $rootScope.$broadcast("filtersChanged");
    };

    $scope.selectAllInFolder = function (folder, container) {
        $rootScope.accountLists.selected += filtersSrv.selectAllInFolder(folder, container);
        $scope.toggleSaveButtons();
    };

    $scope.selectList = function (list, folder, container) {
        var totalSelected = filtersSrv.selectList(list, folder, container);
        folder.selected += totalSelected;
        $rootScope.accountLists.selected += totalSelected;
        $scope.toggleSaveButtons();
    };

    $scope.hasAccountLists = function () {
        return filtersSrv.hasAccountLists($rootScope.accountLists.folders);
    };

    $scope.filterToggled = function (data, field, table, container) {
        filtersSrv.filterToggled(data, field, table, container);
        $scope.toggleSaveButtons();
    };

    $scope.fieldToggled = function (field, table, container) {
        filtersSrv.fieldToggled(field, table, container);
        $scope.toggleSaveButtons();
    };

    $scope.toggleSaveButtons = function (override) {
        if (override !== null && override !== undefined) {
            $scope.data.enableSaveButton = override;
            $scope.data.enableSaveArrow = override;
        } else {
            $scope.data.enableSaveButton = $scope.isAFilterSelected();
            $scope.data.enableSaveArrow = $scope.isAFilterSelected();
        }
    };

    $scope.isAFilterSelected = function () {
        return $scope.data.filterContainer.selected > 0 || $rootScope.accountLists.selected > 0;
    };

    $scope.selectSavedFilter = function (savedFilter) {
        if (savedFilter) {
            filtersSrv.selectSavedFilter(savedFilter.filters, $scope.data.filterContainer);
            filtersSrv.setSelectedFilter(savedFilter);
            filtersSrv.initializeFolders($rootScope.accountLists, savedFilter.list_ids, "save");
            $scope.data.enableSaveArrow = true;
        } else {
            $scope.clearAll();
            $scope.toggleSaveButtons(false);
        }
        $scope.data.selectedSavedFilter = savedFilter;
    };

    $scope.onlySaveFilter = function () {
        if ($scope.data.selectedSavedFilter) {
            var parent = $scope;
            $b.modal({
                title: 'Confirm',
                template: '<div small-loading="data.loading"> Would you like to save and apply? </div>',
                width: '300px',
                buttons: [
                    {
                        'text': 'Yes',
                        'class': 'btn btn-sm btn-primary pull-right',
                        'ng-click': 'saveAndApply()'
                    },
                    {
                        'text': 'No',
                        'ng-click': 'save()',
                        'class': 'btn btn-sm btn-default pull-right somemargin-right'
                    }
                ],
                controller: function ($scope) {
                    $scope.toApply = false;
                    $scope.data = {};

                    $scope.saveAndApply = function () {
                        $scope.toApply = true;
                        $scope.save();
                    };

                    $scope.save = function () {
                        $scope.data = {
                            name: parent.data.selectedSavedFilter.name,
                            private: parent.data.selectedSavedFilter.private,
                            owner_id: parent.data.selectedSavedFilter.owner_id,
                            loading: true
                        };
                        $scope.data.filters = angular.toJson(filtersSrv.toSavable(parent.data.filterContainer));
                        $scope.data.listIds = filtersSrv.getSelectedAccountLists($rootScope.accountLists.folders);
                        api.set('filters/update_saved_filter', $scope.data).success(function (data) {
                            api.get('filters/load_saved_filters', null, false, true).then(function (data) {
                                angular.forEach(data.data, function (v) {
                                    if (v.private) {
                                        v.label = "Personal";
                                    } else {
                                        v.label = "Organization";
                                    }
                                });
                                parent.data.savedFilters = data.data;
                                parent.data.loading = false;
                                $scope.$modal.closeModal();

                                if ($scope.toApply) {
                                    parent.applyFilters();
                                    noty.growl('Your filter has been saved and applied.', 'information', true);
                                }
                                else {
                                    noty.growl('Your filter has been saved.', 'information', true);
                                }
                            });
                        });
                    };
                } //end of controller
            }); //end of $b.modal
        } else {
            $scope.openSaveModal();
        }
    };

    $scope.clearAll = function () {
        filtersSrv.initializeFilters($scope.data.filterContainer, {}, "save");
        filtersSrv.initializeFolders($rootScope.accountLists, [], "save");
        $scope.data.selectedSavedFilter = null;
    };

    $scope.isApplyEnabled = function () {
        return !$scope.data.apiInProgress &&
            ($scope.data.filterContainer.select_apply_diff !== 0 || $rootScope.accountLists.select_apply_diff !== 0);
    };

    $scope.hasFilter = function (item) {
        return item.filters.length ? _.includesPart(_.crush(item), $scope.data.searchTerm) : false;
    };

    $scope.openSaveModal = function () {
        var parent = $scope;
        $b.modal({
            title: 'Save Filter',
            id: 'saveFilterModal',
            template: require('../../main/filters/saveModal.html'),
            width: '400px',
            buttons: [
                {
                    'text': 'Save',
                    'class': 'btn btn-sm btn-primary pull-right',
                    'ng-click': 'saveFilter()',
                    'ng-disabled': '!data.name || data.loading'
                },
                {
                    'text': 'Cancel',
                    'ng-click': 'closeModal()',
                    'class': 'btn btn-sm btn-default pull-right somemargin-right',
                    'ng-disabled': 'data.loading'
                }
            ],
            controller: function ($scope) {
                $scope.data = {};
                $scope.data.private = true;

                $scope.closeModal = function () {
                    $scope.$modal.closeModal();
                };

                $scope.saveFilter = function () {
                    $scope.data.loading = true;
                    $scope.data.filters = angular.toJson(filtersSrv.toSavable(parent.data.filterContainer));
                    $scope.data.listIds = filtersSrv.getSelectedAccountLists($rootScope.accountLists.folders);
                    api.set('filters/update_saved_filter', $scope.data).success(function (data) {
                        api.get('filters/load_saved_filters', null, false, true).then(function (data) {
                            angular.forEach(data.data, function (v, i) {
                                if (v.private) {
                                    v.label = "Personal";
                                } else {
                                    v.label = "Organization";
                                }
                            });
                            parent.data.savedFilters = data.data;
                            parent.data.loading = false;
                            $scope.$modal.closeModal();
                            parent.data.selectedSavedFilter = $scope.data;
                            parent.data.selectedSavedFilter.applied = true;
                            noty.growl('Your filter has been saved.', 'information', true);
                            $scope.$modal.closeModal();
                        });
                    });
                };
            }
        });
    };

    (function init() {
        $scope.$filtersSrv = filtersSrv;
        $scope.$storage = $storage;
        $scope.data = {
            init: false,
            enabledFilterCount: filtersSrv.computeValidFiltersForReport($rootScope.filters, $state.current),
            filterContainer: $rootScope.filters,
            status: {},
            showFilters: true,
            oneAtATime: true,
            loading: false,
            savedFilters: filtersSrv.getSavedFilters(),
            selectedSavedFilter: filtersSrv.getSelectedFilter(),
            toggleModel: filtersSrv.getToggleModel(),
            enableSaveButton: false,
            summaryOpen: false
        };

        $scope.data.enableSaveArrow = $scope.isAFilterSelected();

        // has local storage or big panel is collapsed
        if (!_.has($storage, 'panel') || $storage.panel.isCollapsedHorizontal) {
            $scope.data.toggleModel.no = false;
            $scope.data.toggleModel.small = true;
            $scope.data.toggleModel.big = false;
            //if small panel is collapsed
        } else if ($storage.panel.isSmPanelCollapsed) {
            $scope.data.toggleModel.big = true;
            $scope.data.toggleModel.small = false;
            $scope.data.toggleModel.no = false;
        }
        if (!_.has($storage, 'panel')) {
            $storage.panel = {};
            $storage.panel.isCollapsedHorizontal = true;
            $storage.panel.isSmPanelCollapsed = false;
            $storage.panel.big = $scope.data.toggleModel.big;
            $storage.panel.sm = $scope.data.toggleModel.small;
            $scope.data.isCollapsedHorizontal = $storage.panel.isCollapsedHorizontal;
            $scope.data.isSmPanelCollapsed = $storage.panel.isSmPanelCollapsed;
        } else {
            $scope.data.isCollapsedHorizontal = $storage.panel.isCollapsedHorizontal;
            $scope.data.isSmPanelCollapsed = $storage.panel.isSmPanelCollapsed;
            $storage.panel.big = $scope.data.toggleModel.big;
            $storage.panel.sm = $scope.data.toggleModel.small;
        }
        if (!$storage.panel.showSavedReports) {
            $storage.panel.showSavedReports = false;
        }

        $scope.data.showSavedReports = $storage.panel.showSavedReports;

        var bounceSearch = _.debounce(function (searchTerm) {
            $scope.$evalAsync(filtersSrv.searchFilter($scope.data.filterContainer, $rootScope.accountLists, searchTerm, $scope.data));
        }, 500);

        hotkeys.bindTo($scope)
            .add({
                combo: 'ctrl+g',
                description: 'Toggle the global filters.',
                allowIn: ['INPUT'],
                callback: function () {
                    if (!$storage.panel.isCollapsedHorizontal) {
                        $scope.togglePanel();
                    } else {
                        $scope.toggleSmPanel();
                    }
                }
            });

        $rootScope.$on('$stateChangeSuccess', function () {
            $scope.data.enabledFilterCount = filtersSrv.computeValidFiltersForReport($rootScope.filters, $state.current);
        });

        $scope.$watch('data.searchTerm', bounceSearch);

        $scope.$on('$stateChangeStart', function () {
            filtersSrv.reconcileFilterState($scope.data.filterContainer, "selected", "applied");
            filtersSrv.reconcileFolderState($rootScope.accountLists, "selected", "applied");
        });

        $scope.$on('$apiStart', function () {
            $scope.data.apiInProgress = true;
        });

        $scope.$on('$apiFinish', function (s, success) {
            $scope.data.apiInProgress = false;
        });

    })();
}]);
