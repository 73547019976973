// eslint-disable-next-line no-unused-vars
app.controller('internalConfigCtrl', ['$scope', '$http', '$window', '$location', 'utilities', '$cookies', 'api', "$state", '_', function ($scope, $http, $window, $location, utilities, $cookies, api, $state, _) {
    $scope.cookies = $cookies;
    var loadLeadCompaniesCall;
    var loadLeadDomainsCall;
    // eslint-disable-next-line no-unused-vars
    var globalPropertyCall;
    // eslint-disable-next-line no-unused-vars
    var findOlfCountCall;
    //
    //	$scope.demo = $cookies.get('BrightFunnelDemo') ? angular.fromJson($cookies.get('BrightFunnelDemo')) : {companyName:'Acme Inc.'};
    //
    //	function hideFeaturesAndPagesInGroup(){
    //		for(var p in $scope.configs.page){
    //			$scope.configs.page[p].show = true;
    //		}
    //		for(var f in $scope.configs.feature){
    //			$scope.configs.feature[f].show = true;
    //		}
    //		angular.forEach($scope.data.featureGroups[$scope.data.selectedFeatureGroupKey], function(f){
    //			$scope.configs[f.type][f.child].show = false;
    //		});
    //	}
    //
    function orderStages(waterfall) {
        var s = { funnel: [], disqualified: [], trash: [] };

        waterfall.bf_stages.forEach(function (stage) {
            var idx = waterfall.sf_stages[stage.applies_to].indexOf(stage.stage);
            if (idx > -1) {
                waterfall.sf_stages[stage.applies_to].splice(idx, 1);
            }

            stage.mappings.forEach(function (mappping) {
                idx = waterfall.sf_stages[stage.applies_to].indexOf(mappping);
                if (idx > -1) {
                    waterfall.sf_stages[stage.applies_to].splice(idx, 1);
                }
            });
            if (stage.type.indexOf("funnel") > -1) {
                s.funnel.push(stage);
            } else {
                s.disqualified.push(stage);
            }
        });
        s.funnel.sort(function (a, b) {
            return a.sequence - b.sequence;
        });
        waterfall.bf_stages = s;

        return waterfall;
    }

    //	function refresh_organizations() {
    //		$http.get('admin/organizations').success(function(data) {
    //			$scope.orgs.organizations = data
    //		});
    //	}
    //
    //    function applyMask(tab){
    //        if((tab == "permission" || tab == "users" || tab == "organization") && window.location.href.indexOf("stage.brightfunnel") > 1 ){
    //            $scope.data.mask[tab] = true;
    //        } else if ( (tab == "dataCycle" || tab == "olf" || tab == "waterfall") && $scope.data.stageActive && window.location.href.indexOf("app.brightfunnel") > 1) {
    //            $scope.data.mask[tab] = true;
    //        }
    //    }
    //
    // eslint-disable-next-line no-unused-vars
    function noDupesWhenFull(master, dupes, type) {
        if (dupes[type] && master.length) {
            dupes[type].all.forEach(function (dupe) {
                var idx = master.findIndex(function (ele) { return dupe.item == ele.item; });
                if (idx > -1) {
                    master.splice(idx, 1);
                }
            });
            dupes[type].org.forEach(function (dupe) {
                var idx = master.findIndex(function (ele) { return dupe.item == ele.item; });
                if (idx > -1) {
                    master.splice(idx, 1);
                }
            });
        }
    }

    $scope.orgChanged = function () {
        if (loadLeadCompaniesCall) { loadLeadCompaniesCall.abort(); }
        if (loadLeadDomainsCall) { loadLeadDomainsCall.abort(); }
        $state.current.data.loading = true;
        $scope.data.company = [];
        $scope.data.domain = [];
        $scope.data.olf = null;
        $http.get('admin/org_configurations?orgId=' + $scope.query.orgId).success(function (data) {
            $scope.hidden = data.features;
            $scope.query.groupId = Object.keys(data.groups)[0];
            $scope.org_info = data.org_info;

            $scope.data.featureGroups = data.groups;
            $scope.data.users = data.users;
            $scope.data.org_influence_rules = data.influence_rules;
            $scope.data.waterfall = orderStages(data.waterfall);
            $scope.data.olf = data.olf;
            $scope.data.oppty = data.oppty;
            $scope.data.attribution = data.attribution;
        });
    };
    //
    //	$scope.addStage = function(){
    //		if($scope.data.newStage.length > 0){
    //			$scope.data.waterfall.bf_stages.disqualified.push({stage: $scope.data.newStage, applies_to: "oppty", sequence: null, type: "disqualified", id:null, in_camp_perf: false});
    //		}
    //	};
    //
    //	$scope.makeStart = function(stage){
    //		$scope.data.waterfall.bf_stages.funnel.forEach(function(item){
    //			item.type = "funnel";
    //		});
    //		stage.type = "funnel,default_start_stage";
    //	};
    //
    //	$scope.saveStages = function() {
    //		$state.current.data.loading = true;
    //		Object.keys($scope.data.waterfall.bf_stages).forEach(function(key){
    //			var sequence = 1;
    //			var prevStage = null;
    //            var startStage = null;
    //			$scope.data.waterfall.bf_stages[key].forEach(function(stage){
    //				if(key == "funnel") {
    //					stage.sequence = sequence;
    //					sequence++;
    //					if(stage.type.indexOf("start_stage") == -1){
    //						stage.type = "funnel";
    //					} else {
    //                        startStage = stage;
    //                    }
    //					if(prevStage != null && prevStage.applies_to == "lead" && stage.applies_to == "oppty"){
    //                        if(prevStage == startStage){
    //                            startStage.type = "funnel,last_lead_stage,default_start_stage"
    //                        } else {
    //                            startStage.type = "funnel,default_start_stage";
    //                            prevStage.type = "funnel,last_lead_stage";
    //                        }
    //					}
    //					prevStage = stage;
    //				} else if(key == "disqualified") {
    //					stage.sequence = null;
    //					stage.type = "disqualified";
    //
    //				} else {
    //                    stage.mappings.forEach(function(mapping){
    //                        $scope.data.waterfall.sf_stages[stage.applies_to].push(mapping)
    //                    });
    //                }
    //			});
    //		});
    //		api.set('admin/update_waterfall_stages', {stages: $scope.data.waterfall, orgId: $scope.query.orgId}, true).success(function(data){
    //			$scope.data.waterfall.bf_stages = data;
    //            $state.current.data.loading = false;
    //		});
    //	};
    //
    //	$scope.userButtonText = function(key, value){
    //		return (value ? "Remove " : "Make ") + key;
    //	};
    //
    //	$scope.updateUserPermission = function(user, key){
    //		var url = "admin/update_user_permission?";
    //		url += utilities.param.toQueryString({username: user.username, level: key, add:user.permissions[key]});
    //		$http.get(url)
    //
    //	};
    //
    //    $scope.updateUserEnabled = function(user){
    //        var url = "admin/update_user_enabled?";
    //        url += utilities.param.toQueryString({username: user.username, enabled: user.enabled});
    //        $http.get(url)
    //
    //    };
    //
    //    $scope.updateMarketingRole = function(user){
    //        var url = "admin/update_user_marketing_role";
    //        api.get(url, {username: user.username, role: user.marketing_role}, false, true);
    //    };
    //
    //	$scope.add_hide_feature = function(status, type){
    //		var uri = 'admin/add_feature_state?';
    //		uri += utilities.param.toQueryString({
    //			name:$scope.query[type + "Id"],
    //			status: status,
    //			type: type,
    //			orgId: $scope.query.orgId
    //		});
    //
    //		$http.get(uri).success(function(data) {
    //			$scope.hidden[type] = data;
    //		});
    //	};
    //
    //	$scope.remove_hide_feature = function(obj){
    //		var uri = 'admin/remove_feature_state?';
    //		uri += utilities.param.toQueryString({
    //			id:obj.id
    //		});
    //
    //		$scope.hidden[obj.type].splice([$scope.hidden[obj.type].indexOf(obj)], 1);
    //
    //		$http.get(uri).success(function(data) { });
    //	};
    //
    //	$scope.add_feature_group = function(){
    //		$scope.data.featureGroups[$scope.data.newFeatureGroup] = [];
    //		$scope.data.selectedFeatureGroupKey = $scope.data.newFeatureGroup;
    //	};
    //
    //	$scope.edit_feature_group = function(key){
    //		$scope.data.selectedFeatureGroupKey = key;
    //		$scope.data.showEditFeatureGroup = true;
    //		hideFeaturesAndPagesInGroup();
    //	};
    //
    //	$scope.add_to_group = function(feature, type) {
    //		$scope.configs[type][feature.key].show = false;
    //		var uri = 'admin/add_feature_to_group?';
    //		uri += utilities.param.toQueryString({
    //			name: $scope.data.selectedFeatureGroupKey,
    //			child: feature.key,
    //			type: type,
    //			orgId: $scope.query.orgId
    //		});
    //
    //		$http.get(uri).success(function(data) {
    //			$scope.data.featureGroups[$scope.selectedFeatureGroupKey] = data;
    //		});
    //	};
    //
    //	$scope.remove_from_group = function(feature) {
    //		$scope.configs[feature.type][feature.child].show = true;
    //		$scope.data.featureGroups[$scope.data.selectedFeatureGroupKey].splice($scope.data.featureGroups[$scope.data.selectedFeatureGroupKey].indexOf(feature), 1);
    //		var uri = 'admin/remove_feature_from_group?';
    //		uri += utilities.param.toQueryString({
    //			id: feature.id
    //		});
    //
    //		$http.get(uri).success(function(data) { });
    //	};
    //
    //	$scope.setDemoFlag = function() {
    //		$cookies.put('BrightFunnelDemo',angular.toJson(angular.copy($scope.demo)));
    //	};
    //
    //	$scope.removeDemoFlag = function() {
    //		$cookies.remove('BrightFunnelDemo');
    //	};
    //
    //
    //
    //	$scope.update_user = function(user) {
    //		$scope.user_updates[user.user_id] = user;
    //	};
    //
    //	$scope.clear_user_updates = function() {
    //		$scope.user_updates = {};
    //		$http.get('admin/users').success(function(data) {
    //			$scope.users = data;
    //			angular.forEach($scope.users, function(u) {u.updated_org_id = u.org_id})
    //		});
    //	};
    //
    //	$scope.apply_user_updates = function() {
    //		var updates = [];
    //		angular.forEach($scope.user_updates, function(user, key) {
    //				updates.push({user_id: user.user_id, org_id: user.updated_org_id})
    //			}
    //		);
    //		$http.post("admin/assign_user_to_org", updates).success($scope.clear_user_updates)
    //	};
    //
    //	$scope.hasUpdates = function() {
    //		var value = !($.isEmptyObject($scope.user_updates));
    //		return value;
    //	};
    //
    //	$scope.updateOrgInfluence = function(rule) {
    //		var rules = $scope.data.org_influence_rules[rule.type];
    //
    //		var params = {
    //			rules: rules,
    //			type: rule.type,
    //			orgId: $scope.query.orgId
    //		};
    //		api.set('admin/update_influence_rules', params, true).success(function(){
    //
    //		});
    //	};
    //
    //    $scope.findOlfCount = function() {
    //				if (findOlfCountCall) { findOlfCountCall.abort(); }
    //        $state.current.data.loading = true;
    //        var params = {threshold: $scope.data.olf.score_threshold, orgId: $scope.query.orgId};
    //        (findOlfCountCall = api.get("admin/find_olf_count", params, false, true)).then(function(data){
    //            $scope.data.olf.threshold_count = data.data;
    //            $state.current.data.loading = false;
    //        })
    //    };
    //
    //    $scope.updateOrgConf = function(save, module, field, type, value){
    //        var params = { value: value, module: module, field: field, type: type, orgId: $scope.query.orgId };
    //        if(save){
    //            api.set("admin/update_org_config", params, false).then(function(data){
    //            })
    //        } else {
    //            params.delete = true;
    //            api.set("admin/update_org_config", params, false).then(function(data){
    //                if(module == "olf" && type == "score_threshold"){
    //                    $scope.data.olf.score_threshold = 0;
    //                }
    //            })
    //        }
    //    };
    //
    //    $scope.updateOrgData = function(field){
    //        api.set("admin/update_org_data", {field: field, value: $scope.org_info[field], orgId: $scope.query.orgId}, false).then(function(data){
    //        })
    //    };
    //
    //    $scope.addIgnore = function(type) {
    //        $state.current.data.loading = true;
    //        var params = {
    //            items: $scope.data.olf["ignore_" + type].org,
    //            openItems: $scope.data[type],
    //            orgId: $scope.query.orgId,
    //            type: type
    //        };
    //        api.set("admin/add_ignore", params, true).then(function(data){
    //            $scope.data.olf["ignore_" + type].org = data.data;
    //            $state.current.data.loading = false;
    //        })
    //    };
    //
    //    $scope.addIgnoreAccount = function() {
    //        var exists = false;
    //        $scope.data.olf.ignore_account.forEach(function(a){
    //            if(a.account_id == $scope.data.newAccount.id){
    //                exists = true;
    //            }
    //        });
    //        if(!exists){
    //            $state.current.data.loading = true;
    //            var params = {accountId: $scope.data.newAccount.id, accountName: $scope.data.newAccount.name, orgId: $scope.query.orgId};
    //            api.set("admin/add_ignore_account", params, false).then(function(data){
    //                $scope.data.olf.ignore_account = data.data;
    //                $state.current.data.loading = false;
    //            })
    //        }
    //    };
    //
    //    $scope.removeIgnoreItem = function(item, type){
    //        $state.current.data.loading = true;
    //        var params = {id: item.id, type: type};
    //        api.set("admin/remove_ignore_olf", params, false).then(function(data){
    //            $scope.data.olf["ignore_"+type].splice($scope.data.olf["ignore_"+type].indexOf(item), 1);
    //            $state.current.data.loading = false;
    //        })
    //    };
    //
    //    $scope.getAccounts = function(val){
    //        return $http.get('api/v1/account_names_like', { params: { likeName: val, orgId: $scope.query.orgId }
    //        }).then(function(response){
    //            return response.data
    //        });
    //    };
    //
    //	$scope.addOrganization = function() {
    //		$http.post("admin/add_new_organization", {name: $scope.orgs.new_organization_name}).success(function() {
    //			refresh_organizations();
    //		})
    //	};
    //
    //    $scope.tabClick = function(tab){
    //        applyMask(tab);
    //    };
    //
    $scope.toggleActiveOrgs = function () {
        $scope.configs.orgs = $scope.data.activeOrgsOnly ? $scope.configs.orgsActive : $scope.configs.orgsAll;
    };

    (function init() {
        $scope.query = {
            orgId: 2,
            pageId: "app.campaigns.attribution",
            featureId: "ceff.model.toggle"
        };
        $scope.data = {
            featureGroups: {},
            selectedFeatureGroupKey: "",
            showEditFeatureGroup: false,
            mask: { permission: false, dataCycle: false, users: false, organization: false },
            stageActive: false,
            newAccount: "",
            ignoreType: "domain",
            ignoreOptions: ['domain', 'account', 'company'],
            domain: [],
            company: [],
            marketingRoles: [
                { value: "", ui: "" },
                { value: "demand generation", ui: "Demand Generation" },
                { value: "marketing operations", ui: "Marketing Operations" },
                { value: "field marketing", ui: "Field Marketing" },
                { value: "sales manager", ui: "Sales Manager" },
                { value: "executive", ui: "Executive" },
                { value: "other marketing", ui: "Other Marketing" }
            ],
            activeOrgsOnly: true
        };

        $scope.configs = {
            page: {},
            feature: {
                "ceff.model.toggle": { key: "ceff.model.toggle", name: "Campaign Effectiveness Model Toggle", show: true },
                "tot.rev.predict": { key: "tot.rev.predict", name: "Total Revenue Predictions", show: true },
                "waterfallStages": { key: "waterfallStages", name: "Waterfall Stages", show: true },
                "disableInsights": { key: "disableInsights", name: "Insights", show: true },
                "path2Sale.touchesByCamp": { key: "path2Sale.touchesByCamp", name: "Path To Sale Campaign Touches", show: true },
            },
            org_influence_rules: { cio: [], lto: [] },
            defaultCampaignDateTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
            campaignDateTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
            opptyAmountType: ['ARR', 'MRR'],
            pipePostTouchType: ['ON', 'OFF'],
            extraCampaignDateTypes: { 16: ["Last Member Status Change"] }
        };


        //		function reduce() {
        //			var m = _.flatten($scope.subnav.map(function(c){
        //				return c.children;
        //			}));
        //		}
        //
        //		reduce();
        //
        //
        //		$scope.orgs = {
        //			new_organization_name: '',
        //			organization: []
        //		};
        //
        //		angular.forEach(_.compact(_.flatten($scope.subnav.map(function(c){
        //			return c.children;
        //		}))),function(s){
        //
        //			pushName(s);
        //			if(s.tabs) {
        //				angular.forEach(s.tabs,function (v,i) {
        //					pushName(v);
        //				});
        //			}
        //
        //			function pushName (obj) {
        //				$scope.configs.page[obj.name] = {
        //					key : obj.name,
        //					name : (obj.config.data.title ? obj.config.data.title : obj.config.data.staticTitle) + (obj.config.data.subtitle ? ': ' + obj.config.data.subtitle : ''),
        //					show : true
        //				};
        //			}
        //		});
        //        // adding on manually that is not in the subnav
        //        $scope.configs.page["app.campaign.sfdc"] = {key:"app.campaign.sfdc", name:"Campaign SFDC data", show: true} ;
        //
        //
        $http.get('admin/internal_config').success(function (data) {
            var active = [];
            data.orgs.forEach(function (item) {
                if (item.active) {
                    active.push(item);
                }
            });
            $scope.configs.orgs = [{ id: 0, name: "--Select an org--" }].concat(active);
            $scope.configs.orgsActive = [{ id: 0, name: "--Select an org--" }].concat(active);
            $scope.configs.orgsAll = [{ id: 0, name: "--Select an org--" }].concat(data.orgs);
            $scope.configs.influence_rules = data.influence_rules;
        });
        //
        //        (globalPropertyCall = api.get("globalProperty", {name: "stage.active"}, false, true)).then(function(data){
        //            $scope.data.stageActive = data.data[0].value == "true";
        //        });
        //
        //				$scope.$on('$destroy', function() {
        //					if (loadLeadCompaniesCall) { loadLeadCompaniesCall.abort(); }
        //					if (loadLeadDomainsCall) { loadLeadDomainsCall.abort(); }
        //					if (findOlfCountCall) { findOlfCountCall.abort(); }
        //					if (globalPropertyCall) { globalPropertyCall.abort(); }
        //				});
        //
        //        applyMask("permission");
        //		refresh_organizations();
        //		$scope.clear_user_updates();
        //		$scope.show_unassigned_users = true;
        //
    })();
}]);
