/**
 * Fetches topics data.
 */
(function () {
    'use strict';

    angular
        .module('bombora')
        .factory('topicsSrvc', topicsSrvc);

    topicsSrvc.$inject = ['$http', '$q', 'BomboraConstants', 'environmentSrvc'];

    /**
     * Service to handle topics data.
     */
    function topicsSrvc($http, $q, BomboraConstants, environmentSrvc) {
        /**
         * Environment property containing bombora config endpoint
         * @type {string}
         */
        var bomboraUrl = "BOMBORA_URL";
        /**
         * Minimum number of results, if possible.
         * @type {number}
         */
        var MIN_RESULTS = 30;
        /**
         * This service
         * @type {{}}
         */
        var service = {};
        /**
         * The topics to select
         * @type {!Object}
         */
        var topics__ = {};
        /**
         * Stores the params from a previous request
         * @type {{}}
         */
        var topicsParams = {};

        function emptyTheme(theme) {
            return !theme || theme.key === null;
        }
        /**
         * Get Bombora endpoint
         * @return {Promise<*>} A promise with the url to use
         */
        function getBomboraConfiguration() {
            return environmentSrvc.getVariable(bomboraUrl);
        }


        /**
         * Private method to perform HTTP posts
         * @param url The URL
         * @param params The JSON object to send
         * @param actionFn Call back that returns a value given the request
         * @param emptyMsg Message to display if there are no results
         */
        function postRequest(url, params) {
            return getBomboraConfiguration().then(function (host) {
                return $http.post(host + url, JSON.stringify(params), { withCredentials: false }).then(
                    function (data) {
                        return { key: params, value: data.data };
                    });
            });
        }

        /**
         * Get a list of all the themes
         * @return {Promise<{key: number, value: *}[]>}
         */
        service.getThemes = function () {
            return postRequest(BomboraConstants.API.THEMES, {}).then(function (response) {
                if (response && response.value && response.value.Themes) {
                    return response.value.Themes.map(
                        function (value) {
                            var result = { key: value.ID, value: value.Name };
                            return result;
                        }
                    );
                }
                return $q.reject('No themes');
            },
            null
            );
        };

        /**
         * Get a list of all the categories
         * @param theme The previous selected Theme
         * @return {Promise<{key: *, value: *}[]>} The list of categories for the theme
         */
        service.getCategories = function (theme) {
            return emptyTheme(theme) ? $q.when([]) :
                postRequest(BomboraConstants.API.CATEGORIES, { ThemeID: theme.key }).then(function (response) {
                    if (response && response.value && response.value.Categories) {
                        return response.value.Categories.map(
                            function (value) {
                                var result = {
                                    key: { key: theme.key, value: value.ID },
                                    value: value.Name
                                };
                                return result;
                            }
                        );
                    }
                    return $q.reject('No categories');
                }
                );
        };
        /**
         * Gets a list of topics base on the selection filter
         * @param selected The theme/category selected
         * @param text The search text
         * @param next The next page token
         * @return {Promise<Array>} The list of topics
         */
        service.getTopics = function (selected = undefined,
            text = undefined,
            next = undefined) {
            var params = {};
            if (next) {
                Object.assign(params, topicsParams);
                params['next'] = next;
            } else {
                if (text) {
                    params['q'] = text;
                }
                if (selected && selected.key) {
                    if (typeof selected.key === 'string') {
                        params['theme_id'] = selected.key;
                    } else if (typeof selected.key === 'object') {
                        params['category_id'] = selected.key.value;
                    }
                }
                topicsParams = Object.assign({}, params);
            }
            return postRequest(BomboraConstants.API.TOPICS, params).then(function (response) {
                if (response && response.value && response.value.Topics) {
                    if (!next) {
                        topics__ = {};
                    }
                    var data = response.value.Topics.map(
                        function (value) {
                            var result = {
                                key: value.ID,
                                value: value.Name
                            };
                            topics__[value.ID] = value.Name;
                            return result;
                        }
                    );
                    if (Object.keys(topics__).length <= MIN_RESULTS &&
                        response.value.Next) {
                        return service.getTopics(null, null, response.value.Next);
                    } else {
                        return { topics: data, next: response.value.Next };
                    }
                }
                return $q.reject('No topics');
            }
            );
        };

        /**
         * Load topics from the server
         * @param orgID The organization UUID
         * @return {Promise<*>}
         */
        service.loadTopics = function (orgID) {
            var params = { 'OrgID': `${orgID}` };

            return postRequest(BomboraConstants.API.LOAD_TOPICS, params).then(function (response) {
                if (response && response.value && response.value.Topics) {
                    var data = response.value.Topics.map(
                        function (value) {
                            var result = {
                                key: value.ID,
                                value: value.Name
                            };
                            return result;
                        }
                    );
                    return { topics: data, threshold: response.value.Threshold };
                }
                return $q.reject('No topics');
            }
            );
        };

        /**
         * Save settings into the server
         * @param orgID The organization UUID
         * @param topics The list of topics
         * @param threshold The threshold
         * @return {Promise<*>}
         */
        service.saveTopics = function (orgID, topics, threshold) {
            var params = {
                'OrgID': `${orgID}`,
                'Topics': [...new Set(topics.map(function (value) {
                    return `${value.key}`;
                }))],
                'Threshold': parseInt(threshold)
            };
            return postRequest(BomboraConstants.API.SAVE_TOPICS, params).then(function (response) {
                if (response.value.Success) {
                    return $q.when();
                } else {
                    // TODO: Is this response.value.Message or response.Message
                    return $q.reject(response.value.Message);
                }
            }
            );
        };
        /**
         * Filter topics
         * @param selectedList
         * @return {Array}
         */
        service.filterSelected = function (selectedList) {
            var tmp = {};
            var result = [];
            if (selectedList) {
                selectedList.forEach(function (item) {
                    tmp[item.key] = {};
                });
            }
            Object.keys(topics__).forEach(function (key) {
                if (!tmp[key]) {
                    result.push({ key: key, value: topics__[key] });
                }

            });
            return result;
        };

        service.hasPermissionToEdit = function (userData) {
            return userData.permissions.org_admin || userData.permissions.bf_admin;
        };

        /**
         * These are exposed only for test purposes
         * @type {{makeRequest: (function(*=, *=): Promise<any>), renewToken: renewToken}}
         * @private
         */
        service.__testonly__ = {
            postRequest,
            topics__,
            getBomboraConfiguration
        };

        return service;
    }
})();
