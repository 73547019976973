/* eslint-disable no-console */
app.controller('fieldMappingCtrl', ['$http', 'api', '$scope', '$rootScope', '$state', '$q', 'noty', '_', function ($http, api, $scope, $rootScope, $state, $q, noty, _) {

    function getSchemaConfig() {
        api.get('sfdc/load_schema_config', {}, false, true).then(function (data) {
            $scope.schema = data.data;
            pullConfigs();
        });
    }

    function pullConfigs() {
        $state.current.data.loading = true;
        api.get('readConfig/fetch_field_mappings', {}, false, true).then(function (data) {

            angular.forEach(data.data, function (field) {
                $scope.data.field_mapping[field.object][field.mapping] = {};
                if (field.mapping === 'attribution' || field.mapping === 'template_type') {
                    var label = field.object === 'campaign_member' ? findLabelWithName($scope.templates.attribution_type, field.value) : field.object === 'opportunity_history' ? findLabelWithName($scope.templates.oppty_template_type, field.value) : findLabelWithName($scope.templates.lead_template_type, field.value);
                    $scope.data.field_mapping[field.object][field.mapping].sfdc_field = { label: label, name: field.value };
                    $scope.data.field_mapping[field.object][field.mapping].disabled = field.override;
                    $scope.data.field_mapping[field.object][field.mapping].display_text = label;
                }
                else if (field.mapping === 'wf_mapping') {
                    $scope.data.field_mapping[field.object][field.mapping].sfdc_field = field.value == '0' ? { label: false, name: false } : { label: true, name: true };
                    $scope.data.field_mapping[field.object][field.mapping].disabled = field.override;
                }
                else {
                    $scope.data.field_mapping[field.object][field.mapping].sfdc_field = field.value == 'false' ? { label: false } : field.value == 'true' ? { label: true } : findFieldWithLabel(field.object, field.value);
                    $scope.data.field_mapping[field.object][field.mapping].disabled = field.override;
                }
                if ($scope.data.field_mapping[field.object][field.mapping].sfdc_field && $scope.data.field_mapping[field.object][field.mapping].disabled) {
                    $scope.data.field_mapping[field.object][field.mapping].display_text = '(Overwritten)';
                }
            });

            $state.current.data.loading = false;
            //TODO: contact_to_lead fields are getting nulled out here.  Needs investigation
            api.get('readConfig/fetch_org_mappings', {}, false, true).then(function (data) {
                $scope.data.org_mapping = data.data;

                if ($scope.data.org_mapping.lookbackPeriod.enabled == "false") {
                    $scope.data.org_mapping.lookbackPeriod.enabled = false;
                }
                else if ($scope.data.org_mapping.lookbackPeriod.enabled == "true") {
                    $scope.data.org_mapping.lookbackPeriod.enabled = true;
                }

                let beginYear = new Date(new Date().getFullYear(), 0, 1);
                if (!$scope.data.org_mapping.minDate) {
                    $scope.data.org_mapping.minDate = fillStartDate(beginYear, 2, 0);
                } else {
                    $scope.data.org_mapping.minDate = new Date($scope.data.org_mapping.minDate);
                }

                if (!$scope.data.org_mapping.maxDate) {
                    $scope.data.org_mapping.maxDate = fillStartDate(new Date(), 0, 6);
                } else {
                    $scope.data.org_mapping.maxDate = new Date($scope.data.org_mapping.maxDate);
                }
            });
        });

        api.get('readConfig/fetch_last_mapping', {}, false, true).then(function (data) {
            $scope.last_update = data.data;
        });
    }

    $scope.ago = function () {
        if ($scope.last_update) {
            return moment(moment.utc($scope.last_update).local().format()).fromNow();
        }
    };

    $scope.saveConfigs = function () {
        $state.current.data.loading = true;
        // eslint-disable-next-line no-unused-vars
        api.set('config/save_field_mappings', $scope.data.field_mapping, true).then(function (data) {
            // eslint-disable-next-line no-unused-vars
            api.set('config/save_org_mappings', $scope.data.org_mapping, true).success(function (data) {
                $state.current.data.loading = false;
                noty.growl('Mappings Saved!', 'success');
            });
        });
    };

    function fetchConfigDescriptions() {
        api.get('readConfig/fetch_field_descriptions', {}, false, true).then(function (data) {
            angular.forEach(data.data, function (field) {
                $scope.desc[field.object][field.name] = { header: field.header, description: field.description };
            });
        });
    }

    $scope.fillYear = function (type) {
        var index;
        if (type === 'start') {
            index = $scope.months.indexOf($scope.data.org_mapping.yearStart);
            if (index === 0) {
                $scope.data.org_mapping.yearEnd = $scope.months[11];
            }
            else {
                $scope.data.org_mapping.yearEnd = $scope.months[index - 1];
            }
        }
        else if (type === 'end') {
            index = $scope.months.indexOf($scope.data.org_mapping.yearEnd);
            if (index === 11) {
                $scope.data.org_mapping.yearStart = $scope.months[0];
            }
            else {
                $scope.data.org_mapping.yearStart = $scope.months[index + 1];
            }
        }
    };

    function fillStartDate(date, year, month) {
        return new Date(
            date.getFullYear() - year,
            date.getMonth() - month,
            date.getDate()
        );
    }

    $scope.toggleError = function (enabledToggled = false) {
        let toggleValue = $scope.data.org_mapping.lookbackPeriod.enabled;

        if(enabledToggled){
            toggleValue = !toggleValue;
        }
        if (toggleValue) {
            $scope.isError = ($scope.data.org_mapping.lookbackPeriod.monthValue === '0' || !$scope.data.org_mapping.lookbackPeriod.monthValue || $scope.data.org_mapping.lookbackPeriod.monthValue > 36);
        } else {
            $scope.isError = false;
        }
    };

    function findLabelWithName(obj, name) {
        for (var index = 0; index < obj.length; index++) {
            if (obj[index].name === name) {
                return obj[index].label;
            }
        }
        return '';
    }

    function findFieldWithLabel(obj, label) {
        obj = _.startCase(obj).replace(/\s/g, '');
        if (obj === 'ContactToLead') {
            obj = 'Contact';
        }
        var n = ($scope.schema[obj] || []).length;
        for (var index = 0; index < n; index++) {
            if ($scope.schema[obj][index].label === label) {
                return $scope.schema[obj][index];
            }
        }
        return { name: label, label: label };
    }

    (function init() {
        $state.current.data.loading = true;
        $scope.platform = $rootScope.userData.platform;
        //$scope.hovering is for the ng-mouseover in the template, each dropdown has different content
        $scope.hovering = {
            over_ccf: false,
            over_cgf: false,
            over_adf: false,
            over_at: false,
            over_rgf: false,
            over_lead_wf_tpl: false,
            over_lead_wf_stage: false,
            over_lead_bf_mp: false,
            over_contact_status: false,
            over_ct_wf_stage: false,
            over_amt_fld: false,
            over_rg: false,
            over_wf_stage_fld: false,
            over_wf_tpl_type: false,
            over_use_bf_mp: false
        };
        $scope.data = {};
        $scope.data.field_mapping = {
            account: {},
            campaign: {
                org_actual_cost: {
                    sfdc_field: { label: 'Actual Cost' }
                },
                revenue_group: {
                    sfdc_field: { label: 'Type' }
                },
            },
            campaign_member: {
                activity_date_field: {
                    sfdc_field: { label: 'Created Date' }
                },
                attribution: {
                    sfdc_field: { name: 'touch_based', label: 'Touch Based' }
                }
            },
            lead: {
                revenue_group: {
                    sfdc_field: { label: 'Lead Source' }
                },
                stage: {
                    sfdc_field: { label: 'Status' }
                },
                wf_mapping: {
                    sfdc_field: { label: false }
                }
            },
            contact_to_lead: {
                status_field: {
                    sfdc_field: { label: 'Status' }
                },
                stage_field: {
                    sfdc_field: { label: 'Status' }
                },
            },
            opportunity: {
                waterfall_stage: {
                    sfdc_field: { label: 'Stage' }
                },
                amount_field: {
                    sfdc_field: { label: 'Amount' }
                },
                revenue_group: {
                    sfdc_field: { label: 'Opportunity Type' }
                },
            },
            opportunity_history: {
                wf_mapping: {
                    sfdc_field: { label: false }
                },
                template_type: {
                    sfdc_field: { name: 'with_oppty_history', label: 'All Opportunities' }
                }
            },
            lead_history: {
                template_type: {
                    sfdc_field: { name: 'all_lead', label: 'Default' }
                }
            },
            contact_history: {
                stage_field: {
                    sfdc_field: {}
                }
            }
        };
        $scope.data.org_mapping = {
            yearStart: 'January',
            yearEnd: 'December',
            startDate: '2019-01-01',
            minDate: '',
            maxDate: '',
            attributionModels: [
                { name: 'Single-Touch: First Touch', active: true, type: 'sourced' },
                { name: 'Single-Touch: Last Touch', active: true, type: 'last' },
                { name: 'Multi-Touch: Evenly Weighted', active: true, type: 'even' },
                { name: 'Multi-Touch: 40-20-40', active: true, type: 'custom' }
            ],
            lookbackPeriod: {
                enabled: true,
                monthValue: '24'
            }
        };
        $scope.templates = {
            attribution_type: [{ name: 'touch_based', label: 'Touch Based' }, { name: 'response_based', label: 'Response Based' }, { name: 'custom', label: 'Custom' }],
            oppty_template_type: [{ name: 'with_oppty_history', label: 'All Opportunities' }, { name: 'oppty_closed', label: 'Closed Opportunities' }],
            lead_template_type: [{ name: 'with_lead_history', label: 'Leads Only' }, { name: 'with_lead_and_contact_history', label: 'Leads and Contacts' }, { name: 'all_lead', label: 'Default' }]
        };
        $scope.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        $scope.disable = true;
        $scope.desc = {
            account: {},
            campaign: {
                org_actual_cost: '',
                revenue_group: ''
            },
            campaign_member: {
                activity_date_field: '',
                attribution: ''
            },
            lead: {
                revenue_group: '',
                stage: '',
                wf_mapping: ''
            },
            contact_to_lead: {
                status_field: '',
                stage_field: '',
            },
            opportunity: {
                waterfall_stage: '',
                amount_field: '',
                revenue_group: '',
            },
            opportunity_history: {
                wf_mapping: '',
                template_type: ''
            },
            lead_history: {
                template_type: ''
            }
        };
        $scope.isError = false;
        getSchemaConfig();
        fetchConfigDescriptions();
    })();
}]);
