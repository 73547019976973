app.controller('SFIntegrationCtrl', ['$http', 'api', '$scope', '$rootScope', '$state', '$q', function ($http, api, $scope, $rootScope, $state, $q) {

    // eslint-disable-next-line no-unused-vars
    var schemaConfigCall;

    $scope.addField = function(field) {
        var newField = {active: 0, custom_field: 1, label: field.label, name: field.name, populated: 0, row_id: null, approved: 0, currency: 0};
        if(field.type == 'currency') {
            newField.currency = 1;
        }
        $scope.data.schemaConfig[$scope.query.currentObject].push(newField);
        for (var item in $scope.data.sfdcFields) {
            if($scope.data.sfdcFields[item].name == field.name) {
                $scope.data.sfdcFields.splice(item, 1);
            }
        }
        api.get('sfdc/add_schema_config', {field_name: newField.name, field_label: newField.label, object: $scope.query.currentObject, currency_field: newField.currency}, false, true).then(function(data) {
            newField.row_id = data.data;
            $scope.data.pendingFields.push(newField);
        });

    };

    $scope.switchObject = function(object) {
        $scope.query.currentObject = object;
        loadObject(object);
    };

    $scope.logField = function(object) {
        // eslint-disable-next-line no-console
        console.log(object);
    };

    $scope.isActive = function(object) {
        return $scope.query.currentObject == object;
    };

    $scope.updateField = function(field) {
        // eslint-disable-next-line no-unused-vars
        api.set('sfdc/update_schema_config', field, false, true).then(function(data) {

        });
    };

    $scope.approveField = function(row_id) {
        $state.current.data.loading = true;
        // eslint-disable-next-line no-unused-vars
        api.set('sfdc/approve_config', {rowId: row_id}, false, true).then(function(data) {
            for (var item in $scope.data.pendingFields) {
                if ($scope.data.pendingFields[item].row_id == row_id) {
                    $scope.data.pendingFields.splice(item, 1);
                }
            }
            for (var field in $scope.data.schemaConfig[$scope.query.currentObject]) {
                if ($scope.data.schemaConfig[$scope.query.currentObject][field].row_id == row_id) {
                    $scope.data.schemaConfig[$scope.query.currentObject][field].approved = 1;
                }
            }
            $state.current.data.loading = false;
        });
    };

    function loadObject(object) {
        $state.current.data.loading = true;
        api.get('sfdc/fetch_all_sfdc_fields', {object: object}, false, true).then(function(data) {
            $scope.data.sfdcFields = data.data;
            $scope.query.field = data.data[0];
            var existingFields = [];

            for (field in $scope.data.schemaConfig[object]) {
                existingFields.push($scope.data.schemaConfig[object][field].name);
            }
            for (var field = 0; field < $scope.data.sfdcFields.length; field++) {
                if(existingFields.indexOf($scope.data.sfdcFields[field].name) != -1) {
                    $scope.data.sfdcFields.splice(field, 1);
                    field = field - 1;
                }
            }
            $state.current.data.loading = false;
        });
        api.get('sfdc/fetch_pending_approval', {}, false, true).then(function(data) {
            $scope.data.pendingFields = data.data;
        });
    }

    $scope.getSchemaConfig = function()  {
        api.get('sfdc/load_schema_config', {}, false, true).then(function(data) {
            $scope.data.schemaConfig = data.data;
            loadObject($scope.query.currentObject);
        });

    };

    function getAllObjects() {
        api.get('sfdc/get_parent_objects', {}, false, true).then(function(data) {
            $scope.data.sfdcObjects = data.data;
        });
    }

    (function init(){
        // eslint-disable-next-line no-unused-vars
        var defer = $q.defer();
        $state.current.data.loading = true;
        $scope.data = {sfdcFields: [], sfdcObjects: [], schemaConfig: []};
        $scope.query = {
            loaded: [],
            field: null,
            currentObject: null,
            isAdmin: $rootScope.userData.permissions.bf_admin
        };
        $scope.query.currentObject = 'Account';
        getAllObjects();
        $scope.getSchemaConfig();


    })();
}]);
