import * as COMPONENT_HTML from './component.html';
import './component.less';
import {IDirective, IScope,} from 'angular';

class CheckListCtrl {
    static $inject: Array<string> = ['$scope'];

    public constructor(private $scope: IScope) {
        return;
    }
}

export class CheckList implements IDirective {
    public restrict = 'E';
    public controller: any = CheckListCtrl;
    public controllerAs = '$ctrl';
    public bindToController = true;
    public template: any = COMPONENT_HTML;
    public scope: IScope = {
        list: '=',
        selected: '=',
    };

    public constructor() {
        return;
    }

    public static Factory(): IDirective {
        return new CheckList();
    }
}
