app.controller('insightsCtrl', ['$scope', '$state', 'insights', '$http', 'utilities', '$location', '$b', '$filter', 'api', '_', '$rootScope', 'filters', function ($scope, $state, insights, $http, utilities, $location, $b, $filter, api, _, $rootScope, filters) {
    $scope._ = _;

    $scope.s = $scope;

    $scope.data = {
        toggleModel: filters.getToggleModel(),
        selected: undefined,
    };

    $scope.toggle = function () {
        $scope.data.startSearch = !$scope.data.startSearch;
    };

    this.compileQuestions = function () {
        var new_qs = [];
        // eslint-disable-next-line no-unused-vars
        var qs = $scope.$states.map(function (state, i) {
            var obj;

            if (state.config.data && state.config.data.question && !state.config.data.disabledPage && !state.config.data.hiddenPage) {
                obj = {
                    id: state.name,
                    text: state.config.data.question
                };
            } else {
                obj = null;
            }

            return obj;
        });

        qs = _.compact(qs);
        // eslint-disable-next-line no-unused-vars
        angular.forEach(qs, function (object, count) {
            for (var i = 0; i < object.text.length; i++) {
                var new_obj = {};
                new_obj.id = object.id;
                new_obj.text = object.text[i];
                new_qs.push(new_obj);
            }
        });

        angular.extend($scope.questions.data, new_qs);
    };

    $scope.searchAll = function (term) {
        var terms = term.split(" ");
        $scope.data.filteredQuestions = [];
        _.forEach(terms, function (t) {
            _.forEach($scope.questions.data, function (q) {
                if (q.text.indexOf(t) > -1) {
                    $scope.data.filteredQuestions.push(q);
                }
            });
        });
        return $scope.data.filteredQuestions;
    };

    $scope.insightGo = function (insight) {
        if (insight.url)
            $location.url(decodeURIComponent(insight.url));
    };

    // eslint-disable-next-line no-unused-vars
    $scope.filtersConfirm = function (popover) {
        var s = $scope.stats().unsaved;
        if (s) {
            $b.confirm({
                text: 'You have ' + s + ' changes to filters that have not been applied. Would you like to apply these changes now?',
                callback: function () {
                    filters.applyFilters();
                }
            });
        }
    };

    //------------------------ calculate number of filters on and off ------------------------//
    $scope.stats = _.throttle(function () {
        var obj = {
                selected: 0,
                unsaved: 0
            },
            params = $location.search();

        // eslint-disable-next-line no-unused-vars
        angular.forEach($state.current.data.filter, function (type, i) {
            // eslint-disable-next-line no-unused-vars
            angular.forEach($scope.filters[type], function (filter, ii) {
                var target = params[filter.number] ? (angular.isArray(params[filter.number]) ? params[filter.number] : [params[filter.number]]) : false,
                    hasParam = target,
                    typeKey = {
                        lead: 'l',
                        opportunity: 'o',
                        campaign: "c",
                        account: "a",
                        campaign_member: "cm"
                    },
                    targetType = hasParam ? $filter('filter')(target, function (item) {
                        var suffix = ':' + typeKey[type == "tag" ? filter.name : type];
                        return item.endsWith(suffix);
                    }) : [];

                //------------------------ check what's applied ------------------------//
                if (hasParam && targetType.length) {
                    obj.selected++;
                }

                //------------------------ calculate unsaved changes  ------------------------//
                if (filter.applied && filter.viewSelected.length) {
                    var vsm = filter.viewSelected.map(function (val) {
                            var n = val.name == '(Blank)' ? 'BF_None' : val.name;
                            return n + ':' + typeKey[type == "tag" ? filter.name : type];
                        }),
                        d1 = _.difference(vsm, targetType),
                        d2 = _.difference(targetType, vsm);

                    if (filter.applied && filter.viewSelected.length) {
                        obj.unsaved = obj.unsaved + _.union(d1, d2).length;
                    }
                }
            });
        });

        return obj;
    }, 1000);

    $scope.questions = {
        data: [],
        width: 'auto',
        multiple: false,
        dropdownCssClass: 'question-dropdown',
        initSelection: function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    };

    this.compileQuestions();

    $scope.question = {
        selected: null
    };

    if ($state.current.data.question && $scope.question.selected) {
        $scope.question.selected = (angular.findWhere($scope.questions.data, { id: $state.current.name, text: $scope.question.selected.text })) || (angular.findWhere($scope.questions.data, { id: $state.current.name }));
    }
    else {
        $scope.question.selected = angular.findWhere($scope.questions.data, { id: $state.current.name });
    }

    // eslint-disable-next-line no-unused-vars
    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        if ($state.current.data.question && $scope.question.selected) {
            $scope.question.selected = (angular.findWhere($scope.questions.data, { id: $state.current.name, text: $scope.question.selected.text })) || (angular.findWhere($scope.questions.data, { id: $state.current.name }));
        }
        else {
            $scope.question.selected = angular.findWhere($scope.questions.data, { id: $state.current.name });
        }
    });

    $scope.changeState = function (selected) {
        selected = selected.id || $scope.question.selected.id;
        $scope.data.selected = undefined;
        $state.go(selected);
    };

    $scope.dateComplex = function () {
        return moment(new Date($rootScope.last_updated_date.last_update)).format("dddd @ h:mm a");
    };

    $scope.ago = function () {
        return moment(new Date($rootScope.last_updated_date.last_update)).fromNow();
    };

}]);
