import { module as angularModule } from 'angular';

import { APP_BRIGHTFUNNEL } from '../../../constants';

import * as am4Core from '@amcharts/amcharts4/core';
import * as am4Charts from '@amcharts/amcharts4/charts';
import * as CONSTANTS from '../constants';
import { IKpiSegment } from 'src/shared/models/scorecard/kpi-segment/model';
import {
    SRVC_KPI_DATA_FORMAT,
    KpiDataFormatService,
} from './kpi-data-format.service';
import { IKpiTrendInterval } from 'src/shared/models/scorecard/kpi-trend-interval/model';
import { SRVC_TERMINUS_HUB, TerminusHubService } from '../../terminus-hub/terminus-hub.service';

const app = angularModule(APP_BRIGHTFUNNEL);

export class TrendingKpiChartsService {
    static $inject: string[] = [
        '$filter',
        SRVC_KPI_DATA_FORMAT,
        SRVC_TERMINUS_HUB
    ];

    private am4core: any;
    private am4charts: any;
    private readonly newColorTheme: any[];
    public maxPieSlices = 5;

    public generateDonutGraph (
        chartId: string,
        chartData: IKpiSegment[],
        kpiType: CONSTANTS.TrendingKpiTypes
    ) {
        const isCurrency = CONSTANTS.KPI_TYPE_FORMAT_MAP[kpiType] === CONSTANTS.KPIFormat.Currency;
        const chart = this.am4core.create(chartId, this.am4charts.PieChart);
        const innerRadius = 40;
        chart.data = chartData;
        chart.innerRadius = this.am4core.percent(innerRadius);

        const pieSeries = chart.series.push(new this.am4charts.PieSeries());
        pieSeries.dataFields.value = 'amount';
        pieSeries.dataFields.category = 'descriptor';
        pieSeries.ticks.template.disabled = true;
        pieSeries.alignLabels = false;
        pieSeries.labels.template.radius = -3;
        pieSeries.labels.template.fill = this.am4core.color('#000');
        pieSeries.labels.template.padding(0, 0, 0, 0);
        pieSeries.labels.template.text = '';
        pieSeries.slices.template.tooltipText = `{descriptor} \n ${isCurrency ? '$' : ''}{value.formatNumber('#,###')}`;

        // Disables hover / click
        const slice = pieSeries.slices.template;
        slice.states.getKey('hover').properties.scale = 1;
        slice.states.getKey('active').properties.shiftRadius = 0;

        pieSeries.colors.list = this.newColorTheme;
        // Create custom legend
        chart.events.on('ready', () => {
            // populate our custom legend when chart renders
            chart.customLegend = document.getElementById(`legend-${chartId}`);
            const isLongLegend = pieSeries.dataItems.length > 4;
            const itemsForLegend = isLongLegend ? pieSeries.dataItems.slice(0, 4) : pieSeries.dataItems;
            itemsForLegend.each((row, index) => {
                const color = pieSeries.colors.getIndex(index);
                chart.customLegend.innerHTML += `
                    <span class="donut-legend-item capitalize">
                        <span class="donut-legend-marker" style="background: ${color}"></span>
                        ${row.category || 'n/a'}
                    </span>
                `;
            });
            if (isLongLegend) {
                chart.customLegend.innerHTML += `<span class="donut-legend-others">+${pieSeries.dataItems.length - 4} others</span>`;
            }
        });

        return chart;
    }

    public generateGaugeGraph (
        chartId: string,
        kpiTotal: number,
        gaugeRangeMap: Record<string, number>,
        kpiType: CONSTANTS.TrendingKpiTypes,
    ) {
        const defaultFillOpacity = 1;
        const defaultZIndex = -1;
        const defaultFontSize = 12;
        const chart = this.am4core.create(chartId, this.am4charts.GaugeChart);
        const lowRangeColor = '#EA6953';
        const midRangeColor = '#FFD26E';
        const highRangeColor = '#4AA951';
        const rangeColor = '#6A7171';
        const handColor = '#3D4D54';

        chart.innerRadius = -30;

        const axis = chart.xAxes.push(new this.am4charts.ValueAxis());
        axis.min = gaugeRangeMap.startValue;
        axis.max = gaugeRangeMap.endValue;
        axis.strictMinMax = true;
        axis.renderer.labels.template.disabled = true;
        axis.renderer.grid.template.disabled = true;

        // Add ranges
        const lowRange = axis.axisRanges.create();

        lowRange.value = gaugeRangeMap.startValue;
        lowRange.endValue = gaugeRangeMap.lowRange;
        lowRange.axisFill.fillOpacity = defaultFillOpacity;
        lowRange.axisFill.fill = this.am4core.color(lowRangeColor);
        lowRange.axisFill.zIndex = defaultZIndex;
        lowRange.label.text =  this.kpiDataFormatService.formatKpiValue(gaugeRangeMap.startValue, kpiType, true);
        lowRange.label.fontSize = defaultFontSize;

        const midRange = axis.axisRanges.create();
        midRange.value = gaugeRangeMap.lowRange;
        midRange.endValue = gaugeRangeMap.midRange;
        midRange.axisFill.fillOpacity = defaultFillOpacity;
        midRange.axisFill.fill = this.am4core.color(midRangeColor);
        midRange.axisFill.zIndex = defaultZIndex;
        midRange.label.text =  this.kpiDataFormatService.formatKpiValue(gaugeRangeMap.lowRange, kpiType, true);
        midRange.label.fontSize = defaultFontSize;

        const highRange = axis.axisRanges.create();
        highRange.value = gaugeRangeMap.midRange;
        highRange.endValue = gaugeRangeMap.endValue;
        highRange.axisFill.fillOpacity = defaultFillOpacity;
        highRange.axisFill.fill = this.am4core.color(highRangeColor);
        highRange.axisFill.zIndex = defaultZIndex;
        highRange.label.text =  this.kpiDataFormatService.formatKpiValue(gaugeRangeMap.midRange, kpiType, true);
        highRange.label.fontSize = defaultFontSize;

        const endRange = axis.axisRanges.create();
        endRange.value = gaugeRangeMap.endValue;
        endRange.label.text =  this.kpiDataFormatService.formatKpiValue(gaugeRangeMap.endValue, kpiType, true);
        endRange.label.fontSize = defaultFontSize;

        // Add hand
        const gaugeHand = chart.hands.push(new this.am4charts.ClockHand());
        gaugeHand.value = kpiTotal > gaugeRangeMap.endValue
            ? gaugeRangeMap.endValue
            : kpiTotal < gaugeRangeMap.startValue ? gaugeRangeMap.startValue : kpiTotal;

        gaugeHand.fill = this.am4core.color(handColor);
        gaugeHand.stroke = this.am4core.color(handColor);
        gaugeHand.startWidth = 4;
        gaugeHand.endWidth = 4;
        gaugeHand.radius = this.am4core.percent(98);
        gaugeHand.pin.fill = this.am4core.color(handColor);
        gaugeHand.pin.stroke = this.am4core.color(handColor);
        lowRange.label.fill = this.am4core.color(rangeColor);
        midRange.label.fill = this.am4core.color(rangeColor);
        highRange.label.fill = this.am4core.color(rangeColor);
        endRange.label.fill = this.am4core.color(rangeColor);

        return chart;
    }

    public generateParetoGraph (
        chartId: string,
        chartData: IKpiTrendInterval[],
        kpiType: CONSTANTS.TrendingKpiTypes,
        kpiInterval: CONSTANTS.TrendingKPIsIntervalTypes,
        metricGoal: number,
    ) {
        const formattedParetoGraphData = this.kpiDataFormatService.formatParetoGraphData(chartData, kpiInterval);
        const chart = this.am4core.create(chartId, this.am4charts.XYChart);
        const intervalAxis: am4Charts.CategoryAxis | am4Charts.DateAxis = kpiInterval === CONSTANTS.INTERVAL_QUARTERS ?
            chart.xAxes.push(new this.am4charts.CategoryAxis()) :
            chart.xAxes.push(new this.am4charts.DateAxis());
        const valueAxis: am4Charts.ValueAxis = chart.yAxes.push(new this.am4charts.ValueAxis());
        const columnSeries: am4Charts.ColumnSeries = chart.series.push(new this.am4charts.ColumnSeries());
        const paretoSeries: am4Charts.LineSeries = chart.series.push(new this.am4charts.LineSeries());

        chart.data = formattedParetoGraphData;

        if (kpiInterval === CONSTANTS.INTERVAL_QUARTERS) {
            (intervalAxis as am4Charts.CategoryAxis).dataFields.category = 'descriptor';
        } else if (kpiInterval === CONSTANTS.INTERVAL_MONTHS) {
            (intervalAxis as am4Charts.DateAxis).chart.dateFormatter.inputDateFormat = 'MMM yyyy';
        } else {
            (intervalAxis as am4Charts.DateAxis).chart.dateFormatter.inputDateFormat = 'MMM d, yyyy';
        }

        valueAxis.tooltip.disabled = true;
        valueAxis.numberFormatter = new this.am4core.NumberFormatter();
        valueAxis.numberFormatter.numberFormat = CONSTANTS.KPI_TYPE_VALUE_AXIS_MAP[kpiType];
        valueAxis.renderer.labels.template.adapter.add('text', (value: string) => {
            const unformattedValue: string = value ? this.kpiDataFormatService.stripSpecialCharacters(value) : '';

            return unformattedValue.length > 3 ? this.kpiDataFormatService.formatKpiValue(+unformattedValue, kpiType, true) : unformattedValue;
        });

        columnSeries.fontSize = '10px';
        columnSeries.dataFields.valueY = 'total';
        columnSeries.name = 'Net-New';
        columnSeries.numberFormatter = new this.am4core.NumberFormatter();
        columnSeries.numberFormatter.numberFormat = CONSTANTS.KPI_TYPE_VALUE_AXIS_MAP[kpiType];
        columnSeries.tooltipText = '[bold]{name}[/]: {valueY.value}';

        if (kpiInterval === CONSTANTS.INTERVAL_QUARTERS) {
            columnSeries.dataFields.categoryX = 'descriptor';
        } else {
            columnSeries.dataFields.dateX = 'descriptor';
        }

        paretoSeries.name = 'Cumulative';
        paretoSeries.dataFields.valueY = 'accumulated';
        paretoSeries.adapter.add('tooltipText', (_, target) => {
            const unformatAccumulated: string = this.kpiDataFormatService.stripSpecialCharacters(target.tooltipDataItem.dataContext['accumulated']);
            const reformattedAccumalative: string = this.kpiDataFormatService.formatAsPercentage(+unformatAccumulated / metricGoal);
            return `[bold]{name}[/]: ${reformattedAccumalative}`;
        });
        paretoSeries.minBulletDistance = 15;

        if (kpiInterval === CONSTANTS.INTERVAL_QUARTERS) {
            paretoSeries.dataFields.categoryX = 'descriptor';
        } else {
            paretoSeries.dataFields.dateX = 'descriptor';
        }
        paretoSeries.yAxis = valueAxis;

        chart.cursor = new this.am4charts.XYCursor();
        chart.cursor.xAxis = intervalAxis;
        chart.cursor.behavior = 'none';

        chart.legend = new this.am4charts.Legend();
        chart.legend.itemContainers.template.clickable = false;
        chart.legend.itemContainers.template.cursorOverStyle = this.am4core.MouseCursorStyle.default;

        const markerTemplate = chart.legend.markers.template;
        chart.paddingLeft = 0;
        chart.paddingRight = 0;
        chart.margin(0, 0, 0, 0);
        valueAxis.renderer.opposite = true;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.fill = this.am4core.color('#6A7171');
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.stroke = this.am4core.color('#DEE1E3');
        intervalAxis.renderer.grid.template.disabled = true;
        intervalAxis.renderer.line.strokeOpacity = 1;
        intervalAxis.renderer.line.stroke = this.am4core.color('#DEE1E3');
        intervalAxis.renderer.labels.template.fill = this.am4core.color('#6A7171');
        intervalAxis.renderer.minGridDistance = 50;
        columnSeries.columns.template.fill = this.am4core.color('#08C4B2');
        columnSeries.stroke = this.am4core.color('#08C4B2');
        paretoSeries.fill = this.am4core.color('#6F5ED3');
        paretoSeries.stroke = this.am4core.color('#6F5ED3');
        paretoSeries.strokeWidth = 3;
        chart.legend.useDefaultMarker = true;
        chart.legend.labels.template.fill = this.am4core.color('#6A7171');
        chart.legend.itemContainers.template.paddingTop = 2;
        markerTemplate.width = 8;
        markerTemplate.height = 8;

        return chart;
    }

    generateAreaGraph(
        chartId: string,
        chartData: IKpiTrendInterval[],
        chartStyleType: CONSTANTS.TrendingKPIsChartStyleTypes,
        kpiType: CONSTANTS.TrendingKpiTypes,
        kpiInterval: CONSTANTS.TrendingKPIsIntervalTypes,
        groupBy: CONSTANTS.TrendingKPIsGroupingTypes,
    ) {
        const isStacked = ([
            CONSTANTS.CHART_STYLE_STACKED_AREA,
            CONSTANTS.CHART_STYLE_STACKED_COLUMN,
        ] as CONSTANTS.TrendingKPIsChartStyleTypes[]).indexOf(chartStyleType) > -1;
        const isLine = ([
            CONSTANTS.CHART_STYLE_LINE,
            CONSTANTS.CHART_STYLE_STACKED_AREA,
        ] as CONSTANTS.TrendingKPIsChartStyleTypes[]).indexOf(chartStyleType) > -1;
        const chart = this.am4core.create(chartId, this.am4charts.XYChart);
        const formatTrendingGraphData = this.kpiDataFormatService.formatTrendingGraphData(
            chartData,
            kpiInterval,
            groupBy,
            false,
        );
        const seriesMap = this.kpiDataFormatService.getSeriesKeysMap(
            this.kpiDataFormatService.formatTrendingGraphData(
                chartData,
                kpiInterval,
                groupBy,
                true,
            )
        );
        const intervalAxis: am4Charts.CategoryAxis | am4Charts.DateAxis = kpiInterval === CONSTANTS.INTERVAL_QUARTERS ?
            chart.xAxes.push(new this.am4charts.CategoryAxis()) :
            chart.xAxes.push(new this.am4charts.DateAxis());
        const valueAxis: am4Charts.ValueAxis = chart.yAxes.push(new this.am4charts.ValueAxis());
        chart.data = formatTrendingGraphData;

        if (kpiInterval === CONSTANTS.INTERVAL_QUARTERS) {
            (intervalAxis as am4Charts.CategoryAxis).dataFields.category = 'descriptor';
        } else if (kpiInterval === CONSTANTS.INTERVAL_MONTHS) {
            (intervalAxis as am4Charts.DateAxis).chart.dateFormatter.inputDateFormat = 'MMM yyyy';
        } else {
            (intervalAxis as am4Charts.DateAxis).chart.dateFormatter.inputDateFormat = 'MMM d, yyyy';
        }
        intervalAxis.renderer.grid.template.strokeOpacity = 0;
        intervalAxis.renderer.minGridDistance = 45;

        valueAxis.tooltip.disabled = true;
        valueAxis.numberFormatter = new this.am4core.NumberFormatter();
        valueAxis.numberFormatter.numberFormat = CONSTANTS.KPI_TYPE_VALUE_AXIS_MAP[kpiType];
        valueAxis.renderer.labels.template.adapter.add('text', (value: string) => {
            const unformattedValue: string = value ? this.kpiDataFormatService.stripSpecialCharacters(value) : '';

            return unformattedValue.length > 3 ? this.kpiDataFormatService.formatKpiValue(+unformattedValue, kpiType, true) : unformattedValue;
        });


        chart.colors.list = this.newColorTheme;

        seriesMap.forEach(({ key }) => {
            const series: am4Charts.LineSeries | am4Charts.ColumnSeries = chart.series.push(
                isLine ?
                    new this.am4charts.LineSeries() :
                    new this.am4charts.ColumnSeries()
            );

            series.name = key;
            series.dataFields.valueY = key;
            series.numberFormatter = new this.am4core.NumberFormatter();
            series.numberFormatter.numberFormat = CONSTANTS.KPI_TYPE_VALUE_AXIS_MAP[kpiType];


            if (kpiInterval === CONSTANTS.INTERVAL_QUARTERS) {
                series.dataFields.categoryX = 'descriptor';
            } else {
                series.dataFields.dateX = 'descriptor';
            }

            series.strokeWidth = 2;

            if (isStacked) {
                series.fillOpacity = 1;
                series.stacked = true;
            }

            if (!isLine) {
                (series as am4Charts.ColumnSeries).columns.template.align = 'left';
                (series as am4Charts.ColumnSeries).columns.template.width = this.am4core.percent(80);
            }

            series.tooltipText = '[bold]{name}[/]: {valueY.value}';
            series.tooltip.animationDuration = 0;
        });

        chart.cursor = new this.am4charts.XYCursor();
        chart.cursor.xAxis = intervalAxis;
        chart.cursor.behavior = 'none';

        chart.paddingLeft = 0;
        chart.paddingRight = 0;
        chart.margin(0, 0, 0, 0);
        valueAxis.renderer.opposite = true;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.fill = this.am4core.color('#6A7171');
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.stroke = this.am4core.color('#DEE1E3');
        intervalAxis.renderer.labels.template.fill = this.am4core.color('#6A7171');

        // Create custom legend
        chart.events.on('ready', () => {
            // populate our custom legend when chart renders
            chart.customLegend = document.getElementById(`area-legend-${chartId}`);
            const isLongLegend = chart.series.length > 4;
            const itemsForLegend = isLongLegend ? chart.series.values.slice(0, 4) : chart.series.values;
            itemsForLegend.forEach((row, index) => {
                const color = chart.colors.getIndex(index);
                chart.customLegend.innerHTML += `
                    <span class="area-legend-item capitalize">
                        <span class="area-legend-marker" style="background: ${color}"></span>
                        ${row.name || 'n/a'}
                    </span>
                `;
            });
            if (isLongLegend) {
                chart.customLegend.innerHTML += `<span class="area-legend-others">+${chart.series.length - 4} others</span>`;
            }
        });

        return chart;
    }

    changeChartContainerSize(className: string, value: string, property: string): void {
        // change container's size if there is new design feature activated
        const containers = document.getElementsByClassName(className);
        for (let i = 0; i < containers.length; i++) {
            (containers[i] as HTMLElement).style[property] = value;
        }
    }

    constructor (
        public $filter: any,
        public kpiDataFormatService: KpiDataFormatService,
        private terminusHubService: TerminusHubService,
    ) {
        this.am4charts = am4Charts;
        this.am4core = am4Core;
        this.newColorTheme = [
            this.am4core.color('#FFCD1C'),
            this.am4core.color('#CE3665'),
            this.am4core.color('#6F5ED3'),
            this.am4core.color('#08C4B2'),
            this.am4core.color('#FF9671'),
            this.am4core.color('#F9F871'),
            this.am4core.color('#D65DB1'),
        ];
        this.am4core.options.commercialLicense = true;
    }
}

export const SVRC_TRENDING_KPI_CHARTS = 'trendingKpiChartsService';
app.service(SVRC_TRENDING_KPI_CHARTS, TrendingKpiChartsService);
