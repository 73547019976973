(function ($) {
    $.fn.textfill = function (options) {
        return this.each(function () {
            var text = $(this).text(),
                htmlContent = $(this).html();
            if (!text || text && text.indexOf('{{') !== -1 && text.indexOf('}}') !== -1 || !$(this).is(':visible')) { return; }
            $(this).text('');
            var container = $('<span />').html(htmlContent).appendTo($(this));
            var min = 1, max = 200, fontSize;
            do {
                fontSize = (max + min) / 2;
                container.css('fontSize', fontSize);
                var multiplier = $(this).height() / container.height();
                if (multiplier == 1) { min = max = fontSize; }
                if (multiplier > 1) { min = fontSize; }
                if (multiplier < 1) { max = fontSize; }
            } while ((max - min) > 1);
            fontSize = min;
            if (options.ignoreWidth !== 'true') {
                if ($(this).width() < container.width()) {
                    min = 1;
                    do {
                        fontSize = (max + min) / 2;
                        container.css('fontSize', fontSize);
                        var mltplr = $(this).width() / container.width();
                        if (mltplr == 1) { min = max = fontSize; }
                        if (mltplr > 1) { min = fontSize; }
                        if (mltplr < 1) { max = fontSize; }
                    } while ((max - min) > 1);
                    fontSize = min;
                }
            }
            container.remove();
            $(this).html(htmlContent);
            var minFontSize = options.minFontPixels;
            var maxFontSize = options.maxFontPixels;
            $(this).css('fontSize',
                minFontSize && (minFontSize > fontSize) ?
                    minFontSize :
                    maxFontSize && (maxFontSize < fontSize) ?
                        maxFontSize :
                        fontSize);
        });
    };
})(jQuery);
