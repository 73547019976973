app.controller('OpptysInRangeCtrl', [
    '$scope',
    '$http',
    '$window',
    '$location',
    '$stateParams',
    'utilities',
    '$state',
    "api",
    "$rootScope",
    '$filter',
    '$timeout',
    '$compile',
    '$localStorage',
    '_',
    'opptyAnalysis',
    'colorMap',
    'filters',
    'sigstrSrvc',
    function (
        $scope,
        $http,
        $window,
        $location,
        $stateParams,
        utilities,
        $state,
        api,
        $rootScope,
        $filter,
        $timeout,
        $compile,
        $storage,
        _,
        opptyAnalysis,
        colorMap,
        filtersSvc,
        sigstrSrvc,
    ) {
        var sfdcURL = $scope.sfdcURL;
        var dataCall;
        var visualCall;

        $scope.object_type = $stateParams.t === 'deals' ? 'Deals' : 'Opportunities';
        $scope.statuses = {
            data: null
        };

        $scope.runAllQuery = function () {
            resetFields();
            $scope.loadOnePage();
            loadVisualData();
        };

        $scope.dates_changed = function () {
            $rootScope.$broadcast('detailChanged', "dates");
        };

        function getCsvUrl() {
            let currentParams = angular.extend(angular.copy($scope.query), filtersSvc.getFiltersAsParams($rootScope.filters));
            let selectedLists = filtersSvc.getSelectedAccountLists($rootScope.accountLists.folders);
            if (selectedLists.length > 0) {
                currentParams.al = selectedLists.join(",");
            } else {
                delete currentParams.al;
            }
            $scope.data.csvUrl = 'export/opptyPerformance?' + _.queryString(currentParams);
        }

        if ($stateParams.sortBy) {
            $scope.gridOptions.sortInfo = { fields: [$stateParams.sortBy], directions: ["desc"] };
            for (var i = 0; i < $scope.gridOptions.columnDefs.length; i++) {
                if ($stateParams.sortBy === $scope.gridOptions.columnDefs[i].field) {
                    $scope.sort_order = $scope.gridOptions.columnDefs[i].displayName;
                }
            }
        } else {
            $scope.sort_order = 'Created Date';
        }

        if ($state.params.t && $state.params.t === 'deals') {
            $state.current.data.erasePrevious = true;
        }

        $scope.loadTab = function (type) {
            // eslint-disable-next-line no-unused-vars
            angular.forEach($scope.data.tabs, function (tab, i) {
                tab.loaded = false;
            });
            $scope.data.tabs[type].loaded = true;
            $scope.$broadcast('$$rebind::refreshData');
        };

        $scope.fieldSearching = function (field) {
            return _.result(_.findWhere($scope.data.columns, { 'field': field }), 'displayName');
        };

        $scope.removeCampaign = function () {
            for (var key in $scope.data.influences) {
                if ($scope.query[key]) {
                    $scope.query[key] = null;
                }
            }
            resetFields();
            $scope.loadOnePage();
            loadVisualData();
        };

        function opptysVsStage(data) {
            const stages = {};
            const stageData = {};
            let total = 0;

            data.map((row, idx) => {
                const stage = row[0];
                const quantity = row[1];
                stages[idx] = {
                    type: "donut",
                    name: $filter('truncate')(stage, 20),
                    rawName: stage,
                };

                stageData[idx] = quantity;
                total += quantity;
            });

            $scope.charts.opptysVsStage.dimensions = stages;
            $scope.charts.opptysVsStage.chart.donut.title = total + ' Oppts.';
            $scope.charts.opptysVsStage.data = [stageData];
            $scope.$broadcast('$$rebind::refreshData');
        }

        function opptysVsGroup(data) {
            const groups = {};
            const groupData = {};
            let total = 0;

            data.map((row, idx) => {
                const campaignGroup = row[0] ? row[0] : 'No Campaign Type';
                const quantity = row[1];

                groups[idx] = {
                    type: 'donut',
                    name: campaignGroup,
                };

                groupData[idx] = quantity;
                total += quantity;
            });

            $scope.charts.opptysVsGroup.chart.donut.title = total + ' Oppts.';
            $scope.charts.opptysVsGroup.dimensions = groups;
            $scope.charts.opptysVsGroup.data = [groupData];
            $scope.$broadcast('$$rebind::refreshData');
        }

        function opptysVsLast(data) {
            const lastTouches = {};
            const lastTouchData = {};
            let total = 0;

            data.map((row, idx) => {
                const lastTouch = row[0] ? row[0] : 'No Campaign Type';
                const quantity = row[1];

                lastTouches[idx] = {
                    type: 'donut',
                    name: lastTouch,
                };

                lastTouchData[idx] = quantity;
                total += quantity;
            });
            $scope.charts.opptysVsLast.chart.donut.title = total + ' Oppts.';
            $scope.charts.opptysVsLast.dimensions = lastTouches;
            $scope.charts.opptysVsLast.data = [lastTouchData];
            $scope.$broadcast('$$rebind::refreshData');
        }

        function sigstrIntentScore(data) {
            const intentScores = {};
            const intentScoreData = {};
            let total = 0;

            data.map((row, idx) => {
                const score = row[0];
                const quantity = row[1];

                intentScores[idx] = {
                    type: 'donut',
                    name: score,
                };

                intentScoreData[idx] = quantity;
                total += quantity;
            });

            $scope.charts.sigstrIntentScore.chart.donut.title = total + ' Oppts.';
            $scope.charts.sigstrIntentScore.dimensions = intentScores;
            $scope.charts.sigstrIntentScore.data = [intentScoreData];
            $scope.$broadcast('$$rebind::refreshData');
        }

        function sigstrCompanyScore(data) {
            const mappedScores = {};
            const mappedScoreData = {};
            let total = 0;

            data.map((row, idx) => {
                const score = row[0] ? row[0] : 'None';
                const quantity = row[1];

                mappedScores[idx] = {
                    type: 'donut',
                    name: score,
                };

                mappedScoreData[idx] = quantity;
                total += quantity;
            });

            $scope.charts.sigstrCompanyScore.chart.donut.title = total + ' Oppts.';
            $scope.charts.sigstrCompanyScore.dimensions = mappedScores;
            $scope.charts.sigstrCompanyScore.data = [mappedScoreData];
            $scope.$broadcast('$$rebind::refreshData');
        }

        $scope.$on('filtersChanged', function () {
            resetFields();
            $scope.loadOnePage();
            loadVisualData();
        });

        $scope.$on('$destroy', function () {
            if (dataCall) {
                dataCall.abort();
            }
            //when leaving this controller, you want to remove the no marketing influence from the attribution models
            var nmi = _.find($scope.data.models, { key: "none" });
            if (nmi) {
                $scope.data.models.pop();
            }
        });

        function setInfluenceUI() {
            $scope.data.isCampSpecific = false;
            for (var key in $scope.data.influences) {
                if ($scope.query[key]) {
                    $scope.data.isCampSpecific = true;
                    $scope.data.campaignDesc = $scope.data.influences[key].desc;
                    $scope.data.campaignName = $scope.query[$scope.data.influences[key].nameField];
                }
            }
        }

        $scope.refreshPageData = function() {
            resetFields();
            $scope.loadOnePage();
        };

        $scope.loadOnePage = function() {
            if (dataCall) {
                dataCall.abort();
            }
            $scope.loadingTable = true;
            $state.current.data.loading = true;

            var params = angular.copy($scope.query);

            setInfluenceUI();

            (dataCall = api.getter({url: "oppty_perf", params: params})).then(function (data) {
                for (let i = 0; i < data.data.length; i++) {
                    data.data[i].sf_url = sfdcURL + "/" + data.data[i].id;
                }

                $scope.oppty_data = data.data.oppty_data;
                $scope.oppty_count = data.data.oppty_totals.oppty_count;
                $scope.data.totals = data.data.oppty_totals;
                $scope.page_count = Math.floor($scope.oppty_count / 20);
                if($scope.oppty_count % 20 !== 0) {
                    $scope.page_count++;
                }
                $scope.loadingTable = false;
                evalLoading();

                $scope.$broadcast('$$rebind::refreshData');
                if($scope.tableState) {
                    $scope.tableState.pagination.totalItemCount = $scope.oppty_count;
                    $scope.tableState.pagination.numberOfPages = $scope.page_count;
                }
            }, function (data) {
                $scope.statuses.data = data.status;
                $scope.loadingTable = false;
                evalLoading();
            });
        };

        function loadVisualData() {
            if (visualCall) {
                visualCall.abort();
            }
            $state.current.data.loading = true;
            $scope.loadingVisual = true;

            const params = angular.copy($scope.query);

            (visualCall = api.getter({url: "/v2/oppty_list_visual", params: params})).then((data) => {
                const aggregates = data.data;
                opptysVsStage(aggregates.stage);
                opptysVsGroup(aggregates.source_campaign_group);
                opptysVsLast(aggregates.last_touch_campaign_group);

                if ($scope.isSigstrEnabled) {
                    sigstrIntentScore(aggregates.intent_score);
                    sigstrCompanyScore(aggregates.score_mapped);
                }

                $scope.loadingVisual = false;
                evalLoading();
            });
        }

        $scope.pullPageData = function (tableState) {
            $scope.tableState = tableState;

            tableState.pagination.totalItemCount = $scope.oppty_count;
            tableState.pagination.numberOfPages = $scope.page_count;
            if($scope.hasLoadedData) {
                if (tableState.sort.predicate) {
                    var header = _.find($scope.data.columns, {'field': tableState.sort.predicate[0].predicate});
                    $scope.query.fld = tableState.sort.predicate[0].predicate;
                    $scope.query.dir = !tableState.sort.predicate[0].reverse ? 'a' : 'd';
                    $scope.query.tbl = header.apiType;
                }

                $scope.query.pg = Math.ceil(tableState.pagination.start / $scope.query.ps) + 1;
                $scope.loadOnePage();
            }
            else {
                $scope.hasLoadedData = true;
            }
        };

        function resetFields() {
            $scope.hasLoadedData = false;
            if ($scope.tableState) {
                $scope.tableState.pagination.start = 0;
            }
            $scope.query.pg = 1;
        }

        function evalLoading() {
            if (!($scope.loadingTable && $scope.loadingVisual)) {
                $state.current.data.loading = false;
            }
        }

        $scope.isSigstrEnabled = sigstrSrvc.isEnabled();

        (function init() {
            $scope.query = _.defaults($state.params, {
                startDate: moment(new Date()).subtract(1, 'week')._d.getTime(),
                endDate: new Date().getTime(),
                dateType: 'created',
                closedType: "any",
                wonType: "any",
                cohort: 'quarter2Date',
                model: 'any',
                searchField: 'name',
                type: 'pipeline',
            });
            $scope.query.pg = 1;
            $scope.query.ps = 20;

            $scope.selectors =
            {
                dateLabel: 'Date Field',
                dateOptions: [
                    {name: "Created", value: "created"},
                    {name: "Closed", value: "closed"}
                ],
                statusLabel: 'Status',
                statusOptions: [
                    {name: "Open", value: "open"},
                    {name: "Closed", value: "closed"},
                    {name: "Open or Closed", value: "any"}
                ],
                wonLabel: 'Closed',
                wonOptions: [
                    {name: "Won", value: "won"},
                    {name: "Lost", value: "lost"},
                    {name: "Won or Lost", value: "any"}
                ]
            };

            $scope.data = opptyAnalysis.getData();
            if (!_.find($scope.data.models, { name: 'No Marketing Influence', key: "none" })) {
                $scope.data.models.push({ name: 'No Marketing Influence', key: "none" });
            }
            $scope.data.searchField = $storage.tableStates && $storage.tableStates[$state.current.name] && $storage.tableStates[$state.current.name]['opptyAnalysis'] && $storage.tableStates[$state.current.name]['opptyAnalysis'].search && $storage.tableStates[$state.current.name]['opptyAnalysis'].search.predicateObject && Object.keys($storage.tableStates[$state.current.name]['opptyAnalysis'].search.predicateObject).length ? Object.keys($storage.tableStates[$state.current.name]['opptyAnalysis'].search.predicateObject)[0] : 'name';
            $scope.data.tabs = { opptys: { active: true }, visual: { active: false } };
            $scope.data.columns = opptyAnalysis.getColumns($scope.query);

            $scope.charts = {
                opptysVsGroup: {
                    chart: {
                        data: {
                            color: (color, d) => colorMap(d),
                            onclick: () => { },
                        },
                        donut: {},
                        legend: {
                            position: 'right',
                            item: {
                                onclick: () => { },
                            }
                        },
                        padding: {
                            bottom: 20
                        },
                        tooltip: {
                            format: {
                                value: (value, ratio) => `${ $filter('percentage')(ratio, 1) } (${ value })`,
                            }
                        }
                    }
                },
                opptysVsStage: {
                    chart: {
                        data: {
                            onclick: () => { },
                        },
                        donut: {},
                        legend: {
                            position: 'right',
                            item: {
                                onmouseover: (id) => {
                                    const elem = $(`.c3-legend-item-${ id }`),
                                        d = $scope.charts.opptysVsStage.dimensions[id];
                                    if (_.contains(d.name, '...') && !elem.hasAttr('uib-tooltip')) {
                                        elem.attr({
                                            'uib-tooltip': d.rawName,
                                            'tooltip-append-to-body': 'true',
                                            'tooltip-placement': 'top'
                                        });

                                        $compile(elem)(elem.scope());
                                    }
                                },
                                onclick: () => { }
                            }
                        },
                        padding: {
                            bottom: 20
                        },
                        tooltip: {
                            format: {
                                value: (value, ratio) => `${ $filter('percentage')(ratio, 1) } (${ value })`,
                            }
                        }
                    }
                },
                opptysVsLast: {
                    chart: {
                        data: {
                            onclick: () => { },
                        },
                        donut: {},
                        legend: {
                            position: 'right',
                            item: {
                                onclick: () => { },
                            }
                        },
                        padding: {
                            bottom: 20
                        },
                        tooltip: {
                            format: {
                                value: (value, ratio) => `${ $filter('percentage')(ratio, 1) } (${ value })`,
                            }
                        }
                    }
                },
                sigstrIntentScore: {
                    chart: {
                        data: {
                            onclick: () => { },
                        },
                        donut: {},
                        legend: {
                            position: 'right',
                            item: {
                                onclick: () => { },
                            }
                        },
                        padding: {
                            bottom: 20
                        },
                        tooltip: {
                            format: {
                                value: (value, ratio) => `${ $filter('percentage')(ratio, 1) } (${ value })`,
                            }
                        }
                    }
                },
                sigstrCompanyScore: {
                    chart: {
                        data: {
                            onclick: () => { },
                        },
                        donut: {},
                        legend: {
                            position: 'right',
                            item: {
                                onclick: () => { },
                            }
                        },
                        padding: {
                            bottom: 20
                        },
                        tooltip: {
                            format: {
                                value: (value, ratio) => `${$filter('percentage')(ratio, 1)} (${ value })`,
                            }
                        }
                    }
                },
            };

            var startDate = new Date();
            startDate.setDate(startDate.getDate() - 7);

            getCsvUrl();
            $scope.$on('$apiFinish', getCsvUrl);

            opptyAnalysis.initCheck($scope.query);

            $scope.startDatepicker = {};
            $scope.endDatepicker = {};
            $scope.exportFileName = 'Opportunities List';

            $scope.hasLoadedData = false;
            $scope.loadOnePage();
            loadVisualData();

        })();
    }
]);
