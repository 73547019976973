// State Names
const APP_ANALYZE_ACCOUNTS_SCORECARD = 'app.analyze.accounts.scorecard';
const APP_ANALYZE_ACCOUNTS_SCORECARD_KPI = 'app.analyze.accounts.scorecard.kpi';
const DASHBOARD = 'app.dashboard';
const APP_ANALYZE_OPPTY_INSIGHTS = 'app.analyze.opportunities.listAnalysis';
const APP_MEASUREMENT_STUDIO = 'app.measurementStudio';
const APP_MEASUREMENT_STUDIO_ADVERTISING_INSIGHTS = 'app.measurementStudio.advertisingInsights';
const ANALYZE_MARKETING_IMPACT = 'app.analyze.revenueAndPipeline.marketingImpact';
const DISCOVER_MARKETING_IMPACT = 'app.discover.revenueAndPipeline.marketingImpact';
const ANALYZE_CAMPAIGNS_LIST_ANALYSIS = 'app.analyze.campaigns.listAnalysis';
const ANALYZE_CAMPAIGNS_LIST_ANALYSIS_CAMPAIGN_GROUPS = 'app.analyze.campaigns.listAnalysis.campaignGroups';
const ANALYZE_CAMPAIGNS_TRENDING_ANALYSIS = 'app.analyze.campaigns.trendingAnalysis';
const ANALYZE_CAMPAIGNS_CAMPAIGN_SPECIFIC = 'app.analyze.campaigns.campaignSpecific';
const ANALYZE_CAMPAIGNS_CAMPAIGN_SPECIFIC_TRENDING = 'app.analyze.campaigns.campaignSpecific.trending';
const ANALYZE_REVPIPE_ATTR_BY_QUARTER = 'app.analyze.campaigns.attributionByQuarter';
const APP_DISC_REVPIP_ATTR_BY_QUARTER = 'app.discover.revenueAndPipeline.attributionByQuarter';
const APP_DISC_REVPIP_ATTR_TRENDS = 'app.discover.revenueAndPipeline.attributionTrends';
const DISCOVER_STAGES_SNAPSHOT = 'app.discover.stageProgression.stagesSnapshot';
const DISCOVER_SP_ATTR = 'app.discover.stageProgression.attribution';
const DISCOVER_SP_TRENDING = 'app.discover.stageProgression.trending';
const DISCOVER_SP_TRENDING_DETAILS = 'app.discover.stageProgression.trendingDetails';
const APP_ANALYZE_ACCOUNTS_TRENDING = 'app.analyze.accounts.trending';
const APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY = 'app.analyze.webTracking.webActivity';
const APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL = 'app.analyze.webTracking.webActivity.channel';
const APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL_ASSETS = 'app.analyze.webTracking.webActivity.channelAssets';
const APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL_MEDIUM = 'app.analyze.webTracking.webActivity.channelMedium';
const APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_TRENDING = 'app.analyze.webTracking.webActivityTrending';
const APP_ANALYZE_ACCOUNT_ATTRIBUTION = 'app.analyze.accounts.attribution';
const APP_ANALYZE_ACCOUNTS_ACCOUNT_SPECIFIC = 'app.analyze.accountsAccountSpecific';
const APP_ANALYZE_ACCOUNTS_ACCOUNT_SPECIFIC_ATTRIBUTION = 'app.analyze.accountsAccountSpecific.attribution';
const APP_ANALYZE_ACCOUNTS_ACCOUNT_SPECIFIC_ACCOUNT_JOURNEY = 'app.analyze.accountsAccountSpecific.accountJourney';
const APP_ANALYZE_ACCOUNTS_ACCOUNT_SPECIFIC_TRENDING = 'app.analyze.accountsAccountSpecific.trending';

// Platform Name Constants
const PLATFORM_STANDARD = 'standard';
const PLATFORM_CAMPAIGN = 'campaign';

// eslint-disable-next-line no-unused-vars
function BFStateProvider() {

    const fullPlatform = ['full'];
    const fullAndCampaignPlatform = ['full', 'campaign'];
    const allPlatforms = ['full', 'campaign', 'standard'];
    const allFilters = ["opportunity", "lead", "campaign", "account", "campaign_member", "terminus_account"];

    const stageInfluence = {
        name: 'app.analyze.campaigns.stageInfluence',
        config: {
            data: {
                title: 'Campaign Stage Analytics',
            },
            callFn: `goToNativeTerminusHubReport('app.analyze.campaigns.stageInfluence')`,
            orgTypes: allPlatforms,
            navName: 'Campaign Stage Analytics',
        }};

    const segmentBuilder = {
        name: 'app.segmentBuilder',
        config: {
            data: {
                title: 'Segment builder',
            },
            callFn: `goToNativeTerminusHubReport('app.segmentBuilder')`,
            orgTypes: allPlatforms,
            navName: 'Segment Builder',
        }};

    const advertisingInsights = {
        name: APP_MEASUREMENT_STUDIO_ADVERTISING_INSIGHTS,
        config: {
            data: {
                title: 'Ad Insights',
            },
            callFn: `goToNativeTerminusHubReport('app.measurementStudio.advertisingInsights')`,
            orgTypes: allPlatforms,
            navName: 'Ad Insights',
        },
    };

    const measurementStudio = {
        name: APP_MEASUREMENT_STUDIO,
        config: {
            abstract: true,
            // alwaysAdd: true,
            blind: true,
            data: {
                title: 'Measurement Studio',
            },
            orgTypes: allPlatforms,
            template: '<div ui-view></div>',
        },
        children: [
            advertisingInsights,
        ],
    };

    const marketingImpact = {
        name: DISCOVER_MARKETING_IMPACT,
        config: {
            callFn: `goToNativeTerminusHubReport('app.discover.revenueAndPipeline.marketingImpact')`,
            url: '/marketing-impact?period&type&modelType&opptyType&cohort&influenceType',
            template: require('../../main/discover/revenue-and-pipeline/marketing-impact/index.html'),
            controller: 'marketingImpactCtrl',
            orgTypes: fullAndCampaignPlatform,
            //reloadOnSearch: false,
            data: {
                title: 'Marketing Influence',
                question: ['What percent of total revenue and pipeline is my marketing influencing?'],
                filter: allFilters
            },
            navName: 'Marketing Influence',
        }
    };

    const analyzeMarketingImpact = {
        name: 'app.analyze.revenueAndPipeline',
        config: {
            abstract: true,
            url: '/revenue-and-pipeline',
            data: { title: "Revenue & Pipeline" },
            orgTypes: fullAndCampaignPlatform,
            template: '<div ui-view></div>',
            alwaysAdd: true,
            blind: true,
        },
        children: [
            {
                name: ANALYZE_MARKETING_IMPACT,
                config: {
                    callFn: `goToNativeTerminusHubReport('app.analyze.revenueAndPipeline.marketingImpact')`,
                    url: '/marketing-impact?period&type&modelType&opptyType&cohort&influenceType',
                    template: require('../../main/discover/revenue-and-pipeline/marketing-impact/index.html'),
                    controller: 'marketingImpactCtrl',
                    orgTypes: fullAndCampaignPlatform,
                    //reloadOnSearch: false,
                    data: {
                        title: 'Marketing Influence',
                        question: ['What percent of total revenue and pipeline is my marketing influencing?'],
                        filter: allFilters
                    },
                    navName: 'Marketing Influence',
                },
            },
        ],
    };

    const attributionByCampaignType = {
        name: APP_DISC_REVPIP_ATTR_BY_QUARTER,
        config: {
            callFn: `goToNativeTerminusHubReport('app.discover.revenueAndPipeline.attributionByQuarter')`,
            url: '/attribution-by-quarter?&startDate&endDate&cohort&grp&dataSet&revenueType&influenceType',
            template: require('../../main/discover/revenue-and-pipeline/attribution-by-quarter/index.html'),
            controller: 'revByChannelByQtrCtrl',
            orgTypes: fullPlatform,
            data: {
                title: 'Attribution by Campaign Type',
                question: ['What marketing campaign groups influence the most pipeline/revenue?', 'What marketing campaign groups source the most pipeline/revenue?'],
                filter: allFilters,
            },
            navName: 'Attribution by Campaign Type',
        }
    };

    const dashboard = {
        name: DASHBOARD,
        icon: 'dashboard',
        config: {
            url: '/dashboard?id',
            callFn: `getTerminusHubUrlByReportName(${DASHBOARD})`,
            params: {
                routeId: null, // mandatory param to open dialog by terminus-hub
                influenceType: null,
                modelType: null,
                period: null,
                opptyType: null,
                cohort: null,
                endDate: null,
                startDate: null,
                model: null,
                closedType: null,
                dateType: null,
                type: null,
                wonType: null,
                dataSet: null,
                field: null,
                grp: null,
                freqType: null,
                previous: null,
                goal: null,
                globalFilters: null, // string with global filters, it has to be parsed
                accountLists: null, // string with ids
            },
            template: require('../../main/dashboard/index.html'),
            controller: 'DashboardCtrl',
            orgTypes: fullAndCampaignPlatform,
            data: { title: "Dashboard" },
            subnav: true,
            alwaysAdd: true,
            navName: 'Dashboard',
        }
    };

    const discover = {
        name: 'app.discover',
        icon: 'discover',
        config: {
            abstract: true,
            data: { title: "Discover" },
            orgTypes: fullAndCampaignPlatform,
            subnav: true,
            template: '<div ui-view></div>',
            url: '/discover',
        },
        children: [
            {
                name: 'app.discover.revenueAndPipeline',
                config: {
                    abstract: true,
                    url: '/revenue-and-pipeline',
                    data: { title: "Revenue & Pipeline" },
                    orgTypes: fullAndCampaignPlatform,
                    template: '<div ui-view></div>',
                },
                children: [
                    marketingImpact,
                    attributionByCampaignType,
                    {
                        name: APP_DISC_REVPIP_ATTR_TRENDS,
                        config: {
                            url: '/attribution-trends?metric&type&model',
                            template: require('../../main/discover/revenue-and-pipeline/attribution-trends/index.html'),
                            controller: 'revByChannelAcrossQtrCtrl',
                            orgTypes: fullPlatform,
                            data: {
                                title: 'Attribution Trends',
                                question: ['How have my marketing channels contributed to pipeline and revenue?'],
                                filter: allFilters
                            },
                            navName: 'Attribution Trends',
                        }
                    },
                ]
            },
            {
                name: 'app.discover.stageProgression',
                config: {
                    abstract: true,
                    url: '/stage-progression',
                    data: { title: "Stage Progression" },
                    orgTypes: fullPlatform,
                    upgradeMessage: 'Welcome to our Stage Analysis! This section provides insight into how your leads and contacts are progressing through the buyer’s journey. Better understand the conversion rates from lead stages to open Opportunities and more accurately forecast if you’re on pace for pipeline creation based on past lead velocities. <br><br>Learn more by contacting your CSM or <a href="mailto:support@terminus.com">support@terminus.com</a>.',
                    template: '<div ui-view></div>'
                },
                children: [
                    {
                        name: DISCOVER_STAGES_SNAPSHOT,
                        config: {
                            callFn: `goToNativeTerminusHubReport('app.discover.stageProgression.stagesSnapshot')`,
                            url: '/stages-snapshot?cohort&endDatet&startDate',
                            template: require('../../main/discover/stage-progression/stages-snapshot/index.html'),
                            controller: 'flatStageProgressCtrl',
                            orgTypes: fullPlatform,
                            data: {
                                title: 'Stages Snapshot',
                                question: ["For a given time period how did my leads/opptys progress through stages?"],
                                paramTransform: {
                                    // eslint-disable-next-line no-undef
                                    campaign: alwaysArray,
                                    // eslint-disable-next-line no-undef
                                    campaignGroup: alwaysArray
                                },
                                filter: ["lead", "opportunity", "account", "terminus_account"]
                            },
                            navName: 'Stages Snapshot',
                        },
                    },
                    {
                        name: DISCOVER_SP_ATTR,
                        config: {
                            callFn: `goToNativeTerminusHubReport('app.discover.stageProgression.attribution')`,
                            url: '/attribution?cohort&startDate&endDate&startStageSequence&campaignType&campaignId&attModel',
                            template: require('../../main/discover/stage-progression/attribution/index.html'),
                            controller: 'revWaterfallCtrl',
                            orgTypes: fullPlatform,
                            data: {
                                title: 'Cohorted Waterfall',
                                question: ['What happened to the leads/opptys based on snapshots of stages at cohorted points in time?'],
                                paramTransform: {
                                    // eslint-disable-next-line no-undef
                                    campaignType: alwaysArray,
                                    // eslint-disable-next-line no-undef
                                    campaignId: alwaysArray
                                },
                                filter: ["lead", "opportunity", "account", "terminus_account"]
                            },
                            navName: 'Cohorted Waterfall',
                        },
                        children: [
                            {
                                name: 'app.discover.stageProgression.details',
                                config: {
                                    callFn: `goToNativeTerminusHubReport('app.discover.stageProgression.details')`,
                                    url: '/details?detailStageName&startStageName&cohort&type&campaignType&campaignId&page&window&attModel&startDate&endDate',
                                    template: require('../../main/discover/stage-progression/attribution/details/index.html'),
                                    controller: 'revWaterfallDetailsCtrl',
                                    orgTypes: fullPlatform,
                                    blind: true,
                                    //reloadOnSearch: false,
                                    data: {
                                        title: 'Cohorted Waterfall Details',
                                        question: [],
                                        filter: ["lead", "opportunity", "account", "terminus_account"]
                                    },
                                    navName: 'Cohorted Waterfall Details',
                                }
                            }
                        ]
                    },
                    {
                        name: DISCOVER_SP_TRENDING,
                        config: {
                            callFn: `goToNativeTerminusHubReport('app.discover.stageProgression.trending')`,
                            url: '/trending?cohort&endCohort&{quarters:int}&startStage&endStage&campaignGroup',
                            template: require('../../main/discover/stage-progression/trending/index.html'),
                            controller: 'revWaterfallTrendingCtrl',
                            orgTypes: fullPlatform,
                            //reloadOnSearch: false,
                            data: {
                                title: 'Progression by Quarter',
                                question: ['Historically, what percent of leads/opptys from a particular stage converted and when?', 'What percent of leads should I expect to convert and when?'],
                                paramTransform: {
                                    // eslint-disable-next-line no-undef
                                    campaign: alwaysArray,
                                    // eslint-disable-next-line no-undef
                                    campaignGroup: alwaysArray
                                },
                                filter: ["lead", "opportunity", "account", "terminus_account"]
                            },
                            navName: 'Progression by Quarter',
                        },
                        children: [
                            {
                                name: DISCOVER_SP_TRENDING_DETAILS,
                                config: {
                                    callFn: `goToNativeTerminusHubReport('app.discover.stageProgression.trendingDetails')`,
                                    url: '/trending-details?&startStage&endStage&cohort&campaignGroup&campaignId&quarter',
                                    template: require('../../main/discover/stage-progression/trending/trending-details/index.html'),
                                    controller: 'revWaterfallTrendingDetailsCtrl',
                                    orgTypes: fullPlatform,
                                    blind: true,
                                    //reloadOnSearch: false,
                                    data: {
                                        title: 'Progression by Quarter Details',
                                        question: [],
                                        filter: ["lead", "opportunity", "account", "terminus_account"]
                                    },
                                    navName: 'Progression by Quarter Details',
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    };

    const analyze = {
        name: 'app.analyze',
        icon: 'analyze',
        config: {
            abstract: true,
            subnav: true,
            url: '/analyze',
            data: { title: "Analyze" },
            orgTypes: allPlatforms,
            template: '<div ui-view></div>'
        },
        children: []
    };

    const campaignList = {
        name: ANALYZE_CAMPAIGNS_LIST_ANALYSIS,
        config: {
            url: '/list-analysis?cohort&dataSet&chart&model&ty&{set:int}&co&t&gf&grp&startDate&endDate&search&stages&tab&groupName&allWebActivity&influenceType',
            template: require('../../main/analyze/campaigns/list-analysis/index.html'),
            controller: 'campaignPerformanceCtrl',
            orgTypes: fullAndCampaignPlatform,
            data: {
                title: 'Campaign Analytics',
                question: ['What specific campaigns are most effective?'],
                filter: allFilters,
                paramTransform: {
                    // eslint-disable-next-line no-undef
                    chart: alwaysArray
                },
                noHistory: true,
                pageType: 'campaign-performance'
            },
            redirectTo: 'app.analyze.campaigns.listAnalysis.campaignGroups',
            navName: 'Campaign Analytics',
        },
        children: [
            {
                name: 'app.analyze.campaigns.listAnalysis.campaignGroups',
                config: {
                    callFn: `goToNativeTerminusHubReport('app.analyze.campaigns.listAnalysis.campaignGroups')`,
                    url: '/campaign-groups',
                    template: require('../../main/analyze/campaigns/list-analysis/campaign-groups/index.html'),
                    alwaysAdd: true,
                    blind: true,
                    navName: 'Campaign Types',
                    orgTypes: fullAndCampaignPlatform,
                    data: {
                        title: 'Campaign Analytics',
                        question: ['What specific campaigns are most effective?'],
                        filter: allFilters,
                        paramTransform: {
                            // eslint-disable-next-line no-undef
                            chart: alwaysArray
                        },
                        groupLevel: true,
                        savedOn: 'groupData', //DO NOT CHANGE
                        pageType: 'campaign-performance'
                    }
                },
            },
            {
                name: 'app.analyze.campaigns.listAnalysis.campaigns',
                config: {
                    callFn: `goToNativeTerminusHubReport('app.analyze.campaigns.listAnalysis.campaignGroups')`,
                    url: '/campaigns',
                    template: require('../../main/analyze/campaigns/list-analysis/campaigns/index.html'),
                    alwaysAdd: true,
                    blind: true,
                    navName: 'Campaigns',
                    orgTypes: fullAndCampaignPlatform,
                    data: {
                        title: 'Campaign Analytics',
                        question: ['What specific campaigns are most effective?'],
                        filter: ["lead", "opportunity", "campaign", "account", "campaign_member", "terminus_account"],
                        paramTransform: {
                            // eslint-disable-next-line no-undef
                            chart: alwaysArray
                        },
                        savedOn: 'data', //DO NOT CHANGE
                        pageType: 'campaign-performance'
                    }
                }
            },
            {
                name: 'app.analyze.campaigns.listAnalysis.visual',
                config: {
                    url: '/visual',
                    template: require('../../main/analyze/campaigns/list-analysis/visual/index.html'),
                    alwaysAdd: true,
                    blind: true,
                    navName: 'Visual',
                    orgTypes: fullPlatform,
                    data: {
                        title: 'Campaigns Analytics',
                        question: ['What specific campaigns are most effective?'],
                        filter: allFilters,
                        paramTransform: {
                            // eslint-disable-next-line no-undef
                            chart: alwaysArray
                        },
                        savedOn: 'groupData', //DO NOT CHANGE
                        pageType: 'campaign-performance'
                    }
                }
            },
            {
                name: ANALYZE_CAMPAIGNS_CAMPAIGN_SPECIFIC,
                config: {
                    url: '/list-analysis/campaign-specific?campaignId&modelType&startDate&endDate&allWebActivity&influenceType',
                    template: require('../../main/analyze/campaigns/list-analysis/campaign-specific/index.html'),
                    controller: 'campaignCtrl',
                    orgTypes: fullAndCampaignPlatform,
                    abstract: true,
                    blind: true,
                    alwaysAdd: true,
                    tabs: true,
                    subnav: false,
                    resolve: {
                        required: function ($q, $stateParams) {
                            const defer = $q.defer();
                            if ($stateParams.campaignId) {
                                defer.resolve();
                            } else {
                                defer.reject();
                            }

                            return defer.promise;
                        }
                    },
                    data: {
                        title: 'Campaign Specific',
                        pageType: 'campaign-spec',
                        filter: allFilters,
                        question: [],
                        tabs: true,
                        noHistory: true
                    },
                    navName: 'Campaign Specific',
                },
                children: [
                    {
                        name: 'app.analyze.campaigns.campaignSpecific.attribution',
                        config: {
                            callFn: `goToNativeTerminusHubReport('app.analyze.campaigns.campaignSpecific.attribution')`,
                            url: '/attribution?cohort&tab&dataSet',
                            template: require('../../main/analyze/campaigns/list-analysis/campaign-specific/attribution/index.html'),
                            controller: 'campaignAttributionCtrl',
                            orgTypes: fullAndCampaignPlatform,
                            search: true,
                            alwaysAdd: true,
                            redirectTo: 'app.analyze.campaigns.campaignSpecific.attribution.leads',
                            navName: 'Campaign Specific',
                            //reloadOnSearch: false,
                            data: {
                                tabText: 'Campaign Specific',
                                pageType: 'campaign-spec',
                                tabs: true,
                                question: [],
                            }
                        },
                        children: [
                            {
                                name: 'app.analyze.campaigns.campaignSpecific.attribution.leads',
                                config: {
                                    callFn: `goToNativeTerminusHubReport('app.analyze.campaigns.campaignSpecific.attribution')`,
                                    url: '/leads',
                                    template: require('../../main/analyze/campaigns/list-analysis/campaign-specific/attribution/leads/index.html'),
                                    alwaysAdd: true,
                                    blind: true,
                                    navName: 'People',
                                    orgTypes: fullAndCampaignPlatform,
                                    data: {
                                        title: 'People',
                                        pageType: 'campaign-spec'
                                    }
                                }
                            },
                            {
                                name: 'app.analyze.campaigns.campaignSpecific.attribution.opportunities',
                                config: {
                                    callFn: `goToNativeTerminusHubReport('app.analyze.campaigns.campaignSpecific.attribution')`,
                                    url: '/opportunities',
                                    template: require('../../main/analyze/campaigns/list-analysis/campaign-specific/attribution/opportunities/index.html'),
                                    alwaysAdd: true,
                                    blind: true,
                                    navName: 'Opportunities',
                                    orgTypes: fullAndCampaignPlatform,
                                    data: {
                                        pageType: 'campaign-spec',
                                        title: 'Opportunities'
                                    }
                                }
                            },
                            {
                                name: 'app.analyze.campaigns.campaignSpecific.attribution.deals',
                                config: {
                                    callFn: `goToNativeTerminusHubReport('app.analyze.campaigns.campaignSpecific')`,
                                    url: '/deals',
                                    template: require('../../main/analyze/campaigns/list-analysis/campaign-specific/attribution/deals/index.html'),
                                    alwaysAdd: true,
                                    blind: true,
                                    navName: 'Deals',
                                    orgTypes: fullAndCampaignPlatform,
                                    data: {
                                        title: 'Deals',
                                        pageType: 'campaign-spec'
                                    }
                                }
                            },
                            {
                                name: 'app.analyze.campaigns.campaignSpecific.attribution.values',
                                config: {
                                    url: '/values',
                                    template: require('../../main/analyze/campaigns/list-analysis/campaign-specific/attribution/values/index.html'),
                                    alwaysAdd: true,
                                    blind: true,
                                    navName: 'Oppt. Value',
                                    orgTypes: fullPlatform,
                                    data: {
                                        title: 'Oppt. Value',
                                        pageType: 'campaign-spec'
                                    }
                                }
                            },
                            {
                                name: 'app.analyze.campaigns.campaignSpecific.attribution.stages',
                                config: {
                                    url: '/stages',
                                    template: require('../../main/analyze/campaigns/list-analysis/campaign-specific/attribution/stages/index.html'),
                                    alwaysAdd: true,
                                    blind: true,
                                    navName: 'LTD Conversion Rate',
                                    orgTypes: fullPlatform,
                                    data: {
                                        title: 'LTD Conversion Rate',
                                        pageType: 'campaign-spec'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        name: ANALYZE_CAMPAIGNS_CAMPAIGN_SPECIFIC_TRENDING,
                        config: {
                            url: '/trending?cohort&chartType',
                            template: require('../../main/analyze/campaigns/list-analysis/campaign-specific/trending/index.html'),
                            controller: 'campaignTrendingCtrl',
                            alwaysAdd: true,
                            blind: true,
                            navName: 'Campaign Specific Trending',
                            orgTypes: fullPlatform,
                            data: {
                                tabText: 'Campaign Specific Trending',
                                question: [],
                                tabs: true,
                                paramTransform: {
                                    // eslint-disable-next-line no-undef
                                    ago: alwaysArray
                                }
                            }
                        }
                    },
                    {
                        name: 'app.analyze.campaigns.campaignSpecific.sfdc',
                        config: {
                            url: '/sfdc',
                            alwaysAdd: true,
                            template: require('../../main/analyze/campaigns/list-analysis/campaign-specific/sfdc/index.html'),
                            controller: 'campaignSFDCCtrl',
                            orgTypes: fullAndCampaignPlatform,
                            //reloadOnSearch: false,
                            organizationCrmType: "SALESFORCE",
                            blind: true,
                            data: {
                                tabText: 'Salesforce Compare',
                                title: 'Salesforce Compare',
                                question: [],
                                tabs: true,
                                paramTransform: {
                                    // eslint-disable-next-line no-undef
                                    ago: alwaysArray
                                },
                                tabIcon: 'cloud',
                                filter: []
                            },
                            navName: 'Salesforce Compare',
                            callFn: `goToNativeTerminusHubReport('app.analyze.campaigns.campaignSpecific.sfdc')`,
                        }
                    }
                ]
            }
        ]
    };

    const campaignTrends = {
        name: ANALYZE_CAMPAIGNS_TRENDING_ANALYSIS,
        config: {
            callFn: `goToNativeTerminusHubReport('app.analyze.campaigns.trendingAnalysis')`,
            url: '/trending-analysis?cohort&dataSet&chart&model&startDate&endDate&search&stages&field&grp&previous&type&freqType&dashboard&goal',
            template: require('../../main/analyze/campaigns/trending-analysis/index.html'),
            controller: 'campaignPerformanceTrendingCtrl',
            orgTypes: fullAndCampaignPlatform,
            //reloadOnSearch: false,
            navName: 'Campaign Trends',
            data: {
                title: 'Campaign Trends',
                question: ['How are campaign fields trending over time?'],
                filter: allFilters,
                pageType: 'campaign-performance'
            },
        }
    };

    const historicalAverages = {
        name: 'app.analyze.campaigns.historicalAverages',
        config: {
            url: '/historical-averages-campaign-group?startDate&endDate&cohort&groupType&search',
            template: require('../../main/analyze/campaigns/historical-averages-campaign-group/index.html'),
            controller: 'HistoricalAverageCtrl',
            orgTypes: fullPlatform,
            //reloadOnSearch: false,
            data: {
                title: 'Historical Averages',
                question: ['What was the past performance of prior campaign groups?'],
                filter: ["lead", "opportunity", "account"],
                by: 'campaign'
            },
            navName: 'Historical Averages',
        }
    };

    const historicalAveragesByLeadGroup = {
        name: 'app.analyze.campaigns.historicalAveragesByLeads',
        config: {
            url: '/historical-averages?startDate&endDate&groupType',
            template: require('../../main/analyze/campaigns/historical-averages/index.html'),
            controller: 'HistoricalAverageCtrl',
            orgTypes: fullPlatform,
            //reloadOnSearch: false,
            blind: true,
            data: {
                title: 'Historical Averages by Lead Group',
                // subtitle: 'Lead Performance by Lead Group',
                question: ['What are the all time averages per lead type?'],
                filter: ["lead", "opportunity", "account"],
                by: 'lead'
            },
            navName: 'Historical Averages by Lead Group',
        },
        children: [
            {
                name: 'app.analyze.campaigns.historicalAveragesDetails',
                config: {
                    url: '/historical-averages/haDetails?startDate&endDate&groupType&group&cohort',
                    template: require('../../main/analyze/campaigns/historical-averages/haDetails/index.html'),
                    controller: 'HistoricalAverageDetailsCtrl',
                    orgTypes: fullPlatform,
                    blind: true,
                    data: {
                        title: 'Historical Averages Details',
                        question: [],
                        filter: ["lead", "opportunity", "account"]
                    },
                    navName: 'Historical Averages Details',
                }
            }
        ]
    };

    var campaigns = {
        name: 'app.analyze.campaigns',
        config: {
            abstract: true,
            url: '/campaigns',
            data: { title: "Campaigns" },
            orgTypes: fullAndCampaignPlatform,
            template: '<div ui-view></div>'
        },
        children: [
            campaignList,
            campaignTrends,
            historicalAverages,
            historicalAveragesByLeadGroup,
        ],
    };

    const webTracking = {
        name: 'app.analyze.webTracking',
        config: {
            abstract: true,
            url: '/digital-attribution',
            data: { title: "Digital Attribution" },
            orgTypes: fullAndCampaignPlatform,
            template: '<div ui-view></div>'
        },
        children: [
            {
                name: APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY,
                config: {
                    url: '/web-activity?cohort&dataSet&chart&model&ty&{set:int}&co&t&gf&grp&mf&startDate&endDate&search&stages',
                    template: require('../../main/analyze/digital-attribution/web-activity/index.html'),
                    controller: 'campaignPerformanceCtrl',
                    orgTypes: fullAndCampaignPlatform,
                    //reloadOnSearch: false,
                    data: {
                        title: 'Web Activities List',
                        filter: allFilters,
                        pageType: 'web-activity'
                    },
                    navName: 'Web Activities List',
                    redirectTo: APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL,
                },
                children: [
                    {
                        name: APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL_MEDIUM,
                        config: {
                            url: '/channel-medium',
                            callFn: `goToNativeTerminusHubReport(${APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL_MEDIUM})`,
                            template: require('../../main/analyze/digital-attribution/web-activity/channel-medium/index.html'),
                            alwaysAdd: true,
                            blind: true,
                            navName: 'UTM Medium',
                            orgTypes: fullPlatform,
                            data: {
                                title: 'Web Activities List',
                                filter: allFilters,
                                paramTransform: {
                                    // eslint-disable-next-line no-undef
                                    chart: alwaysArray
                                },
                                savedOn: 'mediumData', //DO NOT CHANGE
                                groupLevel: true,
                                pageType: 'web-activity'
                            }
                        },
                    },
                    {
                        name: APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL,
                        config: {
                            url: '/channel-group',
                            callFn: `goToNativeTerminusHubReport(${APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL})`,
                            template: require('../../main/analyze/digital-attribution/web-activity/channel-group/index.html'),
                            alwaysAdd: true,
                            blind: true,
                            navName: 'UTM Sources',
                            orgTypes: fullPlatform,
                            data: {
                                title: 'Web Activities List',
                                question: ['What specific campaigns are most effective?'],
                                filter: allFilters,
                                paramTransform: {
                                    // eslint-disable-next-line no-undef
                                    chart: alwaysArray
                                },
                                groupLevel: true,
                                savedOn: 'groupData', //DO NOT CHANGE
                                pageType: 'web-activity'
                            }
                        },
                    },
                    {
                        name: APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL_ASSETS,
                        config: {
                            url: '/channel-name',
                            callFn: `goToNativeTerminusHubReport(${APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL_ASSETS})`,
                            template: require('../../main/analyze/digital-attribution/web-activity/channel-name/index.html'),
                            alwaysAdd: true,
                            blind: true,
                            navName: 'UTM Campaign',
                            orgTypes: fullPlatform,
                            data: {
                                title: 'Web Activities List',
                                question: ['What specific campaigns are most effective?'],
                                filter: allFilters,
                                paramTransform: {
                                    // eslint-disable-next-line no-undef
                                    chart: alwaysArray
                                },
                                savedOn: 'data', //DO NOT CHANGE
                                pageType: 'web-activity'
                            }
                        },
                    },
                    // {
                    // 	name: 'app.analyze.webTracking.webActivity.visual',
                    // 	config: {
                    // 		url: '/visual',
                    // 		template: require('./web-activity/web-activity-visual.html'),
                    // 		alwaysAdd: true,
                    // 		blind: true,
                    // 		navName: 'Visual',
                    // 		data : {
                    // 			title: 'Web Activities List',
                    // 			question: ['What specific campaigns are most effective?'],
                    //                                  filter: ["lead", "opportunity", "campaign", "account", "campaign_member"],
                    // 			paramTransform: {
                    // 				chart: alwaysArray
                    // 			},
                    // 			pageType:'web-activity'
                    // 		}
                    // 	},
                    // },
                    {
                        name: 'app.analyze.webTracking.channelAssetSpecific',
                        config: {
                            url: '/web-activity/channel-asset-specific?campaignId&channelId&modelType&startDate&endDate',
                            template: require('../../main/analyze/digital-attribution/web-activity/channel-asset-specific/index.html'),
                            controller: 'campaignCtrl',
                            orgTypes: fullPlatform,
                            abstract: true,
                            blind: true,
                            alwaysAdd: true,
                            tabs: true,
                            subnav: false,
                            resolve: {
                                required: function ($q, $stateParams) {
                                    const defer = $q.defer();
                                    if ($stateParams.campaignId) {
                                        defer.resolve();
                                    } else {
                                        defer.reject();
                                    }

                                    return defer.promise;
                                }
                            },
                            data: {
                                title: 'UTM Campaign Specific',
                                filter: allFilters,
                                question: [],
                                tabs: true,
                                noHistory: true,
                                pageType: 'channel-asset-specific'
                            },
                            navName: 'UTM Campaign Specific',
                        },
                        children: [
                            {
                                name: 'app.analyze.webTracking.channelAssetSpecific.attribution',
                                config: {
                                    callFn: `goToNativeTerminusHubReport('app.analyze.webTracking.channelAssetSpecific.attribution')`,
                                    url: '/attribution?cohort&tab&dataSet',
                                    template: require('../../main/analyze/digital-attribution/web-activity/channel-asset-specific/attribution/index.html'),
                                    controller: 'campaignAttributionCtrl',
                                    orgTypes: fullPlatform,
                                    search: true,
                                    alwaysAdd: true,
                                    redirectTo: 'app.analyze.webTracking.channelAssetSpecific.attribution.leads',
                                    navName: 'UTM Campaign Specific',
                                    //reloadOnSearch: false,
                                    data: {
                                        tabText: 'UTM Campaign Specific',
                                        tabs: true,
                                        question: [],
                                        pageType: 'channel-asset-specific'
                                    }
                                },
                                children: [
                                    {
                                        name: 'app.analyze.webTracking.channelAssetSpecific.attribution.leads',
                                        config: {
                                            callFn: `goToNativeTerminusHubReport('app.analyze.webTracking.channelAssetSpecific.attribution')`,
                                            url: '/leads',
                                            template: require('../../main/analyze/digital-attribution/web-activity/channel-asset-specific/attribution/leads/index.html'),
                                            alwaysAdd: true,
                                            blind: true,
                                            navName: 'Leads',
                                            orgTypes: fullPlatform,
                                            data: {
                                                title: 'Leads',
                                                pageType: 'channel-asset-specific'
                                            }
                                        }
                                    },
                                    {
                                        name: 'app.analyze.webTracking.channelAssetSpecific.attribution.opportunities',
                                        config: {
                                            callFn: `goToNativeTerminusHubReport('app.analyze.webTracking.channelAssetSpecific.attribution')`,
                                            url: '/opportunities',
                                            template: require('../../main/analyze/digital-attribution/web-activity/channel-asset-specific/attribution/opportunities/index.html'),
                                            alwaysAdd: true,
                                            blind: true,
                                            navName: 'Opportunities',
                                            orgTypes: fullPlatform,
                                            data: {
                                                title: 'Opportunities',
                                                pageType: 'channel-asset-specific'
                                            }
                                        }
                                    },
                                    {
                                        name: 'app.analyze.webTracking.channelAssetSpecific.attribution.deals',
                                        config: {
                                            callFn: `goToNativeTerminusHubReport('app.analyze.webTracking.channelAssetSpecific.attribution')`,
                                            url: '/deals',
                                            template: require('../../main/analyze/digital-attribution/web-activity/channel-asset-specific/attribution/deals/index.html'),
                                            alwaysAdd: true,
                                            blind: true,
                                            navName: 'Deals',
                                            orgTypes: fullPlatform,
                                            data: {
                                                title: 'Deals',
                                                pageType: 'channel-asset-specific'
                                            }
                                        }
                                    },
                                    {
                                        name: 'app.analyze.webTracking.channelAssetSpecific.attribution.values',
                                        config: {
                                            url: '/values',
                                            template: require('../../main/analyze/digital-attribution/web-activity/channel-asset-specific/attribution/values/index.html'),
                                            alwaysAdd: true,
                                            blind: true,
                                            navName: 'Oppt. Value',
                                            orgTypes: fullPlatform,
                                            data: {
                                                title: 'Oppt. Value',
                                                pageType: 'channel-asset-specific'
                                            }
                                        }
                                    },
                                    {
                                        name: 'app.analyze.webTracking.channelAssetSpecific.attribution.stages',
                                        config: {
                                            url: '/stages',
                                            template: require('../../main/analyze/digital-attribution/web-activity/channel-asset-specific/attribution/stages/index.html'),
                                            alwaysAdd: true,
                                            blind: true,
                                            navName: 'LTD Conversion Rate',
                                            orgTypes: fullPlatform,
                                            data: {
                                                title: 'LTD Conversion Rate',
                                                pageType: 'channel-asset-specific'
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                name: 'app.analyze.webTracking.channelAssetSpecific.trending',
                                config: {
                                    url: '/trending?cohort&chartType',
                                    template: require('../../main/analyze/digital-attribution/web-activity/channel-asset-specific/trending/index.html'),
                                    controller: 'campaignTrendingCtrl',
                                    alwaysAdd: true,
                                    blind: true,
                                    navName: 'UTM Campaign Specific',
                                    orgTypes: fullPlatform,
                                    data: {
                                        tabText: 'UTM Campaign Specific Trending',
                                        question: [],
                                        tabs: true,
                                        pageType: 'channel-asset-specific',
                                        paramTransform: {
                                            // eslint-disable-next-line no-undef
                                            ago: alwaysArray
                                        }
                                    }
                                }
                            }
                        ]
                    },
                ],
            },
            {
                name: APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_TRENDING,
                config: {
                    url: '/web-activity-trending?cohort&dataSet&chart&model&startDate&endDate&search&stages&field&grp&previous&type&freqType&dashboard&goal',
                    template: require('../../main/analyze/digital-attribution/web-activity/web-activity-trending/index.html'),
                    controller: 'campaignPerformanceTrendingCtrl',
                    callFn: `goToNativeTerminusHubReport(${APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_TRENDING})`,
                    orgTypes: fullPlatform,
                    navName: 'Web Activities Trending',
                    data: {
                        title: 'Web Activities Trending',
                        question: ['How are campaign fields trending over time?'],
                        filter: allFilters,
                        pageType: 'web-activity'
                    },
                }
            }
        ]
    };

    const opptyList = {
        name: APP_ANALYZE_OPPTY_INSIGHTS,
        config: {
            callFn: `goToNativeTerminusHubReport(${APP_ANALYZE_OPPTY_INSIGHTS})`,
            url: '/list-analysis?t&sortBy&startDate&endDate&cohort&dateType&closedType&wonType&model&tbl&fld&dir&pg&influencedCampaignGroup&sourceCampaignGroup&type',
            template: require('../../main/analyze/opportunities/list-analysis/index.html'),
            controller: 'OpptysInRangeCtrl',
            orgTypes: allPlatforms,
            data: {
                title: "Opportunity Insights",
                question: ['How much marketing engagement is influencing my closed won opportunities?', 'When in the sales cycle is my marketing engagement occuring for opportunities?', 'How many contacts are being engaged by marketing influence on opportunities?'],
                filter: allFilters
            },
            navName: 'Opportunity Insights',
        },
        children: [
            {
                name: 'app.analyze.opportunities.allOpptysTouches',
                config: {
                    callFn: `goToNativeTerminusHubReport('app.analyze.opportunities.allOpptysTouches')`,
                    url: '/list-analysis/all-opptys-touches?t&sortBy&startDate&endDate&cohort&dateType&closedType&model&influencedCampaignGroup&sourceCampaignGroup&type&total&wonType',
                    template: require('../../main/analyze/opportunities/list-analysis/all-opptys-touches/index.html'),
                    controller: 'AllOpptysTouchesCtrl',
                    orgTypes: fullPlatform,
                    blind: true,
                    data: {
                        filter: allFilters,
                        title: "Opportunities Touches",
                        question: []
                    },
                    navName: 'Opportunities Touches',
                }
            },
            {
                name: 'app.analyze.opportunities.opportunitySpecific',
                config: {
                    callFn: `goToNativeTerminusHubReport('app.analyze.opportunities.opportunitySpecific')`,
                    url: '/list-analysis/opportunity-specific?oppty&isId&model',
                    template: require('../../main/analyze/opportunities/list-analysis/opportunity-specific/index.html'),
                    controller: 'campaignCtrl',
                    orgTypes: fullAndCampaignPlatform,
                    abstract: true,
                    search: true,
                    blind: true,
                    subnav: false,
                    navName: 'Opportunity Journey',
                    data: {
                        title: 'Opportunity Journey',
                        pageType: 'oppty-spec',
                    }
                },
                children: [
                    {
                        name: 'app.analyze.opportunities.opportunitySpecific.attribution',
                        config: {
                            callFn: `goToNativeTerminusHubReport('app.analyze.opportunities.opportunitySpecific.attribution')`,
                            url: '/attribution?cohort&tab&dataSet',
                            template: require('../../main/analyze/opportunities/list-analysis/opportunity-specific/attribution/index.html'),
                            controller: 'OpportunityCampaignHistoryCtrl',
                            orgTypes: fullAndCampaignPlatform,
                            search: true,
                            alwaysAdd: true,
                            redirectTo: 'app.analyze.opportunities.opportunitySpecific.attribution.totalTouches',
                            navName: 'Opportunity Journey',
                            //reloadOnSearch: false,
                            data: {
                                tabText: 'Opportunity Journey',
                                pageType: 'oppty-spec',
                            }
                        },
                        children: [
                            {
                                name: 'app.analyze.opportunities.opportunitySpecific.attribution.totalTouches',
                                config: {
                                    callFn: `goToNativeTerminusHubReport('app.analyze.opportunities.opportunitySpecific.attribution.totalTouches')`,
                                    url: '/total-touches',
                                    template: require('../../main/analyze/opportunities/list-analysis/opportunity-specific/attribution/total-touches/index.html'),
                                    alwaysAdd: true,
                                    blind: true,
                                    navName: 'Responses',
                                    orgTypes: fullAndCampaignPlatform,
                                    data: {
                                        title: 'Responses',
                                        pageType: 'oppty-spec'
                                    }
                                }
                            },
                            {
                                name: 'app.analyze.opportunities.opportunitySpecific.attribution.peopleInfluencing',
                                config: {
                                    callFn: `goToNativeTerminusHubReport('app.analyze.opportunities.opportunitySpecific.attribution.peopleInfluencing')`,
                                    url: '/people-influencing',
                                    template: require('../../main/analyze/opportunities/list-analysis/opportunity-specific/attribution/people-influencing/index.html'),
                                    search: true,
                                    alwaysAdd: true,
                                    blind: true,
                                    navName: 'People',
                                    orgTypes: fullAndCampaignPlatform,
                                    //reloadOnSearch: false,
                                    data: {
                                        pageType: 'oppty-spec',
                                    }
                                }
                            },
                            {
                                name: 'app.analyze.opportunities.opportunitySpecific.attribution.campaignsInfluencing',
                                config: {
                                    callFn: `goToNativeTerminusHubReport('app.analyze.opportunities.opportunitySpecific.attribution.campaignsInfluencing')`,
                                    url: '/campaignInfluencing',
                                    template: require('../../main/analyze/opportunities/list-analysis/opportunity-specific/attribution/campaignInfluencing/index.html'),
                                    search: true,
                                    alwaysAdd: true,
                                    blind: true,
                                    navName: 'Campaigns',
                                    orgTypes: fullAndCampaignPlatform,
                                    //reloadOnSearch: false,
                                    data: {
                                        pageType: 'oppty-spec',
                                    }
                                }
                            },
                        ]
                    },
                ]
            },
        ]
    };

    const opportunities = {
        name: 'app.analyze.opportunities',
        config: {
            abstract: true,
            data: { title: "Opportunities" },
            url: '/opportunities',
            orgTypes: allPlatforms,
            template: '<div ui-view></div>'
        },
        children: [
            opptyList
        ]
    };

    const accountList = {
        name: APP_ANALYZE_ACCOUNT_ATTRIBUTION,
        config: {
            url: '/list-analysis',
            callFn: `getTerminusHubUrlByReportName(${APP_ANALYZE_ACCOUNT_ATTRIBUTION}, 'data')`,
            template: require('../../main/analyze/accounts/list-analysis/index.html'),
            controller: 'accountsAttributionCtrl',
            orgTypes: allPlatforms,
            data: {
                title: 'Account Hub',
                question: ['What is the marketing and sales engagement for my target accounts?'],
            },
            navName: 'Account Hub',
        },
        children: [
            {
                name: APP_ANALYZE_ACCOUNTS_ACCOUNT_SPECIFIC,
                config: {
                    tabs: true,
                    url: '/list-analysis/account-specific?accountId&cohort',
                    template: require('../../main/analyze/campaigns/list-analysis/account-specific/index.html'),
                    blind: true,
                    orgTypes: allPlatforms,
                    subnav: false,
                    data: {
                        title: 'Account Specific',
                        filter: allFilters,
                    },
                    navName: 'Account Specific',
                },
                children: [
                    {
                        name: APP_ANALYZE_ACCOUNTS_ACCOUNT_SPECIFIC_ATTRIBUTION,
                        config: {
                            url: '/attribution?startDate&endDate&timeUnit&withSalesActivity',
                            template: require('../../main/analyze/campaigns/list-analysis/account-specific/attribution/index.html'),
                            controller: 'accountCtrl',
                            search: true,
                            alwaysAdd: true,
                            navName: 'Account Specific',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Account Snapshot',
                                tabText: 'Account Specific',
                                tabs: true,
                                question: [],
                            },
                        }
                    },
                    {
                        name: APP_ANALYZE_ACCOUNTS_ACCOUNT_SPECIFIC_ACCOUNT_JOURNEY,
                        config: {
                            url: '/account-journey',
                            template: require('../../sfdcJourney/account-journey.html'),
                            controller: 'accountJourneyController',
                            navName: 'Account Insights',
                            orgTypes: allPlatforms,
                            hideGlobalFilters: true,
                            data: {
                                title: 'Account Journey',
                                tabText: 'Account Insights',
                                tabs: true,
                                question: [],
                            },
                        }
                    },
                    {
                        name: APP_ANALYZE_ACCOUNTS_ACCOUNT_SPECIFIC_TRENDING,
                        config: {
                            url: '/trending?metric&startDate&endDate',
                            template: require('../../main/analyze/campaigns/list-analysis/account-specific/trending/index.html'),
                            controller: 'accountTrendingCtrl',
                            search: true,
                            alwaysAdd: true,
                            blind: true,
                            orgTypes: fullPlatform,
                            data: {
                                title: 'Account Snapshot Trending',
                                tabText: 'Account Specific Trending',
                                tabs: true,
                                question: []
                            },
                            navName: 'Account Snapshot Trending',
                        }
                    }
                ]
            }
        ]
    };

    const accounts = {
        name: 'app.analyze.accounts',
        icon: 'group',
        config: {
            abstract: true,
            url: '/accounts?cohort&startDate&endDate&account_filter&metric&search&withSalesActivity&fieldType',
            template: require('../../main/analyze/accounts/index.html'),
            controller: 'accountsCtrl',
            orgTypes: allPlatforms,
            //reloadOnSearch: false,
            data: {
                title: 'Accounts',
                question: [],
                upgradeMessage: '<i class="icon-ban space-right"></i>Welcome to our ABM Command Center! Here you can understand which Target Accounts are primed for Sales to engage with and deep-dive into specific accounts for deeper, comprehensive insights on all valid touches. <br><br>Learn more by contacting your CSM or <a href="mailto:support@terminus.com">support@terminus.com</a>.',
                filter: allFilters,
                navName: 'Accounts',
            }
        },
        children: [
            {
                name: 'app.analyze.accounts.scorecard',
                config: {
                    callFn: `goToNativeTerminusHubReport('app.analyze.accounts.scorecard')`,
                    noUrl: true,
                    orgTypes: allPlatforms,
                    data: {
                        title: 'ABM Scorecard',
                        navName: 'Scorecard'
                    }
                },
                children: [
                    {
                        name: APP_ANALYZE_ACCOUNTS_SCORECARD_KPI,
                        config:{
                            blind: true,
                            noUrl: true,
                            orgTypes: allPlatforms,
                            subnav: false,
                            data: {
                                title: 'Trending Kpi'
                            },
                            navName: 'Trending Kpi',
                        }
                    },
                ]
            },
            accountList,
            {
                name: APP_ANALYZE_ACCOUNTS_TRENDING,
                config: {
                    url: '/trending',
                    template: require('../../main/analyze/accounts/trending/index.html'),
                    controller: 'accountsTrendingCtrl',
                    orgTypes: fullPlatform,
                    data: {
                        title: 'Account Trends',
                        navName: 'Search Specific Trending',
                        question: ['How are my ABM metrics trending over cohorts of time?'],
                        tabIcon: 'stats'
                    },
                    navName: 'Account Trends',
                },
            }
        ],
    };

    const settings = {
        name: 'app.settings',
        config: {
            abstract: true,
            subnav: false,
            url: '/settings',
            alwaysAdd: false,
            template: require('../../main/settings/index.html'),
            orgTypes: allPlatforms,
            data: {
                title: 'Settings',
            }
        },
        children: [
            {
                name: 'app.settings.profile',
                config: {
                    url: '/profile',
                    alwaysAdd: true,
                    blind: true,
                    templateUrl: '',
                    orgTypes: fullAndCampaignPlatform,
                    data: {
                        title: 'Profile',
                        permissionLevel: "org_admin",
                    },
                    navName: 'Profile Settings',
                },
            },
            {
                name: 'app.settings.permissions',
                config: {
                    url: '/permissions',
                    alwaysAdd: true,
                    redirectTo: 'app.settings.permissions.teamPermissions',
                    template: '<div ui-view></div>',
                    orgTypes: allPlatforms,
                    data: {
                        title: 'Permissions',
                        permissionLevel: "org_admin"
                    },
                    navName: 'Permissions Settings',
                },
                children: [
                    {
                        name: 'app.settings.permissions.teamPermissions',
                        config: {
                            alwaysAdd: true,
                            url: '/team',
                            template: require('../../main/settings/permissions/team/index.html'),
                            controller: 'teamConfigureCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Team Permissions',
                                permissionLevel: "org_admin",
                                filter: allFilters
                            },
                            navName: 'Team Permissions Settings',
                        },
                    },
                ],
            },
            {
                name: 'app.settings.stages',
                config: {
                    url: '/stages',
                    alwaysAdd: true,
                    template: require('../../main/settings/stages/index.html'),
                    controller: 'waterfallConfigCtrl',
                    orgTypes: fullPlatform,
                    data: {
                        title: 'Stages',
                        permissionLevel: "org_admin"
                    },
                    navName: 'Stages Settings',
                },
            },
            {
                name: 'app.settings.pagesAndFeatures',
                config: {
                    url: '/pages-and-features',
                    alwaysAdd: true,
                    redirectTo: 'app.settings.pagesAndFeatures.userInterface',
                    template: '<div ui-view></div>',
                    orgTypes: allPlatforms,
                    data: {
                        title: 'Pages and Features'
                    },
                },
                children: [
                    {
                        name: 'app.settings.pagesAndFeatures.userInterface',
                        config: {
                            url: '/user-interface',
                            alwaysAdd: true,
                            template: require('../../main/settings/pages-and-features/user-interface/index.html'),
                            controller: 'userInterfaceCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'User Interface',
                                tabText: 'User Interface'
                            },
                            navName: 'User Interface Settings',
                        },
                    },
                    {
                        name: 'app.settings.pagesAndFeatures.salesActivity',
                        config: {
                            url: '/sales-activity',
                            alwaysAdd: true,
                            template: require('../../main/settings/pages-and-features/sales-activity/index.html'),
                            controller: 'salesActivityConfigCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Sales Activity',
                                tabText: 'Sales Activity',
                                permissionLevel: "org_admin"
                            },
                            navName: 'Sales Activity Settings',
                        },
                    },
                ],
            },
            {
                name: 'app.settings.dataHygiene',
                config: {
                    url: '/data-hygiene',
                    alwaysAdd: true,
                    redirectTo: 'app.settings.dataHygiene.orphanLead',
                    template: '<div ui-view></div>',
                    orgTypes: allPlatforms,
                    data: {
                        title: 'Data Hygiene',
                        permissionLevel: "org_admin",
                    },
                    navName: 'Data Hygiene Settings',
                },
                children: [
                    {
                        name: 'app.settings.dataHygiene.orphanLead',
                        config: {
                            url: '/orphan-lead-finder?editable',
                            alwaysAdd: true,
                            template: require('../../main/settings/data-hygiene/orphan-lead-finder/index.html'),
                            controller: 'orphanLeadFinderCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Orphan Lead Finder',
                                question: [],
                                permissionLevel: "org_admin"
                            },
                            navName: 'Orphan Lead Finder Settings',
                        },
                    },
                ],
            },
            {
                name: 'app.settings.attributionWeight',
                config: {
                    url: '/attribution-weight?editable',
                    callFn: `goToTerminusHub('feature-settings-migrated', 'app.settings.attributionWeight')`,
                    template: require('../../main/settings/attribution-weight/index.html'),
                    controller: 'customWeightCtrl',
                    orgTypes: fullPlatform,
                    data: {
                        title: 'Campaign Scoring',
                        permissionLevel: "org_admin"
                    },
                    navName: 'Campaign Scoring Settings',
                }
            },
            {
                name: 'app.settings.webTracking',
                config: {
                    url: '/digital-attribution',
                    alwaysAdd: true,
                    template: require('../../main/settings/digital-attribution/index.html'),
                    controller: 'webTrackingCtrl',
                    orgTypes: fullPlatform,
                    data: {
                        title: 'Digital Attribution',
                        permissionLevel: "org_admin"
                    },
                    navName: 'Web Tracking Settings',
                }
            },
            {
                name: 'app.settings.globalFilters',
                config: {
                    url: '/global-filters',
                    blind: true,
                    alwaysAdd: true,
                    templateUrl: '',
                    orgTypes: allPlatforms,
                    data: {
                        title: 'Global Filters',
                        permissionLevel: "org_admin"
                    },
                    navName: 'Global Filters Settings',
                }
            },
            {
                name: 'app.settings.godMode',
                config: {
                    callFn: 'renderPopUp4GodMode()',
                    alwaysAdd: true,
                    orgTypes: allPlatforms,
                    data: {
                        title: 'Switch User',
                        permissionLevel: "bf_admin"
                    },
                },
            },
            {
                name: 'app.settings.internalConfigure',
                config: {
                    url: '/internal-configure',
                    alwaysAdd: true,
                    template: '<div ui-view></div>',
                    controller: 'internalConfigCtrl',
                    orgTypes: allPlatforms,
                    redirectTo: 'app.settings.internalConfigure.permissions',
                    data: {
                        title: 'Internal Configuration',
                        permissionLevel: "bf_admin"
                    },
                },
                children: [
                    {
                        name: 'app.settings.internalConfigure.permissions',
                        config: {
                            url: '/permissions',
                            alwaysAdd: true,
                            template: require('../../main/settings/internal-configure/permissions/index.html'),
                            controller: 'permissionConfigCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Permissions',
                                permissionLevel: "bf_admin"
                            },
                            navName: 'Permissions Internal Config',
                        }
                    },
                    {
                        name: 'app.settings.internalConfigure.dataCycle',
                        config: {
                            url: '/data-cycle',
                            alwaysAdd: true,
                            template: require('../../main/settings/internal-configure/data-cycle/index.html'),
                            controller: 'dataCycleConfigCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Data Cycle',
                                permissionLevel: "bf_admin"
                            },
                            navName: 'Data Cycle Internal Config',
                        }
                    },
                    {
                        name: 'app.settings.internalConfigure.olf',
                        config: {
                            url: '/olf',
                            alwaysAdd: true,
                            template: require('../../main/settings/internal-configure/olf/index.html'),
                            controller: 'olfConfigCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'OLF',
                                permissionLevel: "bf_admin"
                            },
                            navName: 'OLF Internal Config',
                        }
                    },
                    {
                        name: 'app.settings.internalConfigure.waterfall',
                        config: {
                            url: '/waterfall',
                            alwaysAdd: true,
                            template: require('../../main/settings/internal-configure/waterfall/index.html'),
                            controller: 'waterfallConfigCtrl',
                            orgTypes: fullAndCampaignPlatform,
                            data: {
                                title: 'Waterfall',
                                permissionLevel: "bf_admin"
                            },
                            navName: 'Waterfall Internal Config',
                        }
                    },
                    {
                        name: 'app.settings.internalConfigure.demo',
                        config: {
                            url: '/demo',
                            alwaysAdd: true,
                            template: require('../../main/settings/internal-configure/demo/index.html'),
                            controller: 'demoConfigCtrl',
                            orgTypes: fullAndCampaignPlatform,
                            data: {
                                title: 'Demo',
                                permissionLevel: "bf_admin"
                            },
                            navName: 'Demo Internal Config',
                        }
                    },
                    {
                        name: 'app.settings.internalConfigure.users',
                        config: {
                            url: '/users',
                            alwaysAdd: true,
                            template: require('../../main/settings/internal-configure/users/index.html'),
                            controller: 'userConfigCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Users',
                                permissionLevel: "bf_admin"
                            },
                            navName: 'Users Internal Config',
                        }
                    },
                    {
                        name: 'app.settings.internalConfigure.organizations',
                        config: {
                            url: '/organizations',
                            alwaysAdd: true,
                            template: require('../../main/settings/internal-configure/organizations/index.html'),
                            controller: 'orgConfigCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Organizations',
                                permissionLevel: "bf_admin"
                            },
                            navName: 'Organizations Internal Config',
                        }
                    },
                    {
                        name: 'app.settings.internalConfigure.sqlConfigs',
                        config: {
                            noUrl: true,
                            parentIsGrandParent: true,
                            alwaysAdd: true,
                            template: '<div ui-view></div>',
                            controller: 'sqlConfigCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'SQL Config',
                                permissionLevel: "bf_admin"
                            },
                            navName: 'SQL Internal Config',
                        },
                        children: [
                            {
                                name: 'app.settings.internalConfigure.sqlConfigs.mySqlConfig',
                                config: {
                                    url: '/my-sql-config',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/internal-configure/my-sql-config/index.html'),
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'My SQL Config',
                                        permissionLevel: "bf_admin"
                                    },
                                    navName: 'My SQL Internal Config',
                                }
                            },
                            {
                                name: 'app.settings.internalConfigure.sqlConfigs.mySqlDiff',
                                config: {
                                    url: '/my-sql-diff',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/internal-configure/my-sql-diff/index.html'),
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'My SQL Diff',
                                        permissionLevel: "bf_admin"
                                    },
                                    navName: 'My SQL Diff Internal Config',
                                }
                            },
                            {
                                name: 'app.settings.internalConfigure.sqlConfigs.impalaConfig',
                                config: {
                                    url: '/impala-config',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/internal-configure/impala-config/index.html'),
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'Impala Config',
                                        permissionLevel: "bf_admin"
                                    },
                                    navName: 'Impala Internal Config',
                                }
                            },
                            {
                                name: 'app.settings.internalConfigure.sqlConfigs.impalaDiff',
                                config: {
                                    url: '/impala-diff',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/internal-configure/impala-diff/index.html'),
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'Impala Diff',
                                        permissionLevel: "bf_admin"
                                    },
                                    navName: 'Impala Diff Internal Config',
                                }
                            }
                        ]
                    },
                    {
                        name: 'app.settings.internalConfigure.cache',
                        config: {
                            url: '/cache',
                            alwaysAdd: true,
                            template: require('../../main/settings/internal-configure/cache/index.html'),
                            controller: 'cacheConfigCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Cache',
                                permissionLevel: "bf_admin"
                            },
                            navName: 'Cache Internal Config',
                        }
                    },
                    {
                        name: 'app.settings.internalConfigure.webTracking',
                        config: {
                            url: '/web-tracking',
                            alwaysAdd: true,
                            template: require('../../main/settings/internal-configure/web-tracking/index.html'),
                            controller: 'snippetGenCtrl',
                            orgTypes: fullAndCampaignPlatform,
                            data: {
                                title: 'Digital Attribution',
                                permissionLevel: "bf_admin"
                            },
                            navName: 'Digital Attribution Internal Config',
                        }
                    },
                    {
                        name: 'app.settings.internalConfigure.sfdcConfigure',
                        config: {
                            url: '/sfdc-oauth',
                            alwaysAdd: true,
                            template: require('../../main/settings/internal-configure/sfdc-oauth/index.html'),
                            controller: 'sfdcCtrl',
                            orgTypes: fullAndCampaignPlatform,
                            data: {
                                title: 'SFDC Configuration',
                                permissionLevel: "bf_admin"
                            },
                            navName: 'SFDC Internal Config',
                        }
                    },
                    {
                        name: 'app.settings.internalConfigure.bombora',
                        config: {
                            url: '/bombora',
                            alwaysAdd: true,
                            template: require('../../main/settings/internal-configure/bombora/index.html'),
                            controller: 'bomboraConfigCtrl',
                            orgTypes: fullAndCampaignPlatform,
                            data: {
                                title: 'Bombora Configuration',
                                permissionLevel: "bf_admin"
                            },
                            navName: 'Bombora Internal Config',
                        }
                    },
                    {
                        name: 'app.settings.internalConfigure.tokenManager',
                        config: {
                            url: '/token-manager',
                            alwaysAdd: true,
                            template: require('../../main/settings/internal-configure/token-manager/index.html'),
                            controller: 'tokenManagerConfigCtrl',
                            orgTypes: fullAndCampaignPlatform,
                            data: {
                                title: 'Token Manager',
                                permissionLevel: "bf_admin"
                            },
                            navName: 'Token Manager Internal Config',
                        }
                    }
                ]
            },
            {
                name: 'app.settings.selfConfigure',
                config: {
                    url: '/self-configure',
                    alwaysAdd: false,
                    redirectTo: 'app.settings.selfConfigure.fieldMapping',
                    template: '<div ui-view></div>',
                    orgTypes: allPlatforms,
                    data: {
                        title: 'Configuration',
                    },
                },
                children: [
                    {
                        name: 'app.settings.selfConfigure.fieldMapping',
                        config: {
                            url: '/field-mapping',
                            alwaysAdd: true,
                            template: require('../../main/settings/self-configure/field-mapping/index.html'),
                            controller: 'fieldMappingCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Field Mapping',
                            },
                            navName: 'Field Mapping Self Config',
                        }
                    },
                    {
                        name: 'app.settings.selfConfigure.accountEngagement',
                        config: {
                            url: '/account-engagement',
                            callFn: `goToTerminusHub('feature-settings-migrated', 'app.settings.selfConfigure.accountEngagement')`,
                            alwaysAdd: false,
                            template: require('../../main/settings/self-configure/account-engagement/index.html'),
                            controller: 'accountEngagementCtrl',
                            controllerAs: 'ctrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Account Engagement',
                                permissionLevel: ['org_admin', 'bf_admin'],
                            },
                            navName: 'Account Engagement Self Config',
                        }
                    },
                    {
                        name: 'app.settings.selfConfigure.bombora',
                        config: {
                            url: '/bombora',
                            alwaysAdd: false,
                            template: require('../../main/settings/self-configure/bombora/index.html'),
                            controller: 'bomboraCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Bombora Intent',
                                permissionLevel: ['org_admin', 'bf_admin'],
                            },
                            navName: 'Bombora Intent Self Config',
                        }
                    },
                    {
                        name: 'app.settings.selfConfigure.validityConfig',
                        config: {
                            noUrl: true,
                            alwaysAdd: true,
                            parentIsGrandParent: true,
                            controller: 'validityCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Validity',
                            }
                        },
                        children: [
                            {
                                name: 'app.settings.selfConfigure.campaignValid',
                                config: {
                                    url: '/campaign/validity',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/self-configure/campaign/validity/index.html'),
                                    controller: 'validityCtrl',
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'Campaign',
                                        pageType: 'Campaign',
                                    },
                                    navName: 'Campaign Validity Self Config',
                                }
                            },
                            {
                                name: 'app.settings.selfConfigure.campaignMemberValid',
                                config: {
                                    url: '/campaign_member/validity',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/self-configure/campaign_member/validity/index.html'),
                                    controller: 'validityCtrl',
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'Campaign Member',
                                        pageType: 'CampaignMember',
                                    },
                                    navName: 'Campaign Member Validity Self Config',
                                }
                            },
                            {
                                name: 'app.settings.selfConfigure.contactValid',
                                config: {
                                    url: '/contact/validity',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/self-configure/contact/validity/index.html'),
                                    controller: 'validityCtrl',
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'Contact',
                                        pageType: 'Contact',
                                    },
                                    navName: 'Contact Validity Self Config',
                                }
                            },
                            {
                                name: 'app.settings.selfConfigure.leadValid',
                                config: {
                                    url: '/lead/validity',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/self-configure/lead/validity/index.html'),
                                    controller: 'validityCtrl',
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'Lead',
                                        pageType: 'Lead',
                                    },
                                    navName: 'Lead Validity Self Config',
                                }
                            },
                            {
                                name: 'app.settings.selfConfigure.opportunityValid',
                                config: {
                                    url: '/opportunity/validity',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/self-configure/opportunity/validity/index.html'),
                                    controller: 'validityCtrl',
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'Opportunity',
                                        pageType: 'Opportunity',
                                    },
                                    navName: 'Opportunity Validity Self Config',
                                }
                            }
                        ]
                    },
                    {
                        name: 'app.settings.selfConfigure.integrations',
                        config: {
                            noUrl: true,
                            alwaysAdd: true,
                            parentIsGrandParent: true,
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Integrations',
                            }
                        },
                        children: [
                            {
                                name: 'app.settings.selfConfigure.sfdc',
                                config: {
                                    url: '/integrations/sfdc',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/self-configure/integrations/sfdc/index.html'),
                                    controller: 'sfdcIntegrationCtrl',
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'Salesforce',
                                    },
                                    navName: 'SFDC Integration Self Config',
                                }
                            },
                            {
                                name: 'app.settings.selfConfigure.hubspot',
                                config: {
                                    url: '/integrations/hubspot',
                                    callFn: `goToNativeTerminusHubReport('app.settings.selfConfigure.hubspot')`,
                                    alwaysAdd: true,
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'Hubspot',
                                    },
                                    navName: 'Hubspot Self Config',
                                }
                            },
                            {
                                name: 'app.settings.selfConfigure.eloqua',
                                config: {
                                    url: '/integrations/eloqua',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/self-configure/integrations/eloqua/index.html'),
                                    orgTypes: fullPlatform,
                                    data: {
                                        title: 'Eloqua',
                                    },
                                    navName: 'Eloqua Integration Self Config',
                                }
                            },
                            {
                                name: 'app.settings.selfConfigure.marketo',
                                config: {
                                    url: '/integrations/marketo',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/self-configure/integrations/marketo/index.html'),
                                    controller: 'marketoIntegrationCtrl',
                                    orgTypes: fullPlatform,
                                    data: {
                                        title: 'Marketo',
                                    },
                                    navName: 'Marketo Integration Self Config',
                                }
                            },
                            {
                                name: 'app.settings.selfConfigure.dynamics',
                                config: {
                                    url: '/integrations/microsoft-dynamics',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/self-configure/integrations/dynamics/index.html'),
                                    orgTypes: fullPlatform,
                                    data: {
                                        title: 'Microsoft Dynamics',
                                    },
                                    navName: 'Microsoft Dynamics Integration Self Config',
                                }
                            }
                        ]
                    },
                    {
                        name: 'app.settings.selfConfigure.globalFilters',
                        config: {
                            noUrl: true,
                            alwaysAdd: true,
                            parentIsGrandParent: true,
                            controller: 'globalFilterCtrl',
                            orgTypes: allPlatforms,
                            data: {
                                title: 'Global Filters',
                            },
                            navName: 'Global Filters Self Config',
                        },
                        children: [
                            {
                                name: 'app.settings.selfConfigure.accountFilters',
                                config: {
                                    url: '/account/filters',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/self-configure/account/filters/index.html'),
                                    controller: 'globalFilterCtrl',
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'Account',
                                        pageType: 'Account',
                                    },
                                    navName: 'Account Filters Self Config',
                                }
                            },
                            {
                                name: 'app.settings.selfConfigure.campaignFilters',
                                config: {
                                    url: '/campaign/filters',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/self-configure/campaign/filters/index.html'),
                                    controller: 'globalFilterCtrl',
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'Campaign',
                                        pageType: 'Campaign',
                                    },
                                    navName: 'Campaign Filters Configuration',
                                }
                            },
                            {
                                name: 'app.settings.selfConfigure.campaignMemberFilters',
                                config: {
                                    url: '/campaign_member/filters',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/self-configure/campaign_member/filters/index.html'),
                                    controller: 'globalFilterCtrl',
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'Campaign Member',
                                        pageType: 'CampaignMember',
                                    },
                                    navName: 'Campaign Member Filters Self Config',
                                }
                            },
                            {
                                name: 'app.settings.selfConfigure.leadFilters',
                                config: {
                                    url: '/lead/filters',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/self-configure/lead/filters/index.html'),
                                    controller: 'globalFilterCtrl',
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'Lead',
                                        pageType: 'Lead',
                                    },
                                    navName: 'Lead Filters Self Config',
                                }
                            },
                            {
                                name: 'app.settings.selfConfigure.opportunityFilters',
                                config: {
                                    url: '/opportunity/filters',
                                    alwaysAdd: true,
                                    template: require('../../main/settings/self-configure/opportunity/filters/index.html'),
                                    controller: 'globalFilterCtrl',
                                    orgTypes: allPlatforms,
                                    data: {
                                        title: 'Opportunity',
                                        pageType: 'Opportunity',
                                    },
                                    navName: 'Opportunity Filters Self Config',
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    };


    return {
        getReportTree: function (platform) {
            if (platform === PLATFORM_STANDARD) {
                accounts.config.blind = true;
                opportunities.config.blind = true;
                analyze.children = [accounts, opportunities, analyzeMarketingImpact];
                return [dashboard, analyze, measurementStudio, settings];
            } else if (platform === PLATFORM_CAMPAIGN) {
                accounts.config.blind = true;
                campaigns.config.blind = true;
                opportunities.config.blind = true;
                attributionByCampaignType.config.orgTypes = fullAndCampaignPlatform;
                attributionByCampaignType.name = ANALYZE_REVPIPE_ATTR_BY_QUARTER;
                attributionByCampaignType.config.callFn = `goToTerminusHub('feature-attribution_campaign-migrated', 'app.analyze.campaigns.attributionByQuarter')`;
                campaigns.children = [
                    campaignList,
                    campaignTrends,
                    attributionByCampaignType,
                    historicalAverages,
                    historicalAveragesByLeadGroup,
                ];
                analyze.children = [campaigns, opportunities, analyzeMarketingImpact, accounts];
                return [dashboard, analyze, measurementStudio, settings];
            } else {
                analyze.children = [campaigns, webTracking, opportunities, accounts];
                return [dashboard, discover, analyze, measurementStudio, settings, stageInfluence, segmentBuilder];
            }
        },
        getNavTree: function (platform, isDataStudio) {

            const isStandardOrCampaign = [
                PLATFORM_CAMPAIGN,
                PLATFORM_STANDARD
            ].indexOf(platform) > -1;

            const measurementStudioNavTree = [
                {
                    name: 'scorecard',
                    navName: 'ABM Scorecard',
                    icon: 'fa-fire',
                    config: {
                        alwaysAdd: true,
                        data: {},
                        subnav: true,
                        callFn: `goToNativeTerminusHubReport('app.analyze.accounts.scorecard')`,
                    },
                    children: [
                        {
                            config: {
                                alwaysAdd: true,
                                data: {},
                                subnav: true,
                            },
                            children: [
                                {
                                    config: {
                                        data: {
                                            title: 'Overview',
                                        },
                                        callFn: `goToNativeTerminusHubReport('app.analyze.accounts.scorecard')`,
                                    },
                                    name: APP_ANALYZE_ACCOUNTS_SCORECARD,
                                },
                                {
                                    config: {
                                        data: {
                                            title: 'Trending',
                                        },
                                        callFn: 'goToScorecardTrendingKpi()',
                                    },
                                    name: APP_ANALYZE_ACCOUNTS_SCORECARD_KPI,
                                },
                            ]
                        }
                    ],
                },
                {
                    config: {
                        alwaysAdd: true,
                        data: {},
                        subnav: true,
                        callFn: `getTerminusHubUrlByReportName(${DASHBOARD})`,
                    },
                    name: DASHBOARD,
                    navName: 'Dashboards',
                    icon: '',
                },
                {
                    config: {
                        alwaysAdd: true,
                        data: {},
                        subnav: true,
                    },
                    name: 'reports',
                    navName: 'Reports',
                    icon: 'fa-table',
                    children: [
                        {
                            config: {
                                alwaysAdd: true,
                                data: {
                                    title: 'Standard',
                                },
                                subnav: true,
                            },
                            name: 'standard',
                            navName: 'Standard',
                            subheader: true,
                            children: [
                                {
                                    name: APP_MEASUREMENT_STUDIO_ADVERTISING_INSIGHTS,
                                    config: {
                                        data: {
                                            title: 'Ad Insights',
                                        },
                                        callFn: `goToNativeTerminusHubReport('app.measurementStudio.advertisingInsights')`,
                                    }
                                },
                                {
                                    name: APP_ANALYZE_OPPTY_INSIGHTS,
                                    url: '/analyze/opportunities/list-analysis/all-opptys-touches',
                                    config: {
                                        data: {
                                            title: 'Opportunity Insights',
                                        },
                                        callFn: `goToNativeTerminusHubReport('app.analyze.opportunities.listAnalysis')`,
                                    },
                                },
                            ],
                        },
                        {
                            config: {
                                alwaysAdd: true,
                                data: {
                                    title: 'Revenue Attribution',
                                },
                                subnav: true,
                            },
                            name: 'revenueAttribution',
                            navName: 'Revenue Attribution',
                            subheader: true,
                            children: [
                                {
                                    config: {
                                        data: {
                                            title: 'Marketing Influence',
                                        },
                                        callFn: isStandardOrCampaign ? `goToNativeTerminusHubReport('app.analyze.revenueAndPipeline.marketingImpact')` : `goToNativeTerminusHubReport('app.discover.revenueAndPipeline.marketingImpact')`,
                                    },
                                    name: isStandardOrCampaign ? ANALYZE_MARKETING_IMPACT: DISCOVER_MARKETING_IMPACT,
                                    navName: 'Marketing Influence',
                                    url: `/${isStandardOrCampaign ? 'analyze' : 'discover'}/revenue-and-pipeline/marketing-impact`,
                                },
                                {
                                    config: {
                                        data: {
                                            title: 'Campaign Analytics',
                                        },
                                        callFn: `goToNativeTerminusHubReport('app.analyze.campaigns.listAnalysis.campaignGroups')`,
                                    },
                                    name: ANALYZE_CAMPAIGNS_LIST_ANALYSIS,
                                    navName: 'Campaign Analytics',
                                    url: '/analyze/campaigns/list-analysis',
                                    redirectTo: ANALYZE_CAMPAIGNS_LIST_ANALYSIS_CAMPAIGN_GROUPS
                                },
                                {
                                    config: {
                                        data: {
                                            title: 'Campaign Trends',
                                        },
                                        callFn: `goToNativeTerminusHubReport('app.analyze.campaigns.trendingAnalysis')`,
                                    },
                                    name: ANALYZE_CAMPAIGNS_TRENDING_ANALYSIS,
                                    navName: 'Campaign Trends',
                                    url: '/analyze/campaigns/trending-analysis',
                                },
                                {
                                    config: {
                                        data: {
                                            title: 'Attribution by Campaign Type',
                                        },
                                        callFn: isStandardOrCampaign ? `goToNativeTerminusHubReport('app.analyze.campaigns.attributionByQuarter')` : `goToNativeTerminusHubReport('app.discover.revenueAndPipeline.attributionByQuarter')`,
                                    },
                                    name: isStandardOrCampaign ? ANALYZE_REVPIPE_ATTR_BY_QUARTER: APP_DISC_REVPIP_ATTR_BY_QUARTER,
                                    navName: 'Attribution by Campaign Type',
                                    url: `/${isStandardOrCampaign ? 'analyze/campaign' : 'discover/revenue-and-pipeline'}/attribution-by-quarter`,
                                },
                                stageInfluence,
                                {
                                    config: {
                                        data: {
                                            title: 'Attribution Trends',
                                        },
                                    },
                                    name: APP_DISC_REVPIP_ATTR_TRENDS,
                                    navName: 'Attribution Trends',
                                    url: '/discover/revenue-and-pipeline/attribution-trends',
                                },
                                {
                                    config: {
                                        callFn: `goToNativeTerminusHubReport('app.discover.stageProgression.stagesSnapshot')`,
                                        data: {
                                            title: 'Stages Snapshot',
                                        },
                                    },
                                    name: DISCOVER_STAGES_SNAPSHOT,
                                    navName: 'Stages Snapshot',
                                    url: '/discover/stage-progression/stages-snapshot',
                                },
                                {
                                    config: {
                                        callFn: `goToNativeTerminusHubReport('app.discover.stageProgression.attribution')`,
                                        data: {
                                            title: 'Cohorted Waterfall',
                                        },
                                    },
                                    name: DISCOVER_SP_ATTR,
                                    navName: 'Cohorted Waterfall',
                                    url: '/discover/stage-progression/attribution',
                                },
                                {
                                    config: {
                                        callFn: `goToNativeTerminusHubReport('app.discover.stageProgression.trending')`,
                                        data: {
                                            title: 'Progression by Quarter',
                                        },
                                    },
                                    name: DISCOVER_SP_TRENDING,
                                    navName: 'Progression by Quarter',
                                    url: '/discover/stage-progression/trending',
                                },
                                {
                                    config: {
                                        data: {
                                            title: 'Account Trends',
                                        },
                                    },
                                    name: APP_ANALYZE_ACCOUNTS_TRENDING,
                                    navName: 'Account Trends',
                                    url: '/discover/stage-progression/trending',
                                },
                            ],
                        },
                        {
                            config: {
                                alwaysAdd: true,
                                data: {
                                    title: 'Digital Attribution',
                                },
                                subnav: true,
                            },
                            name: 'digitalAttribution',
                            navName: 'Digital Attribution',
                            subheader: true,
                            children: [
                                {
                                    config: {
                                        data: {
                                            title: 'Web Activities List',
                                        },
                                        callFn: `goToNativeTerminusHubReport('app.analyze.webTracking.webActivity.channel')`,
                                    },
                                    name: APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY,
                                    navName: 'Web Activities List',
                                    url: '/analyze/digital-attribution/web-activity',
                                    redirectTo: APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL,
                                },
                                {
                                    config: {
                                        data: {
                                            title: 'Web Activities Trending',
                                        },
                                        callFn: `goToNativeTerminusHubReport('app.analyze.webTracking.webActivityTrending')`,
                                    },
                                    name: APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_TRENDING,
                                    navName: 'Web Activities Trending',
                                    url: '/analyze/digital-attribution/web-activity-trending',
                                },
                            ],
                        },
                    ],
                },
            ];

            const dataStudioNavTree = [
                {
                    name: 'accountHub',
                    navName: 'Account Hub',
                    icon: 'fa-warehouse-alt',
                    config: {
                        alwaysAdd: true,
                        data: {},
                        subnav: true,
                        url: '/list-analysis',
                        callFn: `getTerminusHubUrlByReportName(${APP_ANALYZE_ACCOUNT_ATTRIBUTION}, 'data')`,
                    },
                    children: [
                        {
                            name: APP_ANALYZE_ACCOUNTS_ACCOUNT_SPECIFIC,
                            config: {

                                data: {
                                    title: 'Account Specific',
                                    filter: allFilters,
                                },
                                subnav: true,
                                url: '/list-analysis/account-specific?accountId&cohort',
                                navName: 'Account Specific',
                            },
                            children: [
                                {
                                    name: APP_ANALYZE_ACCOUNTS_ACCOUNT_SPECIFIC_ATTRIBUTION,
                                    config: {
                                        data: {
                                            title: 'Account Snapshot',
                                            tabText: 'Account Specific',
                                            tabs: true,
                                            question: [],
                                        },
                                        navName: 'Account Specific',
                                        url: '/attribution?startDate&endDate&timeUnit&withSalesActivity',
                                    }
                                },
                                {
                                    name: APP_ANALYZE_ACCOUNTS_ACCOUNT_SPECIFIC_ACCOUNT_JOURNEY,
                                    config: {
                                        data: {
                                            title: 'Account Journey',
                                            tabText: 'Account Insights',
                                            tabs: true,
                                            question: [],
                                        },
                                        navName: 'Account Insights',
                                        url: '/account-journey',
                                    }
                                },
                                {
                                    name: APP_ANALYZE_ACCOUNTS_ACCOUNT_SPECIFIC_TRENDING,
                                    config: {
                                        data: {
                                            title: 'Account Snapshot Trending',
                                            tabText: 'Account Specific Trending',
                                            tabs: true,
                                            question: []
                                        },
                                        navName: 'Account Snapshot Trending',
                                        url: '/trending?metric&startDate&endDate',
                                    }
                                }
                            ]
                        }
                    ],
                },
            ];

            return isDataStudio ?  dataStudioNavTree : measurementStudioNavTree;
        }
    };
}

window.BFStateProvider = BFStateProvider;
