/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* globals Rollbar, Rollbar.warning */
app.controller('newNavCtrl', ['$scope', '$state', '$filter', '$rootScope', '$timeout',
    '$b', 'api', '$cookies', 'utilities', '$localStorage', 'widgets', 'navigation',
    'campaignPerformance', 'authSrvc', 'isFeatureEnabled', 'environmentSrvc',
    'platformBrowserIdentitySrvc', 'splitIoSrvc', 'terminusHubService',
    function ($scope, $state, $filter,
        $rootScope, $timeout, $b, api,
        $cookies, utilities, $storage,
        widgets, navigation, campaignPerformance,
        authSrvc, isFeatureEnabled, environmentSrvc,
        platformBrowserIdentitySrvc, splitIoSrvc, terminusHubService) {

        $scope.appSwitcherConfig = {
            hub: '',
            scorecard: '',
            digitalAds: '',
            emailSignatures: '',
            chat: '',
            web: '',
            platform: '',
        };
        $scope.profileUrl = '';
        $scope.isUnifiedLoginEnabled;
        $scope.helpLink = `https://support.terminus.com/`;

        const STUDIO_STATE_DATA_STUDIO = 'DATA_STUDIO';
        const STUDIO_STATE_MEASUREMENT_STUDIO = 'MEASUREMENT_STUDIO';

        $scope.navItemConsts = {
            scorecard: 'scorecard',
            reports: 'reports',
            accountHub: 'accountHub',
            revenueAttribution: 'revenueAttribution',
            digitalAttribution: 'digitalAttribution',
            revAttrLink: 'https://support.terminus.com/hc/en-us/articles/360053841254-Revenue-Attribution-Reporting-in-Measurement-Studio',
            digAttrLink: 'https://support.terminus.com/hc/en-us/articles/360053841314-Digital-Attribution-Reporting-in-Measurement-Studio',
        };

        $scope.canAccessEngageMenu = function () {
            var platform = $rootScope.userData['platform'];
            var hasTerminusData = $rootScope.orgConfig['terminus_data'];
            var canAccess = false;
            var rollbar = window['brightfunnel']['rollbar'];

            if (platform === 'full') {
                if (hasTerminusData) {
                    canAccess = true;
                }
            } else if (platform === 'campaign' || platform === 'standard') {
                canAccess = true;
            } else {
                // Report this to RollBar so we can investigate
                var userId = $rootScope && $rootScope.userData && $rootScope.userData['id'];
                rollbar.warning('User Id: ' + (userId || 0) + ' has no platform type!');
            }

            return canAccess;
        };

        $scope.isActive = function (child) {
            return $rootScope.$state.current.name.indexOf(child) > -1;
        };

        $scope.isActiveIcon = function (stateName) {
            const currentState = $state.current;
            return $state.includes(stateName)
                || isDataStudioNavItemActive(stateName, currentState)
                || isMeasureStudioNavItemActive(stateName, currentState)
            ;
        };

        $scope.appSwitcherTitle = generateAppSwitcherTitle();

        function generateAppSwitcherTitle () {
            const currentState = $state.current;
            return utilities.isDataStudioReport(currentState.name)
                    || (
                        utilities.isSettingsPage(currentState.name)
                        && $rootScope.studioState === STUDIO_STATE_DATA_STUDIO
                    )
                ? 'Data Studio'
                : 'Measurement Studio';

        }

        function isDataStudioNavItemActive (stateName, currentState) {
            return (
                utilities.isDataStudioReport(currentState.name)
                && stateName === 'accountHub'
            );
        }

        function isMeasureStudioNavItemActive(stateName, currentState) {
            return (
                utilities.isMeasurementStudioReport(currentState.name)
                && stateName === 'reports'
            );
        }

        function rerenderSubNav(isDataStudio) {
            const bfStateProvider = new BFStateProvider();
            const userData = $rootScope.userData;
            const navState = bfStateProvider.getNavTree($rootScope.userData.platform, isDataStudio);

            $rootScope.subnav = utilities.filterSubNav(userData, navState);
        }

        var hashCode = function (str) {
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            return hash;
        };

        function intToRGB(i) {
            var c = (i & 0x00FFFFFF)
                .toString(16)
                .toUpperCase();

            return "00000".substring(0, 6 - c.length) + c;
        }

        $scope.generateColor = function () {
            return '#' + intToRGB(hashCode($rootScope.userData.orgName));
        };

        $scope.setNavPermission = navigation.setNavPermission;

        function getUser() {
            return _.first(_.upperFirst($rootScope.userData.orgName));
        }

        if (!$storage.history) {
            $storage.history = {
                version: $rootScope.version
            };
        }

        if (!$storage.history[$scope.userData.organizationId]) {
            $storage.history[$scope.userData.organizationId] = {};
        }

        $scope.extractName = navigation.extractName;

        $scope.search = function (state) {
            $rootScope.$broadcast('toggle-search', state);
        };

        $scope.findBucket = function (state) {
            return $state.current.name.indexOf(state.name) > -1;
        };

        $scope.target = function (state) {
            var name = state.name;
            if (state.tabs) {
                var defaultTab = angular.findWhere(state.tabs, function (t) {
                    return t.config.defaultTab && t.config.defaultTab === true;
                });
                name = defaultTab ? defaultTab.name : state.tabs[0].name;
            }
            return name;
        };

        $scope.className = navigation.className;

        $scope.tabIconClass = function (tabName) {
            if (tabName === "Trending") {
                return "benchmarking";
            } else {
                return tabName.toLowerCase();
            }
        };

        $scope.widgets = widgets;

        $scope.addTile = function (id) {
            widgets.addTile(id);
        };

        $scope.ago = function () {
            return moment(new Date($rootScope.last_updated_date.last_update)).fromNow();
        };

        $scope.dateComplex = function () {
            return moment(new Date($rootScope.last_updated_date.last_update)).format("dddd @ h:mm a");
        };

        $scope.newDashboard = function ($event) {
            $event.stopPropagation();
            $event.preventDefault();
            widgets.newDashboard();
        };

        // $rootScope.$on('$$rebind::dashboardUpdated', function() {
        //     $scope.dashboards = widgets.dashboards();
        // });


        $scope.getDashboard = function () {
            $scope.dashboards = widgets.dashboards();
        };

        $scope.getDashboardLink = function (id) {
            const state = 'app.dashboard';
            const params = `/${id}`;
            return terminusHubService.getTerminusHubUrlByReportName(state) + params;

        };

        $scope.dragControlListeners = {
            orderChanged: function (event) {
                widgets.changeDashboardOrder();
                $rootScope.$broadcast('$$rebind::dashboardUpdated');
            }
        };

        $scope.removeDisabled = navigation.removeDisabled;

        $scope.renderLink = navigation.renderLink;

        $scope.allowed = function (child, permissions, platform) {
            if (!child.config.blind && child.status !== "removed" && child.config.orgTypes.includes(platform)) {
                if (child.config.data.permissionLevel) {
                    return permissions[child.config.data.permissionLevel];
                } else {
                    return true;
                }
            } else {
                return false;
            }
        };

        $scope.active = navigation.active;

        $scope.activeDash = function (child) {
            if ($state.current.name === 'app.dashboard') {
                if ($state.params.id === child.id) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        };

        $scope.logout = function () {
            authSrvc.logout();
        };

        $scope.upgrade = function (state) {
            if (state.data.upgradeMessage) {
                $b.alert(state.data.upgradeMessage, state.data.title);
            }
            else {
                $b.alert('<i class="icon-ban space-right"></i>Would you like to upgrade to see ' + state.data.title + '?  Email <a href="mailto:support@terminus.com">support@terminus.com</a> and let\'s talk about it.');
            }
        };

        $scope.notAllowed = function () {
            $b.alert('<i class="icon-ban space-right"></i> You do not have permission to view this page');
        };

        $scope.getNavName = function (c) {
            if (c.name === 'app.analyze.webTracking.channelAssetSpecific.attribution') {
                c.config.navName = campaignPerformance.tabMappings('channel_name') + ' Specific';
                return c.config.navName;
            } else if (c.config.navName) {
                return c.config.navName;
            } else {
                return c.config.data.title;
            }
        };

        $scope.appSwitcherNavigate = function (url) {
            authSrvc.redirect(url);
        };

        $scope.checkFeatureStatus = function (c) {
            if (
                $scope.platform !== 'campaign'
                || !c.config
                || !c.config.data
                || !c.config.data.featureFlag
            ) {
                return true;
            }

            return isFeatureEnabled(c.config.data.featureFlag);
        };

        function setUrls() {
            const dataStudioUrl = 'DATA_STUDIO_URL';

            environmentSrvc.getVariable(dataStudioUrl).then(
                (hub) => $scope.appSwitcherConfig.hub = hub
            );
            environmentSrvc.getVariable('APP_SWITCHER_SCORECARD_URL').then(
                (scorecard) => $scope.appSwitcherConfig.scorecard = scorecard
            );
            environmentSrvc.getVariable('APP_SWITCHER_DIGITAL_ADS_URL').then(
                (digitalAds) => $scope.appSwitcherConfig.digitalAds = digitalAds
            );
            environmentSrvc.getVariable('APP_SWITCHER_EMAIL_SIGNATURES_URL').then(
                (emailSignatures) => $scope.appSwitcherConfig.emailSignatures = emailSignatures
            );
            environmentSrvc.getVariable('APP_SWITCHER_CHAT_URL').then(
                (chat) => $scope.appSwitcherConfig.chat = chat
            );
            environmentSrvc.getVariable('APP_SWITCHER_WEB_URL').then(
                (web) => $scope.appSwitcherConfig.web = web
            );
            environmentSrvc.getVariable('UNIFIED_LOGIN_URL').then(
                (unifiedLoginUrl) => {
                    $scope.appSwitcherConfig.platform = unifiedLoginUrl;
                    $scope.profileUrl = `${ unifiedLoginUrl }/profile`;
                });
        }


        (function init() {
            setUrls();
            platformBrowserIdentitySrvc.getIdentity().then((identityObj) => {
                splitIoSrvc.loadSDK(identityObj.identifier).then(() => {
                    splitIoSrvc.getTreatment('unified_login').then((result) => {
                        $scope.isUnifiedLoginEnabled = result;
                    });

                    splitIoSrvc.getTreatment('zd_sso').then((result) => {
                        $scope.helpLink = result ? `${$scope.appSwitcherConfig.platform}/helpcenter` : `https://support.terminus.com/`;
                    });
                });
            });

            $scope.init = false;
            $scope.campaignPerformance = campaignPerformance;
            $scope.data = {
                user: getUser(),
                userDetail: false
            };
            $scope.platform = $rootScope.userData.platform;
            $scope.cookies = $cookies;
            $scope.fromJson = angular.fromJson;
            $scope.widgets = widgets;
            $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                if (toState.status === 'disabled') {
                    event.preventDefault();
                    $scope.upgrade(toState);
                }
                if (toState.status === 'url') {
                    event.preventDefault();
                    $scope.notAllowed();
                }

                if (toState.redirectTo) {
                    event.preventDefault();
                    $timeout(function () {
                        $state.go(toState.redirectTo, toParams, { location: 'replace' });
                    }, 0, false);
                }
            });

            widgets.getDashboards().then(function (data) {
                $scope.dashboards = widgets.dashboards();
                $rootScope.$broadcast('$$rebind::dashboardUpdated');
            });

            $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                var selected = angular.findWhere($scope.subnav, function (state) {
                    return state.config && state.config.data && state.config.data.open;
                });

                if (fromState && fromState.data && fromState.data.erasePrevious) {
                    delete fromState.data.erasePrevious;
                }

                if (selected && $state.is('app.dashboard')) {
                    selected.config.data.open = false;
                    $state.current.data.open = true;
                } else if (selected && $state.includes(selected.name)) {
                    selected.config.data.open = true;
                } else {
                    var target = angular.findWhere($scope.subnav, function (state) {
                        return state.config.abstract && toState.name.indexOf(state.name) !== -1;
                    });

                    if (target) {
                        if (!target.config.data)
                            target.config.data = {};

                        if (!$scope.init && $scope.ipad || !$scope.init && $rootScope.mobile) {
                            target.config.data.open = false;
                        } else {
                            target.config.data.open = true;
                        }
                    } else if ($state.current.name === 'app.dashboard') {
                        $state.current.data.open = true;
                    }
                }

                const currentState = $state.current;

                const isDataStudio = utilities.isDataStudioReport(currentState.name);
                const isSettingsPage = utilities.isSettingsPage(currentState.name);
                if (
                    !isSettingsPage
                    && (
                        ($rootScope.studioState === STUDIO_STATE_DATA_STUDIO && !isDataStudio)
                        || ($rootScope.studioState == STUDIO_STATE_MEASUREMENT_STUDIO && isDataStudio)
                    )
                ) {
                    rerenderSubNav(isDataStudio);
                    $rootScope.studioState = isDataStudio ? STUDIO_STATE_DATA_STUDIO : STUDIO_STATE_MEASUREMENT_STUDIO;
                    $scope.appSwitcherTitle = generateAppSwitcherTitle();
                }

                $rootScope.$broadcast('$$rebind::stateChanged');

                $scope.init = true;
            });
        })();
    }]);


app.controller('tabCtrl', ['$scope', '_', '$state', 'campaignPerformance', function ($scope, _, $state, campaignPerformance) {
    $scope.hideTab = function (tab) {
        //return false to hide tab
        return (tab && !(tab.name === 'app.analyze.campaigns.campaignSpecific.sfdc' && $scope.hide));
    };

    $scope.getTabName = function (tab) {
        if (tab.data.tabText === 'UTM Campaign Specific Trending') {
            tab.data.tabText = campaignPerformance.tabMappings('channel_name') + ' Trending';
        }
        if (tab.data.tabText === 'UTM Campaign Specific') {
            tab.data.tabText = campaignPerformance.tabMappings('channel_name') + ' Specific';
        }
        return tab.data.tabText;
    };

    $scope.tabIconClass = function (tabName) {
        if (tabName === "Trending") {
            return "benchmarking";
        } else {
            return tabName.toLowerCase();
        }
    };
    $scope.$on('hideSFCTab', function () {
        $scope.hide = true;
    });

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        if ($state.current.data.tabSet) {
            _.forEach($state.current.data.tabSet, function (tab) {
                $scope.setUrl((_.isActualObject(tab) ? $state.get(tab.name) : $state.get(tab)));
            });
        }
    });
}]);
