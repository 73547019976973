app.controller('marketingImpactWidgetCtrl', [
    '$scope', 'api', 'utilities', '$filter', '_', '$timeout', 'userModels',
    'influenceTypes', 'widgets', '$rootScope', '$compile', '$templateCache', 'personaDefaults', '$stateParams',
    function ($scope, api, utilities, $filter, _, $timeout, userModels, influenceTypes, widgets,
        $rootScope, $compile, $templateCache, personaDefaults, $stateParams) {
        const $tile = $scope.$tile;
        let marketingImpactCall;

        this.getData = function () {
            if (marketingImpactCall) { marketingImpactCall.abort(); }

            if (!$scope.widget.query) {
                $tile.endLoading();
                return;
            }

            const miParams = angular.copy($scope.widget.query);
            if (miParams.opptyType === 'oppty') {
                miParams.influenceType = 'pre';
            }
            (marketingImpactCall = api.getter({
                url: 'api/v1/marketing_impact',
                params: miParams,
                paramsNotToUpdate: 'all',
                skipFilters: true,
            })).then(function (data) {
                $tile.loading(30);
                $scope.data.total_revenue = { actual: {} };
                $scope.data.total_revenue.actual = data.data;
                $scope.data.total_revenue.actual = $scope.data.total_revenue.actual.map(function (r) {
                    r.visible = true;
                    return r;
                });
                if ($scope.data.total_revenue.actual.length > 1) {
                    $scope.data.last_row = $scope.data.total_revenue.actual[$scope.data.total_revenue.actual.length - 2];
                }
                $timeout(drawTotalRevenueChart, 500, false);
                $scope.widget.data.lastQuery = angular.copy($scope.widget.query);
                $tile.endLoading();
                $scope.$emit("widgetLoadingDone");
            }, function (data) {
                if (!data.aborted) {
                    $tile.failedLoading();
                }
            });
        };

        $scope.formatChartData = function (data) {
            let aRows = $filter('filter')(data, { visible: true });
            let chart_data = [];
            let type = $scope.widget.query.opptyType;
            let firstNonZeroIndex;
            let lastNonZeroIndex;
            let filteredStartZeroBars;
            let chartLength;
            let chartBars = [];
            let bars;
            let chartData;
            let graphLabels = {
                oppty: 'Pipeline Created',
                deal: 'Revenue'
            };

            for (let i = 0; i < aRows.length; i++) {
                chart_data[i] = [
                    aRows[i].month,
                    0,
                    aRows[i][type] ? aRows[i][type].value : 0,
                    aRows[i]['marketing_' + type] ? aRows[i]['marketing_' + type].value : 0
                ];
            }

            $scope.chart.dimensions = {
                Month: {
                    axis: 'x'
                },
                [graphLabels[type]]: {
                    type: 'bar'
                },
                "Marketing Influenced": {
                    type: 'bar'
                }
            };

            $scope.data.defaultTitleData = type;

            firstNonZeroIndex = _.findIndex(chart_data, function (value) {
                return value[2] > 0 || value[3] > 0;
            });

            lastNonZeroIndex = chart_data.length;
            //start here to get data
            filteredStartZeroBars = chart_data.slice(firstNonZeroIndex, lastNonZeroIndex);

            // show correct bars/ 9 for years and 3 for quarters
            for (let j = 0; j < filteredStartZeroBars.length; j++) {
                filteredStartZeroBars[j].splice(1, 1);
            }
            angular.forEach(filteredStartZeroBars, function (value) {
                bars = _.zipObject(['Month', graphLabels[type], 'Marketing Influenced'], value);
                chartBars.push(bars);
            });

            chartLength = chartBars.length;

            if (chartLength !== 0) {
                let plotPoints = $scope.widget.query.plotPoints;
                chartData = chartBars.slice(chartLength - plotPoints);
            } else {
                chartData = [];
            }


            return chartData;
        };

        function drawTotalRevenueChart() {
        /* had to extract function into a scope function because we currently cannot test functions that are not in scope */
            $scope.chart.data = $scope.formatChartData($scope.data.total_revenue.actual);
            $scope.chart.query = $scope.widget.query;

            let opptyType = $scope.chart.query.opptyType;
            let graphLabels = {
                oppty: 'Pipeline Created',
                deal: 'Revenue'
            };
            let blue = '#005589';
            let orange = '#D88219';
            let oliveGreen = '#97CD78';
            let chartColors = opptyType === 'deal' ? [oliveGreen, blue] : [orange, blue];

            $scope.chart.chart.color = { pattern: chartColors };

            //tooltip
            $scope.chart.chart.tooltip = {
                contents: function (d) {
                    $scope.data.tScope.title = $scope.chart.data[d[0].index].Month;
                    //show % marketing influence on marketing impact tile
                    if (d[1].value !== 0 && d[0].value !== 0) {
                        d.push({
                            id: '% Influenced',
                            index: 3,
                            name: '% Marketing Influenced',
                            value: d[0].value === 0 ? 0 : $filter('metafilter')((d[1].value / d[0].value), 'percentage:0'),
                        });
                    }

                    $scope.data.tScope.rows = d.map(function (r) {
                        return {
                            label: (r.name === 'Revenue' || r.name === 'Pipeline Created') ? graphLabels[opptyType] : r.name,
                            value: r.name !== '% Marketing Influenced' ? $filter('nrFormat')(r.value, $scope.currencySymbol, 0) : r.value,
                            color: (r.name === 'Revenue' || r.name === 'Pipeline Created') ? chartColors[0] : (r.name === 'Marketing Influenced') ? chartColors[1] : ''
                        };
                    });
                    return $scope.data.tScope.$apply(function () { return $scope.data.tooltip[0].outerHTML; });
                }
            };

            $scope.chart.chart.axis.y.label = {
                text: opptyType === 'deal' ? 'Total Revenue' : 'Total Pipeline',
                position: 'outer-middle',
            };

            $scope.data.tooltip = $compile($templateCache.get('chartTooltip.html'))($scope.data.tScope);

            $tile.loading(100);
        }

        (function init(ctrl) {
            $tile.startLoading();
            $tile.loading(10);
            $scope.platform = $rootScope.userData.platform;
            $scope.utils = utilities;
            $scope.currencySymbol = utilities.currencySymbol($scope.orgConfig.groupings.currency);
            $scope.chart = {
                chart: {
                    data: {
                        selection: {
                            enabled: true
                        },
                    },
                    zoom: {
                        enabled: false
                    },
                    axis: {
                        y: {
                            tick: {
                                format: function (d) {
                                    return $filter('nrFormat')(d, true);
                                }
                            }
                        }
                    },
                    padding: {
                        bottom: 15
                    }
                },
            };
            //------------------------ Not Saved ------------------------//
            $scope.data = {
                models: userModels,
                tableData: [],
                accountFilter: false,
                total_revenue: {},
                influence: influenceTypes.attributionTypeMapping,
            };

            $scope.data.tScope = $scope.$new();

            //------------------------ Saved ------------------------//
            $scope.widget.data = _.defaults($scope.widget.data, {
                model: 'even'
            });

            const params = _.defaults($scope.widget.query, personaDefaults.getQueryDefinitions($scope.widget).query);
            $scope.widget.query = widgets.addFilters(params, $scope.widget.data.filters, $scope.widget.data.accountLists);

            if (!_.isDefined(_.find($scope.data.models, { key: $scope.widget.query.modelType }))) {
                $scope.widget.query.modelType = 'even';
            }

            if (!_.isDefined(_.find($scope.data.influence, { key: $scope.widget.query.influenceType }))) {
                $scope.widget.query.influenceType = 'any';
            }

            // NOTE: Update query object with data, which was in query params
            // (terminus-hub Add to Dashboard functionality)
            if ($stateParams.routeId
                && ($stateParams.routeId === 'app.discover.revenueAndPipeline.marketingImpact'
                || $stateParams.routeId === 'app.analyze.revenueAndPipeline.marketingImpact')) {
                Object.keys($scope.widget.query).forEach(key => {
                    // update only properties, which are related to marketing influence data
                    if ($scope.widget.query.hasOwnProperty(key)
                        && $stateParams.hasOwnProperty(key)
                        && $stateParams[key]) {
                        $scope.widget.query[key] = $stateParams[key];
                    }
                });
                // check influenceType separately because it could be absent
                if ($stateParams.influenceType) {
                    $scope.widget.query.influenceType = $stateParams.influenceType;
                }
            }

            $scope.$on('angular-chart-rendered', function (event, options, instance) {
                $scope.data.chartResizer = instance;
                widgets.resizeChart(instance, $scope);
            });

            $scope.$on('gridster-item-resize', function () {
                $timeout(function () {
                    widgets.resizeChart($scope.data.chartResizer);
                }, 500, false);
            });

            $scope.$on('$windowResizeEnd', function () {
                $timeout(function () {
                    widgets.resizeChart($scope.data.chartResizer, $scope);
                }, 10, false);
            });

            $tile.listeners.push($scope.$on('widgetUpdate', function () {
                if (!angular.equals(angular.copy($scope.widget.query), $scope.widget.data.lastQuery)) {
                    $tile.startLoading();
                    $tile.loading(10);
                    ctrl.getData();
                }
            }));

            $scope.$on('$destroy', function () {
                if (marketingImpactCall) { marketingImpactCall.abort(); }
                $scope.data.tScope.$destroy();
            });
        })(this);
    }]);
