
(function () {
    'use strict';

    app.factory('sigstrSrvc', sigstrSrvc);
    sigstrSrvc.$inject = ['$http', 'environmentSrvc'];

    function sigstrSrvc($http, environmentSrvc) {

        const SIGSTR_ENDPOINT = '/v1/load_sigstr_product_type';
        const GRAILS_URL = "GRAILS_URL";
        const service = {};
        let productType = null;

        service.initialize = function () {
            return environmentSrvc.getVariable(GRAILS_URL).then(function(host) {
                return $http.post(host + SIGSTR_ENDPOINT,
                    JSON.stringify({}),
                    { withCredentials: false }).then((resp) => {
                    productType = resp.data.sigstrProductType;
                    return productType;
                }, () => {
                    // Resolve the promise in case of error and hide the error
                    productType = null;
                    return productType;
                });
            }, function(error) {
                throw error;
            });
        };

        service.getProductType = function () {
            return productType;
        };
        service.isEnabled = function () {
            return service.getProductType() === "relationship";
        };

        return service;
    }
})();
