/* eslint-disable no-unused-vars */
var coreServices = angular.module('core-services', []);

coreServices.service('$b', ['$rootScope', '$compile', '$http', '$templateCache', '$controller', '$timeout', '$q', function ($rootScope, $compile, $http, $templateCache, $controller, $timeout, $q) {
    var modals = [],
        template = '<div id="modal_box">' +
            '<div id="modal_box_inner" loading="$modal.loading" ng-mousedown="$modal.backgroundDown($event)" ng-mouseup="$modal.backgroundUp($event)" ng-class="{\'fade-out\':$modal.backdrop != false}" class="fade-out">' +
            '<div id="modal" class="animated" ng-class="[$modal.id,$modal.animate]" ng-mousedown="$modal.modalDown($event)" ng-style="{width:$modal.width}">' +
            '<a ng-if="$modal.x != false" ng-click="$modal.closeModal()" class="close">' +
            '<i class="icon-times icon-inherit"></i>' +
            '</a>' +
            '<div id="modal-header">' +
            '<h3>{{$modal.title}}</h3>' +
            '</div>' +
            '<div id="modal-body-wrapper" ng-style="{maxHeight:$modal.maxHeight}">' +
            '<div id="modal-body-parent">' +
            '<div id="modal-body"></div>' +
            '</div>' +
            '</div>' +
            '<div ng-if="$modal.buttons" id="modal-footer"></div>' +
            '</div>' +
            '</div>' +
            '</div>';

    this.alert = function (text, title) {
        'use strict';

        if ($('.alert-action').length)
            return;

        text = text || 'Alert';

        this.modal({
            template: text,
            title: title || 'Alert',
            id: 'alert-action BrightFunnel',
            width: '300px',
            buttons: [{
                'text': 'ok',
                'ng-click': '$modal.closeModal()',
                'class': 'btn btn-sm btn-primary pull-right'
            }]
        });
    };

    this.confirm = function (config) {
        var text = config.text || 'Are you sure you want to do this?',
            defaults = {
                // the content
                template: text,
                // the title of the modal
                title: 'Confirm',
                // the modal id for css
                id: 'confirm-action BrightFunnel',
                // the width you want (you can leave this blank)
                width: '300px',
                backdrop: false,
                // the html you'd like for the buttons (you can leave this blank)
                buttons: [{
                    'text': 'ok',
                    'ng-click': '$modal.callback($modal)',
                    'class': 'btn btn-sm btn-primary pull-right'
                }, {
                    'text': 'cancel',
                    'ng-click': '$modal.reject($modal)'
                }],
                controller: function ($scope, hotkeys) {
                    hotkeys.bindTo($scope)
                        .add({
                            combo: 'return',
                            description: 'Toggle the global search.',
                            allowIn: ['INPUT'],
                            callback: function () {
                                $scope.$modal.callback($scope.$modal);
                            }
                        });
                }
            };

        angular.extend(defaults, config);

        defaults.callback = function (modal) {
            config.callback(modal);
            modal.closeModal();
        };

        defaults.reject = function (modal) {
            if (config.onReject) {
                config.onReject(modal);
            } else {
                modal.closeModal();
            }
        };

        this.modal(defaults);
    };

    //custom modal
    this.modal = function (config) {

        /*====================================
        =            set up scope            =
        ====================================*/

        var scope = $rootScope.$new();

        scope.$modal = {
            template: 'Add Content',
            templateUrl: null,
            title: 'Warning!',
            id: 'brightfunnel-modal',
            width: '400px',
            buttons: null,
            backdrop: true,
            x: true,
            animate: 'bounceInDown'
        };

        angular.extend(scope.$modal, config);

        if (scope.$modal.controller)
            $controller(scope.$modal.controller, { '$scope': scope });

        scope.$modal.$elem = $(angular.copy(template));
        scope.$modal.$elem.attr('scope-id', scope.$id);

        modals.push(scope);

        /*-----  End of set up scope  ------*/

        /*===================================
        =            get content            =
        ===================================*/

        if (scope.$modal.templateUrl) {
            if ($templateCache.get(scope.$modal.templateUrl)) {
                scope.$modal.template = angular.element($templateCache.get(scope.$modal.templateUrl));
                build();
            } else {
                $http.get(scope.$modal.templateUrl).success(function (data, status, headers, config) {
                    scope.$modal.template = $(data);
                    build();
                }).error(function (data, status, headers, config) {
                    alert('there was an issue loading your modal template.');
                    return;
                });
            }
        } else {
            build();
        }

        /*-----  End of get content  ------*/


        function build() {
            // Append Content
            scope.$modal.$elem.find('#modal-body').append(scope.$modal.template);

            angular.forEach(scope.$modal.buttons, function (value, i) {
                let button = null;

                if (value['btn-group']) {
                    button = angular.element('<btn-dropdown></btn-dropdown>');
                    button.attr('class', 'pull-right');
                    for (let attr in value['btn-group']) {
                        button.attr(attr, `'${value['btn-group'][attr]}'`);
                    }
                    if (value['btn-menu']) {
                        button.attr('btn-menu', `'${JSON.stringify(value['btn-menu'])}'`);
                    }
                } else {
                    const attrs = {
                        'class': 'btn btn-sm pull-right'
                    };

                    angular.extend(attrs, value);
                    button = angular.element('<button></button>', attrs);
                }
                scope.$modal.$elem.find('#modal-footer').append(button);
            });

            $compile(scope.$modal.$elem)(scope);

            $('body').append(scope.$modal.$elem);

            $timeout(function () {
                scope.$modal.animate = null;
            }, 800, false);

            /*==============================
            =            events            =
            ==============================*/

            $("body").keyup(esc);

            // Set Body max height
            function modalSize() {
                scope.$apply(function () {
                    scope.$modal.maxHeight = $(window).height() - 150;
                });
            }

            function esc(e) {
                if (e.keyCode === 27 && $('div#modal').is(':visible')) {
                    scope.$modal.closeModal();
                }
            }

            $timeout(function () {
                modalSize();
            }, 0, false);

            scope.$modal.closeModal = function () {
                var defer = $q.defer();
                if (!scope.$modal.loading) {
                    if (scope.$modal.beforeClose) {
                        $q.when(scope.$modal.beforeClose()).then(function (d) {
                            if (d !== false) {
                                defer.resolve();
                                scope.$modal.removeModal();
                            }
                        }).catch(function () {
                            defer.reject();
                            return;
                        });
                    } else {
                        defer.resolve();
                        scope.$modal.removeModal();
                    }
                }
                return defer.promise;
            };

            scope.$modal.removeModal = function () {
                scope.$modal.animate = 'bounceOutUp';
                $timeout(function () {
                    scope.$destroy();
                }, 750, false);
            };

            scope.$on('$windowResize', modalSize);

            scope.$on('$removeModal', function () {
                scope.$modal.closeModal();
            });

            scope.$modal.modalDown = function (event) {
                event.stopPropagation();
                scope.$modal.onModal = true;
            };

            scope.$modal.backgroundDown = function (event) {
                event.stopPropagation();
                scope.$modal.onModal = false;
            };

            scope.$modal.backgroundUp = function (event) {
                event.preventDefault();

                if (scope.$modal.onModal !== true && scope.$modal.backdrop !== false)
                    scope.$modal.closeModal();
            };

            /*-----  End of events  ------*/


            /*===============================
            =            cleanup            =
            ===============================*/

            scope.$on('$destroy', function () {
                modals.splice(modals.indexOf(scope), 1);
                scope.$modal.$elem.remove();
                $('body').unbind('keyup', esc);
            });

            /*-----  End of cleanup  ------*/
        }
    };
}]);
