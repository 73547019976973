/**
 * Controller for the list directive.
 */
(function () {
    'use strict';

    angular
        .module('bombora')
        .directive('headerList', function () {
            return {
                template: require('./list.html'),
                controller: listCtrl,
                controllerAs: "$ctrl",
                bindToController: true,
                scope: {
                    items: '=',
                    itemClick: '&',
                    symbol: '@',
                    lazyLoad: '&'
                },
                link: listLnk
            };
        });

    listCtrl.$inject = ['lazyLoadSrvc'];

    /**
     * Controller for the directive.
     */
    function listCtrl(lazyLoadSrvc) {
        this.loading = false;
        this.lazyLoadSrvc = lazyLoadSrvc;
    }

    /**
     * Link function for the directive
     * @param scope
     * @param element
     * @param attrs
     */
    function listLnk(scope, element, attrs, ctrl) {
        var list = element[0].querySelector('.list-wrapper');
        var lazyLoadProperties = {
            loading: ctrl.loading,
            endOfList: false,
            scope: scope,
            params: {}
        };

        list.addEventListener('scroll', function (event) {
            lazyLoadProperties = ctrl.lazyLoadSrvc.lazyList(ctrl.lazyLoad, event, lazyLoadProperties);
        });
    }
})();
