(function () {
    'use strict';

    app.directive('configToggle', function () {
        return {
            restrict: 'E',
            template: require('./config-toggle.html'),
            controller: toggleCtrl,
            controllerAs: "$ctrl",
            bindToController: true,
            scope: {
                switchLabel: '=',
                toggleModel: '=',
                onChange: '&',
                params: '=',
                disabled: '=',
            },
        };
    });

    toggleCtrl.$inject = ['$scope'];

    /**
     * Controller for the directive.
     */
    function toggleCtrl($scope) {
        let ctrl = this;
        $scope.switch = ctrl.toggleModel;

        ctrl.change = function () {
            ctrl.toggleModel = !ctrl.toggleModel;
            ctrl.onChange()(ctrl.params, ctrl.toggleModel);
        };
        $scope.$watch('$ctrl.toggleModel', function (value) {
            if (value) {
                ctrl.toggleModel = value;
                $scope.switch = ctrl.toggleModel;
            }
        });
    }

})();
