/**
 * Switch User/God Mode Function.
 */
(function () {
    'use strict';

    app.$inject = ['$uibModal', '$rootScope', '$interval', '$window',
        'noty', 'SwitchUserConstants', 'switchUserSrvc'];

    /**
     * Switch User Function.
     * @param $uibModal
     * @param noty
     * @param SwitchUserConstants
     */
    function switchUserFunc($uibModal, $rootScope, $interval, $window,
        noty, SwitchUserConstants, switchUserSrvc) {
        var settingsElem;
        var interval;

        /**
         * Executes when the god mode modal is closed. If params is not null, pass the object
         * into the switchUserSrvc and reload the page if success.
         * @param params {{ username: <string> >} | null } The result of the modal window.
         */
        function closed(params) {
            if (params) {
                noty.growl(SwitchUserConstants.LABELS.LOGIN_AS.IN_PROGRESS, 'success');
                switchUserSrvc.loginAs(params).then(function (response) {
                    if (response.data.error) {
                        noty.growl(response.data.error, 'error');
                    } else {
                        noty.growl(SwitchUserConstants.LABELS.LOGIN_AS.OK, 'success');
                        switchUserSrvc.reloadAsUser(response.data, true);
                    }
                }, function () {
                    noty.growl(SwitchUserConstants.LABELS.LOGIN_AS.ERROR, 'error');
                });
            } else {
                noty.growl(SwitchUserConstants.LABELS.LOGIN_AS.CANCEL, 'success');
            }
        }

        /**
         * Exposed into rootScope to be called in the User menu.
         * Instead of a page this function is executed.
         */
        $rootScope.renderPopUp4GodMode = function () {
            if (settingsElem) {
                var modalInstance = $uibModal.open({
                    animation: false,
                    template: require('./index.html'),
                    controller: "switchUserModalCtrl",
                    size: 'sm',
                    backdrop: false,
                    windowTopClass: 'backdrop',
                    appendTo: angular.element(settingsElem)
                });

                modalInstance.result.then(closed);
            }
        };

        /**
         * Async method to get the '#user' HTMLElement.
         */
        function getParentElement() {
            settingsElem = document.querySelector('#user');
            if (settingsElem && interval) {
                $interval.cancel(interval);
            }
        }

        /**
         * Speeds up searching for the '#user' object.
         */
        function activate() {
            interval = $interval(getParentElement, 5000, 20);
        }

        activate();
    }

    app.run(switchUserFunc);
})();
