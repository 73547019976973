(function () {
    'use strict';

    app.directive('multiSearch', function () {
        return {
            restrict: 'E',
            template: require('./multisearch.html'),
            controller: multisearchCtrl,
            controllerAs: "$ctrl",
            bindToController: true,
            scope: {
                ngModel: "=",
                ngChange: "&",
                listValues: "=",
                placeholder: "=",
                params: "="
            },
            transclude: true
        };
    });

    multisearchCtrl.$inject = [];

    /**
     * Controller for the directive.
     */
    function multisearchCtrl() { }

})();
