/* eslint-disable no-unused-vars */
app.directive('chartRcbq', ['Colors', '$compile', 'utilities', '$state', '_',function (Colors, $compile, utilities, $state, _) {
    return {
        restrict: 'E',

        // eslint-disable-next-line no-unused-vars
        link: function (scope, element, attrs, ctrl) {
            var svg, chartArea,
                barElement,
                values,
                parentWidth = element[0].clientWidth - 100,
                parentHeight = $(window).height(),
                chartHeight = parentHeight / 2,
                animationDuration = 1000,
                yAxis = d3.svg.axis(),
                legendArea,
                legendColumnWidth = 150,
                barsArea,
                barsAreaWidth,
                columnsCount,
                paging = 14,
                tooltip,
                tooltipWidth = 100,
                tooltipHeight = 90,
                tooltipTitle,
                yAxisElement,
                percentageFormatter,
                chartTitle,
                chartTitleText,
                axisTitleText,
                tooltipPercentage,
                tooltipAmount,
                tooltipPointerTop,
                tooltipPointerBottom,
                tooltipTimeout,
                dollarSign,
                xScale, yScale,
                yAxisTitle,
                shift = 0,
                barElementWidth,
                percentage = d3.format('%'),
                currency = scope.chart.config.data.format,
                colorScale,
                resizing = false,
                width = attrs.width || 250,
                height = attrs.height || 250;
            // $(document).on('click', function (e) {
            //     if ($(e.target).parents('svg').length < 1 && $(e.target).prop('tagName') !== 'svg') {
            //         _resetChart();
            //     }
            // });
            scope.$on('$destroy', function () {
                _cleanupChart();
            });

            scope.$on('$windowResizeEnd', function () {
                _resizeChart();
            });

            scope.$on('reset-chart', _resetChart);

            _initChart();
            _resetChart();

            scope.$on('draw-rcbq-chart', function (event, args) {
                colorScale = args.colorMap;
                parentWidth = element[0].clientWidth - 100;
                values = angular.copy(args.data);
                scope.labels = args.stacks;
                dollarSign = args.currency;
                chartTitleText = args.page_title;
                axisTitleText = args.axis_title;
                scope.labels = scope.labels.map(function (element, index) {
                    return {
                        label: element,
                        color: colorScale(element),
                        id: index
                    };
                });

                barsAreaWidth = parentWidth - legendColumnWidth;
                _updateChart(true);
            });
            scope.$on('show-tooltip', function (e, d) {
                _showTooltip(d);
            });
            scope.$on('hide-tooltip', function (e, d) {
                _hideTooltip(d.d, d.i);
            });
            scope.$on('move-tooltip', function (e, d) {
                _moveTooltip(d.d, d.i);
            });
            scope.$on('toggle-campaign', function (e, d) {
                _toggleCampaign(d);
            });

            scope.getTooltip = function (i) {
                if (values[i] && values[i].tooltip) {
                    return values[i].tooltip;
                }
            };

            function _initChart() {
                xScale = d3.scale.ordinal()
                    .rangeRoundBands([0, parentWidth - 500]);
                svg = d3.select(element[0])
                    .append('svg')
                    .attr({
                        width: parentWidth,
                        height: chartHeight + 50
                    });

                chartArea = svg
                    .append('g')
                    .attr({
                        class: 'chart-area',
                        transform: 'translate(20,0)'
                    });

                chartTitle = chartArea.append('text')
                    .attr({
                        class: 'chart-title',
                        transform: 'translate(0,20)'
                    });

                scope.legendAreaWrapper = svg.append('g')
                    .attr({
                        class: 'legend-area-wrapper',
                        'clip-path': 'url(#legend-clip)',
                        transform: 'translate(0, 0)'
                    });

                legendArea = scope.legendAreaWrapper
                    .append('g')
                    .attr({
                        class: 'legend-area',
                        transform: 'translate(0, 0)'
                    });

                yAxisElement = chartArea.append("g")
                    .attr({
                        class: 'y axis',
                        transform: 'translate(50,40)'
                    });

                if (attrs.normalized !== 'true') {
                    yAxisTitle = svg.append("text")
                        .attr({
                            class: 'chart-label',
                            transform: 'translate(' + [10, chartHeight / 2 + 20] + ') rotate(270)',
                        })
                        .style({
                            'text-anchor': 'middle',
                            'font-weight': 'bold'
                        });
                }

                barsArea = chartArea
                    .append('g')
                    .attr({
                        class: 'bars-area',
                        transform: 'translate(50,40)'
                    });
            }

            function _initTooltip(selection) {
                selection.select('.tooltip-area').remove();
                tooltip = selection
                    .append('g')
                    .data(values)
                    .attr({
                        class: 'tooltip-area',
                        opacity: 0,
                        transform: 'translate(0,-2000)'
                    })
                    .on('mouseenter', _keepTooltipVisible)
                    .on('mouseleave', _hideTooltip)
                ;
                tooltip.append('rect')
                    .attr({
                        style: 'fill: rgba(255,255,255,0.7); stroke: #ddd; stroke-width: 1px;',
                        width: tooltipWidth,
                        height: tooltipHeight,
                        rx: 5,
                        ry: 5
                    });

                tooltipPointerTop = tooltip.append('polygon')
                    .attr({
                        class: 'top-pointer',
                        points: '10 -10, 20 0, 0,0',
                        transform: 'translate(' + [tooltipWidth / 2 - 10, 0] + ')'
                    })
                    .style({
                        fill: 'rgba(255,255,255,0.7)',
                        stroke: '#ddd',
                        'stroke-width': '1px'
                    });

                tooltipPointerBottom = tooltip.append('polygon')
                    .attr({
                        class: 'bottom-pointer',
                        style: 'fill: rgba(255,255,255,0.7);, stroke:#ddd; stroke-width:1px;',
                        points: '10 10, 20 0, 00,0',
                        transform: 'translate(' + [tooltipWidth / 2 - 10, tooltipHeight] + ')'
                    });

                tooltipTitle = tooltip.append('text')
                    .attr({
                        class: 'tooltip-title',
                        transform: 'translate(' + [tooltipWidth / 2, 30] + ')'
                    });

                tooltipPercentage = tooltip.append('text')
                    .attr({
                        class: 'tooltip-percentage',
                        transform: 'translate(' + [tooltipWidth / 2, 50] + ')'
                    });

                tooltipAmount = tooltip.append('text')
                    .attr({
                        class: 'tooltip-amount',
                        transform: 'translate(' + [tooltipWidth / 2, 70] + ')'
                    });
            }

            function _updateChart(updateLegend) {
                var domain, range;

                if (!values) { values = []; } //for instances where chart does not have any data to show

                values.forEach(function (bar, index) {
                    bar.total = _.sum(_.compact(bar.values.map(function (item) {
                        return !item.disabled ? item.amount : 0;
                    }))) || 0;
                    bar.index = index;
                }
                );
                values.forEach(function (bar) {
                    var y0 = 0;
                    bar.values.forEach(function (stackItem, i) {
                        stackItem.id = i;
                        stackItem.color = colorScale(scope.labels[i].label);
                        stackItem.percentage = ((stackItem.disabled ? 0 : stackItem.amount) / bar.total) || 0;
                        stackItem.y0 = y0;
                        stackItem.y1 = attrs.normalized === 'true'
                            ? y0 += stackItem.percentage
                            : y0 += stackItem.disabled ? 0 : stackItem.amount;
                    });
                }
                );
                percentageFormatter = d3.format('%');
                if (attrs.normalized === 'true') {
                    domain = [0, 1];
                }
                else {
                    domain = [0, d3.max(values, function (d, i) { return d.total; })];
                }
                range = [chartHeight - 10, 10];

                yScale = d3.scale.linear()
                    .domain(domain)
                    .rangeRound(range)
                    .nice();

                yAxis.scale(yScale)
                    .orient('left')
                    .ticks(10)
                    .tickFormat(function (d, i) {
                        var yAxisValue = null;
                        if (scope.query && scope.query.metric) {
                            yAxisValue = scope.query.metric === 'count' ? d : currency(d);
                        }
                        else {
                            yAxisValue = attrs.normalized === 'true' ? percentageFormatter(d) : currency(d);
                        }
                        return yAxisValue;

                    });

                yAxisElement
                    .transition()
                    .duration(animationDuration)
                    .call(yAxis);

                if (updateLegend) {
                    scope.$broadcast('update-legend', { width: 300, height: chartHeight, labels: scope.labels });
                } else {
                    values[0].values.map(function (item) {
                        d3.select('.legend-area .legend-element[element-id="' + item.id + '"] text.visibility-icon')
                            .text(function () {
                                return item.disabled ? '' : '';
                            });
                    });
                }
                if (attrs.legend === 'true') {
                    paging = Math.floor(chartHeight / 30);
                    columnsCount = scope.labels.length > paging ? 2 : 1;
                }
                else {
                    columnsCount = 0;
                }
                chartTitle.text(chartTitleText);
                if (attrs.normalized !== 'true') {
                    yAxisTitle.text(axisTitleText);
                }

                barsAreaWidth = parentWidth - legendColumnWidth * columnsCount;
                barElementWidth = (parentWidth - legendColumnWidth * columnsCount) / values.length;

                legendArea.attr({ transform: 'translate(0,0)' });
                shift = 0;

                barElement = barsArea
                    .selectAll('.bar-element')
                    .data(values);

                barElement
                    .enter()
                    .append('g')
                    .attr({
                        class: 'bar-element',
                        'bar-id': function (d, i) { return i; },
                        transform: function (d, i) {
                            return 'translate(' + [barElementWidth * i, 0] + ')';
                        }
                    });

                barsArea
                    .selectAll('.bar-element')
                    .data(values)
                    .exit()
                    .remove();

                barElement
                    .transition()
                    .duration(animationDuration)
                    .attr({
                        transform: function (d, i) {
                            return 'translate(' + [barElementWidth * i, 0] + ')';
                        }
                    });

                yAxisElement
                    .selectAll('.tick line')
                    .transition()
                    .duration(animationDuration)
                    .attr({
                        x2: barsAreaWidth
                    });

                barElement.each(function (d, i) {
                    var label = d3.select(this).select('text.bar-label');

                    if (label.empty()) {
                        label = d3.select(this)
                            .append('text')
                            .attr({
                                'bar-id': function (d, i) { return i; },
                                'text-anchor': 'middle',
                                class: 'bar-label',
                                dy: 0,
                                'uib-tooltip': function (d, i) {
                                    return '{{getTooltip(' + values.indexOf(d) + ')}}';
                                },
                                'tooltip-placement': 'bottom',
                                'tooltip-append-to-body': 'true'
                            });

                        $compile(this)(scope);
                    }

                    label
                        .attr({
                            x: barElementWidth / 2,
                            y: chartHeight + 20
                        })
                        .text(function (d, i) { return d.title; })
                        .call(wrap, barElementWidth - 40);

                    var stackArea = d3.select(this).select('.stacked-bars-area');

                    if (stackArea.empty()) {
                        stackArea = d3.select(this)
                            .append('g')
                            .attr({ class: 'stacked-bars-area' });
                        if (!attrs.normalized) {
                            stackArea
                                .append('text')
                                .attr({
                                    class: 'total-label',
                                    transform: function (d) {
                                        return 'translate(' + [barElementWidth / 2, chartHeight] + ')';
                                    }
                                })
                                .style({
                                    'text-anchor': 'middle',
                                    'font-weight': 'bold',
                                    'font-size': '8pt',
                                    fill: '#555',
                                    opacity: 0
                                });
                        }

                    }
                    d3.selectAll('.total-label')
                        .data(values)
                        .text(function (d, i) {
                            var datum = d, totalValue;
                            if (scope.query.metric) {
                                totalValue = scope.query.metric === 'count' ? datum.total : currency(datum.total);
                            }
                            else {
                                totalValue = currency(datum.total);
                            }
                            return totalValue;
                        })

                        .transition()
                        .duration(animationDuration)
                        .style({
                            opacity: 1
                        })
                        .attr({
                            transform: function (d) {
                                var parentDatum = d3.select(this.parentNode.parentNode).datum(),
                                    totals = _.sum(_.compact(parentDatum.values.map(function (item) {
                                        return !item.disabled ? item.amount : 0;
                                    }))) || 0;
                                return 'translate(' + [barElementWidth / 2, yScale(totals) - 10] + ')';
                            }
                        });

                    var stackedBar = d3.select(this).select('.stacked-bars-area')
                        .selectAll('.stacked-bar')
                        .data(function (d, i) {
                            return d.values;
                        });

                    var stackedBarLabel = d3.select(this).select('.stacked-bars-area')
                        .selectAll('.stacked-bar-label')
                        .data(function (d, i) {
                            return d.values;
                        });

                    stackedBar
                        .transition()
                        .duration(animationDuration)
                        .each(function () {
                            d3.select(this)
                                .on('mouseenter', null)
                                .on('mousemove', null)
                                .on('mouseleave', null);
                        })
                        .attr({
                            fill: function (d, i) {
                                return d.color;
                            },
                            width: function () {
                                return barElementWidth / 2;
                            },
                            height: function (d, i) {
                                return yScale(d.y0) - yScale(d.y1);
                            },
                            y: function (d) {
                                return attrs.normalized === 'true'
                                    ? chartHeight - yScale(d.y0)
                                    : yScale(d.y1);
                            },
                            transform: 'translate(' + [barElementWidth / 4, 0] + ')'
                        })
                        .each('end', function () {
                            d3.select(this)
                                .on('mouseenter', _showTooltip)
                                .on('click', _toggleCampaign)
                                .on('mousemove', _moveTooltip)
                                .on('mouseleave', _hideTooltip);

                        });

                    stackedBar
                        .exit()
                        .transition()
                        .duration(animationDuration)
                        .attr({
                            height: 0,
                            width: barElementWidth / 2,
                            y: chartHeight - 10
                        })
                        .each('end', function () {
                            d3.select(this)
                                .on('mouseenter', null)
                                .on('click', null)
                                .on('mousemove', null)
                                .on('mouseleave', null);

                            d3.select(this)
                                .remove();

                        });

                    stackedBar
                        .enter()
                        .append('rect')
                        .attr({
                            class: 'stacked-bar',
                            'element-id': function (d, i) { return i; },
                            fill: function (d, i) {
                                return d.color;
                            },
                            width: barElementWidth / 2,
                            height: 0,
                            y: chartHeight - 10,
                            transform: 'translate(' + [barElementWidth / 4, 0] + ')'

                        })
                        .transition()
                        .duration(animationDuration)
                        .attr({
                            width: barElementWidth / 2,
                            height: function (d, i) {
                                return yScale(d.y0) - yScale(d.y1);
                            },
                            y: function (d) {
                                return attrs.normalized === 'true'
                                    ? chartHeight - yScale(d.y0)
                                    : yScale(d.y1);
                            },
                            transform: 'translate(' + [barElementWidth / 4, 0] + ')'
                        })
                        .each('end', function () {
                            d3.select(this)
                                .on('mouseenter', _showTooltip)
                                .on('mousemove', _moveTooltip)
                                .on('mouseleave', _hideTooltip);

                        });

                    stackedBarLabel
                        .transition()
                        .duration(animationDuration)
                        .attr({
                            y: function (d) {
                                var thisHeight = yScale(d.y0) - yScale(d.y1);

                                return attrs.normalized === 'true'
                                    ? (chartHeight - yScale(d.y0)) + thisHeight / 2 + 4
                                    : yScale(d.y1) + thisHeight / 2 + 4;
                            },
                            transform: 'translate(' + [barElementWidth / 2, 0] + ')'
                        })
                        .tween('text', function (d) {
                            var unit, value, result;
                            value = d.percentage;
                            result = _textTweenPercentage(value, this);

                            return result;
                        })
                        .style({
                            opacity: function (d, i) {
                                return yScale(d.y0) - yScale(d.y1) > 20 ? 1 : 0;
                            }
                        });

                    stackedBarLabel
                        .enter()
                        .append('text')
                        .text(function (d, i) {
                            return percentage(d.percentage);
                        })
                        .attr({
                            class: 'stacked-bar-label',
                            'element-id': function (d, i) { return i; },
                            y: chartHeight - 10,
                            transform: 'translate(' + [barElementWidth / 2, 0] + ')'

                        })
                        .style({
                            fill: function (d, i) {
                                return '#fff';
                            },
                            'text-anchor': 'middle',
                            opacity: 0,
                            'font-size': 12
                        })
                        .transition()
                        .duration(animationDuration)
                        .attr({
                            y: function (d) {
                                var thisHeight = yScale(d.y0) - yScale(d.y1);
                                return attrs.normalized === 'true'
                                    ? (chartHeight - yScale(d.y0)) + thisHeight / 2 + 4
                                    : yScale(d.y1) + thisHeight / 2 + 4;
                            }
                        })
                        .style({
                            opacity: function (d, i) {
                                return yScale(d.y0) - yScale(d.y1) > 20 ? 1 : 0;
                            }
                        });

                    stackedBarLabel
                        .exit()
                        .remove();
                });
                barElement.call(_initTooltip);
                resizing = false;
            }

            function _showTooltip(d) {
                window.clearTimeout(tooltipTimeout);
                var campaignId = d.id;
                var label = scope.labels[campaignId].label;
                var isChartFrozen = chartArea.classed('frozen');
                d3.selectAll('rect.stacked-bar')
                    .transition()
                    .style({
                        opacity: function (barDatum, i) {
                            return barDatum.id === campaignId
                                ? 1
                                : 0.5;
                        }
                    });

                d3.selectAll('.tooltip-area')
                    .attr({
                        opacity: 1
                    });
                //                d3.selectAll('.tooltip-area').attr({ transform: 'translate(' + [barElementWidth / 2 - tooltipWidth / 2, parseInt(selectedStack.attr('y')) + parseInt(selectedStack.attr('height') / 2) - shift + 10] + ')' })

                if (!isChartFrozen) {
                    d3.selectAll('.tooltip-area').each(function () {
                        var thisTooltip = d3.select(this);
                        var thisParent = d3.select(this.parentNode);
                        thisTooltip.select('.tooltip-title').text(function () { return label.trunc(14); });
                        thisTooltip.select('.tooltip-percentage').text(function (d, i) { return percentageFormatter(thisParent.datum().values[campaignId].percentage); });
                        thisTooltip.select('.tooltip-amount').text(function (d, i) {
                            var totalValue;
                            if (scope.query && scope.query.metric) {
                                if (scope.query.metric === "count") {
                                    totalValue = thisParent.datum().values[campaignId].amount;
                                } else {
                                    if (thisParent.datum().values[campaignId].amount === 0) {
                                        totalValue = '$0';
                                    } else {
                                        totalValue = currency(thisParent.datum().values[campaignId].amount);
                                    }
                                }
                            } else {
                                totalValue = currency(thisParent.datum().values[campaignId].amount);
                            }
                            return totalValue;
                        });
                    });
                }

                if (!isChartFrozen) {
                    d3.selectAll('.legend-element[element-id="' + campaignId + '"] .legend-background-rect')
                        .transition()
                        .attr({ opacity: 0.1 });
                }
            }

            function _keepTooltipVisible() {
                window.clearTimeout(tooltipTimeout);
            }

            // eslint-disable-next-line no-unused-vars
            function _moveTooltip(d, i) {
                var campaignId = d.id;
                var isChartFrozen = chartArea.classed('frozen');

                if (!isChartFrozen) {
                    var selectedStacks = d3.selectAll('.stacked-bar[element-id="' + campaignId + '"]');
                    selectedStacks.each(function (d, i) {
                        var selectedStack = d3.select(this);
                        var selectedStackMiddle = parseInt(selectedStack.attr('y')) + parseInt(selectedStack.attr('height') / 2);
                        var shift = selectedStackMiddle > 270 ? 120 : 0,
                            extraShift;
                        var topPointer = d3.select(this.parentNode.parentNode).select('.tooltip-area').select('.top-pointer');
                        var bottomPointer = d3.select(this.parentNode.parentNode).select('.tooltip-area').select('.bottom-pointer');

                        if (selectedStackMiddle > 270) {
                            topPointer.attr({ opacity: 0 });
                            bottomPointer.attr({ opacity: 1 });
                        }
                        else {
                            topPointer.attr({ opacity: 1 });
                            bottomPointer.attr({ opacity: 0 });
                        }

                        if (selectedStack.attr('height') > 20) {
                            extraShift = selectedStackMiddle > 270 ? 10 : 20;
                        } else {
                            extraShift = selectedStackMiddle > 270 ? 20 : 10;
                        }
                        d3.select(this.parentNode.parentNode).select('.tooltip-area').attr({ transform: 'translate(' + [barElementWidth / 2 - tooltipWidth / 2, parseInt(selectedStack.attr('y')) + parseInt(selectedStack.attr('height') / 2) - shift + extraShift] + ')' });
                    });
                }
            }

            // eslint-disable-next-line no-unused-vars
            function _hideTooltip(d, i) {
                window.clearTimeout(tooltipTimeout);
                tooltipTimeout = window.setTimeout(function () {
                    d3.selectAll('.tooltip-area').attr({
                        opacity: 0,
                        transform: 'translate(0,-2000)'
                    });
                }, 0);
                d3.selectAll('rect.stacked-bar')
                    .transition()
                    .style({
                        opacity: 1
                    });
            }

            // eslint-disable-next-line no-unused-vars
            function _toggleCampaign(d, i) {
                var s = $state.current.name;
                if (s === 'app.discover.revenueAndPipeline.attributionByQuarter') { return; }
                values.forEach(function (barArea) {
                    barArea.values[d.id].disabled = !barArea.values[d.id].disabled;
                });
                _updateChart(false);
            }

            function _resizeChart() {
                if (resizing)
                    return;

                parentWidth = element[0].clientWidth - 100;

                parentHeight = $(window).height();
                chartHeight = parentHeight / 2;

                svg
                    .transition()
                    .attr({
                        width: parentWidth,
                        height: chartHeight + 50
                    });

                xScale.rangeRoundBands([0, parentWidth - 530]);
                if (yScale) {
                    yScale.rangeRound([chartHeight - 10, 10]);
                    barsAreaWidth = parentWidth - legendColumnWidth * columnsCount;
                    barElementWidth = (parentWidth - legendColumnWidth * columnsCount) / values.length;
                    d3.select('.legend-area-wrapper')
                        .transition()
                        .duration(animationDuration)
                        .attr({
                            transform: function () {
                                return 'translate(' + [barsAreaWidth + 100, 40] + ')';
                            }

                        });

                    _updateChart(false);
                }
            }

            function _cleanupChart() {
                d3.selectAll('.tooltip').remove();
            }

            function _resetChart() {
                if (values) {
                    values.forEach(function (bar, index) {
                        bar.values.forEach(function (stackItem, i) {
                            stackItem.disabled = false;
                        });
                    }
                    );

                    _updateChart(false);
                }
            }

            function wrap(text, width) {
                text.each(function () {
                    var text = d3.select(this),
                        words = text.text().split(/\s+/).reverse(),
                        word = words.pop(),
                        line = [],
                        lineNumber = 0,
                        lineHeight = 1.1, // ems
                        y = text.attr("y"),
                        dy = parseFloat(text.attr("dy")),
                        tspan = text.text(null).append("tspan").attr("x", barElementWidth / 2).attr("y", y).attr("dy", dy + "em");
                    while (word) {
                        line.push(word);
                        tspan.text(line.join(" "));
                        if (tspan.node().getComputedTextLength() > width) {
                            line.pop();
                            tspan.text(line.join(" "));
                            line = [word];
                            tspan = text.append("tspan").attr("x", barElementWidth / 2).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
                        }
                        word = words.pop();
                    }
                });
            }

            var _textTweenPercentage = function (data, node) {
                var value = data,
                    i = d3.interpolate(parseInt(node.innerHTML) / 100, value);
                return function (t) {
                    node.textContent = percentage(i(t));
                };
            };

        }
    };
}]);
