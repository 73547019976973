app.controller('orphanLeadFinderCtrl', ['$scope', '$http', '$window', '$location', 'utilities', '$state', 'api', '$rootScope', '_', 'authSrvc',
    function ($scope, $http, $window, $location, utilities, $state, api, $rootScope, _, authSrvc) {
        var PAGE_SIZE = 20;
        var orphanLeadsCall;

        function updatePage() {
            if (orphanLeadsCall) { orphanLeadsCall.abort(); }
            $state.current.data.loading = true;
            (orphanLeadsCall = api.get("orphan_leads", $scope.query, false)).then(function (data) {
                $scope.orphanLeads.leads = data.data.leads;
                $scope.orphanLeads.disablePrev = $scope.query.page === 1;
                $scope.orphanLeads.disableNext = data.data.leads.length < PAGE_SIZE;
                $state.current.data.loading = false;

                // eslint-disable-next-line no-unused-vars
                angular.forEach(data.data.leads, function (lead, i) {
                    if (!lead.oppty_amount) {
                        lead.oppty_amount = 0;
                    }
                    lead.confidence = lead.score <= 29 ? 'low' : lead.score <= 49 ? 'medium' : 'high';
                });
            });
        }

        // eslint-disable-next-line no-unused-vars
        $scope.downloadCSV = function (url) {
            api.getter({ url: "authenticate/createExportToken" })
                .then(function (response) {
                    let url = "export/orphanLeads?" + _.queryString($scope.query);
                    for (var key in response.data) {
                        url += "&" + key + "=" + response.data[key];
                    }
                    url = authSrvc.getUrlToUse(url);
                    authSrvc.redirect(url);
                });
        };


        $scope.prevPage = function () {
            $rootScope.$broadcast('detailChanged', "previous page");
            if (!$scope.orphanLeads.disablePrev) {
                $scope.query.page--;
                updatePage();
            }
        };

        $scope.nextPage = function () {
            $rootScope.$broadcast('detailChanged', "next page");
            if (!$scope.orphanLeads.disableNext) {
                $scope.query.page++;
                updatePage();
            }
        };

        $scope.reRun = function () {
            $rootScope.$broadcast('detailChanged', "filter: " + $scope.query.filterType);
            // reset page on search
            $scope.query.page = 1;
            updatePage();
        };

        $scope.getNames = function (val) {
            return $http.get('api/v1/account_names_starts_like', {
                params: { startName: val }
            }).then(function (response) {
                return response.data;
            });
        };

        (function init() {
            $scope.orphanLeads = {
                disablePrev: true,
                disableNext: true
            };

            $scope.query = {
                page: 1,
                searchTerm: ""
            };

            $scope.tableTitles = [
                { name: "Orphan Lead", tooltip: "An Orphan Lead is a Lead not associated with any account." },
                { name: "Inferred Account", tooltip: "Potential Account match for the Orphan Lead. Matched by on the email domain name of the Orphan Lead and the domains of Associated Leads on the Account." },
                { name: "Confidence Level", tooltip: "Level of confidence based on lead-to-account matching criteria." },
                { name: "Count of Campaigns", tooltip: "Campaigns the Orphan Lead is a member of." },
                { name: "Most Recent Response Date", tooltip: "Most Recent date of the responded campaign membership." },
                { name: "Count of Opportunities", tooltip: "The most recent opportunity on the Inferred Account and the count of additional Opportunities if more than one exist." },
                { name: "Total Opportunities Amount", tooltip: "Total Amount Value of all Opportunities on the Inferred Account." },
            ];



            $scope.$on('$destroy', function () {
                if (orphanLeadsCall) { orphanLeadsCall.abort(); }
            });

            updatePage();
        })();

    }]);
