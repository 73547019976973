import {ListItem} from './list-item';
import {HttpSrvc} from './http.service';
import {FOLDERS_ENDPOINT, GRAILS_URL_VAR} from '../constants/constants';
import {FolderItem} from './folder-item';

export class FolderSrvc {

    static $inject: Array<string> = ['httpSrvc', 'environmentSrvc'];

    public constructor(private httpSrvc: HttpSrvc,
                       private environmentSrvc: any) {
        return;
    }

    public getFolders(): Promise<any> {
        return this.environmentSrvc.getVariable(GRAILS_URL_VAR).then((baseHost: string) => {
            return this.httpSrvc.get(baseHost + FOLDERS_ENDPOINT);
        });
    }

    public convertToFolderItems(response: any): FolderItem[] {
        const folders: FolderItem[] = [];
        // Showing all folder we may narrow it to only public and from the user
        for (const folder of response.data) {
            const listNames: ListItem[] = [];
            for (const list of folder.contents) {
                listNames.push(new ListItem(list.id, list.name));
            }
            folders.push(new FolderItem(folder.folder_id,
                folder.folder_name,
                listNames
            ));
        }
        return folders;
    }
}
