app.controller("campaignTrendingCtrl", [
    "$scope",
    "$b",
    "api",
    "noty",
    "$state",
    "$filter",
    "utilities",
    "$q",
    "$timeout",
    "$rootScope",
    "_",
    "campaign",
    "cohorts",
    "trend",
    function($scope, $b, api, noty, $state, $filter, utilities, $q, $timeout, $rootScope, _, campaign, cohorts, trend) {
        $state.current.data.loading = true;
        $scope.moment = moment;

        var campaignAttributionCall, costCall;

        $scope.$on("filtersChanged", function() {
            getCampaign();
        });

        $scope.diff = function(trend) {
            var decimals = trend.data[0].diff < 0.01 && trend.data[0].diff !== 0 ? 2 : 0;
            return $filter("percentage")(trend.data[0].diff, decimals);
        };

        $scope.runAllQuery = function() {
            getCampaign();
        };

        function updateChartTypes() {
            var a = Object.keys($scope.data.trend);

            $scope.data.chartTypes = a.map(function(d) {
                var displayMap = {
                    leads: "Responses",
                    opptys: "Opportunities",
                    deals: "Deals",
                    pipeline: "Pipeline",
                    revenue: "Revenue"
                };
                return {
                    key: d,
                    label: displayMap[d] + " - " + _.find($scope.data.cohorts, { key: $scope.query.cohort }).value
                };
            });
        }

        function getCampaign() {
            if (campaignAttributionCall) { campaignAttributionCall.abort(); }

            $state.current.data.loading = true;
            var apiUrl = $state.current.data.pageType == "channel-asset-specific" ? "channel_attribution" : "campaign_attribution";
            (campaignAttributionCall = api.get(apiUrl, $scope.query)).then(function(data) {
                $scope.data.data = $scope.calc.format($scope.data, data.data);
                $scope.data.data.campaign.name = _.titleCase($scope.data.data.campaign.name);
                $state.current.data.title = $scope.data.data.campaign.name;

                if ($state.current.data.pageType == "channel-asset-specific") {
                    if (costCall) { costCall.abort(); }

                    $scope.costLoading = true;
                    // get the cost for the specific channel asset
                    var costParams = {
                        channelId: $scope.data.data.campaign.campaign_id,
                        cohort: $scope.query.cohort,
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate
                    };
                    (costCall = api.get("channel_specific_cost", costParams)).then(
                        function(data) {
                            $scope.data.data.campaign.cost = data.data.cost;
                            $scope.costLoading = false;
                        },
                        // eslint-disable-next-line no-unused-vars
                        function(data) {
                            $scope.costLoading = false;
                        }
                    );
                }

                var dateField = trend.dateFieldFromDataset($scope.query.dataSet);

                trend.getTrendData($scope,dateField).then(function() {
                    updateChartTypes();
                    $scope.loadChart($scope.query.chartType);
                    $scope.data.selectedType = _.find($scope.data.chartTypes,{'key': $scope.query.chartType});
                    $state.current.data.loading = false;
                });
            });
        }

        $scope.loadChart = function(t) {
            utilities.queryString({ chartType: t });
            $timeout(function() {
                var c;
                if (t == "pipeline") {
                    c = {
                        data: $scope.data.trend.pipeline,
                        dimensions: {
                            moment: {
                                axis: "x"
                            },
                            total: {
                                type: "line"
                            }
                        }
                    };

                    $scope.chart.chart.axis.y.tick = {
                        format: function(d) {
                            return $filter("nrFormat")(d, "$", 0);
                        }
                    };

                    $scope.chart.chart.axis.y.label.text = "Pipeline";
                } else if (t == "revenue") {
                    c = {
                        data: $scope.data.trend.revenue,
                        dimensions: {
                            moment: {
                                axis: "x"
                            },
                            total: {
                                type: "line"
                            }
                        }
                    };

                    $scope.chart.chart.axis.y.tick = {
                        format: function(d) {
                            return $filter("nrFormat")(d, "$", 0);
                        }
                    };

                    $scope.chart.chart.axis.y.label.text = "Revenue";
                } else if (t == "opptys") {
                    c = {
                        data: $scope.data.trend.opptys,
                        dimensions: {
                            moment: {
                                axis: "x"
                            },
                            total: {
                                type: "line"
                            }
                        }
                    };

                    $scope.chart.chart.axis.y.tick = {
                        format: function(d) {
                            return $filter("number")(d, 0);
                        }
                    };
                    $scope.chart.chart.axis.y.label.text = "Number of Opportunities";
                } else if (t == "leads") {
                    c = {
                        data: $scope.data.trend.leads,
                        dimensions: {
                            moment: {
                                axis: "x"
                            },
                            total: {
                                type: "line"
                            }
                        }
                    };

                    $scope.chart.chart.axis.y.tick = {
                        format: function(d) {
                            return $filter("number")(d, 0);
                        }
                    };
                    $scope.chart.chart.axis.y.label.text = "Number of Responses";
                } else {
                    c = {
                        data: $scope.data.trend.deals,
                        dimensions: {
                            moment: {
                                axis: "x"
                            },
                            total: {
                                type: "line"
                            }
                        }
                    };

                    $scope.chart.chart.axis.y.tick = {
                        format: function(d) {
                            return $filter("number")(d, 0);
                        }
                    };
                    $scope.chart.chart.axis.y.label.text = "Number of Deals";
                }
                angular.extend($scope.chart, c);
            });
        };

        (function init() {
            $scope.data = campaign.getData();
            $scope.data.currencyFilter = "bfCurrency";
            $scope.data.infoName = $state.current.data.pageType == "channel-asset-specific" ? "Channel Asset Info" : "Campaign Info";

            // For dynamics 365 enabled orgs, we do not offer a direct link to the campaign info page
            var dynamics365Enabled = $scope.orgConfig.organization_settings && $scope.orgConfig.organization_settings.dynamics365_enabled;

            $scope.data.sfUrl = dynamics365Enabled ? null : $scope.sfdcURL;

            $scope.query = _.defaults($state.params, {
                modelType: "even",
                dataSet: $scope.data.dataSets[0].key,
                cohort: "quarter2Date",
                chartType: "leads"
            });

            $scope.chart = {
                chart: {
                    point: {
                        r: 6
                    },
                    axis: {
                        y: {
                            label: {
                                position: "outer-middle"
                            }
                        },
                        x: {
                            height: 70,
                            label: {
                                position: "outer-center"
                            },
                            tick: {
                                // eslint-disable-next-line no-unused-vars
                                format: function(d, i) {
                                    return $filter("date")(d, "shortDate");
                                },
                                rotate: -45
                            }
                        }
                    },
                    grid: {
                        x: {
                            show: true
                        },
                        y: {
                            show: true
                        }
                    },
                    legend: {
                        show: false
                    },
                    tooltip: {
                        format: {},
                        contents: function(d, defaultTitleFormat, defaultValueFormat, color) {
                            var $$ = this,
                                config = $$.config,
                                titleFormat = config.tooltip_format_title || defaultTitleFormat,
                                valueFormat = config.tooltip_format_value || defaultValueFormat,
                                text,
                                i,
                                title,
                                value,
                                bgcolor;

                            for (i = 0; i < d.length; i++) {
                                if (!(d[i] && (d[i].value || d[i].value === 0))) {
                                    continue;
                                }

                                if (!text) {
                                    title = titleFormat ? titleFormat(d[i].x) : d[i].x;
                                    text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");
                                }

                                value = valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index) ? valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index) : 0;
                                bgcolor = $$.levelColor ? $$.levelColor(d[i].value) : color(d[i].id);

                                text += "<tr class='" + $$.CLASS.tooltipName + "-" + d[i].id + "'>";
                                text += "<td class='name'><span style='background-color:" + bgcolor + "'></span></td>";
                                text += "<td class='value'>" + value + "</td>";
                                text += "</tr>";
                            }
                            return text + "</table>";
                        }
                    }
                }
            };

            if (!_.isDefined(_.find($scope.data.modelTypes, { key: $scope.query.modelType }))) {
                $scope.query.modelType = "even";
            }

            utilities.combine($scope.query, $state.params);

            getCampaign();

            $scope.$on("$destroy", function() {
                if (campaignAttributionCall) { campaignAttributionCall.abort(); }
                if (costCall) { costCall.abort(); }
            });
        })();
    }
]);
