app.controller('demoConfigCtrl', ['$scope', '$cookies', function ($scope, $cookies) {
    $scope.cookies = $cookies;

    $scope.demo = $cookies.get('BrightFunnelDemo') ? angular.fromJson($cookies.get('BrightFunnelDemo')) : { companyName: 'Acme Inc.' };

    $scope.setDemoFlag = function () {
        $cookies.put('BrightFunnelDemo', angular.toJson(angular.copy($scope.demo)));
    };

    $scope.removeDemoFlag = function () {
        $cookies.remove('BrightFunnelDemo');
    };

}]);
