/* eslint-disable no-undef */

(function() {
    'use strict';

    app.factory('influenceTypes', influenceTypes);

    influenceTypes.$inject = ['$rootScope'];

    function influenceTypes($rootScope) {

        var service = {};

        service.useNewInfluenceType = function () {
            return $rootScope.orgConfig.features
                && $rootScope.orgConfig.features['model']
                && (
                    $rootScope.orgConfig.features.model['influence_type'] === 'canAccess'
                    || $rootScope.orgConfig.features.model['influence_type_limited'] === 'canAccess'
                );
        };

        service.setDefaultInfluenceType = function(scope, useNewInfluenceType = service.useNewInfluenceType) {
            if (!scope.query.influenceType
                || (scope.data.influence
                && scope.data.influence.find(type => type.key !== scope.query.influenceType)
                && useNewInfluenceType())) {
                scope.query.influenceType = 'any';
            }
        };

        service.useTypeFilteredAttr = function (scope) {
            return (
                (
                    (
                        scope.query.influenceType === 'any'
                        && $rootScope.orgConfig.features.model['influence_type'] === 'canAccess'
                    )
                    || scope.query.influenceType === 'pre'
                )
                && scope.userData.platform === 'campaign'
            )
            || (
                (
                    scope.query.influenceType === 'any'
                    || scope.query.influenceType === 'pre'
                )
                && scope.userData.platform === 'full'
            );
        };

        service.typeAttrModelFilter = function (scope) {
            return function (model) {
                if (scope.query.influenceType === 'any') {
                    if (scope.userData.platform === 'full' && model.key !== 'last') {
                        return true;
                    }

                    if (
                        scope.userData.platform === 'campaign'
                        && ['even', 'sourced'].indexOf(model.key) > -1
                    ) {
                        return true;
                    }
                }
                if (scope.query.influenceType === 'pre') {
                    if (model.key !== 'custom' && scope.userData.platform === 'full') {
                        return true;
                    }

                    if (scope.userData.platform === 'campaign') {
                        if (
                            (model.key === 'even' && $rootScope.orgConfig.features.model['influence_type_limited'] === 'canAccess')
                            || (model.key !== 'custom' && $rootScope.orgConfig.features.model['influence_type'] === 'canAccess')
                        ) {
                            return true;
                        }
                    }
                }
                return false;
            };
        };

        function checkQueryParams(influenceType, modelType, platformType) {
            return (influenceType === 'pre' && (platformType === 'full' || platformType === 'campaign') && modelType === 'custom')
                || (influenceType === 'any' && platformType === 'full' && (modelType === 'sourced' || modelType === 'last'))
                || influenceType === 'post';
        }

        service.defaultToEven = function (scope) {
            if (scope && scope.query && scope.userData) {
                const modelType = scope.query.model || scope.query.modelType;
                const influenceType = scope.query.influenceType;
                const platformType = scope.userData.platformType;

                return checkQueryParams(influenceType, modelType, platformType);
            }
            return false;
        };

        service.attributionTypeMapping = [
            { displayName: 'Any Influence', key: 'any' },
            { displayName: 'Pre-Opportunity Influence', key: 'pre' },
            { displayName: 'Post-Opportunity Influence', key: 'post' }
        ];

        service.defaultTypeMappings = [
            { displayName: 'Any Influence', key: 'any' },
        ];

        service.typeMappings = () => {
            return $rootScope.orgConfig.features
                && $rootScope.orgConfig.features['model']
                && $rootScope.orgConfig.features.model['influence_type'] === 'canAccess' ?
                service.attributionTypeMapping
                : service.defaultTypeMappings;
        };

        return service;
    }
})();
