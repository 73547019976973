import { module as angularModule } from 'angular';

import { APP_BRIGHTFUNNEL } from '../../constants';
import { TrendingKpiDonutChart } from './components/donut-chart/component';
import { TrendingKpiAreaChart } from './components/area-chart/component';
import { TrendingKpiGaugeChart } from './components/gauge-chart/component';
import { TrendingKpiParetoChart } from './components/pareto-chart/component';
import { TrendingKpiNumberChart } from './components/number-tile/component';

const app = angularModule(APP_BRIGHTFUNNEL);

app.directive('trendingKpiDonutChart', TrendingKpiDonutChart.Factory);
app.directive('trendingKpiAreaChart', TrendingKpiAreaChart.Factory);
app.directive('trendingKpiGaugeChart', TrendingKpiGaugeChart.Factory);
app.directive('trendingKpiParetoChart', TrendingKpiParetoChart.Factory);
app.directive('trendingKpiNumberChart', TrendingKpiNumberChart.Factory);
