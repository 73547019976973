import {extend as angularExtend} from 'angular';

export class HttpSrvc {

    static $inject: Array<string> = ['$http', '$q'];
    static RETRIES = 5;
    static DONT_RETRY: any = [408, 429, 503, 504];

    public constructor(private $http: any, private $q: any) {
        return;
    }

    public post(url: string, params: any, isUrlEncoded = false): Promise<any> {
        const contentType: any = isUrlEncoded ?
            {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'} :
            {'Content-Type': 'application/json'};
        const data: any = isUrlEncoded ?
            this.urlEncodeParams(params) :
            params;

        const postPromise: Promise<any> =
            this.$http({
                method: 'POST',
                url: url,
                data: data,
                headers: contentType
            });
        return this.try(postPromise);
    }

    public get(url: string): Promise<any> {
        const getPromise: Promise<any> = this.$http.get(url);
        return this.try(getPromise);
    }

    private urlEncodeParams(params: any): string {
        if (typeof params !== 'object') {
            return null;
        }

        const urlEncoded: string[] = [];
        for (const key in params) {

            if (Array.isArray(params[key])) {

                for (const elem of params[key]) {
                    urlEncoded.push(encodeURIComponent(key) + '=' +
                        encodeURIComponent(elem));
                }
            } else {
                urlEncoded.push(encodeURIComponent(key) + '=' +
                    encodeURIComponent(params[key]));
            }
        }
        return urlEncoded.join('&');
    }

    private try(promise: Promise<any>): Promise<any> {
        return promise.then((response) => {
            return response;
        }, (error) => {
            if (!HttpSrvc.DONT_RETRY.includes(error.status)) {
                throw error.statusText;
            }
            const config = angularExtend({
                retryCount: 1
            }, error.config);
            return this.retry(config);
        });
    }

    private retry(config: any): Promise<any> {
        return this.$http(config).then((response) => {
            return response;
        }, (error) => {
            if (!HttpSrvc.DONT_RETRY.includes(error.status)) {
                config.retryCount = HttpSrvc.RETRIES;
            }
            config.retryCount++;

            if (config.retryCount <= HttpSrvc.RETRIES) {
                return this.retry(config);
            }

            throw error.statusText;
        });
    }
}
