/**
 * Sets constants for the application.
 */
(function () {
    'use strict';

    angular
        .module('bombora')
        .constant('BomboraConstants',
            {
                API: {
                    AUTHORIZATION: '/v1/authorize',
                    TOPICS: '/v1/search-topics',
                    SAVE_TOPICS: '/v1/set-topics',
                    LOAD_TOPICS: '/v1/show-topics',
                    CATEGORIES: '/v1/list-categories',
                    THEMES: '/v1/list-themes',
                    CONFIG_HOST: '/bombora/config'
                },
                MAX_SELECTION: 12,
                LABELS: {
                    TO_SELECT: {
                        HEADER: 'Topics',
                        SUBTITLE: 'Select up to ',
                        SUBHEADER: 'To be monitored for surge consumption',

                    },
                    SELECTED: {
                        HEADER: 'Selected Topics',
                        SUBTITLE: ' selected',
                        SUBHEADER: 'View your selected topics below, remove if needed.',
                    },
                    SCORE_THRESHOLD: {
                        HEADER: 'Score Threshold',
                        SUBHEADER: 'Scores above 60 represent an increase in intent.',
                    },
                    SEARCH: {
                        PLACEHOLDER: 'Search'
                    },
                    FILTERS: {
                        HEAD: 'Filter topics by ',
                        THEME: 'Theme',
                        CATEGORY: 'Category',
                    },
                    SAVE: {
                        BUTTON: 'Save',
                        MESSAGE: {
                            OK: 'Config was saved',
                            ERROR: 'There was an error saving the configuration.'
                        }
                    },
                    CLEAR: 'Clear'
                }
            });
})();
