import { module as angularModule } from 'angular';

import { APP_BRIGHTFUNNEL } from '../../constants';
import * as CONSTS from './consts';
import {
    IAuthToken,
    IGetUnifiedCustomerIdForCOURequest,
    IGetUnifiedCustomerIdForCOUResponse,
} from './interface';

const app = angularModule(APP_BRIGHTFUNNEL);

export class CustomerEntitlementService {
    static $inject: string[] = [
        '$http',
        '$rootScope',
        'authSrvc',
        'environmentSrvc',
    ];

    private getUrl = (host: string, path: string) => `${host}${path}`;

    authorize() {
        return this.environmentSrvc.getVariable(CONSTS.CUSTOMER_ENTITLEMENT_URL_ENV).then((host) => {
            const entitlementAuthEndpoint = this.getUrl(host, CONSTS.CUSTOMER_ENTITLEMENT_ENDPOINT_MAP_AUTHORIZE);
            return this.$http.get(entitlementAuthEndpoint, { withCredentials: false }).then((res) => {
                const TERMINUS_HUB = 'Terminus Hub';
                this.authSrvc.writeJwtToCookie(res.data.token);
                this.authSrvc.storeToken(TERMINUS_HUB);
                return res.data;
            });
        });
    }

    getClosestUnifiedCustomerIdForCOU(params: IGetUnifiedCustomerIdForCOURequest) {
        return this.environmentSrvc.getVariable(CONSTS.CUSTOMER_ENTITLEMENT_URL_ENV).then((host) => {
            const entitlementAuthEndpoint = this.getUrl(host, CONSTS.CUSTOMER_ENTITLEMENT_ENDPOINT_MAP_GET_CLOSEST_UNIFIED_CUSTOMER_ID);
            return this.$http.post(entitlementAuthEndpoint, params, { withCredentials: false }).then((res) => {
                return res.data;
            });
        });
    }

    constructor(
        public $http,
        public $rootScope,
        public authSrvc,
        public environmentSrvc,
    ) {}
}

export const SRVC_CUSTOMER_ENTITLMENT = 'customerEntitlementService';
app.service(SRVC_CUSTOMER_ENTITLMENT, CustomerEntitlementService);
