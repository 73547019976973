/**
 * Redirect the brightfunnel app to the previous loaded frament.
 */
(function(w) {
    if(typeof(Storage) !== "undefined" &&
        w.location.hash) {
        localStorage.setItem("ngStorage-redirect",
            JSON.stringify(w.location.hash));
    }
})(window);
