app.controller('webTrackingCtrl', ['$scope', '$http', '$window', '$location', 'utilities', 'api', '$q', '$filter', '$b', '$state', '$timeout', 'noty', '$rootScope', function ($scope, $http, $window, $location, utilities, api, $q, $filter, $b, $state, $timeout, noty, $rootScope) {
    var dataCall;

    function getWebSnippet() {
        if (dataCall) { dataCall.abort(); }
        (dataCall = api.get('fetch_snippet_id', { orgId: $rootScope.userData.organizationId }, false, true)).then(function (data) {
            if (data.data[0]) {
                $scope.data.webSnippet = data.data[0];
            }
            $scope.data.loading = false;
        });
    }

    (function init() {
        $scope.data = {
            loading: true
        };
        getWebSnippet();
    })();

}]);
