// eslint-disable-next-line no-unused-vars
app.controller('snippetGenCtrl', ['$http', 'api', '$scope', '$rootScope', '$state', '$q', function ($http, api, $scope, $rootScope, $state, $q) {

    $scope.logField = function (field) {
        // eslint-disable-next-line no-console
        console.log(field);
    };

    $scope.createSnippet = function () {
        $state.current.data.loading = true;
        var id = makeid();
        api.set('push_snippet_id', { snippetId: id, orgId: $scope.query.orgId }, false, true).then(function () {
            $scope.query.exists = true;
            $scope.query.snippet = id;
            $state.current.data.loading = false;
        });
    };

    //    $scope.downloadSnippet = function() {
    //        var data = 'some data here...',
    //        blob = new Blob([data], { type: 'text/plain' }),
    //        url = $window.URL || $window.webkitURL;
    //        $scope.fileUrl = url.createObjectURL(blob);
    //    };

    function makeid() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 16; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    $scope.loadSnippet = function (orgid) {
        if (!orgid) return;
        $state.current.data.loading = true;
        api.get('fetch_snippet_id', { orgId: orgid }, false, true).then(function (data) {
            $scope.query.exists = data.data[0] != null;
            $scope.query.snippet = data.data[0];
            $state.current.data.loading = false;
        });
    };

    (function init() {
        $scope.query = {
            orgId: 0,
            snippet: "",
            exists: false
        };

        api.get('internal_config', null, false, true).then(function (data) {
            var active = [];
            if (data.data.auth) {
                data.data.orgs.forEach(function (item) {
                    if (item.active) {
                        active.push(item);
                    }
                });
            }
            $scope.configs.orgs = [{ id: 0, name: "--Select an org--" }].concat(data.data.orgs);
            $scope.configs.orgsActive = [{ id: 0, name: "--Select an org--" }].concat(active);
            $scope.configs.orgsAll = [{ id: 0, name: "--Select an org--" }].concat(data.data.orgs);
            $scope.configs.impalaOrgs = [{ id: 0, name: "--Select an org--" }].concat(data.data.impalaorgs);
        });


    })();
}]);
