app.controller('accountsAttributionWidgetCtrl', ['$scope', 'api', 'utilities', 'account', '$filter', '_', 'cohorts', 'personaDefaults', '$rootScope', 'widgets', function ($scope, api, utilities, accountSrv, $filter, _, cohorts, personaDefaults, $rootScope, widgets) {
    var $tile = $scope.$tile,
        dataCall;

    var pageDefaults = {
        ps: '100',
        pg: '1',
        dir: 'd'
    };

    //------------------------ private methods ------------------------//
    this.getData = function () {
        if (dataCall) { dataCall.abort(); }

        var dynamics365Enabled = $rootScope.orgConfig.organization_settings && $rootScope.orgConfig.organization_settings.dynamics365_enabled;
        var sfURL = dynamics365Enabled ? null : $rootScope.sfdcURL;

        var header = _.find(accountSrv.listColumns($rootScope.userData.platform, sfURL), { 'field': $scope.widget.query.fld });
        $scope.widget.query.fieldType = header.apiType;
        $scope.widget.query.tbl = header.apiType;

        (dataCall = api.getter({
            url: 'account/load_list_data',
            params: $scope.widget.query,
            paramsNotToUpdate: 'all',
            skipFilters: true,
        })).then(
            function (data) {
                $scope.data.accounts = data.data.rows;
                $scope.data.totals = data.data.total_rows;
                $scope.data.data = widgets.numberOfTopNResults($scope.data.accounts, $scope.widget);
                $scope.widget.data.lastQuery = angular.copy($scope.widget.query);

                $tile.loading(100);
            },
            function (data) {
                if (!data.aborted) {
                    $tile.failedLoading();
                }
            }
        ).finally(
            function () {
                if ($tile.isLoading()) {
                    $tile.endLoading();
                }
                $scope.$emit("widgetLoadingDone");
            }
        );
    };
    //------------------------ scope methods ------------------------//
    $scope.cohortName = function (key) {
        return _.find($scope.data.cohorts, { key: key }).value;
    };

    $scope.targetField = function () {
        return _.find($scope.data.fields, { field: $scope.widget.query.fld });
    };

    (function init(ctrl) {
        $scope._ = _;

        $scope.data = {
            cohorts: utilities.formatCohorts(cohorts, ['all', 'ago', 'year', 'quarter', 'custom', 'toDate', 'lastFull']),
            fields: $filter('filter')(accountSrv.listColumns($rootScope.userData.platform, null), function (field) {
                return _.includes(field.filter, 'number') ||
                    _.includes(field.filter, 'currency') ||
                    _.includes(field.filter, 'bfCurrency');
            }),
            filters: accountSrv.filters()
        };

        const defaultQuery = personaDefaults.getQueryDefinitions($scope.widget);
        const params = _.defaults($scope.widget.query, defaultQuery.query);
        $scope.widget.query = widgets.addFilters(params, $scope.widget.data.filters, $scope.widget.data.accountLists);
        $scope.widget.query = _.assignIn($scope.widget.query, pageDefaults);

        $scope.widget.data = _.defaults($scope.widget.data, {});

        $tile.listeners.push($scope.$on('widgetUpdate', function () {
            if (!angular.equals(angular.copy($scope.widget.query), $scope.widget.data.lastQuery)) {
                ctrl.getData();
            }
        }));

        $scope.$on("updateListResults", function () {
            $scope.data.data = widgets.numberOfTopNResults($scope.data.accounts, $scope.widget);
        });

        $scope.$on('$destroy', function () {
            if (dataCall) { dataCall.abort(); }
        });
    })(this);
}]);
