'use strict';
import {
    AUTHORIZE_URL,
    GET_VISIBILITY_URL,
    SET_VISIBILITY_URL,
    TERMINUS_HUB,
    TOKEN_MANAGER_DOMAIN_KEY
} from '../constants/constants';

import {IConfig, IntegrationVisibility} from './interfaces';



export class TokenManagerSrvc {

    static $inject: Array<string> = ['$http', 'environmentSrvc', 'authSrvc'];

    private domain: string;

    constructor(
        private $http: any,
        private environmentSrvc: any,
        private authSrvc: any
    ) {
        this.domain = null;
    }

    private config: IConfig = () => {
        return this.environmentSrvc
            .getVariable(TOKEN_MANAGER_DOMAIN_KEY).then((value) => {
                this.domain = value;
            });
    };

    private authorizeSrvc = (error: any, fn: any, params: any) => {
        switch (error.status) {
        case 403:
            return this.$http.get(`${this.domain}${AUTHORIZE_URL}`).then(
                (authorizeResponse) => {
                    this.authSrvc.writeJwtToCookie(authorizeResponse.data.token);
                    this.authSrvc.storeToken(TERMINUS_HUB);
                    return fn(params);
                });
        default:
            return Promise.reject(error);
        }
    };

    public getIntegrationsVisibility = ({orgUuid}: { orgUuid: string }) => {
        const params = {
            orgUuid
        };
        return this.config().then(() => {
            return this.$http.post(`${this.domain}${GET_VISIBILITY_URL}`, params)
                .then((response) => {
                    return response.data;
                }, (error) => {
                    return this.authorizeSrvc(error, this.getIntegrationsVisibility, params);
                });
        });
    };

    public setIntegrationsVisibility = ({orgUuid, cards}: {orgUuid: string; cards: any}) => {
        const integrations: Array<IntegrationVisibility> = [];
        for (const card of cards) {
            const integration: IntegrationVisibility = { service: { serviceId: card.id }, visible: card.value};
            integrations.push(integration);
        }
        const params = {
            orgUuid,
            integrations
        };
        return this.config().then(() => {
            return this.$http.post(`${this.domain}${SET_VISIBILITY_URL}`, params)
                .then((response) => {
                    return response.data;
                }, (error) => {
                    return this.authorizeSrvc(error, this.setIntegrationsVisibility,
                        {orgUuid, cards});
                });
        });
    }
}
