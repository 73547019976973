app.directive('filterList', ['$b', '_', function ($b, _) {
    return {
        require: '^form',
        restrict: 'E',
        template: require('./filter-list.html'),
        scope: {
            rule: "=",
            filter: "=",
            data: "=",
            schema: '='
        },
        link: function (scope, elem, attrs, ctrl) {
            function selectMissingRequiredFields() {
                return elem[0].querySelector('.ng-invalid.ng-touched') ||
                    elem[0].querySelector('.ng-submitted.ng-invalid');
            }
            scope.formHasErrors = function () {
                return selectMissingRequiredFields() ? true : false;
            };

            scope.showRules = function (filter) {
                filter.open = !filter.open;
            };

            scope.addRule = function (filter) {
                if (filter.rules.length < 10) {
                    filter.rules.push({ name: null, type: 'grouping', values: [] });
                }
                else {
                    $b.alert('Rule Limit Reached.');
                }
            };

            scope.deleteFilter = function (filter) {
                var filterToDelete = _.find(scope.data.filters, { id: filter.id });
                scope.data.filters.splice(scope.data.filters.indexOf(filterToDelete), 1);
                ctrl.$dirty = true;
            };
        }
    };
}])
    .directive('filterRuleList', ['$timeout', '_', function ($timeout, _) {
        return {
            require: '^form',
            restrict: 'E',
            template: require('./filter-rule-list.html'),
            scope: {
                rule: "=",
                filter: "="
            },
            link: function (scope, elem, attrs, ctrl) {
                scope.inputIdSuffix = scope.$id;

                scope.handleRuleTextChange = function () {
                    if (_.isEmpty(scope.rule.values)) {
                        ctrl['rule_' + scope.inputIdSuffix].$setValidity('required', false);
                    } else {
                        ctrl['rule_' + scope.inputIdSuffix].$setValidity('required', true);
                    }
                };

                scope.getTypes = function (filter, rule) {
                    if (rule.type === 'only include' || rule.type === 'exclude') {
                        return ['grouping', 'only include', 'exclude'];
                    }
                    for (var count = 0; count < filter.rules.length; count++) {
                        if (filter.rules[count].type === 'only include' || filter.rules[count].type === 'exclude') {
                            return ['grouping'];
                        }
                    }
                    return ['grouping', 'only include', 'exclude'];
                };

                scope.namedRule = function (rule) {
                    return rule.type === 'grouping';
                };

                scope.addInput = function (rule, input) {
                    if (!_.isEmpty(input)) {
                        rule.values.push(input);
                        rule.input = ''; //reset input to be blank
                    }
                };

                scope.removeInput = function (rule, input) {
                    if (input && input.length > 0 && _.indexOf(rule.values, input) >= 0) {
                        _.pull(rule.values, input);
                        if (_.isEmpty(rule.values)) {
                            rule.input = '';
                        }
                    }
                    ctrl.$dirty = true;
                };

                scope.deleteRule = function (filter, rule) {
                    if (filter && rule) {
                        _.pull(filter.rules, rule);
                        // This timeout is to force a UI update in order to hide the fill in required fields message
                        $timeout(function () { });
                    }
                };
            }
        };
    }])
    .factory('filterConfigService', ['api', '$q', function (api, $q) {
        var cachedData;

        function fetchFilterConfig() {
            return cachedData ? $q.when(cachedData) :
                api.get('sfdc/load_schema_config', {}, false, true).then(function (responseData) {
                    cachedData = responseData;
                    return cachedData;
                });
        }

        return {
            fetchFilterConfig: fetchFilterConfig
        };
    }])
    .controller('globalFilterCtrl', ['$http', 'api', '$scope', '$rootScope', '$state', '$q', 'noty', '$b', 'filterConfigService', '_', function ($http, api, $scope, $rootScope, $state, $q, noty, $b, filterConfigService, _) {
        function initSchemaConfigAndFilters() {
            $state.current.data.loading = true;

            var schemaConfigPromise = filterConfigService.fetchFilterConfig();
            var filterConfigPromise = api.get('readConfig/get_filter_configs', { object: $scope.type }, false, true);
            var fetchLastFilterPromise = api.get('readConfig/fetch_last_filter', { object: $scope.type }, false, true);

            $q.all([schemaConfigPromise, filterConfigPromise, fetchLastFilterPromise]).then(function (data) {
                $scope.schema = data[0].data[$state.current.data.pageType];
                $scope.data.filters = data[1].data.filters;
                $scope.data.maxFilters = data[1].data.max;
                $scope.last_update = data[2].data;
                $state.current.data.loading = false;
            });

            //set each filter to be closed by default
            angular.forEach($scope.data.filters, function (filter) {
                filter.open = false;
            });
        }

        function equivalentFilterRules(f1, f2) {
            return _.get(f1, 'rules.length') === _.get(f2, 'rules.length');
        }

        function checkIfFiltersDirty(oldValue, newValue) {
            if (!oldValue || !newValue) {
                return false;
            }
            return (oldValue.length !== newValue.length) ||
                !_.reduce(_.keys(oldValue), function (result, value) {
                    return result && equivalentFilterRules(oldValue[value], newValue[value]);
                }, true);
        }

        $scope.data.filters = [];
        $scope.data.rules = ['grouping', 'only include', 'exclude'];
        $scope.type = $state.current.data.pageType;
        $scope.page = {
            saving_filters: false
        };
        initSchemaConfigAndFilters();

        $scope.ago = function () {
            if ($scope.last_update) {
                return moment(moment.utc($scope.last_update).local().format()).fromNow();
            }
        };

        $scope.addFilter = function () {
            var filters = [];
            for (var i = 1; i <= $scope.data.maxFilters; i++) {
                filters.push("filter" + i);
            }
            for (var count = 0; count < $scope.data.filters.length; count++) {
                filters.splice(filters.indexOf($scope.data.filters[count].id), 1);
            }
            if (filters.length > 0) {
                $scope.data.filters.push({ id: filters[0], name: '', field: $scope.schema[0], rules: [] });
            }
            else {
                $b.alert('You have reached the maximum allowable number of filters.');
            }
        };

        $scope.saveConfigs = function () {
            if ($scope.filterConfigForm.$valid) {
                $scope.page.saving_filters = true;
                // eslint-disable-next-line no-unused-vars
                api.set('config/save_filter_configs', { object: $scope.type, filters: $scope.data.filters }, true).then(function (data) {
                    noty.growl('Successfully saved filters', 'success');
                    $scope.page.saving_filters = false;
                    $scope.filterConfigForm.$setPristine();
                // eslint-disable-next-line no-unused-vars
                }, function (data) {
                    $scope.page.saving_filters = false;
                    noty.growl('There was an error saving your filters. Please contact support.', 'error', true);
                    $scope.filterConfigForm.$setPristine();
                });
            }
        };

        $scope.$on('$apiFinish', function () {
            $scope.$watch(function () {
                return $scope.data.filters;
            }, function (oldValue, newValue) {
                // Set dirty if filters have been added are removed
                if ($scope.filterConfigForm) {
                    // Don't override the value of dirty if it is already true
                    // If it's set to true, that means a filter or input was removed in one of the above directives
                    $scope.filterConfigForm.$dirty = ($scope.filterConfigForm.$dirty || checkIfFiltersDirty(oldValue, newValue));
                }
            }, true);
        });

    }]);
