/**
 * Controller for the json viewer directive.
 */
(function () {
    'use strict';

    angular
        .module('sfdc')
        .directive('textInputField', function () {
            return {
                template: require('./text-input-field.html'),
                controller: textInputFieldCtrl,
                controllerAs: "$ctrl",
                bindToController: true,
                scope: {
                    fieldName: '=',
                    fieldModel: '='
                }
            };
        });

    textInputFieldCtrl.$inject = [];

    /**
     * Controller for the directive.
     */
    function textInputFieldCtrl() { }
})();
