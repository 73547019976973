app.service('opptyAnalysis', [
    '$rootScope',
    'utilities',
    'userModels',
    'influenceTypes',
    'sigstrSrvc',
    function (
        $rootScope,
        utilities,
        userModels,
        influenceTypes,
        sigstrSrvc
    ) {
        var that = this;
        this.getData = function () {
            return {
                cohorts: utilities.formatCohorts($rootScope.rawCohorts, ['all', 'custom', 'year', 'quarter', 'ago', 'toDate', 'lastFull'], true),
                models: this.getAttrModels(),
                isCampSpecific: false,
                campaignDesc: "",
                campaignName: "",
                influences: {
                    sourceCampaignId: { desc: "Opportunities sourced by Campaign", nameField: "campaignName" },
                    sourceCampaignGroup: { desc: "Opportunities sourced by Campaign Type", nameField: "sourceCampaignGroup" },
                    influencedCampaignId: { desc: "Opportunities influenced by Campaign", nameField: "campaignName" },
                    influencedCampaignGroup: { desc: "Opportunities influenced by Campaign Type", nameField: "influencedCampaignGroup" },
                    lastCampaignId: { desc: "Opportunities last touched by Campaign Type", nameField: "campaignName" },
                    lastCampaignGroup: { desc: "Opportunities last touched by Campaign Type", nameField: "lastCampaignGroup" }
                },
                options: [
                    {
                        key: 'revenue',
                        value: 'Closed Won in Cohort'
                    },
                    {
                        key: 'pipeline',
                        value: 'Created in Cohort'
                    },
                    {
                        key: 'closed',
                        value: 'Closed in Cohort'
                    },
                    {
                        key: 'forecasted',
                        value: 'Forecasted in Cohort'
                    }
                ],
                fields: that.getColumns()
            };
        };

        this.getAttrModels = function () {
            var attrModels = [];
            if ($rootScope.userData.platform !== 'full') {
                angular.forEach(angular.copy(userModels), function (model) {
                    if (model.key === 'even') {
                        model.name = 'Marketing Influenced';
                        attrModels.push(model);
                    }
                });
            } else {
                attrModels = angular.copy(userModels);
            }

            influenceTypes.attributionTypeMapping.forEach(function (model) {
                if (model.key !== 'any') {
                    attrModels.push( {
                        key:  model.key,
                        label: model.displayName,
                        name: model.displayName,
                        title: model.displayName,
                        value: model.displayName
                    });
                }
            });

            attrModels.unshift({
                key: 'any',
                label: 'With or Without Influence',
                name: 'With or Without Influence',
                title: 'With or Without Influence',
                value: 'any'
            });

            return attrModels;
        };

        this.initCheck = function (queryType) {
            if (queryType.dateType === "closed" && queryType.closedType === "won") {
                queryType.type = "revenue";
            } else if (queryType.dateType === "closed" && queryType.closedType === "any") {
                queryType.type = "closed";
            } else if (queryType.dateType === "closed" && queryType.closedType === "open") {
                queryType.type = "forecast";
            }
        };

        this.getColumns = function (queryObj) {
            var orgType = $rootScope.userData.platform;
            var orgColumns = [];
            angular.forEach(this.getDefaultColumns(queryObj), function (column) {
                if (column.orgTypes.includes(orgType)) {
                    orgColumns.push(column);
                }
            });
            return orgColumns;
        };

        this.getDefaultColumns = function (queryObj) {
            var allPlatforms = ['full', 'campaign', 'standard'];
            const columns = [
                {
                    field: 'name',
                    orgTypes: allPlatforms,
                    searchable: true,
                    visible: true,
                    displayName: 'Oppt. Name',
                    defaultSort: false,
                    descendingFirst: false,
                    excludeTotal: true,
                    apiType: "oppty",
                    link: function (row) {
                        if ($rootScope.userData.platform !== 'standard') {
                            var opptyState = 'app.analyze.opportunities.opportunitySpecific.attribution.totalTouches';
                            return $rootScope.shref(opptyState, { isId: true, oppty: row.id, model: queryObj });
                        }
                    },
                },
                {
                    field: 'account_name',
                    orgTypes: allPlatforms,
                    searchable: true,
                    displayName: 'Account Name',
                    excludeTotal: true,
                    visible: true,
                    defaultSort: false,
                    descendingFirst: false,
                    apiType: "account",
                    link: function (row) {
                        var accountState = 'app.analyze.accountsAccountSpecific.attribution';
                        return $rootScope.shref(accountState, { accountId: row.account_id });
                    },
                },
                {
                    field: 'owner',
                    orgTypes: allPlatforms,
                    searchable: true,
                    displayName: 'Owner',
                    visible: false,
                    defaultSort: false,
                    excludeTotal: true,
                    descendingFirst: true,
                    apiType: "owner",
                    link: function (row) {
                        return $rootScope.sfdcURL + '/' + row.owner_id;
                    }
                },
                {
                    field: 'revenue',
                    orgTypes: allPlatforms,
                    displayName: 'Amount',
                    tooltip: 'Total Opportunity Amount',
                    cellFilter: 'bfCurrency',
                    visible: true,
                    defaultSort: false,
                    descendingFirst: true,
                    apiType: "oppty",
                },
                {
                    field: 'configured_stage',
                    orgTypes: allPlatforms,
                    searchable: true,
                    displayName: 'Current Stage',
                    visible: true,
                    excludeTotal: true,
                    descendingFirst: false,
                    apiType: "oppty",
                },
                {
                    field: 'created_date',
                    orgTypes: allPlatforms,
                    displayName: 'Created Date',
                    cellFilter: 'date:M/d/yy',
                    visible: true,
                    defaultSort: 'reverse',
                    excludeTotal: true,
                    descendingFirst: false,
                    apiType: "oppty",
                },
                {
                    field: 'close_date',
                    orgTypes: allPlatforms,
                    displayName: 'Close Date',
                    cellFilter: 'date:M/d/yy',
                    visible: true,
                    defaultSort: false,
                    excludeTotal: true,
                    descendingFirst: false,
                    apiType: "oppty",
                },
                {
                    field: 'otd_velocity',
                    orgTypes: allPlatforms,
                    displayName: 'OTD Velocity',
                    cellFilter: 'number',
                    visible: false,
                    defaultSort: false,
                    excludeTotal: false,
                    descendingFirst: false,
                    avgFunction: 'avg', // Average for nun null values
                    apiType: "oppty",
                },
                {
                    field: 'source_campaign_name',
                    orgTypes: allPlatforms,
                    searchable: true,
                    displayName: 'Source Campaign',
                    visible: false,
                    defaultSort: false,
                    excludeTotal: true,
                    descendingFirst: false,
                    apiType: "campaign",
                    link: function (row) {
                        if ($rootScope.userData.platform !== 'standard') {
                            return $rootScope.shref('app.analyze.campaigns.campaignSpecific.attribution.leads', {
                                name: row.source_campaign_name,
                                campaignId: row.source_campaign_id
                            });
                        }
                    },
                },
                {
                    field: 'source_campaign_group',
                    orgTypes: allPlatforms,
                    searchable: true,
                    displayName: 'Source Campaign Type',
                    visible: false,
                    defaultSort: false,
                    excludeTotal: true,
                    descendingFirst: false,
                    apiType: "campaign",
                },
                {
                    field: 'last_campaign_name',
                    orgTypes: allPlatforms,
                    searchable: true,
                    displayName: 'Last Touch Campaign',
                    tooltip: 'before an Opportunity is created',
                    visible: false,
                    defaultSort: false,
                    descendingFirst: false,
                    excludeTotal: true,
                    apiType: "campaign",
                    link: function (row) {
                        if ($rootScope.userData.platform !== 'standard') {
                            return $rootScope.shref('app.analyze.campaigns.campaignSpecific.attribution.leads', {
                                name: row.last_campaign_name,
                                campaignId: row.last_campaign_id
                            });
                        }
                    },
                },
                {
                    field: 'last_touch_campaign_group',
                    orgTypes: allPlatforms,
                    searchable: true,
                    displayName: 'Last Touch Campaign Type',
                    visible: false,
                    defaultSort: false,
                    excludeTotal: true,
                    descendingFirst: false,
                    apiType: "campaign",
                },
                {
                    field: 'unique_campaigns',
                    orgTypes: allPlatforms,
                    displayName: 'Total Campaigns',
                    cellFilter: 'number',
                    tooltip: '# of unique campaigns',
                    visible: false,
                    defaultSort: false,
                    descendingFirst: true,
                    apiType: "oppty",
                },
                {
                    field: 'oppty_contact_count',
                    orgTypes: allPlatforms,
                    displayName: 'People (Oppty.)',
                    cellFilter: 'number',
                    tooltip: "Total # of contacts associated to an opportunity",
                    visible: false,
                    defaultSort: false,
                    descendingFirst: true,
                    apiType: "oppty",
                },
                {
                    field: 'account_contact_count',
                    orgTypes: allPlatforms,
                    displayName: 'People (Acct.)',
                    cellFilter: 'number',
                    tooltip: "Total # of contacts associated to an account",
                    visible: false,
                    defaultSort: false,
                    descendingFirst: true,
                    apiType: "account",
                },
                {
                    field: 'contact_roles_engaged',
                    orgTypes: allPlatforms,
                    displayName: 'Oppty. Respondents',
                    cellFilter: 'number',
                    tooltip: "Total # of contacts associated to an opportunity with marketing activity",
                    visible: false,
                    defaultSort: false,
                    descendingFirst: true,
                    apiType: "oppty",
                },
                {
                    field: 'contact_count_engaged',
                    orgTypes: allPlatforms,
                    displayName: 'Acct. Respondents',
                    cellFilter: 'number',
                    tooltip: "Total # of contacts associated to an account with marketing activity",
                    visible: false,
                    defaultSort: false,
                    descendingFirst: true,
                    apiType: "account",
                },
                {
                    field: 'lead_source',
                    orgTypes: ['full'],
                    displayName: 'Lead Source',
                    visible: false,
                    defaultSort: false,
                    excludeTotal: true,
                    descendingFirst: true,
                    apiType: "oppty",
                },
                {
                    field: 'pre_create_attributions',
                    orgTypes: allPlatforms,
                    displayName: 'Pre-Opp Creation Responses',
                    cellFilter: 'number',
                    tooltip: "Touch occurred before opportunity creation",
                    visible: false,
                    defaultSort: false,
                    excludeTotal: false,
                    descendingFirst: true,
                    apiType: "oppty",
                },
                {
                    field: 'post_create_attributions',
                    orgTypes: allPlatforms,
                    displayName: 'Post-Opp Creation Responses',
                    cellFilter: 'number',
                    tooltip: "Touch occurred after opportunity creation",
                    visible: false,
                    defaultSort: false,
                    excludeTotal: false,
                    descendingFirst: true,
                    apiType: "oppty",
                },
                {
                    field: 'campaign_attributions',
                    orgTypes: allPlatforms,
                    displayName: 'All Responses',
                    cellFilter: 'number',
                    tooltip: "All marketing activity on the opportunity",
                    visible: false,
                    defaultSort: false,
                    descendingFirst: true,
                    excludeTotal: false,
                    apiType: "oppty",
                    totalLink: function (total) {
                        if ($rootScope.userData.platform === 'full') {
                            var opptyState = 'app.analyze.opportunities.allOpptysTouches';
                            var href = $rootScope.shref(opptyState, queryObj);
                            return href ? href + "&total=" + total : null;
                        }
                    },
                },
                {
                    field: 'type',
                    orgTypes: allPlatforms,
                    displayName: 'Opportunity Type',
                    visible: false,
                    defaultSort: false,
                    excludeTotal: true,
                    descendingFirst: false,
                    apiType: "oppty"
                }
            ];
            if (sigstrSrvc.isEnabled()) {
                columns.push(
                    {
                        field: 'company_score',
                        orgTypes: allPlatforms,
                        displayName: 'Relationship Score',
                        tooltip: "",
                        visible: false,
                        defaultSort: false,
                        excludeTotal: true,
                        descendingFirst: true,
                        apiType: "oppty",
                    },
                    {
                        field: 'intent_score',
                        orgTypes: allPlatforms,
                        displayName: 'Propensity to Purchase',
                        cellFilter: 'number',
                        tooltip: "",
                        visible: false,
                        defaultSort: false,
                        excludeTotal: true,
                        descendingFirst: true,
                        apiType: "oppty",
                    },
                    {
                        field: 'first_email',
                        orgTypes: allPlatforms,
                        displayName: 'Sigstr First Email',
                        cellFilter: 'date:M/d/yy',
                        tooltip: "",
                        visible: false,
                        defaultSort: false,
                        excludeTotal: true,
                        descendingFirst: true,
                        apiType: "oppty",
                    },
                    {
                        field: 'last_email',
                        orgTypes: allPlatforms,
                        displayName: 'Sigstr Last Email',
                        cellFilter: 'date:M/d/yy',
                        tooltip: "",
                        visible: false,
                        defaultSort: false,
                        excludeTotal: true,
                        descendingFirst: true,
                        apiType: "oppty",
                    }
                );
            }

            return columns;
        };

        this.normalizeWidgetData = function (data, model, field, parentKey) {
            var structuredData = { data: {} };
            structuredData.data[parentKey] = {};
            data.forEach(function (oppty) {
                var key = oppty.id;
                structuredData.data[parentKey][key] = { 'oppty': {} };
                if (model) {
                    structuredData.data[parentKey][key].oppty = oppty;
                    structuredData.data[parentKey][key].oppty[model] = {};
                    structuredData.data[parentKey][key].oppty[model][field] = oppty[field];
                    delete structuredData.data[parentKey][key].oppty[field];
                }
            });
            return structuredData;
        };
    }
]);
