/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
app.controller('navCtrl', ['$scope', '$state', '$filter', '$rootScope', '$timeout', '$b', 'api', '$cookies', 'utilities', '$localStorage', 'widgets', 'navigation', '$location', 'exportService', '_', function ($scope, $state, $filter, $rootScope, $timeout, $b, api, $cookies, utilities, $storage, widgets, navigation, $location, exportService, _) {
    $scope.init = false;
    $scope.cookies = $cookies;
    $scope.fromJson = angular.fromJson;
    //$scope.dashboards = angular.findWhere($scope.subnav,{name:'app.dashboard'});
    $scope.widgets = widgets;
    //$scope.dashboards.children = widgets.saved();

    if (!$storage.history) {
        $storage.history = {
            version: $rootScope.version
        };
    }

    if (!$storage.history[$scope.userData.organizationId]) {
        $storage.history[$scope.userData.organizationId] = {};
    }

    $scope.extractName = navigation.extractName;
    $scope.setNavPermission = navigation.setNavPermission;
    $scope.target = function (state) {
        var name = state.name;
        if (state.tabs) {
            var defaultTab = angular.findWhere(state.tabs, function (t) {
                return t.config.defaultTab && t.config.defaultTab === true;
            });
            name = defaultTab ? defaultTab.name : state.tabs[0].name;
        }
        return name;
    };

    $scope.className = navigation.className;

    $scope.tabIconClass = function (tabName) {
        if (tabName == "Trending") {
            return "benchmarking";
        } else {
            return tabName.toLowerCase();
        }
    };
    $scope.exportDashboard = function () {
        exportService.expFunc();
    };

    $scope.ago = function () {
        return moment(new Date($rootScope.last_updated_date.last_update)).fromNow();
    };

    $scope.dateComplex = function () {
        return moment(new Date($rootScope.last_updated_date.last_update)).format("dddd @ h:mm a");
    };

    widgets.getDashboards().then(function (data) {
        $scope.dashboards = widgets.dashboards();
    });

    $scope.getDashboard = function () {
        $scope.dashboards = widgets.dashboards();
    };

    $scope.dragControlListeners = {
        orderChanged: function (event) {
            widgets.setDashboards();
        }
    };

    $scope.removeDisabled = navigation.removeDisabled;

    $scope.toggleMenu = function () {
        $rootScope.menu.slide = false;
        $rootScope.menu.sliding = true;
        $rootScope.menu.collapsed = !$rootScope.menu.collapsed;
        if ($rootScope.menu.collapsed) {
            collapseSelected();
        } else {
            expandSelected();
        }

        $timeout(function () {
            $rootScope.navToggled(true);
        }, 500);

        $timeout(function () {
            $rootScope.menu.sliding = false;
        }, 250);
    };

    $scope.navSlideOut = function () {
        if ($rootScope.menu.collapsed) {
            $rootScope.menu.slide = true;
            expandSelected();
        }
    };

    $scope.navSlideIn = function () {
        if ($rootScope.menu.collapsed && !$rootScope.menu.sliding) {
            $rootScope.menu.slide = false;
            collapseSelected();
        }
    };

    $scope.active = navigation.active;

    $scope.upgrade = function (state) {
        if (state.data.upgradeMessage) {
            $b.alert(state.data.upgradeMessage, state.data.title);
        }
        else {
            $b.alert('<i class="icon-ban space-right"></i>Would you like to upgrade to see ' + state.data.title + '?  Email <a href="mailto:support@terminus.com">support@terminus.com</a> and let\'s talk about it.');
        }
    };

    $scope.notAllowed = function () {
        $b.alert('<i class="icon-ban space-right"></i> You do not have permission to view this page');
    };

    function collapseSelected() {
        var selected = angular.findWhere($scope.subnav, function (state) {
            return state.config && state.config.data && state.config.data.open;
        });
        if (selected)
            selected.config.data.open = false;
    }

    function expandSelected() {
        var target = angular.findWhere($scope.subnav, function (state) {
            return state.config.abstract && $state.current.name.indexOf(state.name) != -1;
        });
        if (target)
            target.config.data.open = true;
    }

    function checkMobile(e, data) {
        if (!$rootScope.mobile && data.width < 1024) {
            $rootScope.mobile = true;
            $scope.toggleMenu();
        } else if ($rootScope.mobile && data.width >= 1024) {
            $rootScope.mobile = false;
            $scope.toggleMenu();
        }
    }

    if (!$scope.ipad) {
        $scope.$on('$windowResize', checkMobile);
        checkMobile(null, { width: $(window).width() });
    } else {
        $rootScope.mobile = true;
        $scope.$on('navSwiped', function (e, args) {
            if (args.open) {
                expandSelected();
            } else {
                collapseSelected();
            }
        });
    }

    $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        if (toState.data.disabledPage) {
            event.preventDefault();
            $scope.upgrade(toState);
        }
        if (toState.status == 'url') {
            event.preventDefault();
            $scope.notAllowed();
        }

        if (toState.redirectTo) {
            event.preventDefault();
            $timeout(function () {
                $state.go(toState.redirectTo, toParams, { location: 'replace' });
            }, 0, false);
        }
    });

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        var selected = angular.findWhere($scope.subnav, function (state) {
            return state.config && state.config.data && state.config.data.open;
        });

        if (fromState && fromState.data && fromState.data.erasePrevious) {
            delete fromState.data.erasePrevious;
        }

        if (selected && $state.is('app.dashboard')) {
            selected.config.data.open = false;
            $state.current.data.open = true;
        } else if (selected && $state.includes(selected.name)) {
            selected.config.data.open = true;
        } else {
            var target = angular.findWhere($scope.subnav, function (state) {
                return state.config.abstract && toState.name.indexOf(state.name) != -1;
            });

            if (target) {
                if (!target.config.data)
                    target.config.data = {};

                if (!$scope.init && $scope.ipad || !$scope.init && $rootScope.mobile) {
                    target.config.data.open = false;
                } else {
                    target.config.data.open = true;
                }
            } else if ($state.current.name == 'app.dashboard') {
                $state.current.data.open = true;
            }
        }

        $scope.init = true;
        //for mouseflow
        if (_mfq) {
            window._mfq.push(["newPageView", $location.url()]);
        }
    });
}]);


app.controller('tabCtrl', ['$scope', '_', '$state', function ($scope, _, $state) {
    $scope.tabIconClass = function (tabName) {
        if (tabName == "Trending") {
            return "benchmarking";
        } else {
            return tabName.toLowerCase();
        }
    };

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        if ($state.current.data.tabSet) {
            _.forEach($state.current.data.tabSet, function (tab) {
                $scope.setUrl($state.get(tab.name));
            });
            $rootScope.$broadcast('scrollpointShouldReset');
        }
    });
}]);
