app.config(['$urlRouterProvider', '$urlMatcherFactoryProvider', 'stConfig', 'uibDatepickerConfig',
    'uibDatepickerPopupConfig', '$provide', 'hotkeysProvider', '$httpProvider', '_',
    function ($urlRouterProvider, $urlMatcherFactory, stConfig,
        datepickerConfig, datepickerPopupConfig, $provide, hotkeysProvider, $httpProvider, _) {
        'use strict';

        $httpProvider.defaults.useXDomain = true;
        delete $httpProvider.defaults.headers.common['X-Requested-With'];

        $urlRouterProvider.deferIntercept();

        hotkeysProvider.includeCheatSheet = false;

        datepickerConfig.startingDay = 1;
        datepickerPopupConfig.datepickerPopup = 'M/d/yyyy';

        stConfig.pagination.template = 'pagination.html';
        stConfig.pagination.itemsByPage = 5;
        stConfig.sort.ascentClass = 'icon-sort-up';
        stConfig.sort.descentClass = 'icon-sort-down';
        stConfig.sort.skipNatural = true;

        //------------------------ extend $state ------------------------//

        $provide.decorator('$state', function ($delegate, $stateParams) {
            $delegate.reloadState = function () {
                return $delegate.go($delegate.current, $stateParams, {
                    reload: true,
                    inherit: false,
                    notify: true
                });
            };
            return $delegate;
        });

        $provide.decorator('dateFilter', function ($delegate) {
            return function (date, format, timezone) {
                var tz = timezone ? (timezone === "'local" ? null : timezone) : 'UTC';
                return $delegate.call(this, date, format, tz);
            };
        });

        $provide.decorator('$state', function ($delegate, noty) {
            $delegate.resetState = _.debounce(function () {
                noty.growl('Something was out of sync and we needed to reset this page.', 'information');
                return $delegate.go($delegate.current, {}, {
                    reload: true,
                    inherit: false,
                    notify: true
                });
            }, 5000, {
                leading: true,
                trailing: false
            });
            return $delegate;
        });

        $urlMatcherFactory.type('array', {
            name: 'array',
            decode: function (val) {
                if (angular.isArray(val)) {
                    if (angular.isArray(val[0])) {
                        return val[0][0];
                    }
                    return val[0];
                }
                return [val];
            },
            encode: function (val) {
                var v = angular.isArray(val) ? val : [val];
                return v.join(',');
            },
            equals: function () {
                return true;
            },
            is: function () {
                return true;
            },
            pattern: /\d+/
        });
    }]);

//------------------------ this keeps the current controller form reloading every time you change a query param on the url ------------------------//
app.run(['$rootScope', '$location', '$http', '$state', '$timeout',
    'editableOptions', 'editableThemes', '$urlRouter', 'utilities',
    '$localStorage', '$window', '_', 'routes', 'noty', 'isFeatureEnabled',
    'environmentSrvc', 'terminusHubService', 'customerEntitlementService',
    function ($rootScope, $location, $http, $state, $timeout,
        editableOptions, editableThemes,
        $urlRouter, utilities, $storage, $window,
        _, routes, noty, isFeatureEnabled,
        environmentSrvc, terminusHubService, customerEntitlementService) {
        'use strict';

        $rootScope.boot = function() {

            $rootScope.goToTerminusHub = terminusHubService.goToTerminusHub;
            $rootScope.goToNativeTerminusHubReport = terminusHubService.goToNativeTerminusHubReport;
            $rootScope.goToScorecardTrendingKpi = terminusHubService.goToScorecardTrendingKpi;

            $rootScope.numPendingRequests = function () {
                var count = 0;
                angular.forEach($rootScope.requestStatus, function (v) {
                    if (v) {
                        count += 1;
                    }
                });
                return count;
            };

            $rootScope.shouldDisplayDashboardButton = function (platform, currentState) {
                if (currentState === 'app.analyze.accountsAccountSpecific.accountJourney') {
                    return false;
                }

                var shouldDisplay = false;

                if (!currentState.includes('app.settings') && !currentState.includes('app.dashboard') && !currentState.includes('app.analyze.opportunities.opportunitySpecific')) {
                    if (platform === 'standard') {
                        shouldDisplay = currentState !== 'app.analyze.accountsAccountSpecific.attribution' && !currentState.includes('app.analyze.campaigns.campaignSpecific');
                    } else if (platform === 'campaign') {
                        shouldDisplay = currentState !== 'app.analyze.accountsAccountSpecific.attribution';
                    } else {
                        shouldDisplay = true;
                    }
                }

                return shouldDisplay;
            };

            // table cell can be 'number', 'date', 'bfCurrency', 'percentage', 'number:xx' or something else
            $rootScope.getStyleForTableCell = function (filter) {
                if (filter === 'bfCurrency') {
                    filter = 'currency';
                }

                return filter ? filter.split(':')[0] : '';
            };

            routes.initialize().then(function () {
                //------------------------ this keeps the current controller form reloading every time you change a query param on the url ------------------------//

                $rootScope.$on('$locationChangeSuccess', function (e) {
                    // Prevent $urlRouter's default handler from firing
                    e.preventDefault();

                    if (utilities.urlListen()) {
                        $urlRouter.sync();
                    }
                });

                // Configures $urlRouter's listener *after* your custom listener
                $urlRouter.listen();

                $rootScope.localStorage = $storage;
                //------------------------ fullstory ------------------------//

                function initFullstory() {
                    var uid = 'hub-' + $rootScope.userData.secId,
                        userVars = {
                            email: $rootScope.userData.email,
                            orgUuid: $rootScope.userData.organizationUuid
                        };

                    FS.identify(uid, userVars);
                }

                if ($rootScope.userData.id !== 0 && $rootScope.userData.organizationId !== 13 && window.bfEnvironment === 'PRODUCTION') {
                    initFullstory();
                }
                //---------------------- end fullstory ----------------------//
                //---------------------- gainsight ----------------------//
                function initGainsight(unifiedCustomerId) {
                    aptrinsic('identify',
                        {
                            'id': $rootScope.userData.email,
                            'email': $rootScope.userData.email,
                        },
                        {
                            'id': unifiedCustomerId,
                        }
                    );

                }
                const isImpOrg = $rootScope.userData.username === `org${$rootScope.userData.organizationId}`;

                if (!isImpOrg && $rootScope.userData.id !== 0 && $rootScope.userData.organizationId !== 13) {
                    customerEntitlementService.authorize().then(function (jwt) {
                        if (jwt.token) {
                            const couType = 'BF_ORG';
                            customerEntitlementService.getClosestUnifiedCustomerIdForCOU({
                                customerOrganizationalUnit: {
                                    type: couType,
                                    id: $rootScope.userData.organizationUuid,
                                },
                            }).then(function (res) {
                                initGainsight(res.closestUnifiedCustomerId);
                            });
                        }
                    }).catch(function () { });
                }
                //---------------------- end gainsight ----------------------//
                //

                $rootScope.enableLink = utilities.urlListen();

                // hack until we can do this the right way
                function loadAvatar(url) {
                    $http.get(url).then(function () {
                        $rootScope.userData.avatar = url;
                        $rootScope.$broadcast('$$rebind::userDataChanged');
                    }, function () {
                        $rootScope.userData.avatar = false;
                        $rootScope.$broadcast('$$rebind::userDataChanged');
                    });
                }

                loadAvatar('/images/logos/org' + $rootScope.userData.organizationId + '.png');

                $rootScope.$window = $window;
                $rootScope.stateUrl = utilities.stateUrl;
                $rootScope.shref = utilities.href;
                $rootScope.location = $location;

                $rootScope.$state = $state;
                $rootScope.loading = {
                    state: false
                };

                $rootScope.broadcastScroll = _.throttle(function () {
                    $rootScope.$evalAsync(function () {
                        $rootScope.$broadcast('$contentScrolling');
                    });
                }, 1, { 'trailing': true });

                $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
                    //------------------------ this will apply any param transform functions in state.data ------------------------//
                    if (toState.data.paramTransform && angular.isObject(toState.data.paramTransform)) {
                        angular.forEach(toState.data.paramTransform, function (transform, param) {
                            if (toParams[param] && angular.isFunction(transform)) {
                                toParams[param] = transform(toParams[param]);
                            }
                        });
                    }
                    $rootScope.loading.state = true;
                });

                ////// ANALYTICS //////
                $rootScope.$on('$stateChangeSuccess', function (_, toState) {
                    if (toState.name === 'app.analyze.accounts.attribution') {
                        terminusHubService.getTerminusHubUrlByReportName(
                            'app.analyze.accounts.attribution',
                            'data'
                        );
                    } else if (toState.name === 'app.dashboard') {
                        terminusHubService.getTerminusHubUrlByReportName(
                            'app.dashboard'
                        );
                    }

                    $timeout(function () {
                        $rootScope.loading.state = false;
                    }, 0, false);
                });

                ////// GLOBAL HELPER FUNCTIONS //////
                ///
                $rootScope.scrollShadow = function (distance) {
                    if (distance >= 0) {
                        $('#top-right').addClass('scrolled');
                    } else {
                        $('#top-right').removeClass('scrolled');
                    }
                    //$rootScope.scrolledShadow = distance;
                };

                $rootScope.navToggled = function (broadcast) {
                    $rootScope.menu.sliding = false;
                    if (broadcast) {
                        $rootScope.$broadcast('navToggled');
                    }
                };

                $rootScope.mobileCheck = function (width) {
                    if (!$rootScope.menu.collapsed && !$rootScope.menu.slide && (parseInt(width, 10) === 55)) {
                        $rootScope.menu.mobileCollapsed = true;
                    }
                };

                $rootScope.mobileSlide = function (direction) {
                    $rootScope.$broadcast('navSwiped', { open: direction });
                    $rootScope.menu.slide = direction;
                };

                $rootScope.shouldShowTab = function (tab) {
                    return isFeatureEnabled(tab.data.featureFlag) && !tab.data.hiddenPage && (tab.data.permissionLevel ? $rootScope.userData.permissions[tab.data.permissionLevel] : true);
                };

                // table cell can be 'number', 'date', 'bfCurrency', 'percentage', 'number:xx' or something else
                $rootScope.getStyleForTableCell = function (filter) {
                    if (filter === 'bfCurrency') {
                        filter = 'currency';
                    }

                    return filter ? filter.split(':')[0] : '';
                };


                $rootScope.shouldDisplayDashboardButton = function (platform, currentState) {
                    if (currentState === 'app.analyze.accountsAccountSpecific.accountJourney') {
                        return false;
                    }

                    var shouldDisplay = false,
                        ignoreStateList = [
                            'analyze.accounts.trending',
                            'analyze.accountsAccountSpecific.trending',
                            'analyze.opportunities.opportunitySpecific',
                            'app.analyze.campaigns.attributionByQuarter',
                            'discover.revenueAndPipeline.attributionByQuarter',
                            'campaigns.campaignSpecific.attribution.leads',
                            'campaigns.campaignSpecific.sfdc',
                            'campaigns.campaignSpecific.trending',
                            'dashboard',
                            'revenueAndPipeline.attributionTrends',
                            'settings',
                            'stageProgression.attribution',
                            'stageProgression.trending',
                            'webTracking.channelAssetSpecific.attribution.leads',
                            'webTracking.webActivity.channelMedium'
                        ],
                        stateHandler = function (state) {
                            return currentState.includes(state);
                        };

                    if (!ignoreStateList.some(stateHandler)) {
                        if (platform === 'standard') {
                            shouldDisplay = currentState !== 'app.analyze.accountsAccountSpecific.attribution' && !currentState.includes('app.analyze.campaigns.campaignSpecific');
                        } else if (platform === 'campaign') {
                            shouldDisplay = currentState !== 'app.analyze.accountsAccountSpecific.attribution';
                        } else {
                            shouldDisplay = true;
                        }
                    }

                    return shouldDisplay;
                };

                $rootScope.setUrl = function (s) {
                    if (s && s.data) {
                        s.data.navUrl = s.redirectTo ? utilities.stateUrl(s.redirectTo) : utilities.stateUrl(s.name);
                    }
                };

                editableThemes.bs3.inputClass = 'input-sm';
                editableThemes.bs3.buttonsClass = 'btn-sm';
                editableOptions.theme = 'bs3';
            }, function (error) {
                if (error.status === 405) {
                    if (error.data.url) {
                        window.location.href = error.data.url;
                    } else {
                        noty.growl(error.data.error, 'error');
                    }
                } else if (error.status !== 401) {
                    noty.growl(error.data, 'error');
                }
            });
        };

        environmentSrvc.init().then((env) => {
            if(!env) return;
            $rootScope.boot();
        });
    }]);

app.value('$strap.config', {
    datepicker: {
        language: 'en',
        format: 'd M, yyyy'
    }
});
