app.service('revAttribution', [function () {
    this.process_campaign_performance_data = function (data, query) {
        var processed = [];
        angular.forEach(data.groups, function (group, group_name) {
            var cur = {};
            cur.type = group_name;
            cur.label = group.summary.revenue_group;
            var summary = group.summary;

            if (query.dataSet == "opptyCreatedDate") {
                //Pipeline
                cur.sourced_p_revenue = summary.sourced.pipeline;
                cur.sourced_p_opptys = summary.sourced.opptys;
                cur.ewi_p_revenue = summary.even.pipeline;
                cur.ewi_p_opptys = summary.even.opptys;
                cur.ci_p_revenue = summary.custom.pipeline;
                cur.la_p_revenue = summary.last.pipeline;
                cur.ti_p_opptys = summary.custom.opptys;
            } else {
                //Sourced
                cur.sourced_revenue = summary.sourced.revenue;
                cur.sourced_deals = summary.sourced.deals;
                cur.ewi_revenue = summary.even.revenue;
                cur.ewi_deals = summary.even.deals;
                cur.ci_revenue = summary.custom.revenue;
                cur.la_opptys = summary.last.opptys;
                cur.la_revenue = summary.last.revenue;
                cur.la_deals = summary.last.deals;
                cur.ti_deals = summary.custom.deals;
            }
            processed.push(cur);
        });
        return processed;
    };
}]);
