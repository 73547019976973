/**
 * Fetch data from oauth endpoints.
 */
(function () {
    'use strict';

    angular
        .module('sfdc')
        .factory('sfdcSrvc', sfdcOauthSrvc);

    sfdcOauthSrvc.$inject = ['$http', 'SfdcConstants'];

    /**
     * Service to post data about the user to impersonate.
     * @param $http The http request
     * @param $q The $q service
     * @param SwitchUserConstants Constant to use in the service
     * @return {switchUserSrvc}
     */
    function sfdcOauthSrvc($http, SfdcConstants) {
        var service = this;

        /**
         * Retrieve the list of organizations in the database.
         * @returns {Promise<Object>} A promise which resolves to the json to display.
         *  If an error occurs a {string} with the error is sent in the rejection.
         */
        service.getOrganizationsList = function() {
            return $http.get(SfdcConstants.API.GET_ORGANIZATIONS);
        };

        /**
         * Retrieve the indentity for the current organization.
         * @param {string} The orgId to use in the post body
         * @returns {Promise<Object>} A promise which resolves to the json to display.
         *  If an error occurs a {string} with the error is sent in the rejection.
         */
        service.postIdentify = function(orgId) {
            return $http.post(SfdcConstants.API.IDENTIFY, {
                orgid: orgId
            });
        };

        /**
         * Sends a post to the Show Row method with the orgId and tablename to use.
         * @param {string} The orgId to use in the post body
         * @param {string} The tableName to use in the post body
         * @param {string} The id to use in the post body
         * @returns {Promise<Object>} A promise which resolves to the json to display.
         *  If an error occurs a {string} with the error is sent in the rejection.
         */
        service.postShowRow = function(orgId, tableName, id) {
            return $http.post(SfdcConstants.API.SHOW_ROW, {
                orgid: orgId,
                table: tableName,
                id: id
            });
        };

        /**
         * Sends a post to the Describe Fields method  with the orgId and tablename to use.
         * @param {string} The orgId to use in the post body
         * @param {string} The tableName to use in the post body
         * @returns {Promise<Object>} A promise which resolves to the json to display.
         *  If an error occurs a {string} with the error is sent in the rejection.
         */
        service.postDescribeFields = function(orgId, tableName) {
            return $http.post(SfdcConstants.API.DESCRIBE_FIELDS, {
                orgid: orgId,
                table: tableName
            });
        };

        /**
         * Sends a post to the Describe Table method with the orgId and tablename to use.
         * @param {string} The orgId to use in the post body
         * @param {string} The tableName to use in the post body
         * @returns {Promise<Object>} A promise which resolves to the json to display.
         *  If an error occurs a {string} with the error is sent in the rejection.
         */
        service.postDescribeTable = function(orgId, tableName) {
            return $http.post(SfdcConstants.API.DESCRIBE_TABLE, {
                orgid: orgId,
                table: tableName
            });
        };

        /**
         * Get a user info for the current organization.
         * @param {string} The orgId to use in the post body
         * @returns {Promise<Object>} A promise which resolves to the json to display.
         *  If an error occurs a {string} with the error is sent in the rejection.
         */
        service.postUserInfo = function(orgId) {
            return $http.post(SfdcConstants.API.USER_INFO, {
                orgid: orgId
            });
        };

        return service;
    }
})();
