app.controller("rcbqWidgetCtrl", [
    "$scope",
    "$state",
    "api",
    "utilities",
    "$filter",
    "$rootScope",
    "models",
    "$parse",
    "_",
    "cohorts",
    "revAttribution",
    "personaDefaults",
    "widgets",
    "campaignPerformance",
    function(
        $scope,
        $state,
        api,
        utilities,
        $filter,
        $rootScope,
        models,
        $parse,
        _,
        cohorts,
        revAttribution,
        personaDefaults,
        widgets,
        campaignPerformance
    ) {
        var $tile = $scope.$tile,
            dataCall;

        $scope.rowValue = function(field, s) {
            return $parse(field)(s);
        };

        $scope.currentField = function() {
            var key =
                "ranged." +
                $scope.widget.query.model +
                "." +
                $scope.widget.query.revenueType;
            return _.find($scope.data.fields, { field: key });
        };

        $scope.revenue_type_changed = function() {
            $scope.widget.query.dataSet =
                $scope.widget.query.revenueType == "pipeline"
                    ? "opptyCreatedDate"
                    : "opptyCloseDate";
            formatData();
        };

        $scope.model_changed = function() {
            formatData();
        };

        this.getData = function() {
            if (dataCall) {
                dataCall.abort();
            }

            (dataCall = api.getter({
                url: 'campaign_performance',
                params: angular.copy($scope.widget.query),
                paramsNotToUpdate: 'all',
                skipFilters: true,
            })).then(
                function (data) {
                    $scope.unformattedData = angular.copy(data);
                    formatData();
                    $scope.widget.data.lastQuery = angular.copy(
                        $scope.widget.query
                    );

                    $tile.loading(100);
                },
                function(data) {
                    if (!data.aborted) {
                        $tile.failedLoading();
                    }
                }
            ).finally(
                function () {
                    if ($tile.isLoading()) {
                        $tile.endLoading();
                    }
                    $scope.$emit("widgetLoadingDone");
                }
            );
        };

        function formatData() {
            var currentField = $scope.currentField(),
                formattedData = widgets.formatWidgetListData(
                    $scope.unformattedData,
                    currentField,
                    "groups",
                    $scope,
                    undefined,
                    $scope.widget.query.model
                );
            $scope.sortedData = widgets.sortListData(
                formattedData,
                "value"
            );
            $scope.data.data = widgets.numberOfTopNResults(
                $scope.sortedData,
                $scope.widget
            );
            $scope.data.total = _.sumBy(formattedData, function(o) {
                return o.value;
            });
        }

        (function init(ctrl) {
            $tile.startLoading();
            $tile.loading(10);
            //------------------------ not saved ------------------------//
            $scope.data = {
                cohorts: utilities.formatCohorts(cohorts, [
                    "year",
                    "quarter",
                    "ago",
                    "toDate",
                    "lastFull"
                ]),
                models: models,
                filter: "bfCurrency"
            };
            $scope.data.fields = $filter("filter")(
                campaignPerformance.headers(
                    $scope,
                    $state,
                    $scope.widget.webActivity
                ),
                function(field) {
                    return field.revAttributionField;
                }
            );
            $scope._ = _;

            //------------------------ saved ------------------------//
            $scope.widget.data = _.defaults(
                $scope.widget.data,
                personaDefaults.getQueryDefinitions($scope.widget).data
            );
            const params = _.defaults(
                $scope.widget.query,
                personaDefaults.getQueryDefinitions($scope.widget).query
            );
            $scope.widget.query = widgets.addFilters(params, $scope.widget.data.filters, $scope.widget.data.accountLists);
            $scope.widget.query.dataSet =
                $scope.widget.query.revenueType == "pipeline"
                    ? "opptyCreatedDate"
                    : "opptyCloseDate";

            $scope.widget.data.models = models;

            if (
                !_.isDefined(
                    _.find($scope.data.models, {
                        key: $scope.widget.query.model
                    })
                )
            ) {
                $scope.widget.query.model = $scope.data.models[0].key;
            }

            if (
                !_.isDefined(
                    _.find($scope.data.models, {
                        key: $scope.widget.data.model
                    })
                )
            ) {
                $scope.widget.data.model = $scope.data.models[0].key;
            }

            $tile.listeners.push(
                $scope.$on("widgetUpdate", function() {
                    if (
                        !angular.equals(
                            angular.copy($scope.widget.query),
                            $scope.widget.data.lastQuery
                        )
                    ) {
                        $tile.startLoading();
                        $tile.loading(10);
                        ctrl.getData();
                    }
                })
            );

            $scope.$on("updateListResults", function() {
                $scope.data.data = widgets.numberOfTopNResults($scope.sortedData, $scope.widget);
            });

            $scope.$on("$destroy", function() {
                if (dataCall) {
                    dataCall.abort();
                }
            });
        })(this);
    }
]);
