(function () {
    'use strict';

    app.directive('labelDiv', function () {
        return {
            restrict: 'E',
            template: require('./label-div.html'),
            controller: labelCtrl,
            controllerAs: "$ctrl",
            bindToController: true,
            scope: {
                labelText: '='
            },
            transclude: true
        };
    });

    labelCtrl.$inject = [];

    /**
     * Controller for the directive.
     */
    function labelCtrl() { }

})();
