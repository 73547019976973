app.controller('flatStageProgressCtrl', ['$scope', 'utilities', '$state', 'api', '$rootScope', '_', 'cohorts', '$templateCache', '$compile', '$timeout', 'revenueWaterfall', function ($scope, utilities, $state, api, $rootScope, _, cohorts, $templateCache, $compile, $timeout, revenueWaterfall) {
    var dataCall;

    function loadStageProgress() {
        if (dataCall) { dataCall.abort(); }
        $state.current.data.loading = true;
        (dataCall = api.getter({url:"flat_stage_progression", params:$scope.query})).then(function (data) {
            revenueWaterfall.formatData(data, $scope);
        });
    }

    function generateLinks(data) {
        if (data) {
            angular.forEach(data, function (stage) {
                if (stage.is_lead) {
                    stage.link = $scope.sfdcURL + '/' + stage.id;
                } else {
                    var state = 'app.analyze.opportunities.opportunitySpecific.attribution.totalTouches';
                    stage.link = $scope.shref(state, { oppty: stage.id, isId: true });
                }
            });
        }
    }

    $scope.getDetails = function (stage) {
        if (!stage.items) {
            stage.loading = true;
            var params = angular.copy($scope.query);
            params.stage = stage.name;
            (dataCall = api.getter({url: "flat_stage_progression_detail", params:params, paramsNotToUpdate:"all"})).then(function (data) {
                stage.items = data.data;
                generateLinks(stage.items);
                stage.loading = false;
            });
        }
    };

    $scope.barStyles = function (stage, i) {
        var w = (stage.count / $scope.data.largest) * 100;
        return { width: w + '%', backgroundColor: $scope.data.colors(i) };
    };

    $scope.runAllQuery = function () {
        loadStageProgress();
    };

    (function init() {
        $scope.select_options = utilities.formatCohorts(cohorts, ['all', 'custom', 'ago', 'year', 'quarter', 'toDate', 'lastFull']);
        $scope.query = _.defaults($state.params, {
            cohort: "quarter2Date",
            startDate: moment(new Date()).subtract(1, 'week')._d.getTime(),
            endDate: new Date().getTime(),
        });

        $scope.data = {
            stages: [],
            largest: 0,
        };
        $scope.data.tScope = $scope.$new();
        $scope.data.tooltip = $compile($templateCache.get('chartTooltip.html'))($scope.data.tScope);

        $scope.$on('$destroy', function () {
            if (dataCall) { dataCall.abort(); }
        });

        $scope.chart = revenueWaterfall.getChart($scope);

        $scope.$on('filtersChanged', function () {
            loadStageProgress();
        });

        loadStageProgress();
    })();
}]);
