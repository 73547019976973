/**
 * Controller for the header directive.
 */
(function () {
    'use strict';

    angular
        .module('bombora')
        .directive('headerTopics', function () {
            return {
                bindToController: true,
                template: require('./header.html'),
                controller: headerCtrl,
                controllerAs: "$ctrl",
                scope: {
                    subheader: '='
                }
            };
        });

    headerCtrl.$inject = ['$scope'];

    /**
     * Controller for the directive.
     */
    function headerCtrl($scope) {
        var ctrl = this;

        /**
         * Detect changes to the directive bindings
         */
        $scope.$watch('$ctrl.subtitle', function(value) {
            if (value) {
                ctrl.subheader = value;
            }
        });
    }
})();
