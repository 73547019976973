app.controller('campaignAttributionCtrl', ['$scope', '$b', 'api', 'noty', '$state', '$rootScope', '$filter', 'utilities', '_', 'filters', 'campaignPerformance', 'cohorts', '$q', '$timeout', 'userModels', 'trend', 'campaign', '$localStorage', 'colorMap', '$stateParams', 'navigation', 'influenceTypes', function ($scope, $b, api, noty, $state, $rootScope, $filter, utilities, _, filters, campaignPerformance, cohorts, $q, $timeout, userModels, trend, campaign, $localStorage, colorMap, $stateParams, navigation, influenceTypes) {
    $state.current.data.loading = true;
    $scope.limit = 9;

    $scope.useNewInfluenceType = influenceTypes.useNewInfluenceType;

    var getCampaignCall, getGroupCall, getStagesCall, getWebTrackingCall, costCall;
    $scope.adjustCost = function () {
        var parent = $scope;
        $b.modal({
            title: 'Adjust Campaign Cost',
            id: 'adjust-cost',
            templateUrl: 'adjustCost.html',
            width: '400px',
            buttons: [{
                'html': 'adjust',
                'ng-disabled': 'data.cost < 0 || loading',
                'ng-click': 'adjust()',
                'class': 'btn btn-sm btn-primary pull-right'
            }, {
                'html': 'cancel',
                'ng-click': '$modal.closeModal()'
            }],
            x: true,
            maxed: false,
            controller: function ($scope) {
                $scope.data = {
                    cost: Number(parent.data.data.campaign.cost),
                    campaignId: parent.data.data.campaign.campaign_id
                };
                $scope.loading = false;

                $scope.adjust = function () {
                    $scope.loading = true;
                    var params = { costs: [parent.data.data.campaign.campaign_id + ":" + $scope.data.cost] };
                    api.set('update_campaign_cost', params).success(function (data) {
                        // eslint-disable-next-line no-unused-vars
                        api.set('flush_campaign_performance_cache?nocache=' + $.now()).success(function (d) {
                            $rootScope.$broadcast('trackAction', "cost updated");
                            noty.growl(data.message, 'information');
                            parent.data.data.campaign.cost = $scope.data.cost;
                            parent.calc.reformat(parent.data.data);
                            parent.calc.general(parent.data.data, $scope.platform);
                            parent.makeInfoTab();
                            if (!$localStorage.apiCache) {
                                $localStorage.apiCache = {};
                            }
                            $localStorage.apiCache[$scope.userData.organizationId] = {}; //clear frontend cache
                            $scope.$modal.closeModal();
                            $scope.loading = false;
                        });
                    });
                };
            }
        });
    };

    $scope.isOpen = function (section) {
        section.open = false;
        if ($scope.query.modelType === 'custom' && section.i === 3) {
            section.open = true;
        } else if ($scope.query.modelType !== 'custom' && section.i === 1) {
            section.open = true;
        }
    };

    $scope.runAllQuery = function () {
        getCampaign();
    };

    $scope.setTab = function (type) {
        $scope.query.tab = type;
        utilities.queryString({ tab: type });
    };

    $scope.getOpptyIdByDeal = function (deal) {
        var oppty = _.find($scope.data.data.opptys, { oppty_id: deal.oppty_id });

        // TODO: Do we still need the following logic?
        return oppty ? { oppty: oppty.oppty_id, isId: true } : { oppty: deal.oppty_name, isId: false };
    };

    $scope.useTypeFilteredAttr = influenceTypes.useTypeFilteredAttr;

    $scope.typeAttrModelFilter = influenceTypes.typeAttrModelFilter;

    function getWebTrackingData() {
        if (getWebTrackingCall) { getWebTrackingCall.abort(); }
        var apiUrl = $state.current.data.pageType === 'channel-asset-specific' ? 'campaign_touches' : 'channel_touches';
        (getWebTrackingCall = api.getter({ url: apiUrl, params: $scope.query, paramsNotToUpdate: "all", noPollMessage: true })).then(function (data) {
            if (data.data.touches.length) {
                $scope.data.webTrackingData = data.data.touches;
                $scope.data.data.campaign.is_expandable = true;
                makeMap();
                $scope.$broadcast('$$rebind::refreshData');
            } else {
                $scope.data.data.campaign.is_expandable = false;
            }
        });
    }

    function makeMap() {
        _.forEach($scope.data.data.leads, function (lead) {
            var leadTouches = _.uniqBy(_.filter($scope.data.webTrackingData, { lead_id: lead.id }), 'touch.id'),
                n = leadTouches.length;

            for (var i = 0; i < n; i++) { //flatten it so that touches array is just touches and doesn't include lead_id, oppty_id, touch object
                leadTouches[i] = leadTouches[i].touch;
            }
            lead.web_touches = leadTouches;
            lead.web_touches_amount = lead.web_touches.length;
        });

        _.forEach($scope.data.data.opptys, function (oppty) {
            var opptyTouches = _.uniqBy(_.filter($scope.data.webTrackingData, { oppty_id: oppty.oppty_id }), 'touch.id'),
                n = opptyTouches.length;

            for (var i = 0; i < n; i++) {
                opptyTouches[i] = opptyTouches[i].touch;
            }
            oppty.web_touches = opptyTouches;
            oppty.web_touches_amount = oppty.web_touches.length;
        });

        _.forEach($scope.data.data.deals, function (deal) {
            var dealTouches = _.uniqBy(_.filter($scope.data.webTrackingData, { oppty_id: deal.oppty_id }), 'touch.id'),
                n = dealTouches.length;

            for (var i = 0; i < n; i++) {
                dealTouches[i] = dealTouches[i].touch;
            }
            deal.web_touches = dealTouches;
            deal.web_touches_amount = deal.web_touches.length;
        });

        $scope.data.data.total_lead_touches = _.flatten(_.map($scope.data.data.leads, function (lead) {
            return lead.web_touches;
        })).length;

        $scope.data.data.total_oppty_touches = _.flatten(_.map($scope.data.data.opptys, function (oppty) {
            return oppty.web_touches;
        })).length;

        $scope.data.data.total_deal_touches = _.flatten(_.map($scope.data.data.deals, function (deal) {
            return deal.web_touches;
        })).length;
    }

    $scope.isActive = navigation.isActive;

    $scope.loadNewTab = function(state) {
        $state.go(state.name, $scope.query);
    };

    $scope.getStagesData = function (state) {
        if (state.name === 'app.analyze.campaigns.campaignSpecific.attribution.stages' || state.name === 'app.analyze.webTracking.channelAssetSpecific.attribution.stages') {
            if ($scope.cpStages.length) {
                $state.current.data.loading = false;
                $scope.data.loadingStages = true;

                if (getStagesCall) { getStagesCall.abort(); }
                var params = angular.copy($scope.query);
                params.stages = $scope.cpStages.join('|');
                var apiUrl = $state.current.data.pageType === 'channel-asset-specific' ? 'channel_stages' : 'campaign_stages';
                (getStagesCall = api.getter({ url: apiUrl, params: params, paramsNotToUpdate: "all", noPollMessage: true })).then(function (data) {
                    $scope.data.stages = _.chain($scope.wfStages).filter({ in_camp_perf: true }).map(function (stage) {
                        stage.items = _.chain(_.filterDeep(_.values(data.data[stage.applies_to + 's']), { name: stage.stage }, true)).map(function (item) {
                            if (item.stages.length) {
                                _.extend(item, _.omit(item.stages[0], 'name'));
                            }
                            return _.omit(item, 'stages');
                        }).value();
                        return stage;
                    }).value();
                    $scope.data.loadingStages = false;
                    $scope.$broadcast('$$rebind::refreshData');
                });
            }
        }
    };

    $scope.calloutsTotal = function () {
        // eslint-disable-next-line no-unused-vars
        angular.forEach($scope.data.campaignStates, function (state, key) {
            if (_.includes(state.name, 'leads')) {
                state.key = 'leads';
            }
            else if (_.includes(state.name, 'opportunities')) {
                state.key = 'opptys';
            }
            else if (_.includes(state.name, 'deals')) {
                state.key = 'deals';
            }
            else {
                state.key = {};
            }
        });
    };

    function getCampaign() {
        if (getCampaignCall) { getCampaignCall.abort(); }
        var apiUrl = $state.current.data.pageType === 'channel-asset-specific' ? 'channel_attribution' : 'campaign_attribution';
        $state.current.data.loading = true;
        (getCampaignCall = api.getter({
            url: apiUrl,
            params: $scope.query,
            noCache: true
        })).then(function (data) {
            $scope.data.data = $scope.calc.format(
                $scope.data,
                data.data,
                $scope.limit,
                $scope.platform
            );

            if ($scope.data.data.leads) {
                $scope.data.data.leads.forEach(function (lead) {

                    // TODO: Possibly refactor the code which generates the url to be either a
                    //       angular utility component class, or move it to the back end and just
                    //       pass the finished url to the FE
                    var dynamics365Enabled = $scope.orgConfig.organization_settings && $scope.orgConfig.organization_settings.dynamics365_enabled;

                    var extLeadUrl = $scope.sfdcURL + '/' + lead.id; //create SFDC URL for each lead

                    if (dynamics365Enabled) {
                        extLeadUrl = $scope.orgConfig.organization_settings.dynamicsUrl +
                            "/main.aspx?pagetype=entityrecord&etc=4&id=%7b" + lead.id + "%7d";

                    }

                    if (lead.title == null) {
                        lead.title = "";
                    }

                    lead.lead_ext_link = extLeadUrl;
                });
            }

            $scope.data.campaignGroup = $scope.data.data.campaign.group;

            if ($scope.data.data.campaign.bf_created || $state.current.data.pageType === 'channel-asset-specific') {
                $rootScope.$broadcast('hideSFCTab');
            }

            angular.forEach($scope.data.campaignStates, function (state) {
                var s = $state.get(state.name);
                s.data.title = $scope.data.data.campaign.name;
            });
            $scope.calloutsTotal();
            $state.current.data.loading = false;

            if ($scope.platform === 'full') {
                $scope.getStagesData($state.get($state.current.name));

                if ($state.current.data.pageType !== 'channel-asset-specific') { //only for campaign specific, not for UTM campaign specific
                    if (getGroupCall) { getGroupCall.abort(); }

                    var groupParams = angular.copy($scope.query);
                    groupParams.group = $scope.data.campaignGroup;
                    groupParams.bf_created = $scope.data.data.campaign.bf_created;
                    $scope.data.loadingGroup = true;
                    (getGroupCall = api.getter({
                        url: 'campaign_group_info',
                        params: groupParams,
                        paramsNotToUpdate: "all",
                        noPollMessage: true
                    })).then(function (data) {
                        $scope.data.data.campaignGroup = data.data;
                        $scope.calc.reformat($scope.data.data);
                        $scope.data.loadingGroup = false;
                    });
                }

                if ($state.current.data.pageType === 'channel-asset-specific') {
                    if (costCall) { costCall.abort(); }

                    $scope.costLoading = true;
                    // get the cost for the specific channel asset
                    var costParams = {
                        channelId: $scope.data.data.campaign.campaign_id,
                        cohort: $scope.query.cohort,
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate
                    };
                    (costCall = api.get('channel_specific_cost', costParams)).then(function (data) {
                        $scope.data.data.campaign.cost = data.data.cost;
                        $scope.makeInfoTab();
                        $scope.costLoading = false;
                    // eslint-disable-next-line no-unused-vars
                    }, function (data) {
                        $scope.costLoading = false;
                    });
                }
            }

            if (!($scope.platform === 'full' && $state.current.data.pageType === 'channel-asset-specific')) {
                $scope.makeInfoTab();
            }

            // below saves the data for totals (pipeline, rev, and totals)
            $scope.data.opptys_amount_pipeline_total = _.sumBy($scope.data.data.opptys, 'amount_pipeline');
            $scope.data.opptys_total_amount = _.sumBy($scope.data.data.opptys, 'total_amount');
            $scope.data.deals_total_amount_attributed = _.sumBy($scope.data.data.deals, 'amount');
            $scope.data.deals_total_amount = _.sumBy($scope.data.data.deals, 'total_amount');

            // Calculate ROI
            $scope.data.data.campaign.roi = ($scope.data.data.campaign.cost > 0) ? (($scope.data.data.general.revenue.value - $scope.data.data.campaign.cost) / $scope.data.data.campaign.cost) : 0;

            if ($scope.platform === 'full' && $rootScope.userData.permissions && $rootScope.userData.permissions.web_tracking) {
                getWebTrackingData();
            }

            $state.current.data.loading = false;
            $scope.$broadcast('$$rebind::refreshData');
        });
    }

    $scope.makeInfoTab = function () {
        // For dynamics 365 enabled orgs we do not currently have a direct link to the campaign page
        var dynamics365Enabled = $scope.orgConfig.organization_settings && $scope.orgConfig.organization_settings.dynamics365_enabled;

        var campaignExtLink = dynamics365Enabled ? null : $scope.data.sfUrl + '/' + $scope.query.campaignId;

        $scope.data.infoTab = [
            {
                'label': $state.current.data.pageType === 'channel-asset-specific' ? $scope.data.channel_name : 'Name',
                'link': campaignExtLink,
                'data': $scope.data.data.campaign.name
            },
            {
                'label': $state.current.data.pageType === 'channel-asset-specific' ? $scope.data.channel_group : 'Type',
                'data': $scope.data.data.campaign.group,
            }
        ];

        if ($scope.platform === 'full') {
            $scope.data.infoTab.push({
                'label': 'Cost',
                'data': $scope.data.data.campaign.cost ? $scope.data.data.campaign.cost : 0,
                'filter': 'bfCurrency',
                'func': $state.current.data.pageType === 'channel-asset-specific' ? '' : $scope.adjustCost
            });
        }

        if ($state.current.data.pageType !== 'channel-asset-specific') {
            $scope.data.infoTab.push({
                'label': 'Created',
                'data': $scope.data.data.campaign.created_date,
                'filter': 'date'
            });
        }
    };

    (function init() {
        $scope.colorMap = colorMap;
        $scope.$state = $state;
        $scope.campaignSrv = campaign;
        $scope.platform = $rootScope.userData.platform;
        $scope.data = {
            cohorts: utilities.formatCohorts(cohorts, ['year', 'quarter', 'ago', 'toDate', 'lastFull', 'all', 'custom']),
            modelTypes: userModels,
            tooltips: {
                first: {
                    leads: 'Count of leads and contacts with first touch by this campaign',
                    opportunities: 'Count of opportunities sourced by this campaign',
                    deals: 'Count of deals sourced by this campaign'
                },
                custom: {
                    leads: 'Leads and contacts touched by the campaign',
                    opportunities: 'Opportunities with attributable touches to the campaign',
                    deals: 'Deals with attributable touches to the campaign'
                }
            },
            times: [
                { value: 'All Time', key: '*NONE*', scope: '' },
                { value: 'Custom Range', key: 'time', scope: '' }
            ],
            allTime: { value: 'All Time', key: '*NONE*', scope: '' },
            dataSets: campaign.getData().dataSets,
            leadTableData: [],
            opptyTableData: [],
            dealsTableData: [],
            leadsMap: [],
            conversionRatesTableData: [],
            comparisonMetricsTableData: [],
            costMetricsTableData: [],
            tabs: {
                leads: false,
                opptys: false,
                deals: false
            },
            stageHeaders: [{
                name: 'Name',
                field: 'name',
                // link: function (row) {

                // }
            }, {
                name: 'Date Entered',
                field: 'date_entered',
                filter: 'date'
            }, {
                name: 'Days in Stage',
                field: 'duration'
            }, {
                name: 'Current Stage',
                field: 'current_stage',
                filter: 'date'
            }],
            activeCallout: 'leads',
            chart: {
                legend: {
                    show: false
                },
                tooltip: { show: false },
                size: { height: 40 },
                axis: {
                    x: { show: false },
                    y: { show: false }
                },
                point: {
                    show: false
                },
                color: {
                    pattern: ['#CCCCCC']
                }
            },
            tableData: [],
            allInfluence: false,
            channel_group: campaignPerformance.tabMappings('channel_group'),
            channel_name: campaignPerformance.tabMappings('channel_name'),
            currencyFilter: 'bfCurrency',
            influence: influenceTypes.typeMappings(),
        };
        $scope._ = _;
        $scope.isNaN = isNaN;
        $scope.parseInt = parseInt;
        $scope.campaignPerformance = campaignPerformance;

        angular.forEach($scope.data.modelTypes, function (model) {
            if (_.includes(model.key, 'sourced')) {
                model.alias = 'sourced';
            }
            else {
                model.alias = 'attributed';
            }
        });

        //return active tab
        $scope.data.sfUrl = $scope.sfdcURL;

        $scope.query = { modelType: 'even', dataSet: $scope.data.dataSets[0].key, tab: 'opptys' };
        if (!_.isDefined(_.find($scope.data.modelTypes, { key: $scope.query.modelType }))) {
            $scope.query.modelType = "even";
        }
        utilities.combine($scope.query, $state.params);

        if (influenceTypes.defaultToEven($scope)) {
            $scope.query.modelType = "even";
        }

        if (influenceTypes.useNewInfluenceType()) {
            influenceTypes.setDefaultInfluenceType($scope, influenceTypes.useNewInfluenceType);
        }

        $scope.data.campaignStates = campaignPerformance.getCampaignStates($state, $rootScope.userData);
        $scope.data.tabs[$scope.query.tab] = true;
        $scope.tooltips = $scope.data.tooltips[$scope.query.modelType];
        $scope.cpStages = campaignPerformance.stages();
        if ($scope.query.dataSet === 'campaignCreatedDate') {
            //change data set to activity in this cohort
            $scope.query.dataSet = 'membershipActivity';
            //change url to support data set and then make api call
            utilities.queryString({ dataSet: $scope.query.dataSet });
        }
        $scope.query = _.defaults($scope.query, {
            cohort: 'quarter2Date'
        });

        angular.forEach($scope.data.campaignStates, function (state) {
            state.config.show = true;

            if (state.name === 'app.campaign.attribution.values') {
                state.config.values = 'present';
            }
            else if (state.name === 'app.campaign.attribution.stages') {
                state.config.values = 'present';
            }

            if (state.name === 'app.campaign.attribution.stages' && $scope.query.dataSet !== 'opptyCloseDate') {
                state.config.show = false;
            }
        });

        $scope.$on('filtersChanged', function () {
            getCampaign();
        });

        getCampaign();

        $scope.$on('$destroy', function () {
            if (getCampaignCall) { getCampaignCall.abort(); }
            if (getGroupCall) { getGroupCall.abort(); }
            if (getStagesCall) { getStagesCall.abort(); }
            if (getWebTrackingCall) { getWebTrackingCall.abort(); }
            if (costCall) { costCall.abort(); }
        });

    })();
}]);
