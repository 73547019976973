import {
    CANCEL_CSV_LABEL,
    CSV_IMPORT_MODAL_ID,
    CSV_MODAL_SIZE, DONE_CSV_LABEL,
    IMPORT_CSV_LABEL,
    IMPORT_CSV_MODAL_TITLE, START_OVER_CSV_LABEL
} from '../constants/constants';
import * as MODAL_HTML from '../directives/import-modal/modal.html';
import {ImportCsvModalCtrl} from '../directives/import-modal/component';

export class CreateAccountListModalSrvc {

    static $inject: Array<string> = ['$b'];

    public constructor(private $b: any) {
        return;
    }

    public showModal = () => {
        this.$b.modal({
            title: IMPORT_CSV_MODAL_TITLE,
            id: CSV_IMPORT_MODAL_ID,
            template: MODAL_HTML,
            width: CSV_MODAL_SIZE,
            buttons: [{
                'text': IMPORT_CSV_LABEL,
                'class': 'btn btn-sm btn-primary pull-right somemargin-right',
                'ng-click': 'fn.import()',
                'ng-disabled': 'items.loader.visible || !validModal',
                'ng-if': 'showUploadParams',
            }, {
                'text': CANCEL_CSV_LABEL,
                'class': 'btn btn-sm btn-default pull-right somemargin-right',
                'ng-click': 'fn.cancel()',
                'ng-disabled': 'items.loader.visible',
                'ng-if': 'showUploadParams',
            }, {
                'text': DONE_CSV_LABEL,
                'class': 'btn btn-sm btn-primary pull-right somemargin-right',
                'ng-click': 'fn.cancel()',
                'ng-disabled': 'items.loader.visible',
                'ng-if': '!showUploadParams',
            }, {
                'text': START_OVER_CSV_LABEL,
                'class': 'btn btn-sm btn-default pull-right somemargin-right',
                'ng-click': 'fn.startOver()',
                'ng-disabled': 'items.loader.visible',
                'ng-if': '!showUploadParams',
            },],
            controller: ImportCsvModalCtrl
        });
    };
}
