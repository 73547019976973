/**
 * Controller for the table  and org selector directive.
 */
(function () {
    'use strict';

    angular
        .module('sfdc')
        .directive('orgSelector', function () {
            return {
                template: require('./org-selector.html'),
                controller: orgSelectorCtrl,
                controllerAs: "$ctrl",
                bindToController: true,
                transclude: true,
                link: linkFn,
                scope: {
                    orgs: '=',
                    orgModel: '=',
                    onBtnClick: '&',
                }
            };
        });

    orgSelectorCtrl.$inject = ['SfdcConstants'];

    /**
     * Controller for the directive.
     */
    function orgSelectorCtrl(SfdcConstants) {
        var ctrl = this;

        activate();

        function activate() {
            ctrl.organization = SfdcConstants.LABELS.ORGANIZATION;
            ctrl.button = SfdcConstants.LABELS.BUTTON;
        }
    }

    /**
     * Linker function for this directive.
     * It appends all elements before the button in the directive.
     * @param scope The scope to use
     * @param element The HTML element for the directive
     * @param attrs Attributes used
     * @param ctrl The controller
     * @param transclude The transclude function
     */
    function linkFn(scope, element, attrs, ctrl, transclude) {
        transclude(function(clone) {
            var button = element[0].querySelector('button');
            angular.element(button).before(clone);
        });
    }
})();
