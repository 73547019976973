app.service('insights', ['$rootScope', '$state', function ($rootScope, $state) {
    this.set = function (key, insight) {
        if (!$state.current.data.insights)
            $state.current.data.insights = {};

        if ($state.current.data.insights[key])
            return;

        if (angular.isString(insight)) {
            $state.current.data.insights[key] = { text: insight, level: 'info' };
        } else if (angular.isObject(insight)) {
            $state.current.data.insights[key] = insight;
        }
    };

    this.get = function () {
        return $state.current.data.insights;
    };

}]);
