import * as am4Core from '@amcharts/amcharts4/core';
import * as am4Charts from '@amcharts/amcharts4/charts';

app.controller('campaignPerformanceTrendingWidgetCtrl', ['$scope', 'api', 'utilities', '$timeout', 'trend', 'models', 'campaignPerformance', '$filter', '_', '$templateCache', '$compile', 'cohorts', 'userModels', 'widgets', '$state', '$rootScope', 'personaDefaults', 'isFeatureEnabled', 'influenceTypes', '$stateParams', function ($scope, api, utilities, $timeout, trend, models, campaignPerformance, $filter, _, $templateCache, $compile, cohorts, userModels, widgets, $state, $rootScope, personaDefaults, isFeatureEnabled, influenceTypes, $stateParams) {
    const QUERY_TYPE_TREND = 'trend';
    const QUERY_TYPE_GOAL = 'goal';
    const QUERY_TYPE_BENCHMARK = 'benchmark';
    var $tile = $scope.$tile,
        nowCall,
        thenCall;

    $scope.useNewWidgetTileStyle = $rootScope.userData.platform !== 'standard';
    $scope.useNewInfluenceType = influenceTypes.useNewInfluenceType;

    $scope.amChart;

    $scope.retrieveDataFieldValue = function (fields, queryField) {
        const fieldValue = _.find(fields, { key: queryField }).value;
        return fieldValue === 'Response' ? `Campaign Response` : fieldValue;
    };

    function disposeAmChart() {
        if ($scope.amChart != null) {
            $scope.amChart.dispose();
            $scope.amChart = null;
        }
    }

    function prepAmChartData(dataset, chartType, goal, frequency) {
        const dataSetCopy = angular.copy(dataset);
        const shouldSetByYear = frequency === 'months';

        return dataSetCopy.map((data, idx) => {
            const startOfDataset = new Date(dataSetCopy[0].nowDate);
            const formattedDate = shouldSetByYear ?
                new Date(startOfDataset.setMonth(startOfDataset.getMonth() + idx))
                : new Date(startOfDataset.setDate(startOfDataset.getDate() + (7 * idx)));

            if (!data.nowDate) {
                data.nowDate = formattedDate;
            } else if (!data.thenDate && idx === dataSetCopy.length - 1) {
                data.nowDate = formattedDate;
                data.thenDate = data.nowDate;
            } else {
                data.nowDate = new Date(data.nowDate);
            }

            data.nowDate = data.nowDate.toDateString();
            if (data.thenDate) {
                data.thenDate = data.thenDate.toDateString();
            }

            if (chartType === QUERY_TYPE_GOAL) {
                data.then = goal;
            }

            return data;
        });
    }

    $scope.generateAmChart = function () {
        am4Core.options.commercialLicense = true;
        const amChartId = `${$scope.widget.id ? $scope.widget.id : 'preview'}-cmp-chart`;
        const targetGoal = $scope.widget.type === QUERY_TYPE_GOAL && $scope.widget.data.goals ?
            $scope.widget.data.goals[$scope.widget.query.field][$scope.widget.query.cohort]
            : 1;
        const colorPalette = {
            now: '#08C4B2',
            then: '#C5C5C5',
        };
        const fieldValueAxisMap = {
            'leads': '#,###.',
            'opptys': '#,###.',
            'opptys_unique': '#,###.',
            'deals': '#,###.',
            'deals_unique': '#,###.',
            'pipeline': '$#,###.',
            'revenue': '$#,###.',
            'total_pipeline_influenced': '$#,###.',
            'total_revenue_influenced': '$#,###.',
        };
        const chart = am4Core.create(amChartId, am4Charts.XYChart);
        const intervalAxis = chart.xAxes.push(new am4Charts.DateAxis());
        const valueAxis = chart.yAxes.push(new am4Charts.ValueAxis());

        intervalAxis.renderer.grid.template.strokeOpacity = 0;
        intervalAxis.renderer.minGridDistance = 60;
        intervalAxis.chart.dateFormatter.inputDateFormat = 'MMM d, yyyy';

        valueAxis.tooltip.disabled = true;
        valueAxis.numberFormatter = new am4Core.NumberFormatter();
        valueAxis.numberFormatter.numberFormat = fieldValueAxisMap[$scope.widget.query.field];
        valueAxis.renderer.opposite = true;
        valueAxis.renderer.grid.template.strokeOpacity = 0;
        valueAxis.renderer.labels.template.adapter.add('text', (value) => {
            if (!value) { return; }
            const unformattedValue = value.replace(/[?%,$]/gi, '');

            return $filter('nrFormat')(unformattedValue, value.includes('$'));
        });

        chart.data = prepAmChartData($scope.chart.data, $scope.widget.type, targetGoal, $scope.data.now.frequency);
        chart.cursor = new am4Charts.XYCursor();
        chart.cursor.xAxis = intervalAxis;
        chart.cursor.behavior = 'none';

        if ($scope.widget.type === QUERY_TYPE_BENCHMARK) {
            generateBenchmarkOptions(colorPalette, fieldValueAxisMap, chart);
        } else if ($scope.widget.type === QUERY_TYPE_GOAL) {
            generateGoalOptions(colorPalette, fieldValueAxisMap, chart);
        } else {
            generateTrendOptions(colorPalette, fieldValueAxisMap, chart);
        }

        if ($scope.useNewWidgetTileStyle) {
            valueAxis.renderer.labels.template.fill = am4Core.color('#6A7171');
            valueAxis.renderer.line.strokeOpacity = 1;
            valueAxis.renderer.line.stroke = am4Core.color('#DEE1E3');
            intervalAxis.renderer.labels.template.fill = am4Core.color('#6A7171');

            chart.events.on('ready', () => {
                // populate our custom legend when chart renders
                chart.customLegend = document.getElementById(`${$scope.widget.id}-cmp-legend`);
                chart.series.values.forEach(row => {
                    const color = row.stroke;

                    chart.customLegend.innerHTML += `
                    <span class="legend-item capitalize">
                        <span class="legend-marker" style="background: ${color}"></span>
                        ${row.name || 'n/a'}
                    </span>
                `;
                });
            });
        } else {
            chart.legend = new am4Charts.Legend();
            chart.legend.maxHeight = 50;
            chart.legend.labels.template.truncate = false;
            chart.legend.labels.template.wrap = true;
        }

        $scope.amChart = chart;
    };

    function generateBenchmarkOptions(colorPalette, fieldValueAxisMap, chart) {
        const labelValueList = [
            {
                label: 'now',
                value: $tile.evalString($scope.widget.compare[$scope.widget.type].a.value) || $tile.evalString($scope.widget.compare[$scope.widget.type].a.label),
            },
            {
                label: 'then',
                value: $tile.evalString($scope.widget.compare[$scope.widget.type].b.value) || $tile.evalString($scope.widget.compare[$scope.widget.type].b.label),
            },
        ];
        labelValueList.map((labelValue) => {
            const series = chart.series.push(new am4Charts.LineSeries());

            series.name = labelValue.value;
            series.dataFields.valueY = labelValue.label;
            series.numberFormatter = new am4Core.NumberFormatter();
            series.numberFormatter.numberFormat = fieldValueAxisMap[$scope.widget.query.field];

            series.dataFields.dateX = 'nowDate';

            series.stroke = am4Core.color(colorPalette[labelValue.label]);
            series.strokeWidth = 2;
            series.tooltipText = '[bold #000]{name}[/][#000]: {valueY.value}[/]';
            series.tooltip.animationDuration = 0;
            series.tooltip.background.fill = am4Core.color('#FFF');
            series.tooltip.background.strokeWidth = 2;
            series.tooltip.getFillFromObject = false;
            series.tooltip.getStrokeFromObject = true;
        });
    }

    function generateGoalOptions(colorPalette, fieldValueAxisMap, chart) {
        const labelValueList = [
            {
                label: 'now',
                value: $scope.widget.compare[$scope.widget.type].a ?
                    $tile.evalString($scope.widget.compare[$scope.widget.type].a.value)
                    : $scope.selectedCohort(),
            },
            {
                label: 'then',
                value: 'Goal',
            },
        ];
        labelValueList.map((labelValue) => {
            const series = chart.series.push(new am4Charts.LineSeries());

            series.name = labelValue.value;
            series.dataFields.valueY = labelValue.label;
            series.numberFormatter = new am4Core.NumberFormatter();
            series.numberFormatter.numberFormat = fieldValueAxisMap[$scope.widget.query.field];

            series.dataFields.dateX = 'nowDate';

            series.stroke = am4Core.color(colorPalette[labelValue.label]);
            series.strokeWidth = 2;
            series.tooltipText = '[bold #000]{name}[/][#000]: {valueY.value}[/]';
            series.tooltip.animationDuration = 0;
            series.tooltip.background.fill = am4Core.color('#FFF');
            series.tooltip.background.strokeWidth = 2;
            series.tooltip.getFillFromObject = false;
            series.tooltip.getStrokeFromObject = true;
            series.hiddenInLegend = labelValue.label === 'then';
        });
    }

    function generateTrendOptions(colorPalette, fieldValueAxisMap, chart) {
        const series = chart.series.push(new am4Charts.LineSeries());
        const label = 'now';
        const value = $tile.evalString($scope.widget.compare[$scope.widget.type].a.value);

        series.name = value;
        series.dataFields.valueY = label;
        series.numberFormatter = new am4Core.NumberFormatter();
        series.numberFormatter.numberFormat = fieldValueAxisMap[$scope.widget.query.field];
        series.dataFields.dateX = 'nowDate';

        series.stroke = am4Core.color(colorPalette[label]);
        series.strokeWidth = 2;
        series.tooltipText = '[bold #000]{name}[/][#000]: {valueY.value}[/]';
        series.tooltip.animationDuration = 0;
        series.tooltip.background.fill = am4Core.color('#FFF');
        series.tooltip.background.strokeWidth = 2;
        series.tooltip.getFillFromObject = false;
        series.tooltip.getStrokeFromObject = true;
    }


    $scope.chartHeight = function (h) {
        $timeout(function () {
            $scope.chart.chart.size.height = h;
        }, 100, false);
    };

    $scope.chartWidth = function (w) {
        $timeout(function () {
            $scope.chart.chart.size.width = w;
        }, 100, false);
    };

    //Removed the choices that are not avail via css for the function below: look for campaign-choices in css
    $scope.fieldDisabled = function (field) {
        if (field == 'leads' && _.includes($scope.widget.query.dataSet, 'oppty')) {
            return true;
        }
        return _.includes(campaignPerformance.wfStageKeys, field) && (($scope.widget.query.model == 'even' || $scope.widget.query.model == 'custom') || $scope.widget.query.dataSet !== 'membershipActivity');
    };

    $scope.setDisabled = function (set) {
        if ($scope.widget.query.field == 'leads' && _.includes(set, 'oppty')) {
            return true;
        }
        return set !== 'membershipActivity' && campaignPerformance.wfStageKeys.indexOf($scope.widget.query.field) > -1;
    };

    $scope.modelDisabled = function (model) {
        return model !== 'sourced' && model !== 'last' && campaignPerformance.wfStageKeys.indexOf($scope.widget.query.field) > -1;
    };

    $scope.selectedCohort = function (prev) {
        return trend.currentCohort($scope, prev);
    };

    $scope.cohortChanged = function () {
        $scope.data.freqTypes = campaignPerformance.getFreqTypes($scope.widget.query.cohort);
        $scope.widget.query.freqType = 'auto';
    };

    $scope.fieldChanged = function () {
        $scope.widget.data.currentFilter = _.find($scope.data.fields, { key: $scope.widget.query.field }).filter;
    };

    var calcPercentage = function () { //only for goal tiles
        if (!$scope.widget.data.goals[$scope.widget.query.field][$scope.widget.query.cohort]) {
            $scope.widget.data.goals[$scope.widget.query.field][$scope.widget.query.cohort] = 1;
        }
        $scope.widget.data.lastGoal = angular.copy($scope.widget.data.goals);
        //Health color should be how far INTO your goal (% defined below as diff inside progress health object) relative to how far along you are in your cohort (cohortProgress % calculated below) -- this only applies if today is in the cohort selected
        //Example: If you're 55% of the way to your goal and your 50% of the way through your cohort then the color should be green because the 50% - 55% = 5% (100 - 5 = 95%)
        var currentDate = new Date(),
            cohortObj = _.find($scope.data.cohorts, { key: $scope.widget.query.cohort }), //gets you the current cohort object with start and end times
            goalProgress = utilities.health([$scope.widget.data.goals[$scope.widget.query.field][$scope.widget.query.cohort], $scope.data.nowTotal], '<=', 5, 49); //gets you the health of the goal (how far into your goal are you)

        //check if today is in the cohort selected, if so, use the cohort progress in your calculation
        if (currentDate >= cohortObj.start_date && currentDate < cohortObj.end_date) {
            var cohortProgress = utilities.cohortProgress(cohortObj.start_date, cohortObj.end_date, new Date($scope.data.currentPeriod.nowDate).getTime()); //gets you the health of the cohort (how far into your cohort are you), only if your cohort isn't in the past
            $scope.data.progressHealth = utilities.health([cohortProgress, parseFloat(goalProgress.diff)], '<=', 0.5, 49);
        } else { //otherwise, just use the regular goal progress
            $scope.data.progressHealth = utilities.health([$scope.widget.data.goals[$scope.widget.query.field][$scope.widget.query.cohort], $scope.data.nowTotal], '<=', 5, 49);
        }

        $scope.data.progressPercentage = 100 - parseFloat(utilities.diff($scope.widget.data.goals[$scope.widget.query.field][$scope.widget.query.cohort], $scope.data.nowTotal).replace(',', ''));
        //if progresspercentage is greater than or equal to 100, just make the health "good
        if ($scope.data.progressPercentage >= 100) {
            $scope.data.progressHealth.label = 'good';
            $scope.data.progressHealth.cls = 'success';
            $scope.data.progressHealth.textCls = 'text-success';
            $scope.data.progressHealth.bg = '#3c763d';
        }
        $scope.fieldChanged();
    };

    var makeChart = function () {
        // resets the y axis max of the chart
        $scope.chart.chart.axis = {
            x: { show: false },
            y: { show: false }
        };
        $scope.chart.chart.grid.y.lines = [];

        if ($scope.widget.type == QUERY_TYPE_BENCHMARK) {
            $scope.chart.dimensions = {
                "x": {
                    "axis": "x"
                },
                "now": {
                    "type": "line"
                },
                "then": {
                    "type": "line"
                }
            };
            $scope.chart.chart.tooltip = {
                // eslint-disable-next-line no-unused-vars
                contents: function (d, dt, dv, color) {
                    $scope.data.tScope.rows = d.map(function (r) {
                        $scope.data.tScope.title = _.singular($scope.data[r.id].frequency) + " " + (r.index + 1);
                        return {
                            label: r.name,
                            value: r.value,
                            color: (r.name == 'now') ? $scope.widget.colors[0] : $scope.widget.colors[1],
                            filter: $scope.widget.data.currentFilter
                        };
                    });
                    $scope.data.tScope.health = utilities.health([d[0].value, d[1].value], '>', 0);
                    return $scope.data.tScope.$apply(function () { return $scope.data.tooltip[0].outerHTML; });
                }
            };
        }

        if ($scope.widget.type == QUERY_TYPE_TREND) {
            $scope.chart.dimensions = {
                "x": {
                    "axis": "x"
                },
                "now": {
                    "type": "line"
                }
            };

            $scope.chart.chart.tooltip = {
                // eslint-disable-next-line no-unused-vars
                contents: function (d, dt, dv, color) {
                    $scope.data.tScope.rows = d.map(function (r) {
                        $scope.data.tScope.title = _.singular($scope.data[r.id].frequency) + " " + (r.index + 1);
                        var field = _.find($scope.data.fields, { key: $scope.widget.query.field });
                        return {
                            label: field.value,
                            value: r.value,
                            color: $scope.widget.colors[0],
                            filter: $scope.widget.data.currentFilter
                        };
                    });
                    return $scope.data.tScope.$apply(function () { return $scope.data.tooltip[0].outerHTML; });
                }
            };
        }

        if ($scope.widget.type == QUERY_TYPE_GOAL) {
            $scope.chart.dimensions = {
                "x": {
                    "axis": "x"
                },
                "now": {
                    "type": "line"
                }
            };
            $scope.chart.chart.axis.y.max = $scope.data.currentPeriod.now < $scope.widget.data.goals[$scope.widget.query.field][$scope.widget.query.cohort] ? _.ceil($scope.widget.data.goals[$scope.widget.query.field][$scope.widget.query.cohort] * 1.10) : _.ceil($scope.data.currentPeriod.now * 1.10);
            $scope.chart.chart.grid.y.lines = [
                { value: $scope.widget.data.goals[$scope.widget.query.field][$scope.widget.query.cohort], text: 'My Goal: ' + $filter('number')(Number($scope.widget.data.goals[$scope.widget.query.field][$scope.widget.query.cohort])) },
            ];

            $scope.chart.chart.tooltip = {
                // eslint-disable-next-line no-unused-vars
                contents: function (d, dt, dv, color) {
                    $scope.data.tScope.rows = d.map(function (r) {
                        $scope.data.tScope.title = _.singular($scope.data[r.id].frequency) + " " + (r.index + 1);
                        var field = _.find($scope.data.fields, { key: $scope.widget.query.field });
                        return {
                            label: field.value,
                            value: r.value,
                            color: $scope.widget.colors[0],
                            filter: $scope.widget.data.currentFilter
                        };
                    });
                    return $scope.data.tScope.$apply(function () { return $scope.data.tooltip[0].outerHTML; });
                }
            };
        }

        //so we can use the angular templating engine to power the c3 chart tooltip
        $scope.data.tooltip = $compile($templateCache.get('chartTooltip.html'))($scope.data.tScope);
    };

    this.getData = function () {
        if (nowCall) { nowCall.abort(); }
        if (thenCall) { thenCall.abort(); }

        var current = angular.copy($scope.widget.query),
            url = $scope.widget.webActivity ? 'channels_trending_total' : 'campaigns_trending_total';

        delete current.previous;

        (nowCall = api.getter({
            url,
            params: current,
            paramsNotToUpdate: 'all',
            skipFilters: true,
        })).then(
            function (data) {
                loadPreviousCohortData();
                prepData(data, 'now');
            },
            function (errorResponse) { errorState(errorResponse); }
        );

        function loadPreviousCohortData() {
            (thenCall = api.getter({
                url,
                params: $scope.widget.query,
                paramsNotToUpdate: 'all',
                skipFilters: true,
            })).then(
                function (data) { prepData(data, 'then'); },
                function (errorResponse) { errorState(errorResponse); }
            );
        }

        function errorState(data) {
            if (!data.aborted) {
                $tile.failedLoading();
            }
        }

        function prepData(data, type) {
            const dataSetCopy = angular.copy(data);
            if (!dataSetCopy.data.data.length) {
                $tile.noData();
            }
            trend.convertDate(dataSetCopy.data);
            var t = 0;

            dataSetCopy.data.data = _.compact(dataSetCopy.data.data.map(function (v, i) {
                if ((!v.frequency || moment(new Date(v.frequency)).isAfter(new Date($scope.last_updated_date.last_update).getTime(), 'day')) && !v.totals) {
                    return null;
                } else {
                    v[type] = v.totals + t; //the actual points connected on the graph
                    v.x = (i + 1);
                    v[type + 'Date'] = v.frequency;
                    v[type + 'OldFrequency'] = v.old_frequency;
                    delete v.frequency;
                    delete v.old_frequency;
                    delete v.totals;
                    t = v[type];

                    return v;
                }
            }));
            // check if the current period is in between a unit of time...if it is, make it the current date
            const mostRecentPeriod = dataSetCopy.data.data[dataSetCopy.data.data - 1];
            const secondRecentPeriod = dataSetCopy.data.data[dataSetCopy.data.data - 2];
            const shouldSetByYear = $scope.data && $scope.data.now && $scope.data.now.frequency === 'month';

            if (mostRecentPeriod && !mostRecentPeriod.nowDate) {
                const formattedDate = shouldSetByYear ?
                    new Date(secondRecentPeriod.nowDate.setMonth(secondRecentPeriod.nowDate.getMonth() + 1))
                    : new Date(secondRecentPeriod.nowDate.setDate(secondRecentPeriod.nowDate.getDate() + 7));
                mostRecentPeriod.nowDate = formattedDate;
            }

            $scope.data[type] = dataSetCopy.data;

            $tile.loading($tile.loadingProgress() + 35);
            check();
        }

        function check() {
            if ($tile.loadingProgress() >= 80) {
                $tile.loading(90);
                $scope.widget.data.lastQuery = angular.copy($scope.widget.query);
                $scope.widget.data.lastType = angular.copy($scope.widget.type);
                $scope.chart.data = angular.merge($scope.data.now.data, $scope.data.then.data);
                $scope.data.currentPeriod = _.last(_.filter($scope.data.now.data, function (o) {
                    return $scope.widget.type === QUERY_TYPE_BENCHMARK
                        ? _.isDefined(o.now) && _.isDefined(o.then)
                        : _.isDefined(o.now);
                }));

                if ($scope.data.currentPeriod) {
                    $scope.data.thenTotal = $scope.data.currentPeriod.then;
                    $scope.data.nowTotal = $scope.data.currentPeriod.now;
                    $scope.data.health = utilities.health([$scope.data.nowTotal, $scope.data.thenTotal], '>', 0, null, true);
                    $scope.data.diff = $scope.widget.type === QUERY_TYPE_BENCHMARK
                        ? parseFloat(utilities.diff($scope.data.nowTotal, $scope.data.thenTotal, null, null, true).replace(',', ''))
                        : 0;
                    $scope.data.progressHealth = false;
                } else {
                    $tile.noData();
                }

                //goal
                if ($scope.widget.type == QUERY_TYPE_GOAL) {
                    calcPercentage();
                }
                makeChart();
                $tile.loading(100);
                $tile.endLoading();
                $scope.$emit("widgetLoadingDone");
            }
        }
    };

    function configureCohorts() {
        var newCohorts = angular.copy(utilities.formatCohorts(cohorts, ['lastFull', 'toDate', 'ago', 'year', 'quarter', 'custom'])),
            tempYearCohorts = $filter('filter')(newCohorts, function (cohort) {
                return cohort.scope == 'Year';
            }),
            tempQuarterCohorts = $filter('filter')(newCohorts, function (cohort) {
                return cohort.scope == 'Quarter';
            });

        _.remove(newCohorts, _.last(tempQuarterCohorts));
        _.remove(newCohorts, _.last(tempYearCohorts));
        return newCohorts;
    }

    (function init(ctrl) {
        $tile.startLoading();
        $tile.loading(10);
        //------------------------ not saved ------------------------//
        $scope.utils = utilities;
        $scope.data = {
            models: userModels,
            dataSets: campaignPerformance.dataSets,
            cohorts: configureCohorts(),
            fields: campaignPerformance.fields,
            tScope: $scope.$new(),
        };
        $scope.chart = {
            dimensions: {
                "x": {
                    "axis": "x"
                },
                "now": {
                    "type": "line"
                },
                "then": {
                    "type": "line"
                }
            },
            chart: {
                size: {},
                legend: {
                    show: false
                },
                point: {
                    r: 3.5
                },
                grid: {
                    x: {
                        show: true
                    },
                    y: {
                        show: false
                    }
                },
                axis: {
                    x: { show: false },
                    y: { show: false }
                },
                color: {
                    pattern: $scope.widget.colors
                },
                tooltip: {
                    // eslint-disable-next-line no-unused-vars
                    contents: function (d, dt, dv, color) {
                        $scope.data.tScope.rows = d.map(function (r) {
                            return {
                                cd: r,
                                d: $scope.data[r.id].data[r.index + 1],
                                frequency: $scope.data[r.id].frequency,
                                filter: $scope.widget.data.currentFilter
                            };
                        });
                        $scope.data.tScope.health = utilities.health([d[0].value, d[1].value], '>', 0);
                        return $scope.data.tScope.$apply(function () { return $scope.data.tooltip[0].outerHTML; });
                    }
                }
            }
        };

        //so we can use the angular templating engine to power the c3 chart tooltip
        $scope.data.tooltip = $compile($templateCache.get('trendingTooltip.html'))($scope.data.tScope);

        //------------------------ saved ------------------------//
        $scope.widget.data = _.defaults($scope.widget.data, personaDefaults.getQueryDefinitions($scope.widget).data);
        const params = _.defaults($scope.widget.query, personaDefaults.getQueryDefinitions($scope.widget).query);
        $scope.widget.query = widgets.addFilters(params, $scope.widget.data.filters, $scope.widget.data.accountLists);
        // NOTE: Update query object with data, which was in query params
        // (terminus-hub Add to Dashboard functionality)
        if ($stateParams.routeId && $stateParams.routeId === 'app.analyze.campaigns.trendingAnalysis') {
            Object.keys($scope.widget.query).forEach(key => {
                // update only properties, which are related to marketing influence data
                if ($scope.widget.query.hasOwnProperty(key)
                    && $stateParams.hasOwnProperty(key)
                    && $stateParams[key]) {
                    $scope.widget.query[key] = $stateParams[key];
                }
            });
            // NOTE: add mandatory params which are not in $scope.widget.query for some reason
            $scope.widget.query.type = $stateParams.type;
            $scope.widget.type = $stateParams.type;
            $scope.widget.data.goals = {
                [$scope.widget.query.field]: {
                    [$scope.widget.query.cohort]: $stateParams.goal
                }
            };
        }

        $scope.widget = _.defaults($scope.widget, personaDefaults.getQueryDefinitions($scope.widget).type);

        if (!_.isDefined(_.find($scope.data.models, { key: $scope.widget.query.model }))) {
            $scope.widget.query.model = "even";
        }

        $scope.data.freqTypes = campaignPerformance.getFreqTypes($scope.widget.query.cohort);
        $scope.fieldChanged();

        $tile.listeners.push($scope.$on('widgetUpdate', function () {
            if ($scope.widget.type == QUERY_TYPE_GOAL && !angular.equals(angular.copy($scope.widget.data.goals), $scope.widget.data.lastGoal) && angular.equals(angular.copy($scope.widget.query), $scope.widget.data.lastQuery)) {
                $tile.startLoading();
                $scope.widget.data.lastQuery = angular.copy($scope.widget.query);
                $scope.widget.data.lastType = angular.copy($scope.widget.type);
                calcPercentage();
                makeChart();
                disposeAmChart();
                $tile.loading(100);
                $tile.endLoading();
            } else if (!angular.equals(angular.copy($scope.widget.query), $scope.widget.data.lastQuery) || !angular.equals(angular.copy($scope.widget.type), $scope.widget.data.lastType)) {
                $tile.startLoading();
                disposeAmChart();
                $tile.loading(10);
                ctrl.getData();
            }
        }));

        $scope.$on('angular-chart-rendered', function (event, options, instance) {
            $scope.data.chartResizer = instance;
            widgets.resizeChart($scope.data.chartResizer);
            // if(!_.includes(userAgent(),'firefox')) {
            // 	// chartBlip($scope.data.chartResizer.element);
            // }
        });

        $scope.$on('gridster-item-resize', function () {
            $timeout(function () {
                widgets.resizeChart($scope.data.chartResizer);
                // if(!_.includes(userAgent(),'firefox')) {
                // 	chartBlip($scope.data.chartResizer.element);
                // }
            }, 1500, false);
        });

        $scope.$on('$windowResizeEnd', function () {
            $timeout(function () {
                widgets.resizeChart($scope.data.chartResizer, $scope);
            }, 10, false);
        });

        $scope.$on('columnsChanged', function () {
            $timeout(function () {
                widgets.resizeChart($scope.data.chartResizer, $scope);
                // 			if(!_.includes(userAgent(),'firefox')) {
                // 	chartBlip($scope.data.chartResizer.element);
                // }
            }, 3000, false);
        });

        $scope.$on('$destroy', function () {
            if (nowCall) { nowCall.abort(); }
            if (thenCall) { thenCall.abort(); }
            $scope.data.tScope.$destroy();
        });

        $scope.abs = Math.abs;

        $scope._ = _;
    })(this);
}]);
