app.service('campaignPerformance', ['$rootScope', '$state', 'utilities', 'userModels', '_', 'influenceTypes', 'isFeatureEnabled', function ($rootScope, $state, utilities, userModels, _, influenceTypes, isFeatureEnabled) {
    var that = this;
    var wfStages = [];
    var wfStageKeys = [];

    var aggregateTotalKeys = ['accounts_unique', 'leads_unique', 'opptys_unique', 'deals_unique', 'total_influenced_amount', 'total_influenced_revenue'];

    var fullPlatform = ['full'];
    var fullAndCampaignPlatform = ['full', 'campaign'];

    $rootScope.wfStages.forEach(function (stage) {
        if (stage.in_camp_perf) {
            var item = {
                value: stage.stage,
                key: stage.applies_to + ":" + stage.stage,
                filter: 'number:0'
            };
            wfStages.push(item);
            wfStageKeys.push(item.key);
        }
    });

    this.dataSets = [
        { label: 'Have Activity In This Cohort', key: 'membershipActivity' },
        { label: 'Started In This Cohort', key: 'campaignCreatedDate' },
        { label: 'Influenced Oppts Created In This Cohort', key: 'opptyCreatedDate' },
        { label: 'Influenced Oppts That Have A Close Date In This Cohort', key: 'opptyCloseDate' }
    ];

    let dollarInfluencedFields = [];

    if($rootScope.orgConfig.features && $rootScope.orgConfig.features.campaign_trending
        && $rootScope.orgConfig.features.campaign_trending.influenced) {
        dollarInfluencedFields = [
            {
                value: 'Influenced Pipeline',
                key: 'total_pipeline_influenced',
                filter: 'bfCurrency'
            }, {
                value: 'Influenced Revenue',
                key: 'total_revenue_influenced',
                filter: 'bfCurrency'
            }];
    }

    function createFieldOptions () {
        return $rootScope.userData.platform === 'full' ? [
            {
                value: 'Responses',
                key: 'leads',
                filter: 'number:0'
            }, {
                value: 'Oppty. Touches',
                key: 'opptys',
                filter: 'number:0'
            }, {
                value: 'Influenced Opportunities',
                key: 'opptys_unique',
                filter: 'number:0'
            }, {
                value: 'Deal Touches',
                key: 'deals',
                filter: 'number:0'
            }, {
                value: 'Influenced Deals',
                key: 'deals_unique',
                filter: 'number:0'
            }, {
                value: 'Attributed Pipeline',
                key: 'pipeline',
                filter: 'bfCurrency'
            }, {
                value: 'Attributed Revenue',
                key: 'revenue',
                filter: 'bfCurrency'
            },
        ].concat(dollarInfluencedFields).concat(wfStages)
            : [
                {
                    value: 'Responses',
                    key: 'leads',
                    filter: 'number:0'
                }, {
                    value: 'Influenced Opportunities',
                    key: 'opptys_unique',
                    filter: 'number:0'
                }, {
                    value: 'Influenced Deals',
                    key: 'deals_unique',
                    filter: 'number:0'
                }, {
                    value: 'Attributed Pipeline',
                    key: 'pipeline',
                    filter: 'bfCurrency'
                }, {
                    value: 'Attributed Revenue',
                    key: 'revenue',
                    filter: 'bfCurrency'
                },
            ].concat(dollarInfluencedFields);
    }

    this.fields = createFieldOptions();

    this.wfStageKeys = wfStageKeys;

    this.getFreqTypes = function (cohort) {
        cohort = cohort.toLowerCase();
        var types = ['auto'];
        if (cohort.search('day') > -1) {
            types.push('days');
        } else if (cohort.search('week') > -1) {
            types.push('days');
        } else if (cohort.search('month') > -1) {
            types.push('weeks');
            types.push('months');
        } else if (cohort.search('quarter') > -1 || (cohort.match(/^q\d+/) !== null)) {
            types.push('weeks');
            types.push('months');
        } else if (cohort.search('year') > -1 || cohort.search('fy') > -1) {
            types.push('months');
        }
        return types;
    };

    this.stages = function () {
        var stagesToShow = [];
        $rootScope.wfStages.forEach(function (stage) {
            if (stage.in_camp_perf) {
                stagesToShow.push((stage.applies_to === "lead" ? "l::" : "o::") + stage.stage);
            }
        });
        return stagesToShow;
    };

    this.webTrackingHeaders = function ($scope, $state) {
        var webHeaders = [
            {
                label: that.headerMappings('ranged.revenue_group', that.tabMappings('channel_group')),
                field: 'ranged.revenue_group',
                visible: {
                    campaign: true,
                    group: true,
                    sourced: true,
                    custom: true,
                    last: true,
                    even: true,
                    channel: true
                },
                descendingFirst: false,
                defaultSort: false,
                hideOnChildTable: true,
                model: ['sourced', 'custom', "last", "even"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['groups'],
                link: function (row, web) {
                    //this is for the expandable tables either in campaign groups or channels tab, so we're going to want the opposite because the expandables show channel assets in campaign group and campaigns in channels
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.campaigns.listAnalysis.campaigns' : 'app.analyze.webTracking.webActivity.channelAssets';
                    return $scope.shref(pageUrl, {
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        model: $scope.query.model,
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        gf: web.ranged.revenue_group ? web.ranged.revenue_group : null,
                        influenceType: $scope.query.influenceType,
                    });
                }
            },
            {
                label: that.headerMappings('ranged.campaign_name', that.tabMappings('channel_name')),
                field: 'ranged.campaign_name',
                visible: {
                    campaign: true,
                    sourced: true,
                    custom: true,
                    last: true,
                    even: true,
                    channel_asset: true,
                },
                hideOnChildTable: true,
                descendingFirst: false,
                defaultSort: false,
                model: ['sourced', 'custom', "last", "even"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns'],
                link: function (row, web) {
                    var pageUrl = 'app.analyze.webTracking.channelAssetSpecific.attribution.leads';
                    return $scope.shref(pageUrl, {
                        campaignId: web.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: $scope.query.model,
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        influenceType: $scope.query.influenceType,
                    });
                }
            },
            {
                label: that.headerMappings('ranged.campaign_name', 'Campaign'),
                field: 'ranged.campaign_name',
                visible: {
                    sourced: true,
                    custom: true,
                    last: true,
                    even: true,
                    channel_asset: true,
                    channel: true
                },
                hideOnParentTable: true,
                descendingFirst: false,
                defaultSort: false,
                model: ['sourced', 'custom', "last", "even"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns'],
                link: function (row, web) {
                    //this is for the expandable tables either in channel-assets or campaigns tab, so again we're going to want the opposite because the expandables show channel assets in campaign group and campaigns in channels
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.campaigns.campaignSpecific.attribution.leads' : 'app.analyze.webTracking.channelAssetSpecific.attribution.leads';
                    return $scope.shref(pageUrl, {
                        campaignId: web.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: $scope.query.model,
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        influenceType: $scope.query.influenceType,
                    });
                }
            },
            {
                label: that.headerMappings('ranged.revenue_group', 'Campaign Type'),
                field: 'ranged.revenue_group',
                defaultSort: false,
                descendingFirst: false,
                hideOnParentTable: true,
                visible: {
                    sourced: true,
                    custom: true,
                    last: true,
                    even: true,
                    channel_asset: true
                },
                model: ['sourced', 'custom', "last", "even"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['groups'],
                noDrag: true,
                noTile: true,
                link: function (row, web) {

                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.campaigns.listAnalysis.campaigns' : 'app.analyze.webTracking.webActivity.channelAssets';
                    return $scope.shref(pageUrl, {
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        model: $scope.query.model,
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        gf: web.ranged.revenue_group,
                        allWebActivity: web.ranged.is_synthetic,
                        influenceType: $scope.query.influenceType,
                    });
                }
            },
            {
                label: that.headerMappings('ranged.parent_channel', that.tabMappings('channel_parent')),
                field: 'ranged.parent_channel',
                defaultSort: false,
                descendingFirst: false,
                hideOnChildTable: true,
                visible: {
                    sourced: true,
                    custom: true,
                    last: true,
                    even: true,
                    medium: true,
                    group: $scope.isMediumConfigured,
                    campaign: $scope.isMediumConfigured
                },
                link: function (web) {
                    if ($state.current.data.pageType === 'campaign-performance') { return; } //don't want links for UTM mediums on campaign performance expanded tables
                    var pageUrl = 'app.analyze.webTracking.webActivity.channel';
                    return $scope.shref(pageUrl, {
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        model: $scope.query.model,
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        mf: web.ranged.parent_channel
                    });
                },
                model: ['sourced', 'custom', "last", "even"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                noTile: true,
            },
        ];
        return webHeaders;
    };

    this.headers = function ($scope, $state, webTrackingOnly) {
        let stageRegEx = /^([0-9]|[^a-zA-Z])*|[^0-9a-zA-Z]/g;
        let headers = [
            //----- Default Headers -----//
            {
                label: that.headerMappings('ranged.campaign_name', 'Campaign'),
                field: 'ranged.campaign_name',
                defaultSort: false,
                descendingFirst: false,
                visible: {
                    sourced: true,
                    custom: true,
                    last: true,
                    even: true,
                },
                toggleOrder: {
                    sourced: 1,
                    last: 1,
                    custom: 1,
                    even: 1
                },
                model: ['sourced', 'custom', "last", "even"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns'],
                link: function (row) {
                    //this is for the main table (parent table), if you're on a web activity page, you're going to have the column channel assets, otherwise you're going to have the column campaigns
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.leads' : 'app.analyze.campaigns.campaignSpecific.attribution.leads';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id || row.ranged.id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: $scope.query.model,
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        influenceType: $scope.query.influenceType,
                    });
                },
                noDrag: true,
                noTile: true,
                noWebTracking: true
            },
            {
                label: that.headerMappings('ranged.campaign_group', $state.current.name === 'app.analyze.webTracking.webActivity.channelAssets' ? that.tabMappings('channel_group') : 'Campaign Type'),
                field: 'ranged.campaign_group',
                defaultSort: false,
                descendingFirst: false,
                hideOnChildTable: true,
                visible: {
                    sourced: true,
                    custom: true,
                    last: true,
                    even: true
                },
                toggleOrder: {
                    sourced: 2,
                    custom: 2,
                    last: 2,
                    even: 2
                },
                link: function (web) {//this is for the expandable tables either in campaign groups or channels tab, so we're going to want the opposite because the expandables show channel assets in campaign group and campaigns in channels
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.webActivity.channelAssets' : 'app.analyze.campaigns.listAnalysis.campaigns';
                    return $scope.shref(pageUrl, {
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        model: $scope.query.model,
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        gf: web.ranged.revenue_group ? web.ranged.revenue_group : null,
                        influenceType: $scope.query.influenceType,
                    });
                },
                model: ['sourced', 'custom', "last", "even"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns'],
                noTile: true,
                noDrag: true
            },
            {
                label: that.headerMappings('ranged.revenue_group', 'Campaign Type'),
                field: 'ranged.revenue_group',
                defaultSort: false,
                descendingFirst: false,
                visible: {
                    sourced: true,
                    custom: true,
                    last: true,
                    even: true
                },
                toggleOrder: {
                    sourced: 3,
                    custom: 3,
                    last: 3,
                    even: 3
                },
                model: ['sourced', 'custom', "last", "even"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['groups'],
                noDrag: true,
                noTile: true,
                noWebTracking: true,
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.webActivity.channelAssets' : 'app.analyze.campaigns.listAnalysis.campaigns';
                    return $scope.shref(pageUrl, {
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        model: $scope.query.model,
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        gf: row.ranged.revenue_group,
                        influenceType: $scope.query.influenceType,
                    });
                }
            },
            //----- Single Touch: First Touch -----//
            {
                label: that.headerMappings('ranged.sourced.leads', 'Responses'),
                field: 'ranged.sourced.leads',
                is_default: true,
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: true,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    sourced: 4
                },
                model: ['sourced'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0',
                ungatedTile: true,
                ungatedLabel: 'Known Page Visits',
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.leads' : 'app.analyze.campaigns.campaignSpecific.attribution.leads';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: 'sourced',
                        tab: 'leads',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        influenceType: $scope.query.influenceType,
                    });
                }
            },
            {
                label: that.headerMappings('ranged.sourced.accounts_unique', 'Accounts Influenced'),
                field: 'ranged.sourced.accounts_unique',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: true,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    sourced: 5,
                },
                lazy_load: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_unique_accounts_and_leads' : 'campaign_unique_accounts_and_leads',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_unique_accounts_and_leads' : 'channel_unique_accounts_and_leads',
                model: ["sourced"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0'
            },
            {
                label: that.headerMappings('ranged.sourced.opptys', 'Oppt. Touches'),
                field: 'ranged.sourced.opptys',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: true,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    sourced: 6,
                },
                revAttributionField: true,
                model: ['sourced'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0',
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.opportunities' : 'app.analyze.campaigns.campaignSpecific.attribution.opportunities';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: 'sourced',
                        tab: 'opptys',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate
                    });
                }
            },
            {
                label: that.headerMappings('ranged.sourced.deals', 'Deal Touches'),
                field: 'ranged.sourced.deals',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: true,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    sourced: 7,
                },
                model: ['sourced'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                revAttributionField: true,
                filter: 'number:0',
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.deals' : 'app.analyze.campaigns.campaignSpecific.attribution.deals';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: 'sourced',
                        tab: 'deals',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate
                    });
                }
            },
            {
                label: that.headerMappings('ranged.sourced.pipeline', 'Pipeline Sourced'),
                field: 'ranged.sourced.pipeline',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: true,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    sourced: 8
                },
                revAttributionField: true,
                model: ['sourced'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'bfCurrency'
            },
            {
                label: that.headerMappings('ranged.sourced.revenue', 'Revenue Sourced'),
                field: 'ranged.sourced.revenue',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: true,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    sourced: 9
                },
                model: ['sourced'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                revAttributionField: true,
                filter: 'bfCurrency'
            },
            {
                label: that.headerMappings('ranged.sourced.lto_conv', 'LTO %'),
                field: 'ranged.sourced.lto_conv',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    sourced: 19
                },
                model: ['sourced'],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'percentage:0'
            },
            {
                label: that.headerMappings('ranged.sourced.otd_conv', 'OTD %'),
                field: 'ranged.sourced.otd_conv',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    sourced: 20
                },
                model: ['sourced'],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'percentage:0'
            },
            {
                label: that.headerMappings('ranged.sourced.cpl', 'CPL'),
                field: 'ranged.sourced.cpl',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    sourced: 13
                },
                lazy_load: true,
                ignoreLazy: ($state.current.data.pageType === 'web-activity' || ($scope.widget && $scope.widget.webActivity)) ? true : false, // ignore for web activity list page and DA tile, force to be true or false (instead of undefined)
                url: $state.current.data.pageType === 'web-activity' ? 'channel_perf_cpl' : 'campaign_perf_cpl',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_perf_cpl' : 'channel_perf_cpl',
                model: ['sourced'],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'bfCurrency',
            },
            {
                label: that.headerMappings('ranged.sourced.cpo', 'CPO'),
                field: 'ranged.sourced.cpo',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    sourced: 14
                },
                lazy_load: true,
                ignoreLazy: ($state.current.data.pageType === 'web-activity' || ($scope.widget && $scope.widget.webActivity)) ? true : false, // ignore for web activity list page and DA tile, force to be true or false (instead of undefined)
                url: $state.current.data.pageType === 'web-activity' ? 'channel_perf_cpo' : 'campaign_perf_cpo',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_perf_cpo' : 'channel_perf_cpo',
                model: ['sourced'],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'bfCurrency',
            },
            {
                label: that.headerMappings('ranged.sourced.cpd', 'CPD'),
                field: 'ranged.sourced.cpd',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    sourced: 15
                },
                lazy_load: true,
                ignoreLazy: ($state.current.data.pageType === 'web-activity' || ($scope.widget && $scope.widget.webActivity)) ? true : false, // ignore for web activity list page and DA tile, force to be true or false (instead of undefined)
                url: $state.current.data.pageType === 'web-activity' ? 'channel_perf_cpo' : 'campaign_perf_cpo',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_perf_cpo' : 'channel_perf_cpo',
                model: ['sourced'],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'bfCurrency',
            },
            {
                label: that.headerMappings('ranged.sourced.roi', 'ROI'),
                field: 'ranged.sourced.roi',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    sourced: 17
                },
                model: ['sourced'],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'percentage:0'
            },
            //----- Single Touch: Last Touch -----//
            {
                label: that.headerMappings('ranged.last.leads', 'Responses'),
                field: 'ranged.last.leads',
                is_default: true,
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: true,
                    even: false
                },
                toggleOrder: {
                    last: 4
                },
                model: ["last"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0',
                ungatedTile: true,
                ungatedLabel: 'Known Page Visits',
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.leads' : 'app.analyze.campaigns.campaignSpecific.attribution.leads';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: 'last',
                        tab: 'leads',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        influenceType: $scope.query.influenceType,
                    });
                }
            },
            {
                label: that.headerMappings('ranged.last.accounts_unique', 'Accounts Influenced'),
                field: 'ranged.last.accounts_unique',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: influenceTypes.useNewInfluenceType() ? false : true,
                    even: false
                },
                toggleOrder: {
                    last: 5
                },
                lazy_load: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_unique_accounts_and_leads' : 'campaign_unique_accounts_and_leads',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_unique_accounts_and_leads' : 'channel_unique_accounts_and_leads',
                model: ["last"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0'
            },
            {
                label: that.headerMappings('ranged.last.opptys', 'Oppt. Touches'),
                field: 'ranged.last.opptys',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: true,
                    even: false
                },
                toggleOrder: {
                    last: 6
                },
                revAttributionField: true,
                model: ["last"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0',
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.opportunities' : 'app.analyze.campaigns.campaignSpecific.attribution.opportunities';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: 'last',
                        tab: 'opptys',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate
                    });
                }
            },
            {
                label: that.headerMappings('ranged.last.deals', 'Deal Touches'),
                field: 'ranged.last.deals',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: true,
                    even: false
                },
                toggleOrder: {
                    last: 7
                },
                model: ["last"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                revAttributionField: true,
                filter: 'number:0',
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.deals' : 'app.analyze.campaigns.campaignSpecific.attribution.deals';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: 'last',
                        tab: 'deals',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate
                    });
                }
            },
            {
                label: that.headerMappings('ranged.last.pipeline', 'Pipeline Created'),
                field: 'ranged.last.pipeline',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: true,
                    even: false
                },
                toggleOrder: {
                    last: 8
                },
                revAttributionField: true,
                model: ['last'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'bfCurrency'
            },
            {
                label: that.headerMappings('ranged.last.revenue', 'Revenue Created'),
                field: 'ranged.last.revenue',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: true,
                    even: false
                },
                toggleOrder: {
                    last: 9
                },
                model: ['last'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                revAttributionField: true,
                filter: 'bfCurrency'
            },
            {
                label: that.headerMappings('ranged.last.roi', 'ROI'),
                field: 'ranged.last.roi',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    last: 14
                },
                model: ['last'],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'percentage:0'
            },
            {
                label: that.headerMappings('ranged.last.lto_conv', 'LTO %'),
                field: 'ranged.last.lto_conv',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    last: 16
                },
                model: ['last'],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'percentage:0'
            },
            {
                label: that.headerMappings('ranged.last.otd_conv', 'OTD %'),
                field: 'ranged.last.otd_conv',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    last: 17
                },
                model: ['last'],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'percentage:0'
            },
            //----- Multi Touch: Evenly Weighted -----//
            {
                label: that.headerMappings('ranged.even.leads', 'Responses'),
                field: 'ranged.even.leads',
                is_default: true,
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    even: 4
                },
                model: ["even"],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0',
                ungatedTile: true,
                ungatedLabel: 'Known Page Visits',
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.leads' : 'app.analyze.campaigns.campaignSpecific.attribution.leads';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: 'even',
                        tab: 'leads',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        influenceType: $scope.query.influenceType,
                    });
                }
            },
            {
                label: that.headerMappings('ranged.even.leads_unique', 'People Influenced'),
                field: 'ranged.even.leads_unique',
                defaultSort: false,
                is_default: true,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: true
                },
                toggleOrder: {
                    even: 7
                },
                lazy_load: true,
                ungatedTile: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_unique_accounts_and_leads' : 'campaign_unique_accounts_and_leads',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_unique_accounts_and_leads' : 'channel_unique_accounts_and_leads',
                model: ["even"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0',
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.leads' : 'app.analyze.campaigns.campaignSpecific.attribution.leads';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: 'even',
                        tab: 'leads',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        influenceType: $scope.query.influenceType,
                    });
                }
            },
            {
                label: that.headerMappings('ranged.even.accounts_unique', 'Accounts Influenced'),
                field: 'ranged.even.accounts_unique',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: true
                },
                toggleOrder: {
                    even: 8
                },
                lazy_load: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_unique_accounts_and_leads' : 'campaign_unique_accounts_and_leads',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_unique_accounts_and_leads' : 'channel_unique_accounts_and_leads',
                model: ["even"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0'
            },
            {
                label: that.headerMappings('ranged.opptys_unique', 'Opptys Influenced'),
                field: 'ranged.opptys_unique',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: true
                },
                toggleOrder: {
                    even: 9
                },
                lazy_load: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_total_amount' : 'campaign_total_amount',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_total_amount' : 'channel_total_amount',
                model: ["even"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0',
                model_field_override: true,
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.opportunities' : 'app.analyze.campaigns.campaignSpecific.attribution.opportunities';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: 'even',
                        tab: 'opptys',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate
                    });
                }
            },
            {
                label: that.headerMappings('ranged.deals_unique', 'Deals Influenced'),
                field: 'ranged.deals_unique',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: true
                },
                toggleOrder: {
                    even: 10
                },
                lazy_load: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_total_amount' : 'campaign_total_amount',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_total_amount' : 'channel_total_amount',
                model: ['even'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0',
                model_field_override: true,
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.deals' : 'app.analyze.campaigns.campaignSpecific.attribution.deals';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: 'even',
                        tab: 'deals',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate
                    });
                }
            },
            {
                label: that.headerMappings('ranged.even.opptys', 'Oppt. Touches'),
                field: 'ranged.even.opptys',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    even: 5
                },
                revAttributionField: true,
                model: ["even"],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0'
            },
            {
                label: that.headerMappings('ranged.even.deals', 'Deal Touches'),
                field: 'ranged.even.deals',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    even: 6
                },
                revAttributionField: true,
                model: ["even"],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0'
            },
            {
                label: that.headerMappings('ranged.even.roi', 'ROI'),
                field: 'ranged.even.roi',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    even: false,
                    last: false
                },
                toggleOrder: {
                    even: 19
                },
                model: ['even'],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'percentage:0'
            },
            {
                label: that.headerMappings('ranged.total_influenced_amount', 'Pipeline Influenced'),
                field: 'ranged.total_influenced_amount',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: true
                },
                toggleOrder: {
                    even: 11
                },
                lazy_load: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_total_amount' : 'campaign_total_amount',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_total_amount' : 'channel_total_amount',
                model: ['even'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'bfCurrency',
                model_field_override: true // this is b/c we cant depend on the model to the title selector. have to use the field too, but this one doesnt have the data in the model.
            },
            {
                label: that.headerMappings('ranged.total_influenced_revenue', 'Revenue Influenced'),
                field: 'ranged.total_influenced_revenue',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: true
                },
                toggleOrder: {
                    even: 12
                },
                lazy_load: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_total_amount' : 'campaign_total_amount',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_total_amount' : 'channel_total_amount',
                model: ['even'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'bfCurrency',
                model_field_override: true // this is b/c we cant depend on the model to the title selector. have to use the field too, but this one doesnt have the data in the model.
            },
            //----- Multi Touch: Custom -----//
            {
                label: that.headerMappings('ranged.custom.leads', 'Responses'),
                field: 'ranged.custom.leads',
                is_default: true,
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    last: false,
                    custom: false,
                    even: false,
                },
                toggleOrder: {
                    custom: 4
                },
                model: ['custom'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0',
                ungatedTile: true,
                ungatedLabel: 'Known Page Visits',
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.leads' : 'app.analyze.campaigns.campaignSpecific.attribution.leads';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: 'custom',
                        tab: 'leads',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        influenceType: $scope.query.influenceType,
                    });
                }
            },
            {
                label: that.headerMappings('ranged.custom.leads_unique', 'People Influenced'),
                field: 'ranged.custom.leads_unique',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: true,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    custom: 7
                },
                lazy_load: true,
                ungatedTile: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_unique_accounts_and_leads' : 'campaign_unique_accounts_and_leads',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_unique_accounts_and_leads' : 'channel_unique_accounts_and_leads',
                model: ["custom"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0',
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.leads' : 'app.analyze.campaigns.campaignSpecific.attribution.leads';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: 'custom',
                        tab: 'leads',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate,
                        influenceType: $scope.query.influenceType,
                    });
                }
            },
            {
                label: that.headerMappings('ranged.custom.accounts_unique', 'Accounts Influenced'),
                field: 'ranged.custom.accounts_unique',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: true,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    custom: 8
                },
                lazy_load: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_unique_accounts_and_leads' : 'campaign_unique_accounts_and_leads',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_unique_accounts_and_leads' : 'channel_unique_accounts_and_leads',
                model: ["custom"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0'
            },
            {
                label: that.headerMappings('ranged.opptys_unique', 'Opptys Influenced'),
                field: 'ranged.opptys_unique',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: true,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    custom: 9
                },
                lazy_load: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_total_amount' : 'campaign_total_amount',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_total_amount' : 'channel_total_amount',
                model: ["custom"],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0',
                model_field_override: true,
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.opportunities' : 'app.analyze.campaigns.campaignSpecific.attribution.opportunities';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: 'custom',
                        tab: 'opptys',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate
                    });
                }
            },
            {
                label: that.headerMappings('ranged.deals_unique', 'Deals Influenced'),
                field: 'ranged.deals_unique',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: true,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    custom: 10
                },
                lazy_load: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_total_amount' : 'campaign_total_amount',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_total_amount' : 'channel_total_amount',
                model: ['custom'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0',
                model_field_override: true,
                link: function (row) {
                    var pageUrl = $state.current.data.pageType === 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.deals' : 'app.analyze.campaigns.campaignSpecific.attribution.deals';
                    return $scope.shref(pageUrl, {
                        name: row.ranged.campaign_name,
                        campaignId: row.ranged.campaign_id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.query.dataSet,
                        modelType: 'custom',
                        tab: 'deals',
                        startDate: $scope.query.startDate,
                        endDate: $scope.query.endDate
                    });
                }
            },
            {
                label: that.headerMappings('ranged.custom.opptys', 'Oppt. Touches'),
                field: 'ranged.custom.opptys',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    custom: 5
                },
                revAttributionField: true,
                model: ['custom'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0'
            },
            {
                label: that.headerMappings('ranged.custom.deals', 'Deal Touches'),
                field: 'ranged.custom.deals',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    custom: 6
                },
                model: ['custom'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                revAttributionField: true,
                filter: 'number:0'
            },
            {
                label: that.headerMappings('ranged.custom.roi', 'ROI'),
                field: 'ranged.custom.roi',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    custom: 19
                },
                model: ['custom'],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'percentage:0'
            },
            {
                label: that.headerMappings('ranged.total_influenced_amount', 'Pipeline Influenced'),
                field: 'ranged.total_influenced_amount',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: true,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    custom: 11
                },
                lazy_load: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_total_amount' : 'campaign_total_amount',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_total_amount' : 'channel_total_amount',
                model: ['custom'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'bfCurrency',
                model_field_override: true // this is b/c we cant depend on the model to the title selector. have to use the field too, but this one doesnt have the data in the model.
            },
            {
                label: that.headerMappings('ranged.total_influenced_revenue', 'Revenue Influenced'),
                field: 'ranged.total_influenced_revenue',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: true,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    custom: 12
                },
                lazy_load: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_total_amount' : 'campaign_total_amount',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_total_amount' : 'channel_total_amount',
                model: ['custom'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'bfCurrency',
                model_field_override: true // this is b/c we cant depend on the model to the title selector. have to use the field too, but this one doesnt have the data in the model.
            },
            //----- Shared -----//
            {
                label: that.headerMappings('ranged.cost', 'Cost'),
                field: 'ranged.cost',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    sourced: 12,
                    last: 12,
                    even: 17,
                    custom: 17
                },
                model: ['sourced', 'custom', 'last', "even"],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'bfCurrency'
            }
        ];
        //----- Only on campaigns list, not on digital attribution -----//
        if (!webTrackingOnly) {
            headers.push({
                // Created Date is only on campaigns list
                label: that.headerMappings('ranged.campaign_created_date', 'Created Date'),
                field: 'ranged.campaign_created_date',
                defaultSort: false,
                descendingFirst: true,
                visible: {
                    sourced: false,
                    custom: false,
                    last: false,
                    even: false
                },
                toggleOrder: {
                    sourced: 21,
                    last: 17,
                    even: 20,
                    custom: 20
                },
                model: ['last', 'sourced', 'custom', 'even'],
                orgTypes: fullAndCampaignPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'date'
            });
        }
        //----- Only on digital attribution, not on campaigns list -----//
        if (webTrackingOnly) {
            // add ROAS (Return on Ad Spend) to each header
            ['sourced', 'custom', 'last', 'even'].forEach(model => {
                headers.push({
                    // ROAS is only on web activities
                    label: that.headerMappings('ranged.' + model + '.roas', 'ROAS'),
                    field: 'ranged.' + model + '.roas',
                    defaultSort: false,
                    descendingFirst: false,
                    visible: {
                        sourced: false,
                        custom: false,
                        last: false,
                        even: false
                    },
                    toggleOrder: {
                        sourced: 18,
                        last: 15,
                        even: 20,
                        custom: 20
                    },
                    model: [model],
                    orgTypes: fullAndCampaignPlatform,
                    reportTypes: ['campaigns', 'groups'],
                    filter: 'percentage:0',
                    hideOnChildTable: true
                });
            });

            if ($scope.isMediumConfigured) {
                headers.push({
                    label: that.headerMappings('ranged.parent_channel', that.tabMappings('channel_parent')),
                    field: 'ranged.parent_channel',
                    defaultSort: false,
                    descendingFirst: false,
                    hideOnChildTable: true,
                    visible: {
                        sourced: true,
                        custom: true,
                        last: true,
                        even: true,
                        noMedium: true
                    },
                    model: ['sourced', 'custom', "last", "even"],
                    orgTypes: fullAndCampaignPlatform,
                    reportTypes: ['campaigns', 'groups'],
                    noTile: true
                });
            }
        }
        //----- Shown/Shared Based on Configurations -----//
        headers.push({
            label: that.headerMappings('ranged.sourced.leads_net_new', 'Net New Leads'),
            field: 'ranged.sourced.leads_net_new',
            defaultSort: false,
            descendingFirst: true,
            visible: { sourced: false, custom: false, last: false, even: false },
            toggleOrder: {
                sourced: 22
            },
            model: ['sourced'],
            orgTypes: fullAndCampaignPlatform,
            reportTypes: ['campaigns', 'groups'],
            filter: 'number'
        });
        headers.push({
            label: that.headerMappings('ranged.total_touches', 'Total Campaign Members'),
            field: 'ranged.total_touches',
            defaultSort: false,
            descendingFirst: true,
            visible: { sourced: false, custom: false, last: false, even: false },
            model: ['sourced', 'last', 'even', 'custom'],
            orgTypes: fullAndCampaignPlatform,
            reportTypes: ['campaigns', 'groups'],
            filter: 'number'
        });

        headers.push({
            label: that.headerMappings('ranged.sourced.roi_pipeline', 'Pipeline ROI'),
            field: 'ranged.sourced.roi_pipeline',
            defaultSort: false,
            descendingFirst: true,
            visible: { sourced: false, custom: false, last: false, even: false },
            toggleOrder: {
                sourced: 16
            },
            model: ['sourced'],
            orgTypes: fullPlatform,
            reportTypes: ['campaigns', 'groups'],
            filter: 'percentage:0'
        });
        headers.push({
            label: that.headerMappings('ranged.last.roi_pipeline', 'Pipeline ROI'),
            field: 'ranged.last.roi_pipeline',
            defaultSort: false,
            descendingFirst: true,
            visible: { sourced: false, custom: false, last: false, even: false },
            model: ['last'],
            orgTypes: fullPlatform,
            reportTypes: ['campaigns', 'groups'],
            toggleOrder: {
                last: 13
            },
            filter: 'percentage:0'
        });
        headers.push({
            label: that.headerMappings('ranged.even.roi_pipeline', 'Pipeline ROI'),
            field: 'ranged.even.roi_pipeline',
            defaultSort: false,
            descendingFirst: true,
            visible: { sourced: false, custom: false, last: false, even: false },
            model: ['even'],
            orgTypes: fullPlatform,
            reportTypes: ['campaigns', 'groups'],
            toggleOrder: {
                even: 18
            },
            filter: 'percentage:0'
        });
        headers.push({
            label: that.headerMappings('ranged.custom.roi_pipeline', 'Pipeline ROI'),
            field: 'ranged.custom.roi_pipeline',
            defaultSort: false,
            descendingFirst: true,
            visible: { sourced: false, custom: false, last: false, even: false },
            model: ['custom'],
            orgTypes: fullPlatform,
            reportTypes: ['campaigns', 'groups'],
            toggleOrder: {
                custom: 18
            },
            filter: 'percentage:0'
        });

        const FTR_MODEL_INFLUENCE_TYPE = 'feature-model-influence_type';
        if ($rootScope.userData.platform === 'full' || isFeatureEnabled(FTR_MODEL_INFLUENCE_TYPE)) {
            headers = headers.concat([
                {
                    label: that.headerMappings('ranged.even.pipeline', 'Attributed Pipeline'),
                    field: 'ranged.even.pipeline',
                    defaultSort: false,
                    descendingFirst: true,
                    visible: {
                        sourced: false,
                        custom: false,
                        last: false,
                        even: true
                    },
                    toggleOrder: {
                        even: 13
                    },
                    revAttributionField: true,
                    model: ['even'],
                    orgTypes: fullAndCampaignPlatform,
                    reportTypes: ['campaigns', 'groups'],
                    filter: 'bfCurrency'
                },
                {
                    label: that.headerMappings('ranged.even.revenue', 'Attributed Revenue'),
                    field: 'ranged.even.revenue',
                    defaultSort: false,
                    descendingFirst: true,
                    visible: {
                        sourced: false,
                        custom: false,
                        last: false,
                        even: true
                    },
                    toggleOrder: {
                        even: 14
                    },
                    model: ['even'],
                    orgTypes: fullAndCampaignPlatform,
                    reportTypes: ['campaigns', 'groups'],
                    revAttributionField: true,
                    filter: 'bfCurrency'
                },
                {
                    label: that.headerMappings('ranged.custom.pipeline', 'Attributed Pipeline'),
                    field: 'ranged.custom.pipeline',
                    defaultSort: false,
                    descendingFirst: true,
                    visible: {
                        sourced: false,
                        custom: true,
                        last: false,
                        even: false
                    },
                    toggleOrder: {
                        custom: 13
                    },
                    model: ['custom'],
                    orgTypes: fullAndCampaignPlatform,
                    reportTypes: ['campaigns', 'groups'],
                    revAttributionField: true,
                    filter: 'bfCurrency'
                },
                {
                    label: that.headerMappings('ranged.custom.revenue', 'Attributed Revenue'),
                    field: 'ranged.custom.revenue',
                    defaultSort: false,
                    descendingFirst: true,
                    visible: {
                        sourced: false,
                        custom: true,
                        last: false,
                        even: false
                    },
                    toggleOrder: {
                        custom: 14
                    },
                    model: ['custom'],
                    orgTypes: fullAndCampaignPlatform,
                    reportTypes: ['campaigns', 'groups'],
                    revAttributionField: true,
                    filter: 'bfCurrency'
                },
            ]);
        }

        that.stages().forEach(function (stage) {
            var stageName = stage.split("::")[1];
            headers.push({
                label: that.headerMappings(('ranged.sourced.stages.' + stageName.replace(stageRegEx, "")), stageName),
                field: 'ranged.sourced.stages.' + stageName.replace(stageRegEx, ""),
                defaultSort: false,
                descendingFirst: true,
                visible: { sourced: false, custom: false, last: false, even: false },
                lazy_load: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_perf_stages' : 'campaign_perf_stages',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_perf_stages' : 'channel_perf_stages',
                model: ['sourced'],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0',
                tooltip: 'Number of ' + stageName + "s generated by source campaign"
            });
            headers.push({
                label: that.headerMappings(('ranged.last.stages.' + stageName.replace(stageRegEx, "")), stageName + "s"),
                field: 'ranged.last.stages.' + stageName.replace(stageRegEx, ""),
                defaultSort: false,
                descendingFirst: true,
                visible: { sourced: false, custom: false, last: false, even: false },
                lazy_load: true,
                url: $state.current.data.pageType === 'web-activity' ? 'channel_perf_stages' : 'campaign_perf_stages',
                webTrackInfoUrl: $state.current.data.pageType === 'web-activity' ? 'campaign_perf_stages' : 'channel_perf_stages',
                model: ['last'],
                orgTypes: fullPlatform,
                reportTypes: ['campaigns', 'groups'],
                filter: 'number:0',
                tooltip: 'Number of ' + stageName + "s generated by last touch campaign"
            });
        });
        return headers;
    };

    //We want to show campaigns if:
    // Your dataset is anything other than campaign activity date
    // If your dataset is campaign activity date, show campaigns that have a cost
    // Show campaigns that are in a first and last touch model
    // Show campaigns that are in a multi touch model or evenly weighted model if they have a cost
    // Show campaigns that have lead activity
    // If a stage column is showing, check if the campaign/group has stage activity for that stage...if it does, show the column
    // Finally, if the cost column visible (i.e. only on the page, not on the widget), and every other metric is zero, then don't show the campaign
    this.hasActivity = function (row, $scope) {
        if ($scope.widget && $scope.widget.query) {
            return $scope.widget.query.dataSet != 'membershipActivity' || ($scope.widget.query.model != 'even' && $scope.widget.query.model != 'custom' && row.cost) || row.cost || row[$scope.widget.query.model].leads || row[$scope.widget.query.model].opptys;
        }
        else {
            return $scope.query.dataSet != 'membershipActivity' || ($scope.query.model != 'even' && $scope.query.model != 'custom' && $scope.isHeaderShowing(_.find($scope.data.headers, { field: 'ranged.cost' })) && row.ranged.cost) || $scope.isHeaderShowing(_.find($scope.data.headers, { field: 'ranged.cost' })) && row.ranged.cost || row.ranged[$scope.query.model].leads || that.hasStageActivity(row, $scope);
        }
    };

    this.hasStageActivity = function (row, $scope) {
        if (row.ranged[$scope.query.model].stages) { //checks if stages column is showing
            return _.sumBy(_.filter($scope.data.headers, function (header) {
                return header.visible[$scope.query.model] && header.lazy_load;
            }), function (o) {
                return _.get(row, o.field);
            });
        }
    };

    this.headerMappings = function (headerKey, headerLabel) {
        var hdr;
        if ($rootScope.orgConfig.customColumns[$rootScope.userData.organizationId.toString()] && $rootScope.orgConfig.customColumns[$rootScope.userData.organizationId.toString()][headerKey]) {
            hdr = $rootScope.orgConfig.customColumns[$rootScope.userData.organizationId.toString()][headerKey];
        } else {
            hdr = headerLabel;
        }
        return hdr;
    };

    this.getCampaignStates = function ($state, userData) {
        var state,
            campaignStates;
        if ($state.current.controller) {
            state = $state.current;
        }
        else {
            state = $state.$current.parent.self;
        }
        campaignStates = angular.copy(_.findDeep($rootScope.nav, { name: state.name }));

        if (campaignStates.children) {
            campaignStates = campaignStates.children;
        }

        campaignStates = _.filter(campaignStates, function (child) {
            return utilities.checkStateForAddition(child, userData);
        });

        return campaignStates;
    };

    this.webLabelKeysByState = {
        'app.analyze.webTracking.webActivity.channel': 'channel_group',
        'app.analyze.webTracking.webActivity.channelAssets': 'channel_name',
        'app.analyze.webTracking.webActivity.channelMedium': 'channel_parent'
    };

    this.tabMappingsDefault = {
        'channel_name': 'UTM Campaign',
        'channel_group': 'UTM Source',
        'channel_parent': 'UTM Medium'
    };

    this.tabMappings = function (key) {
        var val,
            arr;
        // channel group, channel name or channel medium
        if (_.get($rootScope.orgConfig, 'web_activity.utm')) {
            arr = _.get($rootScope.orgConfig, 'web_activity.utm');
            _.forEach(arr, function (item) {
                if (_.isArray(item) && _.includes(item, key)) {
                    val = item;
                }
            });
        }
        if (val) {
            val = that.formatWebLabel(val[1]);
        } else {
            val = that.tabMappingsDefault[key];
        }
        return val;
    };

    this.formatWebLabel = function (val) {
        val = val.split(/_|-/);
        //check for UTM
        _.forEach(val, function (v, i) {
            if (v === 'utm') {
                val[i] = _.upperCase(v);
            } else {
                val[i] = _.upperFirst(v);
            }
        });
        val = val.join(" ");
        return val;
    };

    this.untrackedData = function (row, key, type, tableState, displayedState, currentModel) {
        var untrackedRow = _.findDeep(_.get(row, key), function (r) { //find the untracked row
            return _.includes(r.ranged.revenue_group, '(Untracked)');
        });
        if (untrackedRow) { //if the untracked row is there, remove it so that you can calculate the totals without it, then readd it at the end
            _.remove(_.get(row, key), function (object) { return object === untrackedRow; });
        }
        that.updateTotals(_.get(row, key), type, row, true, currentModel); //calculate the totals for the subtable for below use
        var rowTotals = _.omit(row, ['totalsGroupExpanded', 'webTrackingGroupData', 'webTrackingData', 'webTrackingDataCall', 'webGroupDataTable', 'tableStateWebActivityChannels', 'totalsExpanded', 'tableStateWebActivityChannelAssets']), //get's the totals of the parent row
            childTotals = row['totals' + (type === 'groups' ? 'Group' : '') + 'Expanded'], //get the totals of the child row (without untracked)
            diff = utilities.subtractObjects([rowTotals, childTotals]); //calculates the difference between each field and get's you the untracked row

        diff.ranged.revenue_group = '(Untracked)'; //changes the name of the revenue_group to be 'Untracked'
        diff.ranged.campaign_name = '--'; //changes the name of the campaign_name to be '--'

        if (diff.ranged[currentModel]) {
            _.get(row, key).push(diff); //pushes the untracked row into the subset table
        }

        // eslint-disable-next-line no-unused-vars
        angular.forEach(userModels, function (model) {
            that.updateAggregateTotals(currentModel, diff, null);
        });

        //the below prevents the tableState and safe src from being out of sync
        if (_.get(row, tableState) && _.get(row, tableState).totalItems) {
            var tableItems = _.get(row, tableState).items, //this gets you what's inside the table
                untrackedIndex = _.findIndex(tableItems, { ranged: { revenue_group: diff.ranged.revenue_group } }); //find the index of the untracked row that's inside tableItems (this untracked row will NOT contain the lazy loaded numbers)

            if (untrackedIndex !== -1) { //if you've found the untracked row, replace it in the tableItems with the diff object
                tableItems[untrackedIndex] = diff; //this now has the lazy loaded numbers
            }

            if (displayedState && (tableItems.length <= row[displayedState].length)) { //this is for instances where there is less than one page of data shown, where we would need to replace the untracked row in the displayed collection with the diff object
                var displayedCollection = _.get(row, displayedState), //get the displayed collection
                    // eslint-disable-next-line no-redeclare
                    untrackedIndex = _.findIndex(displayedCollection, { ranged: { revenue_group: diff.ranged.revenue_group } }); //find the index of the untracked row that's inside the displayed collection (this untracked row will NOT contain the lazy loaded numbers)
                if (untrackedIndex !== -1) { //if you've found the untracked index, replace it in the displayed collection with the diff object
                    displayedCollection[untrackedIndex] = diff; //this now has the lazy loaded numbers
                }
            }
        }
    };

    /**
    *  @param {object} d [current items in the table]
    *  @param {string} type [which total you want to update (campaigns, groups, medium)]
    *  @param {object} r [where your current data lives ie $scope.data]
    *  @param {boolean} expanded [whether the table is a child table]
    *  @param {string} currentModel [what model your data is in]
    */
    this.updateTotals = function (d, type, r, expanded, currentModel) {
        var destination;

        if (expanded) {
            destination = type === 'campaigns' ? 'totalsExpanded' : 'totalsGroupExpanded';
        } else {
            destination = type === 'campaigns' ? 'totals' : type === 'groups' ? 'totalsGroups' : 'totalsMedium';
        }

        //check the number of campaigns/campaign groups that have cpl, cpd and cpo
        var cplCount = 0,
            cpoCount = 0,
            cpdCount = 0;
        // eslint-disable-next-line no-unused-vars
        angular.forEach(d, function (key, i) {
            if (key.ranged[currentModel].cpl) {
                cplCount++;
            }
            if (key.ranged[currentModel].cpo) {
                cpoCount++;
            }
            if (key.ranged[currentModel].cpd) {
                cpdCount++;
            }
        });

        // eslint-disable-next-line no-unused-vars
        r[destination] = utilities.sumObjects(d, function (obj, value, key, parent) {
            // ['=roi','=cpl','=cpd','=cpo','ltd_','lto_','otd_']
            var avg;
            if (key === 'cpl') {
                avg = _.isNaN(value / cplCount) ? 0 : value / cplCount;
                return avg;
            } else if (key === 'cpd') {
                avg = _.isNaN(value / cpdCount) ? 0 : value / cpdCount;
                return avg;
            } else if (key === 'cpo') {
                avg = _.isNaN(value / cpoCount) ? 0 : value / cpoCount;
                return avg;
            } else if (_.contains(key, 'ltd_') || _.contains(key, 'otd_')) {
                return value / d.length;
            }
        }, ['campaign_created_date', 'campaign_id', 'campaign_group', 'campaign_name', 'parent_channel', 'accounts_unique', 'leads_unique', 'opptys_unique', 'deals_unique', 'total_influenced_amount', 'total_influenced_revenue']); //omit all strings with sumObject, making them empty string

        //calculate totals that are NOT sums of each column. ie roi uses the total rev - total cost / total cost
        _.forEach(userModels, function (model) {
            if (r[destination] && r[destination].ranged) {
                if (r[destination].ranged.cost === 0) {
                    r[destination].ranged[model.key].roi = 0;
                    r[destination].ranged[model.key].roi_pipeline = 0;
                } else {
                    var roi = (r[destination].ranged[model.key].revenue - r[destination].ranged.cost) / r[destination].ranged.cost;
                    var roi_pipe = (r[destination].ranged[model.key].pipeline - r[destination].ranged.cost) / r[destination].ranged.cost;
                    r[destination].ranged[model.key].roi = roi < 0 ? 0 : roi;
                    r[destination].ranged[model.key].roi_pipeline = roi_pipe < 0 ? 0 : roi_pipe;
                }

                if (r.aggregateTotalsVisible) {
                    that.updateAggregateTotals(model, r[destination], r.aggregateTotalsVisible);
                }
            }
        });

        if (r[destination].ranged) {
            r[destination].ranged.sourced.lto_conv = (r[destination].ranged.sourced.opptys) / (r[destination].ranged.sourced.leads);
            r[destination].ranged.sourced.otd_conv = ((r[destination].ranged.sourced.deals) / r[destination].ranged.sourced.opptys) || 0;
        }
    };

    this.updateAggregateTotals = function (model, destination, aggregateTotals) {
        if (!aggregateTotals || Object.keys(aggregateTotals).length < 1 || !destination)
            return;

        aggregateTotalKeys.forEach(function (col) {
            var cell = null;
            if (_.isDefined(destination.ranged[col])) {
                cell = destination.ranged;
            } else if (_.isDefined(destination.ranged[model.key]) && _.isDefined(destination.ranged[model.key][col])) {
                cell = destination.ranged[model.key];
            }

            if (cell) {
                if (aggregateTotals[col])
                    cell[col] = aggregateTotals[col];
                else if (aggregateTotals[model.key] && aggregateTotals[model.key][col])
                    cell[col] = aggregateTotals[model.key][col];
                else
                    cell[col] = 0;
            }
        });
    };

    /**
     * [addToRows: this function adds the data from the previous api call to the current table data]
     * @param {[array]}     dataSet     [current data in table]
     * @param {[array]}     newSet      [data that came back from the api]
     * @param {[type]}      id[key]
     * @param {[array]}     models      [attribution models]
     * @param {[string]}    keys        [key of the column you are lazy loading]
     * @param {[string]}    part        [object path (usually ranged)]
     * @param {[int]}       emptyValue  [0]
     */
    this.addToRows = function (dataSet, newSet, id, models, keys, part, emptyValue) {
        if (newSet && dataSet) {
            dataSet.forEach(function (row) {
                var newGroup = newSet[row[part][id]];

                if (models.length === 0) {
                    keys.forEach(function (key) {
                        row[part][key] = newGroup ? newGroup[key] : emptyValue;
                    });
                }

                models.forEach(function (model) {
                    keys.forEach(function (key) {
                        row[part][model][key] = newGroup ? (newGroup[key] !== undefined ? newGroup[key] : newGroup[model][key]) : emptyValue;
                    });
                });

                //getting deleted
                if (newGroup) {
                    delete newSet[row[part][id]];
                }
            });
        }
    };

    this.getAttrModels = function () {
        var attrModels = [];
        if ($rootScope.userData.platform === 'full') {
            // send all models
            attrModels = angular.copy(userModels);
        } else {
            angular.forEach(angular.copy(userModels), function (model) {
                // omit custom when platformType is campaign
                if ($rootScope.userData.platform === 'campaign' && model.key !== 'custom') {
                    attrModels.push(model);
                }
                // only send even for standard platformType
                if ($rootScope.userData.platform !== 'campaign' && model.key === 'even') {
                    attrModels.push(model);
                }
            });
        }

        return attrModels;
    };
}]);
