(function () {
    'use strict';

    app.directive('configRadio', function () {
        return {
            restrict: 'E',
            template: require('./config-radio.html'),
            controller: radioCtrl,
            controllerAs: "$ctrl",
            bindToController: true,
            scope: {
                radioOptions: '=',
                radioModel: '=',
                onChange: '&',
                params: "="
            },
            transclude: true
        };
    });

    radioCtrl.$inject = [];

    /**
     * Controller for the directive.
     */
    function radioCtrl() { }

})();
