app.controller('marketingImpactCtrl', ['$scope', '$rootScope', '$http', '$window', '$location', '$state', '$filter', 'utilities', 'api', '_', '$timeout', 'filters', 'cohorts', 'userModels', 'influenceTypes', function ($scope, $rootScope, $http, $window, $location, $state, $filter, utilities, api, _, $timeout, filters, cohorts, userModels, influenceTypes) {
    var dataCall;

    // also look at impact_chart-drtv.js for chart definitions etc..
    function drawTotalRevenueChart() {
        var chart_data = [];
        var aRows = $filter('filter')($scope.total_revenue.actual, { visible: true });

        var type = $scope.query.opptyType;
        for (var i = 0; i < aRows.length; i++) {
            chart_data[i] = [
                aRows[i].month,
                0,
                aRows[i][type] ? aRows[i][type].value : 0,
                aRows[i]['marketing_' + type] ? aRows[i]['marketing_' + type].value : 0
            ];
        }

        var firstNonZeroIndex = _.findIndex(chart_data, function (value) {
            return value[2] > 0 || value[3] > 0;
        });
        var lastNonZeroIndex = chart_data.length;

        var filteredStartZeroBars = chart_data.slice(firstNonZeroIndex, lastNonZeroIndex);
        filteredStartZeroBars.unshift(['Month', 'Actual Revenue', 'Marketing Influenced Revenue']);
        $scope.chart.data = filteredStartZeroBars;
        $scope.chart.query = $scope.query;

        if (type === 'deal') {
            var oliveGreen = '#97CD78';
            $scope.chart.config.colors = [oliveGreen, '#005589'];

            $scope.chart.config.legend[0].color = oliveGreen;
        } else {
            $scope.chart.config.colors = ['#D88219', '#005589'];
        }

        $scope.$broadcast('$$rebind::refreshData');
    }

    function loadRevenue() {
        if (dataCall) { dataCall.abort(); }

        $state.current.data.loading = true;

        var params = $scope.query;
        var opptyType = params.opptyType;
        if (opptyType === 'oppty') {
            params.influenceType = 'pre';
        }

        $scope.total_revenue = { actual: [] };
        (dataCall = api.get([{ api: 'marketing_impact', params: $scope.query }])).then(function (data) {
            $scope.data.renderChart = (data.marketing_impact.data.length > 0);
            if ($scope.data.renderChart) {
                $scope.total_revenue.actual = data["marketing_impact"].data;

                // include startDate as unixTimestamp in each item of total_revenue.actual, so you can sort chronologically
                angular.forEach($scope.total_revenue.actual, function (item) {
                    var cohortObj = _.find($scope.data.cohorts, { value: item.month });
                    if (cohortObj && cohortObj.start_date) {
                        item.timestamp = angular.copy(cohortObj.start_date);
                    }
                    item.sortMonth = getSortOrder(item.month);
                });
                checkForNulls($scope.total_revenue, $scope.query.opptyType);
                $scope.total_revenue.actual = $scope.total_revenue.actual.map(function (r) {
                    r.visible = true;
                    r.calculatedCount = calculatePercentage(r['marketing_' + opptyType].count, r[opptyType].count);
                    r.calculatedValue = calculatePercentage(r['marketing_' + opptyType].value, r[opptyType].value);

                    return r;
                });

                if ($scope.total_revenue.actual.length > 1) {
                    $scope.last_row = $scope.total_revenue.actual[$scope.total_revenue.actual.length - 2];
                }
                $scope.sumTotals('totalsActual');
                drawTotalRevenueChart();
            }
        }).finally(function () {
            $state.current.data.loading = false;
        });
    }

    function checkForNulls(data, type) {
        data.actual.map(function (r) {
            if (!r[type]) {
                r[type] = {
                    value: 0,
                    count: 0
                };
            }

            if (!r['marketing_' + type]) {
                r['marketing_' + type] = {
                    value: 0,
                    count: 0
                };
            }
        });
    }

    function calculatePercentage(a, b) {
        return $filter('number')((a / b) * 100, 2);
    }

    $scope.chart = {
        //where data is the data for the chart
        config: {
            //optional title for the chart
            title: 'Total Revenue',
            //colors could be an array or a function that returns something
            yAxis: {
                //label for the yaxis
                label: 'Marketing Influence',
                //optional format for the yaxis
                format: function (d) {
                    return $filter('nrFormat')(d, true, true);
                },
                //optional init function
                // init: function (axis) {
                // do some custom stuff
                // }
            },
            xAxis: {
                //label for the xaxis
                //label: 'xaxislabel',
                //optional format for the xaxis
                format: '.f',
                //optional init function
                // init: function (axis) {
                // do some custom stuff
                // }
            },
            legend: [{ label: 'Actual', color: '#D88219' }, { label: 'Marketing Influenced', color: '#005589' }],
            //optional init function lets you do custom stuff during the init function executed in the directive
            // init: function (chart) {
            // chart would be an object passed in containing all of the needed data associated with the d3 chart
            // },
            //optional update function
            // update: function (chart) {
            // chart would be an object passed in containing all of the needed data associated with the d3 chart
            // },
            //optional resize function
            //resize: function (chart) {
            //    // chart would be an object passed in containing all of the needed data associated with the d3 chart
            //},
            // destroy: function (chart) {
            // chart would be an object passed in containing all of the needed data associated with the d3 chart
            // }
        },
        type: 'total-revenue'
    };

    $scope.toggleBars = function () {
        $rootScope.$broadcast('detailChanged', "toggled bars visibility");
        drawTotalRevenueChart();
    };

    $scope.runAllQuery = function () {
        checkForNulls($scope.total_revenue, $scope.query.opptyType);
        loadRevenue();
    };

    $scope.opptyLink = function (d, raw) {
        var p = {
            dateType: ($scope.query.opptyType == 'oppty' ? 'created' : 'closed'),
            closedType: ($scope.query.opptyType == 'oppty' ? 'any' : 'closed')
        };
        if ($scope.query.period == 'month') {
            var parts = d.split(' '),
                date = new Date(Number(parts[1]), utilities.get_month_index(parts[0])),
                m = moment(date),
                start = date.getTime(),
                end = m.add(1, 'month').subtract(1, 'day')._d.getTime();
            p.cohort = 'time';
            p.startDate = start;
            p.endDate = end;
        } else {
            p.cohort = d.replace(' 20', '');
        }
        if (raw) {
            return p;
        } else {
            return $scope.shref('app.analyze.opportunities.listAnalysis', p);
        }
    };

    $scope.redirectAndSendMetric = function(url) {
        $window.location.href = url;
    };

    function getSortOrder(month) {
        var parts = month.split(' ');
        return new Date(Number(parts[1]), utilities.get_month_index(parts[0]));
    }

    $scope.routeToOppty = function (d) {
        var p = $scope.opptyLink(d, true);
        p.model = 'any';
        return $scope.shref('app.analyze.opportunities.listAnalysis', p);
    };

    $scope.routeToOpptyInfluenced = function (d) {
        var p = $scope.opptyLink(d, true);
        p.model = $scope.query.influenceType === 'any' ? 'even' : $scope.query.influenceType;
        return $scope.shref('app.analyze.opportunities.listAnalysis', p);
    };

    $scope.sumTotals = function (type) {
        $scope.data[type] = utilities.sumObjects($scope.total_revenue.actual, null, ['month']);
    };

    $scope.changeRevenuePipeline = function () {
        utilities.queryString({ opptyType: $scope.query.opptyType });
        if ($scope.total_revenue) {
            checkForNulls($scope.total_revenue, $scope.query.opptyType);
            drawTotalRevenueChart();
        }
    };

    $scope.checkAll = function () {
        $scope.selectedAll = !$scope.selectedAll;
        angular.forEach($scope.total_revenue.actual, function (row) {
            row.visible = $scope.selectedAll;
        });
        $scope.toggleBars();
    };

    (function init() {
        $scope.selectedAll = true;
        $scope.exportMetricTag = "marketingImpactExport";
        $state.current.data.loading = true;
        $scope.platform = $rootScope.userData.platform;
        $scope.query = {
            period: 'quarter',
            opptyType: 'deal',
            modelType: 'even',
            cohort: 'years2RoundDown',
            influenceType: 'any',
        };
        $scope.data = {
            cohorts: utilities.formatCohorts(cohorts, ['all', 'custom', 'year', 'agoRoundDown']),
            models: userModels,
            tableData: [],
            accountFilter: false,
            filter: 'bfCurrency',
            influence: influenceTypes.attributionTypeMapping,
        };

        utilities.combine($scope.query, $state.params);

        if (!_.isDefined(_.find($scope.data.models, { key: $scope.query.modelType }))) {
            $scope.query.modelType = "even";
        }

        $scope.data.renderChart = true;
        $state.current.data.loading = false;

        $scope.$on('$destroy', function () {
            if (dataCall) { dataCall.abort(); }
        });

        $scope.$on('filtersChanged', function () {
            loadRevenue();
        });

        //this causes the first load
        $scope.$on('chartInitDone', function () {
            loadRevenue();
        });

    })();
}]);
