import * as COMPONENT_HTML from './component.html';
import './component.less';
import {IDirective, IScope, IAugmentedJQuery, IAttributes, INgModelController} from 'angular';
import {CHUNK_TIME} from '../../constants/constants';

class LoaderCtrl {
    static $inject: Array<string> = ['$interval'];

    private interval: number;
    private current: number;
    private barStyle: any;
    private show: boolean;

    public constructor(public $interval: any) {
        this.interval = 0;
        this.current = 0;
        this.barStyle = {
            width: this.current + '%',
        };
        this.show = false;
        return;
    }

    private updateBar(value: number) {
        this.current = value;
        this.barStyle.width = value + '%';
    }

    public start(value: number): void {
        this.show = value > CHUNK_TIME;

        let progress = 0;
        if (this.interval) {
            this.$interval.cancel(this.interval);
            this.interval = 0;
        }

        this.updateBar(progress);
        this.interval = this.$interval(() => {
            if (progress < 100) {
                progress += .1;
            } else {
                this.$interval.cancel(this.interval);
                this.interval = 0;
            }
            this.updateBar(Math.round(progress));
        }, value);
    }
}

export class Loader implements IDirective {
    public restrict = 'E';
    public controller: any = LoaderCtrl;
    public controllerAs = '$ctrl';
    public bindToController = true;
    public template: any = COMPONENT_HTML;
    public scope: IScope = {
        totalSeconds: '=',
        message: '=',
    };

    public constructor() {
        return;
    }

    public link = (scope: IScope, elem: IAugmentedJQuery, attributes: IAttributes, ctrl: INgModelController) => {
        scope.$watch('$ctrl.totalSeconds', (value) => {
            ctrl.start(value);
        });
    };

    public static Factory(): IDirective {
        return new Loader();
    }
}
