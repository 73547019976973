/**
 * Sets constants for sfdc oauth module.
 */
(function () {
    'use strict';

    angular
        .module('sfdc')
        .constant('SfdcConstants',
            {
                API: {
                    GET_ORGANIZATIONS: '/admin/organizations',
                    IDENTIFY: '/oauth/identify',
                    DESCRIBE_FIELDS: '/oauth/describeFields',
                    DESCRIBE_TABLE: '/oauth/describeTable',
                    SHOW_ROW: '/oauth/showRow',
                    USER_INFO: '/oauth/user_info'
                },
                LABELS: {
                    TITLE: 'SFDC Internal Configuration',
                    TAB1: 'Identify',
                    TAB2: 'Describe Fields',
                    TAB3: 'Describe Table',
                    TAB4: 'Show Row',
                    TAB5: 'User Info',
                    ORGANIZATION: 'Organization',
                    TABLE_NAME: 'Table Name',
                    ID: 'ID',
                    BUTTON: 'Request JSON',
                    PLACEHOLDER: 'tableName',
                }
            });
})();
