app.service('campaign', ['$b', '$rootScope', '_', '$filter', 'utilities', 'userModels', function ($b, $rootScope, _, $filter, utilities, userModels) {
    this.getData = function () {
        return {
            modelTypes: this.getAttrModels(),
            cohorts: utilities.formatCohorts($rootScope.rawCohorts, ['all', 'ago', 'custom', 'year', 'quarter', 'toDate', 'lastFull']),
            trends: {},
            allTime: { value: 'All Time', key: '*NONE*', scope: '' },
            dataSets: [
                { label: 'Activity In This Cohort', key: 'membershipActivity' },
                { label: 'Oppts Created In This Cohort', key: 'opptyCreatedDate' },
                { label: 'Oppts That Have A Close Date In This Cohort', key: 'opptyCloseDate' }
            ]
        };
    };

    this.getAttrModels = function () {
        var attrModels = [];
        if ($rootScope.userData.platform !== 'full') {
            angular.forEach(angular.copy(userModels), function (model) {
                if (model.key === 'even') {
                    model.name = 'Marketing Influenced';
                    attrModels.push(model);
                }
            });
        }
        else {
            attrModels = angular.copy(userModels);
        }
        return attrModels;
    };

    this.makeTableTitle = function (state) {
        return state ? state.config.navName : "";
    };

    this.leadFooterTDSpan = function (userData, campaign) {
        if (campaign) {
            return userData.permissions.web_tracking && campaign.is_expandable ? 8 : 6;
        } else {
            return 0;
        }
    };

    this.opptyFooterTDSpan = function (userData, campaign) {
        if (campaign) {
            return (userData.permissions.web_tracking && campaign.is_expandable ? 10 : 8) - (userData.platform !== 'full' ? 1 : 0);
        } else {
            return 0;
        }
    };

    this.dealFooterTDSpan = function (userData, campaign) {
        if (campaign) {
            return (userData.permissions.web_tracking && campaign.is_expandable ? 9 : 7) - (userData.platform !== 'full' ? 1 : 0);
        } else {
            return 0;
        }
    };
}]);
