import { module as angularModule } from 'angular';
import {
    format,
} from 'date-fns';

import { APP_BRIGHTFUNNEL } from '../../../../constants';
import {
    IScope,
    ITrendingKpiQuery,
} from '../trending-kpis-widget/component';
import {
    IBaseQueryContainer,
    SRVC_WIDGET_QUERY_STATE,
    WidgetQueryStateService
} from '../../services/query.service';
import * as CONSTANTS from '../../constants';
import { ITrendingKpiHeaderFormat } from '../../interfaces/trending-kpi.interface';
import { IKpiResponse } from '../../../models/scorecard/kpi-response/model';
import { SRVC_WIDGET_RESPONSE_STATE, WidgetResponseStateService } from '../../services/kpi-response.service';
import './component.less';
import { SRVC_TERMINUS_HUB, TerminusHubService } from '../../../terminus-hub/terminus-hub.service';

const app = angularModule(APP_BRIGHTFUNNEL);

export class TrendingKpiTileController {
    static $inject: string[] = [
        '$scope',
        '_',
        SRVC_WIDGET_QUERY_STATE,
        SRVC_WIDGET_RESPONSE_STATE,
        SRVC_TERMINUS_HUB
    ];

    public queryId: string;
    public query: ITrendingKpiQuery;
    public title: string;
    public subtitle: string;
    public achievedGoal: number;
    public headerFormat: ITrendingKpiHeaderFormat;
    public kpiData: IKpiResponse;

    setupQueryServiceWatch() {
        const group = ['ctrl.widgetQueryStateService.widgetQueries', 'ctrl.widgetResponseStateService.kpiResponses'];
        this.$scope.$watchGroup(group, value => {
            const widgetQuery: Record<string, IBaseQueryContainer> = value[0];

            if (widgetQuery[this.queryId]
                && !this._.isEqual(this.widgetQueryStateService.widgetQueries[this.queryId].query, widgetQuery[this.queryId].query)) {
                this.query = this._.cloneDeep(widgetQuery[this.queryId].query);
                this.setInformation();
            }

            if (value[1]) {
                const kpiResponses: Record<string, IKpiResponse> = value[1];
                this.kpiData = kpiResponses[this.queryId];

                if (this.query.visualization === 'gauge'
                    && this.query.gaugeRangeMax
                    && !this.query.trendingGoal) {
                    this.query.trendingGoal = this.query.gaugeRangeMax;
                }

                if (this.query.trendingGoal !== undefined
                    && this.query.trendingGoal !== null
                    && this.kpiData
                    && this.kpiData.totalsInterval) {
                    this.achievedGoal = this.kpiData.totalsInterval.total && this.query.trendingGoal
                        ? this.kpiData.totalsInterval.total / this.query.trendingGoal
                        : 0;
                }
            }
        }, true);
    }

    setupWatches() {
        this.setupQueryServiceWatch();
    }

    setInformation(): void {
        const titleInfo = CONSTANTS.TRENDING_KPI_NEW_UI_TITLE
            .find(item => item.type === this.query.kpi
                && (!item.progression || item.progression === this.query.progression));
        this.headerFormat = CONSTANTS.TRENDING_KPI_NEW_UI_HEADER
            .find(item => item.type === this.query.kpi);
        this.title = titleInfo && titleInfo.label || '';
        this.subtitle = '';
        if (this.query.customStartDate) {
            this.subtitle = `${format(new Date(this.query.customStartDate), 'MMM d, yyyy')} - ${format(new Date(this.query.customEndDate), 'MMM d, yyyy')}`;
        } else if (this.query.cohort) {
            this.subtitle = this.query.cohort.grouping === 'Ago From Today' ?
                `${this.query.cohort.name} Ago` :
                this.query.cohort.name;
        }
    }

    constructor (
        public $scope: IScope,
        public _: any,
        public widgetQueryStateService: WidgetQueryStateService,
        public widgetResponseStateService: WidgetResponseStateService,
        private terminusHubService: TerminusHubService,
    ) {
        this.queryId = this.$scope.widget.queryId;
        this.query = this.getWidgetQuery(this.queryId);
        this.setInformation();
        this.setupWatches();
    }

    private getWidgetQuery(queryId: string): ITrendingKpiQuery {
        if (this.isWidgetStoredInState(queryId)) {
            return this.widgetQueryStateService.widgetQueries[queryId].query;
        }

        return this.$scope.widget.query;
    }

    private isWidgetStoredInState(queryId: string): boolean {
        return !!this.widgetQueryStateService.widgetQueries[queryId];
    }
}

export const CTRL_TRENDING_KPI_TILE = 'trendingKpiTileCtrl';
app.controller(CTRL_TRENDING_KPI_TILE, TrendingKpiTileController);
