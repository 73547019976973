app.controller('accountTrendingCtrl', ['$scope', '$state', 'api', 'utilities', '$filter', 'account', 'cohorts', 'trend', '$timeout', '_', function ($scope, $state, api, utilities, $filter, account, cohorts, trend, $timeout, _) {

    var loadTrendingInfoCall;
    var loadInfoCall;
    //------------------------ scope methods ------------------------//
    $scope.runAllQuery = function () {
        getTrend();
    };

    $scope.changeFilter = function () {
        $scope.$broadcast('filterData');
        utilities.queryString({ filter: $scope.query.filter });
    };

    $scope.picks = function () {
        return $scope.data.chartPick;
    };

    $scope.registerChanges = function (nv, ov) {
        if (!angular.equals(nv.query.metric, ov.query.metric)) {
            updateChart();
            utilities.queryString({ metric: nv.query.metric });
        }
    };

    //------------------------ private methods ------------------------//
    function getTrend() {

        if (loadTrendingInfoCall) { loadTrendingInfoCall.abort(); }

        $state.current.data.loading = true;
        (loadTrendingInfoCall = api.get('account/load_trending_info', $scope.query)).then(function (data) {
            var allData = data;

            trend.convertDate(allData.data);

            // eslint-disable-next-line no-unused-vars
            angular.forEach(allData.data.data, function (value, i) {
                value.frequency = new Date(value.frequency).getTime();
                value.engaged = value.contact_total && value.contact_active ? value.contact_active / value.contact_total : 0;
                //value.percentageEngaged = value.account_activity ? value.account_activity / allData.data.total_accounts : 0;
            });

            $scope.data.accountsTrending = allData.data;
            $scope.chart.data = allData.data.data;
            $scope.data.step = allData.data.frequency;
            $scope.chart.chart.axis.x.label.text = $scope.data.step;
            $timeout(function () {
                updateChart();
            }, 0, false);
            $state.current.data.loading = false;
        });
    }

    function updateChart() {
        if ($scope.query.metric == 'average') {
            // eslint-disable-next-line no-unused-vars
            $scope.chart.chart.axis.y.tick.format = function (d, i) {
                var places,
                    item;

                if ($scope.chart.data && $scope.chart.data.length) {
                    item = utilities.last($scope.chart.data)[_.find(Object.keys($scope.data.chartTypes[$scope.query.metric]), function (k) {
                        return k != 'frequency';
                    })];
                }

                if (item) {
                    if (item <= 0.01) {
                        places = 2;
                    } else if (item >= 0.1) {
                        places = 1;
                    } else {
                        places = 0;
                    }
                }
                var result = $filter('percentage')(d, places);

                return result;
            };
        } else {
            delete $scope.chart.chart.axis.y.tick.format;
        }
        $scope.chart.chart.axis.y.label.text = $scope.data.chartTitles[$scope.query.metric];
        $scope.chart.dimensions = $scope.data.chartTypes[$scope.query.metric];
        $scope.chart.chart.title.text = $scope.data.chartTitles[$scope.query.metric] + ' Over ' + _.startCase(_.find($scope.data.cohorts, { key: $scope.query.cohort }).value);
    }

    //------------------------ init ------------------------//
    (function init() {
        $state.current.data.loading = true;
        $scope.data = {
            chartTitles: {
                average: 'Avg % Contacts Engaged by Marketing',
                contacts: 'Unique Engaged Contacts',
                activity: 'Marketing Engagment'
            },
            chartTypes: {
                average: {
                    "frequency": {
                        axis: "x",
                        dataType: "datetime"
                    },
                    "engaged": {
                        type: "area",
                        name: "Avg % Contacts Engaged"
                    }
                },
                contacts: {
                    "frequency": {
                        axis: "x",
                        dataType: "datetime"
                    },
                    "contact_active": {
                        type: "area",
                        name: "Unique Engaged Contacts"
                    }
                },
                activity: {
                    "frequency": {
                        axis: "x",
                        dataType: "datetime"
                    },
                    "campaign_members": {
                        type: "area",
                        name: "Marketing Activity"
                    }
                }
            },
            rows: [{
                label: 'Marketing Activity',
                field: 'campaign_members',
                link: function (row) {
                    return !row.campaign_members ? false : 'javascript:void(0)';
                },
                click: function (row) {
                    if (!row.campaign_members) { return; }
                    var niceTime = _.find($scope.data.cohorts, { key: $scope.query.cohort }).value,
                        params = angular.copy($scope.query);

                    params.frequency = row.old_frequency;
                    params.freq_type = $scope.data.accountsTrending.frequency;
                    account.activity(niceTime + ' - ' + params.freq_type + ' (' + row.old_frequency + ')', params);
                }
            }, {
                label: 'Unique Engaged Contacts',
                field: 'contact_active'
            }, {
                label: 'Avg % Contacts Engaged',
                field: 'engaged',
                filter: 'percentage:0'
            }],
            cohorts: utilities.formatCohorts(cohorts, ['all', 'ago', 'custom', 'year', 'quarter', 'toDate', 'lastFull']),
            filters: [{
                label: 'all accounts',
                key: 'all'
            }, {
                label: 'only accounts with activity',
                key: 'activity'
            }, {
                label: 'only accounts with opportunities',
                key: 'opptys'
            }, {
                label: 'only accounts with no opportunities',
                key: 'no_opptys'
            }],
            chartPick: [{
                label: 'Unique Engaged Contacts',
                key: 'contacts'
            }, {
                label: 'Marketing Engagement',
                key: 'activity'
            }, {
                label: 'Avg % Contacts Engaged by Marketing',
                key: 'average'
            }],
        };

        $scope.query = {
            cohort: 'months3',
            account_filter: 'all',
            metric: 'average'
        };
        $scope.chart = {
            chart: {
                title: {
                    text: $scope.data.chartTitles[$scope.query.metric] + ' Over ' + _.startCase(_.find($scope.data.cohorts, { key: $scope.query.cohort }).value)
                },
                size: {},
                color: {
                    pattern: ["#FD690F"]
                },
                legend: {
                    show: false
                },
                point: {
                    r: 6
                },
                axis: {
                    y: {
                        label: {
                            text: 'Avg % Contacts Engaged',
                            position: 'outer-middle'
                        },
                        tick: {
                            // eslint-disable-next-line no-unused-vars
                            format: function (d, i) {
                                var places,
                                    item;

                                if ($scope.chart.data && $scope.chart.data.length) {
                                    item = utilities.last($scope.chart.data)[_.find(Object.keys($scope.data.chartTypes[$scope.query.metric]), function (k) {
                                        return k != 'frequency';
                                    })];
                                }

                                if (item) {
                                    if (item <= 0.01) {
                                        places = 2;
                                    }
                                    else if (item >= 0.1) {
                                        places = 1;
                                    }
                                    else {
                                        places = 0;
                                    }
                                }
                                var result = $filter('percentage')(d, places);
                                return result;
                            }
                        }
                    },
                    x: {
                        // type: 'category',
                        height: 80,
                        label: {
                            position: 'outer-center'
                        },
                        tick: {
                            format: function (d) {
                                return $filter('date')(d, 'M/d/yy', 'local');
                            },
                            rotate: -45
                        }
                    }
                },
                grid: {
                    x: {
                        show: false
                    },
                    y: {
                        show: true
                    }
                }
                // axis: {
                // 	x: {
                // 		show: false
                // 	},
                // 	y: {
                // 		show: false
                // 	}
                //    }
            }
        };
        $scope._ = _;
        utilities.combine($scope.query, $state.params);

        (loadInfoCall = api.get('account/load_info', $scope.query)).then(function (data) {
            $state.current.data.title = data.data.name;
        });

        getTrend();

        $scope.$on('filterData', getTrend);
        $scope.$on('filtersChanged', getTrend);
        $scope.$on('metricChanged', updateChart);
        $scope.$on('$destroy', function () {
            loadTrendingInfoCall.abort();
            loadInfoCall.abort();
        });
    })();
}]);
