import { module as angularModule } from 'angular';
import { APP_BRIGHTFUNNEL } from '../../../../constants';

const app = angularModule(APP_BRIGHTFUNNEL);

export class AccountEngagementSrvc {
    static $inject: string[] = [
        '$http',
        '$rootScope',
        '_',
        'terminusHubService',
    ];

    public setKpiConfigUrl = '/v1/set_kpi_config';
    public getKpiConfigUrl = '/v1/get_kpi_config';
    public getCampaignTypesUrl = '/v1/get_campaign_types';
    public loadSpikeModelsUrl = 'organizationInfo/loadSpikeModels';
    public loadIntentTopicsUrl = 'organizationInfo/loadIntentTopics';

    sendConfigUpdate(type, field, valueType, value) {
        const params = {
            type,
            field,
            [valueType]: value,
        };

        return this.$http.post(this.setKpiConfigUrl, params);
    }

    getConfigs(defaults) {
        return this.$http.post(this.getKpiConfigUrl, null).then((configs) => {
            this._.merge(defaults, configs.data);
        });
    }

    getCampaignTypes() {
        return this.$http.get(this.getCampaignTypesUrl).then((types) => types.data);
    }

    getSpikeModels() {
        return this.$http.get(this.loadSpikeModelsUrl).then((models) => models.data);
    }

    getIntentTopics() {
        return this.$http.get(this.loadIntentTopicsUrl).then((models) => models.data);
    }

    isBomboraEnabled() {
        return this.$rootScope.userData
            && this.$rootScope.userData.hiddenFeatures
            && this.$rootScope.userData.hiddenFeatures.route_access
            && this.$rootScope.userData.hiddenFeatures.route_access['app.settings.selfConfigure.bombora'] === 'enabled';
    }

    constructor(
        public $http,
        private $rootScope,
        public _,
        public terminusHubService,
    ) {}
}

export const SRVC_ACCOUNT_ENGAGEMENT = 'accountEngagementSrvc';
app.service(SRVC_ACCOUNT_ENGAGEMENT, AccountEngagementSrvc);
