import * as COMPONENT_HTML from './component.html';
import './component.less';
import {IDirective, IScope,} from 'angular';

class SubtitleCtrl {
    public constructor() {
        return;
    }
}

export class Subtitle implements IDirective {
    public restrict = 'E';
    public controller: any = SubtitleCtrl;
    public controllerAs = '$ctrl';
    public bindToController = true;
    public template: any = COMPONENT_HTML;
    public scope: IScope = {
        label: '=',
    };

    public constructor() {
        return;
    }

    public static Factory(): IDirective {
        return new Subtitle();
    }
}
