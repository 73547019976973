app.controller('campaignSpecificTrendingWidgetCtrl', ['$scope', 'api', 'utilities', '$filter', '$rootScope', 'models', '$parse', '_', 'campaign', 'revenueWaterfall', '$templateCache', '$compile', '$timeout', 'trend', 'widgets', 'personaDefaults', function ($scope, api, utilities, $filter, $rootScope, models, $parse, _, campaign, revenueWaterfall, $templateCache, $compile, $timeout, trend, widgets, personaDefaults) {
    var $tile = $scope.$tile,
        getCampaignData;

    $scope.targetField = function () {
        return _.find($scope.data.fields, { field: $scope.widget.query.field });
    };

    $scope.fieldDisabled = function (field) {
        if (field == 'leads' && _.includes($scope.widget.query.dataSet, 'oppty')) {
            return true;
        }
    };

    $scope.setDisabled = function (set) {
        if ($scope.widget.query.chartType == 'leads' && _.includes(set, 'oppty')) {
            return true;
        }
    };

    $scope.urlChanged = function () {
        var callout,
            baseState;
        if ($scope.widget.query.chartType == 'pipeline' || $scope.widget.query.chartType == 'revenue') {
            callout = 'values';
        }
        else if ($scope.widget.query.chartType == 'opptys') {
            callout = 'opportunities';
        }
        else {
            callout = $scope.widget.query.chartType;
        }

        if ($scope.widget.webActivity) {
            baseState = 'app.analyze.webTracking.channelAssetSpecific.attribution';
        }
        else {
            baseState = 'app.analyze.campaigns.campaignSpecific.attribution';
        }
        $scope.widget.state = baseState.concat('.' + callout);
    };

    this.getData = function () {
        if (!$scope.widget.query.campaignId) {
            $scope.chart.data = [{now: 0}];
            $tile.endLoading();
            return;
        }

        if ($scope.widget.query.campaignId && _.isActualObject($scope.widget.query.campaignId) && $scope.widget.query.campaignId.id) {
            $scope.widget.query.campaignId = $scope.widget.query.campaignId.id;
        }

        if (getCampaignData) { getCampaignData.abort(); }

        $tile.loading(10);
        var params = angular.copy($scope.widget.query),
            url = $scope.widget.webActivity ? 'channel_attribution' : 'campaign_attribution';

        (getCampaignData = api.getter({
            url,
            params,
            paramsNotToUpdate: 'all',
            skipFilters: true,
        })).then(function (data) {
            $tile.loading(30);
            $scope.data.campaign = data.data;
            $scope.widget.data.lastQuery = params;

            var dateField = trend.dateFieldFromDataset($scope.widget.query.dataSet);

            $scope.data.trends = {
                leads: trend.parseTrends(data.data.leads, trend.agoValue($scope, $scope.widget.query.cohort), dateField, true),
                opptys: trend.parseTrends(data.data.opptys, trend.agoValue($scope, $scope.widget.query.cohort), dateField, true),
                deals: trend.parseTrends(data.data.deals, trend.agoValue($scope, $scope.widget.query.cohort), dateField, true),
                pipeline: trend.parseTrends(data.data.opptys, trend.agoValue($scope, $scope.widget.query.cohort), dateField, false, "amount_pipeline"),
                revenue: trend.parseTrends(data.data.deals, trend.agoValue($scope, $scope.widget.query.cohort), dateField, false, "amount")
            };

            angular.forEach($scope.data.trends, function (value, key) {
                prepData(value, 'now', key);
            });

            check();
        }, function (data) {
            if (!data.aborted) {
                $tile.failedLoading();
            }
        });

        function prepData(data, type, key) {
            data = _.compact(data.map(function (v, i) {
                v.now = v.total;
                v.x = (i + 1);
                v[type + 'Date'] = v.moment;
                v[type + 'Total'] = v.total;
                return v;
            }));

            _.set($scope.data, [key, type], {
                data: data,
                frequency: trend.agoValue($scope, $scope.widget.query.cohort)
            });

            $tile.loading($tile.loadingProgress() + 15);
        }

        function check() {
            if ($tile.loadingProgress() >= 90) {
                angular.forEach($scope.data.trends, function (value, key) {
                    $scope.data[key].chartData = $scope.data[key].now.data;
                });
                $scope.data.pipeline.tick = {
                    format: function (d) {
                        return $filter('currency')(d, '$', 0);
                    }
                };
                $scope.data.revenue.tick = {
                    format: function (d) {
                        return $filter('currency')(d, '$', 0);
                    }
                };

                $scope.chart.data = $scope.data[$scope.widget.query.chartType].chartData;
                $scope.chart.chart.axis.y.tick = $scope.data[$scope.widget.query.chartType].tick;
                $scope.widget.data.lastQuery = angular.copy($scope.widget.query);
                $tile.loading(100);
                $tile.endLoading();
                $scope.$emit("widgetLoadingDone");
            }
        }
    };

    (function init(ctrl) {
        $tile.startLoading();
        $tile.loading(10);

        $scope.abs = Math.abs;

        //------------------------ not saved ------------------------//
        $scope.utils = utilities;
        $scope._ = _;
        $scope.data = campaign.getData();
        $scope.data.filterMap = {
            pipeline: 'nrFormat:true:true',
            revenue: 'nrFormat:true:true',
            leads: 'nrFormat',
            opptys: 'number:0',
            deals: 'number:0'
        };
        $scope.data.tScope = $scope.$new();
        $scope.searchCampaigns = revenueWaterfall.searchCampaigns;
        $scope.data.tooltip = $compile($templateCache.get('trendingTooltipSingle.html'))($scope.data.tScope);

        $scope.chart = {
            dimensions: {
                "x": {
                    "axis": "x"
                },
                "now": {
                    "type": "line"
                }
            },
            chart: {
                legend: {
                    show: false
                },
                grid: {
                    x: {
                        show: true
                    },
                    y: {
                        show: false
                    }
                },
                axis: {
                    x: { show: false },
                    y: { show: false }
                },
                point: {
                    r: 3.5
                },
                color: {
                    pattern: $scope.widget.colors
                },
                tooltip: {
                    // eslint-disable-next-line no-unused-vars
                    contents: function (d, dt, dv, color) {
                        $scope.data.tScope.rows = d.map(function (r) {
                            return {
                                cd: r,
                                d: $scope.data[$scope.widget.query.chartType][r.id].data[r.index],
                                frequency: $scope.data[$scope.widget.query.chartType][r.id].frequency,
                                filter: $scope.data.filterMap[$scope.widget.query.chartType]
                            };
                        });
                        return $scope.data.tScope.$apply(function () { return $scope.data.tooltip[0].outerHTML; });
                    }
                }
            }
        };

        //------------------------ saved ------------------------//
        $scope.widget.data = _.defaults($scope.widget.data, personaDefaults.getQueryDefinitions($scope.widget).data);
        const params = _.defaults($scope.widget.query, personaDefaults.getQueryDefinitions($scope.widget).query);
        $scope.widget.query = widgets.addFilters(params, $scope.widget.data.filters, $scope.widget.data.accountLists);

        $scope.data.fields = [
            {
                value: 'Responses',
                key: 'leads',
                filter: 'number:0'
            },
            {
                value: 'Opportunities',
                key: 'opptys',
                filter: 'number:0'
            },
            {
                value: 'Deals',
                key: 'deals',
                filter: 'number:0'
            },
            {
                value: 'Pipeline',
                key: 'pipeline',
                filter: 'bfCurrency'
            },
            {
                value: 'Revenue',
                key: 'revenue',
                filter: 'bfCurrency'
            }];

        if (!_.isDefined(_.find($scope.data.modelTypes, { key: $scope.widget.query.modelType }))) {
            $scope.widget.query.modelType = "even";
        }

        //------------------------ scope functions ------------------------//
        $scope.$watch('widget.query.chartType', $scope.urlChanged);
        $tile.listeners.push($scope.$on('widgetUpdate', function () {
            if (!angular.equals(angular.copy($scope.widget.query), $scope.widget.data.lastQuery)) {
                $tile.startLoading();
                $tile.loading(10);
                ctrl.getData();
            }
        }));

        $scope.$on('angular-chart-rendered', function (event, options, instance) {
            $scope.data.chartResizer = instance;
            widgets.resizeChart($scope.data.chartResizer);
        });

        $scope.$on('gridster-item-resize', function () {
            $timeout(function () {
                widgets.resizeChart($scope.data.chartResizer);
            }, 1500, false);
        });

        $scope.$on('$windowResize', function () {
            $timeout(function () {
                widgets.resizeChart($scope.data.chartResizer, $scope);
            }, 1000, false);
        });

        $scope.$on('$windowResizeEnd', function () {
            $timeout(function () {
                widgets.resizeChart($scope.data.chartResizer, $scope);
            }, 1000, false);
        });

        $scope.$on('columnsChanged', function () {
            $timeout(function () {
                widgets.resizeChart($scope.data.chartResizer, $scope);
            }, 3000, false);
        });

        $scope.$on('$destroy', function () {
            if (getCampaignData) { getCampaignData.abort(); }
            $scope.data.tScope.$destroy();
        });
    })(this);
}]);
