export const IMPORT_CSV_LABEL = 'Upload';
export const CANCEL_CSV_LABEL = 'Cancel';
export const DONE_CSV_LABEL = 'Done';
export const START_OVER_CSV_LABEL = 'Start Over';
export const IMPORT_CSV_MODAL_TITLE = 'Use CSV';
export const IMPORT_CSV_MODAL_SUBTITLE = 'Upload a list of CRM account IDs contained in the first column';

export const CSV_IMPORT_MODAL_ID = 'import-csv-modal';
export const CSV_MODAL_SIZE = '600px';

export const CREATE_UPDATE_LIST = [
    {id: 1, label: 'Create New List'},
    {id: 2, label: 'Modify Existing List'}
];
export const MERGE_OPERATION = 1;
export const REPLACE_OPERATION = 2;
export const DELETE_OPERATION = 3;

export const MERGE_REPLACE_DELETE_LIST = [
    {
        id: MERGE_OPERATION,
        label: 'Merge',
        description: 'Add net new account IDs to the existing list'},
    {
        id: REPLACE_OPERATION,
        label: 'Replace',
        description: 'Replace all current list members, and add new accounts'},
    {
        id: DELETE_OPERATION,
        label: 'Delete',
        description: 'Remove list members that are specified in the CSV'},
];
export const MAX_COLUMN_SIZE = 32;

export const HEADER_CHECK = 1;
export const FIRST_RADIO_LABEL = 'Ignore first row';
export const ADD_HEADERS_LIST = [
    {id: HEADER_CHECK, label: FIRST_RADIO_LABEL},
];

export const FOLDER_DROP_DOWN_TITLE = 'Folder';
export const LIST_DROP_DOWN_TITLE = 'List Name';
export const MODAL_INPUT_TYPE = 'text';
export const DROP_FILES_LABEL = 'Drag or Drop CSV file here';
export const SELECT_FILE_LABEL = 'Select file';
export const REPLACING_MEMBERS_WARNING = 'Replacing members will impact various features ' +
    'such as Scorecard, Global Filters, and Enhanced Audience Segmentation.';
export const NEW_MEMBERS_LABEL = 'New Members';
export const IGNORED_ACCOUNTS_LABEL = 'Ignored Accounts';
export const DELETED_MEMBERS_LABEL = 'Deleted Members';
export const TOTAL_ACCOUNTS_LABEL = 'Total Accounts';
export const CSV_FILE_TYPE = '.csv, text/csv';
export const LIST_UPDATED_LABEL = ' has been updated. Awesome!';
export const LOADER_MESSAGE = 'We are still uploading your CSV. For larger files this can take up to 5 minutes. ' +
    'Please keep this window open until the file has been successfully uploaded.';

export const ERROR_FOLDERS = 'Error loading the folder names';
export const TOTAL_SIZE_ERROR = 'Total file size is too big to process';
export const INVALID_ACCOUNT_ID = 'Invalid account Id found in file: ';
export const INVALID_NUMBER_OF_FILES = 'Only 1 file can be uploaded';
export const EMPTY_FILE_ERROR = 'The file is empty';
export const MISSING_FOLDER = 'No folder was selected';
export const MISSING_LIST_NAME = 'A list name was not provided';
export const MISSING_ACCOUNT_IDS = 'Upload a file with the Account Ids for this list';
export const INVALID_OPERATION = 'Invalid Operation';

export const GRAILS_URL_VAR = 'GRAILS_URL';
export const FOLDERS_ENDPOINT = '/folder/getAccountsListsFolders';
export const CREATE_LIST_ENDPOINT = '/accountsLists/createList';
export const ADD_TO_LIST_ENDPOINT = '/accountsLists/addAccountsToList';
export const REMOVE_FROM_LIST_ENDPOINT = '/accountsLists/removeAccountsFromList';
export const REMOVE_ALL_FROM_LIST_ENDPOINT = '/accountsLists/removeAllAccountsFromList';
export const LIST_CHUNK_SIZE = 9000;
export const CHUNK_TIME = 75;
