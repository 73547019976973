/* eslint-disable no-console */
app.controller('salesActivityConfigCtrl', ['$scope', '$http', '$window', '$location', 'utilities', 'api', '$q', '$filter', '$b', '$state', '_', function ($scope, $http, $window, $location, utilities, api, $q, $filter, $b, $state, _) {
    var loadConfigCall;
    var loadUserRollsCall;
    var loadEnabledObjectsCall;
    var loadTaskTypesCall;
    var dataCall;
    //todo refactor these like functions into one
    function makeRoleMap() {
        $scope.data.disabledRoles = [];
        $scope.data.enabledRoles = [];
        $scope.data.userRoles.forEach(function (role) {
            $scope.data[($scope.data.enabledRoleIds.indexOf(role.id) > -1 ? "enabled" : "disabled") + "Roles"].push(role);
        });
    }

    function makeTaskMap() {
        $scope.data.disabledTaskTypes = [];
        $scope.data.enabledTaskTypes = [];
        $scope.data.taskTypes.forEach(function (item) {
            $scope.data[($scope.data.enabledTaskIds.indexOf(item.id) > -1 ? "enabled" : "disabled") + "TaskTypes"].push(item);
        });
        $state.current.data.loading = false;
    }

    $scope.updateUserRoles = function () {
        api.set("/orgAdmin/update_enabled_objects", { type: "user_role", keys: $scope.data.enabledRoles }, true);
    };

    $scope.updateTaskTypes = function () {
        api.set("/orgAdmin/update_enabled_objects", { type: "task_type", keys: $scope.data.enabledTaskTypes }, true);
    };

    $scope.configSave = function (module, type) {
        var valueType = typeof $scope.data[module][type];
        var value = $scope.data[module][type];
        if(valueType === "boolean") {
            value = !value;
        } else if (valueType === "object") {
            value = value.key;
        }
        var params = { module: module, type: type, value: value, field: "" };
        api.set("/orgAdmin/update_org_config", params);
    };

    (function init() {
        $state.current.data.loading = true;
        $scope.data = {
            userRoles: null,
            enabledRoleIds: null,
            enabledTaskIds: null,
            taskTypes: null,
            salesActivity: null,
            userOptions: [ {key: 'created', value: 'Created By'}, { key: 'assigned', value: 'Assigned To'} ]
        };

        (loadConfigCall = api.get("load_config", { editable: true }, false, true)).then(function (data) {
            $scope.data.salesActivity = data.data.salesActivity;
            $scope.data.salesActivity.userType = _.find($scope.data.userOptions, {key: data.data.salesActivity.userType});
        });

        (loadUserRollsCall = api.get("/orgAdmin/load_user_roles", null, false, true)).then(function (data) {
            $scope.data.userRoles = data.data;
            if ($scope.data.userRoles && $scope.data.enabledRoleIds) {
                makeRoleMap();
            }
        });
        (loadEnabledObjectsCall = api.get("/orgAdmin/load_enabled_objects", { type: "user_role" }, false, true)).then(function (data) {
            $scope.data.enabledRoleIds = data.data;
            if ($scope.data.userRoles && $scope.data.enabledRoleIds) {
                makeRoleMap();
            }
        });

        (loadTaskTypesCall = api.get("/orgAdmin/load_task_types", null, false, true)).then(function (data) {
            $scope.data.taskTypes = data.data;
            if ($scope.data.enabledTaskIds && $scope.data.taskTypes) {
                makeTaskMap();
            } else {
                $state.current.data.loading = false;
            }
        });

        (dataCall = api.get("/orgAdmin/load_enabled_objects", { type: "task_type" }, false, true)).then(function (data) {
            $scope.data.enabledTaskIds = data.data;
            if ($scope.data.enabledTaskIds && $scope.data.taskTypes) {
                makeTaskMap();
            } else {
                $state.current.data.loading = false;
            }
        });

        $scope.$on('$destroy', function () {
            if (loadConfigCall) { loadConfigCall.abort(); }
            if (loadUserRollsCall) { loadUserRollsCall.abort(); }
            if (loadEnabledObjectsCall) { loadEnabledObjectsCall.abort(); }
            if (loadTaskTypesCall) { loadTaskTypesCall.abort(); }
            if (dataCall) { dataCall.abort(); }
        });
    })();

}]);
