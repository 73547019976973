export class OrgConfigElement {
    id: number;
    uuid: string;
    name: string;

    constructor(id: number, uuid: string, name: string) {
        this.id = id;
        this.uuid = uuid;
        this.name = name;
    }
}

export class OrganizationElements {
    orgsActive?: OrgConfigElement[];
    orgsAll?: OrgConfigElement[];
}

export class ServiceDescriptor {
    serviceId: string;
    vendorName: string;
}

export class IntegrationVisibility {
    service: Partial<ServiceDescriptor>;
    visible: boolean;
}

export interface IInternalConfigService {
    getInternalConfig(): Promise<any>;
}

export interface IEnvironmentSrvc {
    getVariable(key: string): Promise<any>;
}

export type IOrgChanged = (elem: OrgConfigElement) => void;

export type IConfig = () => Promise<void>;
