app.controller('accountsTrendingCtrl', ['$scope', 'api', 'utilities', '$state', '$filter', 'account', 'trend', '$timeout', '_', function ($scope, api, utilities, $state, $filter, account, trend, $timeout, _) {
    //------------------------ scope methods ------------------------//
    var dataCall;

    if (dataCall) { dataCall.abort(); }
    //------------------------ private methods ------------------------//
    function getAccountsTrending() {
        $state.current.data.loading = true;
        //these should be in one api call

        (dataCall = api.get([{
            api: 'account/load_list_trending',
            params: $scope.query,
            id: 'listTrending'
        }, {
            api: 'account/load_list_trending_activity',
            params: $scope.query,
            id: 'activityTrending'
        }])).then(function (data) {
            var allData = angular.merge(data.listTrending, data.activityTrending, data.opptyTrending);

            trend.convertDate(allData.data);
            // eslint-disable-next-line no-unused-vars
            angular.forEach(allData.data.data, function (value, i) {
                value.frequency = new Date(value.frequency).getTime();
                value.opened = value.opened ? value.opened : 0;
                value.closed = value.closed ? value.closed : 0;
                value.engaged = value.contact_total && value.contact_active ? value.contact_active / value.contact_total : 0;
                value.percentageEngaged = value.account_activity ? value.account_activity / allData.data.total_accounts : 0;
            });

            $scope.data.accountsTrending = allData.data;
            $scope.chart.data = allData.data.data;
            $scope.data.step = allData.data.frequency;
            $scope.chart.chart.axis.x.label.text = $scope.data.step;
            $timeout(function () {
                updateChart();
            }, 0, false);
            $state.current.data.loading = false;
        });
    }

    function updateChart() {
        var metrics = $scope.picks();
        if ($scope.query.metric == 'average' || $scope.query.metric == 'percentage') {

            // eslint-disable-next-line no-unused-vars
            $scope.chart.chart.axis.y.tick.format = function (d, i) {
                var places,
                    item;

                if ($scope.chart.data && $scope.chart.data.length) {
                    item = utilities.last($scope.chart.data)[_.find(Object.keys($scope.data.chartTypes[$scope.query.metric]), function (k) {
                        return k != 'frequency';
                    })];
                }

                if (item) {
                    if (item <= 0.01) {
                        places = 2;
                    } else if (item >= 0.1) {
                        places = 1;
                    } else {
                        places = 0;
                    }
                }

                var result = $filter('percentage')(d, places);

                return result;
            };
        } else {
            delete $scope.chart.chart.axis.y.tick.format;
        }
        $scope.chart.chart.axis.y.label.text = $scope.$parent.data.chartTitles[$scope.query.metric];
        $scope.chart.dimensions = $scope.data.chartTypes[$scope.query.metric];
        $scope.chart.chart.title.text = _.find(metrics, { key: $scope.query.metric }).label + ' Over ' + _.startCase(_.find($scope.$parent.data.cohorts, { key: $scope.query.cohort }).value);
    }

    $scope.$on('$destroy', function () {
        if (dataCall) { dataCall.abort(); }
    });

    //------------------------ init ------------------------//
    (function init() {
        $scope.data = {
            chartTypes: {
                percentage: {
                    "percentageEngaged": {
                        type: "area",
                        name: "Percentage Engaged"
                    },
                    "frequency": {
                        axis: "x",
                        dataType: "datetime"
                    }
                },
                average: {
                    "frequency": {
                        axis: "x",
                        dataType: "datetime"
                    },
                    "engaged": {
                        type: "area",
                        name: "Avg % Contacts Engaged"
                    }
                },
                contacts: {
                    "frequency": {
                        axis: "x",
                        dataType: "datetime"
                    },
                    "contact_active": {
                        type: "area",
                        name: "Unique Engaged Contacts"
                    }
                },
                activity: {
                    "frequency": {
                        axis: "x",
                        dataType: "datetime"
                    },
                    "campaign_members": {
                        type: "area",
                        name: "Marketing Activity"
                    }
                },
                engaged: {
                    "frequency": {
                        axis: "x",
                        dataType: "datetime"
                    },
                    "account_activity": {
                        type: "area",
                        name: "Accounts Engaged"
                    }
                }
            },
            rows: [{
                label: 'Marketing Engagements',
                field: 'campaign_members',
                metric: '',
                link: function (row) {
                    return !row.campaign_members ? false : 'javascript:void(0)';
                },
                click: function (row) {
                    if (!row.campaign_members) { return; }
                    var niceTime = _.find($scope.$parent.data.cohorts, { key: $scope.query.cohort }).value,
                        params = angular.copy($scope.query);

                    params.frequency = row.old_frequency;
                    params.freq_type = $scope.data.accountsTrending.frequency;
                    account.activity(niceTime + ' - ' + params.freq_type + ' (' + row.old_frequency + ')', params);
                }
            }, {
                label: 'Unique Engaged Contacts',
                field: 'contact_active',
                metric: ''
            }, {
                label: 'Accounts Engaged by Marketing',
                field: 'account_activity',
                metric: ''
            }, {
                label: '% Engaged by Marketing',
                field: 'percentageEngaged',
                filter: 'percentage:0',
                metric: ''
            }, {
                label: 'Avg % Contacts Engaged by Marketing',
                field: 'engaged',
                filter: 'percentage:0',
                metric: ''
            }]
        };

        $scope.chart = {
            chart: {
                size: {},
                color: {
                    pattern: ["#FD690F"]
                },
                legend: {
                    show: false
                },
                point: {
                    r: 6
                },
                axis: {
                    y: {
                        label: {
                            text: 'Avg % Contacts Engaged',
                            position: 'outer-middle'
                        },
                        tick: {
                            // eslint-disable-next-line no-unused-vars
                            format: function (d, i) {
                                var places,
                                    item;

                                if ($scope.chart.data && $scope.chart.data.length) {
                                    item = utilities.last($scope.chart.data)[_.find(Object.keys($scope.data.chartTypes[$scope.query.metric]), function (k) {
                                        return k != 'frequency';
                                    })];
                                }

                                if (item) {
                                    if (item <= 0.01) {
                                        places = 2;
                                    } else if (item >= 0.1) {
                                        places = 1;
                                    } else {
                                        places = 0;
                                    }
                                }

                                var result = $filter('percentage')(d, places);
                                return result;
                            }
                        }
                    },
                    x: {
                        // type: 'category',
                        height: 80,
                        label: {
                            position: 'outer-center'
                        },
                        tick: {
                            format: function (d) {
                                return $filter('date')(d, 'M/d/yy', 'local');
                            },
                            rotate: -45
                        }
                    }
                },
                grid: {
                    x: {
                        show: false
                    },
                    y: {
                        show: true
                    }
                }
                // axis: {
                // 	x: {
                // 		show: false
                // 	},
                // 	y: {
                // 		show: false
                // 	}
                //    }
            }
        };

        var metrics = $scope.picks();
        $scope.chart.chart.title = {
            text: _.find(metrics, { key: $scope.query.metric }).label + ' Over ' + _.startCase(_.find($scope.$parent.data.cohorts, { key: $scope.query.cohort }).value)
        };

        if ($scope.$parent.data.init) {
            getAccountsTrending();
        }

        $scope.$on('getData', getAccountsTrending);
        $scope.$on('filterData', getAccountsTrending);
        $scope.$on('filtersChanged', getAccountsTrending);
        $scope.$on('metricChanged', updateChart);
        $scope.$on('$destroy', function () {
            if ($scope.$parent.data && $scope.$parent.data.startDatepicker) {
                $scope.$parent.data.startDatepicker.opened = false;
                $scope.$parent.data.endDatepicker.opened = false;
            }
        });
    })();
}]);
