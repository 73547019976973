/**
 * Controller for the search directive.
 */
(function () {
    'use strict';

    angular
        .module('bombora')
        .directive('headerSearch', function () {
            return {
                template: require('./search.html'),
                controller: searchCtrl,
                controllerAs: "$ctrl",
                bindToController: true,
                scope: {
                    placeholder: '=',
                    search: '=',
                    onSelected: '&'
                }
            };
        });

    searchCtrl.$inject = [];

    /**
     * Controller for the directive.
     */
    function searchCtrl() { }
})();
