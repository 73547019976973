app.controller('sqlConfigCtrl', ['$http', '$window', '$location', 'utilities', 'api', '$scope', '$state', '$timeout', '$rootScope', '_', '$q', 'ObjectDiff', '$b', 'noty', function ($http, $window, $location, utilities, api, $scope, $state, $timeout, $rootScope, _, $q, ObjectDiff, $b, noty) {
    var PullConfigsCall;
    var PushConfigsCall;
    var SyncConfigsCall;
    var PullIterationCall;

    $scope.syncChanges = function () {
        $state.current.data.loading = true;
        (SyncConfigsCall = api.get('sync_from_dev', { org_id: $scope.query.orgId }, false, true)).then(function (data) {
            $scope.data.params = JSON.parse(data.data[0][0]);
            $scope.data.refresh = true;
            $timeout(function () {
                $scope.data.refresh = false;
            }, 10, false);
            $scope.runDiff();
            $state.current.data.loading = false;
        });
    };

    $scope.pushChanges = function () {
        $b.confirm({
            text: 'Are you sure you want to push this config to production?',
            callback: function () {
                $state.current.data.loading = true;
                $scope.pushStr = angular.toJson($scope.data.params);
                (PushConfigsCall = api.set('push_proc_config', { mapping: $scope.pushStr, orgId: $scope.query.orgId, next_iteration: $scope.curIteration }, false, true)).success(function () {
                    $state.current.data.loading = false;
                    $scope.pullChanges();
                    noty.growl("Configuration updated successfully", 'information', true);
                });
            }
        });
    };

    $scope.pushImpalaChanges = function () {
        $b.confirm({
            text: 'Are you sure you want to push this impala config to production?',
            callback: function () {
                $state.current.data.loading = true;
                $scope.pushStr = angular.toJson($scope.impalaParams);
                (PushConfigsCall = api.set('push_impala_proc_config', { mapping: $scope.pushStr, orgId: $scope.query.orgId, next_iteration: $scope.curImpalaIteration }, false, true)).success(function () {
                    $state.current.data.loading = false;
                    $scope.pullImpalaChanges();
                    noty.growl("Configuration updated successfully", 'information', true);
                });
            }
        });
    };

    $scope.pullChanges = function () {
        $state.current.data.loading = true;
        $scope.query.validIterations = [];
        return api.get('load_iterations', { org_id: $scope.query.orgId }, false, true).then(function (data) {
            $scope.query.validIterations = data.data;
            $scope.curIteration = Math.max.apply(null, data.data);
            return (PullConfigsCall = api.get('load_proc_config', { org_id: $scope.query.orgId }, false, true)).then(function (data) {
                $scope.data.params = JSON.parse(data.data[0][0]);
                $scope.query.iteration = $scope.curIteration;
                $scope.curIteration = $scope.curIteration + 1;
                $scope.runDiff();
                $state.current.data.loading = false;
            });
        });
    };

    $scope.pullImpalaChanges = function () {
        $state.current.data.loading = true;
        $scope.query.validImpalaIterations = [];
        return api.get('load_impala_iterations', { org_id: $scope.query.orgId }, false, true).then(function (data) {
            $scope.curImpalaIteration = Math.max.apply(null, data.data);
            $scope.query.validImpalaIterations = data.data;
            return (PullConfigsCall = api.get('load_impala_config', { org_id: $scope.query.orgId }, false, true)).then(function (data) {
                $scope.impalaParams = JSON.parse(data.data[0][0]);
                $scope.query.impalaIteration = $scope.curImpalaIteration;
                $scope.curImpalaIteration = $scope.curImpalaIteration + 1;
                $scope.runImpalaDiff();
                $state.current.data.loading = false;
            });
        });
    };

    $scope.runDiff = function () {
        (PullIterationCall = api.get('load_proc_iteration', { org_id: $scope.query.orgId, iteration: $scope.query.iteration }, false, true)).then(function (data) {
            var strippedParams;
            strippedParams = $scope.data.params;
            delete Object.keys(strippedParams).__proto__["move"];

            $scope.previousParams = JSON.parse(data.data[0]);
            var diff = ObjectDiff.diffOwnProperties($scope.previousParams, strippedParams);
            $scope.diffValue = ObjectDiff.toJsonView(diff);
        });
    };

    $scope.runImpalaDiff = function () {
        (PullIterationCall = api.get('load_impala_proc_iteration', { org_id: $scope.query.orgId, iteration: $scope.query.impalaIteration }, false, true)).then(function (data) {
            var strippedParams;
            strippedParams = $scope.impalaParams;
            delete Object.keys(strippedParams).__proto__["move"];

            $scope.previousParams = JSON.parse(data.data[0]);
            var diff = ObjectDiff.diffOwnProperties($scope.previousParams, strippedParams);
            $scope.impalaDiffValue = ObjectDiff.toJsonView(diff);
        });
    };

    (function init() {
        // eslint-disable-next-line no-unused-vars
        var defer = $q.defer();
        $scope.query = {
            orgId: 0,
            iteration: 0,
            impalaIteration: 0,
            validIterations: [],
            validImpalaIterations: []
        };

        $scope.data = {};

        api.get('internal_config', null, false, true).then(function (data) {
            var active = [];
            if (data.data.auth) {
                data.data.orgs.forEach(function (item) {
                    if (item.active) {
                        active.push(item);
                    }
                });
            }
            $scope.configs.orgs = [{ id: 0, name: "--Select an org--" }].concat(data.data.orgs);
            $scope.configs.orgsActive = [{ id: 0, name: "--Select an org--" }].concat(active);
            $scope.configs.orgsAll = [{ id: 0, name: "--Select an org--" }].concat(data.data.orgs);
            $scope.configs.impalaOrgs = [{ id: 0, name: "--Select an org--" }].concat(data.data.impalaorgs);
        });

        $scope.pullChanges().then(function () {
            //            $scope.runDiff();
        });
        $scope.pullImpalaChanges().then(function () {
        });

        $scope.$on('$destroy', function () {
            if (PullConfigsCall) { PullConfigsCall.abort(); }
            if (PushConfigsCall) { PushConfigsCall.abort(); }
            if (PullIterationCall) { PullIterationCall.abort(); }
            if (SyncConfigsCall) { PullIterationCall.abort(); }
        });

        $scope.syncEnv = 'Dev';

        if (window.bfEnvironment == 'PRODUCTION') {
            $scope.syncEnv = 'Stage';
        }

    })();
}]);
