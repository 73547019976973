app.controller('DashboardCtrl', [
    '$scope',
    '$rootScope',
    '$http',
    '$window',
    '$location',
    '$state',
    '$filter',
    '$timeout',
    '$b',
    'widgets',
    'utilities',
    'cohorts',
    '_',
    'exportService',
    'models',
    'personaDefaults',
    function ($scope, $rootScope, $http, $window, $location, $state, $filter,
        $timeout, $b, widgets, utilities, cohorts, _, exportService, models, personaDefaults,
    ) {
        /*=============================================
	=  methods
	=============================================*/

        $scope.setDefaults = function () {
            if (!$scope.data.dashboard.widgets.length && $scope.data.dashboard.role !== "blank") {
                $scope.data.dashboard.widgets =
                    $scope.data.dashboard.selectedRole && $scope.data.dashboard.selectedRole.key
                        ? personaDefaults.getDefaultWidgets($scope.data.dashboard.selectedRole.key).widgets
                        : personaDefaults.getDefaultWidgets().widgets;
            }
            widgets.setDashboards(null, true);
        };

        $scope.deleteTile = function (widget, confirm) {
            if (widgets.permissionToEditDashboard($scope.data.dashboard)) {
                if (confirm === false) {
                    $b.confirm({
                        text: "There was an error loading your tile. The tile will need to be deleted and reloaded.",
                        callback: function () {
                            $scope.data.dashboard.widgets.splice($scope.data.dashboard.widgets.indexOf(widget), 1);
                            widgets.setDashboards($scope.data.dashboard);
                        },
                        buttons: [
                            {
                                text: "Ok",
                                "ng-click": "$modal.callback($modal)",
                                class: "btn btn-sm btn-primary pull-right"
                            }
                        ]
                    });
                } else {
                    $b.confirm({
                        text: "Are you sure you want to remove this tile?",
                        callback: function () {
                            $scope.data.dashboard.widgets.splice($scope.data.dashboard.widgets.indexOf(widget), 1);
                            $rootScope.$broadcast("$$rebind::widgetUpdated");
                            widgets.setDashboards($scope.data.dashboard);
                        },
                        buttons: [
                            {
                                text: "Ok",
                                "ng-click": "$modal.callback($modal)",
                                class: "btn btn-sm btn-primary pull-right"
                            }
                        ]
                    });
                }
            } else {
                $b.alert("Only the owner of this dashboard can make changes to it.", "Permission Needed");
            }
        };

        $scope.exportTile = function (widget) {
            var individual_tile = $(".gridster-item#" + widget + " .box");
            exportService.expFunc(individual_tile);
        };

        $scope.duplicate = function (widget) {
            if (widgets.permissionToEditDashboard($scope.data.dashboard)) {
                if ($scope.data.dashboard.widgets.length < widgets.getLimit()) {
                    // $scope.data.dashboard.widgets.splice($scope.data.dashboard.widgets.indexOf(widget),0,widgets.unique(widget));
                    widget.queryId = null;
                    $scope.data.dashboard.widgets.push(widgets.unique(widget));
                    widgets.setDashboards();
                    $rootScope.$broadcast("$$rebind::widgetUpdated");
                } else {
                    $b.alert("You've reached the maximum number of allowable dashboard tiles.", "Limit Reached");
                }
            } else {
                $b.alert("Only the owner of this dashboard can make changes to it.", "Permission Needed");
            }
        };

        $scope.resizeText = function () {
            $timeout(
                function () {
                    $scope.$broadcast("resizeText");
                },
                0,
                false
            );
        };

        $scope.makeToolTip = function () {
            if (widgets.permissionToEditDashboard($scope.data.dashboard)) {
                $scope.data.tooltipText = "manage";
            }
        };

        $scope.changeDashboardName = function (nv) {
            widgets.changeDashboardName(nv);
        };

        $scope.gridColumns = function (num) {
            $scope.data.gridsterOptions.columns = num;
        };

        $scope.calcGridSize = function (size) {
            if (size.width > 1850) {
                return 6;
            } else if (size.width > 1650) {
                return 5;
            } else if (size.width > 1400) {
                return 4;
            } else {
                return 3;
            }
        };

        $scope.deleteDashboard = function (id) {
            widgets.deleteDashboard(id);
        };

        $scope.addTile = function () {
            widgets.addTile($state.params.id);
        };

        $scope.useNewDashboardVisual = $rootScope.userData.platform !== 'standard';

        (function init() {
            $state.current.data.loading = true;
            $scope.query = {};
            $scope.cohorts = cohorts;

            var cols = $scope.calcGridSize({ width: $($window).width() });

            $scope.data = {
                gridsterOptions: {
                    margins: [20, 20],
                    columns: cols,
                    draggable: {
                        enabled: true,
                        handle: ".box-header",
                        start: function (event, $element, widget) {
                            $scope.data.dragging = angular.copy(widget);
                        }, // optional callback fired when drag is started,
                        // drag: function(event, $element, widget) {}, // optional callback fired when item is moved,
                        stop: function (event, $element, widget) {
                            if ($scope.data.dragging.x !== widget.x || $scope.data.dragging.y !== widget.y) {
                                widgets.setDashboards();
                            }
                        } // optional callback fired when item is finished dragging
                    },
                    resizable: {
                        enabled: true,
                        handles: ["se"],
                        //start: function(event, $element, widget) {}, // optional callback fired when resize is started,
                        start: function () {
                            //$($window).trigger('resize.fittext');
                        }, // optional callback fired when item is resized,
                        stop: function (event, $element) {
                            $scope.$broadcast("changeListResults", $element[0].id, $scope);
                        } // optional callback fired when item is finished resizings
                    },
                    outerMargin: false,
                    defaultSizeX: 1,
                    pushing: true,
                    swapping: true,
                    mobileBreakPoint: 600
                },
                dashboard: null
            };

            widgets.getDashboards().then(function () {
                $scope.query.id = widgets.dashboards()[0].id;
                $scope.query = _.defaults(_.compactObject($state.params), $scope.query);
                $scope.data.dashboard = widgets.getDashboard($scope.query.id);

                if ($scope.data.dashboard) {
                    if ($state.params.id === null || $state.params.id === undefined) {
                        utilities.queryString({ id: String($scope.query.id) }).then($scope.setDefaults);
                    } else {
                        $scope.setDefaults();
                    }

                    $state.current.data.loading = false;

                    if (!widgets.permissionToEditDashboard($scope.data.dashboard)) {
                        $scope.data.gridsterOptions.draggable.enabled = false;
                        $scope.data.gridsterOptions.resizable.enabled = false;
                    }
                }
                // NOTE: if there is routeId in query params then we have to open dashboard dialog
                // with data from terminus-hub
                if ($scope.query.routeId && $scope.query.id) {
                    widgets.addTile($scope.query.id, $scope.query);
                }

                $rootScope.$broadcast("$$rebind::dashboardUpdated");
            });

            $scope.$on("$windowResizeEnd", function (e, size) {
                $scope.gridColumns($scope.calcGridSize(size));
            });

            $scope.$on("$windowResize", function () {
                $scope.$broadcast("resizeText");
            });

            $scope.pendingLoads = [];
            $scope.loadingCount = 0;

            $scope.$on("widgetControllerInit", function (signal, control) {
                if ($scope.loadingCount < widgets.getConcurrent()) {
                    $scope.loadingCount++;
                    control.getData();
                }
                else {
                    $scope.pendingLoads.push(control);
                }
            });
            $scope.$on("widgetLoadingDone", function () {
                var control;
                $scope.loadingCount--;
                if ($scope.pendingLoads.length > 0) {
                    control = $scope.pendingLoads.shift();
                    $scope.loadingCount++;
                    control.getData();
                }
            });

            //make a scope.on to read when dashboard controllers are instantiated
            //increment a count on each broadcast, proceed with loading once count equals widget.dashboards.length
            //pull out each getData call for each widget controller, and call from here

            //------------------------ message service for outside of ctrl ------------------------//
            $scope.$on("widgetMessage", function (s, data) {
                if ($scope[data.fun]) {
                    $scope[data.fun](data.args);
                }
            });
        })();
    }
]);

app.controller("widgetCtrl", [
    "$scope",
    "$interpolate",
    "api",
    "utilities",
    "$timeout",
    "$injector",
    "widgets",
    "$parse",
    "$b",
    "$state",
    "_",
    "$rootScope",
    "filters",
    "$controller",
    "$q",
    function ($scope, $interpolate, api, utilities, $timeout, $injector, widgets, $parse, $b, $state, _, $rootScope, filtersSrv, $controller, $q) {
        // Flag that is true if a flip is occurring as a result of a save
        $scope.saveInProgress = false;

        $scope.$tile = {
            listeners: []
        };

        $scope._ = _;

        $scope.$tile.evalString = function (s, scope) {
            scope = scope || $scope;
            if (!s) {
                return;
            }
            return !_.contains(s, "{{") && !_.contains(s, " ") ? $parse(s)(scope) : $interpolate(s)(scope);
        };

        $scope.$tile.setIndex = function (scope) {
            scope.row.$index = scope.$index;
        };

        function handleSaveComplete() {
            $scope.saveInProgress = false;
        }

        $scope.$tile.updateAndFlip = function () {
            $scope.saveInProgress = true;

            if ($scope.flip && _.isFunction($scope.flip)) {
                $scope.flip();
            }
            $scope.$broadcast("widgetUpdate");
            $scope.$tile.saveChange(angular.copy($scope.widget), $scope.data.cp).then(function () {
                handleSaveComplete();
            }, function () {
                handleSaveComplete();
            });
        };

        $scope.$tile.loading = function (n) {
            $scope.$tile.data.loading.current = n;
        };

        $scope.$tile.isLoading = function () {
            return $scope.$tile.data.loading.show === true;
        };

        $scope.$tile.startLoading = function () {
            $scope.$tile.data.loading.show = true;
        };

        $scope.$tile.numListFiltersApplied = function () {
            return $scope.widget.data.lastQuery.al ? $scope.widget.data.lastQuery.al.split(",").length : 0;
        };

        $scope.$tile.endLoading = function () {
            $timeout(
                function () {
                    $scope.$apply(function () {
                        $scope.$tile.data.loading.show = false;
                        if ($scope.$parentState) {
                            //$scope.$tile.resizeText();
                            $scope.$broadcast("resizeText");
                        }
                        // $rootScope.$broadcast('tileFlipToFront');
                        $timeout(
                            function () {
                                $scope.$broadcast("resizeText");
                            },
                            200,
                            false
                        ); //200 because we need to wait for the text to render so we can resize it
                    });
                },
                1200,
                false
            );
        };

        $scope.$tile.loadingProgress = function () {
            return $scope.$tile.data.loading.current;
        };

        $scope.$tile.failedLoading = function () {
            $scope.$tile.data.loading.current = 0;
            $scope.$tile.data.loading.show = false;
            $scope.$tile.data.loading.failed = true;
        };

        $scope.$tile.noData = function () {
            $scope.$tile.data.loading.current = 0;
            $scope.$tile.data.loading.show = false;
            $scope.$tile.data.noData = true;
        };

        $scope.$tile.toggleSettings = function () {
            var id = $state.params.id;
            var dashboards = widgets.dashboards();
            var dashboard = _.find(dashboards, { id: id });
            if (widgets.permissionToEditDashboard(dashboard)) {
                $scope.flip();
                if ($scope.flipped) {
                    $scope.$tile.data.cp = angular.copy($scope.widget);
                }
            } else {
                $b.alert("Only the owner of this dashboard can make changes to it.", "Permission Needed");
            }
        };

        $scope.$tile.filter = function () {
            var model = {
                title: 'Add a Filter to "' + ($scope.widget.userTitle || $scope.$tile.evalString($scope.widget.title)) + '"',
                id: "add-filter",
                template: require("../filters/filters_new.html"),
                width: "600px",
                buttons: [
                    {
                        html: "Save & Close",
                        "ng-click": "finish()",
                        class: "btn btn-sm pull-right btn-primary"
                    },
                    {
                        html: "Clear All",
                        "ng-click": "clearAll()",
                        class: "btn btn-sm pull-right btn-warning"
                    }
                ],
                x: false,
                maxed: false,
                controller: "globalFiltersModalCtrl",
                parent: $scope
            };
            $b.modal(model);
        };



        $scope.$tile.appliedFilters = function () {
            var lk = $scope.$tile.tileLink();
            var count = [];
            if (lk) {
                count = lk.match(new RegExp(/filter(\d)/g)) || [];
                $scope.$tile.data.numFilters = count.length;
            }
            return count.length;
        };

        $scope.$tile.clickThrough = function() {
            const trendingKpiState = 'app.analyze.accounts.scorecard.kpi';
            overrideFilters();

            if ($scope.widget.state === trendingKpiState) {
                goToScorecardTrendingKpi();
            }
        };

        function overrideFilters() {
            filtersSrv.initializeFilters($rootScope.filters, filtersSrv.makeSelectedFilterMap($scope.widget.query), "sync");
            filtersSrv.initializeFolders($rootScope.accountLists, filtersSrv.getListIdsFromParams($scope.widget.query), "sync");
        }

        function goToScorecardTrendingKpi() {
            $scope.goToScorecardTrendingKpi($scope.widget.query);
        }

        $scope.$tile.tileLink = function () {
            // if a dashboard tile. create dashboard
            let params = $scope.widget.query;
            const trendingKpiState = 'app.analyze.accounts.scorecard.kpi';

            if ($scope.widget.layout === "comparison" && $scope.widget.types.length && $scope.widget.type) {
                params = _.clone(params);
                const dashboard = widgets.getDashboard($state.params.id);
                if (widgets.permissionToEditDashboard(dashboard)) {
                    params.dashboard = $state.params.id + "_" + $scope.widget.id;
                }
            }
            // if you see search params in URL, strip them out
            if (params.search) {
                delete params.search;
            }

            if ($scope.widget.state !== trendingKpiState) {
                return $scope.shref($scope.widget.state, params);
            }

            return;
        };

        $scope.$tile.reset = function () {
            if ($scope.$tile.listeners) {
                angular.forEach($scope.$tile.listeners, function (canceler) {
                    canceler();
                });
            }
            delete $scope.widget.query;
            delete $scope.widget.data;
            widgets.setDashboards();
            $scope.$tile.data.loading.failed = false;
            $scope.$tile.data.noData = false;
            handleWidgetTileGlobalFilters();
            initLinker();
        };

        $scope.$tile.saveChange = function (nv, ov) {
            var setDashboardPromise;
            if (!angular.equals(nv, ov)) {
                setDashboardPromise = widgets.setDashboards();
            }
            delete $scope.$tile.data.cp;
            return setDashboardPromise ? setDashboardPromise : $q.when();
        };

        $scope.$tile.getListTileValueHeaderLabel = function (valueHeaderMap, field) {
            var label = 'Value';

            // leads will become leads, ranged.even.leads will become leads
            field = (field || '').split('.').pop();

            if (valueHeaderMap && valueHeaderMap[field]) {
                label = valueHeaderMap[field].label || label;
            }

            return label;
        };

        function handleWidgetTileGlobalFilters() {
            const widgetState = $state.get($scope.widget.state);

            if (!$scope.widget.data) {
                $scope.widget.data = {};
            }
            $scope.widget.data.filters = angular.copy($rootScope.filters);
            $scope.widget.data.enabledFilterCount = filtersSrv.computeValidFiltersForReport($scope.widget.data.filters, widgetState);
            $scope.widget.data.accountLists = angular.copy($rootScope.accountLists);
            filtersSrv.initializeFilters($scope.widget.data.filters, filtersSrv.makeSelectedFilterMap($scope.widget.query), "sync");
            filtersSrv.initializeFolders($scope.widget.data.accountLists, filtersSrv.getListIdsFromParams($scope.widget.query), "sync");
        }

        function initLinker() {
            if ($scope.widget.link) {
                var control = $controller($scope.widget.link, { $scope: $scope, cohorts: $scope.rawCohorts });
                $scope.$emit("$tileInit", $scope);

                $scope.$emit("widgetControllerInit", control);
            }
        }

        (function init() {
            $scope._ = _;
            $scope.$state = $state;
            $scope.queryMap = utilities.queryMap;
            $scope.platform = $rootScope.userData.platform;
            $scope.$tile.data = {
                templates: {
                    comparison: "/main/widgets/comparison.html",
                    list: "/main/widgets/list.html",
                    chart: "/main/widgets/chart.html",
                    custom: "/main/widgets/custom.html"
                },
                dashPopover: {
                    template: require("./dashboard-popover.html")
                },
                loading: {
                    max: 100,
                    current: 0,
                    show: true
                }
            };
            /*=============================================
		        =  local storage strips things like functions so we need to get the definition of the original widget and
		        merge it with the one that's saved. This also allows us to get any changes that may have been made to the original
		    =============================================*/

            if ($scope.widget.key === "cpList") {
                if ($scope.widget.query) {
                    if ($scope.widget.query.camp && $scope.widget.query.camp === "campaigns") {
                        $scope.widget.key = "campaignsList";
                    }
                    if ($scope.widget.query.gf && _.isActualObject($scope.widget.query.gf)) {
                        delete $scope.widget.query.gf;
                    }
                    if ($scope.widget.query.camp && $scope.widget.query.camp === "groups") {
                        if ($scope.widget.query.gf) {
                            delete $scope.widget.query.gf;
                        }
                    }
                }
            }

            $scope.$tile.widgetDef = angular.copy(widgets.get($scope.widget.key));
            //strip out empty strings ore empty values in the saved widget
            //$scope.widget = _.compactObject($scope.widget);

            //delete the tile if it can't find the definition based on the ID... this means we no longer support the widget
            if (!$scope.$tile.widgetDef) {
                $scope.deleteTile($scope.widget, false);
                return;
            }
            $scope.widget = angular.extend($scope.widget, $scope.$tile.widgetDef);

            $scope.$on("changeListResults", function (e, widgetId, scope) {
                // double check for row and then return the ones that don't
                var type = _.find(scope.data.dashboard.widgets, { id: widgetId });
                if (type.list && $scope.widget.id === widgetId) {
                    $scope.$broadcast("updateListResults");
                    $scope.$broadcast("resizeText");
                    $scope.$tile.data.loading.show = true;
                    $scope.$tile.loading(50);
                    $scope.$tile.loading(99);
                    widgets.setDashboards();
                    $scope.$tile.endLoading();
                }
            });
            $scope.$evalAsync(function () {
                handleWidgetTileGlobalFilters();
                initLinker();
            });
        })();
    }
]);
