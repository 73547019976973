import { module as angularModule } from 'angular';

import { APP_BRIGHTFUNNEL } from '../../../constants';
import { ITrendingKpiQuery } from '../components/trending-kpis-widget/component';

const app = angularModule(APP_BRIGHTFUNNEL);

export interface IBaseQueryContainer {
  query: ITrendingKpiQuery;
  valid: boolean;
}

export class WidgetQueryStateService {
  public widgetQueries: Record<string, IBaseQueryContainer> = {};

  public get allQueriesValid(): boolean {
      const widgetQueryIds = Object.keys(this.widgetQueries);
      return widgetQueryIds.map(
          (key) => this.widgetQueries[key].valid
      ).filter((valid) => valid).length === widgetQueryIds.length;
  }

  public clearQuery(queryId: string) {
      delete this.widgetQueries[queryId];
  }
}

export const SRVC_WIDGET_QUERY_STATE = 'WidgetQueryStateService';
app.service(SRVC_WIDGET_QUERY_STATE, WidgetQueryStateService);
