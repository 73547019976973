/* eslint-disable no-undef */
app.controller('OpportunityCampaignHistoryCtrl', ['$scope', '$http', '$window', '$location', '$stateParams', 'utilities', '$state', "$rootScope", 'api', 'colorMap', '_', 'userModels', 'campaignPerformance', '$compile', '$templateCache', '$timeout', 'navigation', 'campaign', 'trend', '$filter', function ($scope, $http, $window, $location, $stateParams, utilities, $state, $rootScope, api, colorMap, _, userModels, campaignPerformance, $compile, $templateCache, $timeout, navigation, campaign, trend, $filter) {
    var dataCall, stagesCall;

    function makeURLCallback(id) {
        return "/" + id;
    }

    function getTransformProp(transform, x) {
        var t = transform.split('translate')[1];
        t = t.substring(1, t.length - 1);
        if (x) {
            return parseInt(t.split(',')[0]);
        } else {
            return parseInt(t.split(',')[1]);
        }
    }

    function attachScrollEvent() {
        var oppLabels = document.getElementsByClassName('scrollable');
        _.forEach(oppLabels, function (ele) {
            var elem = $(ele);
            var slide_timer,
                slide = function () {
                    ele.scrollLeft += 1;
                    if (ele.scrollLeft < ele.scrollWidth) {
                        slide_timer = setTimeout(slide, 40);
                    }
                };

            ele.onmouseover = ele.onmouseout = function (e) {
                e = e || window.event;
                e = e.type === 'mouseover';
                clearTimeout(slide_timer);

                if (e) {
                    elem.css('overflow', 'auto');
                    elem.css('overflow', 'hidden');
                    elem.css('text-overflow', 'clip');
                    slide();
                } else {
                    elem.css('text-overflow', '');
                    elem.scrollLeft(0);
                }
            };
        });
    }

    function offset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    function formatChartSettings(chartData) {
        var padding1;
        var padding2;
        var firstDay;
        var lastDay;
        // may need to change if hiding x axis
        if (chartData.length >= 1) {
            if (chartData.length >= 8) {
                firstDay = $scope.chart.data[0].date;
                lastDay = $scope.chart.data[$scope.chart.data.length - 1].date;
                padding1 = moment(firstDay).subtract(3, 'days').format('MM/DD/YY');
                padding2 = moment(lastDay).add(3, 'days').format('MM/DD/YY');
                $scope.chart.state.range = [padding1, padding2];
                $scope.chart.chart.subchart.show = true;
                $scope.chart.chart.axis.x.tick.count = 8;
                $scope.chart.chart.axis.x.tick.format = function (x) {
                    return moment(x).format("MMM 'YY");
                };
            }
            if (chartData.length < 8 || $scope.data.sameMonthAndYear) {
                $scope.chart.chart.axis.x.tick.format = '%m/%d/%y';
                $scope.chart.chart.subchart.show = false;
                $scope.chart.chart.axis.x.tick.count = chartData.length;
                firstDay = $scope.chart.data[0].date;
                lastDay = $scope.chart.data[$scope.chart.data.length - 1].date;
                padding1 = moment(firstDay).subtract(1, 'days').format('MM/DD/YY');
                padding2 = moment(lastDay).add(1, 'days').format('MM/DD/YY');
                $scope.chart.state.range = [0, 0];
            }
        } else {
            $scope.chart.chart.subchart.show = false;
        }
    }

    function moveGripIcon() {
        var extent = document.getElementsByClassName('extent')[0];
        var g = document.getElementById('svg-grip-container');
        g.setAttribute('transform', 'translate(' + (parseInt(extent.getAttribute('width')) / 2 + parseInt(extent.getAttribute('x'))) + ',0)');
    }

    function createGripIcon() {
        if (!document.getElementById('svg-grip-container')) {
            var extent = document.getElementsByClassName('extent')[0];
            // eslint-disable-next-line no-unused-vars
            var brush = document.getElementsByClassName('c3-brush')[0];
            // var background = brush.getElementsByClassName('background')[0];
            var svgNS = "http://www.w3.org/2000/svg";
            var rect1 = document.createElementNS(svgNS, 'rect');
            rect1.setAttribute('class', 'grip-icon');
            rect1.setAttribute('width', 4);
            rect1.setAttribute('height', 6);
            rect1.setAttribute('y', parseInt(extent.getAttribute('y')) + 2);
            var rect2 = rect1.cloneNode(true);
            var rect3 = rect1.cloneNode(true);
            rect1.setAttribute('x', 7);
            rect3.setAttribute('x', 14);
            // rect1.style.x = 7;
            // rect3.style.x = 14;
            var g = document.createElementNS(svgNS, 'g');
            g.setAttribute('id', 'svg-grip-container');
            g.setAttribute('transform', 'translate(' + (parseInt(extent.getAttribute('width')) / 2 + parseInt(extent.getAttribute('x'))) + ',0)');
            g.appendChild(rect1);
            g.appendChild(rect2);
            g.appendChild(rect3);
            extent.parentNode.insertBefore(g, extent.nextSibling);
        }
    }

    function createSlider() {
        var subChart = document.getElementsByClassName('c3-chart')[1],
            brush = document.getElementsByClassName('c3-brush')[0],
            background = brush.getElementsByClassName('background')[0],
            backgroundCopy = background.cloneNode(),
            extent = document.getElementsByClassName('extent')[0],
            xClone = document.getElementsByClassName('x-clone')[0];

        backgroundCopy.setAttribute('id', "background-copy");
        backgroundCopy.classList.remove('background');
        if (!document.getElementById('background-copy')) {
            brush.insertBefore(backgroundCopy, brush.firstChild);
        }
        backgroundCopy = document.getElementById('background-copy');
        backgroundCopy.setAttribute('width', parseInt(background.getAttribute('width')));
        subChart.style.display = 'none';
        brush.removeAttribute('clip-path');
        extent.setAttribute('height', 10);
        if (background.getAttribute('height')) {
            extent.setAttribute('y', parseInt(background.getAttribute('height')) - 5);
            backgroundCopy.setAttribute('y', (parseInt(extent.getAttribute('y'))));
            backgroundCopy.setAttribute('height', 4);
            backgroundCopy.style.visibility = 'visible';

            var handles = brush.getElementsByClassName('resize');
            _.forEach(handles, function (handle) {
                handle.removeEventListener('mouseup', function () {

                });
                handle.onmouseup = function () {
                    if ($scope.data.domains) {
                        $scope.$broadcast('stopSliding');
                    }
                };
                var rect = handle.getElementsByTagName('rect')[0];
                rect.style.y = parseInt(extent.style.y) - 5;

                rect.setAttribute('y', parseInt(extent.getAttribute('y')) - 5);
                rect.setAttribute('height', 20);
                rect.setAttribute('width', 20);
                rect.setAttribute('x', -4);
                rect.setAttribute('rx', 15);
            });

            if (xClone) {
                xClone.getElementsByTagName('path')[0].style.display = 'none';
            }
            // grip icon
            $timeout(function () {
                createGripIcon();
            }, 0);
        }
    }

    function cloneXAxis(newCopy) {
        var subChart = document.getElementsByClassName('c3-chart')[1].parentElement;
        if (subChart) {
            var ticks = subChart.getElementsByClassName('c3-axis-x')[0], // orginal subchart x-axis
                pathElt = ticks.getElementsByTagName('path')[0],
                xAxisClone = $(ticks).clone()[0], // resized clone
                angularChart = document.getElementsByTagName('angular-chart')[0],
                // eslint-disable-next-line no-unused-vars
                svgElt = angularChart.getElementsByTagName('svg')[0],
                parentChart = document.getElementsByClassName('c3-chart')[0].parentElement,
                subChartBrush = document.getElementsByClassName('c3-brush')[0],
                subChartHeight = subChartBrush.getElementsByClassName('background')[0].getAttribute('height'),
                transform = subChartBrush.parentElement.getAttribute('transform'),
                transformX = getTransformProp(transform, true),
                transformY = getTransformProp(transform),
                y = transformY + parseInt(subChartHeight),
                translate = 'translate(' + transformX + ',' + y + ')';

            pathElt.style.display = 'none';

            if (ticks.getElementsByTagName('g').length) {
                if (!$scope.data.xAxisClone || newCopy) {
                    if (newCopy) {
                        /* we know the ticks are currently weeks, not months */
                        var currTicks = $scope.data.xAxisClone.getElementsByClassName('tick');
                        var orgTicks = ticks.getElementsByClassName('tick');
                        if (orgTicks.length !== currTicks.length) {
                            //get path length
                            var d = pathElt.getAttribute('d');
                            $scope.data.xAxisClone.getElementsByTagName('path')[0].setAttribute('d', d);
                            //get difference between first tick and last tick
                            var firstTickPos = getTransformProp(orgTicks[0].getAttribute('transform'), true);
                            var lastTickPos = getTransformProp(orgTicks[orgTicks.length - 1].getAttribute('transform'), true);
                            var spacing = (lastTickPos - firstTickPos) / (currTicks.length - 1);
                            //change transform each tick in $scope.data.xAxis
                            var i;
                            var currNum = 0;
                            for (i = 0; i < currTicks.length; i++) {
                                var t;
                                //use first index of chart
                                if (i === 0 || i === currTicks.length - 1) {
                                    var tickCopy = i === 0 ? orgTicks[0] : orgTicks[orgTicks.length - 1];
                                    if (_.includes(tickCopy.getAttribute('transform'), 'translate(')) {
                                        currNum = getTransformProp(tickCopy.getAttribute('transform'), true);
                                    }
                                } else {
                                    currNum = currNum + spacing;
                                }
                                t = 'translate(' + currNum + ',0)';
                                currTicks[i].setAttribute('transform', t);
                            }
                            // append
                            parentChart.parentNode.insertBefore($scope.data.xAxisClone, parentChart.nextSibling);
                        } else {
                            document.getElementsByClassName('x-clone')[0].remove();
                            if (xAxisClone.classList.contains('hidden')) {
                                xAxisClone.classList.remove('hidden');
                            }
                        }
                    }
                    if (xAxisClone && angularChart && !angularChart.getElementsByClassName('x-clone').length) {
                        if (!xAxisClone.classList.contains('x-clone')) {
                            xAxisClone.classList.add('x-clone');
                        }
                        $scope.data.xAxisClone = xAxisClone;
                        parentChart.parentNode.insertBefore($scope.data.xAxisClone, parentChart.nextSibling);
                        $scope.data.xAxisClone.setAttribute('transform', translate);
                    }
                } else {
                    var className = pathElt.getAttribute('class');
                    // eslint-disable-next-line no-redeclare
                    var d = pathElt.getAttribute('d');
                    $scope.data.xAxisClone.getElementsByTagName('path')[0].setAttribute('class', className);
                    $scope.data.xAxisClone.getElementsByTagName('path')[0].setAttribute('d', d);
                    parentChart.parentNode.insertBefore($scope.data.xAxisClone, parentChart.nextSibling);
                }
            }
        }
        createSlider();
    }

    function resetLegend() {
        $scope.data.legend = {
            groups: {
                title: 'Campaigns',
                data: {
                    'campaign types': []
                }
            },
            contacts: {
                title: 'People',
                data: {
                    'types': [],
                    'titles': []
                }
            }
        };
        $scope.data.legend.groups.data[$scope.data.channel_group] = [];
    }

    function loadOpptyInfo() {
        $state.current.data.loading = true;
        if (dataCall) { dataCall.abort(); }

        (dataCall = api.get('oppty_campaign_history', $scope.query)).then(function (data) {
            $scope.data.data = data.data;

            var dynamics365Enabled = $scope.orgConfig.organization_settings && $scope.orgConfig.organization_settings.dynamics365_enabled;

            $scope.data.data.associations.forEach(function (d) {
                d.campaign_url = '#/campaign/attribution?name=' + d.campaign_name + '&campaignId=' + d.campaign_id;
                var extLeadUrl = $scope.data.sfdcURL + makeURLCallback(d.contact_id);

                if (dynamics365Enabled) {
                    extLeadUrl = $scope.orgConfig.organization_settings.dynamicsUrl
                        + "/main.aspx?pagetype=entityrecord&etc=4&id=%7b" + d.contact_id + "%7d";
                }

                d.dynamics365Enabled = dynamics365Enabled;
                d.contact_url = extLeadUrl;
                d.source_campaign = d.active && $scope.data.data.oppty.source_campaign_id === d.campaign_id;

                d.offer = _.compactObjectDeep({
                    name: d.offer_name,
                    referrer: d.offer_referrer,
                    url: d.offer_url
                });
            });

            $scope.associations = $scope.data.data.associations;
            $scope.data.oppty = $scope.data.data.oppty;
            angular.forEach($scope.data.campaignStates, function (state) {
                var s = $state.get(state.name);
                s.data.title = $scope.data.oppty && $scope.data.oppty.name ? $scope.data.oppty.name : "Opportunity Specific";
            });

            if ($scope.data.oppty) {
                $state.current.data.title = $scope.data.oppty.name;
            } else {
                $state.current.data.title = "Opportunity Specific";
            }
            $scope.makeInfoTab();
            $scope.calc.general($scope.data.data);
            $scope.changeModel();
            $state.current.data.loading = false;
        });
    }

    function checkStageDupes(stage) {
        var date = $filter('date')(stage.start_date, 'shortDate');
        var dupeIndex = _.findIndex($scope.chart.chart.regions, { 'start': date });
        if (dupeIndex !== -1) {
            $scope.chart.chart.regions.splice(dupeIndex, 1);
        }
    }

    function getStagesLabels() {
        // eslint-disable-next-line no-unused-vars
        _.forEach($scope.chart.data, function (data, index) {
            var date = moment(data.date);
            _.forEach($scope.chart.chart.regions, function (region) {
                if (region.end && date.isBefore(moment(region.end)) && date.isAfter(moment(region.start)) || !region.end && date.isAfter(moment(region.start)) || date.isSame(moment(region.start))) {
                    data.stage = region.name;
                }
            });
        });
    }

    function formatStages() {
        /* REGION.END is the correct START date of the stage. Region.start is the */
        _.forEach($scope.data.stages, function (stage, index) {
            var stageDate = $filter('date')(stage.start_date, 'shortDate'),
                lastDate = $scope.chart.data[$scope.chart.data.length - 1].date;
            if (moment(stageDate).isBefore(moment(lastDate)) || moment(stageDate).isSame(moment(lastDate))) {
                checkStageDupes(stage);
                if (index == $scope.data.stages.length - 1) {
                    $scope.chart.chart.regions.push({
                        name: stage.name,
                        start: stageDate,
                        class: !$scope.chart.chart.regions.length ? 'gray-region' : ($scope.chart.chart.regions.length - 1) % 2 === 0 ? 'white-region' : 'gray-region'
                    });
                } else {
                    $scope.chart.chart.regions.push({
                        name: stage.name,
                        start: stageDate,
                        end: $filter('date')($scope.data.stages[index + 1].start_date, 'shortDate'),
                        class: !$scope.chart.chart.regions.length ? 'gray-region' : ($scope.chart.chart.regions.length - 1) % 2 === 0 ? 'white-region' : 'gray-region'
                    });
                }
            }
        });
        getStagesLabels();
    }

    function getStages(id) {
        if (stagesCall) { stagesCall.abort(); }

        (stagesCall = api.get('opportunityHistory', { opptyId: id }, false)).then(function (data) {
            $scope.data.stages = _.orderBy(data.data, ['start_date'], ['asc']);
            if ($scope.chart.data.length > 0) {
                formatStages();
            }
        });
    }

    var changeXAxis = function (domain) {
        $timeout(function () {
            var d1 = $filter('date')(domain[0], 'shortDate'),
                d2 = $filter('date')(domain[1], 'shortDate'),
                difference;
            d1 = moment(d1);
            d2 = moment(d2);

            if (d1 && d2) {
                difference = d2.diff(d1, 'days');
            }

            if (difference < 90) {
                $scope.chart.chart.axis.x.tick.format = '%m/%d/%y';
                $scope.chart.chart.axis.x.tick.culling = false;
                $scope.chart.chart.axis.x.tick.count = 32;

            } else {
                $scope.chart.chart.axis.x.tick.format = function (x) {
                    return moment(x).format("MMM 'YY");
                };
                $scope.chart.chart.axis.x.tick.culling = true;
                $scope.chart.chart.axis.x.tick.count = 8;
            }
            $scope.data.domains = null;
        }, 0);
    };

    function calcAttributed(row, model) {
        if (model == "custom") {
            row.percent_attributed = ($scope.data.oppty.is_closed ? row.amount_attributed : row.pipeline_amount_attributed) / $scope.data.oppty.amount * 100;
            row.dollar_attributed = $scope.data.oppty.is_closed ? row.amount_attributed : row.pipeline_amount_attributed;
        } else if (model == "even") {
            row.percent_attributed = (row.active && row.is_attributed && ($scope.data.oppty.won || $scope.data.oppty.campaign_attributions == $scope.data.oppty.pipeline_campaign_attributions || !row.is_post_oppty_creation)) ? (1 / ($scope.data.oppty.is_closed && $scope.data.oppty.won ? $scope.data.oppty.campaign_attributions : $scope.data.oppty.pipeline_campaign_attributions) * 100) : 0;
            row.dollar_attributed = $scope.data.oppty.amount * row.percent_attributed / 100;
        } else if (model == "sourced" || model == "last") {
            row.percent_attributed = 100;
            row.dollar_attributed = $scope.data.oppty.amount;
        }
    }

    function getUniqueCampaignIds() {
        var m = {};
        $scope.data.influence.forEach(function (d) {
            if (!m[d.campaign_id]) {
                m[d.campaign_id] = d.campaign_id;
            }
        });
        $scope.data.unique_campaign_ids = Object.keys(m);
    }

    function formatLegend(data) {
        /*
        C - Level: Chief, C_O
        VP: Vice President, VP
        Director: Dir, Dir, Dr
        Manager: Mgr, Mngr
        Other: Everything Else
        */
        if (data.title) {
            var title = data.title.toLowerCase();
            if (title.includes('chief') || title.match(/\bc.{1}O\b/ig)) {
                data.role = 'C-Level';
                data.prio = 1;
            } else if (title.includes('vp') || title.includes('vice president') || title.includes('v.p')) {
                data.role = 'Vice Presidents';
                data.prio = 2;
            } else if (title.includes('director') || title.includes('dir') || title.includes('dr')) {
                data.role = 'Directors';
                data.prio = 3;
            } else if (title.includes('manager') || title.includes('mgr') || title.includes('mngr')) {
                data.role = 'Managers';
                data.prio = 4;
            } else {
                data.role = 'Other';
                data.prio = 5;
            }
        } else {
            data.role = 'Other';
            data.prio = 5;
        }

        if (!_.find($scope.data.legend.contacts.data.titles, { name: data.role })) {
            $scope.data.legend.contacts.data.titles.push({
                name: data.role,
                show: true,
                priority: data.prio,
                key: 'role',
                group: 'contacts'
            });
        }

        if (data.relationship) {
            if (!_.find($scope.data.legend.contacts.data.types, { name: data.relationship })) {
                $scope.data.legend.contacts.data.types.push({
                    name: data.relationship,
                    show: true,
                    key: 'relationship',
                    group: 'contacts'
                });
            }
        }

        if (data.campaign_group) {
            if (!_.find($scope.data.legend.groups.data['campaign types'], { name: data.campaign_group })) {
                $scope.data.legend.groups.data['campaign types'].push({
                    name: data.campaign_group,
                    show: true,
                    key: 'campaign_group',
                    group: 'groups'
                });
            }
        }

        if (data.channel_group) {
            if (!_.find($scope.data.legend.groups.data[$scope.data.channel_group], { name: data.channel_group })) {
                $scope.data.legend.groups.data[$scope.data.channel_group].push({
                    name: data.channel_group,
                    show: true,
                    key: 'channel_group',
                    group: 'groups'
                });
            }
        }
    }

    function formatInfluencing(data) {
        /* format data for People Influencing Call */
        var person = _.find($scope.data.peopleInfluencing, { 'contact_name': data.contact_name });
        if (person) {
            person.touches.push(data);
            person.touchesAmt = person.touches.length;
        } else {
            var newPerson = {
                contact_name: data.contact_name,
                title: data.title ? data.title : '--',
                relationship: data.relationship,
                touches: [data],
                contact_id: data.contact_id,
                extLeadUrl: $scope.data.sfdcURL + '/' + data.contact_id
            };
            newPerson.touchesAmt = newPerson.touches.length;

            var dynamics365Enabled = $scope.orgConfig.organization_settings && $scope.orgConfig.organization_settings.dynamics365_enabled;

            if (dynamics365Enabled) {
                newPerson.dynamics365Enabled = dynamics365Enabled;
                newPerson.extLeadUrl = $scope.orgConfig.organization_settings.dynamicsUrl
                    + "/main.aspx?pagetype=entityrecord&etc=4&id=%7b" + data.contact_id + "%7d";
            }

            $scope.data.peopleInfluencing.push(newPerson);
        }

        /* format data for Campaign Influencing Call */
        var campaign = _.find($scope.data.campaignsInfluencing, { 'campaign_name': data.campaign_name });
        if (campaign) {
            campaign.people.push(data);
            campaign.membersAmt = campaign.people.length;
            campaign.totalPercentage = campaign.totalPercentage + data.percent_attributed;
            campaign.totalDollars = campaign.totalDollars + data.dollar_attributed;
        } else {
            var newCampaign = {
                campaign_name: data.campaign_name,
                campaign_id: data.campaign_id,
                cm_created_date: data.cm_created_date,
                campaign_group: data.campaign_group,
                people: [data]
            };
            newCampaign.totalPercentage = data.percent_attributed;
            newCampaign.totalDollars = data.dollar_attributed;
            newCampaign.membersAmt = newCampaign.people.length;
            $scope.data.campaignsInfluencing.push(newCampaign);
        }
    }

    function formatData(data) {
        resetLegend();
        var chartData = [];
        $scope.data.campaignsInfluencing = [];
        $scope.data.peopleInfluencing = [];
        $scope.data.sameMonthAndYear = true;
        data = _.orderBy(data, ['cm_created_date'], ['asc']);
        var firstDate = $filter('date')(data[0].cm_created_date, 'shortDate');
        var touches = 0;
        _.forEach(data, function (d) {
            formatLegend(d);
            $scope.data.legend.contacts.data.titles = _.orderBy($scope.data.legend.contacts.data.titles, ['priority'], ['asc']);
            $scope.data.legend.contacts.data.types = _.orderBy($scope.data.legend.contacts.data.types, ['name'], ['asc']);
            $scope.data.legend.groups.data['campaign types'] = _.orderBy($scope.data.legend.groups.data['campaign types'], ['name'], ['asc']);
            if ($scope.data.legend.groups.data[$scope.data.channel_group] && $scope.data.legend.groups.data[$scope.data.channel_group].length) {
                $scope.data.legend.groups.data[$scope.data.channel_group] = _.orderBy($scope.data.legend.groups.data[$scope.data.channel_group], ['name'], ['asc']);
            }
            var date = $filter('date')(d.cm_created_date, 'shortDate');
            if (moment(date).format('MM/YY') !== moment(firstDate).format('MM/YY')) {
                $scope.data.sameMonthAndYear = false;
            }

            formatInfluencing(d);
            var dataObj = _.find(chartData, { date: date });
            if (dataObj) {
                dataObj.campaigns.push(d);
                dataObj.touches = dataObj.campaigns.length;
                touches = touches + 1;
                dataObj['total touches'] = touches;
            } else {
                var obj = {
                    date: date,
                    campaigns: [d]
                };
                obj.cm_created_date = d.cm_created_date;
                obj.touches = obj.campaigns.length;
                obj.disabledCount = 0;
                touches = touches + obj.touches;
                obj['total touches'] = touches;
                chartData.push(obj);
            }
        });

        $scope.chart.data = chartData;
        getStages($scope.query.oppty);

        $scope.data.chartDataCopy = angular.copy(chartData);
        $scope.data['Total Touches'] = touches;
        formatChartSettings(chartData);
        $scope.$broadcast('$$rebind::refreshData');
    }

    function showLabel(d) {
        d3.selectAll(".oppty-chart .c3-shape").style("opacity", 0.1);
        var k = ".c3-shape-" + d.index;
        d3.selectAll('.oppty-chart ' + k).style("opacity", 1);
        d3.selectAll('.oppty-chart .c3-circle').each(function (data) {
            if (d.index == data.index && !document.getElementsByClassName('data-label').length && !document.getElementsByClassName('expanded_circle').length) {
                var dataNode = d3.select(this),
                    touches = $scope.chart.data[d.index].touches,
                    e = $(dataNode[0]),
                    label = $scope.chart.data[data.index].date,
                    stage = $scope.chart.data[data.index].stage,
                    chart = document.getElementsByClassName('oppty-chart')[0],
                    x = e[0].cx.baseVal.value,
                    y = e[0].cy.baseVal.value;
                chart = chart.getElementsByTagName('g')[0];
                //fix here
                var fillColor = dataNode[0][0].classList.contains('group-highlight') && dataNode[0][0].classList.contains('contact-highlight') ? '#f7973d' : dataNode[0][0].classList.contains('group-highlight') ? '#168FC3' : dataNode[0][0].classList.contains('contact-highlight') ? '#f7973d' : '#168FC3';
                var stroke_width = dataNode[0][0].classList.contains('group-highlight') ? '2px' : '1px';
                d3.select(chart).insert('circle')
                    .attr('cx', x)
                    .attr('cy', y)
                    .attr('r', parseInt($scope.chart.chart.point.r) * 1.75)
                    .attr('fill', fillColor)
                    .attr('class', 'expanded_circle')
                    .attr('stroke-width', stroke_width);

                if (stage) {
                    d3.select(chart).insert('text')
                        .attr('x', x)
                        .attr('y', y - 40)
                        .attr('class', 'stage-label')
                        .attr("text-anchor", "middle")
                        .text(stage);
                }

                d3.select(chart).insert('text')
                    .attr('x', x)
                    .attr('y', y - 20)
                    .attr('class', 'data-label')
                    .attr("text-anchor", "middle")
                    .text(label);

                d3.select(chart).insert('text')
                    .attr('x', x)
                    .attr('y', y + 5)
                    .attr('class', 'inner-data-label')
                    .attr("text-anchor", "middle")
                    .text(touches);
            }
        });
    }

    function loopThroughLegend(category) {
        for (var key in category) {
            for (var i = 0; i < category[key].length; i++) {
                if (!category[key][i].show) {
                    return true;
                }
            }
        }
        return false;
    }

    function hideLabel(d) {
        var svg;
        if (document.getElementsByClassName('data-label').length) {
            svg = d3.select(".oppty-chart");
            svg.selectAll("text.data-label").remove();
        }
        if (document.getElementsByClassName('stage-label').length) {
            svg = d3.select(".oppty-chart");
            svg.selectAll("text.stage-label").remove();
        }
        if (document.getElementsByClassName('inner-data-label').length) {
            svg = d3.select(".oppty-chart");
            svg.selectAll("text.inner-data-label").remove();
        }

        if (document.getElementsByClassName('expanded_circle').length) {
            svg = d3.select(".oppty-chart");
            svg.selectAll(".expanded_circle").remove();
        }

        d3.selectAll(".oppty-chart .c3-circle").style("opacity", 1);
        d3.selectAll(".oppty-chart .c3-line").style("opacity", 1);
        d3.selectAll(".oppty-chart .c3-area-total-touches").style("opacity", 0.2);

        var k = ".c3-shape-" + d.index;
        d3.selectAll('.oppty-chart ' + k).style("opacity", 1);
        // eslint-disable-next-line no-unused-vars
        d3.selectAll("#chart").each(function (d) {
            d3.selectAll(".oppty-chart .c3-shape").style("opacity", 1);
        });
    }

    function tooltipHovered(d) {
        var chartContainer = document.getElementsByClassName('oppty-chart')[0];
        var tooltip = chartContainer.getElementsByClassName('c3-tooltip-container')[0];
        var chart = chartContainer.getElementsByTagName('g')[0];

        $(chart).mouseleave(function () {
            chartContainer.getElementsByClassName('c3-tooltip-container')[0].style.display = 'block';
            var table = chartContainer.getElementsByClassName('c3-tooltip');
            if (table.length) {
                table[0].style.display = "table";
            }
            if (chartContainer.getElementsByClassName('c3-tooltip')[0]) {
                chartContainer.getElementsByClassName('c3-tooltip')[0].style.display = "none";
            }
            hideLabel(d);
        });

        $(tooltip).unbind("mouseenter");
        $(tooltip).unbind("mouseleave");

        $(tooltip).mouseenter(function () {
            showLabel(d);
            chartContainer.getElementsByClassName('c3-tooltip-container')[0].style.display = 'block';
            var table = chartContainer.getElementsByClassName('c3-tooltip');
            if (table.length) {
                table[0].style.display = "table";
            }
            attachScrollEvent();
            d3.selectAll('.oppty-chart .c3-circle').each(function (data) {
                if (d.index == data.index) {
                    var dataNode = this;
                    dataNode.setAttribute('r', parseInt($scope.chart.chart.point.r) * 1.75);
                }
            });
        });

        $(tooltip).mouseleave(function () {
            hideLabel(d);
            chartContainer.getElementsByClassName('c3-tooltip')[0].style.display = "none";
            d3.selectAll('.c3-circle').each(function (data) {
                if (d.index == data.index) {
                    var dataNode = this;
                    dataNode.setAttribute('r', parseInt($scope.chart.chart.point.r));
                }
            });
        });
    }

    $scope.showListView = function () {
        $scope.timelineView = false;
    };

    $scope.makeInfoTab = function () {
        $scope.data.infoTab = [
            {
                'label': 'Account',
                'filter': 'date',
                'link': $scope.shref('app.analyze.accountsAccountSpecific.attribution', { accountId: $scope.data.oppty.account_id }),
                'data': $scope.data.oppty.account_name
            },
            {
                'label': 'Created',
                'filter': 'date',
                'data': $scope.data.oppty.created_date ? $scope.data.oppty.created_date : 'N/A'
            },
            {
                'label': 'Closed',
                'filter': 'date',
                'data': $scope.data.oppty.close_date ? $scope.data.oppty.close_date : 'N/A'
            },
            {
                'label': 'Opp. Type',
                'data': $scope.data.oppty.type ? $scope.data.oppty.type : 'N/A'
            }
        ];
    };

    $scope.filterData = function (val) {
        if (val.show) {
            val.show = false;
            val.highlight = false;
        } else {
            val.show = true;
        }
        filterDataByGroups(val.show);
        formatChartSettings($scope.chart.data);
    };

    var colorNodes = function (highlightArr) {
        //reset all the nodes
        d3.selectAll(".c3-circle").classed('group-highlight', false);
        d3.selectAll(".c3-circle").classed('contact-highlight', false);
        //goes through higlight arr and just rehighlights everytime
        _.forEach(highlightArr, function (highlight) {
            d3.selectAll(".c3-circle").each(function (d) {
                var dataNode = d3.select(this);
                if ($scope.chart.data[d.index]) {
                    for (var i = 0; i < $scope.chart.data[d.index].campaigns.length; i++) {
                        if ($scope.chart.data[d.index].campaigns[i][highlight.key] == highlight.name) {
                            if (highlight.group == 'groups') {
                                dataNode.classed('group-highlight', true);
                            } else {
                                dataNode.classed('contact-highlight', true);
                            }
                            break;
                        }
                    }
                }
            });
        });
    };
    //here
    $scope.highlight = function (val, bool) {
        var highlighted = [];
        if (val) {
            if (!bool) {
                val.highlight = true;
            } else {
                val.highlight = false;
            }
        }
        // adds icon to legend
        _.forEach($scope.data.legend, function (section) {
            _.forEach(section.data, function (data) {
                var temp = _.filter(data, function (d) {
                    if (d.highlight && d.show) {
                        return d;
                    }
                });
                highlighted = highlighted.concat(temp);
            });
        });
        colorNodes(highlighted);
    };

    function filterDataByGroups() {
        var newArr = [],
            removedItems = [],
            dataCopy = angular.copy($scope.data.chartDataCopy);

        _.forEach($scope.data.legend, function (section) {
            _.forEach(section.data, function (data) {
                var temp = _.filter(data, function (d) {
                    if (!d.show) {
                        return d;
                    }
                });
                removedItems = removedItems.concat(temp);
            });
        });

        if (removedItems.length) {
            _.forEach(dataCopy, function (data) {
                // checks if campaigns in touches on a single date are different
                _.forEach(data.campaigns, function (touch) {
                    for (var i = 0; i < removedItems.length; i++) {
                        if (touch[removedItems[i].key] == removedItems[i].name) {
                            touch.disabled = true;
                            data.disabledCount = data.disabledCount + 1;
                            break;
                        }
                    }
                });
                if (data.disabledCount !== data.campaigns.length) {
                    newArr.push(data);
                }
            });
        } else {
            newArr = dataCopy;
        }
        $scope.chart.data = newArr;
        getStagesLabels();
    }

    $scope.getOpptyName = function (val) {
        return $http.get('api/v1/oppty_names_starts_like', {
            params: {
                startName: val
            }
        }).then(function (response) {
            $scope.query.isId = response.data.length === 0;
            return response.data;
        });
    };

    $scope.touchType = function (row) {
        var types = [];
        if (!row.is_post_oppty_creation) {
            types.push('pre');
        }
        if (row.is_source_campaign) {
            types.push('first');
        }
        if (row.is_last_campaign) {
            types.push('last');
        }
        if (row.is_post_oppty_creation) {
            types.push('post');
        }
        return types.join(',');
    };

    $scope.changeModel = function () {
        $rootScope.$broadcast('modelChanged', "");
        $scope.data.allInfluence = false;
        $scope.data.influence = $scope.associations.filter(function (row) {
            calcAttributed(row, $scope.query.model);
            if ($scope.query.model == "custom" || $scope.query.model == "even") {
                return row.is_attributed && row.active;
            } else if ($scope.query.model == "sourced") {
                return row.is_source_campaign;
            } else if ($scope.query.model == "last") {
                return row.is_last_campaign;
            }
        });
        // add 1 hour to the created_date of the last campaign to ensure that it is the last campaign before post oppty touches in sorting
        _.forEach($scope.data.influence, function (campaign) {
            if (campaign.is_last_campaign) {
                campaign.cm_created_date = moment(campaign.cm_created_date).add(1, 'hours').toISOString();
            }
        });
        utilities.queryString({ model: $scope.query.model });
        getUniqueCampaignIds();
        if ($scope.data.influence.length) {
            formatData($scope.data.influence);
        }
        else {
            $scope.$broadcast('$$rebind::refreshData');
        }
    };

    $scope.filterInfluence = function () {
        $rootScope.$broadcast('detailChanged', "influence type");
        if ($scope.data.allInfluence) {
            $scope.query.model = "even";
        }
        $scope.data.influence = $scope.associations.filter(function (row) {
            calcAttributed(row, $scope.query.model);
            return $scope.data.allInfluence || (row.is_attributed && row.active);
        });
    };

    $scope.oppty_entered = function () {
        $rootScope.$broadcast('detailChanged', "oppty selected");
        loadOpptyInfo();
    };

    $scope.getRowClass = function (row) {
        if (row.getProperty('active')) {
            if (row.getProperty('source_campaign')) {
                return "source-campaign";
            } else {
                return "row-active";
            }
        } else {
            return "row-inactive";
        }
    };

    $scope.getExportDataOCH = function () {
        $rootScope.$broadcast('exportData', "csv", { info: "from ui" });
        return $scope.data.influence.map(function (d) {
            return {
                cm_created_date: d.cm_created_date, campaign_name: d.campaign_name, contact_name: d.contact_name,
                type: d.type, is_attributed: d.is_attributed, source_campaign: d.source_campaign
            };
        });
    };

    $scope.getExportHeaderOCH = function () {
        return ['Date', 'Campaign', 'Contact', 'Influence Type', 'Attributed', 'Source Campaign'];
    };

    $scope.isActive = navigation.isActive;

    //------------------------ clean up api calls ------------------------//
    $scope.$on('$destroy', function () {
        if (dataCall) { dataCall.abort(); }
        if (stagesCall) { stagesCall.abort(); }
    });

    (function init() {
        $state.current.data.loading = true;

        $scope.campaignSrv = campaign;
        $scope.platform = $rootScope.userData.platform;

        $scope.colorMap = colorMap;

        $scope._ = _;
        $scope.campaignPerformance = campaignPerformance;
        $scope.data = {
            init: true,
            regions: [],
            tableData: [],
            peopleData: [],
            allInfluence: false,
            modelTypes: userModels,
            channel_group: campaignPerformance.tabMappings('channel_group'),
            channel_name: campaignPerformance.tabMappings('channel_name'),
            cohorts: utilities.formatCohorts($rootScope.rawCohorts, ['all']),
            quarters: utilities.formatCohorts($rootScope.rawCohorts, ['quarter']),
            tScope: $scope.$new(),
            removedData: [],
            hiddenData: [],
            highlighted: {},
            hasHighlight: false,
            hasHidden: false,
            view: 'timeline',
            currencyFilter: 'bfCurrency'
        };

        $scope.query = _.defaults($state.params);

        if (!_.isDefined(_.find($scope.data.modelTypes, { key: $scope.query.model }))) {
            $scope.query.model = "even";
        }

        if ($scope.platform !== 'full') {
            $scope.query.modelType = "even";
        }

        $scope.data.tooltip = $compile($templateCache.get('opptySpecTooltip.html'))($scope.data.tScope);
        $scope.data.campaignStates = campaignPerformance.getCampaignStates($state, $rootScope.userData);
        angular.forEach($scope.data.campaignStates, function (state) {
            state.arrName = state.name == 'app.analyze.opportunities.opportunitySpecific.attribution.totalTouches' ? 'influence' : state.name == 'app.analyze.opportunities.opportunitySpecific.attribution.peopleInfluencing' ? 'peopleInfluencing' : 'campaignsInfluencing';
            state.config.show = true;
        });
        $scope.chart = {
            dimensions: {
                date: {
                    axis: "x",
                },
                'total touches': {
                    type: "area",
                },
            },
            state: {},
            chart: {
                onrendered: function () {
                    // ticks code
                    var chartContainer = document.getElementsByClassName('oppty-chart')[0],
                        subChart = document.getElementsByClassName('c3-chart')[1].parentElement;

                    if ($scope.chart.data && $scope.chart.data.length >= 8 && !$scope.data.sameMonthAndYear) {
                        var ticks = subChart.getElementsByClassName('c3-axis-x')[0]; // original subticks
                        cloneXAxis();
                        if (!ticks.classList.contains('hidden')) {
                            ticks.classList.add("hidden");
                        }

                        var rightHandle = subChart.getElementsByClassName('resize e')[0];
                        var leftHandle = subChart.getElementsByClassName('resize w')[0];

                        if (!rightHandle.classList.contains('grip-handles')) {
                            rightHandle.classList.add("grip-handles");
                        }
                        if (!leftHandle.classList.contains('grip-handles')) {
                            leftHandle.classList.add("grip-handles");
                        }
                    }

                    if (!$scope.chart.data || $scope.chart.data && !$scope.chart.data.length) {
                        $scope.chart.chart.data.empty = {};
                        $scope.chart.chart.data.empty.label = {};
                        if ($scope.data.legend) {
                            var isHidden = false;
                            var groups = $scope.data.legend.groups.data;
                            var contacts = $scope.data.legend.contacts.data;
                            isHidden = loopThroughLegend(groups);
                            if (!isHidden) {
                                loopThroughLegend(contacts);
                            }
                        }
                        if (!document.getElementById('no-data-container').length) {
                            $scope.chart.chart.subchart.show = false;
                            var newEl = document.getElementById('no-data-container');
                            newEl.style.display = 'block';
                            newEl.innerHTML = isHidden ? '<div class="no-data-text"><div><img src="../images/no-data-face.svg"></div><h3>No Data To Show</h3><p>Too Many Hidden Filters</p></div>' : '<div class="no-data-text"><div><img src="../images/no-data-face.svg"></div><h3>No Data To Show</h3><p>This Opportunity Has No Touches</p></div>';
                            newEl.style.left = (chartContainer.offsetWidth / 2) - (chartContainer.offsetWidth / 4) + 'px';
                            // newEl.style.top = chartContainer.offsetTop + chartContainer.offsetTop/2 +'px' ;
                            chartContainer.parentNode.insertBefore(newEl, chartContainer.nextSibling);
                        }
                    } else {
                        $scope.chart.chart.data.empty = {};
                        // eslint-disable-next-line no-redeclare
                        var newEl = document.getElementById('no-data-container');
                        newEl.style.display = 'none';
                    }
                },
                legend: {
                    show: false
                },
                padding: {
                    right: 60,
                    top: 20,
                },
                zoom: {
                    rescale: true,
                },
                axis: {
                    x: {
                        show: true,
                        type: 'timeseries',
                        tick: {
                            format: function (x) {
                                return moment(x).format("MMM 'YY");
                            },
                            count: 8,
                            centered: true,
                            rotate: 45,
                        },
                        height: 50
                    },
                    y: {
                        padding: {
                            bottom: 0
                        },
                        tick: {
                            format: function (y) {
                                if (y % 1 === 0) {
                                    return y;
                                }
                            }
                        },
                        label: {
                            text: 'Cumulative Responses',
                            position: 'outer-middle'
                        },
                    }
                },
                regions: [],
                grid: {
                    y: {
                        show: true,
                        lines: { class: 'solid-lines' }
                    },
                    x: {
                        lines: []
                    }
                },
                subchart: {
                    // show: true,
                    size: {
                        height: 70
                    },
                    onbrush: function (domain) {
                        $scope.data.domains = domain;
                        moveGripIcon();
                    },
                },
                point: {
                    r: 7,
                    focus: {
                        expand: {
                            enabled: true
                        }
                    }
                },
                color: {
                    pattern: [
                        "#168FC3",
                    ]
                },
                data: {
                    x: 'date',
                    xFormat: '%m/%d/%Y',
                    onmouseover: function (d) {
                        if ($scope.chart.data.length > 1) {
                            hideLabel(d);
                            showLabel(d);
                            tooltipHovered(d);
                        }
                    },
                },
                tooltip: {
                    show: true,
                    // eslint-disable-next-line no-unused-vars
                    position: function (data, width, height, element) {
                        var chart = document.getElementsByClassName('oppty-chart')[0],
                            start = parseInt($(chart).offset().top),
                            // eslint-disable-next-line no-unused-vars
                            startLeft = parseInt($(chart).offset().left),
                            h,
                            left,
                            top;
                        if (!chart.classList.contains('showScrollBar')) {
                            chart.className += " showScrollBar";
                        }
                        d3.selectAll('.c3-circle').each(function (d) {
                            if (data[0].index == d.index) {
                                var dataNode = d3.select(this),
                                    e = $(dataNode[0]),
                                    // eslint-disable-next-line no-unused-vars
                                    top = e.offset().top,
                                    // eslint-disable-next-line no-unused-vars
                                    h = (parseInt(e.attr('r')) * 2) + 5,
                                    // eslint-disable-next-line no-unused-vars
                                    left = e.offset().left;
                            }
                        });

                        var position = c3.chart.internal.fn.tooltipPosition.apply(this, arguments);
                        /* top of the chart's position - top of the circle position  */
                        position.top = top ? (top - start) + h : position.top;
                        if (position.top > position.left) {
                            if (left > 0) {
                                position.left = position.left - (position.left / 2);
                            }
                        } else {
                            if (left > 0) {
                                position.left = position.left + 20;
                            }
                        }
                        return position;
                    },
                    // eslint-disable-next-line no-unused-vars
                    contents: function (d, dt, dv, color) {
                        var index = d[0].index;
                        $scope.data.tScope.rows = $scope.chart.data[index].campaigns.map(function (campaign) {
                            return {
                                contact_name: campaign.contact_name,
                                relationship: campaign.relationship,
                                title: campaign.title,
                                disable: campaign.disabled,
                                percent_attributed: campaign.percent_attributed,
                                campaign_group: campaign.campaign_group,
                                campaign_name: campaign.campaign_name,
                                channel_group: campaign.channel_group,
                                dollar_attributed: campaign.dollar_attributed,
                                is_attributed: campaign.is_attributed ? campaign.is_attributed : false,
                                is_post_oppty_creation: campaign.is_post_oppty_creation,
                                is_source_campaign: campaign.is_source_campaign,
                                is_last_campaign: campaign.is_last_campaign,
                            };
                        });
                        return $scope.data.tScope.$apply(function () { return $scope.data.tooltip[0].outerHTML; });
                    },
                }
            },
        };

        if ($stateParams.oppty) {
            $scope.query.oppty = decodeURIComponent($stateParams.oppty);
            if (!$scope.query.isId) {
                $scope.query.isId = false;
            }
            loadOpptyInfo();
        } else if ($stateParams.isId) {
            $scope.query.oppty = $stateParams.isId;
            $scope.query.isId = true;
            loadOpptyInfo();
        }

        $scope.$on('$windowResizeEnd', function () {
            if ($scope.chart.data && $scope.chart.data.length >= 8 && !$scope.data.sameMonthAndYear) {
                cloneXAxis(true);
                moveGripIcon();
            }
            if ($scope.chart.data && !$scope.chart.data.length && document.getElementById('no-data-container').style.display === 'block') {
                var chartContainer = document.getElementsByClassName('oppty-chart')[0];
                var newEl = document.getElementById('no-data-container');
                newEl.style.left = (chartContainer.offsetWidth / 2) - (chartContainer.offsetWidth / 4) + 'px';
            }
            $timeout(function () {
                $scope.$broadcast('rendered');
            }, 0);
        });

        $scope.data.sfdcURL = $scope.sfdcURL;
        // eslint-disable-next-line no-unused-vars
        $scope.$on('angular-chart-rendered', function (event, options, instance) {
            $scope.highlight();
            $scope.$broadcast('rendered');
        });

        $scope.$on('rendered', function () {
            if ($scope.chart.data && $scope.chart.data.length == 1) {
                var container = document.getElementById('one-data');
                var circle = document.getElementsByClassName('c3-circle')[0];
                var circleOffset = offset(circle);

                container.style.left = ((circleOffset.left / 2)) + 72 + 'px';

                $scope.chart.chart.tooltip.show = false;
                $scope.chart.chart.point.focus.expand.enabled = false;
                $scope.chart.chart.axis.x.show = false;
                attachScrollEvent();
            } else {
                $scope.chart.chart.tooltip.show = true;
                $scope.chart.chart.point.focus.expand.enabled = true;
                $scope.chart.chart.axis.x.show = true;
            }
        });

        $scope.$on('stopSliding', function () {
            changeXAxis($scope.data.domains);
        });
    })();
}]);
