app.controller('settingsNavCtrl', ['$scope', '$state', '$rootScope', 'navigation', 'utilities', 'terminusHubService',
    function ($scope, $state, $rootScope, navigation, utilities, terminusHubService) {
        const SELF_CONFIG_SFDC = 'app.settings.selfConfigure.sfdc';
        const SELF_CONFIG_HUBSPOT = 'app.settings.selfConfigure.hubspot';
        const HUBSPOT_CRM_TYPE = 'HUBSPOT';
        const terminusHubNativeSettings = [
            SELF_CONFIG_HUBSPOT,
        ];
        $scope.setUrl = function (state) {
            if (!state.config.noUrl) {
                // if settings is migrated then return new route
                // or setting is native to terminus-hub
                if (state.config.callFn
                    && (
                        $scope.isMigratedSettings
                        || terminusHubNativeSettings.includes(state.config.name)
                    )) {
                    const subdomain = terminusHubNativeSettings.includes(state.config.name) ? 'data' : 'measurement';
                    return terminusHubService.getTerminusHubUrlByReportName(state.config.name, subdomain);
                } else {
                    return utilities.stateUrl(state.config.name);
                }
            }
        };

        $scope.active = function (child) {
            return $state.current.name === child.name;
        };

        $scope.hasPermission = function (statePermission, userPermissions) {
            var permissionArray = Array.isArray(statePermission) ? statePermission : [statePermission];
            for (var i = 0; i < permissionArray.length; i++) {
                if (userPermissions[permissionArray[i]]) {
                    return true;
                }
            }
            return false;
        };

        $scope.handleHubspotSfdcConfigItems = function (config) {
            return SELF_CONFIG_SFDC === config.name
                ? !$scope.hasHubspotIntegrationAccess
                : SELF_CONFIG_HUBSPOT === config.name
                    ? $scope.hasHubspotIntegrationAccess
                    : true;
        };

        $scope.hasHubspotIntegrationAccess = false;

        (function () {
            $scope.data = {
                parent: navigation.getSettingParent()
            };
            $scope.isMigratedSettings = terminusHubService.isFeatureEnabled('feature-settings-migrated');

            $scope.$on('$stateChangeSuccess', function () {
                $scope.data.parent = navigation.getSettingParent();
            });

            $scope.hasHubspotIntegrationAccess =
                $rootScope.userData.crm
                && $rootScope.userData.crm.type === HUBSPOT_CRM_TYPE
                && $rootScope.userData.crm.isAutomatedIngest
            ;
        })();
    }]);
