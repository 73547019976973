app.controller('HistoricalAverageCtrl', ['$scope', '$http', '$window', '$location', 'utilities', 'api', '$state', '$filter', '$rootScope', 'colorMap', '_', 'cohorts', function ($scope, $http, $window, $location, utilities, api, $state, $filter, $rootScope, colorMap, _, cohorts) {
    var dataCall;

    function getHistAverage() {
        if (dataCall) { dataCall.abort(); }
        $state.current.data.loading = true;
        var params = angular.copy($scope.query);
        params.startDate = moment(new Date(parseInt(params.startDate))).startOf('day')._d.getTime();
        params.endDate = moment(new Date(parseInt(params.endDate))).startOf('day')._d.getTime();
        (dataCall = api.get('hist_averages', params)).then(function (data) {
            $scope.data.hist_avgs = data.data.groups;
            if (!data.data.groups.length) {
                $state.current.data.loading = false;
                return;
            }

            $scope.data.hist_avgs.forEach(function (i) {
                i.group = i.rev_group;
                i.details_url = $scope.makeLink() + "&group=" + i.rev_group;
                i.avg_lto_velocity = i.pipeline.velocity;
                i.avg_otd_velocity = i.revenue.velocity;
                i.avg_ltd_velocity = i.revenue.velocity > 0 ? i.pipeline.velocity + i.revenue.velocity : 0;
                i.avg_oppty_value = i.pipeline.avg_oppty_value;
                i.avg_deal_value = i.revenue.avg_oppty_value;
                i.avg_lto = i.pipeline.conv_rate / 100;
                i.avg_otd = i.revenue.otd_conv_rate / 100;
                i.avg_ltd = i.revenue.conv_rate / 100;
            });
            //using this sort b/c ngGrod does not sort on 2 columns and when opptys where the same the rows came back indeterminate
            $scope.data.hist_avgs.sort(function (a, b) {
                return a.oppty_count == b.oppty_count ? b.lead_count - a.lead_count : b.oppty_count - a.oppty_count;
            });
            $state.current.data.loading = false;
        });
        $scope.$broadcast('$$rebind::refreshData');
    }

    $scope.datesChanged = function () {
        $rootScope.$broadcast('detailChanged', "dates");
        getHistAverage();
    };

    $scope.runAllQuery = function () {
        getHistAverage();
    };

    $scope.loadSearch = function () {
        if ($scope.query.search) {
            utilities.queryString({ search: null });
            $scope.data.tableState.controller.search($scope.query.search, 'group');
        }
        $scope.$broadcast('$$rebind::refreshData');
    };

    $scope.sumTotals = function (items) {
        $scope.data.totals = utilities.sumObjects(items, function (sums, value, key) {
            var col = _.findWhere($scope.gridOptions.columnDefs, { field: key }),
                final;

            if (col && col.weight) {
                var weighted = _.compact(items.map(function (item) {
                        return item[key] ? item[col.weight] * item[key] : null;
                    })),
                    total = _.reduce(weighted, function (total, n) {
                        return total + n;
                    });

                final = total / sums[col.weight];
                if (isNaN(parseInt(final))) {
                    final = 0;
                }
                return final;
            }
        }, ['group']);
    };

    $scope.makeLink = function () {
        var filterType = $state.current.data.filter;
        if (!$scope.data.hist_avgs || $scope.hist_averages && !$scope.hist_averages.length) {
            return '';
        }

        var params = { cohort: $scope.query.cohort, groupType: $scope.query.groupType };
        if ($scope.query.cohort == "time") {
            params['startDate'] = new Date($scope.query.startDate).getTime();
            params['endDate'] = new Date($scope.query.endDate).getTime();
        }

        params = $scope.addFilterParams(params, filterType);

        var state = 'app.analyze.campaigns.historicalAveragesDetails';
        return $scope.shref(state, params);
    };

    (function init() {
        $scope.query = _.defaults($state.params, {
            cohort: 'quarter2Date',
            groupType: $state.current.data.by,
            startDate: moment(new Date()).subtract(1, 'week')._d.getTime(),
            endDate: new Date().getTime()
        });
        $scope.data = {
            cohorts: utilities.formatCohorts(cohorts, ['all', 'custom', 'year', 'quarter', 'ago', 'toDate', 'lastFull']),
            pipe: false
        };
        $scope.colorMap = colorMap;

        // Initialization code begins here

        // $scope.summary = {};
        // $scope.summary = {headers:["", "Leads", "Opp.", "Deals", "Avg. Opp. Value", "Avg. Deal Value", "Avg. LTO", "Avg. OTD", "Avg. LTD", "Avg. LTO Days", "Avg. OTD Days", "Avg. LTD Days", "Avg. Campaign Touches", "Avg. Campaign Responses", "CPL"]};

        // $scope.getExportDataHA = function () {
        //     $rootScope.$broadcast('exportData', "csv", {info: "from ui"});
        //     return $scope.hist_avgs.map(function(d) {
        //         return {
        //             group: d.group, leads: d.lead_count, opptys: d.oppty_count, deals: d.deal_count, oppty_val: d.avg_oppty_value,
        //             deal_val: d.avg_deal_value, lto_conv: d.avg_lto, otod_conv: d.avg_otd, ltd_conv: d.avg_ltd,
        //             lto_velocity: d.avg_lto_velocity, otd_velocity: d.avg_otd_velocity, ltd_velocity: d.avg_ltd_velocity,
        //             avg_campaign_touches: d.avg_campaign_touches, avg_campaign_responses: d.avg_campaign_responses, cpl: d.cpl
        //         };
        //     });
        // };

        var type = $state.current.data.by;
        $scope.data.tableHeader = type === "campaign" ? "Campaign Type" : "Lead Group";

        // $scope.getExportHeaderHA = function () {
        //     return [$scope.data.tableHeader].concat($scope.summary.headers.slice(1, $scope.summary.headers.length))
        // };

        var tooltips = {
            leads: 'The total number of leads created during the specified time period for each ' + type + ' group.',
            opptys: 'The number of unique opportunities associated to the leads created during the specified time period for each  ' + type + ' group.',
            deals: 'The number of unique deals associated to leads created during the specified time period for each ' + type + ' group.',
            oppty_val: 'The average value for opportunities associated to leads created during the specified time period for each ' + type + ' group.',
            deal_val: 'The average value for deals associated to leads created during the specified time period for each ' + type + ' group.',
            lto_conv: 'The Lead-To-Opportunity conversion rate for leads created during the specified time period for each ' + type + ' group.',
            otod_conv: 'The Opportunity-To-Deal conversion rate for closed/won opportunities associated to leads created during the specified time period for each ' + type + ' group.',
            ltd_conv: 'The Lead-To-Deal conversion rate for leads created during the specified time period that ended up closed/won for each ' + type + ' group.',
            lto_velocity: 'The average Lead-To-Opportunity velocity for leads created during the specified time period that are associated to opportunities for each ' + type + ' group.',
            otod_velocity: 'The average Opportunity-To-Deal velocity for closed/won opportunities associated to leads created during the specified time period for each ' + type + ' group.',
            ltd_velocity: 'The average Lead-To-Deal velocity for leads created during the specified time period that ended up closed/won for each ' + type + ' group.',
            cpl: 'Your cost-per-lead for each lead type. This column may require additional configuration for each ' + type + ' group.'
        };

        // $scope.data.startDatepicker = {};
        // $scope.data.endDatepicker = {};
        $scope.gridOptions = {
            data: 'hist_avgs',
            // eslint-disable-next-line no-undef
            plugins: [new ngGridFlexibleHeightPlugin()],
            showFooter: true,
            multiSelect: false,
            enableColumnResize: true,
            enableColumnReordering: true,
            enableRowSelection: true,
            enableHighlighting: true,
            footerRowHeight: 46,
            columnDefs: [
                { field: 'group', displayName: $scope.data.tableHeader, width: 120, visible: 'always', descendingFirst: false, link: function (row) { return row.details_url; } },
                { field: 'lead_count', displayName: 'Leads', width: 75, tooltip: tooltips.leads, descendingFirst: true, visible: true },
                { field: 'oppty_count', displayName: 'Opp.', cellFilter: 'haRounder:2', width: 75, tooltip: tooltips.opptys, descendingFirst: true, visible: true },
                { field: 'deal_count', displayName: 'Deals', cellFilter: 'haRounder:2', width: 75, tooltip: tooltips.deals, descendingFirst: true, visible: true },
                { field: 'avg_oppty_value', weight: 'oppty_count', displayName: 'Avg. Opp. Value', cellFilter: 'roundCurrency', width: 145, tooltip: tooltips.oppty_val, descendingFirst: true, visible: true },
                { field: 'avg_deal_value', weight: 'deal_count', displayName: 'Avg. Deal Value', cellFilter: 'roundCurrency', width: 135, tooltip: tooltips.deal_val, descendingFirst: true, visible: true },
                { field: 'avg_lto', weight: 'lead_count', displayName: 'Avg. LTO', cellFilter: 'percentage:2', width: 95, tooltip: tooltips.lto_conv, descendingFirst: true, visible: true },
                { field: 'avg_otd', weight: 'oppty_count', displayName: 'Avg. OTD', cellFilter: 'percentage:2', width: 95, tooltip: tooltips.otod_conv, descendingFirst: true, visible: true },
                { field: 'avg_ltd', weight: 'lead_count', displayName: 'Avg. LTD', cellFilter: 'percentage:2', width: 90, tooltip: tooltips.ltd_conv, descendingFirst: true, visible: true },
                { field: 'avg_lto_velocity', weight: 'lead_count', displayName: 'Avg. LTO Days', cellFilter: 'number:0', width: 125, tooltip: tooltips.lto_velocity, descendingFirst: true, visible: true },
                { field: 'avg_otd_velocity', weight: 'oppty_count', displayName: 'Avg. OTD Days', cellFilter: 'number:0', width: 125, tooltip: tooltips.otod_velocity, descendingFirst: true, visible: true },
                { field: 'avg_ltd_velocity', weight: 'lead_count', displayName: 'Avg. LTD Days', cellFilter: 'number:0', width: 125, tooltip: tooltips.ltd_velocity, descendingFirst: true, visible: true },
                { field: 'cpl', displayName: 'CPL', weight: 'lead_count', cellFilter: 'roundCurrency', width: 80, tooltip: tooltips.cpl, descendingFirst: true, visible: false }
            ]
        };

        $scope.gridOptions.columnDefs.forEach(function (v) {
            v.width += 10;
        });

        $scope.$on('filtersChanged', function () {
            getHistAverage();
        });

        $scope.$on('$destroy', function () {
            if (dataCall) { dataCall.abort(); }
        });

        // getCohortOptions();
        getHistAverage();
    })();

}]);

app.controller('HistoricalAverageDetailsCtrl', ['$scope', '$http', '$window', '$location', '$stateParams', '$state', 'utilities', 'api', '_', function ($scope, $http, $window, $location, $stateParams, $state, utilities, api, _) {
    /*=============================================
    =  TO DO: this needs to be rewritten... lets use the params through the router and use $scope.query
    =============================================*/
    $scope.data = {};
    $scope.query = angular.copy($state.params);

    var historicalAverageDetailsCall;

    var sfdcURL = $scope.sfdcURL;

    function makeURLCallback(id) {
        return "/" + id;
    }

    // eslint-disable-next-line no-unused-vars
    function getURL() {
        var url = "api/v1/hist_average_details?";
        var query = location.hash.split("?")[1];
        return url + query;
    }

    function getCsvUrl(nv, ov) {
        if (!angular.equals(nv, ov)) {
            $scope.data.csvUrl = 'export/historicalAverageDetails?' + _.queryString(nv);
        }
    }

    $scope.pipeData = function (tableState) {
        $scope.data.pipe = !$scope.data.pipe;
        if ($scope.data.pipe) {
            if (historicalAverageDetailsCall) { historicalAverageDetailsCall.abort(); }

            $scope.loadingTable = true;

            var params = angular.copy($scope.query),
                pagination = tableState.pagination,
                start = pagination.start || 0,     // This is NOT the page number, but the index of item in the list that you want to use to display the table.
                number = pagination.number || 10;  // Number of entries showed per page.

            if (!tableState.sort.predicate) {
                tableState.sort.predicate = "campaign_group";
            }
            var field = tableState.sort.predicate;

            params.pg = Math.ceil(start / number) || 1;
            params.ps = number;
            params.tbl = field.substr(0, field.indexOf("_"));
            params.fld = field.replace(params.tbl + '_', '');
            params.dir = !tableState.sort.reverse ? 'a' : 'd';
            (historicalAverageDetailsCall = api.get('hist_average_details', params, ['pg', 'ps', 'tbl', 'fld', 'dir'])).then(function (data) {
                $scope.loadingTable = false;
                $scope.data = data.data.details;
                $scope.statuses.data = data.status;
                tableState.pagination.numberOfPages = Math.ceil(data.data.total_rows / number);
                tableState.pagination.totalItemCount = data.data.total_rows;
            });
        }
    };

    // paging code
    $scope.setPagingData = function (data, page, pageSize) {
        if (!$scope.data) return;
        $scope.pagedData = data.slice((page - 1) * pageSize, page * pageSize);
        $scope.pagedData.forEach(function (d) {
            d.lead_url = sfdcURL + makeURLCallback(d.lead_id);
            d.oppty_url = sfdcURL + makeURLCallback(d.oppty_id);
        });
        $scope.totalServerItems = data.length;
        if (!$scope.$$phase) {
            $scope.$apply();
        }
    };

    $scope.$watch('pagingOptions', function (newVal, oldVal) {
        if (newVal !== oldVal && newVal.currentPage !== oldVal.currentPage) {
            $scope.setPagingData($scope.data, $scope.pagingOptions.currentPage, $scope.pagingOptions.pageSize);
        }
    }, true);


    $scope.pagingOptions = {
        pageSize: 1000,
        currentPage: 1
    };

    $scope.sortInfo = { fields: ['lead_id'], directions: ['asc'] };
    // sort over all data
    function sortData(field, direction) {
        if (!$scope.data) return;
        var directionAsInt = direction == "asc" ? 1 : -1;
        var idx = [], idxNull = [];
        for (var i = 0; i < $scope.data.length; i++) {
            var attr = $scope.data[i][field];
            if (attr !== null) {
                idx.push([i, attr]);
            } else {
                idxNull.push([i, attr]);
            }
        }
        idx.sort(function (a, b) {
            if (a[1] == b[1])
                return 0;
            if (a[1] > b[1])
                return directionAsInt;
            if (a[1] < b[1])
                return -directionAsInt;
        });
        var d = idx.map(function (r) { return $scope.data[r[0]]; });
        d = d.concat(idxNull.map(function (r) { return $scope.data[r[0]]; }));
        $scope.data = d;
    }

    // eslint-disable-next-line no-unused-vars
    $scope.$watch('sortInfo', function (newVal, oldVal) {
        sortData(newVal.fields[0], newVal.directions[0]);
        $scope.pagingOptions.currentPage = 1;
        $scope.setPagingData($scope.data, $scope.pagingOptions.currentPage, $scope.pagingOptions.pageSize);
    }, true);

    getCsvUrl($state.params, null);
    $scope.$watchCollection('$state.params', getCsvUrl);

    $scope.statuses = {
        data: null
    };

    $scope.totalServerItems = 0;
    $scope.gridOptions = {
        columnDefs: [
            // eslint-disable-next-line no-undef
            { field: 'lead_name', displayName: 'Person', cellTemplate: ngGridCellWithLink('lead_url') },
            { field: 'lead_created_date', displayName: 'Lead Created Date', cellFilter: 'date' },
            // eslint-disable-next-line no-undef
            { field: 'oppty_name', displayName: 'Opportunity', cellTemplate: ngGridCellWithLink('oppty_url') },
            { field: 'oppty_amount', displayName: 'Amount', cellFilter: 'currency' },
            { field: 'oppty_created_date', displayName: 'Oppty Created Date', cellFilter: 'date' },
            { field: 'oppty_close_date', displayName: 'Oppty Close Date', cellFilter: 'date' }
        ]
    };

    if (!$scope.userData.hiddenFeatures.features.waterfallStages) {
        $scope.gridOptions.columnDefs.push({ field: 'lead_stage', displayName: 'Lead Stage', visible: true });
        $scope.gridOptions.columnDefs.push({ field: 'oppty_stage', displayName: 'Opportunity Stage', visible: true });
    }

    if ($stateParams.groupType == 'lead') {
        $scope.gridOptions.columnDefs.unshift({ field: $stateParams.groupType + '_group', displayName: 'Lead Group', width: 120 });
    }
    else {
        $scope.gridOptions.columnDefs.unshift({ field: $stateParams.groupType + '_group', displayName: 'Campaign Type', width: 120 });
    }

    $scope.$on('$destroy', function () {
        if (historicalAverageDetailsCall) { historicalAverageDetailsCall.abort(); }
    });

}]);
