app.controller('validityCtrl', ['$http', 'api', '$scope', '$rootScope', '$state', '$q', 'noty', '$b', '_', function ($http, api, $scope, $rootScope, $state, $q, noty, $b, _) {

    //    var apiUrl = $state.current.data.pageType == 'web-activity' ? 'channel_performance' : 'campaign_performance';

    function getSchemaConfig() {
        $state.current.data.loading = true;
        api.get('sfdc/load_schema_config', {}, false, true).then(function (data) {
            $scope.schema = data.data[$state.current.data.pageType];
            getValidityRules();
        });
    }

    function getValidityRules() {
        $state.current.data.loading = true;
        api.get('readConfig/fetch_validity_mappings', { object: $state.current.data.pageType }, false, true).then(function (data) {
            $scope.validity_tree = data.data.formatted;
            $scope.validity = data.data.flat;
            $scope.isCustom = data.data.custom;
            var id_count = 1;
            for (var count = 0; count < $scope.validity.length; count++) {
                if ($scope.validity[count].field == 'item') {
                    $scope.validity[count].display_id = id_count;
                    id_count++;
                    $scope.validity[count].operators = ["is",
                        "is not",
                        "starts with",
                        "ends with",
                        "contains"];
                }
            }
            if ($scope.validity_tree != null) {
                $scope.validity_expr = parseValidExpr($scope.validity_tree, '');
            }
            getTypes();
            api.get('readConfig/fetch_last_validity', { object: $state.current.data.pageType }, false, true).then(function (data) {
                $scope.last_update = data.data;
                $state.current.data.loading = false;
            });
        });
    }

    $scope.ago = function () {
        if ($scope.last_update) {
            return moment(moment.utc($scope.last_update).local().format()).fromNow();
        }
    };

    // eslint-disable-next-line no-unused-vars
    function parseValidExpr(expression, expr_string) {
        if (expression.field == 'expression') {
            return '('.concat(parseValidExpr(expression.inner_operator), ' ', expression.value, ' ', parseValidExpr(expression.outer_operator), ')');
        }
        else if (expression.field == 'expression:root') {
            return ''.concat(parseValidExpr(expression.inner_operator), ' ', expression.value, ' ', parseValidExpr(expression.outer_operator));
        }
        else {
            for (var count = 0; count < $scope.validity.length; count++) {
                if ($scope.validity[count].id == expression.id) {
                    return $scope.validity[count].display_id;
                }
            }
            return expression.id;
        }
    }

    $scope.deleteRule = function (display_id) {
        var rule = _.find($scope.validity, { display_id: display_id });
        $scope.validity.splice($scope.validity.indexOf(rule), 1);
    };

    $scope.saveConfigs = function () {
        $scope.parseTextExpr().then(function () {
            $scope.page.saving_configs = true;
            // eslint-disable-next-line no-unused-vars
            api.set('config/save_validity_mappings', { flat: $scope.validity, tree: $scope.validity_tree, object: $state.current.data.pageType }, true).then(function (data) {
                noty.growl('Successfully saved mappings', 'success');
                $scope.page.saving_configs = false;
            // eslint-disable-next-line no-unused-vars
            }, function (data) {
                $scope.page.saving_configs = false;
                noty.growl('There was an error saving your validity mappings. Please contact support.', 'error', true);
            });
        });
    };

    function getTypes() {
        for (var count = 0; count < $scope.validity.length; count++) {
            if ($scope.validity[count].field == 'item') {
                for (var schemaCount = 0; schemaCount < $scope.schema.length; schemaCount++) {
                    if ($scope.schema[schemaCount].label == $scope.validity[count].inner_operator.label) {
                        $scope.validity[count].inner_operator = $scope.schema[schemaCount];
                        $scope.getOp($scope.validity[count]);
                    }
                }
            }
        }
    }

    $scope.addRule = function (rule) {
        var new_id = 0;
        angular.forEach($scope.validity, function (rule) {
            if (rule.display_id > new_id) {
                new_id = rule.display_id;
            }
        });
        new_id++;

        $scope.validity.push({ field: 'item', inner_operator: $scope.schema[0], outer_operator: '', value: "is", display_id: new_id, operators: ["is", "is not", "starts with", "ends with", "contains"] });
        $scope.toggleEditable(rule, new_id);
    };

    function findRuleWithDID(id) {
        for (var count = 0; count < $scope.validity.length; count++) {
            if ($scope.validity[count].field.includes('item') && $scope.validity[count].display_id == id) {
                return $scope.validity[count];
            }
        }
        return null;
    }

    $scope.parseTextExpr = function () {
        var defer = $q.defer();
        $scope.validity_tree = recParse($scope.validity_expr);
        if ($scope.validity_tree != null && !($scope.validity_tree.field.includes('root'))) {
            $scope.validity_tree.field = $scope.validity_tree.field.concat(':root');
        }
        if ($scope.validity_tree == null) {
            $scope.validity_tree = 'Empty';
        }
        defer.resolve();
        return defer.promise;
    };

    $scope.editExpression = function () {
        if ($scope.validity_expr) { $scope.old_expression = angular.copy($scope.validity_expr); }
        $scope.edit_expr = true;
    };

    function recParse(expr_string) {
        var first_expr,
            first_expr_obj,
            operator,
            last_expr,
            last_expr_obj,
            first_expr_type,
            last_expr_type,
            rec_expr,
            split_str,
            increment;

        //        var defer = $q.defer();


        if (expr_string == null) {
            $scope.edit_expr = false;
            return null;
        }

        //        try {
        if (!(String(expr_string).split(' ').length > 1)) {
            $scope.edit_expr = false;
            return findRuleWithDID(parseInt(String(expr_string).split(' ')[0]));
        }

        //Find the first operand
        //Strip preceding whitespace
        while (expr_string.charAt(0) == ' ') {
            expr_string = expr_string.substring(1, expr_string.length);
        }
        //If the first element is a rule #, first expression is the rule
        if (expr_string.charAt(0) <= '9' && expr_string.charAt(0) >= '0') {
            split_str = expr_string.split(' ', 3);
            first_expr = split_str[0];
            first_expr_type = 1;
            expr_string = expr_string.substring(first_expr.length, expr_string.length);
        }
        //If first element is an expression, recurse
        else if (expr_string.charAt(0) == '(') {
            increment = 1;
            first_expr = '(';
            for (var count = 1; count < expr_string.length && increment > 0; count++) {
                if (expr_string.charAt(count) == ')') {
                    increment--;
                }
                else if (expr_string.charAt(count) == '(') {
                    increment++;
                }
                first_expr = first_expr.concat(expr_string.charAt(count));
            }
            first_expr = first_expr.substring(1, first_expr.length - 1);
            expr_string = expr_string.substring(first_expr.length + 2, expr_string.length);
            first_expr_type = 2;
        }


        //Find the operator
        //Strip preceding whitespace
        while (expr_string.charAt(0) == ' ') {
            expr_string = expr_string.substring(1, expr_string.length);
        }

        //Find the next space separated item
        operator = expr_string.split(' ')[0];
        if (operator != 'AND' && operator != 'OR') {
            throw 'Invalid Operator';
        }

        expr_string = expr_string.substring(operator.length, expr_string.length);

        //Find the remaining expression
        //Strip preceding whitespace
        while (expr_string.charAt(0) == ' ') {
            expr_string = expr_string.substring(1, expr_string.length);
        }

        last_expr = expr_string;

        //If the last element is a rule #, first expression is the rule
        if (expr_string.charAt(0) <= '9' && expr_string.charAt(0) >= '0') {
            split_str = expr_string.split(' ');
            last_expr = split_str[0];
            last_expr_type = 1;
            expr_string = expr_string.substring(last_expr.length, expr_string.length);
        }
        else {
            if (expr_string.charAt(0) == '(') {
                increment = 1;
                last_expr = '(';
                // eslint-disable-next-line no-redeclare
                for (var count = 1; count < expr_string.length && increment > 0; count++) {
                    if (expr_string.charAt(count) == ')') {
                        increment--;
                    }
                    else if (expr_string.charAt(count) == '(') {
                        increment++;
                    }
                    last_expr = last_expr.concat(expr_string.charAt(count));
                }
                last_expr = last_expr.substring(1, last_expr.length - 1);
                expr_string = expr_string.substring(last_expr.length + 2, expr_string.length);
            }
            last_expr_type = 2;
        }


        if (expr_string.length > 1) {
            rec_expr = '';
            if (first_expr_type == 2) {
                rec_expr = '('.concat('(', first_expr, ') ', operator, ' ');
            }
            else {
                rec_expr = '('.concat(first_expr, ' ', operator, ' ');
            }
            if (last_expr_type == 2) {
                rec_expr = rec_expr.concat('(', last_expr, '))', expr_string);
            }
            else {
                rec_expr = rec_expr.concat(last_expr, ')', expr_string);
            }
            return recParse(rec_expr);
        }

        if (first_expr_type == 1) {
            first_expr_obj = findRuleWithDID(parseInt(first_expr));
        }
        else if (first_expr_type == 2) {
            first_expr_obj = recParse(first_expr);
        }

        if (last_expr_type == 1) {
            last_expr_obj = findRuleWithDID(parseInt(last_expr));
        }
        else if (last_expr_type == 2) {
            last_expr_obj = recParse(last_expr);
        }
        $scope.edit_expr = false;
        return { inner_operator: first_expr_obj, outer_operator: last_expr_obj, value: operator, field: 'expression' };
        //            }
        //        catch (err) {
        //            $scope.edit_expr = true;
        //            $scope.isValidExpression = false;
        //            $b.alert('Please enter a valid expression.  Expressions must only contain existing rules, AND/OR, and parentheses.  Your expression has not been saved');
        //
        //            return $scope.validity_tree;
        //        }

    }

    $scope.getOp = function (rule, addingNew) {
        if (rule.inner_operator.type != null) {
            if (rule.inner_operator.type.includes('CHAR') || rule.inner_operator.type.includes('TEXT')) {
                rule.operators = ["is",
                    "is not",
                    "starts with",
                    "does not start with",
                    "ends with",
                    "does not end with",
                    "contains",
                    "does not contain"];
            }
            else if (rule.inner_operator.type == 'DOUBLE' || rule.inner_operator.type == 'INT') {
                rule.operators = ["=",
                    "!=",
                    "<",
                    ">",
                    "<=",
                    ">="];
            }
            else if (rule.inner_operator.type == 'DATETIME') {
                rule.operators = ["before",
                    "after",
                    "is on"];
            }
        }
        if (addingNew) { rule.value = rule.operators[0]; }
    };

    $scope.toggleEditable = function (rule, display_id) {
        if (rule) { $scope.old_rule = angular.copy(rule); }
        else { $scope.old_rule = {}; }

        angular.forEach($scope.validity, function (r) {
            r.editable = (r.display_id == display_id);
        });
    };

    $scope.doneEditing = function (rule) {
        if (rule && rule.editable) { rule.editable = !rule.editable; }
    };

    $scope.cancelEditing = function (rule) {
        if (rule && $scope.old_rule) {
            angular.merge(rule, $scope.old_rule);
            rule.editable = false;
        }
        else {
            $scope.validity_expr = $scope.old_expression;
            $scope.edit_expr = false;
        }
    };

    (function init() {
        $scope.isValidExpression = true;
        $scope.edit_expr = false;
        $scope.page = {
            saving_configs: false
        };
        getSchemaConfig();
        $scope.type = $state.current.data.pageType;
    })();
}]);
