import {IInternalConfigService, OrganizationElements, OrgConfigElement} from './interfaces';

export class OrganizationsSrvc {

    static $inject: Array<string> = ['internalConfigSrvc'];

    constructor(
        private internalConfigSrvc: IInternalConfigService
    ) {
    }

    getOrgs(): Promise<OrganizationElements> {
        return this.internalConfigSrvc.getInternalConfig().then(
            (data) => {
                const active: OrgConfigElement[] = [];
                const configs: OrganizationElements = new OrganizationElements();

                data.data.orgs.forEach(function (item) {
                    if (item.active) {
                        active.push(item);
                    }
                });
                const initElement: OrgConfigElement = new OrgConfigElement(0, '0', '--Select an org--');
                configs.orgsActive = [initElement].concat(active);
                configs.orgsAll = [initElement].concat(data.orgs);
                return configs;
            });
    }

}
