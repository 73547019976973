/**
 * Controller for the json viewer directive.
 */
(function () {
    'use strict';

    angular
        .module('sfdc')
        .directive('jsonViewer', function () {
            return {
                template: require('./json-viewer.html'),
                controller: jsonViewerCtrl,
                controllerAs: "$ctrl",
                bindToController: true,
                scope: {
                    data: '='
                }
            };
        });

    jsonViewerCtrl.$inject = [];

    /**
     * Controller for the directive.
     */
    function jsonViewerCtrl() { }
})();
