/**
 * Posts data to the loginAs endpoint.
 */
(function () {
    'use strict';

    app.factory('switchUserSrvc', switchUserSrvc);

    switchUserSrvc.$inject = ['$http', '$q', 'authSrvc', 'SwitchUserConstants', 'environmentSrvc'];

    /**
     * Service to post data about the user to impersonate.
     * @param $http The http request
     * @param $q The $q service
     * @param authSrvc The auth service to use for storing new tokens.
     * @param SwitchUserConstants Constant to use in the service
     * @return {switchUserSrvc}
     */
    function switchUserSrvc($http, $q, authSrvc, SwitchUserConstants, environmentSrvc) {
        var service = this;

        /**
         * Post the identity of the user to impersonate.
         * @param params The parameters to post
         * @return {Promise<*>} A promise which will resolve/reject based on the
         *    request status.
         */
        service.loginAs = function (params) {
            return $http.post(SwitchUserConstants.API.LOGIN_AS,
                JSON.stringify(params),
                {withCredentials: false});
        };

        /**
         * Stores the JWT associated with the user to impersonate and reloads
         * the app.
         *
         * @param loginAsResponseData The response received from a call to `loginAs`.
         * @param goNewApp Specifies is the redirect destination. "true" means the new MS.
         */
        service.reloadAsUser = function (loginAsResponseData, goNewApp = false) {
            authSrvc.writeJwtToCookie(loginAsResponseData.token);
            if (goNewApp) {
                environmentSrvc.getVariable('TERMINUS_HUB_URL').then(url => {
                    authSrvc.redirect(url);
                });

                return;
            }
            authSrvc.redirect('/');
        };

        return service;
    }
})();
