app.controller('campaignCtrl', ['$scope', '$state', 'api', '$rootScope', '$filter', '$timeout', 'utilities', '$q', 'noty', '_', 'campaign', 'filters', 'trend', function ($scope, $state, api, $rootScope, $filter, $timeout, utilities, $q, noty, _, campaign, filters, trend) {
    var isOrphanLeadCall;
    $scope.updateNotes = function (notes, id) {
        var params = {
            objId: id,
            info: notes
        };

        var apiUrl = $state.current.data.pageType === 'channel-asset-specific' ? 'update_note_channel' : 'update_note';
        // eslint-disable-next-line no-unused-vars
        api.set(apiUrl, params).success(function (data) {
            $rootScope.$broadcast('trackAction', "notes");
            noty.growl('note updated...', 'success');
        });
    };

    $scope.hasS = function (s) {
        if (s) {
            return s.toLowerCase().slice(-1) === 's';
        }
    };

    $scope.calc = {
        modelLabel: {
            sourced: "Sourced",
            last: "Created",
            even: "Attributed",
            custom: "Attributed"
        },
        total: function (arr, prop) {
            var m = _.compact(arr.map(function (row) {
                    return row[prop];
                })),
                t = m.length > 1 ? m.reduce(function (a, b) {
                    return a + b;
                }) : m[0] || 0;

            return Math.round(t);
        },
        general: function (data, platform) {
            var o = {};
            if ($state.current.data.pageType === 'campaign-spec' || $state.current.data.pageType === 'channel-asset-specific') {
                o = {
                    revenue: {
                        label: 'REVENUE',
                        filter: 'nrFormat:true:true',
                        value: data.deals.length ? $scope.calc.total(data.deals, (platform !== 'full' ? 'total_amount' : 'amount')) : 0,
                        listener: true,
                        show: true
                    },
                    pipeline: {
                        label: 'PIPELINE',
                        filter: 'nrFormat:true:true',
                        value: data.opptys.length ? $scope.calc.total(data.opptys, (platform !== 'full' ? 'total_amount' : 'amount_pipeline')) : 0,
                        listener: true,
                        show: true
                    },
                    roi: {
                        label: 'RETURN ON INVESTMENT',
                        filter: 'percentage:0',
                        listener: true,
                        show: false
                    }
                };
                var c = data.campaign.cost;
                o.roi.value = c ? (((o.revenue.value * data.annualizationFactor) - c) / c) : null;
                if (o.roi.value < 0) {
                    o.roi.value = 0;
                }
            } else {
                o = {
                    opptyValue: {
                        label: 'OPP. VALUE',
                        filter: 'nrFormat:true:true',
                        value: data.oppty && data.oppty.amount ? data.oppty.amount : 0,
                        listener: true,
                        show: true
                    },
                    currentStage: {
                        label: 'STAGE',
                        // filter : 'nrFormat:true:true',
                        value: data.oppty.waterfall_stage ? data.oppty.waterfall_stage : 'N/A',
                        listener: true,
                        show: true
                    },

                };
            }
            data.general = o;
        },
        reformat: function (data) {
            data.comparisonMetrics = [
                {
                    label: 'Average Opportunity Size',
                    filter: 'bfCurrency',
                    value: {
                        campaign: data.general.pipeline.value / data.opptys.length,
                        group: data.campaignGroup.total_pipeline / data.campaignGroup.oppty_count,
                        health: $scope.calc.health([data.general.pipeline.value / data.opptys.length, data.campaignGroup.total_pipeline / data.campaignGroup.oppty_count], '>')
                    }
                },
                {
                    label: 'Average Deal Size',
                    filter: 'bfCurrency',
                    value: {
                        campaign: data.general.revenue.value / data.deals.length,
                        group: data.campaignGroup.total_revenue / data.campaignGroup.deal_count,
                        // group : data.campaignGroup.avg_deal_value,
                        health: $scope.calc.health([data.general.revenue.value / data.deals.length, data.campaignGroup.total_revenue / data.campaignGroup.deal_count], '>')
                    }
                }
            ];
            data.costMetrics = [
                {
                    label: 'Return on Investment',
                    filter: 'percentage:0',
                    listener: true,
                    value: {
                        campaign: data.general.roi.value,
                        group: ((data.campaignGroup.total_revenue - data.campaignGroup.total_cost) / data.campaignGroup.total_cost) * 100,
                        health: $scope.calc.health([data.general.roi.value, ((data.campaignGroup.total_revenue - data.campaignGroup.total_cost) / data.campaignGroup.total_cost) * 100], '>')
                    }
                },
                {
                    label: 'Cost per Lead',
                    filter: 'bfCurrency',
                    listener: true,
                    value: {
                        campaign: data.campaign.cost && data.leads.length ? data.campaign.cost / data.leads.length : null,
                        group: data.campaignGroup.total_cost / data.campaignGroup.lead_count,
                        health: data.campaign.cost && data.leads.length ? $scope.calc.health([data.campaign.cost / data.leads.length, data.campaignGroup.total_cost / data.campaignGroup.lead_count], '<') : null
                    }
                },
                {
                    label: 'Cost per Opportunity',
                    filter: 'bfCurrency',
                    listener: true,
                    value: {
                        campaign: data.campaign.cost && data.opptys.length ? data.campaign.cost / data.opptys.length : null,
                        group: data.campaignGroup.total_cost / data.campaignGroup.oppty_count,
                        health: data.campaign.cost && data.opptys.length ? $scope.calc.health([data.campaign.cost / data.opptys.length, data.campaignGroup.total_cost / data.campaignGroup.oppty_count], '<') : null
                    }
                },
                {
                    label: 'Cost per Deal',
                    filter: 'bfCurrency',
                    listener: true,
                    value: {
                        campaign: data.campaign.cost && data.deals.length ? data.campaign.cost / data.deals.length : null,
                        group: data.campaignGroup.total_cost / data.campaignGroup.deal_count,
                        health: data.campaign.cost && data.deals.length ? $scope.calc.health([data.campaign.cost / data.deals.length, data.campaignGroup.total_cost / data.campaignGroup.deal_count], '<') : null
                    }
                }
            ];
            data.conversionRates = [
                {
                    label: 'Lead to Opportunity',
                    filter: 'percentage:0',
                    value: {
                        // campaign : data.campaign.avg_lto,
                        // group : data.campaignGroup.avg_lto,
                        campaign: data.opptys.length / data.leads.length,
                        group: data.campaignGroup.oppty_count / data.campaignGroup.lead_count,
                        health: $scope.calc.health([data.opptys.length / data.leads.length, data.campaignGroup.oppty_count / data.campaignGroup.lead_count], '>')
                    }
                },
                {
                    label: 'Opportunity to Deal',
                    filter: 'percentage:0',
                    value: {
                        // campaign : data.campaign.avg_otd,
                        // group : data.campaignGroup.avg_otd,
                        campaign: data.deals.length / data.opptys.length,
                        group: data.campaignGroup.deal_count / data.campaignGroup.oppty_count,
                        health: $scope.calc.health([data.deals.length / data.opptys.length, data.campaignGroup.deal_count / data.campaignGroup.oppty_count], '>')
                    }
                },
                {
                    label: 'Lead to Deal',
                    filter: 'percentage:0',
                    value: {
                        // campaign : data.campaign.avg_ltd,
                        // group : data.campaignGroup.avg_ltd,
                        campaign: data.deals.length / data.leads.length,
                        group: data.campaignGroup.deal_count / data.campaignGroup.lead_count,
                        health: $scope.calc.health([data.deals.length / data.leads.length, data.campaignGroup.deal_count / data.campaignGroup.lead_count], '>')
                    }
                }
            ];
        },
        health: utilities.health,
        subset: function (data, page, limit) {
            var f = $filter('filter')(data, page.search);

            if (data.length <= limit) {
                return f;
            } else {
                page.total = f.length;
                return f.slice((page.current - 1) * page.limit, (page.current * page.limit));
            }
        },
        pagination: function (d, data, l) {
            function m(set) {
                return {
                    current: 1,
                    total: data[set].length,
                    limit: l,
                    max: 10,
                    boundry: true
                };
            }
            d.pagination = {
                leads: {},
                opptys: {},
                deals: {}
            };

            if (data.leads.length > l) {
                d.pagination.leads = m('leads');
            }
            if (data.opptys.length > l) {
                d.pagination.opptys = m('opptys');
            }
            if (data.deals.length > l) {
                d.pagination.deals = m('deals');
            }
        },
        // eslint-disable-next-line no-unused-vars
        orphans: _.debounce(function (arr, i) {
            var orphs = $filter('filter')(arr, function (row) {
                    return !row.account_name;
                }),
                flat = orphs.map(function (orph) {
                    return orph.id;
                });

            if (!flat.length) {
                return;
            }

            if (isOrphanLeadCall) { isOrphanLeadCall.abort(); }

            (isOrphanLeadCall = api.get('is_orphan_lead', { leadIds: flat }, false)).then(function (data) {
                var nos = _.difference(flat, data.data),
                    o = _.intersection(flat, data.data);

                angular.forEach(nos, function (id) {
                    var row = _.find(orphs, { id: id });
                    if (row) {
                        row.account_name = '--';
                    }
                });
                angular.forEach(o, function (id) {
                    var row = _.find(orphs, { id: id });
                    if (row) {
                        row.orphan = true;
                    }
                });
                $scope.$broadcast('$$rebind::refreshData');
            });
        }, 100),
        format: function (d, data, l, platform) {
            $scope.calc.general(data, platform);
            if (l) {
                $scope.calc.pagination(d, data, l);
            }
            return data;
        },
        when: function (t) {
            return moment().subtract(t[0], t[1])._d.getTime();
        },
        compare: function (data, cohort_array, dateField) {
            return {
                leads: $scope.calc.historicalData(data.leads, cohort_array, dateField, true),
                opptys: $scope.calc.historicalData(data.opptys, cohort_array, dateField, true),
                deals: $scope.calc.historicalData(data.deals, cohort_array, dateField, true),
                pipeline: $scope.calc.historicalData(data.opptys, cohort_array, dateField, false, 'amount_pipeline'),
                revenue: $scope.calc.historicalData(data.deals, cohort_array, dateField, false, 'amount')
            };
        },
        historicalData: trend.parseTrends
    };

    $scope.$on('$destroy', function () {
        if (isOrphanLeadCall) { isOrphanLeadCall.abort(); }
    });
}]);
