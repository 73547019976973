app.controller('CampaignMaturityCtrl', ['$scope', '$http', '$window', '$location', '$timeout', '$stateParams', 'api', 'insights', '$state', 'utilities', '$rootScope', 'cohorts', '$filter', '_', function ($scope, $http, $window, $location, $timeout, $stateParams, api, insights, $state, utilities, $rootScope, cohorts, $filter, _) {
    // $scope.query = angular.copy($state.params);
    $scope.query = _.defaults($state.params, {
        cohort: "time",
        startDate: moment(new Date()).subtract(1, 'week')._d.getTime(),
        endDate: new Date().getTime(),
        groupType: $state.current.data.by,
    });
    $scope.cohortDrop = { width: '280px', escapeMarkup: function (m) { return m; } };
    var cohortsForOrgCall;
    var campaignMaturityCall;

    var getSelectOptions = function () {
        if (cohortsForOrgCall) { cohortsForOrgCall.abort(); }

        $state.current.data.loading = true;
        (cohortsForOrgCall = api.get('cohorts_for_org')).then(function (data) {
            var cohorts = [{ key: "time", value: "Custom Range" }];
            cohorts = cohorts.concat(utilities.cohortsForUI(data.data));
            $scope.selectOptions = cohorts;
            if ($state.params.cohort === undefined) {
                $scope.query.cohort = $scope.selectOptions[2].key;
            }
            $scope.statuses.select_options = status;
            getCampaignMaturityData();
        });
    };

    var getCampaignMaturityData = function () {
        if (campaignMaturityCall) { campaignMaturityCall.abort(); }
        $state.current.data.loading = true;

        var params = angular.copy($scope.query);
        if ($scope.query.startDate && $scope.query.endDate) {
            var startDate = new Date($scope.query.startDate);
            var endDate = new Date($scope.query.endDate);
            if ($scope.query.cohort == "time") {
                params.startDate = startDate.getTime();
                params.endDate = endDate.getTime();
            } else {
                delete params.startDate;
                delete params.endDate;
            }
        }

        $scope.cohort_name = $scope.selectOptions.filter(function (o) {
            return o.key == $scope.query.cohort;
        })[0].value;

        (campaignMaturityCall = api.get('campaign_maturity', params)).then(function (data) {
            $scope.all_data = data.data;

            prepare_table();
            $scope.statuses.campaign_maturity_data = data.status;
            $state.current.data.loading = false;
        }, function (data) {
            $scope.statuses.campaign_maturity_data = data.status;
            $state.current.data.loading = false;
        });
    };

    $scope.cohortChanged = function () {
        if ($scope.query.cohort != "time") {
            $rootScope.$broadcast('detailChanged', "cohort");
            getCampaignMaturityData();
        }
    };

    $scope.sumTotals = function (items) {
        $scope.data.totals = utilities.sumObjects(items, null, ['name']);
    };

    $scope.recalculate = function () {
        $rootScope.$broadcast('detailChanged', "dates");
        getCampaignMaturityData();
    };

    $scope.exportData = function () {
        $state.current.data.loading = true;
        var d = {
            title: $scope.type,
            headers: [],
            rows: []
        };

        build($scope.gridOptions, d);
        $state.current.data.loading = false;

        function build(data, table) {
            angular.forEach($scope[data.data], function (row, i) {
                var r = [];

                // eslint-disable-next-line no-unused-vars
                angular.forEach(data.columnDefs, function (def, ii) {
                    if (i === 0) {
                        table.headers.push(def.displayName.replace(' months', ''));
                    }
                    if (!def.cellFilter) {
                        r.push(String($filter('truncate')(String(row[def.field]), 15)).replace('undefined', '--'));
                    } else {
                        var f = def.cellFilter == 'currency:"$":0' ? 'nrFormat' : def.cellFilter;

                        r.push(String($filter('truncate')((String($filter(f)(row[def.field], true)).replace('.00', '')), 15)).replace('undefined', '--'));
                    }
                });

                table.rows.push(r);
            });
        }

        return d;
    };

    $scope.changeRevenueType = function () {
        $rootScope.$broadcast('detailChanged', "revenue type");
        prepare_table();
    };

    function prepare_table() {
        var data = $scope.all_data[$scope.type];
        var campaigns = [];
        var maturity_by_campaign = {};
        // just do cumulative for now
        var current_amount = {};

        for (var i = 0; i < data.length; i++) {
            if (!maturity_by_campaign[data[i].id]) {
                campaigns.push(data[i].id);
                maturity_by_campaign[data[i].id] = {
                    total_months: data[i].total_months,
                    name: data[i].name,
                    id: data[i].id,
                    amounts: []
                };
                current_amount[data[i].id] = 0;
            }
            current_amount[data[i].id] = current_amount[data[i].id] + data[i].amount;
            maturity_by_campaign[data[i].id]["display_amount_" + data[i].maturity_months] = current_amount[data[i].id];
            maturity_by_campaign[data[i].id].amounts[data[i].maturity_months] = current_amount[data[i].id];
        }

        var max_campaign_name = null;
        var max_campaign_value = -Infinity;

        Object.keys(maturity_by_campaign).forEach(function (key) {
            var mbc = maturity_by_campaign[key];
            var lastAmt = 0;
            for (var i = 0; i < mbc.total_months; i++) {
                lastAmt = mbc.amounts[i] || lastAmt;
                mbc.amounts[i] = lastAmt;
                mbc["display_amount_" + i] = lastAmt;
                mbc["filter_on_" + i] = lastAmt;
            }
            // eslint-disable-next-line no-redeclare
            for (var i = mbc.total_months; i < 12; i++) {
                mbc["filter_on_" + i] = -1;
            }
            if (mbc.amounts[mbc.amounts.length - 1] > max_campaign_value) {
                max_campaign_value = mbc.amounts[mbc.amounts.length - 1];
                max_campaign_name = mbc.name;
            }
        });

        $scope.max_campaign_name = max_campaign_name;

        var values = Object.keys(maturity_by_campaign).map(function (key) {
            return maturity_by_campaign[key];
        });

        var chartValues = Object.keys(maturity_by_campaign).map(function (key) {
            var mbc = maturity_by_campaign[key];
            var values = [{ amount: 0, intervals: 0 }];
            var lastAmt = 0;
            for (var i = 0; i < mbc.amounts.length; i++) {
                values.push({
                    amount: mbc.amounts[i] || lastAmt,
                    intervals: i + 1
                });
                lastAmt = mbc.amounts[i] || lastAmt;
            }
            return {
                line_name: mbc.name,
                values: values
            };
        });
        $scope.maturity = values;
        $scope.maturity_chart = chartValues;
        insights.set($scope.cohort_name + $scope.type, 'Of the campaigns created during ' + $scope.cohort_name + ', ' + $scope.max_campaign_name + ' has sourced the most ' + $scope.type);
    }

    $scope.statuses = {
        selectOptions: null,
        campaign_maturity_data: null
    };

    $scope.getExportDataCM = function () {
        $rootScope.$broadcast('exportData', "csv", { info: "from ui" });
        return $scope.maturity.map(function (d) {
            return {
                name: d.name, display_amount_0: d.display_amount_0, display_amount_1: d.display_amount_1, display_amount_2: d.display_amount_2,
                display_amount_3: d.display_amount_3, display_amount_4: d.display_amount_4, display_amount_5: d.display_amount_5, display_amount_6: d.display_amount_6,
                display_amount_7: d.display_amount_7, display_amount_8: d.display_amount_8, display_amount_9: d.display_amount_9,
                display_amount_10: d.display_amount_10, display_amount_11: d.display_amount_11, display_amount_12: d.display_amount_12
            };
        });
    };

    $scope.getExportHeaderCM = function () {
        var headers = ["Name"];
        for (var i = 0; i < 12; i++) {
            headers.push("Month " + (i + 1));
        }
        return headers;
    };

    $scope.gridOptions = {
        data: 'maturity',
        // eslint-disable-next-line no-unused-vars
        beforeSelectionChange: function (rowItem, event) {
            $scope.gridOptions.selectRow(rowItem.rowIndex, true);
            $timeout(function () {
                $scope.$broadcast('toggle-line', $scope.gridOptions.$gridScope.selectedItems[0]);
            }, 100);
        },
        // eslint-disable-next-line no-undef
        plugins: [new ngGridFlexibleHeightPlugin()],
        showFooter: true,
        multiSelect: false,
        enableColumnResize: true,
        enableColumnReordering: true,
        enableRowSelection: true,
        enableHighlighting: true,
        footerRowHeight: 46,
        columnDefs: [
            { field: 'name', displayName: 'Name', width: 400, defaultSort: true, link: function (row) { return $scope.shref('app.analyze.campaigns.campaignSpecific.attribution.leads', { name: row.name, campaignId: row.id }); } }
        ]
    };

    for (var i = 0; i < 12; i++) {
        $scope.gridOptions.columnDefs.push(
            {
                field: 'display_amount_' + i, displayName: String(i + 1), width: 100, cellFilter: 'currency:$:0', index: i,
                cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{row.entity[\'display_amount_' + i + '\'] | currency:"$":0}}</span></div>'
            }
        );
    }

    $scope.$on('filtersChanged', function () {
        getCampaignMaturityData();
    });

    $scope.$on('$destroy', function () {
        if (cohortsForOrgCall) { cohortsForOrgCall.abort(); }
        if (campaignMaturityCall) { campaignMaturityCall.abort(); }
    });

    $scope.data = {
        cohorts: utilities.formatCohorts(cohorts, ['custom', 'year', 'quarter', 'ago', 'toDate', 'lastFull'])
    };

    $scope.type = 'pipeline';

    getSelectOptions();
}]);
