import * as COMPONENT_HTML from './component.html';
import './component.less';
import {IDirective, IScope, IAugmentedJQuery, IAttributes, INgModelController} from 'angular';

class BtnDropdownCtrl {
    static $inject: Array<string> = ['$scope'];

    private menu: Array<any>;

    public eval(value: string): any {
        return this.$scope.$parent.$eval(value);
    }

    public setMenu(value: string): void {
        this.menu = JSON.parse(value);
    }

    public constructor(private $scope: IScope) {
        return;
    }
}

export class BtnDropdown implements IDirective {
    public restrict = 'E';
    public controller: any = BtnDropdownCtrl;
    public controllerAs = '$ctrl';
    public bindToController = true;
    public template: any = COMPONENT_HTML;
    public scope: IScope = {
        btnText: '=',
        btnClass: '=',
        btnClick: '=',
        btnDisabled: '=',
        btnMenu: '=',
    };
    public link =  (scope: IScope, elem: IAugmentedJQuery, attributes: IAttributes,
        ctrl: INgModelController) => {

        scope.$watch('$ctrl.btnMenu', (value) => {
            if (value) {
                ctrl.setMenu(value);
            }
        });
    };

    public constructor() {
        return;
    }

    public static Factory(): IDirective {
        return new BtnDropdown();
    }
}
