/**
 * Controller for the slider directive.
 */
(function () {
    'use strict';

    angular
        .module('bombora')
        .directive('headerSlider', function () {
            return {
                template: require('./slider.html'),
                controller: sliderCtrl,
                controllerAs: "$ctrl",
                bindToController: true,
                scope: {
                    ngModel: '=',
                    ngDisabled: '=',
                    ngChange: '&'
                },
                link: sliderLink
            };
        });

    sliderCtrl.$inject = [];

    /**
     * Controller for the directive.
     */
    function sliderCtrl() {
    }

    function sliderLink(scope, element, attrs, ctrl) {
        var lnk = {};

        /**
         * Update the location and value of the slider thumb
         * @param value The value of the slider (i.e. 0-99)
         */
        function updateOutput(value) {
            var width = lnk.rangeInput.offsetWidth - lnk.thumb.offsetWidth;
            lnk.thumb.style.left = (width * value * 0.01) + "px";
            lnk.output.innerHTML = value;
        }

        activate();

        /**
         * Standard method to execute when the link starts
         */
        function activate() {
            lnk.rangeInput = element[0].querySelector('.slider');
            lnk.thumb = element[0].querySelector('.thumb-slider');
            lnk.output = element[0].querySelector('.output-slider');
            lnk.rangeInput.value = ctrl.ngModel;
            lnk.output.value = lnk.rangeInput.value;

            lnk.rangeInput.addEventListener('input', function () {
                updateOutput(this.value);
            });
            updateOutput(lnk.rangeInput.value);
        }

        scope.$watch('$ctrl.ngModel', function() {
            updateOutput(ctrl.ngModel);
        });

        /**
         * Custom watch to listen to resize
         */
        scope.$watch(
            function () {
                return lnk.rangeInput.offsetWidth;
            },
            function () {
                updateOutput(lnk.rangeInput.value);
            });
    }
})();
