app.controller('revWaterfallCtrl', ['$scope', 'Colors', '$http', '$q', 'utilities', '$filter', '$state', '$window', '$location', 'api', '$rootScope', '_', 'cohorts', 'revenueGroups', 'revenueWaterfall', '$b', '$timeout', 'userModels', function ($scope, Colors, $http, $q, utilities, $filter, $state, $window, $location, api, $rootScope, _, cohorts, revenueGroups, revenueWaterfall, $b, $timeout, userModels) {
    var dataCall;
    var initCalls;
    var maxValue;

    $scope.query = angular.copy($state.params);

    $scope.barStyles = function (stage, i) {
        if (maxValue) {
            var w = (stage.primary.total / parseInt(maxValue)) * 100;
            return { width: w + '%', backgroundColor: $scope.waterfall.c(i) };
        }
    };

    function getMaxStageValue() {
        $timeout(function () {
            if ($scope.waterfall.data) {
                var totalForStage,
                    stageTotals = [];
                $scope.waterfall.data.forEach(function (stage) {
                    totalForStage = _.clone(stage.primary.total);
                    stageTotals.push(totalForStage);
                });

                maxValue = _.max(stageTotals);
            }
        }, 0, false);
    }

    $scope.selectStage = function (stage, i) {
        $scope.waterfall.selected.color = $scope.waterfall.c(i);
        $scope.waterfall.selected.stage = stage;
        $scope.calcHeight();
    };

    $scope.removeCampaignType = function () {
        $scope.query.campaignType = null;
    };

    $scope.removeCampaignId = function () {
        $scope.query.campaignId = null;
    };

    $scope.selectedCampaign = function (name) {
        $scope.data.selectedCampaign = [];
        _.forEach(name, function (name) {
            if (name.name) {
                $scope.data.selectedCampaign.push(name.name);
            }
        });
    };

    $scope.calcHeight = function () {
        var stageFilter = $scope.query.startStageSequence ? (Number($scope.query.startStageSequence)) : 0;
        $scope.waterfall.selected.height = ($scope.waterfall.data.length - stageFilter) * 60 + 20 + 58 + 92;
    };

    $scope.runAllQuery = function () {
        calcCampId();
        get_revenue();
    };

    function calcCampId() {
        if ($scope.query.campaignType && $scope.query.campaignType.length) {
            angular.forEach($scope.query.campaignId, function (campaign, i) {
                var camp = angular.findWhere($scope.waterfall.campaigns, { id: campaign });
                if (!_.contains($scope.query.campaignType, camp.revenue_group)) {
                    $scope.query.campaignId.splice(i, 1);
                }
            });
        }
    }

    $scope.selectedCohort = function (hort) {
        if ($scope.select_options && $scope.select_options.length) {
            var c = angular.findWhere($scope.select_options, { key: hort });
            return c.value;
        }
    };

    $scope.selectedStarting = function (sequence) {
        if ($scope.waterfall.data && $scope.waterfall.data.length) {
            var c = angular.findWhere($scope.waterfall.data, { sequence: sequence });
            return c.primary.stage;
        }
    };

    $scope.stageType = function (sequence, plural) {
        if ($scope.waterfall.stages && $scope.waterfall.stages.length) {
            var c = angular.findWhere($scope.waterfall.stages, { sequence: sequence });
            if (c) {
                var stageType = c['applies_to'];
                if (plural) stageType = (stageType === "lead") ? "Leads/Contacts" : "Opportunities";
                return stageType;
            }
        }
    };

    $scope.attModelLabel = function (attModel) {
        return attModel === "sourced" ? " sourced " : attModel === "last" ? " last touched " : "";
    };

    $scope.reduceCampaigns = function (groups, campaigns) {
        return groups && groups.length ? $filter('filter')(campaigns, function (campaign) {
            return _.contains(groups, campaign.revenue_group);
        }) : campaigns;
    };

    $scope.detailLink = function (type, stage) {
        if ($scope.query.campaignId && _.isArray($scope.query.campaignId) && _.isActualObject($scope.query.campaignId[0])) {
            var campaignIds = [];
            angular.forEach($scope.query.campaignId, function (camp) {
                campaignIds.push(camp.id);
            });
            $scope.query.campaignId = campaignIds;
        }

        var params = {
            detailStageName: stage ? stage.primary.stage : $scope.waterfall.selected.stage.primary.stage,
            startStageName: $scope.selectedStarting($scope.query.startStageSequence),
            cohort: $scope.query.cohort,
            type: type,
            attModel: $scope.query.attModel,
            campaignId: $scope.query.campaignId,
            startDate: $scope.query.startDate,
            endDate: $scope.query.endDate
        };

        if ($scope.query.campaignType && (!$scope.query.campaignId || $scope.query.campaignId.length === 0)) {
            params.campaignType = $scope.query.campaignType;
        }

        return "#/discover/stage-progression/details?" + utilities.param.toQueryString(params);
    };

    $scope.searchCampaigns = revenueWaterfall.searchCampaigns;

    function calcPercent(numerator, denominator) {
        // returns 0 in case denominator is 0, arbitrary but better than ending up with NaN.
        return denominator === 0 ? 0 : (numerator || 0) / denominator;
    }

    function calcWaterfall(data) {
        $scope.waterfall.data = data.map(function (obj, index) {
            var total = (obj.remaining || 0) + (obj.wasInStage || 0);
            return {
                primary: {
                    stage: obj.stage,
                    total: total,
                    progressed: obj.progressed ? obj.progressed : 0,
                    progressionRate: (obj.progressed || 0) / total,
                    progressionVelocity: obj.velocity ? Math.round(obj.velocity) : null,
                    velocityFromTop: $scope.query.startStageSequence !== obj.sequence ? Math.round(obj.velocity_from_top) : "--"
                },
                secondary: {
                    remaining: (obj.remaining || 0),
                    disqualified: obj.disqualified ? obj.disqualified : 0,
                    progressionFromTop: $scope.query.startStageSequence !== obj.sequence ? (obj.progressed ? obj.progressed / ((data[$scope.query.startStageSequence - 1].remaining || 0) + (data[$scope.query.startStageSequence - 1].wasInStage || 0)) : null) : "--",
                    value: obj.amount_remaining ? obj.amount_remaining : 0,
                    velocityNext: obj.velocity_next ? $filter('numberEx')(obj.velocity_next, 1) : 0
                },
                sequence: obj.sequence,
                rates: [
                    { count: (obj.progressed_next || 0), percent: calcPercent(obj.progressed_next, total), title: "Progressed To Next Stage", last_show: false, key: "nextStage", tooltip: "Number of leads or opportunities that progressed to the next sequential stage in your revenue waterfall stages." },
                    { count: (obj.progressed || 0), percent: calcPercent(obj.progressed, total), title: "Progressed To Any Stage", last_show: false, key: "anyStage", tooltip: "Number of leads or opportunities that progressed to any forward stage in your revenue waterfall stages. Forward stages do not include closed lost, disqualified, hold, or nurture stages." },
                    { count: (obj.remaining || 0), percent: calcPercent(obj.remaining, total), title: "Remaining", last_show: true, key: "remaining", tooltip: "Number of leads or opportunities that are currently in this stage." },
                    { count: (obj.progressed_closed || 0), percent: calcPercent(obj.progressed_closed, total), title: "Win Rate", last_show: false, key: "winClosed", tooltip: "Number of leads or opportunities that ended up Closed Won. If a lead stage is selected, there may be a higher count than Closed Won due to multiple leads on the same Deal." },
                    { count: (obj.disqualified || 0) + (obj.nurture || 0), percent: calcPercent((obj.disqualified || 0) + (obj.nurture || 0), total), title: "Dropout Rate", last_show: true, key: "dropout", tooltip: "Number of leads or opportunities that fell out of the funnel progression: closed lost, disqualified, hold, to nurture, etc." }
                ],
                amounts: [
                    { value: (obj.amount_was_in_stage || 0) + (obj.amount_remaining || 0), title: "Total Amount", tooltip: "Total opportunity amount that entered this stage during the time cohort selected" },
                    { value: (obj.amount_remaining || 0), title: "Remaining Amount", tooltip: "Amount of opportunities still in this stage." }
                ],
                is_last: data.length === index + 1
            };
        });

        $scope.waterfall.tableHeaders.length = 0;

        //calculate table headers
        angular.forEach($scope.waterfall.data, function (v, i) {
            angular.forEach(v.primary, function (vc, k) {
                if (i === 0) {
                    $scope.waterfall.tableHeaders.push({ text: (k === 'progressionRate' ? utilities.uncamel(k) + ' (any stage)' : utilities.uncamel(k)), field: k });
                }
                if (angular.isNumber(vc))
                    $scope.waterfall.tableTotals[k] = null;
            });
        });

        //we'll have the first column in rev waterfall data table be static, so remove the first element in the tableheaders array
        //to accommodate that
        $scope.waterfall.tableHeaders.shift();

        angular.forEach($scope.waterfall.tableTotals, function (value, key) {
            $scope.waterfall.tableTotals[key] = $filter('filter')($scope.waterfall.data, function (stage) {
                return !$scope.query.startStageSequence ? stage : stage.sequence >= $scope.query.startStageSequence;
            }).reduce(function (oldValue, newValue, i, data) {
                if (key === 'progressionVelocity') {
                    return oldValue + newValue.primary[key];
                } else if (key === 'progressionRate') {
                    return (data[data.length - 1].secondary.remaining / data[0].primary.total) * 100;
                } else {
                    return null;
                }
            }, 0);
        });

        var i = !$scope.query.startStageSequence ? 0 : $scope.query.startStageSequence - 1;

        $scope.selectStage($scope.waterfall.data[i], i);
        calcWidth();
        $scope.calcHeight();
    }

    function calcWidth() {
        var longest;

        //calculate totals and define table headers
        // eslint-disable-next-line no-unused-vars
        angular.forEach($scope.waterfall.data, function (v, i) {

            angular.forEach(v.primary, function (vc, k) {
                if (k === 'stage') {
                    if (!longest || longest && vc.length > longest.length) {
                        longest = vc;
                    }
                }
            });

        });

        var w = 0.15 * $('#bottom-right-bottom').width(),
            lw = angular.stringWidth(longest) + 21;

        $scope.waterfall.labelWidth = lw >= w ? w : lw;
    }

    var get_revenue = function () {
        if (dataCall) { dataCall.abort(); }

        //to not get revenue if user chose "sourced" or "last" and there's no campaign IDs or campaign Groups provided. Prevents the user from waiting for server response to question that does not make sense,
        //instead allowing the user to immediately start choosing campaigns or groups. (when switching from "all" those choices become visible)
        if ($scope.query.attModel !== "all" && (!$scope.query.campaignId || $scope.query.campaignId.length === 0) && (!$scope.query.campaignType || $scope.query.campaignType.length === 0)) { return; }

        $state.current.data.loading = true;

        var params = angular.copy($scope.query);

        $scope.data.filteredCampaigns = [];
        if (params.campaignId && params.campaignId.length) {
            delete params.campaignType;
            if (_.isArray(params.campaignId)) {
                params.campaignId.forEach(function (camp, index) {
                    if (camp.id && camp.name) {
                        $scope.data.filteredCampaigns.push(camp.name);
                        params.campaignId[index] = camp.id;
                    }
                });
            }
        }

        if ($scope.query.attModel === 'all' && $scope.query.campaignId && $scope.query.campaignId.length) {
            $scope.query.campaignId = null;
            utilities.queryString({ attModel: $scope.query.attModel, campaignId: null });
            delete params.campaignId;
        }

        (dataCall = api.get('revenue_waterfall', params)).then(function (data) {
            $scope.statuses.data = data.status;
            calcWaterfall(data.data);
            getMaxStageValue();
            $state.current.data.loading = false;
        }, function (data) {
            $scope.statuses.data = data.status;
            $state.current.data.loading = false;
        });
    };

    $scope.$on('filtersChanged', function () {
        get_revenue();
    });

    $scope.$on('$destroy', function () {
        if (dataCall) { dataCall.abort(); }
        if (initCalls) { initCalls.abort(); }
    });

    (function init() {
        $state.current.data.loading = true;

        $scope.statuses = {
            select_options: null,
            data: null
        };

        $scope.data = {
            modelOptions: [
                { label: "All in Cohort", key: "all" }
            ].concat(_.filter(userModels, function (o) {
                return o.key !== 'even' && o.key !== 'custom';
            }))
        };

        $scope.waterfall = {
            c: Colors.scale(),
            tableHeaders: [],
            tableTotals: {},
            filter: { stage: 1 },
            selected: { stage: null },
            labelWidth: 0,
            tableData: [],
            campaigns: [],
            campaignGroups: revenueGroups
        };

        $scope.utils = utilities;

        var calls = [];

        if ($state.params.campaignId) {
            calls.push({ api: 'campaign_names_like', params: { likeName: $state.params.campaignId } });
        }

        function load(data) {
            $scope.waterfall.stages = $scope.wfStages.filter(function (s) {
                return s.type !== null && s.type.indexOf("funnel") > -1;
            });
            $scope.select_options = utilities.formatCohorts(cohorts, ['all', 'custom', 'ago', 'year', 'quarter', 'toDate', 'lastFull']);

            if (data.campaign_names_like) {
                $scope.waterfall.campaigns = data.campaign_names_like.data;
            }

            if ($state.params.startStageSequence === undefined) {
                var c = angular.findWhere($scope.wfStages, { default_stage: true });

                if (c) {
                    $scope.query.startStageSequence = c.sequence;
                }

                $scope.query.cohort = 'quarter2Date';
            }

            $scope.query.attModel = $state.params.attModel || "all";
            $scope.query.startStageSequence = parseInt($state.params.startStageSequence) || 1;

            get_revenue();
        }
        if (calls.length > 0) {
            if (initCalls) { initCalls.abort(); }

            (initCalls = api.get(calls)).then(function (data) {
                load(data);
            });
        } else {
            load({});
        }

        $scope.$on('$windowResizeEnd', function () {
            $scope.$apply(function () {
                calcWidth();
            });
        });
    })();

}]);

app.controller('revWaterfallDetailsCtrl', ['$scope', 'api', '$q', '$state', '$filter', 'utilities', "$rootScope", '_', 'cohorts', function ($scope, api, $q, $state, $filter, utilities, $rootScope, _, cohorts) {
    $scope.utils = utilities;

    var dataCall;

    function updateStageHeader() {
        $scope.details.stageHeader = ["anyStage", "dropout"].indexOf($scope.query.type) < 0 ? "Current Stage" : "Next Stage";
    }

    /**
     * Remove Paging and sort params from the search params
     * @param urlStr The search params used in the request
     * @param paramsToRemove Paging and sorting params to remove
     * @return {string} The search params cleaned.
     */
    function removeParams4CSV(urlStr, paramsToRemove) {
        var search_params = new URLSearchParams(urlStr);

        paramsToRemove.forEach(function (param) {
            search_params.delete(param);
        });

        return search_params.toString();
    }

    $scope.pipeData = function (tableState) {
        if (dataCall) { dataCall.abort(); }

        $scope.loadingTable = true;

        var params = angular.copy($scope.query),
            pagination = tableState.pagination,
            start = pagination.start || 0,     // This is NOT the page number, but the index of item in the list that you want to use to display the table.
            number = pagination.number || 20,  // Number of entries showed per page.
            page = Math.ceil(start / number) + 1;

        params.fld = tableState.sort.predicate;
        params.dir = !tableState.sort.reverse ? 'a' : 'd';

        params.pg = page;
        params.window = pageWindow(page, number, 1000);
        var cached = _.get($scope.data.pageCache.data, [$scope.query.type, page]);
        if (cached) {
            $scope.data.leads = cached;
            assignTableState(tableState);
            $scope.loadingTable = false;
        } else {
            (dataCall = api.get("revenue_waterfall_details", params, ['window', 'fld', 'dir', 'pg'])).then(function (data) {
                // Getting the exact parameters for this request and using them for the CSV
                // just remove page and sort params
                $scope.data.csvUrl = "export/revenue_waterfall_details?" +
                    removeParams4CSV(
                        _.queryString(data.params),
                        ['window', 'fld', 'dir', 'pg']);
                $scope.data[$scope.query.type] = data.data;
                $scope.data.pageCache.query = angular.copy(params);
                $scope.data.pageCache.numPages = Math.ceil(data.data.total_rows / number);
                $scope.data.pageCache.window = window;
                /* Passing 1 as the index because the destination follows this format ['stage', index]
                   The index is 1 because the table is client side and there is no 'page 0' so we skip 0 and start at 1
                */
                utilities.chunkData($scope.data.pageCache.data, data.data.rows, number, [$scope.query.type, page], 1);
                $scope.cacheTable($scope.query.type, $scope.data.pageCache.numPages, data.data.total_rows);
                assignTableState(tableState);
                $scope.data.leads = _.get($scope.data.pageCache.data, [$scope.query.type, page]);
                updateStageHeader();
                $scope.loadingTable = false;
            });
        }
    };

    // scope function for unit test
    $scope.cacheTable = function (queryType, numPages, totalItemCount) {
        $scope.data.tableCache[queryType] = {
            numPages: numPages,
            totalItemCount: totalItemCount
        };
    };

    $scope.updateTable = function () {
        refreshData();
    };

    $scope.changeStage = function () {
        $rootScope.$broadcast('detailChanged', "stage detail");
        var pagination = $scope.data.stTable.controller.tableState().pagination;
        pagination.start = 0;
        pagination.current_page = 1;

        $scope.updateTable();
        updateStageHeader();
    };

    $scope.$on('filtersChanged', function () {
        $scope.data.pageCache = {
            data: {}
        };
        $scope.data.tableCache = {};
        $scope.updateTable();
    });

    //------------------------ private methods ------------------------//
    function pageWindow(page, pageSize, windowSize) {
        return Math.floor(((page - 1) * pageSize / windowSize));
    }

    function assignTableState(tableState) {
        tableState.pagination.numberOfPages = $scope.data.tableCache[$scope.query.type].numPages;
        tableState.pagination.totalItemCount = $scope.data.tableCache[$scope.query.type].totalItemCount;
    }

    function refreshData() {
        if ($scope.data.stTable) {
            $scope.data.stTable.controller.pipe($scope.data.stTable.controller.tableState());
        }
    }

    (function init() {
        $scope.query = angular.copy($state.params);
        $scope._ = _;
        $scope.data = {
            pageCache: {
                data: {}
            },
            // tableCache caches the number of pages and number of items
            tableCache: {
            },
            cohorts: utilities.formatCohorts(cohorts, ['all', 'custom', 'ago', 'year', 'quarter', 'toDate', 'lastFull']),
        };

        $scope.details = {
            headers: {
                lead: [{
                    label: "Lead Name",
                    field: "name"
                }, {
                    label: "Entering Stage Date",
                    field: "entered_date",
                    filter: "date:shortDate"
                }, {
                    label: "Source Campaign",
                    field: "campaign_name",
                    // eslint-disable-next-line no-unused-vars
                    link: function (row, query) {
                        var state = 'app.analyze.campaigns.campaignSpecific.attribution.leads';
                        return $scope.shref(state, { name: row.campaign_name, campaignId: row.campaign_id, model: 'first' });
                    }
                }, {
                    label: "Campaign Type",
                    field: "campaign_group"
                }],
                oppty: [{
                    label: "Opportunity Name",
                    field: "name",
                    // eslint-disable-next-line no-unused-vars
                    link: function (row, query) {
                        var opptyState = 'app.analyze.opportunities.opportunitySpecific.attribution.totalTouches';
                        return $scope.shref(opptyState, { oppty: row.id, isId: true });
                    }
                }, {
                    label: "Amount",
                    field: "amount",
                    filter: "currency"
                }, {
                    label: "Entering Stage Date",
                    field: "entered_date",
                    filter: "date:shortDate"
                }, {
                    label: "Source Campaign",
                    field: "campaign_name",
                    // eslint-disable-next-line no-unused-vars
                    link: function (row, query) {
                        var opptyState = 'app.analyze.opportunities.opportunitySpecific.attribution.totalTouches';
                        return $scope.shref(opptyState, { oppty: row.oppty_id, isId: true });
                    }
                }, {
                    label: "Campaign Type",
                    field: "campaign_group"
                }]
            },
            select: [
                { key: "starting", value: "Total Starting" },
                { key: "nextStage", value: "Progressed To Next Stage" },
                { key: "anyStage", value: "Progressed To Any Stage" },
                { key: "remaining", value: "Remaining" },
                { key: "winClosed", value: "Win" },
                { key: "dropout", value: "Dropout" }
            ],
            cohort: $state.params.cohort
        };

        if (!$state.params.type)
            $scope.query.type = $scope.details.select[0].key;

        updateStageHeader();

        $scope.pager = {
            pageSize: 20,
            current: 1,
            pageData: [],
            totalPages: 1,
            currentWindow: 0,
            windowSize: 0,
            windowIndex: 0
        };

        $scope.$on('$destroy', function () {
            if (dataCall) { dataCall.abort(); }
        });
    })();

    this.__testonly__ = { removeParams4CSV };
}]);
