import { IDirective, IScope, } from 'angular';

import * as am4charts from '@amcharts/amcharts4/charts';

import { SVRC_TRENDING_KPI_CHARTS, TrendingKpiChartsService } from '../../services/kpi-charts.service';
import { ITrendingKpiQuery, } from '../trending-kpis-widget/component';
import { IKpiResponse, } from 'src/shared/models/scorecard/kpi-response/model';
import * as ComponentTemplate from './component.html';
import './component.less';

export class TrendingKpiGaugeChartController {
    static $inject: string[] = [
        '$scope',
        SVRC_TRENDING_KPI_CHARTS,
    ];

    public chart: am4charts.GaugeChart;
    public query: ITrendingKpiQuery;
    public queryId: string;
    public responseData: IKpiResponse;

    constructor (
        private $scope: IScope,
        private trendingKpiChartsService: TrendingKpiChartsService,
    ) {
        this.initialize();
    }

    public get gaugeRange(): Record<string, number> {
        return {
            startValue: +(this.query.gaugeRangeMin),
            lowRange: +(this.query.gaugeRangeRedYellow),
            midRange: +(this.query.gaugeRangeYellowGreen),
            endValue: +(this.query.gaugeRangeMax),
        };
    }

    public get areGaugeRangeFilled(): boolean {
        return this.query.gaugeRangeRedYellow !== null
            && this.query.gaugeRangeYellowGreen !== null
            && this.query.gaugeRangeMax !== null
            && this.query.gaugeRangeMin !== null;
    }

    private initialize(): void {
        this.queryId = this.$scope.ctrl.id;

        this.trendingKpiChartsService.changeChartContainerSize('trending-kpi-gauge-chart', '56%', 'height');
        this.setupWatches();
    }

    private setupWatches(): void {
        this.setupWidgetQueryWatch();
        this.setupKPIDataWatch();
        this.setupOnDestroy();
    }

    private setupWidgetQueryWatch(): void {
        this.$scope.$watch('ctrl.widgetQuery', (value: ITrendingKpiQuery) => {
            if (value) {
                this.query = value;
                if (this.areGaugeRangeFilled && this.responseData) {
                    this.initializeChart();
                }
            }
        });
    }

    private setupKPIDataWatch(): void {
        this.$scope.$watch('ctrl.kpiData', (value: IKpiResponse) => {
            if (value) {
                this.responseData = value;
                if (this.query && this.areGaugeRangeFilled) {
                    this.initializeChart();
                }
            }
        });
    }

    private initializeChart(): void {
        this.chart = this.trendingKpiChartsService.generateGaugeGraph(
            this.queryId,
            this.responseData.totalsInterval.total,
            this.gaugeRange,
            this.query.kpi,
        );
    }

    private setupOnDestroy() {
        this.$scope.$on('$destroy', () => {
            this.cleanupChart();
        });
    }

    private cleanupChart() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
}

export class TrendingKpiGaugeChart implements IDirective {
    public restrict = 'E';
    public controller: any = TrendingKpiGaugeChartController;
    public controllerAs = 'ctrl';
    public bindToController = true;
    public template: any = ComponentTemplate;
    public scope: IScope = {
        widgetQuery: '=',
        kpiData: '=',
        id: '=',
    };

    public constructor() {
        return;
    }

    public static Factory(): IDirective {
        return new TrendingKpiGaugeChart();
    }
}
