app.controller('configCtrl', ['$scope', '$http', '$window', '$location', 'utilities', 'api', '$q', '$filter', '$b', '$state', '$timeout', 'noty', '$rootScope', '_', function ($scope, $http, $window, $location, utilities, api, $q, $filter, $b, $state, $timeout, noty, $rootScope, _) {
    var listeners = [];
    var deleteConfigItemCall;
    var globalPropertyCall;
    var loadConfigCall;
    var liveConfigCall;
    var editableConfigCall;

    $scope.moment = moment;
    //    $scope.query = {
    //        orgId : 2
    //        };
    $scope.sleepListen = false;
    $scope.data = {
        throttle: 300,
        version: 0,
        undone: false,
        groupQ: [],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        cmActivityTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
        cmSuccessCriteria: ['Responded', 'Status', 'Custom'],
        timeDecay: ['12 Months', '18 Months', '24 Months', '36 Months', 'Other', 'None'],
        rules: {},
        sortable: {
            //foo
            // accept: function (sourceItemHandleScope, destSortableScope) {return true;},
            itemMoved: function (event) {
                $scope.sleepListen = true;
                if (!event.source.sortableScope.modelValue.length) {
                    var rows = $scope.data.org.validity[event.source.sortableScope.$parent.tab.id + 'Valid'],
                        expression = event.source.sortableScope.$parent.row;
                    rows.splice(rows.indexOf(expression), 1);
                    api.get("OrgAdmin/delete_config_item", { id: expression.id }, false, true);
                    $timeout(function () {
                        $scope.sleepListen = false;
                    }, 10);
                }
            }
            // orderChanged: function(event) {},
            // containment: '#board'
        },
        timeshift: [
            'All Time',
            '24 Months',
            '12 Months',
            '6 Months'
        ],
        validityTabs: [
            {
                name: 'Leads',
                active: true,
                id: 'lead'
            },
            {
                name: 'Opportunities',
                active: false,
                id: 'opportunity'
            },
            {
                name: 'Campaigns',
                active: false,
                id: 'campaign'
            },
            {
                name: 'Campaign Memberships',
                active: false,
                id: 'campaignMember'
            }
        ],
        operators: {
            string: [
                "is",
                "is not",
                "starts with",
                "ends with",
                "contains"
            ],
            number: [
                "=",
                "!=",
                "<",
                ">",
                "<=",
                ">="
            ],
            boolean: [
                "is",
                "is not"
            ],
            date: [
                "before",
                "after",
                "is"
            ],
            datetime: [
                "before",
                "after",
                "is"
            ]
        },
        values: {
            boolean: ['Yes (true)', 'No (false)']
        },
        fields: {},
        dateOptions: {
            'year-format': "'yy'",
            'starting-day': 1
        },
        datepicker: {}
    };

    $scope.isNumber = angular.isNumber;

    //todo I should not have to do this. look into using original function is template
    $scope.isArray = angular.isArray;
    $scope.isObject = angular.isObject;
    $scope.removeRule = function (tab, row, rule) {
        $b.confirm({
            text: 'Are you sure you want to remove this rule?',
            // eslint-disable-next-line no-unused-vars
            callback: function ($modal) {
                var rows = $scope.data.org.validity[tab.id + 'Valid'],
                    i = row.expression.indexOf(rule);
                row.expression.splice(i, 1);
                api.get("OrgAdmin/delete_config_item", { id: rule.id }, false, true);
                if (row.expression.length === 0) {
                    rows.splice(rows.indexOf(row), 1);
                    api.get("OrgAdmin/delete_config_item", { id: row.id }, false, true);
                }
            }
        });
    };

    $scope.currentDate = function (rule) {
        if (!rule.value.length) {
            rule.value = [new Date($.now()).toISOString()];
        }
    };

    $scope.getInt = function (rule) {
        if (rule.value.length && angular.isString(rule.value[0])) {
            rule.value = [Number(rule.value)];
        }
    };


    $scope.removeOpen = function (rule) {
        delete rule.dpo;
    };

    $scope.theField = function (id, name) {
        if (name && $scope.data.fields[id] && $scope.data.fields[id].length) {
            return angular.findWhere($scope.data.fields[id], { label: name });
        }
    };

    $scope.getFieldType = function (table, t) {
        if (t) {
            if (angular.isArray(t)) {
                return $filter('filter')($scope.data.fields[table], function (field) {
                    return _.contains(t, field.type);
                });
            } else {
                return $filter('filter')($scope.data.fields[table], { type: t });
            }
        } else {
            return $scope.data.fields[table];
        }
    };

    $scope.addRule = function (index, collection, i) {
        $scope.data.addingRule = true;
        var tabObj = $scope.data.validityTabs[index],
            tab = tabObj.id + 'Valid',
            arr,
            placeholder = {
                field: '',
                operator: '',
                value: [],
                outer_operator: true,
                temp: {
                    loading: true,
                    loadingListener: $scope.$on('ruleLoaded', function (e, data) {
                        if (data.id === placeholder.id) {
                            $scope.sleepListen = true;
                            $timeout(function () {
                                if (placeholder && placeholder.expression[0]) {
                                    placeholder.expression[0].temp.loading = false;
                                    placeholder.expression[0].temp.loadingListener();
                                    delete placeholder.expression[0].temp.loadingListener;
                                }
                                $scope.sleepListen = false;
                                $scope.data.addingRule = false;
                            }, 0);
                        }
                    })
                }
            };

        if (tabObj.id == 'campaignMember') {
            placeholder.timeshift = $scope.data.timeshift[0];
        }
        if (!collection) {
            if (!$scope.data.org.validity[tab])
                $scope.data.org.validity[tab] = [];
            arr = $scope.data.org.validity[tab];
            placeholder = {
                outer_operator: true,
                expression: [
                    placeholder
                ]
            };
        } else {
            arr = collection;
        }
        if (i === undefined) {
            arr.push(placeholder);
        } else {
            arr.splice(i + 1, 0, placeholder);
        }
    };

    $scope.addFilter = function () {
        if (!$scope.data.org.filters[$scope.data.filterTab]) {
            $scope.data.org.filters[$scope.data.filterTab] = [];
        }
        if ($scope.data.org.filters.length >= 2) {
            $b.alert('<i class="icon-ban space-right"></i>Would you like to add more than two filters? Email <a href="mailto:support@terminus.com">support@terminus.com</a> and let\'s talk about it.');
        } else {

            var filters = $scope.data.org.filters[$scope.data.filterTab].slice(0);
            var num = 1;
            if (filters.length > 0) {
                filters.sort(function (a, b) {
                    return a.number.replace("filter", "") - b.number.replace("filter", "");
                });

                filters.forEach(function (f) {
                    if (num == parseInt(f.number.replace("filter", ""))) {
                        num++;
                    }
                });
            }
            var placeholder = {
                    name: "filter " + num,
                    number: "filter" + num,
                    column: "None"
                },
                cp = angular.copy($scope.data.org.filters);

            cp[$scope.data.filterTab].push(placeholder);
            $state.current.data.loading = true;
            api.set('OrgAdmin/update_config', { filters: cp }, true).success(function (data) {
                $scope.unlisten();
                $timeout(function () {
                    $scope.data.org.filters = angular.extend($scope.data.org.filters, data.filters);
                    $state.current.data.loading = false;
                    $scope.listen();
                }, 0);
            });

        }
    };

    $scope.removeFilter = function (filter, type) {
        if (deleteConfigItemCall) { deleteConfigItemCall.abort(); }
        $b.confirm({
            text: 'Are you sure you want to remove this filter?',
            callback: function () {
                // eslint-disable-next-line no-unused-vars
                (deleteConfigItemCall = api.get("OrgAdmin/delete_config_item", { id: filter.id }, false, true)).then(function (d) {
                    $scope.unlisten();
                    $scope.data.org.filters[type].splice($scope.data.org.filters[type].indexOf(filter), 1);
                    $scope.listen();
                });
            }
        });
    };

    $scope.getEditable = function () {
        return api.get('load_config', { editable: true, orgid: $scope.query.orgId }, false, true);
    };

    $scope.loadEditable = function (allowEdit) {
        $scope.data.live = $scope.data.org;
        $scope.data.org = $scope.data.editable;
        if (allowEdit) {
            $scope.data.locked = true;
            $timeout(function () {
                $scope.listen();
            });
        }
    };

    $scope.loadLive = function (reload) {
        if (loadConfigCall) { loadConfigCall.abort(); }
        if (!reload) {
            $scope.data.locked = false;
            $scope.unlisten();
            $scope.data.editable = $scope.data.org;
            $scope.data.org = $scope.data.live;
        } else {
            $state.current.data.loading = true;
            $scope.unlisten();
            (loadConfigCall = api.get('load_config', { orgid: $scope.query.orgId }, false, true)).then(function (data) {
                $scope.data.editable = $scope.data.org;
                $scope.data.live = data.data;
                $scope.data.org = $scope.data.live;
                $state.current.data.loading = false;
            });
        }
    };

    $scope.confirmChanges = function (e) {
        // eslint-disable-next-line no-unused-vars
        var defer = $q.defer();
        if (!$scope.data.locked) {
            if ($scope.userData.permissions.org_admin === true && !$scope.data.noEdit) {
                $rootScope.$broadcast('trackAction', "load editable");
                $scope.loadEditable(true);


            } else {
                e.stopImmediatePropagation();
                e.stopPropagation();
                e.preventDefault();
                noty.growl('You don\'t have permission to edit your BrightFunnel configuration or the configuration is locked.', 'warning');
            }
        } else {
            $scope.loadConfigs().then(function () {
                var defer = $q.defer();
                defer.resolve();
            });
        }
    };

    $scope.resetValue = function (rule) {
        var tab = angular.findWhere($scope.data.validityTabs, { active: true }),
            field = $scope.theField(tab.id, rule.field),
            type = $scope.getType(field.type);

        if (type == 'boolean' || field.type == 'picklist') {
            rule.value = field.picklistValues ? [field.picklistValues[0]] : [$scope.data.values[type][0]];
        } else {
            rule.value = [];
        }
    };

    $scope.getType = function (type) {
        var types = {
                number: ['percent', 'int', 'double', 'currency'],
                date: ['date'],
                datetime: ['datetime'],
                boolean: ['boolean'],
                string: ['id', 'trextarea', 'string', 'picklist', 'multipicklist', 'email']
            },
            operator = 'string';
        angular.forEach(types, function (value, key) {
            if (_.contains(value, type)) {
                operator = key;
            }
        });
        return operator;
    };

    $scope.stringToArray = function (rule) {
        if (rule.field !== '' && angular.isString(rule.value) && rule.value.indexOf(',') != -1) {
            rule.value = rule.value.split(',');
            rule.value = rule.value.map(function (v) {
                return v.trim();
            });
        } else if (rule.field !== '' && angular.isString(rule.value) && rule.value.indexOf(',') === -1) {
            rule.value = [rule.value];
        }
    };

    $scope.removeFromGroup = function (set, group, rule) {
        set.push({
            outer_operator: true,
            expression: [rule]
        });
        group.splice(group.indexOf(rule), 1);
    };

    $scope.toggleJoin = function (rule) {
        if ($scope.data.locked) {
            rule.outer_operator = !rule.outer_operator;
        }
    };

    $scope.toggleChoose = function (row) {
        $scope.data.grouping = !$scope.data.grouping;
        if (row) {
            $scope.addToQ(row);
        }
        if (!$scope.data.grouping && $scope.data.groupQ.length) {
            // eslint-disable-next-line no-unused-vars
            angular.forEach($scope.data.groupQ, function (row, i) {
                row.expression[0].temp.selected = false;
            });
            $scope.data.groupQ.length = 0;
        }
    };

    $scope.inQ = function (rule) {
        return ($scope.data.groupQ.indexOf(rule) != -1);
    };

    $scope.addToQ = function (row) {
        if (!$scope.inQ(row)) {
            if (!row.expression[0].temp) {
                row.expression[0].temp = {};
            }
            row.expression[0].temp.selected = true;
            $scope.data.groupQ.push(row);
        }
    };

    $scope.removeFromQ = function (row) {
        $scope.data.groupQ.splice($scope.data.groupQ.indexOf(row), 1);
    };

    $scope.toggleQ = function (row) {
        if ($scope.inQ(row)) {
            $scope.removeFromQ(row);
        } else {
            $scope.addToQ(row);
        }
    };

    $scope.groupRules = function (tab) {
        var rows = $scope.data.org.validity[tab.id + 'Valid'],
            newGroup = angular.copy($scope.data.groupQ);

        newGroup.push(function (a, b) {
            return _.isArray(a) ? a.concat(b) : undefined;
        });
        var m = _.merge.apply(this, newGroup);
        angular.forEach($scope.data.groupQ, function (row, i) {
            if (i < $scope.data.groupQ.length - 1) {
                api.get("OrgAdmin/delete_config_item", { id: row.id }, false, true);
            }
            rows.splice(rows.indexOf(row), 1);
        });
        rows.push(m);
        $scope.data.groupQ.length = 0;
        $scope.toggleChoose();
    };
    $scope.configSave = _.debounce(function (path) {
        $scope.configCall($scope.requestObj(path, $scope.$eval('data.org.' + path)));
    }, $scope.data.throttle, { 'leading': false, 'trailing': true });
    // eslint-disable-next-line no-unused-vars
    $scope.configCall = function (moduleNew, moduleOld) {
        // if(!$scope.data.noSave) {
        //     $scope.data.noSave = true;
        api.set('OrgAdmin/update_config', moduleNew, true).success(function (data) {
            if (data.validity) {
                var newRule;
                // eslint-disable-next-line no-unused-vars
                angular.forEach(data, function (o, k) {
                    // eslint-disable-next-line no-unused-vars
                    angular.forEach(o, function (oc, kc) {
                        // eslint-disable-next-line no-unused-vars
                        angular.forEach(oc, function (occ, kcc) {
                            // eslint-disable-next-line no-unused-vars
                            angular.forEach(occ.expression, function (rule, i) {
                                if (rule.temp && rule.temp.loading) {
                                    newRule = angular.copy(occ);
                                }
                                angular.forEach(rule, function (ruleVal, ruleKey) {
                                    if (ruleKey != 'id') {
                                        delete rule[ruleKey];
                                    }
                                });
                            });
                        });
                    });
                });
                if (newRule) {
                    $scope.$broadcast('ruleLoaded', newRule);
                    $scope.sleepListen = true;
                    $timeout(function () {
                        $.extend(true, $scope.data.org, data);
                        $scope.data.noSave = false;
                        $scope.sleepListen = false;
                    }, 0);
                }
            }
            noty.growl('all changes saved...', 'success', true);
        });
        // } else {
        //     $scope.data.noSave = false;
        // }
    };

    $scope.requestObj = function (path, val) {
        var data = $scope.data.org,
            arr = path.split('.'),
            map = {};
        function indexType(data, i) {
            map[i] = angular.isArray(data[i]) ? 'array' : 'object';
            return data[i];
        }
        arr.reduce(indexType, data);
        var obj = {};
        function indexObj(obj, i) {
            var type = map[i],
                last = (i == arr[arr.length - 1]);
            if (last) {
                obj[i] = val;
            } else {
                if (type == 'object') {
                    obj[i] = {};
                } else {
                    obj[i] = [];
                }
            }
            return obj[i];
        }
        arr.reduce(indexObj, obj);
        return obj;
    };

    $scope.ruleListener = function (rule) {
        rule.temp.listener = $scope.$watch(function () {
            return rule;
        }, function (nv, ov) {
            if (nv != ov) {
                // eslint-disable-next-line no-undef
                $scope.configSave('validity.' + key);
            }
        });
    };

    $scope.listen = function () {
        angular.forEach($scope.data.org.validity, function (data, key) {
            listeners.push($scope.$watch(function () {
                return data;
            }, function (nv, ov) {
                if (nv != ov && !$scope.sleepListen) {
                    $scope.configSave('validity.' + key);
                }
            }, true));
        });
        listeners.push($scope.$watch('data.org.filters', function (nv, ov) {
            if (nv && nv != ov) {
                $scope.configSave('filters');
            }
        }, true));
    };

    $scope.unlisten = function () {
        // eslint-disable-next-line no-unused-vars
        angular.forEach(listeners, function (unwatch, i) {
            unwatch();
        });
        listeners.length = 0;
    };



    $scope.data.dpicker = {
        toggleMinDate: function () {
            $scope.minDate = $scope.minDate ? null : new Date();
        },

        maxDate: new Date('2014-06-22'),

        dateOptions: {
            startingDay: 1,
            showWeeks: true
        },

        // Disable weekend selection
        disabled: function (calendarDate, mode) {
            return mode === 'day' && (calendarDate.getDay() === 0 || calendarDate.getDay() === 6);
        },

        hourStep: 1,
        minuteStep: 15,

        timeOptions: {
            hourStep: [1, 2, 3],
            minuteStep: [1, 5, 10, 15, 25, 30]
        },

        showMeridian: true,
        timeToggleMode: function () {
            $scope.showMeridian = !$scope.showMeridian;
        },

        resetHours: function () {
            $scope.date.setHours(1);
        },

        dateTimeNow: function (rule) {
            if (!rule.value.length) {
                rule.value = [new Date()];
            }
        },

        getReadValue: function (rule) {
            if (rule.value.length) {
                return rule.value[0];
            } else {
                return '';
            }
        }
    };

    $scope.data.dpicker.toggleMinDate();

    $scope.$on('$destroy', function () {
        if (deleteConfigItemCall) { deleteConfigItemCall.abort(); }
        if (globalPropertyCall) { globalPropertyCall.abort(); }
        if (loadConfigCall) { loadConfigCall.abort(); }
        if (liveConfigCall) { liveConfigCall.abort(); }
        if (editableConfigCall) { editableConfigCall.abort(); }
    });

    $scope.requestDataRefresh = function () {
        $b.confirm({
            text: 'You will be added to the queue for a data refresh. This process may take 30 minutes to 2 hours depending on your database size.',
            // eslint-disable-next-line no-unused-vars
            callback: function ($modal) {
                $rootScope.$broadcast('trackAction', "submit for approval");
                $state.current.data.loading = true;
                api.set('OrgAdmin/request_data_refresh').success(function (data) {
                    noty.growl(data, 'information');
                    $state.current.data.loading = false;
                });
            }
        });

    };

    $scope.submitForApproval = function () {
        var valid = true;

        // eslint-disable-next-line no-unused-vars
        angular.forEach($scope.data.org.validity, function (rules, key) {
            // eslint-disable-next-line no-unused-vars
            angular.forEach(rules, function (expression, i) {
                if (!expression.id || !expression.expression || expression.expression && !expression.expression.length) {
                    valid = false;
                } else {
                    // eslint-disable-next-line no-unused-vars
                    angular.forEach(expression.expression, function (rule, i) {
                        if (!rule.id || (!rule.operator || rule.operator === '') || (!rule.value || rule.value === '' || rule.value && !angular.isArray(rule.value || rule.value && angular.isArray(rule.value) && !rule.value.length))) {
                            valid = false;
                        }
                    });
                }
            });
        });

        if (!valid) {
            $b.alert('One or more of your validity rules is missing a required value. Each rule must have a field, operator, and value selected.');
        } else {
            $b.confirm({
                text: 'Are you sure you want to submit this configuration for approval? This configuration request will be reviewed by our development team.',
                // eslint-disable-next-line no-unused-vars
                callback: function ($modal) {
                    $rootScope.$broadcast('trackAction', "submit for approval");
                    $state.current.data.loading = true;
                    api.set('OrgAdmin/submit_new_config').success(function (data) {
                        noty.growl(data, 'information');
                        $scope.data.org.locked = true;
                        $scope.data.editable.locked = true;
                        $scope.data.locked = false;
                        $state.current.data.loading = false;
                    });
                }
            });
        }
    };

    $scope.loadConfigs = function () {
        var defer = $q.defer();
        //------------ load both the live version and editable -------------//

        (liveConfigCall = api.get('load_config', null, false, true)).then(function (data) {
            $scope.data.org = data.data;
            check();
            $state.current.data.loading = false;
        });

        (editableConfigCall = api.get('load_config', { editable: true }, false, true)).then(function (data) {
            $scope.data.editable = data.data;
            $scope.data.editable.editable = true;
            check();
        });


        if (!($scope.data.fields.opportunity && $scope.data.fields.campaign && $scope.data.fields.lead && $scope.data.org && $scope.data.editable)) {

            //------------ load all of the salesforce objects -------------//
            api.sfget('campaignMember').success(function (data) {
                $scope.data.fields.campaignMember = data.fields;
                check();
                // get rest of objects after we load one
                api.sfget('lead').success(function (data) {
                    $scope.data.fields.lead = data.fields;
                    check();
                });
                api.sfget('campaign').success(function (data) {
                    $scope.data.fields.campaign = data.fields;
                    check();
                });
                api.sfget('opportunity').success(function (data) {
                    $scope.data.fields.opportunity = data.fields;
                    check();
                });
                api.sfget('account').success(function (data) {
                    $scope.data.fields.account = data.fields;
                    check();
                });
            });
        }
        function check() {
            if ($scope.data.fields.opportunity && $scope.data.fields.campaign && $scope.data.fields.lead && $scope.data.org && $scope.data.editable) {
                defer.resolve();
            }
        }
        return defer.promise;

    };

    $scope.cancelSubmit = function (admin) {
        // eslint-disable-next-line no-unused-vars
        var defer = $q.defer();
        if (!admin) {
            $b.confirm({
                text: 'Are you sure you want to cancel your request to change your BrightFunnel configuration?',
                // eslint-disable-next-line no-unused-vars
                callback: function ($modal) {
                    $rootScope.$broadcast('trackAction', "cancel submit");
                    $state.current.data.loading = true;
                    api.set('OrgAdmin/retract_new_config').success(function (data) {
                        noty.growl(data, 'information');
                        $scope.data.editable.locked = false;
                        $scope.data.org.locked = false;
                        $scope.loadConfigs().then(function () {
                            var defer = $q.defer();
                            defer.resolve();
                        });
                        $state.current.data.loading = false;
                    });
                }
            });
        } else {
            var parent = $scope;
            $b.modal({
                title: 'Are you sure?',
                id: 'reject-config',
                template: '<p>Are you sure you want to reject this config request?</p>' +
                    '<textarea class="form-control max-width" ng-model="message" placeholder="let the user know why you are rejecting their config change request...." rows="3"></textarea>',
                width: '400px',
                buttons: [{
                    'text': 'reject',
                    'ng-disabled': '!message',
                    'ng-click': 'reject()',
                    'class': 'btn btn-sm btn-primary pull-right'
                }, {
                    'html': 'cancel',
                    'ng-click': '$modal.closeModal()'
                }],
                x: true,
                maxed: false,
                controller: function ($scope) {
                    $scope.reject = function () {
                        $rootScope.$broadcast('trackAction', "reject config");
                        $scope.$modal.loading = true;
                        api.set('OrgAdmin/retract_new_config', { message: $scope.message }).success(function (data) {
                            noty.growl(data, 'information');
                            parent.data.editable.locked = false;
                            parent.data.org.locked = false;
                            parent.loadConfigs().then(function () {
                                var defer = $q.defer();
                                defer.resolve();
                            });
                            $scope.$modal.loading = false;
                            $scope.$modal.closeModal();
                            $state.current.data.loading = false;
                        });
                    };
                }
            });
        }
    };


    $scope.bfSave = function () {

        $b.confirm({
            text: 'Are you sure this configuration is completely implemented? This draft will now show as the live configuration to the customer.',
            // eslint-disable-next-line no-unused-vars
            callback: function ($modal) {
                $rootScope.$broadcast('trackAction', "config saved");
                $state.current.data.loading = true;
                api.set('BFAdmin/save_edited_config').success(function (data) {
                    // eslint-disable-next-line no-unused-vars
                    var defer = $q.defer();
                    noty.growl(data, 'information');
                    $scope.data.org.locked = false;
                    $scope.data.org.approved = false;
                    $scope.data.org.estimate = false;
                    $scope.data.org.notes = null;
                    $scope.data.org.internal_notes = null;
                    $scope.loadConfigs().then(function () {
                        $state.current.data.loading = false;
                    });
                });
            }
        });

    };

    $scope.updateNotes = _.debounce(function (note, internal) {
        var params = { notes: note };
        if (internal) {
            params.internal = true;
        }
        api.set('BFAdmin/updateNotes', params);
    }, 300);

    //init
    (function () {
        var defer = $q.defer();
        $state.current.data.loading = true;

        $scope.data.canEdit = !$scope.userData.permissions.org_admin;

        if ($location.host().indexOf("app.brightfunnel") > -1) {
            (globalPropertyCall = api.get("globalProperty", { name: "stage.active" }, false, true)).then(function (data) {
                $scope.data.noEdit = data.data[0].value == "true";
            });
        } else {
            $scope.data.noEdit = false;
        }
        //$scope.configs.orgs = [{id:0, name:"--Select an org--"}].concat(active);

        $scope.$on('$destroy', function () {
            if (deleteConfigItemCall) { deleteConfigItemCall.abort(); }
            if (globalPropertyCall) { deleteConfigItemCall.abort(); }
            if (loadConfigCall) { loadConfigCall.abort(); }
            if (liveConfigCall) { liveConfigCall.abort(); }
            if (editableConfigCall) { editableConfigCall.abort(); }
        });

        $scope.loadConfigs().then(function () {
            defer.resolve();
        });

        return defer.promise;

    })().then(function () {
    });
}]);
