// eslint-disable-next-line no-unused-vars
app.controller('savableReportsCtrl', ['$scope', 'filters', '$state', 'api', '_', '$b', '$rootScope', '$window', 'noty', '$location', function ($scope, filtersSrv, $state, api, _, $b, $rootScope, $window, noty, $location) {

    $scope.resize = function () {
        $($window).resize();
    };

    $scope.parse = function (str) {
        return JSON.parse(str);
    };

    $scope.openFolder = function (e, folder) {
        e.preventDefault();
        _.forEach($scope.data.folders, function (f) {
            if (f.folder_id == folder.folder_id) {
                f.open = !f.open;
            } else {
                f.open = false;
            }
        });
    };

    $scope.reportClick = function (e, stateParams) {
        filtersSrv.initializeFilters($rootScope.filters, filtersSrv.makeSelectedFilterMap(JSON.parse(stateParams)), "sync");
        filtersSrv.initializeFolders($rootScope.accountLists, filtersSrv.getListIdsFromParams(JSON.parse(stateParams)), "sync");
        e.stopPropagation();
    };

    $scope.canViewFolder = function (folder) {
        return folder.is_mine || folder.folder_permission == 'public' || folder.folder_permission == 'read-only';
    };

    $scope.canEditFolder = function (folder) {
        return folder && folder.is_mine;
    };

    $scope.createFolder = function () {
        var parent = $scope;

        $b.modal({
            title: 'Create Folder',
            id: 'create-folder-modal',
            template: require('./create-folder-modal.html'),
            width: '400px',
            buttons: [
                {
                    'text': 'Save',
                    'class': 'btn btn-sm btn-primary pull-right',
                    'ng-click': 'saveFolder()',
                    'ng-disabled': '!data.folder_name || data.loading'
                },
                {
                    'text': 'Cancel',
                    'ng-click': 'closeModal()',
                    'class': 'btn btn-sm btn-default pull-right somemargin-right',
                    'ng-disabled': 'data.loading'
                }
            ],
            controller: function ($scope) {
                $scope.data = {
                    folder_permission: 'read-only',
                };
                $scope.error = {
                    message: null
                };

                $scope.closeModal = function () {
                    $scope.$modal.closeModal();
                };

                $scope.saveFolder = function () {
                    $scope.data.loading = true;
                    var params = {
                        folder_name: $scope.data.folder_name,
                        folder_permission: $scope.data.folder_permission,
                        folder_type: 'saved_report'
                    };

                    // eslint-disable-next-line no-unused-vars
                    api.set('folder/createFolder', params).success(function (data, status) {
                        api.loadSavedReports().then(function (data) {
                            parent.data.folders = data;
                            $rootScope.savedReports = data;
                            $scope.data.loading = false;
                            $scope.error.message = null;
                            $scope.closeModal();

                            noty.growl('Successfully created folder ' + $scope.data.folder_name, 'success');
                        });
                    }).error(function (data, status) {
                        $scope.data.loading = false;
                        $scope.error.message = api.handleErrorResponse(data, status);
                    });
                };
            }
        });
    };

    $scope.createReport = function () {
        var parent = $scope;
        var state = $state;

        $b.modal({
            title: 'Create Report',
            id: 'create-report-modal',
            template: require('./create-report-modal.html'),
            width: '400px',
            buttons: [
                {
                    'text': 'Save',
                    'class': 'btn btn-sm btn-primary pull-right',
                    'ng-click': 'saveReport()',
                    'ng-disabled': '!data.name || data.loading || !data.folder'
                },
                {
                    'text': 'Cancel',
                    'ng-click': 'closeModal()',
                    'class': 'btn btn-sm btn-default pull-right somemargin-right',
                    'ng-disabled': 'data.loading'
                }
            ],
            controller: function ($scope) {
                $scope.data = {
                    url: location.href,
                    folders: parent.data.folders
                };
                $scope.error = {
                    message: null
                };

                $scope.closeModal = function () {
                    $scope.$modal.closeModal();
                };

                $scope.saveReport = function () {
                    $scope.data.loading = true;
                    var params = {
                        name: $scope.data.name,
                        url: $scope.data.url,
                        folder_id: $scope.data.folder.folder_id,
                        stateName: state.current.name,
                        stateParams: JSON.stringify(state.params),
                        description: $scope.data.description
                    };

                    api.set('report/create', params).success(function (data) {
                        $scope.data.loading = false;
                        $scope.error.message = null;
                        var folder = _.find(parent.data.folders, { 'folder_id': params.folder_id });
                        folder.contents.push(data);
                        $rootScope.savedReports = parent.data.folders;
                        $scope.closeModal();
                    }).error(function (data, status) {
                        $scope.data.loading = false;
                        $scope.error.message = api.handleErrorResponse(data, status);
                    });
                };
            }
        });
    };

    // eslint-disable-next-line no-unused-vars
    $scope.updateFolder = function (event, folder, index) {
        event.preventDefault();
        event.stopPropagation();

        if (folder.is_mine) {
            var parent = $scope;

            $b.modal({
                title: 'Update Folder',
                id: 'create-folder-modal',
                template: require('./create-folder-modal.html'),
                width: '400px',
                buttons: [
                    {
                        'text': 'Update',
                        'class': 'btn btn-sm btn-primary pull-right',
                        'ng-click': 'editFolder()',
                        'ng-disabled': '!data.folder_name || data.loading'
                    },
                    {
                        'text': 'Cancel',
                        'ng-click': 'closeModal()',
                        'class': 'btn btn-sm btn-default pull-right somemargin-right',
                        'ng-disabled': 'data.loading'
                    }
                ],
                controller: function ($scope) {
                    $scope.data = {
                        folder_id: folder.folder_id,
                        folder_name: folder.folder_name,
                        folder_permission: folder.folder_permission,
                    };
                    $scope.error = {
                        message: null
                    };

                    $scope.closeModal = function () {
                        $scope.$modal.closeModal();
                    };

                    $scope.editFolder = function () {
                        $scope.data.loading = true;
                        var params = {
                            folder_id: $scope.data.folder_id,
                            folder_name: $scope.data.folder_name,
                            folder_permission: $scope.data.folder_permission,
                            folder_type: 'saved_report'
                        };

                        // eslint-disable-next-line no-unused-vars
                        api.set('folder/updateFolder', params).success(function (data) {
                            noty.growl('Successfully updated folder ' + $scope.data.folder_name, 'success');

                            api.loadSavedReports().then(function (data) {
                                parent.data.folders = data;
                                $rootScope.savedReports = data;
                                $scope.data.loading = false;
                                $scope.error.message = null;
                                $scope.closeModal();
                            });
                        }).error(function (data, status) {
                            $scope.data.loading = false;
                            $scope.error.message = api.handleErrorResponse(data, status);
                        });
                    };
                }
            });
        } else {
            $b.alert('Only the owner of this folder can make changes to it.', 'Permission Needed');
        }
    };

    $scope.deleteFolder = function (event, folder, index) {
        event.preventDefault();
        event.stopPropagation();

        if (folder.is_mine) {
            var parent = $scope;

            $b.modal({
                title: 'Delete "' + folder.folder_name + '"?',
                id: 'delete-folder-modal',
                template: require('./delete-folder-modal.html'),
                width: '400px',
                buttons: [
                    {
                        'text': 'Delete',
                        'class': 'btn btn-sm btn-primary pull-right',
                        'ng-click': 'deleteFolder()',
                        'ng-disabled': 'data.loading'
                    },
                    {
                        'text': 'Cancel',
                        'ng-click': 'closeModal()',
                        'class': 'btn btn-sm btn-default pull-right somemargin-right',
                        'ng-disabled': 'data.loading'
                    }
                ],
                controller: function ($scope) {
                    $scope.data = {
                        folder_id: folder.folder_id,
                        folder_name: folder.folder_name
                    };
                    $scope.error = {
                        message: null
                    };

                    $scope.closeModal = function () {
                        $scope.$modal.closeModal();
                    };

                    $scope.deleteFolder = function () {
                        $scope.data.loading = true;

                        var params = {
                            folder_id: $scope.data.folder_id,
                            folder_type: 'saved_report'
                        };

                        // eslint-disable-next-line no-unused-vars
                        api.set('folder/deleteFolder', params).success(function (data) {
                            parent.data.folders.splice(index, 1);
                            $scope.data.loading = false;
                            $scope.error.message = null;
                            $scope.closeModal();
                            noty.growl('Successfully deleted folder ' + $scope.data.folder_name, 'success');
                        }).error(function (data, status) {
                            $scope.data.loading = false;
                            $scope.error.message = api.handleErrorResponse(data, status);
                        });
                    };
                }
            });
        } else {
            $b.alert('Only the owner of this folder can make changes to it.', 'Permission Needed');
        }
    };

    $scope.deleteReport = function (event, report, folder, index) {
        event.preventDefault();
        event.stopPropagation();

        var parent = $scope;
        $b.modal({
            title: 'Delete Report',
            id: 'delete-report-modal',
            template: require('./delete-report-modal.html'),
            width: '400px',
            buttons: [
                {
                    'text': 'Delete',
                    'class': 'btn btn-sm btn-primary pull-right',
                    'ng-click': 'deleteReport()',
                },
                {
                    'text': 'Cancel',
                    'ng-click': 'closeModal()',
                    'class': 'btn btn-sm btn-default pull-right somemargin-right',
                    'ng-disabled': 'data.loading'
                }
            ],
            controller: function ($scope) {
                $scope.data = {
                    name: report.name
                };
                $scope.error = {
                    message: null
                };

                $scope.closeModal = function () {
                    $scope.$modal.closeModal();
                };

                $scope.deleteReport = function () {
                    $scope.data.loading = true;
                    var params = {
                        report_id: report.report_id
                    };
                    // eslint-disable-next-line no-unused-vars
                    api.set('report/delete', params).success(function (data) {
                        var currentFolder = _.find(parent.data.folders, { folder_id: folder.folder_id });
                        currentFolder.contents.splice(index, 1);
                        $scope.data.loading = false;
                        $scope.error.message = null;
                        $scope.closeModal();
                        noty.growl('The report has been deleted.', 'information', true);
                    }).error(function (data, status) {
                        $scope.data.loading = false;
                        $scope.error.message = api.handleErrorResponse(data, status);
                    });
                };
            }
        });
    };

    $scope.updateReport = function (event, report, folder, index) {
        event.preventDefault();
        event.stopPropagation();

        var parent = $scope;
        $b.modal({
            title: 'Update Report',
            id: 'update-report-modal',
            template: require('./create-report-modal.html'),
            width: '400px',
            buttons: [
                {
                    'text': 'Update',
                    'class': 'btn btn-sm btn-primary pull-right',
                    'ng-click': 'editReport()',
                    'ng-disabled': '!data.name || data.loading'
                },
                {
                    'text': 'Cancel',
                    'ng-click': 'closeModal()',
                    'class': 'btn btn-sm btn-default pull-right somemargin-right',
                    'ng-disabled': 'data.loading'
                }
            ],
            controller: function ($scope) {
                $scope.data = {
                    name: report.name,
                    folder: folder,
                    description: report.description,
                    url: report.url,
                    editing: true
                };
                $scope.error = {
                    message: null
                };

                $scope.closeModal = function () {
                    $scope.$modal.closeModal();
                };

                $scope.editReport = function () {
                    $scope.data.loading = true;
                    var params = {
                        name: $scope.data.name,
                        report_id: report.report_id,
                        description: $scope.data.description
                    };
                    api.set('report/update', params).success(function (data) {
                        var currentFolder = _.find(parent.data.folders, { folder_id: folder.folder_id });
                        currentFolder.contents[index] = data;
                        $scope.data.loading = false;
                        $scope.error.message = null;
                        $scope.closeModal();
                    }).error(function (data, status) {
                        $scope.data.loading = false;
                        $scope.error.message = api.handleErrorResponse(data, status);
                    });
                };
            }
        });
    };

    (function init() {
        $scope.data = {
            oneAtATime: true,
            folders: $rootScope.savedReports
        };
        $scope.error = {
            message: null
        };
    })();

}]);
