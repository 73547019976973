/* eslint-disable no-undef */
app.service('navigation', ['$rootScope', '$filter', '$state', function ($rootScope, $filter, $state) {
    const ANALYZE_CAMPAIGNS_LIST_ANALYSIS = 'app.analyze.campaigns.listAnalysis';
    const ANALYZE_CAMPAIGNS_LIST_ANALYSIS_CAMPAIGN_GROUPS = 'app.analyze.campaigns.listAnalysis.campaignGroups';
    const ANALYZE_CAMPAIGNS_CAMPAIGN_SPECIFIC_TRENDING = 'app.analyze.campaigns.campaignSpecific.trending';
    const APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY = 'app.analyze.webTracking.webActivity';
    const APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL = 'app.analyze.webTracking.webActivity.channel';
    const APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL_ASSETS = 'app.analyze.webTracking.webActivity.channelAssets';
    const DISCOVER_SP_TRENDING = 'app.discover.stageProgression.trending';
    const DISCOVER_SP_TRENDING_DETAILS = 'app.discover.stageProgression.trendingDetails';

    var that = this;

    this.removeDisabled = function (state) {
        return $filter('filter')((state.children ? state.children : state.tabs), function (child) {
            return child.config ? !child.config.data.hiddenPage && !child.config.blind : true;
        });
    };

    this.className = function (state) {
        if (state.name == 'app.dashboard') {
            state.icon = 'dashboard3';
        }
        return state.icon ? state.icon : that.extractName(state.name).toLowerCase();
    };

    // this.active = function(child) {
    //     if(!(_.find($rootScope.breadcrumbs,{name:child.name}) && $state.current.name === child.name) && child.children && child.children.length){
    //           return _.find(child.children,{name:$state.current.name});
    //     }
    //     else {
    //         return _.find($rootScope.breadcrumbs,{name:child.name}) && $state.current.name === child.name;
    //     }
    // };
    this.active = function (child) {
        let alternateSearchName;
        if (
            child.name === APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY
            && (
                $state.current.name === APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL
                || $state.current.name === APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY_CHANNEL_ASSETS
            )
        ) {
            alternateSearchName = APP_ANALYZE_WEB_TRACKING_WEB_ACTIVITY;
        }
        if (
            child.name === ANALYZE_CAMPAIGNS_LIST_ANALYSIS
            && (
                $state.current.name === ANALYZE_CAMPAIGNS_LIST_ANALYSIS_CAMPAIGN_GROUPS
                || $state.current.name === ANALYZE_CAMPAIGNS_CAMPAIGN_SPECIFIC_TRENDING
            )
        ) {
            alternateSearchName = ANALYZE_CAMPAIGNS_LIST_ANALYSIS;
        }

        if (
            child.name === DISCOVER_SP_TRENDING
            && $state.current.name === DISCOVER_SP_TRENDING_DETAILS
        ) {
            alternateSearchName = DISCOVER_SP_TRENDING;
        }

        if (alternateSearchName) {
            return _.find($rootScope.breadcrumbs, { name: alternateSearchName });
        }

        if (!(_.find($rootScope.breadcrumbs, { name: child.name }) && $state.current.name === child.name) && child.children && child.children.length) {
            return _.find(child.children, { name: $state.current.name });
        }
        return _.find($rootScope.breadcrumbs, { name: child.name }) && $state.current.name === child.name;
    };

    this.extractName = function (string) {
        var split = string.split('.').pop(),
            result = split.replace(/([A-Z])/g, " $1"),
            finalResult = result.charAt(0).toUpperCase() + result.slice(1);

        return finalResult.replace('And', '&').replace('Opps', 'Opps.');
    };

    this.getSettingParent = function () {
        var settings = $rootScope.settings[0].children,
            obj = _.findDeep(settings, { 'name': $state.$current.data.parent });

        // we need to handle the grandchildren exception for the settings-nav
        if (obj && obj.config.parentIsGrandParent) {
            obj = _.findDeep(settings, { 'name': obj.config.data.parent });
        }
        return obj;
    };

    this.renderLink = function (state) {
        return state.status != "removed" && state.status != "url" && state.config.subnav !== false;
    };

    this.isActive = function (s) {
        return $state.includes(s.name);
    };
}]);
