app.controller('AllOpptysTouchesCtrl', ['$scope', '$http', '$window', '$location', '$stateParams', 'utilities', '$state', "api", "$rootScope", 'filters', '$timeout', '$compile', '$localStorage', '_', function ($scope, $http, $window, $location, $stateParams, utilities, $state, api, $rootScope, filterSrv, $timeout, $compile, $storage, _) {
    var allTouchesCall;

    if (allTouchesCall) { allTouchesCall.abort(); }

    $scope.statuses = {
        data: null
    };

    $scope.fieldSearching = function (field) {
        return _.result(_.findWhere($scope.data.columns, { 'field': field }), 'displayName');
    };

    //todo can this and other such be in one common place rather than copy-pasted?
    // eslint-disable-next-line no-unused-vars
    $scope.$watchCollection('data.tableState', function (nv, ov) {
        if (nv && nv.items) {
            var exclude = [];
            // eslint-disable-next-line no-unused-vars
            angular.forEach($scope.data.columns, function (col, i) {
                if (col.excludeTotal) {
                    exclude.push(col.field);
                }
            });
            $scope.data.totals = utilities.sumObjects(nv.items, null, exclude);
        }
    });

    $scope.pipeData = function (tableState) {
        if (allTouchesCall) {
            allTouchesCall.abort();
        }
        $state.current.data.loading = true;
        let params = $scope.query,
            pagination = tableState.pagination,
            start = pagination.start || 0,     // This is NOT the page number, but the index of item in the list that you want to use to display the table.
            number = pagination.number || 20,  // Number of entries showed per page.
            page = Math.ceil(start / number) + 1;
        params.ps = 20;
        params.pg = Math.floor(((page - 1) * number / params.ps) + 1);

        (allTouchesCall = api.getter({url:"all_touches", params: params})).then(function (data) {
            $scope.touch_data = data.data.touches;
            tableState.pagination.numberOfPages = Math.ceil(data.data.total / number);
            tableState.pagination.totalItemCount = data.data.total;
            $scope.data.csvUrl = 'export/allOpptyTouches?' + _.queryString(angular.extend(angular.copy(params), filterSrv.getFiltersAsParams($rootScope.filters)));
            $state.current.data.loading = false;
        // eslint-disable-next-line no-unused-vars
        }, function (data) {
            $state.current.data.loading = false;
        });
    };

    function refreshData() {
        if ($scope.data.stTable) {
            let newState = $scope.data.stTable.controller.tableState();
            newState.pagination.start = 0;
            $scope.data.stTable.controller.pipe(newState);
        }
    }

    (function init() {
        $state.current.data.loading = true;
        $scope.query = {
            dateType: "created"
        };
        utilities.combine($scope.query, $state.params);

        $scope.data = {
            cohorts: [{ key: "time", value: "Custom Range" }, { key: "*NONE*", value: "All Time" }],
            columns: [
                { field: "oppty.name", searchable: true, displayName: "Oppt. Name", defaultSort: false, excludeTotal: true },
                { field: "touch.date", displayName: "Activity Date", cellFilter: "date:M/d/yy", defaultSort: false, excludeTotal: true },
                { field: "touch.attributed_amount", displayName: "Custom Attribution Amount", cellFilter: "bfCurrency", defaultSort: false },
                { field: "touch.attributed_percent", displayName: "Custom Attribution Percent", cellFilter: "percentage:2", excludeTotal: true, defaultSort: false },
                { field: "touch.pre_or_post", searchable: true, displayName: "Type", excludeTotal: true, defaultSort: false },
                { field: "campaign.name", searchable: true, displayName: "Campaign Name", excludeTotal: true, defaultSort: false },
                { field: "campaign.type", searchable: true, displayName: "Campaign Type", excludeTotal: true, defaultSort: false }
            ],
            isCampSpecific: false,
            campaignDesc: "",
            campaignName: ""
        };

        $scope.$on('$destroy', function () {
            if (allTouchesCall) { allTouchesCall.abort(); }
        });

        $scope.$on('filtersChanged', refreshData);

        $scope._ = _;

    })();

}]);
