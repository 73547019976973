import { module as angularModule } from 'angular';

import { APP_BRIGHTFUNNEL } from '../../constants';
import * as CONSTS from './consts';

const app = angularModule(APP_BRIGHTFUNNEL);

export class TerminusHubService {
    static $inject: string[] = [
        '$rootScope',
        '$window',
        'noty',
        'authSrvc',
        '_',
        'storageSrvc',
        'Constants',
        'environmentSrvc',
    ];

    private checkAuthToken = (url) => {
        return this.authSrvc.isLoggedIn(this.Constants.TERMINUS_HUB).then(() => {
            this.authSrvc.redirect(url, this.isNewtab());
        }, (error) => {
            const path = `${this.$window.location.pathname}${this.$window.location.search}`;
            if (this.storageSrvc.save) {
                this.storageSrvc.save('ngStorage-redirect',
                    JSON.stringify(path +
                        window.location.hash));
            }
            this.authSrvc.removeToken(this.Constants.TERMINUS_HUB);
            this.authSrvc.goToEndpoint(error, url, this.isNewtab());
            throw error;
        });
    };

    private getUrl = (host: string, path: string) => `${host}${path}`;

    private isNewtab = () => this.$window.event && (this.$window.event.ctrlKey || this.$window.event.metaKey);

    formatQueryParams(params) {
        const cohortStartDate = +(new Date(params.cohort.startDate));
        const cohortEndDate = +(new Date(params.cohort.endDate));
        const customStartDate = +(new Date(params.customStartDate));
        const customEndDate = +(new Date(params.customEndDate));
        const customDateCohort = {
            startDate: customStartDate,
            endDate: customEndDate,
            cohort: 'custom',
            name: 'Custom Range',
        };
        const isCustomDateRange = customStartDate && customEndDate;

        return {
            tab: 1,
            kpiProgression: params.progression,
            kpiType: params.kpi,
            kpiGroup: params.groupBy,
            kpiInterval: params.interval,
            kpiTrend: params.trendingType,
            firmographic: params.firmographic,
            impressions: 90,
            graphSeriesType: params.trendingChartStyle,
            accountListFilter: params.accountLists.join(','),
            cohort: isCustomDateRange ? customDateCohort.cohort : params.cohort.cohort,
            name: isCustomDateRange ? customDateCohort.name : params.cohort.name,
            startDate: isCustomDateRange ? customStartDate : cohortStartDate,
            endDate: isCustomDateRange ? customEndDate : cohortEndDate,
        };
    }

    private getHost(subdomain: string): string {
        const host = CONSTS.TERMINUS_GOTO_MAP[this.$window.location.host];
        let hostInterpolated = host ? host.replace('{{subdomain}}', subdomain) : this.$window.location.host;
        if (subdomain === 'data') {
            hostInterpolated = hostInterpolated.replace('4300', '4600');
        }
        return this.$window.location.protocol + '//' + (hostInterpolated);
    }

    isFeatureEnabled = (featureName: string): boolean => {
        if (!featureName) {
            return true;
        }
        const parts = featureName.split('-');

        if (parts.length != 3) {
            return false;
        }
        return this.$rootScope.orgConfig.features
            && this.$rootScope.orgConfig.features[parts[1]]
            && this.$rootScope.orgConfig.features[parts[1]][parts[2]] === 'canAccess';
    };

    goToTerminusHub = (featureFlag: string, reportName: string, subdomain = 'measurement') => {
        const url = featureFlag && this.isFeatureEnabled(featureFlag)
            ? this.getTerminusHubUrlByReportName(reportName, subdomain)
            : this.$rootScope.shref(reportName);
        this.checkAuthToken(url);
    };

    goToNativeTerminusHubReport = (reportName: string, subdomain = 'measurement') => {
        const url = reportName
            ? this.getTerminusHubUrlByReportName(reportName, subdomain)
            : '';
        this.checkAuthToken(url);
    }

    getTerminusHubUrlByReportName = (reportName: string, subdomain = 'measurement') => {
        return this.getUrl(this.getHost(subdomain), CONSTS.TERMINUS_HUB_ENDPOINT_MAP[reportName]);
    };

    goToScorecardTrendingKpi = (params) => {
        const trendingQueryString = this._.queryString(this.formatQueryParams(params));
        const trendingUrl = `${this.getTerminusHubUrlByReportName(CONSTS.TerminusHubReports.Scorecard)}?${trendingQueryString}`;
        return this.checkAuthToken(trendingUrl);
    };

    constructor(

    public $rootScope,
        public $window,
        public noty,
        public authSrvc,
        public _: any,
        public storageSrvc,
        public Constants,
        public jwtInterceptor,
        public environmentSrvc,
    ) { }
}

export const SRVC_TERMINUS_HUB = 'terminusHubService';
app.service(SRVC_TERMINUS_HUB, TerminusHubService);
