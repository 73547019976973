import { module as angularModule } from 'angular';

import { APP_BRIGHTFUNNEL } from '../../constants';
import * as CONSTS from './consts';
import {
    ITokenResponse,
    IIsEnabledOrgRequest,
    IIsEnabledOrgResponse,
} from './interface';

const app = angularModule(APP_BRIGHTFUNNEL);

export class HubspotService {
    static $inject: string[] = [
        '$http',
        'authSrvc',
        'environmentSrvc',
    ];

    private getUrl = (host: string, path: string) => `${host}${path}`;

    authorize() {
        return this.environmentSrvc.getVariable(CONSTS.HUBSPOT_CONFIGURATION_URL_ENV).then((host: string) => {
            const hubspotIsEnabledEndpoint = this.getUrl(host, CONSTS.HUBPOST_ENDPOINT_MAP_AUTHORIZE);
            return this.$http.get(hubspotIsEnabledEndpoint, { withCredentials: false }).then((res: { data: ITokenResponse }) => {
                const TERMINUS_HUB = 'Terminus Hub';
                this.authSrvc.writeJwtToCookie(res.data.token);
                this.authSrvc.storeToken(TERMINUS_HUB);
                return res.data;
            });
        });
    }

    isEnabledOrg(params: IIsEnabledOrgRequest) {
        return this.environmentSrvc.getVariable(CONSTS.HUBSPOT_CONFIGURATION_URL_ENV).then((host: string) => {
            const hubspotIsEnabledEndpoint = this.getUrl(host, CONSTS.HUBPOST_ENDPOINT_MAP_IS_ENABLED_ORG);
            return this.$http.post(hubspotIsEnabledEndpoint, params, { withCredentials: false }).then((res: { data: IIsEnabledOrgResponse}) => {
                return res.data;
            });
        });
    }

    constructor(
        public $http,
        public authSrvc,
        public environmentSrvc,
    ) { }
}

export const SRVC_HUBSPOT = 'hubspotService';
app.service(SRVC_HUBSPOT, HubspotService);
