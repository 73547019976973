/* eslint-disable no-undef */
(function () {
    'use strict';
    angular.module('brightfunnel')
        .service('Colors', ['api', '$q', '_', '$rootScope', 'utilities', function (api, $q, _, $rootScope, utilities) {
            var colors       = ['blue', 'orange', 'green', 'red', 'purple', 'brown', 'pink', 'gray', 'yellow', 'turquoise'],
                cat20        = d3.scale.category20().range(),
                cat20Sorted  = {},
                //                groupsColors = [
                //                    '#a6cee3', '#05baba', '#1f78b4', '#196293', '#65747b', '#49565c', '#2c3539', '#14bda7', '#129080', '#0f9d58', '#55674d', '#b2df8a', '#33a02c', '#fb9a99', '#ff82ab', '#d662a8', '#e31a1c', '#db4437', '#871212', '#fdbf6f', '#ff7f00', '#ff6700', '#b592c6', '#734a9f', '#4a3763', '#ffff99', '#dfc212', '#b15928', '#8b5742', '#6f4534', '#3c3c3c', '#656565', '#888787'
                //                ],
                colorsMaster = [
                    '#175884',
                    '#1f77b4',
                    '#678ab9',
                    '#aec7e8',
                    '#be5b03',
                    '#ff7f0e',
                    '#f59b4c',
                    '#ffbb78',
                    '#1b811b',
                    '#2ca02c',
                    '#6dbf6d',
                    '#98df8a',
                    '#b11414',
                    '#d62728',
                    '#fc4a4b',
                    '#d6616b',
                    '#ff9896',
                    '#73459d',
                    '#9467bd',
                    '#b386db',
                    '#693b31',
                    '#8c564b',
                    '#ad7265',
                    '#c49c94',
                    '#a54a95',
                    '#ce6dbd',
                    '#e377c2',
                    '#f7b6d2',
                    '#362f2f',
                    '#4a4949',
                    '#7f7f7f',
                    '#c7c7c7',
                    '#73740f',
                    '#999a1f',
                    '#bcbd22',
                    '#dbdb8d',
                    '#097783',
                    '#1499a7',
                    '#17becf',
                    '#9edae5'
                ],
                colorMap,
                groupsColors,
                that = this;

            (function () {
                var primaries   = [],
                    secondaries = [];

                angular.forEach(cat20, function (color, i) {
                    if (i % 2 === 0) {
                        primaries.push(color);
                    } else {
                        secondaries.push(color);
                    }
                });

                angular.forEach(colors, function (color, i) {
                    cat20Sorted[color] = {
                        primary: primaries[i],
                        secondary: secondaries[i]
                    };
                });
            })();

            this.get = function (color, level) {
                if (!color && !level) {
                    return cat20;
                } else if (color && !level) {
                    return cat20Sorted[color];
                } else if (color && level) {
                    return cat20Sorted[color][level];
                }
            };

            this.scale = function (scale) {
                return ( scale == '10' ? d3.scale.category10() : scale == '20' ? d3.scale.category20() : scale == '20b' ? d3.scale.category20b() : d3.scale.category20() );
            };
            this.setColorMap = function () {
                var defer = $q.defer();
                if(!colorMap) {
                    utilities.waitFor(function(){
                        return _.get($rootScope,'revenueGroups');
                    }).then(function(d){
                        that.colorSize(d);
                        colorMap = d3.scale.ordinal()
                            .domain(d)
                            .range(groupsColors);
                        defer.resolve(colorMap);
                    });
                } else {
                    defer.resolve(colorMap);
                }
                return defer.promise;
            };

            this.getColorMap = function () {
                return colorMap;
            };

            this.colorSize = function(groups) {
                var a = angular.copy(colorsMaster),
                    size = 4,
                    arrays = _.chunk(a,size),
                    l = groups.length,
                    cut = l <= 10 ? [2,-1] : l <= 20 ? [1,3] : l <= 30 ? [1,4] : false;

                angular.forEach(arrays,function(colorGroup,i){
                    if(cut) {
                        arrays[i] = colorGroup.slice.apply(colorGroup,cut);
                    }
                });

                groupsColors = _.flatten(arrays);
            };
        }]);
}());
