/**
 * Salesforce Oauth Controller.
 */
(function () {
    'use strict';

    app
        .controller('sfdcCtrl', sfdcCtrl);

    sfdcCtrl.$inject = ['$scope', '$filter', 'SfdcConstants', 'sfdcSrvc'];

    function sfdcCtrl($scope, $filter, SfdcConstants, sfdcSrvc) {

        activate();

        /**
         * Initialize the scope
         */
        function activate() {
            $scope.title = SfdcConstants.LABELS.TITLE;
            $scope.tab1 = SfdcConstants.LABELS.TAB1;
            $scope.tab2 = SfdcConstants.LABELS.TAB2;
            $scope.tab3 = SfdcConstants.LABELS.TAB3;
            $scope.tab4 = SfdcConstants.LABELS.TAB4;
            $scope.tab5 = SfdcConstants.LABELS.TAB5;
            $scope.placeholder = SfdcConstants.LABELS.PLACEHOLDER;
            $scope.table = SfdcConstants.LABELS.TABLE_NAME;
            $scope.idField = SfdcConstants.LABELS.ID;
            $scope.orgObj = { id: 0 };
            $scope.tableInput = { value: "" };
            $scope.idInput = { value: "" };
            $scope.identifyContent = "";
            $scope.describeFieldsContent = "";
            $scope.describeTableContent = "";
            $scope.showRowContent = "";
            $scope.userInfoContent = "";

            /**
             * Load organizations and order them alphabetically.
             */
            sfdcSrvc.getOrganizationsList().then(function (response) {
                $scope.orgs = $filter('orderBy')(response.data, 'name');
            }, function (rejection) {
                // eslint-disable-next-line no-console
                console.error(rejection);
            });
        }

        /**
         * Make a post request given a function as a param.
         * @param postFn The function to call
         * @param varField The variable in the scope to update
         * @param orgId The org ID to use
         * @param tableName The table name to use  (Optional)
         * @param id The ID to use (Optional)
         */
        function doAPost(postFn, varField, params) {
            var args = [];
            var hasErrors = false;
            params.forEach(function (param) {
                if ((typeof (param.value) === 'number' && param.value < 0) ||
                    (typeof (param.value) !== 'number' && !param.value)) {
                    $scope[varField] = { error: 'Param ' + param.key + ' is missing.' };
                    hasErrors = true;
                } else {
                    args.push(param.value);
                }
            });

            if (hasErrors) return;

            postFn.apply(this, args).then(function (response) {
                $scope[varField] = response.data;
            }, function (rejection) {
                $scope[varField] = rejection.data;
                // eslint-disable-next-line no-console
                console.error(rejection.data);
            });

        }

        /**
         * Triggered when the tab is selected.
         */
        $scope.tabIdentifyFn = function () {
            doAPost(sfdcSrvc.postIdentify,
                'identifyContent',
                [
                    { key: 'orgId', value: $scope.orgObj.id }
                ]);
        };

        /**
         * Triggered when the button is pressed in the directive.
         */
        $scope.tabDescribeFieldsFn = function () {
            doAPost(sfdcSrvc.postDescribeFields,
                'describeFieldsContent',
                [
                    { key: 'orgId', value: $scope.orgObj.id },
                    { key: 'tableName', value: $scope.tableInput.value }
                ]);
        };

        /**
         * Triggered when the button is pressed in the directive.
         */
        $scope.tabDescribeTableFn = function () {
            doAPost(sfdcSrvc.postDescribeTable,
                'describeTableContent',
                [
                    { key: 'orgId', value: $scope.orgObj.id },
                    { key: 'tableName', value: $scope.tableInput.value }
                ]);
        };

        /**
         * Triggered when the button is pressed in the directive.
         */
        $scope.tabUserInfoFn = function () {
            doAPost(sfdcSrvc.postUserInfo,
                'userInfoContent',
                [
                    { key: 'orgId', value: $scope.orgObj.id }
                ]);
        };

        /**
         * Triggered when the tab is selected.
         */
        $scope.tabShowRowFn = function () {
            doAPost(sfdcSrvc.postShowRow,
                'showRowContent',
                [
                    { key: 'orgId', value: $scope.orgObj.id },
                    { key: 'tableName', value: $scope.tableInput.value },
                    { key: 'id', value: $scope.idInput.value }
                ]);
        };
    }
})();
