app.service('personaDefaults', ['$rootScope', 'widgets', 'campaignPerformance', 'utilities', '$filter', 'personaMap', '_', function ($rootScope, widgets, campaignPerformance, utilities, $filter, personaMap, _) {
    var that = this;
    // used in dashbaord-trl to get persona based wigdet definition
    this.getDefaultWidgets = function (persona) {
        var person = persona ? persona : $rootScope.userData && $rootScope.userData.marketingRole ? _.find(personaMap.personRoleMap, { name: $rootScope.userData.marketingRole }) && _.find(personaMap.personRoleMap, { name: $rootScope.userData.marketingRole }).key : null,
            dashboardWidgets = {
                widgets: []
            };
        if (person === 'demandGen' || person === 'digitalMarketing') {
            if ($rootScope.userData.platform === 'full') {
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('leadsGenerated')), 'Leads This Q Vs. Last Year This Q', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('leadsGenerated')), 'Leads This Q Vs. Last Q', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('leadsGenerated')), 'Attributed Pipeline This Q Vs. Last Year This Q', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('leadsGenerated')), 'Attributed Pipeline This Q Vs. Last Q', person));
            }
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('cpList')), 'Top Types by Leads Sourced QTD', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('campaignsList')), 'Top Campaigns by Leads Sourced QTD', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('cpList')), 'Top Types by Attributed Pipeline QTD', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('campaignsList')), 'Top Campaigns by Attributed Pipeline QTD', person));
        } else if (person === 'marketingOps') {
            if ($rootScope.userData.platform === 'full') {
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('stagesSnapshot')), 'Last Full Week Stages Snapshot', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('stagesSnapshot')), 'Quarter To Date Stages Snapshot', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('cpList')), '1st Touch: Top Types by Revenue', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('cpList')), 'Last Touch: Top Types by Revenue', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('cpList')), 'Evenly Weighted: Top Types by Revenue', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('campaignsList')), '1st Touch: Top Campaigns By Revenue', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('campaignsList')), 'Last Touch: Top Campaigns By Revenue', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('campaignsList')), 'Evenly Weighted: Top Campaigns By Revenue', person));
            } else {
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('campaignsList')), 'Top Campaigns By Revenue', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('cpList')), 'Top Types by Revenue', person));
            }
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('accountsList')), 'Top Accounts by Engagement', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('opptyAnalysisList')), 'Top Forecasted Opptys This Quarter', person));
        } else if (person === 'contentMarketing') {
            if ($rootScope.userData.platform === 'full') {
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('leadsGenerated')), 'Leads Goal QTD', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('leadsGenerated')), 'Pipeline Goal QTD', person));
            }
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('campaignsList')), 'Last Full Quarter: Top Campaigns by Attributed Pipeline', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('campaignsList')), 'QTD: Top Campaigns by Attributed Pipeline', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('campaignsList')), 'Next Quarter: Top Campaigns by Forecasted Pipeline', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('opptyAnalysisList')), 'Top Opptys Created This Quarter', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('opptyAnalysisList')), 'Top Opptys Closed Won This Quarter', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('opptyAnalysisList')), 'Top Opptys Forecasted This Quarter', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('accountsList')), 'Top Accounts by Engagement', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('accountsList')), 'Top Accounts by Sales Activity', person));
        } else if (person === 'salesLeader') {
            if ($rootScope.userData.platform === 'full') {
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('leadsGenerated')), 'Pipeline Trend QTD', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('leadsGenerated')), 'Revenue Trend QTD', person));
            }
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('accountsList')), 'Top Accounts by Engagement', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('accountsList')), 'Top Accounts by Sales Activity', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('marketingImpact')), 'Attributed Pipeline by Quarter', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('marketingImpact')), 'Attributed Revenue by Quarter', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('opptyAnalysisList')), 'Top Opptys Created This Quarter', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('opptyAnalysisList')), 'Top Opptys Closed Won This Quarter', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('opptyAnalysisList')), 'Top Opptys Forecasted Next Quarter', person));

        } else if (person === 'fieldMarketing') {
            if ($rootScope.userData.platform === 'full') {
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('leadsGenerated')), 'Leads Goals QTD', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('leadsGenerated')), 'Pipeline Goal QTD', person));
                dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('stagesSnapshot')), 'Stages Snapshot QTD', person));
            }
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('campaignsList')), 'Last Full Quarter: Top Campaigns by Attributed Pipeline', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('campaignsList')), 'QTD: Top Campaigns by Attributed Pipeline', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('campaignsList')), 'Next Quarter: Top Campaigns by Forecasted Pipeline', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('opptyAnalysisList')), 'Top Opptys Created This Quarter', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('opptyAnalysisList')), 'Top Opptys Closed Won This Quarter', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('opptyAnalysisList')), 'Top Opptys Forecasted Next Quarter', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('accountsList')), 'Top Accounts by Engagement', person));
            dashboardWidgets.widgets.push(that.setPersonaAndName(widgets.unique(widgets.get('accountsList')), 'Top Accounts by Sales Activity', person));
        }

        return dashboardWidgets;
    };

    this.setPersonaAndName = function (widget, title, person) {
        /* if you want to change the title of a widget, use widget.userTitle */
        widget.userTitle = title;
        widget.persona = person;
        return widget;
    };

    // used in the individual widget ctrl to set the correct query definitions for the widget
    // if there is not a persona or a title, use default settings found in queryDefaultMap
    this.getQueryDefinitions = function (widget) {
        var title = widget.userTitle ? widget.userTitle.toLowerCase() : null;
        var persona = widget.persona ? widget.persona : null;
        if (persona === null) {
            persona = 'otherMarketing';
        }
        var map = personaMap.personaMap();
        var widgetQuery = persona && title && map[persona] && map[persona][title] ? map[persona][title] : personaMap.queryDefaultMap[widget.key];
        return widgetQuery;
    };

}]);
