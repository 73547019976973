app.controller('olfConfigCtrl', ['$scope', '$http', '$window', '$location', 'utilities', '$cookies', 'api', "$state", '_', function ($scope, $http, $window, $location, utilities, $cookies, api, $state, _) {
    $scope.cookies = $cookies;
    var loadLeadCompaniesCall;
    var loadLeadDomainsCall;
    var globalPropertyCall;
    var findOlfCountCall;

    $scope.demo = $cookies.get('BrightFunnelDemo') ? angular.fromJson($cookies.get('BrightFunnelDemo')) : { companyName: 'Acme Inc.' };

    // eslint-disable-next-line no-unused-vars
    function hideFeaturesAndPagesInGroup() {
        for (var p in $scope.configs.page) {
            $scope.configs.page[p].show = true;
        }
        for (var f in $scope.configs.feature) {
            $scope.configs.feature[f].show = true;
        }
        angular.forEach($scope.data.featureGroups[$scope.data.selectedFeatureGroupKey], function (f) {
            $scope.configs[f.type][f.child].show = false;
        });
    }

    function refresh_organizations() {
        $http.get('admin/organizations').success(function (data) {
            $scope.orgs.organizations = data;
        });
    }

    function applyMask(tab) {
        if ((tab == "permission" || tab == "users" || tab == "organization") && window.location.href.indexOf("stage.brightfunnel") > 1) {
            $scope.data.mask[tab] = true;
        } else if ((tab == "dataCycle" || tab == "olf" || tab == "waterfall") && $scope.data.stageActive && window.location.href.indexOf("app.brightfunnel") > 1) {
            $scope.data.mask[tab] = true;
        }
    }

    function noDupesWhenFull(master, dupes, type) {
        if (dupes[type] && master.length) {
            dupes[type].all.forEach(function (dupe) {
                var idx = master.findIndex(function (ele) { return dupe.item == ele.item; });
                if (idx > -1) {
                    master.splice(idx, 1);
                }
            });
            dupes[type].org.forEach(function (dupe) {
                var idx = master.findIndex(function (ele) { return dupe.item == ele.item; });
                if (idx > -1) {
                    master.splice(idx, 1);
                }
            });
        }
    }

    $scope.addIgnore = function (type) {
        $state.current.data.loading = true;
        var params = {
            items: $scope.data.olf["ignore_" + type].org,
            openItems: $scope.data[type],
            orgId: $scope.query.orgId,
            type: type
        };
        api.set("admin/add_ignore", params, true).then(function (data) {
            $scope.data.olf["ignore_" + type].org = data.data;
            $state.current.data.loading = false;
        });
    };

    $scope.getAccounts = function (val) {
        return $http.get('api/v1/account_names_like', {
            params: { likeName: val, orgId: $scope.query.orgId }
        }).then(function (response) {
            return response.data;
        });
    };

    $scope.addIgnoreAccount = function () {
        var exists = false;
        $scope.data.olf.ignore_account.forEach(function (a) {
            if (a.account_id == $scope.data.newAccount.id) {
                exists = true;
            }
        });
        if (!exists) {
            $state.current.data.loading = true;
            var params = { accountId: $scope.data.newAccount.id, accountName: $scope.data.newAccount.name, orgId: $scope.query.orgId };
            api.set("admin/add_ignore_account", params, false).then(function (data) {
                $scope.data.olf.ignore_account = data.data;
                $state.current.data.loading = false;
            });
        }
    };

    $scope.removeIgnoreItem = function (item, type) {
        $state.current.data.loading = true;
        var params = { id: item.id, type: type };
        // eslint-disable-next-line no-unused-vars
        api.set("admin/remove_ignore_olf", params, false).then(function (data) {
            $scope.data.olf["ignore_" + type].splice($scope.data.olf["ignore_" + type].indexOf(item), 1);
            $state.current.data.loading = false;
        });
    };

    $scope.findOlfCount = function () {
        if (findOlfCountCall) { findOlfCountCall.abort(); }
        $state.current.data.loading = true;
        var params = { threshold: $scope.data.olf.score_threshold, orgId: $scope.query.orgId };
        (findOlfCountCall = api.get("admin/find_olf_count", params, false, true)).then(function (data) {
            $scope.data.olf.threshold_count = data.data;
            $state.current.data.loading = false;
        });
    };

    $scope.updateOrgConf = function (save, module, field, type, value) {
        var params = { value: value, module: module, field: field, type: type, orgId: $scope.query.orgId };
        if (save) {
            // eslint-disable-next-line no-unused-vars
            api.set("admin/update_org_config", params, false).then(function (data) {
            });
        } else {
            params.delete = true;
            // eslint-disable-next-line no-unused-vars
            api.set("admin/update_org_config", params, false).then(function (data) {
                if (module == "olf" && type == "score_threshold") {
                    $scope.data.olf.score_threshold = 0;
                }
            });
        }
    };

    $scope.orgChanged = function () {
        if (loadLeadCompaniesCall) { loadLeadCompaniesCall.abort(); }
        if (loadLeadDomainsCall) { loadLeadDomainsCall.abort(); }
        $state.current.data.loading = true;
        $scope.data.company = [];
        $scope.data.domain = [];
        $scope.data.olf = null;
        $http.get('admin/org_configurations?orgId=' + $scope.query.orgId).success(function (data) {
            $scope.hidden = data.features;
            $scope.query.groupId = Object.keys(data.groups)[0];
            $scope.org_info = data.org_info;

            $scope.data.featureGroups = data.groups;
            $scope.data.users = data.users;
            $scope.data.org_influence_rules = data.influence_rules;
            $scope.data.olf = data.olf;
            $scope.data.oppty = data.oppty;
            $scope.data.attribution = data.attribution;

            if ($scope.configs.extraCampaignDateTypes[data.org_info.id]) {
                $scope.configs.campaignDateTypes = $scope.configs.defaultCampaignDateTypes.concat($scope.configs.extraCampaignDateTypes[data.org_info.id]);
            }

            noDupesWhenFull($scope.data.company, $scope.data.olf, "company");
            noDupesWhenFull($scope.data.domain, $scope.data.olf, "domain");

            $state.current.data.loading = false;
        });

        (loadLeadCompaniesCall = api.get('load_lead_companies', { orgId: $scope.query.orgId }, false, true)).then(function (data) {
            $scope.data.company = data.data;
            noDupesWhenFull($scope.data.company, $scope.data.olf, "company");
        });

        (loadLeadDomainsCall = api.get('load_lead_domains', { orgId: $scope.query.orgId }, false, true)).then(function (data) {
            $scope.data.domain = data.data;
            noDupesWhenFull($scope.data.domain, $scope.data.olf, "domain");
        });
    };

    $scope.clear_user_updates = function () {
        $scope.user_updates = {};
        $http.get('admin/users').success(function (data) {
            $scope.users = data;
            angular.forEach($scope.users, function (u) { u.updated_org_id = u.org_id; });
        });
    };

    (function init() {
        $scope.query = {
            orgId: 0,
            pageId: "app.campaigns.attribution",
            featureId: "ceff.model.toggle"
        };
        $scope.data = {
            featureGroups: {},
            selectedFeatureGroupKey: "",
            showEditFeatureGroup: false,
            mask: { permission: false, dataCycle: false, users: false, organization: false },
            stageActive: false,
            newAccount: "",
            ignoreType: "domain",
            ignoreOptions: ['domain', 'account', 'company'],
            domain: [],
            company: [],
            marketingRoles: [
                { value: "", ui: "" },
                { value: "demand generation", ui: "Demand Generation" },
                { value: "marketing operations", ui: "Marketing Operations" },
                { value: "field marketing", ui: "Field Marketing" },
                { value: "sales manager", ui: "Sales Manager" },
                { value: "executive", ui: "Executive" },
                { value: "other marketing", ui: "Other Marketing" }
            ],
            activeOrgsOnly: true
        };

        $scope.configs = {
            page: {},
            feature: {
                "ceff.model.toggle": { key: "ceff.model.toggle", name: "Campaign Effectiveness Model Toggle", show: true },
                "tot.rev.predict": { key: "tot.rev.predict", name: "Total Revenue Predictions", show: true },
                "waterfallStages": { key: "waterfallStages", name: "Waterfall Stages", show: true },
                "disableInsights": { key: "disableInsights", name: "Insights", show: true },
                "path2Sale.touchesByCamp": { key: "path2Sale.touchesByCamp", name: "Path To Sale Campaign Touches", show: true },
                "dashboard beta": { key: "dashboard.beta", name: "dashboard beta", show: true }
            },
            org_influence_rules: { cio: [], lto: [] },
            defaultCampaignDateTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
            campaignDateTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
            opptyAmountType: ['ARR', 'MRR'],
            pipePostTouchType: ['ON', 'OFF'],
            extraCampaignDateTypes: { 16: ["Last Member Status Change"] }
        };


        function reduce() {
            // eslint-disable-next-line no-unused-vars
            var m = _.flatten($scope.subnav.map(function (c) {
                return c.children;
            }));
        }

        reduce();


        $scope.orgs = {
            new_organization_name: '',
            organization: []
        };

        angular.forEach(_.compact(_.flatten($scope.subnav.map(function (c) {
            return c.children;
        }))), function (s) {

            pushName(s);
            if (s.tabs) {
                // eslint-disable-next-line no-unused-vars
                angular.forEach(s.tabs, function (v, i) {
                    pushName(v);
                });
            }

            function pushName(obj) {
                $scope.configs.page[obj.name] = {
                    key: obj.name,
                    name: (obj.config.data.title ? obj.config.data.title : obj.config.data.staticTitle) + (obj.config.data.subtitle ? ': ' + obj.config.data.subtitle : ''),
                    show: true
                };
            }
        });
        // adding on manually that is not in the subnav
        $scope.configs.page["app.campaign.sfdc"] = { key: "app.campaign.sfdc", name: "Campaign SFDC data", show: true };


        $http.get('admin/internal_config').success(function (data) {
            var active = [];
            data.orgs.forEach(function (item) {
                if (item.active) {
                    active.push(item);
                }
            });
            $scope.configs.orgs = [{ id: 0, name: "--Select an org--" }].concat(active);
            $scope.configs.orgsActive = [{ id: 0, name: "--Select an org--" }].concat(active);
            $scope.configs.orgsAll = [{ id: 0, name: "--Select an org--" }].concat(data.orgs);
            $scope.configs.influence_rules = data.influence_rules;
        });

        (globalPropertyCall = api.get("globalProperty", { name: "stage.active" }, false, true)).then(function (data) {
            $scope.data.stageActive = data.data[0].value == "true";
        });

        $scope.$on('$destroy', function () {
            if (loadLeadCompaniesCall) { loadLeadCompaniesCall.abort(); }
            if (loadLeadDomainsCall) { loadLeadDomainsCall.abort(); }
            if (findOlfCountCall) { findOlfCountCall.abort(); }
            if (globalPropertyCall) { globalPropertyCall.abort(); }
        });

        applyMask("olf");
        refresh_organizations();
        $scope.clear_user_updates();
        $scope.show_unassigned_users = true;

    })();
}]);
