import {module as angularModule} from 'angular';
import {APP_BRIGHTFUNNEL} from '../../constants';

import {Loader} from './directives/loader/component';
import {RadioChoice} from './directives/radio-choice/component';
import {DropDown} from './directives/drop-down/component';
import {AccountListsSrvc} from './services/account-lists.service';
import {BoxChoice} from './directives/box-choice/component';
import {UploadFile} from './directives/upload-file/component';
import {CheckList} from './directives/check-list/component';
import {Note} from './directives/note/component';
import {ModalTable} from './directives/modal-table/component';
import {ModalInput} from './directives/modal-input/component';
import {ContextSrvc} from './services/context.service';
import {HttpSrvc} from './services/http.service';
import {FolderSrvc} from './services/folder.service';
import {UpdateFiltersSrvc} from './services/update-filters.service';
import {Subtitle} from './directives/subtitle/component';
import {BtnDropdown} from './directives/btn-dropdown/component';
import {CreateAccountListModalSrvc} from './services/create-account-list-modal.service';

const app = angularModule(APP_BRIGHTFUNNEL);

// Directives
app.directive('loader', Loader.Factory);
app.directive('radioChoice', RadioChoice.Factory);
app.directive('dropDown', DropDown.Factory);
app.directive('boxChoice', BoxChoice.Factory);
app.directive('uploadFile', UploadFile.Factory);
app.directive('checkList', CheckList.Factory);
app.directive('note', Note.Factory);
app.directive('modalTable', ModalTable.Factory);
app.directive('modalInput', ModalInput.Factory);
app.directive('subtitle', Subtitle.Factory);
app.directive('btnDropdown', BtnDropdown.Factory);

// Services
app.service('accountListsSrvc', AccountListsSrvc);
app.service('folderSrvc', FolderSrvc);
app.service('csvContextSrvc', ContextSrvc);
app.service('httpSrvc', HttpSrvc);
app.service('csvUpdateGlobalFilters', UpdateFiltersSrvc);
app.service('createAccountListModalSrvc', CreateAccountListModalSrvc);
