import {
    element as angularElement,
    IAttributes,
    IAugmentedJQuery,
    IDirective,
    INgModelController,
    IScope
} from 'angular';
import * as COMPONENT_HTML from './component.html';
import './component.less';
import {INVALID_NUMBER_OF_FILES, TOTAL_SIZE_ERROR} from '../../constants/constants';

class UploadFileCtrl {
    static $inject: Array<string> = ['$scope'];
    static MAX_SIZE = 2048; // 2MB ->  +/- 56K dynamics CRM Account IDs

    private highlight: boolean;

    public constructor(private $scope: IScope) {
        this.highlight = false;
        return;
    }

    public readFile(input: any): void {
        const files: any = input.files;
        if (files.length !== 1) {
            this.upload(null, INVALID_NUMBER_OF_FILES);
            return;
        }

        const fsize: number = files.item(0).size;
        const totalSize: number = Math.floor(fsize / 1024);
        if (totalSize >= UploadFileCtrl.MAX_SIZE) {
            this.upload(null, TOTAL_SIZE_ERROR);
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            this.$scope.$ctrl.boxLabel = files.item(0).name;
            this.upload(reader.result, null);
            input.value = null;
            return;
        };

        reader.onerror = (error: any) => {
            reader.abort();
            this.upload(null, error);
            input.value = null;
            return;
        };
        reader.readAsText(files[0]);
    }

    private upload(text: ArrayBuffer | string, error: string): void {
        if (this.$scope.$ctrl.fileUploaded) {
            this.$scope.$ctrl.fileUploaded()(text, error);
        }
    }
}

export class UploadFile implements IDirective {
    public restrict = 'E';
    public controller: any = UploadFileCtrl;
    public controllerAs = '$ctrl';
    public bindToController = true;
    public template: any = COMPONENT_HTML;
    public scope: IScope = {
        boxLabel: '=',
        buttonLabel: '=',
        fileType: '=',
        fileUploaded: '&',
    };

    public constructor() {
        return;
    }

    public static Factory(): IDirective {
        return new UploadFile();
    }

    public link = (scope: IScope, elem: IAugmentedJQuery, attributes: IAttributes, ctrl: INgModelController) => {
        for (const eventName of ['dragenter', 'dragover']) {
            elem.bind(eventName, (evt: any) => {
                evt.stopPropagation();
                evt.preventDefault();
                scope.$apply(() => {
                    ctrl.highlight = true;
                });
            });
        }
        for (const eventName of ['dragleave', 'drop']) {
            elem.bind(eventName, (evt: any) => {
                evt.stopPropagation();
                evt.preventDefault();
                scope.$apply(() => {
                    ctrl.highlight = false;
                });
            });
        }
        elem.bind('drop', (evt: any) => {
            evt.stopPropagation();
            evt.preventDefault();
            const target = evt.dataTransfer;
            ctrl.readFile(target);
        });

        const input = angularElement(elem[0].querySelector('input'));
        input.bind('change', (evt: any) => {
            evt.stopPropagation();
            evt.preventDefault();

            const target = evt.target;
            ctrl.readFile(target);
        });
    };
}
