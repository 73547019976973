(function () {
    'use strict';

    app.factory('lazyLoadSrvc', lazyLoadSrvc);

    lazyLoadSrvc.$inject = ['$timeout'];

    /**
     * Service to kpi configs.
     */
    function lazyLoadSrvc($timeout) {
        /**
         * This service
         * @type {{}}
         */
        var service = {};

        service.lazyList = function(loadFunction, event, metaObj) {
            var el = event.target;
            if (el.offsetHeight + el.scrollTop >= el.scrollHeight &&
                !metaObj.endOfList) {

                var lazyL = loadFunction();
                metaObj.endOfList = true;
                if (lazyL && (typeof lazyL === 'function')) {
                    if (!metaObj.loading) {
                        lazyL(metaObj.params);
                    }
                    metaObj.scope.$apply(function () {
                        metaObj.loading = true;
                    });
                    $timeout(function () {
                        metaObj.loading = false;
                        el.scrollTop = el.scrollTop - 10;
                    }, 1000);
                }
            } else if (el.offsetHeight + el.scrollTop < el.scrollHeight) {
                metaObj.endOfList = false;
            }

            return metaObj;
        };

        return service;
    }
})();
