/* eslint-disable no-undef */
app.service('exportService', ['$b', '$window', function ($b, $window) {
    this.expFunc = function (elem) {
        var model = {
            title: 'Chart Image Export',
            id: 'scenario-wizard',
            templateUrl: 'export-image.html',
            width: '600px',
            buttons: [{
                'html': '<i class="icon-download space-right"></i>Download',
                'ng-disabled': 'disabled(wizard.current)',
                'ng-click': 'export()',
                'class': 'btn btn-sm btn-primary pull-right'
            }, {
                'text': 'Cancel',
                'ng-click': '$modal.closeModal()'
            }],
            x: true,
            maxed: false,
            controller: ['$scope', 'api', 'utilities', 'noty', function ($scope, api, utilities, noty) {
                var original = elem;

                $scope.data = {
                    imageOnly: true,
                    annotation: null,
                    clone: original.clone(),
                    insightTypes: [{
                        cls: 'info-circle',
                        key: 'info'
                    }, {
                        cls: 'thumbs-o-up',
                        key: 'success'
                    }, {
                        cls: 'warning',
                        key: 'danger'
                    }],
                    size: 20,
                    align: 'center',
                    bold: 'bold'
                };

                $scope.data.insightType = $scope.data.insightTypes[0];

                $scope.export = function () {
                    // if($scope.data.imageOnly) {
                    //var preview = $($scope.$modal.$elem).find('#img-preview');
                    //Doing plain SVG for now because that's all that's working with Chrome Canary
                    //var preview = $($scope.$modal.$elem).find('#img-preview');
                    $('#modal-body-wrapper').addClass('show-all');
                    api.export.getScreenshot('img-preview', 'png', $scope, noty, api).then(function () {
                        $scope.$modal.screenshotLoading = false;
                        $scope.$modal.closeModal();
                        // only needed the below timeout for when dev tools are open
                        setTimeout(function () {
                            $($window).resize();
                        }, 500);
                    });
                    // }
                    // else {
                    // 	api.savePdfAsImg(scope.exportData());
                    // }
                };

                $scope.loadPreview = function () {
                    var preview = $($scope.$modal.$elem).find('#img-preview');
                    var temp = $scope.data.clone; //original.clone()
                    var svg = temp.find('svg');

                    $scope.$modal.width = original.width() + 80;
                    $(preview).append(svg[0]);
                    // this is for pages that don't have svg's or charts, therefore we need to use
                    // html to canvg to convert html to a canvas before appending to preview
                    // when you comment this back in, move the above two lines below (modal.width and preview.append)
                    /*
	                svg.each(function() {
	                	if(!this.hasAttribute('height') && !this.hasAttribute('width')) {
	                	    var w = $(this).width(),
	                	        h = $(this).height();
	                	    $(this).attr('height',parseInt(h)).attr('width',parseInt(w));
	                	}
	                	var parent = $(this).parent();
	                	var chartCanvas = api.convertChart($(this), parent);
	                	$(this).replaceWith($(chartCanvas));
	                });
	                */
                    /*
                     html2canvas(temp[0]).then(function(canvas) {
                         $(temp).replaceWith(canvas);
                     });
                     */
                };
            }]
        };
        $b.modal(model);
    };
}]);
