import { module as angularModule } from 'angular';
import { APP_BRIGHTFUNNEL } from '../../constants';
import {TokenManagerCtrl} from './token-manager-ctrl';
import {OrganizationsSrvc} from './services/organizations-srvc';
import {TokenManagerSrvc} from './services/token-manager-srvc';
import {OrganizationSelector} from './directives/organization-selector/component';
import {SettingsHeader} from './directives/settings-header/component';
import {ImageCard} from './directives/image-card/component';
import {ImageCardSwitch} from './directives/image-card-switch/component';
import {SettingsButton} from './directives/settings-button/component';
// Directives
import './directives/organization-selector/component.less';
import './directives/settings-header/component.less';
import './directives/image-card/component.less';
import './directives/image-card-switch/component.less';
import './directives/settings-button/component.less';

const app = angularModule(APP_BRIGHTFUNNEL);

// Directives
app.controller('tokenManagerConfigCtrl', TokenManagerCtrl);
app.service('organizationsSrvc', OrganizationsSrvc);
app.service('tokenManagerSrvc', TokenManagerSrvc);
app.directive('organizationSelector', OrganizationSelector.Factory);
app.directive('settingsHeader', SettingsHeader.Factory);
app.directive('imageCard', ImageCard.Factory);
app.directive('imageCardSwitch', ImageCardSwitch.Factory);
app.directive('settingsButton', SettingsButton.Factory);
