/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
app.directive('legend', function ($timeout, $compile) {
    return {
        restrict: 'A',

        link: function (scope, element, attrs, ctrl) {
            var legendItemsInColumn,
                legendAreaControls,
                paging = 14,
                parentHeight,
                legendArea,
                legendElementHeight,
                legendClip,
                shift = 0,
                legendElement,
                legendColumnWidth,
                columnsCount,
                defs,
                parentWidth = element[0].clientWidth;

            scope.$on('update-legend', function (event, args) {
                parentWidth = element[0].clientWidth;
                legendElementHeight = 30;
                parentHeight = args.height;
                paging = Math.floor(parentHeight / legendElementHeight);
                legendColumnWidth = args.width / 2;
                d3.select('.legend-area-controls').remove();
                legendAreaControls = d3.select(element[0]).select('svg').append('g')
                    .attr({
                        class: 'legend-area-controls'
                    })
                    .style(
                        {
                            display: 'none'
                        }
                    );

                legendItemsInColumn = args.labels.length > paging * 2 ? Math.floor(args.labels.length / 2) : paging - 1;
                args.labels.length <= paging * 2
                    ? legendAreaControls.style({ display: 'none' })
                    : legendAreaControls.style({ display: 'block' });

                if (d3.select('defs').empty()) {
                    defs = d3.select(element[0]).select('svg').insert("defs");
                }

                d3.select('#legend-clip').remove();
                columnsCount = args.labels.length > paging ? 2 : 1;
                legendArea = d3.select(element[0]).select('.legend-area');
                legendClip = d3.select('defs').append("clipPath")
                    .attr("id", "legend-clip")
                    .append("rect")
                    .attr("id", "clip-rect")
                    .attr("x", 0)
                    .attr("y", 0)
                    .attr("width", columnsCount * 150)
                    .attr("height", args.height);
                legendAreaControls.attr({
                    transform: 'translate(' + [parentWidth - (columnsCount * 150) / 2, 0] + ')'
                });

                var legendAreaWidth = args.width;
                scope.legendAreaWrapper.attr({
                    transform: 'translate(' + [parentWidth - columnsCount * 150, 40] + ')'
                });
                legendArea.attr({ transform: 'translate(0, 0)' });

                var legendAreaUpButton = legendAreaControls.append('g').attr({ class: 'up-button' });
                legendAreaUpButton.attr({ transform: 'translate(0,20)' });
                legendAreaUpButton.append('circle').attr({
                    cx: 0,
                    cy: 0,
                    r: 10
                });
                legendAreaUpButton.append('polygon')
                    .attr(
                        {
                            fill: '#fff',
                            points: '-0.002,4.503 8.094,4.503 4.776,1.198 5.481,0.496 10.002,5 5.481,9.504 4.776,8.802 8.094,5.497 -0.002,5.497',
                            transform: 'translate(-5,5) rotate(-90)'

                        }
                    );


                var legendAreaDownButton = legendAreaControls.append('g').attr({ class: 'down-button' });
                legendAreaDownButton.attr({ transform: 'translate(' + [0, parentHeight + 60] + ')' });
                legendAreaDownButton.append('circle').attr({
                    cx: 0,
                    cy: 0,
                    r: 10
                });
                legendAreaDownButton.append('polygon')
                    .attr(
                        {
                            fill: '#fff',
                            points: '-0.002,4.503 8.094,4.503 4.776,1.198 5.481,0.496 10.002,5 5.481,9.504 4.776,8.802 8.094,5.497 -0.002,5.497',
                            transform: 'translate(5,-5) rotate(90)'

                        }
                    );

                legendAreaDownButton
                    .on('click', _scroll)
                    .on('mouseenter', function () {
                        d3.select(this).select('circle')
                            .transition()
                            .duration(100)
                            .attr({ r: 12 });
                    })
                    .on('mouseleave', function () {
                        d3.select(this).select('circle')
                            .transition()
                            .duration(100)
                            .attr({ r: 10 });

                    });
                legendAreaUpButton
                    .on('click', _scroll)
                    .on('mouseenter', function () {
                        d3.select(this).select('circle')
                            .transition()
                            .duration(100)
                            .attr({ r: 12 });
                    })
                    .on('mouseleave', function () {
                        d3.select(this).select('circle')
                            .transition()
                            .duration(100)
                            .attr({ r: 10 });

                    });


                function _scroll() {
                    var button = this;
                    legendArea
                        .transition()
                        .attr({
                            transform: function () {
                                var maxShift = (legendItemsInColumn * legendElementHeight - legendClip.attr('height')) / legendElementHeight + 1;
                                d3.select(button).classed('up-button') ? shift++ : shift--;
                                if (maxShift <= (shift + 1) * -1) {
                                    shift = -maxShift;
                                }
                                if (-shift <= 0) {
                                    shift = 0;
                                }
                                var y = _getXYFromTranslate(d3.select(this).attr('transform'))[1];
                                return 'translate(' + [0, shift * legendElementHeight] + ')';
                            }
                        });
                }

                legendArea.selectAll('.legend-element').remove();

                legendElement = legendArea
                    .selectAll('.legend-element')
                    .data(args.labels)
                    .enter()
                    .append('g')
                    .attr({
                        class: 'legend-element',
                        opacity: 0,
                        'element-id': function (d, i) { return d.id; },
                        transform: function (d, i) {
                            var horizontalShift = 0,
                                verticalShift = 0;
                            if (columnsCount == 2 && i > legendItemsInColumn) {
                                horizontalShift = legendColumnWidth;
                                verticalShift = (legendItemsInColumn + 1) * legendElementHeight;
                            }
                            return 'translate(' + [0 + horizontalShift, legendElementHeight * i - verticalShift] + ')';

                        }
                    });
                legendElement
                    .append('rect')
                    .style({
                        fill: function (d, i) {
                            return d.color;
                        }
                    })
                    .attr({
                        class: 'legend-background-rect',
                        width: legendColumnWidth,
                        height: legendElementHeight,
                        opacity: 0

                    });

                legendElement
                    .append('text')
                    .text(function (d, i) {
                        d.wrapped = d.label.length >= 14;
                        return d.label.trunc(14) || 'Untitled';
                    })
                    .attr(
                        {
                            transform: function (d, i) { return 'translate(' + [35, legendElementHeight / 2 + 4] + ')'; },
                            class: 'legend-label'
                        }
                    );
                legendElement
                    .append('text')
                    //.text('')
                    .text('')
                    .attr(
                        {
                            transform: 'translate(' + [legendColumnWidth - 15, legendElementHeight / 2 + 4] + ')',
                            class: 'visibility-icon icon-eye'
                        })
                    .style({
                        'text-anchor': 'end'
                    });
                legendElement
                    .append('circle')
                    .style({
                        fill: function (d, i) {
                            return d.color;
                        }
                    })
                    .attr(
                        {
                            r: 0,
                            cx: 20,
                            cy: legendElementHeight / 2,
                            opacity: 1
                        }
                    )
                    .transition()
                    .delay(function (d, i) {
                        return 1000 + 50 * i;
                    })
                    .attr(
                        {
                            r: 4
                        }
                    );
                legendElement.each(function () {
                    var datum = d3.select(this).datum();
                    if (datum.wrapped) {
                        addTooltip(d3.select(this));
                    }
                    function addTooltip(legendItem) {
                        legendItem
                            .attr({
                                dy: 0,
                                'uib-tooltip': datum.label,
                                'tooltip-placement': 'top',
                                'tooltip-append-to-body': 'true'
                            });
                        $compile(legendItem.node())(scope);
                    }

                });
                legendElement
                    .transition()
                    .delay(function (d, i) {
                        return 500 + 50 * i;
                    })
                    .attr('opacity', 1);
                $timeout(function () {
                    legendElement
                        .on('mouseenter', function (d, i) { scope.$broadcast('show-tooltip', d); })
                        .on('mousemove', function (d, i) { scope.$broadcast('move-tooltip', { d: d, i: i }); })
                        .on('mouseleave', function (d, i) { scope.$broadcast('hide-tooltip', { d: d, i: i }); });
                    // .on('click', function (d, i) {scope.$broadcast('toggle-campaign', d)})
                }, 2000);
            }
            );
            function _getXYFromTranslate(translateString) {
                var split = translateString.split(",");
                var x = split[0] ? ~~split[0].split("(")[1] : 0;
                var y = split[1] ? ~~split[1].split(")")[0] : 0;
                return [x, y];
            }

        }
    };
});
