/* eslint-disable no-undef */
app.factory('userModels', ['$rootScope', '_', function ($rootScope, _) {
    var models = [];
    _.forEach($rootScope.orgConfig.attributionModels, function (model, key) {
        if (model.active) {
            models.push({ label: model.custom_name, name: model.custom_name, value: model.custom_name, title: model.custom_name, key: key });
        }
    });
    return models;
}]);
