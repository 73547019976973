import {copy as angularCopy} from 'angular';

import {IScope} from 'angular';
import {FolderSrvc} from './folder.service';

export class UpdateFiltersSrvc {

    static $inject: Array<string> = ['$rootScope', 'folderSrvc', 'filters'];

    public constructor(private $rootScope: IScope,
                       private folderSrvc: FolderSrvc,
                       private filters: any,
    ) {
        return;
    }

    public updateAccountListsFilters(): Promise<void> {
        return this.folderSrvc.getFolders().then((response: any) => {
            const selectedIds: any = this.filters.getSelectedAccountLists(this.$rootScope.accountLists.folders);
            this.$rootScope.accountLists.folders = angularCopy(response.data);
            this.filters.initializeFolders(this.$rootScope.accountLists, selectedIds, 'save');
        });
    }
}
