app.directive('stackedBar', ['bfChart', '$timeout', '$document', function (bfChart, $timeout, $document) {
    return {
        restrict: 'EA',
        require: 'ngModel',
        link: function (scope, elem, attrs) {
            var model = scope.$eval(attrs.ngModel);

            if (!model || angular.isArray(model.data) && !model.data.length) return;

            model.margin = {
                top: 12,
                left: 78,
                right: 24,
                bottom: 64
            };

            model.init = function (scope, elem, chart) {
                /**
                * WHEN: This fires after the basic chart elements have events have been added to the page
                * Init function only fires once period and should be used to modify or set up variables uses in your modal. This function does not rerun when lazy reload takes place.
                *
                **/
                chart.stack = d3.layout.stack();
                $document.click(function (e) {
                    "use strict";
                    if (e.target.classList.contains('legend-background-rect')) {
                        return;
                    }
                    chart.chartArea.selectAll('.barbox')
                        .classed('faded', false)
                        .classed('active', false);
                    d3.selectAll('.legend-element')
                        .classed('active', false);


                });
                // chart.legend.mouseenter = function (object, model) {
                //     "use strict";
                //     var datum = d3.select(object).datum();
                //     console.log('stackedBarChart.js > chart.legend.mouseenter')
                // };
                // chart.legend.mouseleave = function (object, model) {
                //     "use strict";
                //     var datum = d3.select(object).datum();
                //     console.log('stackedBarChart.js > chart.legend.mouseleave')
                // };
                // eslint-disable-next-line no-unused-vars
                chart.legend.click = function (object, model) {
                    "use strict";
                    var datum = d3.select(object).datum();
                    var legendElement = d3.select(object),
                        currentState = legendElement.classed('active');

                    d3.selectAll('.legend-element')
                        .classed('active', false);

                    legendElement
                        .classed('faded', false)
                        .classed('active', !currentState);

                    var isSelected = legendElement.classed('active');
                    chart.chartArea.selectAll('.barbox')
                        .classed('faded', false)
                        .classed('active', false);
                    chart.chartArea.selectAll('.barbox[data-index="' + datum.index + '"]')
                        .classed('active', isSelected);
                    chart.chartArea.selectAll('.barbox:not(.active)')
                        .classed('faded', isSelected);

                };
            };

            model.update = function (scope, elem, chart) {
                /**
                *
                * The update function should be used defining data being bound within your chart and does get rerun: when lazy resize fires and also when the data in your chart changes
                *
                **/
                chart.ignore = true;

                if (chart.updateStart && angular.isFunction(chart.updateStart)) {
                    chart.updateStart(chart);
                }

                /* from 0 to the largest x value */
                chart.axis.x.scale = d3.scale.linear()
                    /* domain is basically the data for the actual values that will go in the axis */
                    .domain(chart.axis.x.domain(chart))
                    /* now define the range which is issentially it's size on the screen */
                    .range([0, chart.width]);

                chart.axis.y.scale = d3.scale.ordinal()
                    .domain(chart.axis.y.domain(chart))
                    .rangeRoundBands([0, chart.height], .3);

                /*==========  define the axis with d3  ==========*/
                chart.axis.x.axis = d3.svg.axis()
                    .scale(chart.axis.x.scale)
                    .orient('bottom')
                    .tickFormat(chart.axis.x.tick.format);


                chart.axis.y.axis = d3.svg.axis()
                    .scale(chart.axis.y.scale)
                    .orient('left');

                /*==========  now attach the scales we've made to their respective axis elements  ==========*/
                chart.axis.x.elem.call(chart.axis.x.axis);
                chart.axis.y.elem.call(chart.axis.y.axis);


                /*==========  select chart area and bind data  ==========*/
                chart.chartArea.selectAll('.barbox')
                    .remove()
                    .data(chart.data)
                    .enter()
                    .append('g')
                    .style('fill', function (d, i) {
                        return chart.colors(i);
                    })
                    .attr({
                        class: 'barbox',
                        'data-index': function (d, i) { return i; }
                    });

                chart.chartArea.selectAll('.barbox').selectAll('rect')
                    .remove()
                    .data(function (d) {
                        return d;
                    })
                    .enter()
                    .append('rect')
                    .attr('class', 'bar')
                    .attr('x', function (d) {
                        return chart.axis.x.scale(d.x0);
                    })
                    // eslint-disable-next-line no-unused-vars
                    .attr('y', function (d, i) {
                        return chart.axis.y.scale(d.y);
                    })
                    // eslint-disable-next-line no-unused-vars
                    .attr('height', function (d) {
                        return chart.axis.y.scale.rangeBand();
                    })
                    .attr('width', function (d) {
                        return chart.axis.x.scale(d.x);
                    })
                    .on('mouseover', function (d) {
                        var xPos = (chart.axis.x.scale(d.x) / 2 + (chart.axis.x.scale(d.x0) + elem.offset().left + chart.margin.left)) - 100;
                        var yPos = (chart.axis.y.scale.rangeBand() * 0.9) + (chart.axis.y.scale(d.y) + elem.offset().top + chart.margin.top);

                        chart.tooltip.elem
                            .style('left', xPos + 'px')
                            .style('top', yPos + 'px')
                            .html(chart.tooltip.content(chart, d));

                        //number "stage" leads or opt created in cohort converted to "ending stage" in same quarter

                        chart.tooltip.elem.style('display', 'block').style('opacity', '1');
                    })
                    .on('mouseout', function () {
                        chart.tooltip.elem.style('display', 'none').style('opacity', '0');
                    });

                if (chart.barValues && angular.isFunction(chart.barValues)) {
                    chart.chartArea.selectAll('.barbox').selectAll('text')
                        .data(function (d) {
                            return d;
                        })
                        .enter()
                        .append('text')
                        .attr('fill', 'white')
                        // eslint-disable-next-line no-unused-vars
                        .attr('y', function (d, i) {
                            return chart.axis.y.scale(d.y) + ((chart.axis.y.scale.rangeBand() / 2) + 4);
                        })
                        .attr('x', function (d) {
                            var s = String(d.x) + '%';
                            return chart.axis.x.scale(d.x0) + (chart.axis.x.scale(d.x) / 2 - ((s.length / 2) * 6));
                        })
                        .attr('style', 'font-weight:bold;')
                        .text(chart.barValues);
                }

                /*==========  insert the legend box  ==========*/
                if (chart.legend) {
                    /*
                                        chart.chartArea.append('rect')
                                            .attr('fill', 'white')
                                            .attr('width', chart.legend.width)
                                            .attr('height', 30 * chart.data.length)
                                            .attr('x', chart.width + chart.margin.left)
                                            .attr('y', 0)
                                            .attr('class','legend');
                    */

                    /*
                                        if(chart.legend.series && angular.isFunction(chart.legend.series)) {
                                            chart.legend.series(chart).forEach(function (s, i) {
                                                chart.chartArea.append('text')
                                                    .attr('fill', 'black')
                                                    .attr('x', chart.width + chart.margin.left + 8)
                                                    .attr('y', i * 24 + 24)
                                                    .text(s);
                                                chart.chartArea.append('rect')
                                                    .attr('fill', chart.colors(i))
                                                    .attr('width', 60)
                                                    .attr('height', 20)
                                                    .attr('x', chart.width + chart.margin.left + 90)
                                                    .attr('y', i * 24 + 6);
                                            });
                                        }
                    */
                }

                chart.ignore = false;
            };

            bfChart.newChart(scope, elem, model);
        }
    };
}]);

app.directive('stackedBarChart', [function () {
    // Return our directive description object // that will create the directive
    return {
        restrict: 'EA',
        require: 'ngModel',
        scope: {
            'ngModel': '=',
            'showGrouped': '='
        },

        // eslint-disable-next-line no-unused-vars
        link: function (scope, element, attrs, ctrl) { // Define our D3 components
            var svg,
                chartArea,
                parentWidth,
                parentHeight = $(window).height(),
                chartHeight = parentHeight / 2,
                samplesPerLayer,
                xAxis,
                xAxisElement,
                yAxis,
                // eslint-disable-next-line no-unused-vars
                yAxisElement,
                legend,
                xScale, yScale,
                yGroupMax, yStackMax,
                layersCount, stack, layers,
                formatString = '$,.0f',
                // eslint-disable-next-line no-unused-vars
                width,
                tooltip,
                showGrouped = true,
                data = null;

            if (attrs.yAxisFormat != null) { formatString = attrs.yAxisFormat; }
            var yAxisFormat = d3.format(formatString);

            var margin = {
                top: 30,
                right: 20,
                bottom: parseInt(attrs.bottomMargin) || 80,
                left: 80
            };
            width = width - margin.left - margin.right;
            parentHeight = parentHeight - margin.top - margin.bottom;

            _initChart();

            scope.$on('$destroy', function () {
                _cleanupChart();
            });

            scope.$on('$windowResizeEnd', function () {
                _resizeChart();
            });

            scope.$on('navToggled', function () {
                _resizeChart();
            });

            // eslint-disable-next-line no-unused-vars
            scope.$watch('ngModel', function (newVals, oldVals) {
                data = newVals.data;
                _updateChart();
            }, true);


            function _initChart() {
                svg = d3.select(element[0])
                    .append('svg')
                    .attr({
                        width: parentWidth,
                        height: chartHeight + 110
                    });
                chartArea = svg.append("g")
                    .attr("transform", "translate(" + [margin.left, margin.top] + ")");
                tooltip = d3.select("body").append("div")
                    .attr("class", "tooltip vlc")
                    .style("opacity", 0);

            }

            function _updateChart() {
                parentWidth = element[0].clientWidth - 80;
                width = attrs.width || parentWidth;

                if (!data) {
                    return; // not yet initialized
                }
                samplesPerLayer = data.length - 1; // number of samples per layer
                layersCount = data[0].length - 1; // number of layers
                stack = d3.layout.stack();
                layers = stack(d3.range(layersCount).map(function (j) {
                    var result = [];
                    for (var i = 0; i < samplesPerLayer; i++) {
                        var val = data[i + 1][j + 1];
                        if (!val) {
                            val = 0;
                        }
                        result[result.length] = { x: i, y: val, layer: j };
                    }
                    return result;
                }));
                yGroupMax = d3.max(layers, function (layer) { return d3.max(layer, function (d) { return d.y; }); });
                yStackMax = d3.max(layers, function (layer) { return d3.max(layer, function (d) { return d.y0 + d.y; }); });
                yGroupMax = yGroupMax * 1.05;
                yStackMax = yStackMax * 1.05;

                xScale = d3.scale.ordinal()
                    .domain(d3.range(samplesPerLayer))
                    .rangeRoundBands([0, parentWidth], 0.2);

                yScale = d3.scale.linear()
                    .domain([0, yStackMax])
                    .range([chartHeight, 0]);
                var color = d3.scale.category10()
                    .domain([0, layersCount - 1]);

                xAxis = d3.svg.axis()
                    .scale(xScale)
                    .tickSize(0)
                    .tickPadding(10)
                    .orient("bottom")
                    // eslint-disable-next-line no-unused-vars
                    .tickFormat(function (d, i) { return data[d + 1][0]; });

                yAxis = d3.svg.axis()
                    .scale(yScale)
                    .orient("left")
                    .ticks(5)
                    .tickSize(-parentWidth)
                    .tickPadding(10)
                    // eslint-disable-next-line no-unused-vars
                    .tickFormat(function (d, i) { return yAxisFormat(d); });

                chartArea.selectAll(".layer").remove();
                chartArea.selectAll("rect").remove();
                chartArea.selectAll(".axis").remove();
                chartArea.selectAll("text").remove();

                xAxisElement = chartArea.append("g")
                    .attr("class", "x axis")
                    .attr("transform", "translate(" + [0, chartHeight - 20] + ")")
                    .call(xAxis);

                xAxisElement
                    .selectAll("text")
                    .style("text-anchor", function () {
                        return xScale.rangeBand() < 200 ? 'end' : 'middle';
                    })
                    // eslint-disable-next-line no-unused-vars
                    .attr("transform", function (d) {
                        var transform;
                        if (xScale.rangeBand() < 200) {
                            transform = 'translate(0,10) rotate(-45)';
                        }
                        else {
                            transform = 'translate(0,10) rotate(0)';
                        }
                        return transform;
                    });

                yAxisElement = chartArea.append("g")
                    .attr("class", "y axis")
                    .call(yAxis);

                var layer = chartArea.selectAll(".layer")
                    .data(layers)
                    .enter().append("g")
                    .attr("class", "layer")
                    .style("fill", function (d, i) { return color(i); });

                var rect = layer.selectAll("rect")
                    .data(function (d) { return d; })
                    .enter().append("rect")
                    .attr("x", function (d) { return xScale(d.x); })
                    .attr("y", chartHeight)
                    .attr("width", xScale.rangeBand())
                    .attr("height", 0)
                    .on("mouseenter", function (d) {
                        tooltip.transition()
                            .duration(200)
                            .style({
                                display: 'block',
                                opacity: 0.9
                            });

                        tooltip.html('<p style="font-weight: bold;">' + data[d.x + 1][0] + '</p><p>'
                            + data[0][d.layer + 1] + ": <span style=\"font-weight: bold\">"
                            + yAxisFormat(d.y) + "</span></p>")
                            .style("left", (d3.event.pageX) + "px")
                            .style("top", (d3.event.pageY - 28) + "px");
                    })
                    // eslint-disable-next-line no-unused-vars
                    .on("mousemove", function (d) {
                        tooltip
                            .style({
                                left: (d3.event.pageX - 125) + 'px',
                                top: (d3.event.pageY + 40) + 'px'
                            });
                    })
                    // eslint-disable-next-line no-unused-vars
                    .on("mouseleave", function (d) {
                        tooltip.transition()
                            .duration(200)
                            .style("opacity", 0)
                            .each('end', function () {
                                d3.select(this).style({ display: 'none' });
                            });
                    });

                var shift = xAxisElement.node().getBBox().height + 50;
                svg
                    .transition()
                    .duration(500)
                    .attr({
                        width: parentWidth,
                        height: chartHeight + shift
                    });

                chartArea.append("text")
                    .attr("class", "title")
                    .attr("x", (parentWidth / 2))
                    .attr("y", -margin.top / 2)
                    .attr("text-anchor", "middle")
                    .style("font-size", "16px")
                    .style("font-weight", "bold")
                    .text(attrs.title);

                scope.$watch('showGrouped', function (val) {
                    showGrouped = val;
                    yScale.domain([0, yGroupMax]);
                    if (showGrouped) {
                        scope.transitionGrouped();
                    } else {
                        scope.transitionStacked();
                    }
                }, true);

                scope.transitionGrouped = function () {
                    yScale.domain([0, yGroupMax]);
                    yAxis.tickSize(-parentWidth);
                    chartArea.selectAll("g .y.axis")
                        .transition()
                        .duration(500)
                        .call(yAxis);
                    rect
                        .transition()
                        .duration(500)
                        .delay(function (d, i) { return i * 10; })
                        .attr("x", function (d, i, j) {
                            return xScale(d.x) + xScale.rangeBand() / layersCount * j;
                        })
                        .attr("width", xScale.rangeBand() / layersCount)
                        .transition()
                        .duration(500)
                        .attr("y", function (d) { return yScale(d.y); })
                        .attr("height", function (d) { return chartHeight - yScale(d.y); });
                };

                scope.transitionStacked = function () {
                    yScale.domain([0, yStackMax]);
                    yAxis.tickSize(-parentWidth);

                    chartArea
                        .selectAll("g .y.axis")
                        .transition()
                        .duration(500)
                        .call(yAxis);

                    rect
                        .transition()
                        .duration(500)
                        .delay(function (d, i) { return i * 10; })
                        .attr("y", function (d) { return yScale(d.y0 + d.y); })
                        .attr("height", function (d) { return yScale(d.y0) - yScale(d.y0 + d.y); })
                        .transition()
                        .attr("x", function (d) { return xScale(d.x); })
                        .attr("width", xScale.rangeBand());
                };
            }

            scope.$on('$destroy', function () {
                _cleanupChart();
            });

            function _resizeChart() {
                parentWidth = element[0].clientWidth - 80;
                parentHeight = $(window).height();
                chartHeight = parentHeight / 2;

                xScale.rangeRoundBands([0, parentWidth], 0.2);
                yScale.range([chartHeight, 0]);

                if (showGrouped) {
                    scope.transitionGrouped();
                }
                else {
                    scope.transitionStacked();
                }
                xAxis.scale(xScale);
                xAxisElement
                    .transition()
                    .duration(500)
                    .attr("transform", "translate(0," + chartHeight + ")")
                    .call(xAxis)
                    .selectAll("text")
                    .style("text-anchor", "end");

                xAxisElement
                    .selectAll("text")
                    .style("text-anchor", function () {
                        return xScale.rangeBand() < 200 ? 'end' : 'middle';
                    })
                    // eslint-disable-next-line no-unused-vars
                    .attr("transform", function (d) {
                        var transform;
                        if (xScale.rangeBand() < 200) {
                            transform = 'translate(0,10) rotate(-45)';
                        }
                        else {
                            transform = 'translate(0,10) rotate(0)';
                        }
                        return transform;

                    });

                var shift = xAxisElement.node().getBBox().height + 50;
                legend
                    .transition()
                    .duration(500)
                    .attr('transform', function () {
                        scope.parentWrapperHeight = chartHeight + shift + 50 + 'px';
                        scope.$apply();
                        return 'translate(' + [((parentWidth + 80) / 2) - this.getBBox().width / 2, chartHeight + shift] + ')';
                    });

                svg
                    .transition()
                    .duration(500)
                    .attr({
                        width: parentWidth,
                        height: chartHeight + shift
                    });
            }

            function _cleanupChart() {
                d3.selectAll('.tooltip').remove();
            }

        }
    };
}]);
