import {ListItem} from './list-item';

export class FolderItem {
    public constructor(private _id: string,
                       private _label: string,
                       private _list: ListItem[]) {
    }

    get id(): string {
        return this._id;
    }

    get label(): string {
        return this._label;
    }

    get list(): ListItem[] {
        return this._list;
    }
}
