/**
 * Handles csv downloads.
 */
(function () {
    'use strict';

    app
        .factory('downloadCsvSrvc', downloadCsvSrvc);

    downloadCsvSrvc.$inject = ['api', 'authSrvc'];

    /**
     * Service to handle csv downloads.
     *
     *
     * @param api The `api` service
     * @param authSrvc The `authSrvc` service
     */
    function downloadCsvSrvc(api, authSrvc) {
        var service = {};

        /**
         * To allow mocking redirects in unit tests
         * @param url The url
         */
        service.redirect = function (url) {
            location.href = url;
        };

        /**
         * Download the Csv from a URL
         * @param url The url to redirect the window to
         * @return {Promise} The Promise when download is finished
         */
        service.downloadCsv = function (url) {
            return api.getter({ url: "authenticate/createExportToken" })
                .then(function (response) {
                    /** Add the correct pod from jwt **/
                    url = authSrvc.getUrlToUse(url);
                    for (var key in response.data) {
                        url += "&" + key + "=" + response.data[key];
                    }
                    service.redirect(url);
                });
        };
        return service;
    }
})();
