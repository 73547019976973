app.controller('accountCtrl', [
    '$scope', '$rootScope', 'api', 'utilities', '$state', '$filter', '$http', 'colorMap', 'cohorts', 'account', '_', 'terminusHubService',
    function ($scope, $rootScope, api, utilities, $state, $filter, $http, colorMap, cohorts, account, _, terminusHubService) {

        var getAccountCall, loadAdvertisingCall;
        var salesloftURL = "https://app.salesloft.com/app/import/crm?";
        //------------------------ scope methods ------------------------//
        $scope.last = function (type, time) {
            var set = type === 'marketing' ? 'activity_list' : 'sales_activity',
                dateField = type === 'marketing' ? 'activity_date' : 'created_date',
                ago = moment().subtract(time, 'days')._d.getTime();
            return $scope.data.account ? $filter('filter')($scope.data.account[set], function (row) {
                var d = new Date(row[dateField]).getTime();
                return d >= ago;
            }).length : null;
        };

        var baseUrl = $rootScope.sfdcURL;
        if ($rootScope.orgConfig.organization_settings && $rootScope.orgConfig.organization_settings.dynamics365_enabled) {
            baseUrl = $rootScope.orgConfig.organization_settings.dynamicsUrl;
        }

        $scope.resizeChart = function (instance) {
            return function (w) {
                if (instance) {
                    try {
                        instance.resize({ width: w, height: w });
                    }
                    // eslint-disable-next-line no-empty
                    catch (e) { }
                }
            };
        };

        $scope.websiteLink = function (k, v) {
            if (k === 'website') {
                return (_.contains(v, 'http://') ? '' : 'http://') + v;
            }
        };

        $scope.runAllQuery = function () {
            loadAccountInfo();
            loadAdvertising();
        };

        $scope.loadAdvertisingData = function () {
            loadAdvertising();
        };

        $scope.chartTypes = _.once(function (d) {
            return _.filter(Object.keys(d), function (k) {
                return !_.includes(k, 'Sales') && !_.includes(k, 'Visits');
            });
        });

        $scope.exportAllToSalesloft = function (people) {
            var maxInPayload = 120;

            var leadIds = [];
            var contactIds = [];
            for (var i = 0; i < people.length; i++) {
                if (people[i].contact && people[i].contact.id) {
                    contactIds.push(toShortSFDCId(people[i].contact.id));
                } else {
                    leadIds.push(toShortSFDCId(people[i].id));
                }
                if (leadIds.length === maxInPayload) {
                    $scope.openNewWindow(salesloftURL + "lead=" + leadIds.join(","));
                    leadIds = [];
                }
                if (contactIds.length === maxInPayload) {
                    $scope.openNewWindow(salesloftURL + "contact=" + contactIds.join(","));
                    contactIds = [];
                }
            }

            if (leadIds.length > 0) {
                $scope.openNewWindow(salesloftURL + "lead=" + leadIds.join(","));
            }
            if (contactIds.length > 0) {
                $scope.openNewWindow(salesloftURL + "contact=" + contactIds.join(","));
            }
        };

        $scope.openNewWindow = function (url) {
            window.open(url, "_blank");
        };

        $scope.salesloftEnabled = function () {
            return $rootScope.orgConfig.features && $rootScope.orgConfig.features.account_hub
            && $rootScope.orgConfig.features.account_hub.salesloft;
        };

        //------------------------ private methods ------------------------//

        function toShortSFDCId(id) {
            return id.substr(0, 15);
        }

        function makeDonut(type) {
            var o = {},
                d = {},
                c = {
                    size: {},
                    padding: {
                        bottom: 20
                    },
                    donut: {
                    //title: Math.round(((_.max(_.crush(o)) / _.sum(_.crush(o))) * 100)) + '%'
                        title: null
                    },
                    data: {
                        colors: {}
                    // onclick: function(d, elem) {
                    // 	// do something
                    // },
                    // onmouseover: function (d,i) {
                    // 	$scope.data.chart[type].chart.donut.title = d.value + ' ' + d.id;
                    // },
                    // onmouseout: function (d,i) {
                    // 	// $scope.$apply(function() {
                    // 	// 	$scope.data.chart[type].chart.donut.title = donutTitle;
                    // 	// });
                    // }
                    }
                // legend: {
                // 	position: 'right'
                // }
                };

            if (type === 'Responses by Type') {
                c.donut.title = 'Types';

                // eslint-disable-next-line no-unused-vars
                angular.forEach($scope.data.account.activity_list, function (activity, i) {
                    d[activity.campaign.revenue_group] = { type: 'donut' };
                    o[activity.campaign.revenue_group] ? o[activity.campaign.revenue_group]++ : o[activity.campaign.revenue_group] = 1;
                    c.data.colors[activity.campaign.revenue_group] = colorMap(activity.campaign.revenue_group);
                });
            } else if (type === 'Responses by Title') {
                c.donut.title = 'Roles';

                var map = {
                        "CXO": ['ceo', 'cro', 'coo', 'cmo', 'owner', 'board of directors', 'chief marketing officer', 'chief revenue officer', 'chief opperating officer', 'chief executive officer'],
                        "VP/Directors": ['vp', 'director', 'head of', 'vice president'],
                        "Managers": ['manager']
                    },
                    hitMap = {};

                // eslint-disable-next-line no-unused-vars
                angular.forEach($scope.data.account.activity_list, function (activity, i) {
                    var contact = activity.contact,
                        contactID = contact.id,
                        contactTitle = contact.title,
                        hit = false;

                    if (contactTitle && contactID) {
                        contactTitle = contactTitle.toLowerCase();

                        if (hitMap[contactID]) {
                            o[hitMap[contactID]] ? o[hitMap[contactID]]++ : o[hitMap[contactID]] = 1;
                            hit = true;
                        } else {
                            angular.forEach(map, function (titles, bucket) {
                                d[bucket] = {
                                    type: 'donut'
                                };
                                // eslint-disable-next-line no-unused-vars
                                angular.forEach(titles, function (title, i) {
                                    if (!hit) {
                                        if (_.contains(contactTitle, title)) {
                                            o[bucket] ? o[bucket]++ : o[bucket] = 1;
                                            hitMap[contactID] = contactTitle;
                                            hit = true;
                                        }
                                    }
                                });
                            });
                        }
                    }

                    if (!hit) {
                        o["Misc"] ? o["Misc"]++ : o["Misc"] = 1;
                    }

                    d.Misc = {
                        type: 'donut'
                    };
                });
            } else if (type === 'Sales Activity by Type') {
                c.donut.title = 'Activity Types';

                // eslint-disable-next-line no-unused-vars
                angular.forEach($scope.data.account.sales_activity, function (activity, i) {
                    var type = activity.type;
                    d[type] = { type: 'donut' };
                    o[type] ? o[type]++ : o[type] = 1;
                });
            } else if (type === 'Page Visits by Channel') {
                c.donut.title = 'Page Visits';
                // eslint-disable-next-line no-unused-vars
                angular.forEach($scope.data.account.web_activity, function (activity, i) {
                    var type = activity.channel;
                    d[type] = { type: 'donut' };
                    o[type] ? o[type] += activity.pages : o[type] = activity.pages;
                });
            } else {
                return;
            }
            $scope.data.chart[type] = {
                data: [o],
                dimensions: d,
                chart: c
            };
        }

        function mostCommonEmail() {
            var domains = {};
            // eslint-disable-next-line no-unused-vars
            angular.forEach($scope.data.account.contacts, function (contact, i) {
                if (contact.email && _.contains(contact.email, '@')) {
                    var domain = contact.email.split('@')[1];
                    domains[domain] = domains[domain] ? domains[domain]++ : 1;
                }
            });
            return _.findKey(domains, function (o) {
                return o === _.max(_.crush(domains));
            });
        }

        function changeUniqueVisitorVisibility() {
            $scope.data.advertising[3].visible = $scope.query.timeUnit === 'day';
        }

        function loadAccountInfo() {
            $state.current.data.loading = true;
            if ($scope.data.account) {
                $scope.data.account.activity_list = null;
                $scope.data.account.sales_activity = null;
                $scope.data.account.contacts = null;
                $scope.data.account.orphan_leads = null;
                $scope.data.account.web_activity = null;
                $scope.data.account.advertising = null;
            }

            api.get('account/load_info', $scope.query).then(function (response) {

                $scope.data.title = response.data.name;
                $scope.data.account = response.data;
                delete $scope.data.account["industry"];
                delete $scope.data.account["employee_range"];
                delete $scope.data.account["revenue_range"];
                delete $scope.data.account["relationship_score"];
                delete $scope.data.account["propensity_to_purchase"];

                if ($scope.data.account.website) {
                    $scope.data.logo = 'https://logo.clearbit.com/' + $scope.data.account.website.replace('http://', '');
                }

                loadPeople();
                loadOpportunities();
                loadAdvertising();
            }).finally(function () {
                $state.current.data.loading = false;
            });
        }

        function loadPeople() {
            api.get('account/load_people', $scope.query).then(function (response) {
                $scope.data.account.contacts = response.data.contacts;
                $scope.data.account.orphan_leads = response.data.orphan_leads;
                if (!$scope.data.account.website) {
                    $scope.data.logo = 'https://logo.clearbit.com/' + ($scope.data.account.contacts.length ? mostCommonEmail() : _.toLower(_.replace($scope.data.account.name, ' ', '')) + '.com');
                }

                loadTouches();

                $scope.data.account.orphan_leads.forEach(function (ol) {
                    ol.confidence = ol.score <= 29 ? 'low' : ol.score <= 49 ? 'medium' : 'high';
                });

                $scope.data.account.contacts.forEach(function (contact) {
                    if (contact.type === 'olf_converted') {
                        contact.type = 'orphan lead match';
                    }
                });

                $scope.data.account.contacts.forEach(function (item) {
                    var id = item.contact && item.contact.id ? item.contact.id : '';
                    item.crmLink = baseUrl + '/' + id;
                });
            });
        }

        function loadOpportunities() {
            $scope.data.account.opptys = null;

            var params = angular.copy($scope.query);
            params.cohort = utilities.getAllTimeValue();
            api.get('account/load_opportunities', params).then(function (response) {
                $scope.data.account.opptys = response.data;
                $scope.data.account.total_open_opportunity_value = _.sumBy(response.data, function (o) { return o.is_open ? o.amount : 0; });
                $scope.data.account.total_closed_won_revenue = _.sumBy(response.data, function (o) { return o.won ? o.amount : 0; });

                if ($scope.data.account.activity_list != null && $scope.orgConfig.salesActivity.enabled) {
                    loadSalesActivity();
                }
            });
        }

        function loadTouches() {
            api.get('account/load_touches', $scope.query).then(function (response) {
                $scope.data.account.activity_list = response.data.activity_list;

                if ($scope.data.account.opptys != null && $scope.orgConfig.salesActivity.enabled) {
                    loadSalesActivity();
                }

                $scope.data.account.contacts.forEach(function (contact) {
                    contact.campaign_members = response.data.counts_by_id[contact.lead_id] ? response.data.counts_by_id[contact.lead_id] : 0;
                });

                $scope.data.account.activity_list.forEach(function (item) {
                    var id = item.contact && item.contact.id ? item.contact.id : '';
                    item.contact.crmLink = baseUrl + '/' + id;
                });

                makeDonut('Responses by Type');
                makeDonut('Responses by Title');
            });
        }

        function loadSalesActivity() {
            api.get('account/load_sales_data', $scope.query).then(function (response) {
                $scope.data.account.sales_activity = response.data.tasks;

                $scope.data.account.contacts.forEach(function (contact) {
                    contact.sa_count = response.data.lead_task_count[contact.lead_id] ? response.data.lead_task_count[contact.lead_id] : 0;
                });

                $scope.data.account.opptys.forEach(function (oppty) {
                    oppty.sa_count = response.data.oppty_task_count[oppty.id] ? response.data.oppty_task_count[oppty.id] : 0;
                });

                $scope.data.account.sales_activity.forEach(function (task) {
                    if (!task.type && task.subject) {
                        var subject = task.subject.toLowerCase();
                        if (_.includesAny(subject, ['call ', ' call', 'call:', '[call]', 'call-', 'call,']) || subject === 'call') {
                            task.type = 'Call';
                        } else if (_.includesAny(subject, ['email ', ' email', 'email:', '[email]', 'email-', 'email,']) || subject === 'email') {
                            task.type = 'Email';
                        } else if (_.includesAny(subject, ['meeting ', ' meeting', 'meeting:', '[meeting]', 'meeting-', 'meeting,']) || subject === 'meeting') {
                            task.type = 'Meeting';
                        } else if (_.includesAny(subject, ['demo ', ' demo', 'demo:', '[demo]', 'demo-', 'demo,']) || subject === 'demo') {
                            task.type = 'Demo';
                        } else {
                            task.type = 'Other';
                        }
                    }

                    if (task.type === 'BF_None') {
                        task.type = '';
                    }
                });

                makeDonut('Sales Activity by Type');
            });
        }

        function loadAdvertising() {
            if ($rootScope.orgConfig.terminus_data) {
                if (loadAdvertisingCall) { loadAdvertisingCall.abort(); }

                (loadAdvertisingCall = api.get('account/load_advertising', $scope.query)).then(function (data) {
                    $scope.data.account.advertising = {};
                    $scope.data.account.advertising.rows = data.data;

                    $scope.data.account.advertising.total_impressions = _.sumBy(data.data, function (o) { return o.impressions; });
                    $scope.data.account.advertising.total_clicks = _.sumBy(data.data, function (o) { return o.clicks; });
                    $scope.data.account.advertising.total_page_views = _.sumBy(data.data, function (o) { return o.page_views; });

                    changeUniqueVisitorVisibility();
                });
            }
        }

        $scope.getSortField = function (column) {
            return function (row) {
                return account.getSortField(row, column, $scope.query.timeUnit);
            };
        };

        $scope.$on('$destroy', function () {
            if (getAccountCall) { getAccountCall.abort(); }
            if (loadAdvertisingCall) { loadAdvertisingCall.abort(); }
        });

        /**
     * Check if this is where we should add the name link
     * @param key The account data key
     * @return {boolean|*} True if the key is name and we have an account ID
     */
        $scope.isNameLink = function (key) {
            return key === 'name' && $scope.query.accountId;
        };
        /**
     * Build the name link based on the SF url and accountID
     * @return {string} The url for SF
     */
        $scope.getNameLink = function () {
            return baseUrl + '/' + $scope.query.accountId;
        };

        //------------------------ init ------------------------//
        (function init() {
            var contactColumns = [{
                    label: 'Person',
                    field: 'name',
                    descendingFirst: false,
                    linkExternal: true,
                    link: function (row) {
                        return $scope.sfdcURL + '/' + row.id;
                    }
                }, {
                    label: 'Source Campaign',
                    field: 'source_campaign.name',
                    descendingFirst: false,
                    linkExternal: true,
                    link: function (row) {
                        const params = `?name=${row.source_campaign.name}&campaignId=${row.source_campaign.id}&cohort=${$scope.query.cohort}`;
                        return row.source_campaign.id
                            ? terminusHubService.getTerminusHubUrlByReportName('app.analyze.campaigns.campaignSpecific.attribution') + params
                            : false;
                    }
                }, {
                    label: 'Campaign Responses',
                    filter: 'number',
                    field: 'campaign_members',
                    descendingFirst: true
                }, {
                    label: 'Relationship',
                    field: 'type',
                    descendingFirst: false
                }, {
                    label: 'Sales Activity',
                    filter: 'number',
                    field: 'sa_count',
                    descendingFirst: true
                }, {
                    label: 'Created',
                    field: 'created_date',
                    filter: 'date:M/d/yy',
                    descendingFirst: true
                }],
                opptyColumns = [{
                    label: 'Opportunity Name',
                    field: 'name',
                    descendingFirst: false,
                    linkExternal: true,
                    link: function (row) {
                        const params = `?oppty=${row.id}&isId=true`;
                        return terminusHubService.getTerminusHubUrlByReportName('app.analyze.opportunities.opportunitySpecific.attribution') + params;
                    }
                }, {
                    label: 'Type',
                    field: 'opportunity_type',
                    descendingFirst: false
                }, {
                    label: 'Amount',
                    field: 'amount',
                    filter: 'bfCurrency',
                    descendingFirst: true
                }, {
                    label: 'Sales Activity',
                    filter: 'number',
                    field: 'sa_count',
                    descendingFirst: true
                }, {
                    label: 'Created',
                    field: 'created_date',
                    filter: 'date:M/d/yy',
                    descendingFirst: true
                }, {
                    label: 'Closed',
                    field: 'close_date',
                    filter: 'date:M/d/yy',
                    descendingFirst: true
                }, {
                    label: 'Stage',
                    field: 'stage',
                    descendingFirst: false
                }],
                orphanColumns = [{
                    label: 'Person',
                    field: 'name',
                    descendingFirst: false,
                    link: function (row) {
                        return $scope.sfdcURL + '/' + row.id;
                    },
                    linkExternal: true
                }, {
                    label: 'Campaign Memberships',
                    filter: 'number',
                    field: 'campaign_member.count',
                    descendingFirst: true
                }, {
                    label: 'Sales Activity',
                    filter: 'number',
                    field: 'sa_count',
                    descendingFirst: true
                }, {
                    label: 'Created',
                    field: 'created_date',
                    filter: 'date:M/d/yy',
                    descendingFirst: true
                }, {
                    label: 'Last Activity',
                    field: 'campaign_member.last_activity',
                    filter: 'date:M/d/yy',
                    descendingFirst: true
                }, {
                    label: 'Confidence',
                    field: 'confidence',
                    popover: 'olf-matches.html',
                    descendingFirst: false,
                    init: function (scope) {
                        scope.olf_match = _.omit(_.find($scope.data.account.orphan_leads, { id: scope.row.id })).olf_matches;
                        scope.orphan_lead = _.omit(_.find($scope.data.account.orphan_leads, { id: scope.row.id }));
                    }
                }];

            $state.current.data.title = null;
            //set up query object
            $scope.query = {
                cohort: '*NONE*',
                withSalesActivity: $scope.orgConfig.salesActivity.enabled
            };

            if (!$scope.query.timeUnit) {
                $scope.query.timeUnit = 'month';
            }

            if ($scope.salesloftEnabled()) {

                contactColumns.push({
                    label: '',
                    field: 'salesloft',
                    descendingFirst: false,
                    linkExternal: true,
                    link: function (row) {
                        var id = !row.contact.id ? row.lead_id : row.contact.id;
                        var type = !row.contact.id ? "lead" : "contact";
                        return salesloftURL + type + "=" + toShortSFDCId(id);
                    }
                });

                orphanColumns.push({
                    label: '',
                    field: 'salesloft',
                    descendingFirst: false,
                    linkExternal: true,
                    link: function (row) {
                        return salesloftURL + "lead=" + toShortSFDCId(row.id);
                    }
                });
            }

            $scope.moment = moment;
            $scope.instance = {};
            $scope.data = {
                cohorts: utilities.formatCohorts(cohorts, ['all', 'ago', 'custom', 'year', 'quarter', 'toDate', 'lastFull']),
                dateTypes: utilities.formatDateTypes(),
                tableStates: {},
                currencyFilter: 'bfCurrency',
                chart: {},
                loaded: false,
                marketingChartType: 'Responses by Type',
                columns: {
                    contacts: ($scope.orgConfig.salesActivity.enabled ? contactColumns : _.remove(contactColumns, function (n) {
                        return n.field !== "sa_count";
                    })),
                    opptys: ($scope.orgConfig.salesActivity.enabled ? opptyColumns : _.remove(opptyColumns, function (n) {
                        return n.field !== "sa_count";
                    }))
                },
                web: [{
                    label: 'Channel',
                    field: 'channel',
                    descendingFirst: false
                }, {
                    label: 'Visits',
                    field: 'visits',
                    descendingFirst: true,
                    filter: 'number:0'
                }, {
                    label: 'Pages',
                    field: 'pages',
                    descendingFirst: true,
                    filter: 'number:0'
                }, {
                    label: 'Unique Contacts',
                    field: 'unique_contacts',
                    descendingFirst: true,
                    filter: 'number:0'
                }],
                activity: [{
                    label: 'Date',
                    field: 'activity_date',
                    filter: 'date:M/d/yy',
                    defaultSort: true,
                    descendingFirst: true
                }, {
                    label: 'Campaign',
                    field: 'campaign.name',
                    descendingFirst: false,
                    link: function (row) {
                        const params = `?name=${row.campaign.name}&campaignId=${row.campaign.id}&cohort=${$scope.query.cohort}`;
                        return terminusHubService.getTerminusHubUrlByReportName('app.analyze.campaigns.campaignSpecific.attribution') + params;
                    }
                }, {
                    label: 'Person',
                    field: 'contact.name',
                    descendingFirst: false,
                    popover: 'contact-info.html',
                    init: function (scope) {
                        scope.contact = _.omit(_.find($scope.data.account.contacts, { id: scope.row.contact.id }), ['id', 'lead_id']);
                    },
                    link: function (row) {
                        return row.contact.crmLink ? row.contact.crmLink : '';
                    }
                }],
                salesActivity: [{
                    label: 'Type',
                    field: 'type'
                }, {
                    label: 'Date',
                    field: 'created_date',
                    filter: 'date:M/d/yy',
                    defaultSort: true,
                    descendingFirst: true
                }, {
                    label: 'Person',
                    field: 'name'
                }, {
                    label: 'Assigned To',
                    field: 'assigned_to'
                }, {
                    label: 'Subject',
                    field: 'subject'
                }],
                advertising: [{
                    label: 'Date',
                    field: 'time_interval_formatted',
                    sortField: 'visit_date_utc',
                    defaultSort: true,
                    descendingFirst: true,
                    visible: true
                }, {
                    label: 'Impressions',
                    field: 'impressions',
                    sortField: 'impressions',
                    defaultSort: false,
                    descendingFirst: true,
                    filter: 'number:0',
                    visible: true
                }, {
                    label: 'Clicks',
                    field: 'clicks',
                    sortField: 'clicks',
                    defaultSort: false,
                    descendingFirst: true,
                    filter: 'number:0',
                    visible: true
                }, {
                    label: 'Unique Visitors',
                    field: 'unique_visitors',
                    sortField: 'unique_visitors',
                    defaultSort: false,
                    descendingFirst: true,
                    filter: 'number:0',
                    visible: false
                }, {
                    label: 'Page Views',
                    field: 'page_views',
                    sortField: 'page_views',
                    defaultSort: false,
                    descendingFirst: true,
                    filter: 'number:0',
                    visible: true
                }],
                activeTab: 'marketing'
            };

            changeUniqueVisitorVisibility();

            if ($rootScope.orgConfig.olf.enabled || $rootScope.userData.platform === 'full') {
                $scope.data.columns.orphan_leads = $scope.orgConfig.salesActivity.enabled ? orphanColumns : _.remove(orphanColumns, function (n) {
                    return n.field !== "sa_count";
                });
            }

            utilities.combine($scope.query, $state.params);

            $scope.isObject = angular.isObject;
            $scope.isNumber = angular.isNumber;
            $scope._ = _;

            var stateName = 'app.analyze.accountsAccountSpecific.trending';
            var trendingParams = angular.copy(utilities.getHistory(stateName)) || {};
            angular.extend(trendingParams, $state.params);
            utilities.setHistory(stateName, trendingParams);

            loadAccountInfo();
            $scope.$on('filtersChanged', loadAccountInfo);
        })();
    }]);
