import * as COMPONENT_HTML from './component.html';
import './component.less';
import {IDirective, IScope,} from 'angular';

class BoxChoiceCtrl {
    static $inject: Array<string> = ['$scope'];

    public constructor(private $scope: IScope) {
        this.$scope.$watch('$ctrl.list', (list: any[]) => {
            if (list && list.length > 0) {
                this.$scope.$ctrl.selected = list[0];
            }
        });

        return;
    }

    private click = (item: any) => {
        this.$scope.$ctrl.selected = item;
    };

    private show = (item: any) => {
        return this.$scope.$ctrl.selected == item;
    };
}

export class BoxChoice implements IDirective {
    public restrict = 'E';
    public controller: any = BoxChoiceCtrl;
    public controllerAs = '$ctrl';
    public bindToController = true;
    public template: any = COMPONENT_HTML;
    public scope: IScope = {
        list: '=',
        selected: '=',
    };

    public constructor() {
        return;
    }

    public static Factory(): IDirective {
        return new BoxChoice();
    }
}
