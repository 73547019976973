import { IDirective, IScope, } from 'angular';

import { ITrendingKpiQuery, } from '../trending-kpis-widget/component';
import { IKpiResponse } from 'src/shared/models/scorecard/kpi-response/model';
import { IKpiTrendInterval } from 'src/shared/models/scorecard/kpi-trend-interval/model';
import * as CONSTANTS from '../../constants';
import * as ComponentTemplate from './component.html';
import './component.less';

export class TrendingKpiNumberTileController {
    static $inject: string [] = [
        '$scope',
    ];

    public kpiFormat: string;
    public kpiSubtitle: string;
    public intervalSubtitle: string;
    public isComparisonPositive = false;
    public query: ITrendingKpiQuery;
    public queryId: string;
    public responseData: IKpiResponse;
    public retrieveSubheaderTitle = () => `${ this.kpiSubtitle } from last ${ this.intervalSubtitle }`;

    constructor (
        public $scope: IScope,
    ) {
        this.initialize();
    }

    public compareInterval(trends: IKpiTrendInterval[]): number {
        const sortedTrends = trends.sort((a, b) => (a.descriptor > b.descriptor) ? 1 : ((b.descriptor > a.descriptor) ? -1 : 0));
        const currSeg = sortedTrends[sortedTrends.length - 1];
        const prevSeg = sortedTrends[sortedTrends.length - 2];
        const total = currSeg.total - prevSeg.total;

        this.isComparisonPositive = total >= 0;
        return total;
    }

    private initialize(): void {
        this.queryId = this.$scope.ctrl.id;
        this.setupWatches();
    }

    private setupWatches(): void {
        this.setupWidgetQueryWatch();
        this.setupKPIDataWatch();
    }

    private setupWidgetQueryWatch(): void {
        this.$scope.$watch('ctrl.widgetQuery', (value: ITrendingKpiQuery) => {
            if (value) {
                this.query = value;
                this.initializeTile();
            }
        });
    }

    private setupKPIDataWatch(): void {
        this.$scope.$watch('ctrl.kpiData', (value: IKpiResponse) => {
            if (value) {
                this.responseData = value;
            }
        });
    }

    private initializeTile(): void {
        this.kpiFormat = CONSTANTS.KPI_TYPE_FORMAT_MAP[this.query.kpi];
        this.kpiSubtitle =
            CONSTANTS.KPI_TYPE_FORMAT_MAP[this.query.kpi] === CONSTANTS.KPIFormat.Number
            && !!CONSTANTS.KPI_FORMAT_TYPE_NUMBER_DESCRIPTOR_MAP[this.query.kpi]
                ? CONSTANTS.KPI_FORMAT_TYPE_NUMBER_DESCRIPTOR_MAP[this.query.kpi]
                : CONSTANTS.KPI_HUMAN_READABLE_MAP[this.query.kpi];
        this.intervalSubtitle = CONSTANTS.INTERVAL_HUMAN_READABLE_MAP[this.query.interval];
    }
}

export class TrendingKpiNumberChart implements IDirective {
    public restrict = 'E';
    public controller: any = TrendingKpiNumberTileController;
    public controllerAs = 'ctrl';
    public bindToController = true;
    public template: any = ComponentTemplate;
    public scope: IScope = {
        widgetQuery: '=',
        kpiData: '=',
        id: '=',
    };

    public constructor() {
        return;
    }

    public static Factory(): IDirective {
        return new TrendingKpiNumberChart();
    }
}
