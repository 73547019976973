/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
var coreDirectives = angular.module('core-directives', []);

coreDirectives.directive('setIf', ['$compile', function ($compile) {
    return {
        transclude: 'element',
        priority: 1000,
        terminal: true,
        restrict: 'A',
        compile: function (element, attr, linker) {
            return function (scope, iterStartElement, attr) {
                if (attr.waitFor) {
                    var wait = scope.$watch(attr.waitFor, function (nv, ov) {
                        if (nv) {
                            build();
                            wait();
                        }
                    });
                } else {
                    build();
                }

                function build() {
                    iterStartElement[0].doNotMove = true;
                    var expression = attr.setIf;
                    var value = scope.$eval(expression);

                    linker(scope, function (clone) {
                        if (attr.setIfEvent) {
                            scope.$on(attr.setIfEvent, function () {
                                clone.remove();
                                iterStartElement.after($compile(clone.removeAttr('set-if'))(scope));
                            });
                        }
                        if (value) {
                            iterStartElement.after(clone);
                            clone.removeAttr('set-if');
                            clone.removeAttr('wait-for');
                        }
                    });
                }
            };
        }
    };
}]);

coreDirectives.directive('loading', ['$interval', '_', '$rootScope', function ($interval, _, $rootScope) {
    return {
        link: function ($scope, elem, attrs, controller) {
            var parent = elem.parent(),
                p = parseInt(parent.css('paddingTop')),
                pole,
                template = $('<div class="bubblingG">' +
                    '<span id="bubblingG_1"></span>' +
                    '<span id="bubblingG_2"></span>' +
                    '<span id="bubblingG_3"></span>' +
                    '</div>');

            function position() {
                var o = elem.offset();

                if (o.top > 0) {
                    pole = $interval(function () {
                        var h = elem.outerHeight(true),
                            wh = $(window).height(),
                            st = $('#content').scrollTop(),
                            sh = elem.scrollParent()[0].scrollHeight,
                            esh = elem[0].scrollHeight;

                        o = elem.offset();

                        // if(sh === wh) {
                        //     template.css({
                        //         top : ((h / 2) + o.top + p) - (template.height() / 2) - st
                        //     });
                        // } else {
                        //     template.css({
                        //         top : ((wh / 2) - o.top) + (template.height() / 2) + st
                        //     });
                        // }

                        template.css({
                            top: (esh / 2)
                        });
                    }, 1);
                }
            }

            $scope.$watch(attrs.loading, function (val) {
                if (val) {
                    parent.append(template);
                    position();
                    parent.addClass('relative');
                    elem.addClass('blur');
                } else {
                    if (angular.isObject(pole)) {
                        $interval.cancel(pole);
                    }
                    template.remove();
                    parent.removeClass('relative');
                    elem.removeClass('blur');
                    $rootScope.$broadcast('checkScroll');
                }
            }, true);

            $scope.$on('$destroy', function () {
                template.remove();
            });
        }
    };
}]);

coreDirectives.directive('popoverMask', ['$compile', '$timeout', function ($compile, $timeout) {
    return {
        restrict: 'A',
        priority: 0,
        link: function (scope, elem, attrs) {
            if (!attrs.uibPopover && !attrs.uibPopoverTemplate)
                return;

            var s,
                title = attrs.popoverTitle;

            elem.mouseup(build);

            function check(e) {
                if (!$.contains($('.popover[title="' + title + '"]')[0], $(e.target)[0])) {
                    elem.click();
                }
            }

            function build(e) {
                $timeout(function () {
                    s = $('.popover[title="' + title + '"]').length ? $('.popover[title="' + title + '"]').scope().$parent : false;
                    if (!s) return;

                    var open = s.isOpen;

                    var w = s.$watch('isOpen', function (nv, ov) {
                        if (nv) {
                            //var mask = $('<div class="mask"></div>');

                            $('body').mousedown(check);

                            //$('body').append(mask);
                        } else {
                            $('body').unbind('mousedown', check);
                        }
                    });

                    elem.unbind('mouseup', build);
                }, 100, false);
            }

            scope.$on('$destroy', function () {
                elem.unbind('mouseup', build);
                $('body').unbind('mousedown', check);
            });
        }
    };
}]);

coreDirectives.directive('popoverClose', ['$timeout', '$parse', function ($timeout, $parse) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            if (!attrs.uibPopover && !attrs.uibPopoverTemplate || !attrs.popoverClose)
                return;

            var s;

            elem.mouseup(build);

            function build(e) {

                $timeout(function () {
                    s = elem.parent().find('.popover').scope().$parent;
                    var change = $parse(attrs.popoverClose);

                    if (angular.isFunction(change)) {
                        var w = s.$watch('isOpen', function (nv, ov) {
                            if (!nv && nv !== ov) {
                                change(scope, {
                                    popover: s
                                });
                                w();
                            }
                        });
                    }
                }, 0, false);
            }

            scope.$on('$destroy', function () {
                elem.unbind('mouseup', build);
            });
        }
    };
}]);

coreDirectives.directive('html', ['$rootScope', '_', function ($rootScope, _) {
    return {
        restrict: 'E',
        link: function (scope, iElement, iAttrs) {
            function _resize(e) {
                $rootScope.$broadcast('$windowResize', { height: $(window).height(), width: $(window).width() });
            }
            function _defer(e) {
                $rootScope.$broadcast('$windowResizeEnd', { height: $(window).height(), width: $(window).width() });
            }
            var defer = _.debounce(_defer, 300);

            // function startDigest(){
            //     $rootScope.$digest();
            // }

            $(window).resize(_resize);
            $(window).resize(defer);


            // $(window).blur(function(){
            //     console.log("started digest!");
            //     scope.digestInterval = setInterval(startDigest,2000);
            // });

            $(window).focus(function () {
                $rootScope.$broadcast('$windowFocused');
            });
        }
    };
}]);

coreDirectives.directive('transitionComplete', ['_', function (_) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            if (!attrs.transitionComplete)
                return;

            function callback() {
                if (attrs.transitionCompleteProperty) {
                    scope.$eval(attrs.transitionComplete)(elem.css(attrs.transitionCompleteProperty));
                } else {
                    scope.$eval(attrs.transitionComplete)();
                }
            }

            var defer = _.debounce(callback, 300);

            elem[0].addEventListener('webkitTransitionEnd', callback);
            elem[0].addEventListener('transitionend', callback);
            elem[0].addEventListener('oTransitionEnd', callback);
        }
    };
}]);

coreDirectives.directive('setHtml', [function () {
    return {
        restrict: "A",
        priority: 100,
        link: function ($scope, $el, $attr) {
            $($el).html($scope.$eval($attr.setHtml));
            $($el).removeAttr('set-html');
        }
    };
}]);

coreDirectives.directive('setText', [function () {
    return {
        restrict: "A",
        priority: 100,
        link: function ($scope, $el, $attr) {
            if ($attr.waitFor) {
                var wait = $scope.$watch($attr.waitFor, function (nv, ov) {
                    if (nv) {
                        build();
                        wait();
                    }
                });
            } else {
                build();
            }

            function build() {
                $($el).text($scope.$eval($attr.setText));
                $($el).removeAttr('set-text');
            }
        }
    };
}]);

coreDirectives.directive('setClass', [function () {
    return {
        restrict: "A",
        priority: 100,
        link: function ($scope, $el, $attr) {
            if ($attr.setClass.indexOf('{') != -1) {
                var data = $scope.$eval($attr.setClass);
                angular.forEach(data, function (v, k) {
                    var expression = v;
                    var cls = k;
                    if (expression) {
                        $el.addClass(cls);
                    }
                });
                $($el).removeAttr('set-class');
            } else {
                $($el).addClass($scope.$eval($attr.setClass));
                $($el).removeAttr('set-class');
            }
        }
    };
}]);

coreDirectives.directive('setTitle', [function () {
    return {
        restrict: "A",
        priority: 100,
        link: function ($scope, $el, $attr) {
            $($el).attr('title', $scope.$eval($attr.setTitle));
            $($el).removeAttr('set-title');
        }
    };
}]);

coreDirectives.directive('setHref', [function () {
    return {
        restrict: "A",
        priority: 100,
        link: function ($scope, $el, $attr) {
            if ($attr.setHref.charAt(0) == '{') {
                var data = $scope.$eval($attr.setHref);
                $.each(data, function (k, v) {
                    var expression = v;
                    var url = k;
                    if (expression) {
                        $($el).attr('href', url);
                    }
                });
                $($el).removeAttr('set-href');
            } else {
                $($el).attr('href', $scope.$eval($attr.setHref));
                $($el).removeAttr('set-href');
            }
        }
    };
}]);

coreDirectives.directive('setSrc', [function () {
    return {
        restrict: 'A',
        priority: 100,
        link: function (scope, elem, attrs) {
            $(elem).attr('src', scope.$eval(attrs.setSrc));
            $(elem).removeAttr('set-src');
        }
    };
}]);

coreDirectives.directive('setAttrIf', [function () {
    return {
        restrict: "A",
        priority: 1000,
        link: function ($scope, $el, $attr) {
            if ($attr.setAttrIf.indexOf('{') != -1) {
                var data = $scope.$eval($attr.setAttrIf);
                $.each(data, function (k, v) {
                    var expression = v,
                        att;
                    if (k.indexOf('|') != -1) {
                        att = k.replace(/ /g, '');
                        att = att.split('|');
                    } else {
                        return;
                    }
                    if (expression) {
                        $($el).attr(att[0], $scope.$eval(unescape(att[1])));
                    }
                });
                $($el).removeAttr('set-attr-if');
            }
        }
    };
}]);

coreDirectives.directive('setAttr', ['$timeout', '$compile', function ($timeout, $compile) {
    return {
        restrict: 'A',
        priority: 900,
        link: function (scope, elem, attrs) {
            if (attrs.setAttr.indexOf('{') != -1 && attrs.setAttr.indexOf('}') != -1) {
                var data = scope.$eval(attrs.setAttr),
                    compile = false;

                angular.forEach(data, function (v, k) {
                    if (angular.isObject(v) && v.hasOwnProperty('condition') && v.hasOwnProperty('value')) {
                        var check = angular.isString(v.condition) ? scope.$eval(v.condition) : v.condition;

                        if (v.value && check) {
                            elem.attr(k, v.value);
                            if (v.compile) {
                                compile = true;
                            }
                        }
                    } else {
                        if (angular.isObject(v)) {
                            elem.attr(k, angular.toJson(v));
                        } else {
                            elem.attr(k, v);
                        }
                    }
                });

                elem.removeAttr('set-attr');

                if (compile) {
                    $timeout(function () {
                        $compile(elem)(scope);
                    }, 0, false);
                }
            }
        }
    };
}]);

coreDirectives.directive('setRepeat', [function () {

    return {
        transclude: 'element',
        priority: 1000,
        compile: compileFun
    };

    function compileFun(element, attrs, linker) {
        var expression = attrs.setRepeat.split(' in ');
        expression = {
            child: expression[0],
            property: expression[1]
        };

        return {
            post: repeat
        };

        function repeat(scope, iele, iattrs /*, attr*/) {
            var template = element[0].outerHTML;
            var data = scope.$eval(expression.property);
            addElements(data, scope, iele);

            return;

            function makeNewScope(index, expression, value, scope, collection) {
                var childScope = scope.$new();
                childScope[expression] = value;
                childScope.$index = index;
                childScope.$first = (index === 0);
                childScope.$last = (index === (collection.length - 1));
                childScope.$middle = !(childScope.$first || childScope.$last);

                /**
                 *
                 * uncomment this if you want your children to keep listening for changes
                 *
                 **/

                //childScope.$watch(function updateChildScopeItem(){
                //childScope[ident.value] = coll[idx];
                //});
                return childScope;
            }

            function addElements(collection, scope, insPoint) {
                var frag = document.createDocumentFragment();
                var newElements = [],
                    element, idx, childScope;

                angular.forEach(data, function (v, i) {
                    childScope = makeNewScope(i, expression.child, v, scope, collection);
                    element = linker(childScope, angular.noop);
                    newElements.push(element);
                    frag.appendChild(element[0]);
                });

                insPoint.after(frag);
                return newElements;
            }
        }
    }

}]);

coreDirectives.directive('liquidText', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            var self = elem,
                maxFontSize = attrs.maxFontSize ? parseInt(maxFontSize, 10) : null,
                fontSize = parseInt(self.css("fontSize"), 10);

            function calcSize() {
                var orgText = scope.$eval(attrs.liquidText);

                if (!orgText || orgText && orgText.indexOf('null') != -1)
                    return;

                var maxWidth = self.innerWidth(),
                    ourText = $("<span style='visability:hidden;'>" + orgText + "</span>").appendTo($('body')),
                    multiplier = maxWidth / ourText.width(),
                    newSize = fontSize * (multiplier - 0.1),
                    clone = elem.clone(),
                    cloneWrapper = $('<div style="height:0;width:0;visability:hidden;overflow:hidden"></div>');

                clone.empty().css('visability', 'hidden').appendTo(cloneWrapper);
                cloneWrapper.appendTo(self);
                var maxHeight = clone.innerHeight();

                self.css("fontSize", (maxFontSize && maxFontSize > 0 && newSize > maxFontSize) ? maxFontSize : newSize);
                var scrollHeight = self[0].scrollHeight;

                if (scrollHeight > maxHeight && maxHeight != 0) {
                    multiplier = maxHeight / scrollHeight;
                    newSize = (newSize * multiplier);
                    self.css(
                        "fontSize",
                        (maxFontSize && maxFontSize > 0 && newSize > maxFontSize) ?
                            maxFontSize :
                            newSize
                    );
                }

                $timeout(function () {
                    ourText.remove();
                    cloneWrapper.remove();
                }, 0, false);
            }

            calcSize();

            scope.$watch(attrs.liquidText, function () {
                calcSize();
            });

            scope.$on('$windowResize', function () {
                calcSize();
            });
        }
    };
}]);

coreDirectives.directive('ellipsis', function () {
    return {
        restrict: 'C',
        link: function (scope, elem, attrs) {
            var ele = elem[0];

            var slide_timer,
                max = ele.scrollWidth,
                slide = function () {
                    ele.scrollLeft += 1;
                    if (ele.scrollLeft < ele.scrollWidth) {
                        slide_timer = setTimeout(slide, 40);
                    }
                };

            ele.onmouseover = ele.onmouseout = function (e) {
                e = e || window.event;
                e = e.type === 'mouseover';
                clearTimeout(slide_timer);
                //tweet.className = e ? '' : 'hiding';
                if (e) {
                    elem.css('overflow', 'auto');
                    elem.css('overflow', 'hidden');
                    elem.css('text-overflow', 'clip');
                    slide();
                } else {
                    elem.css('text-overflow', '');
                    ele.scrollLeft = 0;
                }
            };
        }
    };
});

coreDirectives.directive("popoverHtmlUnsafePopup", function () {
    return {
        restrict: "EA",
        replace: true,
        scope: { title: "@", content: "@", placement: "@", animation: "&", isOpen: "&" },
        // can not find file
        templateUrl: "partials/predicted-revenue/popover-html-unsafe-popup.html"
    };
}).directive("popoverHtmlUnsafe", ["$uibTooltip", function ($uibTooltip) {
    return $uibTooltip("popoverHtmlUnsafe", "popover", "click");
}]);

coreDirectives.directive('fitText', ['$timeout', function ($timeout) {
    return {
        link: function (scope, elem, attrs) {
            init();
            scope.$on('resizeText', init);
            scope.$on('$$rebind::bindHeight', function () {
                $timeout(init, 0, false);
            });

            function init() {
                if (attrs.fitTextDelay) {
                    $timeout(function () {
                        elem.textfill({ minFontPixels: 10, ignoreWidth: attrs.fitText });
                    }, 0, false);
                }
                else {
                    elem.textfill({ minFontPixels: 10, ignoreWidth: attrs.fitText });
                }
            }
        }
    };
}]);

coreDirectives.directive('childScope', ['$parse', function ($parse) {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, elem, attrs, ctrl) {
            if (!attrs.childScope) return;

            var cs = scope.$parent.$eval(attrs.childScope);

            if (angular.isObject(cs)) {
                angular.forEach(cs, function (value, key) {
                    $parse(key).assign(scope, scope.$parent.$eval(value));
                });
            }
        }
    };
}]);

coreDirectives.directive('lClass', [function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            if (!attrs.lClass) return;

            var w = scope.$watch(function () {
                var cls = scope.$eval(attrs.lClass);
                if (!angular.isObject(cls)) {
                    w();
                    return;
                }

                angular.forEach(cls, function (condition, cl) {
                    if (condition) {
                        elem.addClass(cl);
                    } else {
                        elem.removeClass(cl);
                    }
                });
            });
        }
    };
}]);

coreDirectives.directive('flippy', ['$timeout', function ($timeout) {
    return {
        restrict: 'EA',
        link: function ($scope, $elem, $attrs) {

            var options = {
                flipDuration: ($attrs.flipDuration) ? $attrs.flipDuration : 400,
                timingFunction: 'ease-in-out',
            };

            // setting flip options
            angular.forEach(['flippy-front', 'flippy-back'], function (name) {
                var el = $elem.find(name);
                if (el.length == 1) {
                    angular.forEach(['', '-ms-', '-webkit-'], function (prefix) {
                        angular.element(el[0]).css(prefix + 'transition', 'all ' + options.flipDuration / 1000 + 's ' + options.timingFunction);
                    });
                }
            });

            /**
			 * behaviour for flipping effect.
			 */
            $scope.flip = function () {
                $elem.toggleClass('flipped');
                var f = $elem.hasClass('flipped'),
                    t = f ? 0 : 500;
                $timeout(function () {
                    $scope.flipped = $elem.hasClass('flipped');
                }, t, false);
            };
        }
    };
}]);

coreDirectives.directive('ngScroll', ['$parse', function ($parse) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            if (!attrs.ngScroll) { return; }
            var getter = $parse(attrs.ngScroll),
                isFunction = angular.isFunction(getter(scope));

            elem.scroll(change);

            function change() {
                if (isFunction) {
                    getter(scope)();
                } else {
                    getter(scope);
                }
            }
        }
    };
}]);

coreDirectives.directive('bindHtmlCompile', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            scope.$watch(function () {
                return scope.$eval(attrs.bindHtmlCompile);
            }, function (value) {
                element.html(value);
                $compile(element.contents())(scope);
            });
        }
    };
}]);

coreDirectives.directive('scopeInit', ['$parse', function ($parse) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            if (!attrs.scopeInit) { return; }
            var getter = $parse(attrs.scopeInit),
                isFunction = angular.isFunction(getter(scope));

            if (isFunction) {
                getter(scope)(scope);
            }
        }
    };
}]);

coreDirectives.directive('include', ['$templateCache', '$compile', '$http', '_', function ($templateCache, $compile, $http, _) {
    return {
        restrict: 'E',
        //transclude
        link: function (scope, elem, attrs) {
            if (!attrs.src) { return; }

            //var oldSettings = _.templateSettings.interpolate,
            var tpl = $templateCache.get(scope.$eval(attrs.src)),
                map = attrs.map ? scope.$eval(attrs.map) : null;

            if (map && !angular.isObject(map)) { return; }

            //_.templateSettings.interpolate = /\[\[([\s\S]+?)\]\]/g;

            if (!tpl) {
                $http.get(scope.$eval(attrs.src)).success(function (data) {
                    tpl = data;
                    replace();
                });
            } else {
                replace();
            }

            function replace() {
                if (map) {
                    angular.forEach(map, function (v, k) {
                        var regex = new RegExp(_.escapeRegExp(k), 'g');
                        tpl = _.replace(tpl, regex, v);
                    });
                }

                //var compiled = _.template(tpl)({$map:map});

                elem.append($compile(tpl)(scope));

                //_.templateSettings.interpolate = oldSettings;
            }
        }
    };
}]);

coreDirectives.directive('scopeRef', [function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            scope.$this = function () {
                return scope;
            };
        }
    };
}]);

coreDirectives.directive('includeReplace', [function () {
    return {
        require: 'ngInclude',
        restrict: 'A', /* optional */
        link: function (scope, el, attrs) {
            el.replaceWith(el.children());
        }
    };
}]);

coreDirectives.directive('body', [function () {
    return {
        restrict: 'E',
        link: function (scope, elem, attrs) {
            var appliedClass;

            function calcClasses(s, size) {
                var windowSize;
                if (size.width >= 1600) { //xlg
                    windowSize = 'extra-large-window';
                }
                else if (size.width >= 1200) { //lg
                    windowSize = 'large-window';
                }
                else if (size.width >= 992) { //md
                    windowSize = 'medium-window';
                }
                else { //sm
                    windowSize = 'small-window';
                }

                if (!appliedClass) {
                    $(elem).addClass(windowSize);
                    appliedClass = _.clone(windowSize);

                } else if (windowSize != appliedClass) {
                    $(elem).addClass(windowSize);
                    $(elem).removeClass(appliedClass);
                    appliedClass = _.clone(windowSize);
                }
                scope.windowSize = windowSize;
            }
            scope.$on('$windowResize', calcClasses);
        }
    };
}]);
