(function () {
    'use strict';

    app.directive('headerSelector', function () {
        return {
            restrict: 'E',
            template: require('./header-selector.html'),
            controller: selectorCtrl,
            controllerAs: "$ctrl",
            bindToController: true,
            scope: {
                options: "=",
                ngModel: '=',
                ngIf: '=',
                label: '='
            }
        };
    });

    selectorCtrl.$inject = [];

    /**
     * Controller for the directive.
     */
    function selectorCtrl() { }

})();
