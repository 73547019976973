app.controller('dataCycleConfigCtrl', ['$scope', '$http', '$window', '$location', 'utilities', '$cookies', 'api', "$state", '_', function ($scope, $http, $window, $location, utilities, $cookies, api, $state, _) {
    $scope.cookies = $cookies;
    var globalPropertyCall;

    $scope.demo = $cookies.get('BrightFunnelDemo') ? angular.fromJson($cookies.get('BrightFunnelDemo')) : { companyName: 'Acme Inc.' };

    function refresh_organizations() {
        $http.get('admin/organizations').success(function (data) {
            $scope.orgs.organizations = data;
        });
    }

    function applyMask(tab) {
        if ((tab == "permission" || tab == "users" || tab == "organization") && window.location.href.indexOf("stage.brightfunnel") > 1) {
            $scope.data.mask[tab] = true;
        } else if ((tab == "dataCycle" || tab == "olf" || tab == "waterfall") && $scope.data.stageActive && window.location.href.indexOf("app.brightfunnel") > 1) {
            $scope.data.mask[tab] = true;
        }
    }

    $scope.orgChanged = function () {
        $state.current.data.loading = true;
        $scope.data.company = [];
        $scope.data.domain = [];
        $scope.data.olf = null;
        $http.get('admin/org_configurations?orgId=' + $scope.query.orgId).success(function (data) {
            $scope.hidden = data.features;
            $scope.query.groupId = Object.keys(data.groups)[0];
            $scope.org_info = data.org_info;

            $scope.data.featureGroups = data.groups;
            $scope.data.users = data.users;
            $scope.data.org_influence_rules = data.influence_rules;
            $scope.data.olf = data.olf;
            $scope.data.oppty = data.oppty;
            $scope.data.attribution = data.attribution;

            if ($scope.configs.extraCampaignDateTypes[data.org_info.id]) {
                $scope.configs.campaignDateTypes = $scope.configs.defaultCampaignDateTypes.concat($scope.configs.extraCampaignDateTypes[data.org_info.id]);
            }

            $state.current.data.loading = false;
        });
    };

    $scope.clear_user_updates = function () {
        $scope.user_updates = {};
        $http.get('admin/users').success(function (data) {
            $scope.users = data;
            angular.forEach($scope.users, function (u) { u.updated_org_id = u.org_id; });
        });
    };

    $scope.updateOrgInfluence = function (rule) {
        var rules = $scope.data.org_influence_rules[rule.type];

        var params = {
            rules: rules,
            type: rule.type,
            orgId: $scope.query.orgId
        };
        api.set('admin/update_influence_rules', params, true).success(function () {

        });
    };

    $scope.updateOrgConf = function (save, module, field, type, value) {
        var params = { value: value, module: module, field: field, type: type, orgId: $scope.query.orgId };
        if (save) {
            // eslint-disable-next-line no-unused-vars
            api.set("admin/update_org_config", params, false).then(function (data) {
            });
        } else {
            params.delete = true;
            // eslint-disable-next-line no-unused-vars
            api.set("admin/update_org_config", params, false).then(function (data) {
                if (module == "olf" && type == "score_threshold") {
                    $scope.data.olf.score_threshold = 0;
                }
            });
        }
    };

    (function init() {
        $scope.query = {
            orgId: 0,
            pageId: "app.campaigns.attribution",
            featureId: "ceff.model.toggle"
        };
        $scope.data = {
            //			featureGroups: {},
            //			selectedFeatureGroupKey: "",
            //			showEditFeatureGroup: false,
            mask: { permission: false, dataCycle: false, users: false, organization: false },
            //            stageActive: false,
            //            newAccount: "",
            //            ignoreType: "domain",
            //            ignoreOptions: ['domain', 'account', 'company'],
            //            domain: [],
            //            company: [],
            //            marketingRoles: [
            //                {value:"", ui:""},
            //                {value:"demand generation", ui:"Demand Generation"},
            //                {value:"marketing operations", ui:"Marketing Operations"},
            //                {value:"field marketing", ui:"Field Marketing"},
            //                {value:"sales manager", ui:"Sales Manager"},
            //                {value:"executive", ui:"Executive"},
            //                {value:"other marketing", ui:"Other Marketing"}
            //            ],
            activeOrgsOnly: true
        };

        $scope.configs = {
            page: {},
            feature: {
                "ceff.model.toggle": { key: "ceff.model.toggle", name: "Campaign Effectiveness Model Toggle", show: true },
                "waterfallStages": { key: "waterfallStages", name: "Waterfall Stages", show: true },
                "disableInsights": { key: "disableInsights", name: "Insights", show: true },
                "path2Sale.touchesByCamp": { key: "path2Sale.touchesByCamp", name: "Path To Sale Campaign Touches", show: true },
                "dashboard beta": { key: "dashboard.beta", name: "dashboard beta", show: true }
            },
            org_influence_rules: { cio: [], lto: [] },
            defaultCampaignDateTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
            campaignDateTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
            opptyAmountType: ['ARR', 'MRR'],
            onOff: ['ON', 'OFF'],
            extraCampaignDateTypes: { 16: ["Last Member Status Change"] }
        };

        function reduce() {
            // eslint-disable-next-line no-unused-vars
            var m = _.flatten($scope.subnav.map(function (c) {
                return c.children;
            }));
        }

        reduce();

        $scope.orgs = {
            new_organization_name: '',
            organization: []
        };

        angular.forEach(_.compact(_.flatten($scope.subnav.map(function (c) {
            return c.children;
        }))), function (s) {

            pushName(s);
            if (s.tabs) {
                // eslint-disable-next-line no-unused-vars
                angular.forEach(s.tabs, function (v, i) {
                    pushName(v);
                });
            }

            function pushName(obj) {
                $scope.configs.page[obj.name] = {
                    key: obj.name,
                    name: (obj.config.data.title ? obj.config.data.title : obj.config.data.staticTitle) + (obj.config.data.subtitle ? ': ' + obj.config.data.subtitle : ''),
                    show: true
                };
            }
        });
        // adding on manually that is not in the subnav
        $scope.configs.page["app.campaign.sfdc"] = { key: "app.campaign.sfdc", name: "Campaign SFDC data", show: true };

        $http.get('admin/internal_config').success(function (data) {
            var active = [];
            data.orgs.forEach(function (item) {
                if (item.active) {
                    active.push(item);
                }
            });
            $scope.configs.orgs = [{ id: 0, name: "--Select an org--" }].concat(active);
            $scope.configs.orgsActive = [{ id: 0, name: "--Select an org--" }].concat(active);
            $scope.configs.orgsAll = [{ id: 0, name: "--Select an org--" }].concat(data.orgs);
            $scope.configs.influence_rules = data.influence_rules;
        });

        (globalPropertyCall = api.get("globalProperty", { name: "stage.active" }, false, true)).then(function (data) {
            $scope.data.stageActive = data.data[0].value == "true";
        });

        $scope.$on('$destroy', function () {
            if (globalPropertyCall) { globalPropertyCall.abort(); }
        });

        applyMask("dataCycle");
        refresh_organizations();
        $scope.clear_user_updates();
        $scope.show_unassigned_users = true;

    })();
}]);
