import {ListItem} from './list-item';
import {HttpSrvc} from './http.service';
import {FolderItem} from './folder-item';
import {
    ADD_TO_LIST_ENDPOINT,
    CREATE_LIST_ENDPOINT,
    GRAILS_URL_VAR, LIST_CHUNK_SIZE, REMOVE_ALL_FROM_LIST_ENDPOINT,
    REMOVE_FROM_LIST_ENDPOINT
} from '../constants/constants';

export class AccountListsSrvc {

    static $inject: Array<string> = ['httpSrvc', 'environmentSrvc'];

    public constructor(private httpSrvc: HttpSrvc, private environmentSrvc: any) {
        return;
    }

    public createList(folder: FolderItem, listName: string): Promise<any> {
        return this.environmentSrvc.getVariable(GRAILS_URL_VAR).then((baseHost: string) => {
            return this.httpSrvc.post(baseHost + CREATE_LIST_ENDPOINT,
                {folder_id: folder.id, name: listName}, true
            ).then((response) => {
                return response.data;
            });
        });
    }

    private chunkPost(listItem: ListItem, accountIds: string[],
        endpoint: string, collectFunc: (results: any[]) => any): Promise<any> {
        return this.environmentSrvc.getVariable(GRAILS_URL_VAR).then((baseHost: string) => {
            const chunkData: any = [];
            const chunkResults: any[] = [];

            for (let i = 0; i < accountIds.length; i += LIST_CHUNK_SIZE) {
                chunkData.push(accountIds.slice(i, i + LIST_CHUNK_SIZE));
            }

            const lastPromise = chunkData.reduce((accumulator, data) => {
                return accumulator.then(() => {
                    return this.httpSrvc.post(baseHost + endpoint,
                        {list_id: listItem.id, account_ids: data, csv_upload: true}, true
                    ).then((response) => {
                        chunkResults.push(response.data);
                        return;
                    });
                });
            }, Promise.resolve());

            return lastPromise.then(() => {
                return collectFunc(chunkResults);
            });
        });
    }

    public addToList(listItem: ListItem, accountIds: string[]): Promise<any> {
        const collectFunc = (results: any[]) => {
            const result = {
                list_id: 0,
                new_members: 0,
                duplicate_members: 0,
                total_members: 0
            };

            for (const dataItem of results) {
                result.list_id = dataItem.list_id;
                result.new_members += dataItem.new_members;
                result.duplicate_members += dataItem.duplicate_members;
                result.total_members = dataItem.total_members;
            }
            return result;
        };
        return this.chunkPost(listItem, accountIds, ADD_TO_LIST_ENDPOINT, collectFunc);
    }

    public removeFromList(listItem: ListItem, accountIds: string[]): Promise<any> {
        const collectFunc = (results: any[]) => {
            const result = {
                list_id: 0,
                removed_members: 0,
                total_members: 0,
            };

            for (const dataItem of results) {
                result.list_id = dataItem.list_id;
                result.removed_members += dataItem.removed_members;
                result.total_members = dataItem.total_members;
            }
            return result;
        };
        return this.chunkPost(listItem, accountIds, REMOVE_FROM_LIST_ENDPOINT, collectFunc);
    }

    public removeAllFromList(listItem: ListItem): Promise<any> {
        return this.environmentSrvc.getVariable(GRAILS_URL_VAR).then((baseHost: string) => {
            return this.httpSrvc.post(baseHost + REMOVE_ALL_FROM_LIST_ENDPOINT,
                {list_id: listItem.id}, true
            ).then((response) => {
                return response.data;
            });
        });
    }
}
