export class RowList {
    public constructor(private _title: string, private _num: number) {
    }

    get title(): string {
        return this._title;
    }

    get num(): number {
        return this._num;
    }
}
