/**
 * Provider to configure all the application routes.
 * It queries the backend to get userdata and then
 * configure all states.
 */
(function (w) {
    'use strict';

    var app = angular
        .module('brightfunnel');

    w['app'] = app;

    app.provider('routes', routesProvider);

    routesProvider.$inject = ['$stateProvider', '$urlRouterProvider', '$httpProvider'];

    // eslint-disable-next-line no-unused-vars
    function routesProvider($stateProvider, $urlRouterProvider, $httpProvider) {

        var DEFAULT_APP_STATE = {
            state: 'app.dashboard',
            stateParams: undefined
        };
        var REDIRECT_STORAGE = 'ngStorage-redirect';

        /**
         * Defines application states to initialize the state provider.
         * @type {{children: Array, name: string,
         *          config: {blind: boolean,
         *                  controller: *[],
         *                  resolve: {getReports: (function(*, *): boolean),
         *                              getAccountListsForFilters: (function(*, *, *, *): boolean),
         *                              revenueGroups: (function(*, *): *),
         *                              version: (function(*, *): *),
         *                              loadTimes: (function(*, *, *=): *),
         *                              sfdcUrl: (function(*, *): (string|*)),
         *                              getFilters: (function(*, *, *, *): boolean),
         *                              api: string,
         *                              ipad: config.resolve.ipad,
         *                              updated: (function(*, *, *): boolean),
         *                              colorMap: (function(*, *, *): *),
         *                              orgConfig: (function(*, *): boolean),
         *                              wfStages: (function(*, *): *),
         *                              cohorts: (function(*, *, *): *)},
         *                              abstract: boolean,
         *                              url: string,
         *                              templateUrl: (*|*|*)}}[]}
         */
        var newStates = [{
            name: 'app',
            config: {
                abstract: true,
                blind: true,
                url: '?filter1&filter2&filter3&filter4&filter5&filter6&filter7&filter8&filter9&filter10&filter11&filter12&tag&al',
                templateUrl: './main/index.html?version=' + Date.now(),
                resolve: {
                    api: "api",
                    version: function () {
                        return 0;
                    },
                    getFilters: function (api, $rootScope, filters, $location) {
                        return !$rootScope.filters ? api.loadDataFilters().then(function (data) {

                            $rootScope.filters = filters.createFiltersObject(data);
                            filters.initializeFilters($rootScope.filters, filters.makeSelectedFilterMap($location.search()), "sync");
                        }) : true;
                    },
                    getAccountListsForFilters: function (api, $rootScope, filters, $location) {
                        return !$rootScope.accountLists ? api.loadAccountFolders().then(function (folders) {
                            $rootScope.accountLists = { folders: folders, selected: 0, applied: 0, select_apply_diff: 0 };
                            filters.initializeFolders($rootScope.accountLists, filters.getListIdsFromParams($location.search()), "sync");
                        }) : true;
                    },
                    getReports: function (api, $rootScope) {
                        return !$rootScope.savedReports ? api.loadSavedReports().then(function (data) {
                            $rootScope.savedReports = data;
                        }) : true;
                    },
                    sfdcUrl: function (api, $rootScope, $q) {
                        if (!$rootScope.sfdcURL) {
                            return api.getter(
                                {
                                    url: 'organizationInfo/get_crm_url',
                                    paramsNotToUpdate: 'all'
                                }).then(function (resp) {
                                $rootScope.sfdcURL = resp.data.crm_url ? resp.data.crm_url : "sf_url_missing";
                            });
                        }
                        return $q.when();
                    },
                    orgConfig: function (api, $rootScope, _, utilities) {
                        return !$rootScope.orgConfig ? api.loadAndSetOrgConfig().then(function (data) {
                            $rootScope.orgConfig = data.data;
                            utilities.filterStates($rootScope.userData, newStates[0], _, $stateProvider);
                        }) : true;
                    },
                    ipad: ['$rootScope', 'utilities', function ($rootScope, utilities) {
                        $rootScope.menu = {
                            collapsed: false,
                            slide: false,
                            sliding: false
                        };
                        $rootScope.ipad = utilities.ipad();
                        if ($rootScope.ipad) {
                            $rootScope.menu.collapsed = true;
                        }
                    }],
                    wfStages: function ($rootScope, api, $q) {
                        if (!$rootScope.wfStages) {
                            return api.getter(
                                {
                                    url: 'load_wf_stages',
                                    params: {},
                                    paramsNotToUpdate: 'all'
                                }).then(function (data) {
                                $rootScope.wfStages = data.data;
                            });
                        }
                        return $q.when($rootScope.wfStages);
                    },
                    cohorts: function (api, $q, $rootScope) {
                        if (!$rootScope.rawCohorts) {
                            return api.getter(
                                {
                                    url: 'cohorts_for_org',
                                    params: { withDates: "true" },
                                    paramsNotToUpdate: 'all'
                                }).then(function (data) {
                                //push current quarter in
                                data.data.quarter.past.push(data.data.quarter.current);
                                //push current year in
                                data.data.year.past.push(data.data.year.current);
                                $rootScope.rawCohorts = data.data;
                                return $rootScope.rawCohorts;
                            });
                        }

                        return $q.when($rootScope.rawCohorts);
                    },
                    updated: function ($rootScope, $http, api) {
                        return !$rootScope.last_updated_date ? $http.get(api.evalUri('last_update') + '?tstamp=' + $.now()).then(function (data) {
                            data.data.last_update = moment.utc(new Date(data.data.last_update)).toDate();
                            $rootScope.last_updated_date = data.data;
                            $rootScope.$broadcast('$$rebind::dataRefreshed');
                        }) : true;
                    },
                    revenueGroups: function ($rootScope, api, _, $q) {
                        if (!$rootScope.revenueGroups) {
                            return api.getter(
                                {
                                    url: 'revenue_groups',
                                    params: {},
                                    paramsNotToUpdate: 'all'
                                }).then(function (data) {
                                var i = _.findIndex(data.data, function (d) {
                                    return d === "";
                                });
                                if (i > -1) {
                                    data.data[i] = '(No Campaign Type)';
                                }
                                return $rootScope.revenueGroups = data.data;
                            });
                        }
                        return $q.when($rootScope.revenueGroups);
                    },
                    colorMap: function ($state, $stateParams, Colors) {
                        return Colors.setColorMap();
                    },
                    sigstrProductType: function (sigstrSrvc, $q) {
                        if(sigstrSrvc.getProductType() == null){
                            return sigstrSrvc.initialize();
                        }
                        else return $q.when(sigstrSrvc.getProductType());
                    }
                },
                controller: ['$rootScope', '$filter', '$state', '_', 'utilities', function ($rootScope, $filter, $state, _, utilities) {
                    const STUDIO_STATE_DATA_STUDIO = 'DATA_STUDIO';
                    const STUDIO_STATE_MEASUREMENT_STUDIO = 'MEASUREMENT_STUDIO';
                    // eslint-disable-next-line no-undef
                    const bfStateProvider = new BFStateProvider();
                    const isDataStudio = utilities.isDataStudioReport($state.current.name) || (utilities.isSettingsPage($state.current.name) && $rootScope.studioState === STUDIO_STATE_DATA_STUDIO);
                    const navState = bfStateProvider.getNavTree($rootScope.userData.platform, isDataStudio);
                    const userData = $rootScope.userData;

                    $rootScope.studioState = isDataStudio ? STUDIO_STATE_DATA_STUDIO : STUDIO_STATE_MEASUREMENT_STUDIO;
                    $rootScope.nav = _.cloneDeep(newStates);
                    $rootScope.newNav = navState;
                    $rootScope.subnav = utilities.filterSubNav(userData, navState);

                    $rootScope.settings = $filter('filter')(newStates[0].children, function (state) {
                        if (state.name === 'app.settings' && state.status !== "removed") {
                            return state;
                        }
                    });

                    $rootScope.$states = [];

                    function pushState(s) {
                        //var sc = angular.copy(s);
                        // eslint-disable-next-line no-unused-vars
                        angular.forEach(s, function (state, i) {
                            $rootScope.$states.push(state);
                            if (state.children) {
                                pushState(state.children);
                            }
                            if (state.tabs) {
                                pushState(state.tabs);
                            }
                        });
                    }

                    pushState(newStates);

                    $rootScope.stateRaw = function () {
                        return angular.findWhere($rootScope.$states, { name: $state.current.name });
                    };

                }]
            },

            children: []
        }];

        /**
         * Get the user data from the backend
         * @param $http The AngularJS http Object
         * @param $rootScope The AngularJS rootScope Object
         * @param $q The AngularJS q Object
         * @returns {Promise<*>} A promise resolved with the data
         *  or rejected with the error.
         */
        function getUserData($http, $rootScope, $q) {
            if (!$rootScope.userData) {
                const endpoint = `/userData/get_user_data`;
                return $http.get(endpoint, { withCredentials: false });
            }
            return $q.reject();
        }

        /**
         * The the Angular path from the URL fragment
         * @param fragmentState The URL frament
         * @returns {string} A string with the application path.
         */
        function getFragmentState(fragmentState) {
            var pattern = /(\/[0-9a-z-/]+)(?:\?)?/i;
            var match = fragmentState.match(pattern);
            return match ? match[1] : "";
        }

        /**
         * The state parameters extracted from the URL fragment.
         * Not sure why I am finding quotes at the end of the query.
         * That is why it is there. Just in case and remove it.
         * @param url The URL fragment
         * @returns {Object} A list of key pairs
         */
        function getStateParams(url) {
            var pattern = /(\?.*)$/i;
            var match = url.match(pattern);
            if (match) {
                var urlStateParams = new URLSearchParams(match[1]);
                var stateParams = {};
                var entries = Array.from(urlStateParams.entries());
                for (var i = 0; i < entries.length; i++) {
                    stateParams[entries[i][0]] = entries[i][1];
                }
                return stateParams;
            } else {
                return {};
            }
        }

        /**
         * Check if a state has a matching path to the path
         * required to follow
         * @param $state The $state service
         * @param state The current state
         * @param matchUrl The mathching path
         * @returns {null|*} A matching state or null if not found.
         */
        function checkState($state, state, matchUrl) {
            if (state) {
                var fragment = $state.href(state.name, {}, { absolute: false });
                var url = undefined;
                if (fragment) {
                    url = getFragmentState(fragment);
                }

                if (url === matchUrl) {
                    return state;
                }
            }
            return null;
        }

        /**
         * Go to the state with a matching path
         * @param $state The state service
         * @param urlToMatch The matching path
         * @returns {{stateParams: undefined, state: string}|{stateParams: ({}|{}), state: *}}
         *  The state name and state params.
         */
        function goToPath($state, urlToMatch) {
            var stateUrl = "";
            var stateParams;
            // Return to 'home', if no navigation url is present
            if (!urlToMatch) {
                return DEFAULT_APP_STATE;
            } else {
                stateUrl = getFragmentState(urlToMatch);
                if (!stateUrl) {
                    return DEFAULT_APP_STATE;
                }
                stateParams = getStateParams(urlToMatch);
            }

            // Look-up state from navigation url
            var matchedState,
                states = $state.get();
            for (var i = 0; i < states.length; i++) {
                var state = states[i];

                var checkResult = checkState($state, state, stateUrl);
                if (checkResult) {
                    matchedState = { state: checkResult.name, stateParams: stateParams };
                    break;
                }
            }

            if (matchedState) {
                // Valid deep-link found
                return matchedState;
            } else {
                // Invalid deep-link, redirect to default
                return DEFAULT_APP_STATE;
            }
        }

        /**
         * Initializes all the views for the app. The main requirement here
         * is to wait fot all the modules to load, get the user data
         * in an authenticated HTTP GET request and configure the
         * states for the application.
         * @param $q Deferred Object
         * @param $rootScope Rootscope for the app
         * @param $http Module to make the GET request
         * @param $state State object
         * @param authSrvc The authentication service to load the JWT
         * @param Constants Required constants
         * @param utilties
         * @returns {{initialize: (function(): *)}} The initializer.
         */
        this.$get = ['$q', '$rootScope', '$http', '$state', 'storageSrvc', 'filters',
            'authSrvc', 'Constants', 'utilities', '_',
            function ($q, $rootScope, $http, $state, storageSrvc, filters,
                authSrvc, Constants, utilities, _) {

                function initializeAngularStates() {
                    return getUserData($http, $rootScope, $q).then(function (response) {
                        var data = response.data;
                        if (data.op === 'get') {
                            var userData = response.data.userData;
                            window.bfEnvironment = response.data.environment;
                            window.version = response.data.version;

                            if (!userData) {
                                throw 'No user data';
                            }
                            userData.isAdmin = function () {
                                return this.permissions.bf_admin || this.permissions.org_admin;
                            };
                            $rootScope.userData = userData;
                            window.maintCheck(userData);

                            // eslint-disable-next-line no-undef
                            const bfStateProvider = new BFStateProvider();

                            newStates[0].children = bfStateProvider.getReportTree(userData.platform);

                            $urlRouterProvider.otherwise('/dashboard');

                            $stateProvider.state(newStates[0].name, newStates[0].config);
                            utilities.filterStates(userData, newStates[0], _, $stateProvider);
                            if (!$state.current || $state.current.abstract) {
                                const urlToMatch = storageSrvc.load(REDIRECT_STORAGE);
                                if (urlToMatch) {
                                    storageSrvc.save(REDIRECT_STORAGE, undefined);
                                }
                                const newState = goToPath($state, urlToMatch);
                                /* ---- DELETE THIS CODE AFTER MIGRATING DASHBOARD FUNCTIONALITY (initializeAngularStates) ---- */
                                // if user was redirected from terminus-hub to dashboard route with routeId in query params
                                // then we have use these query params to set up a default tile
                                if (newState
                                    && newState.state === "app.dashboard"
                                    && newState.stateParams
                                    && newState.stateParams.routeId) {
                                    // get global filters from query params
                                    const globalFilters = filters.getGlobalFiltersFromDashboardParams(newState.stateParams);
                                    // remove filters from params and add new param - globalFilters
                                    const params = Object.assign(
                                        {},
                                        utilities.removeFilterValues(newState.stateParams),
                                        {
                                            globalFilters: JSON.stringify(globalFilters), // to keep filters in params after removing from url
                                            accountLists: newState.stateParams.al // to keep lists in params after removing from url
                                        }
                                    );
                                    $state.go(newState.state, params);
                                    /* ---- DELETE THIS CODE AFTER MIGRATING DASHBOARD FUNCTIONALITY (end) ---- */
                                } else {
                                    $state.go(newState.state, utilities.removeFilterValues(newState.stateParams));
                                }
                            }
                            return;

                        } else if (data.op === 'redirect') {
                            authSrvc.redirect(data.url);
                            throw 'Redirecting';
                        }
                    });
                }

                return {
                    initialize: function () {
                        // Store the JWT token if it is in the request
                        authSrvc.storeToken(Constants.TERMINUS_HUB);

                        return authSrvc.isLoggedIn(Constants.TERMINUS_HUB).then(() => {
                            return initializeAngularStates();
                        }, (error) => {
                            authSrvc.goToEndpoint(error);
                            throw 'Redirecting to login';
                        });
                    },
                    /* __start-test-block__ */
                    __test_only__: {
                        goToPath,
                        checkState,
                        getStateParams,
                        getFragmentState
                    }
                /* __end-test-block__ */
                };
            }];
    }
})(window);
