/**
 * Fetches bombora config data.
 */
(function () {
    'use strict';

    angular
        .module('bombora')
        .factory('bomboraConfigSrvc', bomboraConfigSrvc);

    bomboraConfigSrvc.$inject = ['$http', 'environmentSrvc'];

    /**
     * Service to handle bombora configs.
     */
    function bomboraConfigSrvc($http, environmentSrvc) {
        /**
         * This service
         * @type {{}}
         */
        var service = {};

        /**
         * Environment property containing bombora config endpoint
         * @type {string}
         */
        var config_url_env = "BOMBORA_CONFIG_URL";

        /**
         * Post request to Bombora config
         * @return {Promise<*>} A promise with response from the request
         */
        function postRequest(url, params) {
            return environmentSrvc.getVariable(config_url_env).then(function(host) {
                return $http.post(host+url,
                    JSON.stringify(params),
                    {withCredentials: false}
                ).then(
                    function (data) {
                        return data;
                    });
            });
        }

        service.setTopicLimit = function(params) {
            return postRequest("/v1/set-tracked-topics-limit", params);
        };

        service.setCanConfigureThreshold = function(params) {
            return postRequest("/v1/set-can-configure-threshold", params);
        };

        service.getConfiguredLimitThreshold = function(params) {
            return postRequest("/v1/get-config", params);
        };


        return service;
    }
})();
