/**
 * Switch User/God Mode Controller.
 */
(function () {
    'use strict';

    app
        .controller('switchUserModalCtrl', switchUserModalCtrl);

    switchUserModalCtrl.$inject = ['$scope', '$uibModalInstance', 'SwitchUserConstants', 'noty'];

    /**
     * Switch User Modal Controller.
     * @param $scope
     * @param noty
     */
    function switchUserModalCtrl($scope, $uibModalInstance, SwitchUserConstants) {

        activate();

        /**
         * Initialize the scope
         */
        function activate() {
            $scope.title = SwitchUserConstants.LABELS.TITLE;
            $scope.label = SwitchUserConstants.LABELS.LABEL;
            $scope.button = SwitchUserConstants.LABELS.BUTTON;
            $scope.placeholder = SwitchUserConstants.LABELS.PLACEHOLDER;
            $scope.user = '';
        }

        /**
         * Clicked if the user wants to login
         */
        $scope.login = function(){
            $uibModalInstance.close({ username: $scope.user });
        };

        /**
         * Clicked if the user wants to cancel.
         */
        $scope.cancel = function(){
            $uibModalInstance.close(null);
        };
    }
})();
