app.service('account', ['$b', '$rootScope', '$q', '_', 'api', 'sigstrSrvc', 'isFeatureEnabled',
    function ($b, $rootScope, $q, _, api, sigstrSrvc, isFeatureEnabled) {

        this.activity = function (name, params) {
            var model = {
                title: 'Marketing Activity: ' + name,
                id: 'marketing-activity',
                template: require('./activity.html'),
                width: '75%',
                x: true,
                maxed: false,
                controller: function ($scope, api) {
                    $scope._ = _;
                    $scope.data = {
                        loading: true,
                        name: name,
                        //these columns are seen in accounts trending metric (ex: % engaged by marketing) modal
                        columns: [
                            {
                                label: 'Campaign Name',
                                field: 'campaign.name'
                            },
                            {
                                label: 'Activity Date',
                                field: 'campaign_member.activity_date',
                                filter: 'date'
                            },
                            {
                                label: 'Person',
                                field: 'lead.name'
                            }]
                    };
                    api.getter({
                        url:'account/load_marketing_activity_details',
                        params: params,
                        paramsNotToUpdate: "all"
                    }).then(function (data) {
                        $scope.data.data = data.data;
                        $scope.data.loading = false;
                    });
                }
            };

            $b.modal(model);
        };

        this.listColumns = function (platform, sfURL) {
            var order = 0;
            var columns = [{
                label: 'Account Name',
                field: 'account_name',
                order: ++order,
                noDrag: true,
                visible: true,
                apiType: "account_columns",
                link: function (row, query) {
                    var s = 'app.analyze.accountsAccountSpecific.attribution';
                    if (query.startDate && query.endDate) {
                        return $rootScope.shref(s, {
                            accountId: row.account_id,
                            cohort: query.cohort,
                            startDate: query.startDate,
                            endDate: query.endDate
                        });
                    } else {
                        return $rootScope.shref(s, { accountId: row.account_id, cohort: query.cohort });
                    }
                }
            }];

            columns.push(
                {
                    label: 'Industry',
                    field: 'industry',
                    order: ++order,
                    defaultSort: false,
                    descendingFirst: true,
                    visible: false,
                    apiType: "firmographics"
                },
                {
                    label: 'Employee Range',
                    field: 'employee_range',
                    order: ++order,
                    defaultSort: false,
                    descendingFirst: true,
                    visible: false,
                    apiType: "firmographics"
                },
                {
                    label: 'Revenue Range',
                    field: 'revenue_range',
                    order: ++order,
                    defaultSort: false,
                    descendingFirst: true,
                    visible: false,
                    apiType: "firmographics"
                }
            );

            if (
                $rootScope.orgConfig.custom_columns
                && $rootScope.orgConfig.custom_columns.account
            ) {
                _.forEach($rootScope.orgConfig.custom_columns.account, function (v) {
                    var column = {
                        label: v.name,
                        field: v.column,
                        order: ++order,
                        defaultSort: false,
                        descendingFirst: true,
                        visible: false,
                        apiType: "account_columns",
                    };
                    if (v.field_type === "date") {
                        column.filter = "date";
                    } else if (v.field_type === "number") {
                        column.filter = "number";
                    }
                    columns.push(column);
                });
            }

            if ($rootScope.orgConfig.terminus_data) {
                columns.push(
                    {
                        label: 'Engagement Spike',
                        field: 'spike_date',
                        order: ++order,
                        filter: 'date',
                        defaultSort: false,
                        descendingFirst: true,
                        visible: true,
                        apiType: "engagement_spike"
                    },
                    {
                        label: 'Spike Model',
                        field: 'spike_models',
                        order: ++order,
                        defaultSort: false,
                        descendingFirst: true,
                        visible: true,
                        apiType: "engagement_spike"
                    },
                    {
                        label: 'Ad Impressions',
                        field: 'ad_impressions',
                        order: ++order,
                        filter: 'number',
                        defaultSort: false,
                        descendingFirst: true,
                        visible: true,
                        apiType: "ad_impressions"
                    },
                    {
                        label: 'Ad Clicks',
                        field: 'ad_clicks',
                        order: ++order,
                        filter: 'number',
                        defaultSort: false,
                        descendingFirst: true,
                        visible: true,
                        apiType: "ad_clicks"
                    },
                    {
                        label: 'Page Views',
                        field: 'page_views',
                        order: ++order,
                        filter: 'number',
                        defaultSort: false,
                        descendingFirst: true,
                        visible: true,
                        apiType: "web_visits"
                    }
                );
            }

            columns.push(
                {
                    label: 'Last Intent Date',
                    field: 'intent_date',
                    order: ++order,
                    defaultSort: false,
                    descendingFirst: true,
                    visible: false,
                    apiType: "intent",
                    filter: 'date'
                },
                {
                    label: 'Intent Topic',
                    field: 'topic',
                    order: ++order,
                    defaultSort: false,
                    descendingFirst: true,
                    visible: false,
                    apiType: "intent"
                }
            );

            if(sigstrSrvc.isEnabled()) {
                columns.push(
                    {
                        label: 'Propensity to Purchase',
                        field: 'intent_score',
                        order: ++order,
                        defaultSort: false,
                        descendingFirst: true,
                        visible: false,
                        apiType: "sigstr_relationship",
                        filter: 'number'
                    },
                    {
                        label: 'Relationship Score',
                        field: 'company_score',
                        order: ++order,
                        defaultSort: false,
                        descendingFirst: true,
                        visible: false,
                        apiType: "sigstr_relationship"
                    },
                    {
                        label: 'Sigstr First Email',
                        field: 'first_interaction',
                        order: ++order,
                        defaultSort: false,
                        descendingFirst: true,
                        visible: false,
                        apiType: "sigstr_relationship",
                        filter: 'date'
                    },
                    {
                        label: 'Sigstr Last Email',
                        field: 'last_interaction',
                        order: ++order,
                        defaultSort: false,
                        descendingFirst: true,
                        visible: false,
                        apiType: "sigstr_relationship",
                        filter: 'date'
                    });
            }

            columns.push({
                label: 'Owner',
                field: 'owner',
                order: ++order,
                defaultSort: false,
                descendingFirst: true,
                visible: false,
                apiType: "account_columns",
                // eslint-disable-next-line no-unused-vars
                link: function (row, query) {
                    if (sfURL) {
                        return sfURL + "/" + row.owner_id;
                    }
                }
            },
            {
                label: 'Known People',
                field: 'contacts',
                order: ++order,
                filter: 'number',
                defaultSort: false,
                descendingFirst: true,
                visible: true,
                apiType: "contact"
            },
            {
                label: 'Unique Campaign Respondents',
                field: 'influenced_contacts',
                order: ++order,
                filter: 'number',
                defaultSort: 'reverse',
                descendingFirst: true,
                visible: true,
                apiType: "influenced_contacts"
            },
            {
                label: 'Campaign Responses',
                field: 'campaign_members',
                order: ++order,
                filter: 'number',
                defaultSort: false,
                descendingFirst: true,
                visible: true,
                apiType: "campaign_member"
            }, {
                label: 'Parent Account',
                field: 'parent_account',
                order: ++order,
                defaultSort: false,
                descendingFirst: true,
                visible: false,
                apiType: "parent_account"
            });

            const FTR_MODEL_INFLUENCE_TYPE = 'feature-model-influence_type';
            if ($rootScope.userData.platform === 'full' || isFeatureEnabled(FTR_MODEL_INFLUENCE_TYPE)) {
                columns.push(
                    {
                        label: 'Pre-Opp Creation Responses',
                        field: 'pre',
                        order: ++order,
                        defaultSort: false,
                        descendingFirst: true,
                        visible: false,
                        filter: 'number',
                        apiType: "oppty_influence"
                    },
                    {
                        label: 'Post-Opp Creation Responses',
                        field: 'post',
                        order: ++order,
                        defaultSort: false,
                        descendingFirst: true,
                        visible: false,
                        filter: 'number',
                        apiType: "oppty_influence"
                    }
                );
            }

            if ($rootScope.orgConfig.salesActivity.enabled) {
                columns.push({
                    label: 'Sales Activity',
                    field: 'sales_activity',
                    order: ++order,
                    filter: 'number',
                    defaultSort: false,
                    descendingFirst: true,
                    visible: false,
                    apiType: "sales_activity"
                });
            }

            columns.push({
                label: 'Last Campaign Response Date',
                field: 'last_response',
                order: ++order,
                filter: 'date',
                defaultSort: false,
                descendingFirst: true,
                visible: true,
                apiType: "account_columns"
            },
            {
                label: 'Last Activity',
                field: 'last_activity',
                order: ++order,
                filter: 'date',
                defaultSort: false,
                descendingFirst: true,
                visible: platform !== 'full',
                apiType: "account_columns"
            },
            {
                label: 'Open Pipeline',
                field: 'open_pipeline',
                order: ++order,
                filter: 'bfCurrency',
                defaultSort: false,
                descendingFirst: true,
                visible: true,
                apiType: "open_opportunities"
            },
            {
                label: 'Open Opportunities',
                field: 'open_opptys',
                order: ++order,
                filter: 'number',
                defaultSort: false,
                descendingFirst: true,
                visible: true,
                apiType: "open_opportunities"
            },
            {
                label: 'Total Opportunities',
                field: 'opptys',
                order: ++order,
                filter: 'number',
                defaultSort: false,
                descendingFirst: true,
                visible: false,
                apiType: "opportunities"
            },
            {
                label: 'Total Deals',
                field: 'deals',
                order: ++order,
                filter: 'number',
                defaultSort: false,
                descendingFirst: true,
                visible: false,
                apiType: "deals"
            },
            {
                label: 'Total Pipeline',
                field: 'pipeline',
                order: ++order,
                filter: 'bfCurrency',
                defaultSort: false,
                descendingFirst: true,
                visible: false,
                apiType: "opportunities"
            },
            {
                label: 'Total Revenue',
                field: 'revenue',
                order: ++order,
                filter: 'bfCurrency',
                defaultSort: false,
                descendingFirst: true,
                visible: false,
                apiType: "deals"
            });

            return columns;
        };

        this.filters = function () {
            return [
                {
                    label: 'All Accounts',
                    key: 'all'
                },
                {
                    label: 'Needs Marketing',
                    key: 'needs_marketing'
                }, {
                    label: 'Needs Sales',
                    key: 'needs_sales'
                }, {
                    label: 'Needs Marketing & Sales',
                    key: 'needs_sales_and_marketing'
                },
                {
                    label: 'Needs Contacts',
                    key: 'needs_contacts'
                }, {
                    label: 'Has Open Opportunities',
                    key: 'has_open_opportunities'
                }, {
                    label: 'Active Accounts With No Open Opportunities',
                    key: 'active_with_no_opportunities'
                }, {
                    label: 'All Accounts With No Open Opportunities',
                    key: 'no_open_opportunities'
                }, {
                    label: 'Without Ad Impressions',
                    key: 'without_ad_impressions'
                }, {
                    label: 'With Ad Impressions',
                    key: 'with_ad_impressions'
                }, {
                    label: 'Without Ad Clicks',
                    key: 'without_ad_clicks'
                }, {
                    label: 'With Ad Clicks',
                    key: 'with_ad_clicks'
                }, {
                    label: 'Without Page Views',
                    key: 'without_page_views'
                }, {
                    label: 'With Page Views',
                    key: 'with_page_views'
                }
            ];
        };

        this.removePagingAndSortingParams = function (params) {
            if (params) {
                delete params.pg;
                delete params.ps;
                delete params.tbl;
                delete params.fld;
                delete params.dir;
            }

            return params;
        };

        this.addDefaultsIfNoData = function (added, account, defaults) {
            if (!added) {
                for (var key in defaults) {
                    account[key] = defaults[key];
                }
            }
            // eslint-disable-next-line no-redeclare
            for (var key in account) {
                if (account[key] === null) {
                    account[key] = defaults[key];
                }
            }
        };

        this.addResultsToAccountObject = function (accountChunks, destination, rows, defaults) {
            var that = this;
            for (var n in accountChunks) {
                accountChunks[n][destination[1]][destination[2]].forEach(function (account) {
                    var added = false;
                    for (var key in rows[account.account_id]) {
                        account[key] = rows[account.account_id][key];
                        added = true;
                    }
                    that.addDefaultsIfNoData(added, account, defaults);
                });
            }
        };

        this.getCsvUrl = function (newParams, headers) {
            var params = this.removePagingAndSortingParams(angular.copy(newParams));
            var visibleHeaders = [];
            headers.forEach(function (header) {
                if (header.visible) {
                    visibleHeaders.push(header.field);
                }
            });
            var headerParam = "";
            if (visibleHeaders.length > 0) {
                headerParam = "&visible=" + visibleHeaders.join(",");
            }
            return 'export/accountHub?' + _.queryString(params) + headerParam;
        };

        this.hasFilteringValues = function (params) {
            var hasFilterValues = false;

            for (var key in params) {
                var value = params[key];
                if (value !== null && value !== undefined && value.length > 0) {
                    if (key.indexOf("filter") === 0 && Array.isArray(value)) {
                        hasFilterValues = true;
                    } else if (key === "search") {
                        hasFilterValues = true;
                    } else if (key === "al" && value.split(",").length > 1) {
                        hasFilterValues = true;
                    } else if (key === "account_filter" && value !== 'all') {
                        hasFilterValues = true;
                    }
                }
            }

            return hasFilterValues;
        };

        this.getSortField = function (row, column, timeUnit) {
            if (row && column) {
                if (column.field === 'time_interval_formatted') {
                    switch (timeUnit) {
                    case 'day':
                    case 'week':
                    case 'month':
                    case 'year':
                    default:
                        return row.event_date_utc ? row.event_date_utc : row.visit_date_utc;
                    case 'fiscal_qtr':
                        return row.end_date;
                    }
                } else {
                    return row[column.sortField];
                }
            }
        };

        /**
         * Getting Spike model names
         * @return {Promise<*>} A promise with unique model names for the organization.
         */
        this.getModelNames = function () {
            var deferred = $q.defer();
            api.getter({ url: 'organizationInfo/loadSpikeModels', skipFilters: true }).then(
                function (data) {
                    if (data && data.data) {
                        deferred.resolve(data.data);
                    } else {
                        deferred.reject('No data');
                    }
                },
                function (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        };

    }]);
