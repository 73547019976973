/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
app.controller('campaignPerformanceCtrl', ['$scope', 'api', 'utilities', '$state', '$interval', '$timeout', '$filter', '$parse', '$b', '$rootScope', '$q', '$stateParams', 'colorMap', '$localStorage', 'campaignPerformance', '_', 'cohorts', 'userModels', 'influenceTypes',
    function ($scope, api, utilities, $state, $interval, $timeout, $filter, $parse, $b, $rootScope, $q, $stateParams, colorMap, $storage, campaignPerformance, _, cohorts, userModels, influenceTypes) {

        var tagsCall, dataCall;
        var errorRefresh = false;

        /*=============================================
        =  scope functions
        =============================================*/

        $scope.useNewInfluenceType = influenceTypes.useNewInfluenceType;

        $scope.isHeaderShowing = function (header) {
            var shouldLoad = false;
            for (var n in header.visible) {
                shouldLoad = (shouldLoad || header.visible[$scope.query.model]);
            }
            return shouldLoad;
        };

        $scope.isActive = function (s) {
            return $state.includes(s);
        };

        $scope.includeTab = function (state, orgConfig) {
            return state.config.name !== 'app.analyze.campaigns.listAnalysis.visual' ? true :
                (orgConfig.features && orgConfig.features.campaign_analytics && orgConfig.features.campaign_analytics.visual === 'canAccess');
        };

        function getCampaigns() {
            if (dataCall) { dataCall.abort(); }
            $state.current.data.loading = true;
            $scope.data.loaded = {};
            var ranged, summary, tags;
            var params = angular.copy($scope.query);
            var apiUrl = $state.current.data.pageType === 'web-activity' ? 'channel_performance' : 'campaign_performance';
            var apiUrl2 = $state.current.data.pageType === 'web-activity' ? 'load_tags_by_channel' : 'load_tags_by_campaign';
            delete params.search;
            params.t = true;
            params.grp = 'no-list';

            (dataCall = api.get([{ api: apiUrl, params: params, updateUrl: false, noCache: true }, { api: apiUrl2, params: params, updateUrl: false, noCache: true }])).then(function (data) {
                ranged = data[apiUrl].data;
                tags = data[apiUrl2].data;
                if (ranged && tags) {
                    calculateCampaignData(ranged, summary, tags);
                }
            });
        }

        $scope.tableStateCampaigns = function (info) {
            $scope.data.tableCampaignsState = info;
        };

        $scope.tableStateCampaignGroups = function (info) {
            $scope.data.tableCampaignGroupsState = info;
        };

        $scope.tableStateMedium = function (info) {
            $scope.data.tableStateMedium = info;
        };

        $scope.removeDupTags = function (tags) {
            return _.uniq(tags, 'id');
        };

        $scope.tabSelected = function () {
            $scope.$broadcast('$$rebind::refreshData');
        };

        $scope.allowToggle = function () {
            return $scope.query.ty !== 'c';
        };

        $scope.parseText = function (f, s) {
            var scope = s || $scope;
            return $parse(f)(scope);
        };

        $scope.parseSummary = function (f, s, string) {
            var str = string ? string : 'summary.';
            return $parse(f.replace('ranged.', str))(s);
        };

        $scope.getHeaders = function (model, group, set) {
            var iter = set ? set : $scope.data.headers;
            var platform = $scope.platform;
            var reportType = group ? 'groups' : 'campaigns';
            return $filter('filter')(iter, function (header) {
                return _.contains(header.model, model) && _.contains(header.orgTypes, platform) && _.contains(header.reportTypes, reportType);
            });
        };

        $scope.checkSearch = function () {
            const groupSearchList = [
                'app.analyze.campaigns.listAnalysis.campaignGroups',
                'app.analyze.webTracking.webActivity.channel',
            ];
            const campaignSearchList = [
                'app.analyze.campaigns.listAnalysis.campaigns',
                'app.analyze.webTracking.webActivity.channelAssets'
            ];
            const currentName = $state.current.name;
            if (
                $scope.query
                && $scope.query.search
                && $storage.tableStates[currentName]
            ) {
                // set storage to new query
                $scope.data.currentSearch = $scope.query.search;
                if (groupSearchList.indexOf(currentName) > -1) {
                    $storage.tableStates[currentName].groupSearch = $scope.data.currentSearch;
                } else if (campaignSearchList.indexOf(currentName) > -1) {
                    $storage.tableStates[currentName].campaignSearch = $scope.data.currentSearch;
                }
            }
        };

        $scope.clearSearch = function () {
            utilities.queryString({ search: null });
            $scope.$broadcast('$$rebind::refreshData');
        };

        $scope.exportVisualData = function () {
            $state.current.data.loading = true;

            var data = {
                headers: $scope.getHeaders($scope.query.model, $scope.data.tabs.campaignGroups.active),
                rows: null
            };
            data.rows = $scope.data.data.map(function (row) {
                var arr = [];

                angular.forEach(data.headers, function (header, i) {
                    var txt = $filter('metafilter')(_.get(row, header.field), header.filter) || '';
                    arr.push(txt);
                });
                return arr;
            });

            data.headers = data.headers.map(function (o) { return o.label; });
            $state.current.data.loading = false;
            return data;
        };

        $scope.formatChart = function (reorder) {
            var defer = $q.defer(),
                dimensions = {};
            angular.forEach($scope.data.headers, function (header, i) {
                var parts = header.field.split('ranged.'),
                    field = parts[1];

                if (field === 'revenue_group') {
                    dimensions[field] = {
                        axis: 'x',
                        type: 'category',
                        displayFormat: function (i) {
                            return $filter('truncate')($scope.chart.data[i].revenue_group, 15);
                        }
                    };
                } else {
                    dimensions[field] = {
                        type: 'bar',
                        show: false,
                        labels: true,
                        axis: 'y',
                        name: header.label
                    };
                }
            });
            $scope.chart.data = $scope.data.groupData.map(function (row) {
                row = angular.copy(row);
                var o = {};

                _.deepMapValues(row.ranged, function (value, path) {
                    o[path] = value;
                    return value;
                });
                row.ranged = o;

                return row.ranged;
            });
            $scope.chart.dimensions = dimensions;

            $scope.setChart($scope.query.chart, reorder);
            $scope.orderChart($scope.query.co);

            defer.resolve($scope.chart);

            return defer.promise;
        };

        /*=============================================
        =  import tags from activity
        =============================================*/

        var loadTags = function () {
            if (tagsCall) { tagsCall.abort(); }
            var params = $state.current.data.pageType === 'web-activity' ? { objectType: 'channel', tagType: 'tag' } : { objectType: 'campaign', tagType: 'tag' };
            (tagsCall = api.get('load_tags', params, false, true)).then(function (data) {
                $scope.data.tags = data.data;
                $scope.$broadcast('$$rebind::refreshTags');
            });
        };

        var findTag = function (tag, tags) {
            var found = false;
            angular.forEach(tags, function (tag2) {
                if (tag2.name === tag.name) {
                    found = tag2;
                }
            });
            return found;
        };

        $scope.addTag = function (selected) {
            if (!selected) { return; }

            $rootScope.$broadcast('trackAction', 'Add Tag', 'addTag');
            var apiUrl = $state.current.data.pageType === 'web-activity' ? 'add_tag_channel' : 'add_tag';
            var tag = angular.isObject(selected) ? selected : { name: selected };
            var selectedItems = $scope.data.selectedItems;
            var objIds = [];
            angular.forEach(selectedItems, function (campaign) {
                if (!findTag(tag, campaign.tags)) {
                    objIds.push(campaign.ranged.campaign_id);
                }
            });

            if (objIds.length > 0) {
                api.set(apiUrl, { name: tag.name, objIds: objIds }).success(function (data) {
                    $scope.data.tags.push(tag);
                    angular.forEach(selectedItems, function (campaign) {
                        var newTags = data[campaign.ranged.campaign_id];
                        if (newTags) {
                            campaign.tags = newTags;
                            campaign.tagNames = newTags.map(function (item) {
                                return item.name;
                            });
                            angular.forEach(newTags, function (tag) {
                                var found = findTag(tag, $scope.data.tagsInAllRows);
                                if (!found) {
                                    $scope.data.tagsInAllRows.push({ name: tag.name, object_ids: [tag.object_id] });
                                } else {
                                    found.object_ids.push(tag.object_id);
                                }
                            });
                        }
                    });
                    $scope.$broadcast('$$rebind::refreshTags');
                });
            }
        };

        $scope.removeTag = function (tag, row) {
            $rootScope.$broadcast('trackAction', 'Remove Tag', 'removeTag');
            var apiUrl = $state.current.data.pageType === 'web-activity' ? 'remove_tag_channel' : 'remove_tag';
            row.tags.splice(row.tags.indexOf(tag), 1);
            angular.forEach(row.tagNames, function (ctag, i) {
                if (ctag === tag.name) {
                    row.tagNames.splice(i, 1);
                }
            });
            angular.forEach($scope.data.tagsInAllRows, function (topTag) {
                $scope.data.tagsInAllRows.splice($scope.data.tagsInAllRows.indexOf(topTag), 1);
            });
            api.set(apiUrl, { name: tag.name, objIds: [tag.object_id] }, true).success(function (data) {
                $scope.$broadcast('$$rebind::refreshTags');
            });
        };

        $scope.removeTags = function (tag) {
            $rootScope.$broadcast('trackAction', 'Remove Tags', 'removeTags');
            var apiUrl = $state.current.data.pageType === 'web-activity' ? 'remove_tag_channel' : 'remove_tag';
            var objIds = [];
            angular.forEach($scope.data.selectedItems, function (item) {
                if (tag.object_ids.indexOf(item.campaign_id) > -1) {
                    objIds.push(item.campaign_id);
                    angular.forEach(item.tags, function (rmTag) {
                        if (rmTag.name === tag.name) {
                            item.tags.splice(item.tags.indexOf(rmTag), 1);
                        }
                    });
                    angular.forEach(item.tagNames, function (ctag, i) {
                        if (ctag === tag.name) {
                            item.tagNames.splice(i, 1);
                        }
                    });
                }
            });
            $scope.data.tagsInAllRows.splice($scope.data.tagsInAllRows.indexOf(tag), 1);
            api.set(apiUrl, { name: tag.name, objIds: objIds }).success(function (data) {
                $scope.$broadcast('$$rebind::refreshTags');
            });
        };

        $scope.lineChecked = function (row, allSelected) {
            $scope.data.selectedItems = allSelected;

            if (angular.isArray(row)) { //for when the checkall is checked
                $scope.data.tagsInAllRows = [];
                angular.forEach(row, function (aRow) {
                    if (aRow.entity.tags.length > 0) {
                        $scope.lineChecked(aRow);
                    }
                });
            } else {
                var tags = row.tags;
                angular.forEach(tags, function (tag) {
                    var rowTag = findTag(tag, $scope.data.tagsInAllRows);

                    if (row.isSelected) {
                        if (rowTag) {
                            rowTag.object_ids.push(tag.object_id);
                        } else {
                            $scope.data.tagsInAllRows.push({ name: tag.name, object_ids: [tag.object_id] });
                        }
                    } else if (rowTag) {
                        if (rowTag.object_ids.length === 1) {
                            $scope.data.tagsInAllRows.splice($scope.data.tagsInAllRows.indexOf(rowTag), 1);
                        } else {
                            rowTag.object_ids.splice(rowTag.object_ids.indexOf(tag.object_id), 1);
                        }
                    }
                });
            }
        };

        $scope.addTags = function () {
            var parent = $scope;
            var model = {
                title: 'Add Tags To Selected Campaigns',
                id: 'add-tags-window',
                templateUrl: 'add-tags.html',
                width: '500px',
                buttons: [{
                    'text': 'close',
                    'class': 'btn btn-sm btn-primary pull-right',
                    'ng-click': '$modal.closeModal()'
                }],
                backdrop: false,
                x: true,
                controller: function ($scope) {
                    $scope.tagsInAllRows = parent.data.tagsInAllRows;
                    $scope.removeTags = parent.removeTags;
                    $scope.addTag = parent.addTag;
                    $scope.tags = parent.data.tags;
                    $scope.data = parent.gridOptions;
                    $scope.tagSelected = parent.data.tagselected;
                    $scope.$on('$destroy', function () {
                        angular.forEach(parent.data.data, function (value, i) {
                            value.isSelected = false;
                        });
                    });
                }
            };

            $b.modal(model);
        };

        /*=============================================
        =  end imported tag code
        =============================================*/

        $scope.headersSelect = function (headers) {
            return $filter('filter')(headers, function (h) {
                return h.label != 'Campaign' && !_.contains(h.field, 'revenue_group');
            });
        };

        $scope.shownChart = function () {
            var keys = $filter('filter')(Object.keys($scope.chart.dimensions), function (key) {
                    return $scope.chart.dimensions[key].show;
                }),
                objs = keys.map(function (prop) {
                    return $scope.chart.dimensions[prop];
                });
            return objs;
        };

        $scope.checkChartData = function (chart, reorder) {
            _.forEach($scope.query.chart, function (query) {
                var header = _.find($scope.data.headers, { 'field': query });
                if (header) {
                    var info = $scope.data.loaded[header.url];
                    if (header.lazy_load && !info) {
                        loadExtraData(header.url, false).then(function () {
                            $scope.loading = true;
                            $scope.formatChart(reorder);
                            $scope.loading = false;
                        });
                    } else {
                        $scope.loading = true;
                        $scope.setChart(chart, reorder);
                        $scope.loading = false;
                    }
                }
            });
        };

        $scope.setChart = function (chart, reorder) {
            if (!chart) { return; }

            if (!angular.isArray(chart)) { chart = [chart]; }

            $scope.chart.chart.axis.y.label.text = null;
            $scope.chart.chart.axis.y2.label.text = null;
            if (chart[1]) {
                $scope.chart.chart.legend.show = true;
                $scope.chart.chart.axis.x.label.position = 'outer-left';
            } else {
                $scope.chart.chart.legend.show = false;
                $scope.chart.chart.axis.x.label.position = 'outer-center';
            }

            angular.forEach($scope.chart.dimensions, function (d, k) {
                var field1 = chart[0] ? chart[0].replace('ranged.', '') : null,
                    field2 = chart[1] ? chart[1].replace('ranged.', '') : null,
                    filter1 = angular.findWhere($scope.data.headers, { field: $scope.query.chart[0] }).filter,
                    filter2 = angular.findWhere($scope.data.headers, { field: $scope.query.chart[1] }).filter;

                if (d.axis !== 'x') {
                    var pointer = $scope.chart.dimensions[k];

                    pointer.show = false;
                    pointer.axis = 'y';

                    if (field1 && k === field1) {
                        pointer.show = true;
                        pointer.axis = 'y';
                        if (filter1) {
                            pointer.displayFormat = function (d) {
                                if (_.includes(filter1, 'Currency')) {
                                    return $filter('bfCurrency')(d);
                                } else if (_.includes(filter1, 'percent')) {
                                    return $filter('percentage')(d, 0);
                                } else {
                                    return $filter('nrFormat')(d);
                                }
                            };
                        }
                        $scope.chart.chart.axis.y.label.text = d.name;
                    }

                    if (field2 && k === field2) {
                        pointer.show = true;
                        pointer.axis = 'y2';
                        if (filter2) {
                            pointer.displayFormat = function (d) {
                                if (_.includes(filter2, 'Currency')) {
                                    return $filter('bfCurrency')(d);
                                } else if (_.includes(filter2, 'percent')) {
                                    return $filter('percentage')(d, 0);
                                } else {
                                    return $filter('nrFormat')(d);
                                }
                            };
                        }
                        $scope.chart.chart.axis.y2.label.text = d.name;
                    }
                }
            });

            if (reorder) {
                $scope.query.co = $scope.chartOrderSet()[0];
                $scope.orderChart($scope.query.co);
            }
            chart = _.compact(chart);
            utilities.queryString({ chart: chart });
        };

        $scope.orderChart = function (field) {
            var f = field === 'alpha' ? 'revenue_group' : _.find($scope.data.headers, { label: field }).field.split('ranged.')[1];
            $scope.chart.data = _.orderBy($scope.chart.data, function (o) {
                return o[f];
            }, [(field === 'alpha' ? 'asc' : 'desc')]);
            utilities.queryString({ co: field });
        };

        $scope.chartOrderSet = function () {
            var s = ['alpha'];
            angular.forEach(_.compact($scope.query.chart), function (c) {
                var k = (_.contains(c, 'ranged.') ? c.split('ranged.')[1] : c),
                    t = $scope.chart.dimensions[k] ? $scope.chart.dimensions[k].name : false;

                if (t) {
                    s.push(t);
                }
            });
            return s;
        };

        $scope.reapplyAggregateTotals = function (group, medium) {
            var aggregateTotalsObj = {};
            if (group) {
                _.forEach($scope.data.aggregateTotals, function (type) {
                    if (type && type.groups) {
                        Object.assign(aggregateTotalsObj, _.find(type.groups, function (g) {
                            return g.revenue_group === group;
                        }));
                    }
                });
            } else if (medium) {
                _.forEach($scope.data.aggregateTotals, function (type) {
                    if (type && type.medium) {
                        Object.assign(aggregateTotalsObj, type.medium[medium]);
                    }
                });
            } else {
                _.forEach($scope.data.aggregateTotals, function (type) {
                    if (type && type.total) {
                        Object.assign(aggregateTotalsObj, type.total);
                    }
                });
            }

            $scope.data.aggregateTotalsVisible = aggregateTotalsObj;

            recalcTotals();
        };

        // only happens for WAL table
        $scope.applyMediumFilter = function (medium) {
            if (medium) {
                var d;
                if ($scope.data.groupData) {
                    $scope.data.groupData.length = 0;
                }
                d = $filter('filter')($scope.data.unfilteredGroups, function (row) {
                    return row.ranged.parent_channel === medium && campaignPerformance.hasActivity(row, $scope);
                });
                $scope.data.groupData = d;
            } else {
                $scope.data.groupData = _.filter(_.clone($scope.data.unfilteredGroups), function (row) {
                    return campaignPerformance.hasActivity(row, $scope);
                });
                if ($rootScope.numPendingRequests() === 0) { loadLazyColumns(); }
                utilities.queryString({ mf: null });
            }

            $scope.reapplyAggregateTotals(null, medium);
        };

        // only happens for campaigns list table
        $scope.applyGroupFilter = function (group) {
            if (group) {
                var d;
                if ($scope.data.data) {
                    $scope.data.data.length = 0;
                }
                d = $filter('filter')($scope.data.unfilteredData, function (row) {
                    return row.ranged.revenue_group === group && campaignPerformance.hasActivity(row, $scope);
                });
                $scope.data.data = d; //DO NOT CHANGE WHERE THIS IS SAVED
                $scope.data.swatchColor = $scope.colorMap(group);

            } else {
                $scope.data.data = _.filter(_.clone($scope.data.unfilteredData), function (row) {
                    return campaignPerformance.hasActivity(row, $scope);
                });
                $scope.data.groupData = _.filter(_.clone($scope.data.unfilteredGroups), function (row) {
                    return campaignPerformance.hasActivity(row, $scope);
                }); //DO NOT CHANGE WHERE THIS IS SAVED

                if ($rootScope.numPendingRequests() === 0) { loadLazyColumns(); }

                utilities.queryString({ gf: null });
            }

            $scope.reapplyAggregateTotals(group, null);
        };

        $scope.modelChange = function (groupFilter, mediumFilter) {
            if (mediumFilter) {
                $scope.applyMediumFilter(mediumFilter);
            } else {
                $scope.applyGroupFilter(groupFilter);
            }
        };

        $scope.runAllQuery = function () {
            if (influenceTypes.useNewInfluenceType() && influenceTypes.defaultToEven($scope)) {
                $scope.query.model = 'even';
            }
            changeModel($scope.query.model).then(function () {
                getCampaigns();
            });
        };

        $scope.registerChanges = function (nv, ov) {
            if (!angular.equals(nv.query.model, ov.query.model)) {
                changeModel(nv.query.model, ov.query.model);
                $scope.modelChange($scope.query.gf, null);
                if ($state.current.data.pageType === 'web-activity') {
                    $scope.modelChange(null, $scope.query.mf);
                }
                //on model change, we want the loadwebtracking data function to be kicked off if any of the rows on the big tables are expanded
                if ($scope.data.expandedRows) {
                    if ($state.current.name === 'app.analyze.campaigns.listAnalysis.campaignGroups' || $state.current.name === 'app.analyze.webTracking.webActivity.channel') { //this is campaign groups tab and UTM sources tab
                        _.forEach($scope.data.expandedRows, function (row) {
                            $scope.loadWebTrackingData(row, true, 'tableStateWebActivityChannels', 'webTrackingGroupData');
                        });
                    } else if ($state.current.name === 'app.analyze.campaigns.listAnalysis.campaigns' || $state.current.name === 'app.analyze.webTracking.webActivity.channelAssets') { //this is for campaigns tab and UTM campaigns tab
                        _.forEach($scope.data.expandedRows, function (row) {
                            $scope.loadWebTrackingData(row, false, 'tableStateWebActivityChannelAssets', 'webTrackingData');
                        });
                    }
                }
            }
        };

        $scope.loadSearch = function () {
            if ($scope.query.search) {
                $storage.tableStates[$state.current.name].groupSearch = $scope.query.search;
                utilities.queryString({ search: null });
            }
        };

        $scope.cancelCall = function (row) {
            return function () {
                if (row.webTrackingDataCall) { row.webTrackingDataCall.abort(); }
                var name = row.ranged.revenue_group;
                var index = _.findIndex($scope.data.expandedRows, function (r) {
                    return $scope.parseText('ranged.revenue_group', r) === name;
                });
                $scope.data.expandedRows.splice(index, 1);
            };
        };

        $scope.pushRow = function (row) {
            return function () {
                $scope.data.expandedRows.push(row);
            };
        };

        //this function is called when you expand each of the rows, so you want the opposite call (for web activity, you want campaign perf, vice versa)
        $scope.loadWebTrackingData = function (row, group, tableState, safeSrc) {
            if (row.webTrackingDataCall) { row.webTrackingDataCall.abort(); }
            row.loading = true;
            var params = angular.copy($scope.query);
            params = _.omit(params, ['grp', 'groupName', 'groupId']);
            if (group) {
                params.groupName = row.ranged.revenue_group;
                if ($state.current.name === 'app.analyze.campaigns.listAnalysis.campaignGroups') { params.grp = 'no-list-only'; }
                if ($state.current.navName === 'UTM Sources') { params.parentName = row.ranged.parent_channel; }
            } else {
                params.groupId = row.ranged.campaign_id;
            }
            //if it's the first time opening a row, make the channel performance call
            if ((row && !row[tableState] && !row[safeSrc])) {
                var apiUrl = $state.current.data.pageType === 'web-activity' ? 'campaign_performance' : 'channel_performance';
                (row.webTrackingDataCall = api.get(apiUrl, params, false)).then(function (data) {
                    row.webTrackingGroupData = [];
                    row.webTrackingData = [];
                    var obj = $state.current.name === 'app.analyze.campaigns.listAnalysis.campaignGroups' ? data.data.groups : data.data;
                    var key = $state.current.name === 'app.analyze.campaigns.listAnalysis.campaignGroups' ? 'summary' : 'campaign';
                    _.forEach(obj, function (o) {
                        if (o[key]) {
                            o.ranged = angular.copy(o[key]);
                            delete o[key];
                            if (campaignPerformance.hasActivity(o, $scope)) {
                                if (group) {
                                    row.webTrackingGroupData.push(o);
                                } else {
                                    row.webTrackingData.push(o);
                                }
                                if (!o.ranged.revenue_group) {
                                    o.ranged.revenue_group = 'No Campaign Type';
                                }
                            }
                            if (!group) {
                                o.ranged.campaign_group = o.ranged.revenue_group;
                            }
                        }
                    });
                    row.loading = false;
                    loadLazyColumns(row); //load the extra data for the rows
                    recalcTotals(row); //recalculate totals for normal header values (leads, oppts, etc. )
                });
            } else {
                loadLazyColumns(row); //load the lazy loaded data (won't load if already loaded based on info.loaded in load extra data)
            }
        };

        $scope.getCohortTemplate = function getCohortTemplate(query) {
            if (!$scope.data.cohorts) { return; }

            return _.find($scope.data.cohorts, { key: query.cohort }).value;
        };

        $scope.orderCols = function (model, headerSubset) {
            /* Some of the columns need to appear in a certain order. The rest do not. */
            var cols = $scope.getHeaders(model, headerSubset),
                hasOrder = [],
                noOrder = [];
            cols.forEach(function (col) {
                if (col.toggleOrder && col.toggleOrder[$scope.query.model]) {
                    hasOrder.push(col);
                } else {
                    noOrder.push(col);
                }
            });
            hasOrder = _.sortBy(hasOrder, [function (o) { return o.toggleOrder[$scope.query.model]; }]);
            return hasOrder.concat(noOrder);
        };

        $scope.useTypeFilteredAttr = influenceTypes.useTypeFilteredAttr;

        $scope.typeAttrModelFilter = influenceTypes.typeAttrModelFilter;

        /*=============================================
        =  private functions
        =============================================*/
        function recalcTotals(row) {
            var currentModel = $scope.query.model;
            if (!row) { //parent tables, will NOT need untracked data calculations
                if ($state.current.navName === 'Campaigns' || $state.current.navName === 'UTM Campaign') {//if campaigns or utm campaigns
                    if ($scope.data.tableCampaignsState && $scope.data.tableCampaignsState.items) {
                        campaignPerformance.updateTotals($scope.data.tableCampaignsState.items, 'campaigns', $scope.data, false, currentModel);
                    }
                } else if ($state.current.navName === 'Campaign Types' || $state.current.navName === 'UTM Sources') { //if campaign groups or utm sources
                    if ($scope.data.tableCampaignGroupsState && $scope.data.tableCampaignGroupsState.items) {
                        campaignPerformance.updateTotals($scope.data.tableCampaignGroupsState.items, 'groups', $scope.data, false, currentModel);
                    }
                } else if ($state.current.navName === 'UTM Medium') {//if utm medium
                    if ($scope.data.tableStateMedium && $scope.data.tableStateMedium.items) {
                        campaignPerformance.updateTotals($scope.data.tableStateMedium.items, 'medium', $scope.data, false, currentModel);
                    }
                }
            } else { //expanded tables, untracked calculations are needed
                if ($state.current.navName === 'Campaign Types' || $state.current.navName === 'UTM Sources') { //if campaign groups or utm sources
                    if (row.webTrackingGroupData.length) {
                        if ($state.current.navName === 'UTM Sources') { //if you're on UTM sources, just calculate the totals
                            campaignPerformance.updateTotals(row.webTrackingGroupData, 'groups', row, true, currentModel);
                        }
                        if ($state.current.navName === 'Campaign Types') { //if you're on campaign groups, do untracked calculations (web activity list should always show 0 for untracked), only when all api requests are done
                            campaignPerformance.untrackedData(row, 'webTrackingGroupData', 'groups', 'tableStateWebActivityChannels', 'webGroupDataTable', currentModel); //difference with untracked row inserted
                            campaignPerformance.updateTotals(row.webTrackingGroupData, 'groups', row, true, currentModel); //recalculates
                        }
                    }
                } else if ($state.current.navName === 'Campaigns' || $state.current.navName === 'UTM Campaign') { //if campaigns or channel-assets
                    if (row.webTrackingData && row.webTrackingData.length) {
                        if ($state.current.navName === 'UTM Campaign') { //if you're on UTM campaigns, just calculate the totals
                            campaignPerformance.updateTotals(row.webTrackingData, 'campaigns', row, true, currentModel);
                        }
                        if ($state.current.navName === 'Campaigns') { //if you're on campaigns, do untracked calculations (web activity list should always show 0 for untracked), only when all api requests are done
                            campaignPerformance.untrackedData(row, 'webTrackingData', 'campaigns', 'tableStateWebActivityChannelAssets', 'webDataTable', currentModel);
                            campaignPerformance.updateTotals(row.webTrackingData, 'campaigns', row, true, currentModel);
                        }
                    }
                }
            }
        }

        function changeModel(model, oldModel) {
            var defer = $q.defer();
            if (model) {
                if (oldModel) {
                    utilities.queryString({ model: model });
                }
                $scope.data.headerSubset = $scope.orderCols(model);
                $scope.data.headerSubsetGroups = $scope.orderCols(model, true);
                $scope.$broadcast('$$rebind::refreshHeaders');
            }
            defer.resolve();
            return defer.promise;
        }

        function loadLazyColumns(row) { //load lazy columns if they are showing
            var lazyLoadHeaders = _.filter($scope.data.headers, function (header) {
                return $scope.isHeaderShowing(header) && header.lazy_load;
            });

            if (lazyLoadHeaders.length) {
                lazyLoadHeaders.forEach(function (header) {
                    // ignoreLazy is true for cost columns on DA page, ignore call
                    if (header.ignoreLazy) { return; }

                    if (row && header.lazy_group_only && (!$state.current.data.groupLevel || $state.current.navName === 'UTM Sources')) {} //don't load extra data if you're a lazy group call and you're expanding a row in UTM sources
                    else if (!row && header.lazy_group_only && !$state.current.data.groupLevel) {} //don't load extra data  for a lazy group call if you're on the parent table in a non-group-level page
                    else {
                        if (row) { //if you're expanding a row, load the extra data for that row
                            loadExtraData(header.webTrackInfoUrl, false, row).then(function () {
                                if ($rootScope.numPendingRequests() === 0) { recalcTotals(row); } //recalculate totals for lazy loaded headers (unique, stages, etc.), only when all calls have come back
                            });
                        } else { //you're not expanding a row, then load the extra data for the parent table and recalculate the totals for lazy loaded headers, only when all calls have come back
                            loadExtraData(header.url, false).then(function () {
                                if ($rootScope.numPendingRequests() === 0) { recalcTotals(); }
                            });
                        }
                    }
                });
            }
            if (row && row.loading) { row.loading = false; } //if you're loading at this point, stop loading
        }

        function loadExtraData(url, allTime, row) {
            var defer = $q.defer();
            var part = allTime ? 'summary' : 'ranged';
            var params = angular.copy($scope.query);
            if (row) {
                //key is either groupName or groupId for backend to know parent channels
                var grp = $state.current.name === 'app.analyze.campaigns.listAnalysis.campaignGroups' ||
                    $state.current.name === 'app.analyze.webTracking.webActivity.channel' ? 'groupName' : 'groupId';
                // namespace by model
                var model = $scope.query.model;
                // type is namespaceing by either revenue_group or campaign_id
                var type = grp === 'groupName' ? 'revenue_group' : 'campaign_id';
                // key3 is namespacing one level further for parent channel
                if (grp === 'groupName') {
                    var medium = 'parent_channel';
                }
                params[grp] = row.ranged[type];
                if ($state.current.navName === 'UTM Sources') { params.parentName = row.ranged.parent_channel; }
            }
            var urlKey = url + (row && model ? model : '') + (row ? '_' + row[part][type] : '') + (row && row[part][medium] ? '_' + row[part][medium] : '');
            var info = $scope.data.loaded[urlKey];
            if (!info) {
                info = {};
                $scope.data.loaded[urlKey] = info;
            }
            if ((!info.loaded && $scope.data.groupData) || ($state.current.data.pageType === 'web-activity' && !info.loaded && $scope.data.unfilteredGroups)) {
                info.loaded = true;
                if (info.apiCall) {
                    info.apiCall.abort();
                }
                (info.apiCall = api.getter({ url: url, params: params, paramsNotToUpdate: 'all' })).then(function (data) {
                    var campaigns = data.data.campaigns,
                        groups = data.data.groups,
                        medium = data.data.parent_channels ? data.data.parent_channels : false,
                        models = data.data.models,
                        keys = data.data.keys,
                        emptyValue = data.data.empty_value,
                        aggregateTotals = data.data.total;

                    if (row) {
                        if (aggregateTotals) {
                            if (row.aggregateTotalsVisible) {
                                _.merge(row.aggregateTotalsVisible, aggregateTotals);
                            } else {
                                row.aggregateTotalsVisible = aggregateTotals;
                            }
                        }

                        if ($state.current.name === 'app.analyze.campaigns.listAnalysis.campaignGroups') { //for campaign groups expanded rows, use webTrackingGroupData with UTM sources (groups) in expanded rows
                            campaignPerformance.addToRows(row.webTrackingGroupData, groups, 'key', models, keys, part, emptyValue);
                        } else if ($state.current.name === 'app.analyze.webTracking.webActivity.channel') { //for utm sources, use webTrackingGroupData with campaigns in expanded rows
                            campaignPerformance.addToRows(row.webTrackingGroupData, campaigns, 'campaign_id', models, keys, part, emptyValue);
                        } else if ($state.current.name === 'app.analyze.webTracking.webActivity.channelAssets' || $state.current.name === 'app.analyze.campaigns.listAnalysis.campaigns') { //for campaigns and utm campaigns, use webTrackingData with campaigns in expanded rows
                            campaignPerformance.addToRows(row.webTrackingData, campaigns, 'campaign_id', models, keys, part, emptyValue);
                        }
                    } else {
                        if (aggregateTotals) {
                            if ($scope.data.aggregateTotalsVisible) {
                                _.merge($scope.data.aggregateTotalsVisible, aggregateTotals);
                            } else {
                                $scope.data.aggregateTotalsVisible = aggregateTotals;
                            }
                            _.forEach(keys, function (k) {
                                $scope.data.aggregateTotals[k] = {};
                                $scope.data.aggregateTotals[k].groups = _.cloneDeep(groups);
                                $scope.data.aggregateTotals[k].medium = _.cloneDeep(medium);
                                $scope.data.aggregateTotals[k].total = _.cloneDeep(aggregateTotals);
                            });
                        }

                        campaignPerformance.addToRows($scope.data.groupData, groups, 'key', models, keys, part, emptyValue);
                        campaignPerformance.addToRows($scope.data.unfilteredData, campaigns, 'campaign_id', models, keys, part, emptyValue);
                        if (medium && $scope.data.mediumData) { //DO NOT CHANGE WHERE MEDIUM DATA IS SAVED
                            campaignPerformance.addToRows($scope.data.mediumData, medium, 'parent_channel', models, keys, part, emptyValue);
                        }
                    }
                    if (url === 'campaign_perf_stages' && $scope.query.dataSet === 'membershipActivity' || url === 'channel_perf_stages' && $scope.query.dataSet === 'membershipActivity') {
                        var n, o;
                        for (n in campaigns) { //for campaigns tab, UTM sources tab, and UTM campaigns tab
                            o = {};
                            o[part] = campaigns[n];
                            if (!row) {
                                $scope.data.unfilteredData.push(o);
                                $scope.data.data = _.filter(_.clone($scope.data.unfilteredData), function (r) {
                                    return campaignPerformance.hasActivity(r, $scope);
                                });
                            } else {
                                if (campaignPerformance.hasActivity(o, $scope)) {
                                    if ($state.current.name === 'app.analyze.webTracking.webActivity.channel') {
                                        row.webTrackingGroupData.push(o);
                                    } else if ($state.current.name === 'app.analyze.webTracking.webActivity.channelAssets' || $state.current.name === 'app.analyze.campaigns.listAnalysis.campaigns') {
                                        row.webTrackingData.push(o);
                                    }
                                }
                            }
                        }
                        for (n in groups) { //for campaign groups expanded rows
                            o = {};
                            o[part] = groups[n];
                            if (!row) {
                                $scope.data.groupData.push(o);
                                $scope.data.groupData = _.filter(_.clone($scope.data.groupData), function (row) {
                                    return campaignPerformance.hasActivity(row, $scope);
                                });
                            } else {
                                if (campaignPerformance.hasActivity(o, $scope)) {
                                    if ($state.current.name === 'app.analyze.campaigns.listAnalysis.campaignGroups') {
                                        row.webTrackingGroupData.push(o);
                                    }
                                }
                            }
                        }

                        if (medium && $scope.data.mediumData) { //for UTM medium tab
                            for (n in medium) {
                                o = {};
                                o[part] = medium[n];
                                if (!row) {
                                    $scope.data.mediumData.push(o);
                                    $scope.data.mediumData = _.filter(_.clone($scope.data.mediumData), function (row) {
                                        return campaignPerformance.hasActivity(row, $scope);
                                    });
                                }
                            }
                        }
                    }

                    if ($scope.query.gf) {
                        $scope.applyGroupFilter($scope.query.gf);
                    }

                    if ($scope.query.mf && $state.current.data.pageType === 'web-activity') {
                        $scope.applyMediumFilter($scope.query.mf);
                    }

                    _.forEach(campaigns, function (c) {
                        c.campaign_group = c.revenue_group;
                        if (!c.campaign_group) {
                            c.campaign_group = 'No Campaign Type';
                        }
                    });
                    $scope.$broadcast('$$rebind::refreshData');
                    defer.resolve();
                }, function () {
                    defer.reject();
                });
            }
            if (row && row.loading) { row.loading = false; }

            return defer.promise;
        }

        function calculateGroupData(ranged, allTime, models, groups, utmMedium) {
            var rangedData = utmMedium ? ranged.parent_channels : ranged.groups;
            for (var n in rangedData) {
                var allTimeSummary;
                if (allTime && allTime.groups[n]) {
                    allTimeSummary = allTime.groups[n].summary;
                    models.forEach(function (m) {
                        //setting cost per object for all time
                        try {
                            rangedData[n].summary[m].cpl = allTime.groups[n].summary[m].cpl;
                            rangedData[n].summary[m].cpo = allTime.groups[n].summary[m].cpo;
                            rangedData[n].summary[m].cpd = allTime.groups[n].summary[m].cpd;
                        } catch (e) {
                            if (!errorRefresh) {
                                errorRefresh = true;
                                api.set('api/v1/flush_campaign_performance_cache').success(function (d) {
                                    $storage.apiCache[$scope.userData.organizationId] = {};
                                    getCampaigns();
                                });
                            }
                        }
                    });
                } else {
                    allTimeSummary = {};
                }
                if (n === '') {
                    rangedData['(No Campaign Type)'] = _.cloneDeep(rangedData[n]);
                    rangedData['(No Campaign Type)'].summary.revenue_group = '(No Campaign Type)';
                    delete rangedData[n];
                    n = '(No Campaign Type)';
                }
                groups.push({ ranged: rangedData[n].summary, summary: allTimeSummary });
            }
        }

        function calculateCampaignData(ranged, allTime, tags, ignoreLoad) {
            var campaigns = [], groups = [], medium = [];
            var models = $scope.platform === 'full' ? ['sourced', 'last', 'even', 'custom'] : influenceTypes.useNewInfluenceType() && $scope.platform === 'campaign' ? ['sourced', 'last', 'even'] : ['even'];

            for (var n in ranged.campaigns) {
                //setting cost per object for all time
                var allTimeCamp;
                if (allTime && allTime.campaigns[n]) {
                    models.forEach(function (m) {
                        try {
                            ranged.campaigns[n].campaign[m].cpl = allTime.campaigns[n].campaign[m].cpl;
                            ranged.campaigns[n].campaign[m].cpo = allTime.campaigns[n].campaign[m].cpo;
                            ranged.campaigns[n].campaign[m].cpd = allTime.campaigns[n].campaign[m].cpd;
                        } catch (e) {
                            if (!errorRefresh) {
                                errorRefresh = true;
                                api.set('api/v1/flush_campaign_performance_cache').success(function (d) {
                                    $storage.apiCache[$scope.userData.organizationId] = {};
                                    getCampaigns();
                                });
                            }
                        }

                    });
                    allTimeCamp = allTime.campaigns[n].campaign;
                } else {
                    allTimeCamp = {};
                }
                if (ranged.campaigns[n].campaign.revenue_group === '') {
                    ranged.campaigns[n].campaign.revenue_group = '(No Campaign Type)';
                }
                campaigns.push({ ranged: ranged.campaigns[n].campaign, tags: tags[n], summary: allTimeCamp });
            }

            calculateGroupData(ranged, allTime, models, groups, false);
            if (ranged.parent_channels && Object.keys(ranged.parent_channels).length) {
                calculateGroupData(ranged, allTime, models, medium, true);
            }

            angular.forEach(campaigns, function (row, i) {
                row.ranged.campaign_group = row.ranged.revenue_group;
                if (!row.ranged.campaign_group) {
                    row.ranged.campaign_group = 'No Campaign Type';
                }
                row.summary.campaign_group = row.summary.revenue_group;
                angular.forEach($scope.data.headers, function (header, i) {
                    angular.forEach(header, function (prop, key) {
                        if (!row.ranged[prop.field]) {
                            row.ranged[prop.field] = 0;
                        }
                    });
                });
            });

            $scope.data.unfilteredData = campaigns;
            $scope.data.unfilteredGroups = groups;
            $scope.applyGroupFilter($scope.query.gf);
            if ($state.current.data.pageType === 'web-activity') {
                $scope.applyMediumFilter($scope.query.mf);
                $scope.data.mediumData = medium.length ? medium : false;
            } else { //you're on campaigns list
                $scope.data.groupData = _.filter(_.clone(groups), function (row) {
                    return campaignPerformance.hasActivity(row, $scope);
                });
            }
            //this is used for the filter by on the campaigns/channel-assets tab...we want to only filter by the campaign groups and channels available
            if ($state.current.navName === 'UTM Sources') {
                $scope.data.campaignGroups = _.uniq(_.map(_.values($scope.data.mediumData), function (o) { return o.ranged.parent_channel; }));
            } else {
                $scope.data.campaignGroups = _.uniq(_.map(_.values($scope.data.groupData), function (o) { return o.ranged.revenue_group; }));
            }

            //load lazy columns if they are showing
            loadLazyColumns();

            if ($state.current.data.pageType !== 'web-activity') {
                $scope.formatChart();
            }
            if (!ignoreLoad) {
                $state.current.data.loading = false;
            }
            $scope.$broadcast('$$rebind::refreshData');
        }

        (function init() {
            $state.current.data.loading = true;
            $scope.startDatepicker = {};
            $scope.endDatepicker = {};
            $scope._ = _;
            $scope.$state = $state;
            $scope.campaignPerformance = campaignPerformance;
            $scope.numPages = 20;
            $scope.colorMap = colorMap;
            $scope.platform = $rootScope.userData.platform;
            $scope.data = {
                cohorts: utilities.formatCohorts(cohorts, ['all', 'custom', 'year', 'quarter', 'ago', 'toDate', 'lastFull']),
                tableData: [],
                expandedRows: [],
                groupTableData: [],
                dataSets: campaignPerformance.dataSets,
                models: campaignPerformance.getAttrModels(),
                numRows: 5,
                tagsInAllRows: [],
                selectedItems: [],
                chartZoom: 0,
                headerSubset: [],
                headerSubsetGroups: [],
                loaded: {},
                tabs: {},
                channelGroup: campaignPerformance.tabMappings('channel_group'),
                channelAsset: campaignPerformance.tabMappings('channel_name'),
                channelMedium: campaignPerformance.tabMappings('channel_parent'),
                aggregateTotals: {},
                aggregateTotalsVisible: {},
                influence: influenceTypes.typeMappings(),
            };

            var state = $state.current.controller ? $state.current : $state.$current.parent.self;

            $scope.data.campaignListStates = angular.copy(_.findDeep($rootScope.nav, { name: state.name }));
            if ($scope.data.campaignListStates.children) {
                $scope.data.campaignListStates = _.filter($scope.data.campaignListStates.children, function (c) {
                    return !c.config.tabs && c.config.status === 'enabled';
                });
            }

            if ($state.current.data.pageType === 'web-activity') {
                $scope.data.dataSets = _.filter($scope.data.dataSets, function (o) { return o.key !== 'campaignCreatedDate'; });
                if (!$scope.data.channelMedium) {
                    var index = _.findIndex($scope.data.campaignListStates, { 'name': 'app.analyze.webTracking.webActivity.channelMedium' });
                    if (index !== -1) {
                        $scope.data.campaignListStates.splice(index, 1);
                    }
                }
            }
            _.forEach($rootScope.orgConfig.web_activity.utm, function (names) {
                if (_.includes(names, 'channel_parent')) {
                    $scope.isMediumConfigured = true;
                }
            });

            $scope.data.webHeaders = campaignPerformance.webTrackingHeaders($scope, $state);
            if ($state.current.data.pageType === 'web-activity') {
                $scope.data.headers = campaignPerformance.headers($scope, $state, true);
            } else {
                $scope.data.headers = campaignPerformance.headers($scope, $state);
            }

            $scope.data.tabMap = {
                'app.analyze.campaigns.listAnalysis.campaignGroups': {
                    pairState: 'app.analyze.campaigns.listAnalysis.campaigns',
                    tableKey: 'performanceTableGroups-',
                    pairKey: 'performanceTable-'
                },
                'app.analyze.campaigns.listAnalysis.campaigns': {
                    pairState: 'app.analyze.campaigns.listAnalysis.campaignGroups',
                    tableKey: 'performanceTable-',
                    pairKey: 'performanceTableGroups-'
                },
                'app.analyze.webTracking.webActivity.channel': {
                    pairState: 'app.analyze.webTracking.webActivity.channelAssets',
                    tableKey: 'performanceTableGroups-',
                    pairKey: 'performanceTable-'
                },
                'app.analyze.webTracking.webActivity.channelAssets': {
                    pairState: 'app.analyze.webTracking.webActivity.channel',
                    tableKey: 'performanceTable-',
                    pairKey: 'performanceTableGroups-'
                }
            };

            $scope.chart = {
                data: [],
                dimensions: {},
                chart: {
                    axis: {
                        //------------------------ make horizontal bar chart ------------------------//
                        // rotated: true,
                        y: {
                            label: {
                                position: 'outer-middle'
                            },
                            tick: {
                                format: function (d) {
                                    var filter = angular.findWhere($scope.data.headers, { field: $scope.query.chart[0] }).filter;
                                    if (_.includes(filter, 'Currency')) {
                                        return $filter('nrFormat')(d, true);
                                    } else {
                                        return $filter('metafilter')(d, filter);
                                    }
                                }
                            }
                        },
                        x: {
                            type: 'category',
                            tick: {
                                rotate: -45,
                                multiline: false
                            },
                            height: 120,
                            label: {
                                text: 'Campaign Types',
                                position: 'outer-left'
                            }
                        },
                        y2: {
                            label: {
                                position: 'outer-middle'
                            },
                        }
                    },
                    data: {
                        color: function (color, d, x) {
                            var c;
                            if (!angular.isObject(d) && !$scope.query.chart[1]) {
                                c = '';
                            } else if ($scope.query.chart[1]) {
                                c = color;
                            } else {
                                c = colorMap($scope.chart.data[d.index].revenue_group);
                            }
                            return c;
                        }
                    },
                    legend: {
                        show: true
                    }
                }
            };

            //------------------------ set defaults ------------------------//
            //
            $scope.query = _.defaults($state.params, {
                cohort: 'quarter2Date',
                ty: 'c',
                startDate: moment(new Date()).subtract(1, 'week')._d.getTime(),
                endDate: new Date().getTime(),
                model: 'even',
                dataSet: $scope.data.dataSets[0].key,
                chart: ['ranged.sourced.leads'],
                co: 'alpha',
                gf: null,
                mf: null,
            });

            if (!_.isDefined(_.find($scope.data.models, { key: $scope.query.model }))) {
                $scope.query.model = $scope.data.models[0].key;
            }

            if ($scope.query.gf) {
                $scope.data.swatchColor = $scope.colorMap($scope.query.gf);
            }

            influenceTypes.setDefaultInfluenceType($scope, influenceTypes.useNewInfluenceType);

            if ($state.current.navName === 'Campaign Types' || $state.current.navName === 'UTM Sources') {
                $scope.query.gf = null;
            }
            else if ($state.current.navName === 'UTM Medium') {
                $scope.query.mf = null;
            }

            changeModel($scope.query.model);

            if (!angular.isArray($scope.query.chart)) {
                $scope.query.chart = [$scope.query.chart];
            }

            if ($state.current.data.pageType !== 'web-activity') {
                if ($scope.query.chart[0]) {
                    $scope.checkChartData($scope.query.chart[0]);
                }
                if ($scope.query.chart[1]) {
                    $scope.checkChartData($scope.query.chart[1]);
                }
            }

            getCampaigns();

            loadTags();

            $scope.$on('filtersChanged', function () {
                getCampaigns();
            });

            $scope.$watchCollection('data.tableCampaignsState', function (nv) { //this is the campaigns/channel-assets table
                if (nv && nv.items.length) {
                    campaignPerformance.updateTotals(nv.items, 'campaigns', $scope.data, false, $scope.query.model);
                }
            });

            $scope.$watchCollection('data.tableCampaignGroupsState', function (nv) { //this is the campaign groups/channels table
                if (nv && nv.items.length) {
                    campaignPerformance.updateTotals(nv.items, 'groups', $scope.data, false, $scope.query.model);
                }
            });

            $scope.$watchCollection('data.tableStateMedium', function (nv) { //this is the campaign groups/channels table
                if (nv && nv.items.length) {
                    campaignPerformance.updateTotals(nv.items, 'medium', $scope.data, false, $scope.query.model);
                }
            });

            //watches headers in parent tables, and checks to see if any lazy loaded headers are showing. If they are, recalculate the totals for the table AND any expanded rows that have lazy loaded headers showing
            $scope.$watch(function () {
                return $scope.data.headers;
            }, function (nv) {
                var lazyLoadHeaders = _.filter(nv, function (h) {
                    return $scope.isHeaderShowing(h) && h.lazy_load;
                });
                lazyLoadHeaders.forEach(function (header) {
                    // ignoreLazy is true for cost columns on DA page, ignore call
                    if (header.ignoreLazy) { return; }

                    if (header.lazy_group_only && !$state.current.data.groupLevel) { return; }
                    else {
                        loadExtraData(header.url, false).then(function () {
                            if ($rootScope.numPendingRequests() === 0) { recalcTotals(); } //recalculate totals, only when all calls have come back
                            _.forEach($scope.data.expandedRows, function (row) {
                                loadExtraData(header.webTrackInfoUrl, false, row).then(function () {
                                    if ($rootScope.numPendingRequests() === 0) { recalcTotals(row); } //recalculate totals, only when all calls have come back
                                });
                            });
                        });
                    }
                });
            }, true);

            $scope.$on('$destroy', function () {
                if (dataCall) { dataCall.abort(); }
                if (tagsCall) { tagsCall.abort(); }
            });
        })();
    }]);
