app.service('revenueWaterfall', ['$b', '$rootScope', '$filter', 'api', '_', 'utilities', '$timeout', '$state', function ($b, $rootScope, $filter, api, _, utilities, $timeout, $state) {
    this.searchCampaigns = function ($select, destination, key) {
        if ($select.search.length > 2) {
            $select.loading = true;
            var url = 'campaign_names_like';
            return api.get(url, { likeName: $select.search }, false).then(function (data) {
                _.set(destination, key, data.data);
                $select.loading = false;
            });
        }
    };

    this.getChart = function ($scope) {
        return {
            dimensions: {
                name: {
                    axis: "x",
                },
                leadCount: {
                    type: "bar",
                    axis: "y",
                    name: "Lead Stages"
                },
                opptyCount: {
                    type: "bar",
                    axis: "y2",
                    name: "Opportunity Stages"
                }
            },
            chart: {
                title: {},
                padding: {
                    top: 5,
                    bottom: 90
                },
                data: {
                    labels: true
                },
                size: {
                    height: 500
                },
                legend: {
                    show: true
                },
                color: {
                    pattern: ['#FC690F', '#1B61A4']
                },
                axis: {
                    x: {
                        tick: {
                            multiline: false,
                            rotate: -45,
                            format: function (x) {
                                return _.truncate($scope.chart.data[x].name, { length: '13' });
                            }
                        }
                    },
                    y: {
                        label: {
                            text: 'Lead Count',
                            position: 'outer-middle'
                        },
                        tick: {
                            count: 6,
                            format: function (d) {
                                return $filter('nrFormat')(d);
                            },
                        }
                    },
                    y2: {
                        label: {
                            text: 'Opportunity Count',
                            position: 'outer-middle'
                        },
                        tick: {
                            count: 6,
                            format: function (d) {
                                return $filter('nrFormat')(d);
                            },
                        }
                    }
                },
                tooltip: {
                    contents: function (dimensions, dt, dv, color) {
                        $scope.data.tScope.title = $scope.data.stages[dimensions[0].index].name;
                        $scope.data.tScope.rows = dimensions.map(function (r, i) {
                            return {
                                label: (r.id == 'opptyCount' ? 'opportunity count' : 'lead count'),
                                value: r.value,
                                color: color(dimensions[i])
                            };
                        });
                        return $scope.data.tScope.$apply(function () { return $scope.data.tooltip[0].outerHTML; });
                    }
                }
            }
        };
    };

    this.formatData = function (data, $scope) {
        var query,
            scopeData,
            check = false;

        if ($scope.widget) {
            query = $scope.widget.query;
            scopeData = $scope.widget.data;
        }
        else {
            query = $scope.query;
            scopeData = $scope.data;
            check = true;
        }

        data.data.forEach(function (s) {
            s.stageType = _.find($scope.wfStages, { stage: s.name }).applies_to;
            if (s.stageType == 'lead') {
                s.leadCount = s.count;
            } else {
                s.opptyCount = s.count;
            }
            $scope.data.largest = s.count > $scope.data.largest ? s.count : $scope.data.largest;
        });
        $scope.chart.chart.title.text = 'Count per Stage - ' + _.find($scope.select_options, { key: query.cohort }).value;
        $scope.data.stages = data.data;
        $timeout(function () {
            $scope.chart.data = angular.copy(data.data);
            if (check) {
                $state.current.data.loading = false;
            }
        }, 0, false);

        if (scopeData.lastQuery) {
            scopeData.lastQuery = angular.copy(query);
        }
    };
}]);
