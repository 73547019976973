/**
 * Sets constants for the switch user module.
 */
(function () {
    'use strict';

    app
        .constant('SwitchUserConstants',
            {
                API: {
                    LOGIN_AS: '/loginAs/changeToUser'
                },
                LABELS: {
                    TITLE: 'God Mode',
                    LABEL: 'Username',
                    BUTTON: 'Login as',
                    PLACEHOLDER: 'user@example.com',
                    LOGIN_AS: {
                        OK: "Switching to a new user.",
                        CANCEL: "Switching to a new user cancelled.",
                        ERROR: "Unable to switch to a new user. Try again.",
                        IN_PROGRESS: 'In Progress'
                    }
                }
            });
})();
