app.controller('teamConfigureCtrl', ['$scope', '$http', '$window', '$location', 'utilities', 'api', '$q', '$filter', '$b', '$state', '$timeout', 'noty', '$rootScope', function ($scope, $http, $window, $location, utilities, api, $q, $filter, $b, $state, $timeout, noty, $rootScope) {
    var loadOrgUsersCall;
    // eslint-disable-next-line no-unused-vars
    var loadFiltersCall;
    var filters;

    function setFilters() {
        $scope.data.filters = { lead: [], opportunity: [], campaign: [], account: [], campaign_member: [] };
        Object.keys(filters).forEach(function (table) {
            filters[table].forEach(function (filter) {
                var data = [];
                $scope.data.filters[table].push({ name: filter.name, data: data });
                filter.data.forEach(function (f) {
                    var isSelected = false;
                    if ($scope.data.selectedUser.user_filters[table] && $scope.data.selectedUser.user_filters[table][filter.number]) {
                        $scope.data.selectedUser.user_filters[table][filter.number].forEach(function (uf) {
                            if (uf == f) {
                                isSelected = true;
                            }
                        });
                    }
                    data.push({ name: f, applyTo: filter.number, isSelected: isSelected });
                });
            });
        });
    }

    $scope.userSelected = function () {
        $state.current.data.loading = true;
        $rootScope.$broadcast('detailChanged', "user selected");
        for (var n in $scope.data.team) {
            var member = $scope.data.team[n];
            if ($scope.data.selectedUserId == member.user_id) {
                $scope.data.selectedUser = member;
                break;
            }
        }

        updateReportTree($scope.data.reports[0].children, $scope.data.team[n].report_status);
        if (filters) {
            setFilters();
        }

        $state.current.data.loading = false;
    };

    $scope.updateUserPermission = function (key) {
        $rootScope.$broadcast('detailChanged', "user permissions changed");
        var user = $scope.data.selectedUser;
        var url = "/orgAdmin/update_user_permission";
        api.get(url, { username: user.username, level: key, add: user.permission_levels[key] }, false, true);
    };

    $scope.updateMarketingRole = function () {
        $rootScope.$broadcast('detailChanged', "user marketing role changed");
        var user = $scope.data.selectedUser;
        var url = "/orgAdmin/update_user_marketing_role";
        api.get(url, { username: user.username, role: user.marketing_role }, false, true);
    };

    $scope.updateUserEnabled = function () {
        var user = $scope.data.selectedUser;
        var url = "orgAdmin/update_user_enabled?";
        url += utilities.param.toQueryString({ username: user.username, enabled: user.enabled });
        $http.get(url);

    };

    $scope.addDataFilter = function (filter, type) {
        $rootScope.$broadcast('detailChanged', "set user data filters");
        var params = {
            name: filter.name,
            type: filter.applyTo,
            add: filter.isSelected,
            userId: $scope.data.selectedUser.user_id,
            tableRef: type
        };
        api.set("/orgAdmin/update_user_data_filter", params);
    };

    $scope.shouldShowPermission = utilities.shouldShowPermission;

    function createReportTree(item, children) {
        if (children) {
            item.children = [];
            children.forEach(function (child) {
                if (!child.config.alwaysAdd && utilities.checkStateAndStatusForAddition(child, $rootScope.userData)) {
                    var newItem = {
                        name: child.name,
                        title: child.config.data.title,
                        status: false,
                        alwaysAdd: child.config.alwaysAdd
                    };
                    item.children.push(newItem);
                    var next = child.children ? child.children : child.tabs;
                    createReportTree(newItem, next);
                }
            });
        }
    }

    function updateReportTree(children, status) {
        if (children) {
            children.forEach(function (child) {
                child.status = status ? status.indexOf(child.name) === -1 : false;
                updateReportTree(child.children, status);
            });
        }
    }

    $scope.updateReportAccess = function (item) {
        $state.current.data.loading = true;
        var keys = updateChildNavStatus(item.children, item.status);
        if (item.name) {
            keys.push(item.name);
        }
        var params = { keys: keys, status: item.status, userId: $scope.data.selectedUserId };
        api.set("orgAdmin/update_report_access", params, true).success(function () {
            $state.current.data.loading = false;
        });
    };

    function updateChildNavStatus(children, newStatus) {
        var keys = [];
        if (children) {
            children.forEach(function (child) {
                child.status = newStatus;
                keys.push(child.name);
                keys = keys.concat(updateChildNavStatus(child.children, newStatus));
            });
        }
        return keys;
    }


    (function init() {
        $state.current.data.loading = true;
        $scope.data = {
            team: [],
            userFilters: [],
            marketingRoles: [
                { value: "", ui: "" },
                { value: "demand generation", ui: "Demand Generation" },
                { value: "marketing operations", ui: "Marketing Operations" },
                { value: "field marketing", ui: "Field Marketing" },
                { value: "sales manager", ui: "Sales Manager" },
                { value: "executive", ui: "Executive" },
                { value: "other marketing", ui: "Other Marketing" }
            ],
            reports: [{
                title: "Toggle All",
                status: true,
                isTop: true
            }],
            filterDisplay: {
                lead: "People",
                opportunity: "Opportunity",
                campaign: "Campaign",
                campaign_member: "Activity",
                account: "Account"
            }
        };

        createReportTree($scope.data.reports[0], $rootScope.nav[0].children);

        (loadOrgUsersCall = api.get("/orgAdmin/load_org_users", null, false, true)).then(function (data) {
            $scope.data.team = data.data;
            $scope.data.selectedUser = $scope.data.team[0];
            $scope.data.selectedUserId = $scope.data.selectedUser && $scope.data.selectedUser.user_id;
            const status = $scope.data.team && $scope.data.team[0] && $scope.data.team[0].report_status;
            updateReportTree($scope.data.reports[0].children, status);
            $state.current.data.loading = false;
        });

        $scope.$on('$destroy', function () {
            if (loadOrgUsersCall) { loadOrgUsersCall.abort(); }
        });

    })();

}]);
