// eslint-disable-next-line no-unused-vars
/* global chartInstance */
"use strict";

app.controller('accountsInsightsWidgetCtrl', ['$scope', '$q', 'api', 'account', 'utilities', '$filter', '_',
    '$timeout', 'userModels', 'widgets', '$rootScope', '$compile', '$templateCache',
    'personaDefaults', 'cohorts', function ($scope, $q, api, accountSrv, utilities, $filter,
        _, $timeout, userModels, widgets, $rootScope,
        $compile, $templateCache, personaDefaults, cohorts) {

        var $tile = $scope.$tile;
        var dataService;
        /**
         * Label to use when all spike name models should be used.
         * The value should be unique as the name of the model is being used
         * as an identifier
         * @type {string}
         */
        var ALL_MODELS = 'All Models';

        // lookups use by the account metrics dropdown
        function getAccountTypes() {
            return [
                {
                    display: 'Accounts w/ Impressions',
                    value: 'ad_impressions',
                    label: "Impressions"
                },
                {
                    display: 'Accounts w/ Clicks',
                    value: 'ad_clicks',
                    label: "Clicks"
                },
                {
                    display: 'Accounts w/ Page Views',
                    value: 'page_views',
                    label: "Page Views"
                },
                {
                    display: 'Accounts w/ Spikes',
                    value: 'spikes',
                    label: "Spikes"
                },
                {
                    display: 'Accounts w/ Opportunities Created',
                    value: 'opptys',
                    label: "Opportunities Created"
                },
                {
                    display: 'Accounts w/ Deals',
                    value: 'deals',
                    label: "Deals"
                }
            ];
        }

        // lookups use by the metrics dropdown
        function getMetricTypes() {
            return [
                {
                    display: 'Total Impressions',
                    value: 'ad_impressions'
                },
                {
                    display: 'Total Clicks',
                    value: 'ad_clicks'
                },
                {
                    display: 'Total Page Views',
                    value: 'page_views'
                },
                {
                    display: 'Total Engagement Spikes',
                    value: 'spikes'
                }
            ];
        }

        // lookups use by the time units dropdown
        function getTimeUnits() {
            return [
                {
                    display: 'Auto',
                    value: 'auto'
                },
                {
                    display: 'Days',
                    value: 'day'
                },
                {
                    display: 'Weeks',
                    value: 'week'
                },
                {
                    display: 'Months',
                    value: 'month'
                },
                {
                    display: 'Quarters',
                    value: 'fiscal_qtr'
                },
                {
                    display: 'Years',
                    value: 'fiscal_year'
                }
            ];
        }

        function setCorrelationDisplay(value) {
            var selected = $filter('filter')(getAccountTypes(), { 'value': value })[0];
            $scope.data.correlationDisplay = selected.display;
            $scope.data.thresholdDisplay = selected.label;
        }

        // call utilities to return lookups and filter out the ones we do not need
        function getCohorts() {
            var unfilteredCohorts = angular.copy(utilities.formatCohorts(cohorts, ['all', 'ago', 'year', 'quarter', 'custom', 'toDate', 'lastFull']));

            return unfilteredCohorts.filter(function (item) {
                return item.key !== 'week2Date' && item.key !== 'lastFullWeek';
            });
        }

        // the structure is used by both angular-chart and c3js libraries for plotting bar and line charts
        function drawChart() {
            $scope.chart = {
                data: $scope.data.chartData,
                // angular-chart
                dimensions: {
                    time_interval_formatted: {
                        name: 'Date',
                        axis: 'x',
                        dataType: 'category'
                    },
                    accounts: {
                        name: $scope.widget.getAccountTypeLabel(),
                        type: $scope.widget.chart.type,
                        axis: 'y',
                        color: $scope.widget.colors.accounts
                    },
                    metric: {
                        name: $scope.widget.getMetricTypeLabel(),
                        type: $scope.widget.chart.type,
                        axis: 'y2',
                        color: $scope.widget.colors.metric
                    }
                },
                // c3js
                chart: {
                    data: {
                        selection: {
                            enabled: true
                        },
                        empty: {
                            label: {
                                text: 'No Data Available'
                            }
                        }
                    },
                    zoom: {
                        enabled: false
                    },
                    axis: {
                        x: {
                            tick: {
                                rotate: -35,
                                multiline: false
                            },
                            height: 50
                        },
                        y: {
                            label: {
                                text: $scope.widget.getAccountTypeLabel(),
                                position: 'outer-middle'
                            },
                            tick: {
                                format: function (d) {
                                    return $filter('nrFormat')(d, false, true, 1);
                                }
                            }
                        },
                        y2: {
                            show: true,
                            label: {
                                text: $scope.widget.getMetricTypeLabel(),
                                position: 'outer-middle'
                            },
                            tick: {
                                format: function (d) {
                                    return $filter('nrFormat')(d, false, true, 1);
                                }
                            }
                        }
                    },
                    legend: {
                        show: true,
                        item: {
                            onclick: function (id) {
                                var chart = $scope.data['chartInstance'];

                                chart.toggle(id);

                                if (id === 'accounts') {
                                    $scope.data.hideYAxis = chart.internal['hiddenTargetIds'].indexOf(id) !== -1;
                                }

                                if (id === 'metric') {
                                    $scope.data.hideY2Axis = chart.internal['hiddenTargetIds'].indexOf(id) !== -1;
                                }

                                // we do not want to send users to the account hub page yet
                                d3.event.preventDefault();
                            }
                        }
                    }
                }
            };

            // create tooltip
            $scope.chart.chart.tooltip = {
                // eslint-disable-next-line no-unused-vars
                contents: function (d, dt, dv, color) {
                    // include year part in data tooltip
                    $scope.data.tScope.title = $scope.chart.data[d[0].index].time_interval_formatted;

                    // setting the label,value and color for account and engagement types
                    $scope.data.tScope.rows = d.map(function (r) {
                        var item;
                        var displayLabel;
                        var metricType = r.id;

                        if (metricType === 'accounts') {
                            item = $scope.data.accountTypes.find(function (item) {
                                return item.value === $scope.widget.query.account_field;
                            });
                        } else if (metricType === 'metric') {
                            item = $scope.data.metricTypes.find(function (item) {
                                return item.value === $scope.widget.query.metric_field;
                            });
                        }

                        displayLabel = item ? item.display : 'N/A';

                        return {
                            label: displayLabel,
                            value: r.value.toLocaleString(),
                            color: $scope.widget.colors[metricType]
                        };
                    });

                    return $scope.data.tScope.$apply(function () { return $scope.data.tooltip[0].outerHTML; });
                }
            };

            $scope.data.tooltip = $compile($templateCache.get('chartTooltip.html'))($scope.data.tScope);

            $scope.chart.query = $scope.widget.query;

            $tile.loading(100);
        }

        // execute ajax call to retrieve metric data from the backend
        this.getData = function () {
            if (dataService) {
                dataService.abort();
            }

            if (!$scope.widget.query) {
                $tile.endLoading();
                return;
            }

            var getParams = angular.copy($scope.widget.query);
            // If all models were selected, remove
            // models from the query
            if (getParams.models &&
                getParams.models.length == 1 &&
                getParams.models[0] === ALL_MODELS) {
                delete getParams.models;
            }

            // execute data service with url params (filters)
            (dataService = api.getter(
                {
                    url: 'account/load_metric_insights',
                    params: getParams,
                    paramsNotToUpdate: 'all',
                    skipFilters: true
                })).then(
                // on success
                function (response) {
                    $tile.loading(30);

                    $scope.data.chartData = response.data || [];
                    $scope.widget.data.lastQuery = angular.copy($scope.widget.query);
                    $timeout(drawChart, 500, false);

                    $tile.endLoading();
                },
                // on error
                function (data) {
                    if (!data.aborted) {
                        $tile.failedLoading();
                    }
                }
            ).finally(
                // clean up
                function () {
                    // remove loader if it is still in loading state
                    $scope.$emit("widgetLoadingDone");
                    if ($tile.isLoading()) {
                        $tile.endLoading();
                    }
                }
            );
        };

        (function init(ctrl) {
            $tile.startLoading();
            $tile.loading(10);

            // get default configs for widget
            $scope.widget.data = _.defaults($scope.widget.data, personaDefaults.getQueryDefinitions($scope.widget).data);
            $scope.widget.chart = _.defaults($scope.widget.chart, personaDefaults.getQueryDefinitions($scope.widget).chart);
            $scope.widget.query = _.defaults($scope.widget.query, personaDefaults.getQueryDefinitions($scope.widget).query);

            // initialize the data object and set the selected accountType and engagementType to match the defaults
            $scope.data = {
                // show the y-axis by default
                hideYAxis: false,
                hideY2Axis: false,
                // tooltip
                tScope: $scope.$new(),
                // get cohorts, timeUnits, accountTypes and engagementTypes the ui-selections
                cohorts: getCohorts(),
                timeUnits: getTimeUnits(),
                accountTypes: getAccountTypes(),
                metricTypes: getMetricTypes(),
                correlationDisplay: "",
                thresholdDisplay: "",
                models: []
            };

            var modelPromise = accountSrv.getModelNames();

            modelPromise.then(function (data) {
                data.unshift(ALL_MODELS);
                $scope.data.models = data;
            });

            /**
             * Check if we should show spike models names
             * @return {boolean} True to show; otherwise false
             */
            $scope.shouldUseSpikesModel = function () {
                return $scope.widget.query.metric_field === 'spikes' ||
                    $scope.widget.query.account_field === 'spikes';
            };

            /**
             * On multiple select, check if all is selected if so,
             * remove all other selections. If a unique option is selected,
             * remove all.
             * @param item The item selected
             * @param model The model key for the item
             */
            $scope.onMultipleSelect = function (item, model) {
                if (model === ALL_MODELS) {
                    $scope.widget.query.models = [ALL_MODELS];
                } else {
                    $scope.widget.query.models = $scope.widget.query.models.filter(
                        function (elem) {
                            return elem !== ALL_MODELS;
                        });
                }
            };

            /**
             * Check if it is the last item
             */
            $scope.onRemoved = function () {
                if (!$scope.widget.query.models ||
                    $scope.widget.query.models.length === 0) {
                    $scope.widget.query.models = [ALL_MODELS];
                }
            };

            setCorrelationDisplay($scope.widget.query.account_field);

            $scope.widget.getAccountTypeLabel = function () {
                var account = $scope.data.accountTypes.find(function (item) {
                    return item.value === $scope.widget.query.account_field;
                });

                return account ? account.display : 'Account Metric';
            };

            $scope.widget.getMetricTypeLabel = function () {
                var engagement = $scope.data.metricTypes.find(function (item) {
                    return item.value === $scope.widget.query.metric_field;
                });

                return engagement ? engagement.display : 'Engagement Metric';
            };

            $scope.widget.getWidgetTitle = function () {
                return $scope.widget.getAccountTypeLabel() + ' vs. ' + $scope.widget.getMetricTypeLabel();
            };

            $scope.onBlurThreshold = function (event) {
                var value = parseInt(event.target.value, 10);

                // we do not want anything smaller than 1 or not a number
                if (value !== value || value <= 0) {
                    $scope.widget.query.threshold = 1;
                }
            };

            // eslint-disable-next-line no-unused-vars
            $scope.onChangeChartType = function (event) {
                $scope.chart.dimensions.accounts.type = $scope.widget.chart.type;
                $scope.chart.dimensions.metric.type = $scope.widget.chart.type;
            };

            $scope.onSelectAccountType = function (value) {
                // if someone selected impressions and time unit as day, we want to reset the time unit select to auto
                if (value === 'impressions' && $scope.widget.query.time_unit === 'day') {
                    $scope.widget.query.time_unit = 'auto';
                }
                setCorrelationDisplay(value);
            };

            $scope.onSelectMetricType = function (value) {
                // if someone selected spikes and time unit as day, we want to reset the time unit select to auto
                if (value === 'spikes' && $scope.widget.query.time_unit === 'day') {
                    $scope.widget.query.time_unit = 'auto';
                }
            };

            $scope.shouldHideThresholdInput = function () {
                var shouldHide = $scope.widget.query.account_field === 'opptys' || $scope.widget.query.account_field === 'deals' || $scope.widget.query.account_field === 'spikes';

                if (shouldHide) {
                    $scope.widget.query.threshold = 1;
                }

                return shouldHide;
            };

            $scope.shouldDisableDayInTimeUnitSelect = function (timeUnit) {
                return timeUnit === 'day' && ($scope.widget.query.account_field === 'impressions' || $scope.widget.query.metric_field === 'spikes');
            };

            $scope.$on('angular-chart-rendered', function (event, options, instance) {
                $scope.data.chartResizer = instance;
                widgets.resizeChart(instance, $scope);
            });

            $scope.$on('gridster-item-resize', function () {
                $timeout(function () {
                    widgets.resizeChart($scope.data.chartResizer);
                }, 500, false);
            });

            $scope.$on('$windowResizeEnd', function () {
                $timeout(function () {
                    widgets.resizeChart($scope.data.chartResizer, $scope);
                }, 10, false);
            });

            $scope.$on('$destroy', function () {
                if (dataService) {
                    dataService.abort();
                }

                $scope.data.tScope.$destroy();
            });

            $tile.listeners.push($scope.$on('widgetUpdate', function () {
                if (!angular.equals(angular.copy($scope.widget.query), $scope.widget.data.lastQuery)) {
                    $tile.startLoading();
                    $tile.loading(10);
                    ctrl.getData();
                }
            }));

        })(this);
    }]);
