'use strict';

// lodash
import 'lodash';

// jquery
import 'jquery';

// angular
import 'angular';
import 'angular-animate';
import 'angular-cookies';
import 'angular-input-masks';
import 'angular-material';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-touch';

// angular bind notifier
import 'angular-bind-notifier';

// angular ui
import 'angular-ui-bootstrap';
import 'angular-ui-router';
import 'angular-ui-event';
import './lib/select.js';

// select2
import 'select2';
import 'angular-ui-select2';

// angular wizard
import 'angular-wizard';

// angular file saver
import 'angular-file-saver';

// noty
import 'noty/js/noty/packaged/jquery.noty.packaged.js';
import './lib/angular.noty.js';

// edit in place
import 'angular-xeditable';

// local storage
import 'ngstorage';

// compress for storage;
import 'lz-string';

// angular toogle switch
import './lib/angular-toggle-switch.js';

// drag and drop library used in config
import 'ng-sortable';
import 'lrDragNDrop';

// angular gridser
import './lib/gridster.js';

// moment
import 'moment';

// date-fns
import 'date-fns';

// liquid text width
import './lib/jquery.fittext.js';
import './lib/jquery.textfill.js';

// angular smart table
import './lib/smart-table.js';
import './lib/st-multi-sort.js';

// create hotkeys
import 'angular-hotkeys';

// round progress loader
import 'angular-svg-round-progressbar';

// frontend csv export
import 'ng-csv';

// pdf & image export
import 'html2canvas';
import 'jspdf';
import 'pdfjs-dist';
import 'pdfmake';

// for jsonedit config
import 'Utils/jsonEdit/directives.js';
import 'angular-object-diff';

// pathseg
import './lib/pathseg.js';

// js detech element
import 'javascript-detect-element-resize';

// brightfunnel
import 'Styles/css/d3charts.css';
import 'Styles/less/styles.less';

// d3
import 'd3';
import 'c3';
import 'angular-chart';
import '@amcharts/amcharts4/core.js';
import '@amcharts/amcharts4/charts.js';

import 'Global/redirect-init';

//Module Definition
import './entry.ts';

// Global
import 'Global/mixins-srvc.js';
import 'Global/utilities-srvc.js';
import 'Global/filters-fltr.js';
import 'Global/core-directives.js';
import 'Global/core-services.js';
// //comment back in for visual engine
// import 'Global/visual-srvc.js';
import 'Global/api-srvc.js';
import 'Global/bf-state-provider.js';
import 'Global/chart-srvc.js';
import 'Global/colors-srvc.js';
import 'Global/dashboard-popover.ts';
import 'Global/export-srvc.js';
import 'Global/global-filters-ctrl.js';
import 'Global/models-srvc.js';
import 'Global/influence-type-srvc.js';
import 'Global/nav-ctrl.js';
import 'Global/newNav-ctrl.js';
import 'Global/framework-drtv.js';
import 'Global/rollbar.js';
import 'Global/legend-drtv.js';
import 'Global/filters-srvc.js';
import 'Global/nav-srvc.js';
import 'Global/top-right-ctrl.js';
import 'Global/download-csv-srvc.js';

// main app
import './application.js';

// Switch User - God Mode
import 'SwitchUser/switch_user.js';

import './shared/sigstr/service/sigstr-config-srvc.js';

// Account Engagement
import './main/analyze/accounts/accounts-ctrl.js';
import './main/analyze/accounts/trending/accountsTrending-ctrl.js';
import './main/analyze/accounts/accountsAttribution-ctrl.js';
import './main/analyze/accounts/accountsAttributionWidget-ctrl.js';
import './main/analyze/accounts/accountsInsightsWidget-ctrl.js';
import './main/analyze/accounts/accounts-srvc.js';

// account specific
import './main/analyze/campaigns/list-analysis/account-ctrl.js';
import './main/analyze/campaigns/list-analysis/account-specific/trending/accountTrending-ctrl.js';

// revenue attribution
import './main/discover/revenue-and-pipeline/attribution-by-quarter/revenueByChannelByQtr-ctrl.js';
import './main/discover/revenue-and-pipeline/attribution-trends/revenueByChannelAcrossQtr-ctrl.js';
import './main/discover/revenue-and-pipeline/rcbqWidget-ctrl.js';
import './main/discover/revenue-and-pipeline/rcbq_chart-drtv.js';
//comment back in for visual engine
import './main/discover/revenue-and-pipeline/revAttribution-srvc.js';

// campaign performance.
import './shared/campaign-performance/campaignPerformance-ctrl.js';
import './shared/campaign-performance/campaignMaturity-ctrl.js';
import './shared/campaign-performance/campaignPerformanceTrending-ctrl.js';
import './shared/campaign-performance/campaignPerformanceTrendingWidget-ctrl.js';
import './shared/campaign-performance/campaignPerformanceListWidget-ctrl.js';
import './shared/campaign-performance/campaignPerformance-srvc.js';
import './shared/campaign-performance/campaignPerformanceTrend-srvc.js';

// trending kpis
import './shared/trending-kpis/components/trending-kpi-tile/component.ts';
import './shared/trending-kpis/components/trending-kpis-widget/component.ts';
import './shared/trending-kpis/entry';

//campaign specific
import './main/analyze/campaigns/list-analysis/campaign-specific/campaign-ctrl.js';
import './main/analyze/campaigns/list-analysis/campaign-specific/attribution/campaignAttribution-ctrl.js';
import './main/analyze/campaigns/list-analysis/campaign-specific/trending/campaignTrending-ctrl.js';
import './main/analyze/campaigns/list-analysis/campaign-specific/sfdc/campaignSFDC-ctrl.js';
import './main/analyze/campaigns/list-analysis/campaign-specific/campaignSpecificTrendingWidget-ctrl.js';
import './main/analyze/campaigns/list-analysis/campaign-specific/line-chart-drtv.js';
import './main/analyze/campaigns/list-analysis/campaign-specific/campaign-srvc.js';

// config
import './shared/config/config-ctrl.js';
import './shared/config/team-ctrl.js';
import './shared/config/userProfile-ctrl.js';
import './shared/config/salesActivity-ctrl.js';
import './shared/config/internalConfig-ctrl.js';
import './shared/config/userInterface-ctrl.js';
import './shared/config/customWeight-ctrl.js';
import './shared/config/settingsNav-ctrl.js';
import './shared/config/internal-configure/permissionsConfig-ctrl.js';
import './shared/config/internal-configure/dataCycleConfig-ctrl.js';
import './shared/config/internal-configure/olfConfig-ctrl.js';
import './shared/config/internal-configure/userConfig-ctrl.js';
import './shared/config/internal-configure/demoConfig-ctrl.js';
import './shared/config/internal-configure/waterfallConfig-ctrl.js';
import './shared/config/internal-configure/orgConfig-ctrl.js';
import './shared/config/internal-configure/sqlConfig-ctrl.js';
import './shared/config/internal-configure/cacheConfig-ctrl.js';
import './shared/config/internal-configure/snippetConfig-ctrl.js';
import './shared/config/internal-configure/bomboraConfig-ctrl.js';
import './shared/config/internal-configure/services/internal-config-srvc.js';
import './shared/config/self-config/services/account-engagement-srvc.ts';
import './shared/config/self-config/services/lazy-load-srvc.js';
import './shared/config/SFintegration-ctrl.js';
import './shared/config/internal-configure/integrations-ctrl.js';
import './shared/config/internal-configure/webTracking-ctrl.js';
import './shared/config/internal-configure/sfdc/app.js';
import './shared/config/internal-configure/sfdc/sfdc-ctrl.js';
import './shared/config/internal-configure/sfdc/services/constants.js';
import './shared/config/internal-configure/sfdc/services/sfdc-srvc.js';
import './shared/config/internal-configure/sfdc/directives/json-viewer-ctrl.js';
import './shared/config/internal-configure/sfdc/directives/text-input-field-ctrl.js';
import './shared/config/internal-configure/sfdc/directives/org-selector-ctrl.js';
import './shared/self-config/selfConfig-ctrl.js';
import './shared/token-manager/entry.ts';
import './shared/account-list-upload/entry';
import './shared/self-config/fieldMapping-ctrl.js';
import './shared/self-config/account-engagement-ctrl.ts';
import './shared/self-config/validity-ctrl.js';
import './shared/self-config/global-filters/globalFilter-ctrl.js';
import './main/settings/self-configure/integrations/sfdc/sfdcIntegration-ctrl.js';
import './main/settings/self-configure/integrations/marketo/marketoIntegration-ctrl.js';

//config directives
import './shared/self-config/directives/panel/panel-ctrl';
import './shared/self-config/directives/label-div/label-div-ctrl';
import './shared/self-config/directives/config-toggle/config-toggle-ctrl';
import './shared/self-config/directives/config-radio/config-radio-ctrl';
import './shared/self-config/directives/multisearch/multisearch-ctrl';
import './shared/self-config/directives/error-div/error-div-ctrl';
import './shared/self-config/directives/config-input/config-input-ctrl';
import './shared/self-config/directives/horiz-radio/horiz-radio-ctrl';
import './shared/self-config/directives/group-multisearch/group-multisearch';

// dashboard
import './main/dashboard/index.js';

// Link to Terminus Hub
import './shared/terminus-hub/terminus-hub.service.ts';

// historical averages
import './main/analyze/campaigns/historicalAverage-ctrl.js';

// insights
import './shared/insights/insights-ctrl.js';
import './shared/insights/insights-srvc.js';

// marketing impact
import './main/discover/revenue-and-pipeline/marketing-impact/index.js';
import './main/discover/revenue-and-pipeline/marketing-impact/marketingImpact-ctrl.js';
import './main/discover/revenue-and-pipeline/marketing-impact/marketingImpactWidget-ctrl.js';

//oppty analysis
import './main/analyze/opportunities/list-analysis/opptysInRange-ctrl.js';
import './main/analyze/opportunities/list-analysis/all-opptys-touches/allOpptysTouches-ctrl.js';
import './main/analyze/opportunities/list-analysis/opptyAnalysis-srvc.js';
import './main/analyze/opportunities/list-analysis/opptyAnalysisListWidget-ctrl.js';
import './main/analyze/opportunities/list-analysis/directives/selector/header-selector-ctrl.js';

// //oppty history
import './main/analyze/opportunities/list-analysis/opportunity-specific/attribution/opportunityCampaignHistory-ctrl.js';

// orphan lead finder
import './main/settings/data-hygiene/orphan-lead-finder/orphanLeadFinder-ctrl.js';

//rev waterfall
import './main/discover/stage-progression/stages-snapshot/stagesSnapshotWidget-ctrl';
import './main/discover/stage-progression/attribution/revWaterfall-ctrl';
import './main/discover/stage-progression/trending/trending-ctrl.js';
import './main/discover/stage-progression/stackedBarChart-drtv.js';
import './main/discover/stage-progression/attribution/revWaterfall-srvc.js';
import './main/discover/stage-progression/stages-snapshot/flatStageProgress-ctrl.js';

// savable reports
import './shared/savable-reports/sav-reports-ctrl.js';

import 'Utils/trunc.js';

// Init pdf.js
import 'Global/pdfjs-init.js';

// Account journey
import 'AccountJourney/app.js';
import 'AccountJourney/constants/account-journey-constants.js';

// tab services
import 'AccountJourney/services/account-journey-srvc.js';
import 'AccountJourney/services/people_tab_srvc.js';
import 'AccountJourney/services/insight_tab_srvc.js';
import 'AccountJourney/services/activity_tab_srvc.js';
import 'AccountJourney/services/journey_tab_srvc.js';
import 'AccountJourney/services/datetime-srvc.js';

// components/controllers
import 'AccountJourney/components/insights/insights-component.js';

import 'AccountJourney/components/activity/activity-tab-component.js';
import 'AccountJourney/components/activity/activity-tab-controller.js';
import 'AccountJourney/components/chart/chart-component.js';
import 'AccountJourney/components/journey/journey-tab-component.js';
import 'AccountJourney/components/journey/journey-tab-controller.js';
import 'AccountJourney/components/people/people-tab-activity-component.js';
import 'AccountJourney/components/people/people-tab-activity-controller.js';
import 'AccountJourney/account-journey-ctrl.js';
import 'AccountJourney/account-journey-component.js';
import 'AccountJourney/components/people/people-tab-controller.js';
import 'AccountJourney/components/people/people-tab-component.js';
import 'AccountJourney/directives/card/card-ctrl.js';
import 'AccountJourney/directives/check-data/check-data-ctrl.js';
import 'AccountJourney/directives/page-header/page-header-ctrl.js';

// cards
import 'AccountJourney/components/insights/cards/most-active-person-card.js';
import 'AccountJourney/components/insights/cards/most-active-person-controller.js';
import 'AccountJourney/components/insights/cards/interest-on-website-card.js';
import 'AccountJourney/components/insights/cards/interest-on-website-controller.js';
import 'AccountJourney/components/insights/cards/most-frequent-topics-card.js';
import 'AccountJourney/components/insights/cards/most-frequent-topics-controller.js';
import 'AccountJourney/components/insights/cards/activity-trends-card.js';
import 'AccountJourney/components/insights/cards/activity-trends-controller.js';

// services/constants/filters
import 'AccountJourney/services/chart-generation-srvc.js';
import 'AccountJourney/services/account-journey-tooltip-generator.js';
import 'AccountJourney/services/chart-config-builder.js';
import 'AccountJourney/services/chart-data-aggregator.js';
import 'AccountJourney/services/chart-data-normalizer.js';
import 'AccountJourney/services/journey-date-range-generator.js';
import 'AccountJourney/services/journey-highlight-srvc.js';
import 'AccountJourney/services/chart-tooltip-data-format-srvc.js';
import 'AccountJourney/services/tooltip-template-srvc.js';
import 'AccountJourney/services/person-activity-count-aggregator.js';
import 'AccountJourney/services/c3-chart-refresh-srvc.js';

import 'AccountJourney/filters/orderByWithNullLastFilter.js';
import 'AccountJourney/filters/currentDateFilter.js';
import 'AccountJourney/filters/sortByDateFilter.js';

// Customer Entitlement Service
import './shared/customer-entitlement/customer-entitlement.service.ts';

// Hubspot Service
import './shared/hubspot/hubspot.service.ts';

// Bombora Module
import 'Bombora/bombora.js';

// jwt Authenticate Module
import 'Jwt/jwt.js';

// Environment Module
import 'Environment/index.js';

// routes
import './routes.js';

// require html files for ng-include
require('ng-cache-loader?prefix=packman:**!./main/new-nav.html');
require('ng-cache-loader?prefix=packman:**!./main/new-nav-top-right.html');
require('ng-cache-loader?prefix=packman:**!./main/filters/filters_small_panel.html');
require('ng-cache-loader?prefix=packman:**!./main/filters/filters_new.html');
require('ng-cache-loader?prefix=packman:**!./main/filters/filters_summary.html');
require('ng-cache-loader?prefix=packman:**!./main/filters/filtersSelect.html');
require('ng-cache-loader?prefix=packman:**!./main/analyze/digital-attribution/web-activity/channel-asset-specific/index.html');
require('ng-cache-loader?prefix=packman:**!./main/analyze/campaigns/trending-analysis/trending-details.html');
require('ng-cache-loader?prefix=packman:**!./main/analyze/campaigns/list-analysis/campaign-specific/attribution/index.html');
require('ng-cache-loader?prefix=packman:**!./shared/savable-reports/index.html');

// require dynamic images for ng-include/templates
import 'Styles/fonts/analyze.svg';
import 'Styles/fonts/dashboard3.svg';
import 'Styles/fonts/discover.svg';
import 'Styles/fonts/engage.svg';
import 'Styles/images/terminus-mark-solid.svg';
import './images/add-tile-icon.svg';
import './images/ring-alt.svg';
