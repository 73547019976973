app.controller('customWeightCtrl', ['$scope', '$http', '$window', '$location', 'utilities', 'api', '$q', '$filter', '$b', '$state', '$timeout', 'noty', '$rootScope', '_', function ($scope, $http, $window, $location, utilities, api, $q, $filter, $b, $state, $timeout, noty, $rootScope, _) {


    function updateAttribution(selections) {
        $state.current.data.loading = true;
        // eslint-disable-next-line no-unused-vars
        api.set("orgAdmin/update_attribution", selections, true).success(function (data) {
            $state.current.data.loading = false;
        });
        $scope.saveAttributionType('weightType');
    }

    function loadSelections(callback) {
        api.get([{ api: "orgAdmin/load_attribution_selections", params: {}, noCache: true }, { api: "load_config", params: { editable: true }, noCache: true }]).then(function (data) {
            if (data["load_config"].data.attribution.custom) {
                $scope.data.attributionType = data["load_config"].data.attribution.custom.weightType;
                $scope.data.weightType = data["load_config"].data.attribution.custom.weightType;
                $scope.data.ocrOverride = data["load_config"].data.attribution.custom.ocrOverride == "true";
            } else {
                $scope.data.attributionType = "default";
            }
            var sel = data["orgAdmin/load_attribution_selections"].data;
            if (sel.percent) {
                sel.percent.forEach(function (item) {
                    $scope.options.percent[item.type].value = item.value;
                });
            }

            //selections are set here
            $scope.data.selections = sel;
            $scope.calcPercentage();
            if (callback) { callback(); }
            $state.current.data.loading = false;
        });
    }

    //what is apply scores
    function applyScores(types, customType) {
        var newItem,
            newTypes = [],
            selections = $scope.data.selections[customType];
        types.forEach(function (item) {
            if (item === null) {
                newItem = { display: "", value: 0 };
            } else if (typeof (item) == "object") {
                newItem = item;
            } else {
                newItem = { display: item, value: 0 };
            }
            newTypes.push(newItem);
            if (selections) {
                selections.forEach(function (s) {
                    if (newItem.display == s.type) {
                        newItem.value = s.value;
                    }
                });
            }
        });
        newTypes.sort(function (a, b) { return b.value - a.value; });
        return newTypes;
    }


    $scope.calcPercentage = function () {
        var total = $scope.options.percent.source.value + $scope.options.percent.last.value + $scope.options.percent.other.value;
        var remaining = Math.abs(total - 100);
        if (total > 100) {
            $scope.data.remaining = {
                text: 'Over Limit',
                textCol: '#EC644B',
                bg: 'rgba(236,100,75,0.1)',
                value: remaining + '%',
                disabled: true
            };
        } else if (total < 100) {
            $scope.data.remaining = {
                text: 'Remaining',
                textCol: '#EC644B',
                bg: 'rgba(236,100,75,0.1)',
                value: remaining + '%',
                disabled: true
            };
        } else {
            $scope.data.remaining = {
                text: "You're at 100% !",
                textCol: '#27AE60',
                bg: 'rgba(39,174,96,0.1)',
                icon: 'icon icon-sm icon-check'
            };
        }
    };


    $scope.loadMeta = function () {
        _.forEach($scope.options.custom, function (type, key) {
            if (!type.types) {
                $state.current.data.loading = true;
                api.get(type.loadUrl, {}, false, false).then(function (data) {
                    type.types = applyScores(data.data, key);
                    type.loaded = true;
                    $state.current.data.loading = false;
                });
            } else if (!type.loaded) {
                // no url, apply scores
                type.types = applyScores(type.types, key);
                type.loaded = true;
            }
        });
        $scope.enableOCR();
    };

    $scope.parseInt = function (type) {
        type.value = _.parseInt(type.value);
    };

    $scope.savePercent = function () {
        var selections = [];
        for (var n in $scope.options.percent) {
            selections.push({ object: "percent", type: n, value: $scope.options.percent[n].value });
        }
        updateAttribution(selections);
    };

    /* save Custom selections */
    $scope.saveSelections = function () {
        var selections = [];

        _.forEach($scope.options.custom, function (item, key) {
            _.forEach(item.types, function (type) {
                selections.push({ object: key, type: type.display, value: type.value });
            });
        });
        updateAttribution(selections);
        $scope.saveAttributionType("ocrOverride", $scope.data.ocrOverride);
    };


    //move to save function
    $scope.saveAttributionType = function (type, ocr) {
        var data = { attribution: { custom: {} } };
        data.attribution.custom[type] = ocr ? $scope.data.ocrOverride : $scope.data.attributionType;
        api.set("orgAdmin/update_config", data, true).then(function () {
        });
    };

    $scope.enableOCR = function () {
        //this changes the opportunity contact role field depending on if OCR is enabled
        $scope.options.custom.touchType.types.forEach(function (item) {
            if (item.has_override) {
                item.value = $scope.data.ocrOverride ? 0 : 5;
                item.disabled = $scope.data.ocrOverride ? true : false;
            }
        });
    };

    $scope.save = function () {
        if ($scope.data.attributionType == 'percent') {
            $scope.savePercent();
        } else {
            $scope.saveSelections();
        }
    };

    (function init() {
        $state.current.data.loading = true;
        $scope._ = _;

        $scope.options = {
            weightTypes: ["percent", "custom"],
            percent: {
                source: { display: "First Touch", value: 40 },
                last: { display: "Last Touch", value: 40 },
                other: { display: "Other Touches", value: 20 },
            },
            custom: {
                channel: { display: "Channel", types: _.without($rootScope.revenueGroups, "", null) },
                // touchStatus: { display: "Campaign Member Status", types: null, loadUrl: "load_campaign_member_status" },
                //title: {display: "Lead/Contact Title", types: null, loadUrl: "" },
                touchPosition: { display: "Touch Point Position", types: ["Source Touch", "Oppty Conversion Touch"] },
                time: { display: "Time Decay", types: [{ display: "Months", value: 0 }, { display: "Half Life", value: 0 }] },
                touchType: { display: "Attributed Touch Type", types: [{ display: "Opportunity Contact Role", has_override: true, value: 5 }, "Account Contact", "Orphan Lead"] },
                ocrRole: { display: "Opportunity Contact Roles", types: null, loadUrl: "load_opportinity_contact_roles" },
            }
        };

        $scope.data = {
            // weightType: "percent",
            // customType: "channel",
            cmTypes: null,
            attributionType: "",
            ocrOverride: false,

        };

        loadSelections($scope.loadMeta);
    })();

}]);
