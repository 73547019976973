/**
 * Bombora Controller.
 */
(function () {
    'use strict';

    app.controller('bomboraCtrl', bomboraCtrl);

    bomboraCtrl.$inject = ['BomboraConstants', '$scope', '$timeout', 'topicsSrvc', 'noty', 'bomboraConfigSrvc'];

    /**
     * Bombora Controller.
     * @param BomboraConstants
     * @param $scope
     * @param topicsSrvc
     * @param noty
     */
    function bomboraCtrl(BomboraConstants, $scope, $timeout, topicsSrvc, noty, bomboraConfigSrvc) {
        var ctrl = this;
        /**
         * Organization ID.
         * @type {number}
         */
        var orgID = $scope.userData.organizationUuid;

        activate();

        function activate() {
            $scope.bombora_config = {};
            bomboraConfigSrvc.getConfiguredLimitThreshold({orgUuid: orgID})
                .then(function(config) {
                    $scope.bombora_config = config.data;
                    /**
                     * Title of the first header
                     * @type {string}
                     */
                    $scope.title1 = BomboraConstants.LABELS.TO_SELECT.HEADER + ' ( ' +
                        BomboraConstants.LABELS.TO_SELECT.SUBTITLE +
                        $scope.bombora_config.trackedTopicsLimit +
                        ' )';
                }, () => {
                    $scope.bombora_config = {
                        trackedTopicsLimit: 0,
                        canConfigureThreshold: false
                    };
                });

            /**
             * Search string used.
             * @type {string}
             */
            $scope.search = '';
            /**
             * Check if any changes have been made
             * @type {boolean}
             */
            $scope.disableSave = true;
            /**
             * List of topics to select
             * @type {Array}
             */
            $scope.topicsToSelect = [];
            /**
             * Label for the save button.
             * @type {string}
             */
            $scope.saveLabel = BomboraConstants.LABELS.SAVE.BUTTON;
            /**
             * Label for the clear button.
             * @type {string}
             */
            $scope.clearLabel = BomboraConstants.LABELS.CLEAR;
            /**
             * Description for the first header
             * @type {string}
             */
            $scope.subheader1 = BomboraConstants.LABELS.TO_SELECT.SUBHEADER;
            /**
             * Description for the second header
             * @type {string}
             */
            $scope.subheader2 = BomboraConstants.LABELS.SELECTED.SUBHEADER;
            /**
             * Title of the third header
             * @type {string}
             */
            $scope.title3 = BomboraConstants.LABELS.SCORE_THRESHOLD.HEADER;
            /**
             * Description of the third header
             * @type {string}
             */
            $scope.subheader3 = BomboraConstants.LABELS.SCORE_THRESHOLD.SUBHEADER;
            /**
             * Search placeholder
             * @type {string}
             */
            $scope.searchPlaceholder = BomboraConstants.LABELS.SEARCH.PLACEHOLDER;
            /**
             * Placeholder for the theme filter
             * @type {string}
             */
            $scope.filterPlaceholder1 = BomboraConstants.LABELS.FILTERS.HEAD +
                BomboraConstants.LABELS.FILTERS.THEME;
            /**
             * Placeholder for the category filter
             * @type {string}
             */
            $scope.filterPlaceholder2 = BomboraConstants.LABELS.FILTERS.HEAD +
                BomboraConstants.LABELS.FILTERS.CATEGORY;
            /**
             * List of categories to use. Can be null -> All
             * @type {Array|null}
             */
            $scope.categories = null;

            /**
             * The last selected filter.
             * @type {null}
             */
            ctrl.lastSelected = null;
            /**
             * The last list token
             * @type {null}
             */
            ctrl.token = null;
            /**
             * Timeout to wait until the user stops typing
             * @type {number}
             */
            ctrl.ignoreSearchTimeout = 0;

            topicsSrvc.getTopics(null, $scope.search).then(
                function (topics) {
                    ctrl.token = topics.next;
                    topicsSrvc.loadTopics(orgID).then(
                        function (data) {
                            /**
                             * List of selected topics
                             * @type {Array}
                             */
                            $scope.topicsSelected = data.topics;
                            /**
                             * List of topics to select.
                             * @type {Array}
                             */
                            $scope.topicsToSelect = topicsSrvc.filterSelected($scope.topicsSelected);
                            /**
                             * Slider value
                             * @type {number}
                             */
                            $scope.sliderValue = data.threshold;
                            /**
                             * List of themes for the UI
                             * @type {string}
                             */
                            topicsSrvc.getThemes().then(function (themes) {
                                $scope.themes = themes;
                                $scope.themes.unshift({
                                    key: null,
                                    value: $scope.filterPlaceholder1
                                });
                            }, function () {
                                $scope.themes = [{
                                    key: null,
                                    value: $scope.filterPlaceholder1
                                }];
                            });

                            update();
                        }, function () {
                            $scope.topicsSelected = [];
                            $scope.topicsToSelect = topicsSrvc.filterSelected($scope.topicsSelected);
                            topicsSrvc.getThemes().then(function (themes) {
                                $scope.themes = themes;
                                $scope.themes.unshift({
                                    key: null,
                                    value: $scope.filterPlaceholder1
                                });
                            }, function () {
                                $scope.themes = [{
                                    key: null,
                                    value: $scope.filterPlaceholder1
                                }];
                            });
                            $scope.sliderValue = 60;
                            update();
                        }
                    );
                },
                function () {
                    $scope.topicsToSelect = [];
                }
            );
        }

        /**
         * Local function to help select topics with a specific key
         * @param item The item to remove
         * @return {function(*, *, *): boolean} A function to filter out
         *   a specific item
         */
        function cmp(item) {
            return function (value) {
                return item.key !== value.key;
            };
        }

        /**
         * Function to update titles on the top headers
         */
        function update() {
            /**
             * Title of the second header
             * @type {string}
             */
            $scope.title2 = BomboraConstants.LABELS.SELECTED.HEADER + ' ( ' +
                ($scope.topicsSelected.length) + ' ' +
                BomboraConstants.LABELS.SELECTED.SUBTITLE +
                ' )';
        }

        /**
         * Removes all selected topics.
         */
        $scope.clearTopics = function () {
            $scope.disableSave = false;
            $scope.topicsToSelect = topicsSrvc.filterSelected([]);
            $scope.topicsSelected.length = 0;
            update();
        };

        /**
         * Add topics to the list of selected topics and remove it
         * from the list of topics to select
         * @param item The item to add
         */
        $scope.addTopics = function (item) {
            $scope.disableSave = false;
            if ($scope.topicsSelected.length >= $scope.bombora_config.trackedTopicsLimit) {
                return;
            }
            $scope.topicsSelected.push(item);
            $scope.topicsToSelect = $scope.topicsToSelect.filter(cmp(item));
            update();
        };

        /**
         * Remove an item from the list of selected topics
         * @param item The item to remove
         */
        $scope.removeTopics = function (item) {
            $scope.disableSave = false;
            $scope.topicsSelected = $scope.topicsSelected.filter(cmp(item));
            $scope.topicsToSelect = topicsSrvc.filterSelected($scope.topicsSelected);
            update();
        };

        /**
         * When a new theme is selected, update info
         * @param item Selected theme
         */
        $scope.onThemeChanged = function (item) {
            ctrl.lastSelected = item;
            topicsSrvc.getCategories(item).then(function (categories) {
                $scope.categories = categories;
                $scope.categories.unshift({
                    key: item.key,
                    value: $scope.filterPlaceholder2
                });

                topicsSrvc.getTopics(item, $scope.search).then(
                    function (topics) {
                        $scope.topicsToSelect = topicsSrvc.filterSelected($scope.topicsSelected);
                        ctrl.token = topics.next;
                        update();
                    },
                    function () { }
                );

            });
        };

        /**
         * When a new category is selected, update info
         * @param item The selected category
         */
        $scope.onCategoryChanged = function (item) {
            ctrl.lastSelected = item;
            topicsSrvc.getTopics(item, $scope.search).then(
                function (topics) {
                    $scope.topicsToSelect = topicsSrvc.filterSelected($scope.topicsSelected);
                    ctrl.token = topics.next;
                    update();
                },
                function () { }
            );
        };

        /**
         * When the search text changes, update topics to select
         * @param text The text in the search box
         */
        $scope.onSearch = function (text) {
            if (ctrl.ignoreSearchTimeout) {
                $timeout.cancel(ctrl.ignoreSearchTimeout);
            }
            ctrl.ignoreSearchTimeout = $timeout(function () {
                $scope.search = text;
                topicsSrvc.getTopics(ctrl.lastSelected, $scope.search).then(
                    function (topics) {
                        $scope.topicsToSelect = topicsSrvc.filterSelected($scope.topicsSelected);
                        ctrl.token = topics.next;
                        update();
                    },
                    function () { }
                );
            }, 500);
        };

        /**
         * Save the existing configuration.
         */
        $scope.save = function () {
            topicsSrvc.saveTopics(orgID, $scope.topicsSelected, $scope.sliderValue).then(
                function () {
                    noty.growl(BomboraConstants.LABELS.SAVE.MESSAGE.OK, 'success');
                },
                function () {
                    noty.growl(BomboraConstants.LABELS.SAVE.MESSAGE.ERROR, 'error');
                }
            );
        };

        /**
         * Update the value from the slider
         * @param value
         */
        $scope.sliderChange = function (value) {
            $scope.disableSave = false;
            $scope.sliderValue = value;
        };

        // eslint-disable-next-line no-unused-vars
        $scope.lazyLoader = function (params) {
            if (ctrl.token) {
                topicsSrvc.getTopics(null, null, ctrl.token).then(
                    function (topics) {
                        $scope.topicsToSelect = $scope.topicsToSelect.concat(topics.topics);
                        ctrl.token = topics.next;
                        update();
                    },
                    function () { }
                );
            }
        };

        $scope.hasPermissionToEdit = topicsSrvc.hasPermissionToEdit($scope.userData);

    }
})();
