import { module as angularModule } from 'angular';

import { APP_BRIGHTFUNNEL } from '../../../constants';
import { IKpiResponse } from 'src/shared/models/scorecard/kpi-response/model';

const app = angularModule(APP_BRIGHTFUNNEL);

export class WidgetResponseStateService {
    public kpiResponses: Record<string, IKpiResponse> = {};
}

export const SRVC_WIDGET_RESPONSE_STATE = 'WidgetResponseStateService';
app.service(SRVC_WIDGET_RESPONSE_STATE, WidgetResponseStateService);
