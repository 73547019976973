import { module as angularModule } from 'angular';
import {
    startOfWeek,
    format,
} from 'date-fns';


import { APP_BRIGHTFUNNEL } from '../../../constants';
import * as CONSTANTS from '../constants';
import { IKpiTrendInterval } from 'src/shared/models/scorecard/kpi-trend-interval/model';
import { IKpiSegment } from 'src/shared/models/scorecard/kpi-segment/model';

const app = angularModule(APP_BRIGHTFUNNEL);

export interface IKpiGraphData {
    descriptor: string;
}

export class KpiDataFormatService {
    static  $inject: string[] = [
        '$filter',
    ];
    public moment: any;

    public formatByWeek(year: number, intervalVal: number) {
        return format(
            startOfWeek(new Date(year, 0, (intervalVal - 1) * 7), { weekStartsOn: 1 }),
            'MMM d, yyyy'
        );
    }

    public formatByMonth(year: number, intervalVal: number) {
        return format(
            new Date(year, intervalVal - 1, 1),
            'MMM d, yyyy'
        );
    }

    public formatByQuarter(year: number, intervalVal: number) {
        return `Q${intervalVal} ${year}`;
    }

    public formatIntervalDate (
        intervalDateStr: string,
        kpiInterval: string,
    ) {
        const [year, intervalVal] = intervalDateStr.split(' ');
        switch (kpiInterval) {
        case CONSTANTS.INTERVAL_WEEKS: {
            return this.formatByWeek(+year, +intervalVal);
        }
        case CONSTANTS.INTERVAL_MONTHS: {
            return this.formatByMonth(+year, +intervalVal);
        }
        case CONSTANTS.INTERVAL_QUARTERS: {
            return this.formatByQuarter(+year, +intervalVal);
        }
        default: {
            return `${intervalVal} ${year}`;
        }
        }
    }

    public formatTrendingGraphData (
        kpiData: IKpiTrendInterval[],
        kpiInterval: CONSTANTS.TrendingKPIsIntervalTypes,
        kpiGroup: CONSTANTS.TrendingKPIsGroupingTypes,
        withSortKey: boolean,
    ) {
        const data = [ ...kpiData ].sort((a, b) => (a.descriptor > b.descriptor) ? 1 : ((b.descriptor > a.descriptor) ? -1 : 0))
            .map((dateIntervals: IKpiTrendInterval) => {
                const formattedData: Partial<IKpiGraphData> = {};
                const formattedIntervalDate = this.formatIntervalDate(dateIntervals.descriptor, kpiInterval);
                formattedData.descriptor = formattedIntervalDate;

                if (kpiGroup === CONSTANTS.KPI_GROUPING_NONE) {
                    formattedData['total'] = dateIntervals.total;
                    return formattedData;
                }

                if (withSortKey) {
                    this.formatSeriesKeysMap(formattedData, dateIntervals.segments);
                } else {
                    this.formatGraphSegmentData(formattedData, dateIntervals.segments);
                }

                return formattedData;
            });
        return data;
    }

    public formatParetoGraphData(
        kpiData: IKpiTrendInterval[],
        kpiInterval: CONSTANTS.TrendingKPIsIntervalTypes,
    ) {
        let accumulator = 0;
        const data = [ ...kpiData ].sort((a, b) => (a.descriptor > b.descriptor) ? 1 : ((b.descriptor > a.descriptor) ? -1 : 0))
            .map((dateIntervals: IKpiTrendInterval) => {
                const formattedData: Partial<IKpiGraphData> = {};
                const formattedIntervalDate = this.formatIntervalDate(dateIntervals.descriptor, kpiInterval);

                formattedData.descriptor = formattedIntervalDate;
                formattedData['total'] = this.$filter('number')(dateIntervals.total, 0);
                accumulator += dateIntervals.total;

                formattedData['accumulated'] = this.$filter('number')(accumulator, 0);

                return formattedData;
            });

        return data;
    }

    public formatGraphSegmentData (
        dataTable: Partial<IKpiGraphData>,
        segments: IKpiSegment[],
    ) {
        segments.map((segment: IKpiSegment) => {
            dataTable[segment.descriptor ? segment.descriptor : CONSTANTS.TRENDING_KPIS_MISSING_DESCRIPTOR_BACKFILL] = segment.amount;
        });
    }

    public formatSeriesKeysMap(
        dataTable: Partial<IKpiGraphData>,
        segments: IKpiSegment[],
    ) {
        segments.map((segment: IKpiSegment) => {
            dataTable[segment.sortKey] = segment.descriptor ? segment.descriptor : CONSTANTS.TRENDING_KPIS_MISSING_DESCRIPTOR_BACKFILL;
        });
    }

    public formatAsCurrency = (value: number) => `${this.$filter('currency')(value, '$', 0)}`;

    public formatAsPercentage = (value: number) => `${this.$filter('percentage')(value, 0)}`;

    public formatAsNumber = (value: number) => `${this.$filter('number')(value, 0)}`;

    public formatAsTruncatedValue = (value: number) => `${this.$filter('nrFormat')(value, 0)}`;

    public formatKpiValue = (value: number, kpiType: string, truncate: boolean) => {
        switch (CONSTANTS.KPI_TYPE_FORMAT_MAP[kpiType]) {
        case CONSTANTS.KPIFormat.Currency:
            return truncate ? `$${this.formatAsTruncatedValue(value)}` : this.formatAsCurrency(value);

        case CONSTANTS.KPIFormat.Percentage:
            return truncate ? `${this.formatAsTruncatedValue(value)}%` : this.formatAsPercentage(value);

        default:
            return truncate ? this.formatAsTruncatedValue(value) : this.formatAsNumber(value);
        }
    }

    public getSeriesKeysMap(graphData: Partial<IKpiGraphData>[]) {
        return Object.keys(graphData[0])
            .filter((key) => key !== 'descriptor')
            .map((key) => ({ key: graphData[0][key] }));
    }

    public stripSpecialCharacters = (value: string) => value.replace(/[?%,$]/gi, '');

    constructor (
        public $filter: any,
    ) { }
}

export const SRVC_KPI_DATA_FORMAT = 'kpiDataFormatService';
app.service(SRVC_KPI_DATA_FORMAT, KpiDataFormatService);
