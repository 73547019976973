app.service('personaMap', ['$rootScope', 'campaignPerformance', 'utilities', '$filter', function ($rootScope, campaignPerformance, utilities, $filter) {
    this.personRoleMap = [
        {
            key: 'blank',
            name: 'new Custom Dashboard',
            dashboard: 'Custom Dashboard'
        },
        {
            key: 'contentMarketing',
            name: 'content marketing',
            dashName: 'Content Marketing Dashboard'
        },
        {
            key: 'demandGen',
            name: 'demand generation',
            dashName: 'Demand Generation Dashboard'
        },
        {
            key: 'digitalMarketing',
            name: 'digital marketer',
            dashName: 'Digital Marketing Dashboard'
        },
        {
            key: 'executive',
            name: 'executive',
            dashName: 'Executive Dashboard'
        },
        {
            key: 'fieldMarketing',
            name: 'field marketing',
            dashName: 'Field Marketing Dashboard'
        },
        {
            key: 'marketingOps',
            name: 'marketing operations',
            dashName: 'Marketing Operations Dashboard'
        },
        {
            key: 'otherMarketing',
            name: 'other marketing',
            dashName: 'My Dashboard'
        },
        {
            key: 'salesLeader',
            name: 'sales manager',
            dashName: 'Sales Manager Dashboard'
        },
    ];

    this.personaMap = function () {
        var map = {
            otherMarketing: {},
            executive: {
                'stages snapshot qtd': {
                    query: {
                        cohort: 'quarter2Date'
                    }
                },
                'attributed pipeline by quarter': {
                    query: {
                        period: 'quarter',
                        type: 'actual',
                        opptyType: 'oppty',
                        modelType: 'even'
                    }
                },
                'pipeline trend qtd': {
                    query: {
                        field: 'pipeline',
                        model: 'even',
                        cohort: 'quarter2Date',
                        grp: "no-list",
                        dataSet: "membershipActivity",
                        previous: true,
                        freqType: 'auto',
                    },
                    type: 'trend'
                },
                'top campaigns by attributed pipeline': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'membershipActivity',
                        model: 'even',
                        camp: 'campaigns',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.even.pipeline'
                    }
                },
                'attributed revenue by quarter': {
                    query: {
                        period: 'quarter',
                        type: 'actual',
                        opptyType: 'deal',
                        modelType: 'even'
                    }
                },
                'revenue trend qtd': {
                    query: {
                        field: 'revenue',
                        model: 'even',
                        cohort: 'quarter2Date',
                        grp: "no-list",
                        dataSet: "membershipActivity",
                        previous: true,
                        freqType: 'auto'
                    },
                    type: 'trend',
                },
                'top campaigns by attributed revenue': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'membershipActivity',
                        model: 'even',
                        camp: 'campaigns',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.even.revenue'
                    }
                },
                'top opptys closed won this quarter': {
                    query: {
                        closedType: 'won',
                        cohort: 'quarter2Date',
                        dateType: 'closed',
                        model: 'even',
                        type: 'revenue'
                    }
                },
                'top opptys created this quarter': {
                    query: {
                        closedType: 'any',
                        cohort: 'quarter2Date',
                        dateType: 'closed',
                        model: 'even',
                        type: 'pipeline'
                    }
                },
                'top opptys forecasted this quarter': {
                    query: {
                        closedType: 'open',
                        cohort: 'quarter2Date',
                        dateType: 'closed',
                        model: 'even',
                        type: 'forecast'
                    }
                },
            },
            demandGen: {
                'leads this q vs. last year this q': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'membershipActivity',
                        field: 'leads',
                        freqType: 'auto',
                        grp: 'no-list',
                        model: 'even',
                        previous: 'yoy',
                    },
                    type: {
                        type: 'benchmark'
                    }
                },
                'leads this q vs. last q': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'membershipActivity',
                        field: 'leads',
                        freqType: 'auto',
                        grp: 'no-list',
                        model: 'even',
                        previous: true,
                    },
                    type: {
                        type: 'benchmark'
                    }
                },
                'top types by leads sourced qtd': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'membershipActivity',
                        model: 'even',
                        camp: 'groups',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.sourced.leads'
                    }
                },
                'top campaigns by leads sourced qtd': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'membershipActivity',
                        model: 'even',
                        camp: 'campaigns',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.sourced.leads'
                    }
                },
                'attributed pipeline this q vs. last year this q': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'opptyCreatedDate',
                        field: 'pipeline',
                        freqType: 'auto',
                        grp: 'no-list',
                        model: 'even',
                        previous: 'yoy',
                    },
                    type: {
                        type: 'benchmark'
                    }
                },
                'attributed pipeline this q vs. last q': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'opptyCreatedDate',
                        field: 'pipeline',
                        freqType: 'auto',
                        grp: 'no-list',
                        model: 'even',
                        previous: true,
                    },
                    type: {
                        type: 'benchmark'
                    }
                },
                'top types by attributed pipeline qtd': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'opptyCreatedDate',
                        model: 'even',
                        camp: 'groups',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.even.pipeline'
                    }
                },
                'top campaigns by attributed pipeline qtd': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'opptyCreatedDate',
                        model: 'even',
                        camp: 'campaigns',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.even.pipeline'
                    }
                }
            },
            marketingOps: {
                '1st touch: top types by revenue': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'membershipActivity',
                        model: 'sourced',
                        camp: 'groups',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.sourced.revenue'
                    }
                },
                'last touch: top types by revenue': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'membershipActivity',
                        model: 'last',
                        camp: 'groups',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.last.revenue'
                    }
                },
                'evenly weighted: top types by revenue': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'membershipActivity',
                        model: 'even',
                        camp: 'groups',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.even.revenue'
                    }
                },
                'top accounts by engagement': {
                    query: {
                        cohort: 'months3',
                        account_filter: 'all',
                        withSalesActivity: $rootScope.orgConfig.salesActivity.enabled,
                        fld: 'campaign_members'
                    }
                },
                '1st touch: top campaigns by revenue': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'membershipActivity',
                        model: 'sourced',
                        camp: 'campaigns',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.sourced.revenue'
                    }
                },
                'last touch: top campaigns by revenue': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'membershipActivity',
                        model: 'last',
                        camp: 'campaigns',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.last.revenue'
                    }
                },
                'evenly weighted: top campaigns by revenue': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'membershipActivity',
                        model: 'even',
                        camp: 'campaigns',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.even.revenue'
                    }
                },
                'top forecasted opptys this quarter': {
                    query: {
                        closedType: 'open',
                        cohort: 'quarter2Date',
                        dateType: 'closed',
                        model: 'even',
                        type: 'forecast'
                    }
                },
                'last full week stages snapshot': {
                    query: {
                        cohort: 'lastFullWeek'
                    }
                },
                'quarter to date stages snapshot': {
                    query: {
                        cohort: 'quarter2Date'
                    }
                }
            },
            //waterfall,last lead stage
            contentMarketing: {
                'last full quarter: top campaigns by attributed pipeline': {
                    query: {
                        cohort: 'lastFullQuarter',
                        dataSet: 'membershipActivity',
                        model: 'even',
                        camp: 'campaigns',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.even.pipeline'
                    }
                },
                'qtd: top campaigns by attributed pipeline': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'membershipActivity',
                        model: 'even',
                        camp: 'campaigns',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.even.pipeline'
                    }
                },
                'next quarter: top campaigns by forecasted pipeline': {
                    query: {
                        cohort: $filter('filter')(utilities.formatCohorts($rootScope.rawCohorts, ['quarter']), { scope: 'quarter' })[0].key,
                        dataSet: 'opptyCloseDate',
                        model: 'even',
                        camp: 'campaigns',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.even.pipeline'
                    }
                },
                'leads goal qtd': {
                    query: {
                        field: 'leads',
                        model: 'even',
                        cohort: 'quarter2Date',
                        grp: "no-list",
                        dataSet: "membershipActivity",
                        previous: true,
                        freqType: 'auto',
                    },
                    type: {
                        type: 'goal'
                    },
                    data: {
                        goals: {
                            leads: {
                                quarter2Date: 0
                            }
                        }
                    }
                },
                'pipeline goal qtd': {
                    query: {
                        field: 'pipeline',
                        model: 'even',
                        cohort: 'quarter2Date',
                        grp: "no-list",
                        dataSet: "membershipActivity",
                        previous: true,
                        freqType: 'auto',
                    },
                    type: {
                        type: 'goal'
                    },
                    data: {
                        goals: {
                            pipeline: {
                                quarter2Date: 0
                            }
                        }
                    }
                },
                'top opptys created this quarter': {
                    query: {
                        closedType: 'any',
                        cohort: 'quarter2Date',
                        dateType: 'created',
                        model: 'even',
                        type: 'pipeline'
                    }
                },
                'top opptys closed won this quarter': {
                    query: {
                        closedType: 'won',
                        cohort: 'quarter2Date',
                        dateType: 'closed',
                        model: 'even',
                        type: 'revenue'
                    }
                },
                'top opptys forecasted this quarter': {
                    query: {
                        closedType: 'open',
                        cohort: 'quarter2Date',
                        dateType: 'closed',
                        model: 'even',
                        type: 'forecast'
                    }
                },
                'stages snapshot qtd': {
                    query: {
                        cohort: 'quarter2Date'
                    }
                },
                'top accounts by engagement': {
                    query: {
                        cohort: 'months3',
                        account_filter: 'all',
                        withSalesActivity: $rootScope.orgConfig.salesActivity.enabled,
                        fld: 'campaign_members'
                    }
                },
                'top accounts by sales activity': {
                    query: {
                        cohort: 'quarter2Date',
                        account_filter: 'all',
                        withSalesActivity: $rootScope.orgConfig.salesActivity.enabled,
                        fld: 'sales_activity'
                    }
                },
            },
            fieldMarketing: {
                'last full quarter: top campaigns by attributed pipeline': {
                    query: {
                        cohort: 'lastFullQuarter',
                        dataSet: 'membershipActivity',
                        model: 'even',
                        camp: 'campaigns',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.even.pipeline'
                    }
                },
                'qtd: top campaigns by attributed pipeline': {
                    query: {
                        cohort: 'quarter2Date',
                        dataSet: 'membershipActivity',
                        model: 'even',
                        camp: 'campaigns',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.even.pipeline'
                    }
                },
                'next quarter: top campaigns by forecasted pipeline': {
                    query: {
                        cohort: $filter('filter')(utilities.formatCohorts($rootScope.rawCohorts, ['quarter']), { scope: 'quarter' })[0].key,
                        dataSet: 'opptyCloseDate',
                        model: 'even',
                        camp: 'campaigns',
                        grp: 'no-list',
                        stages: campaignPerformance.stages().join("|"),
                        field: 'ranged.even.pipeline'
                    }
                },
                'leads goal qtd': {
                    query: {
                        field: 'leads',
                        model: 'even',
                        cohort: 'quarter2Date',
                        grp: "no-list",
                        dataSet: "membershipActivity",
                        previous: true,
                        freqType: 'auto',
                    },
                    type: {
                        type: 'goal'
                    },
                    data: {
                        goals: {
                            leads: {
                                quarter2Date: 0
                            }
                        }
                    }
                },
                'pipeline goal qtd': {
                    query: {
                        field: 'pipeline',
                        model: 'even',
                        cohort: 'quarter2Date',
                        grp: "no-list",
                        dataSet: "membershipActivity",
                        previous: true,
                        freqType: 'auto',
                    },
                    type: {
                        type: 'goal'
                    },
                    data: {
                        goals: {
                            pipeline: {
                                quarter2Date: 0
                            }
                        }
                    }
                },
                'top opptys closed won this quarter': {
                    query: {
                        closedType: 'won',
                        cohort: 'quarter2Date',
                        dateType: 'closed',
                        model: 'even',
                        type: 'revenue'
                    }
                },
                'top opptys created this quarter': {
                    query: {
                        closedType: 'any',
                        cohort: 'quarter2Date',
                        dateType: 'closed',
                        model: 'even',
                        type: 'pipeline'
                    }
                },
                'top opptys forecasted this quarter': {
                    query: {
                        closedType: 'open',
                        cohort: 'quarter2Date',
                        dateType: 'closed',
                        model: 'even',
                        type: 'forecast'
                    }
                },
                'stages snapshot qtd': {
                    query: {
                        cohort: 'quarter2Date'
                    }
                },
                'top accounts by engagement': {
                    query: {
                        cohort: 'quarter2Date',
                        account_filter: 'all',
                        withSalesActivity: $rootScope.orgConfig.salesActivity.enabled,
                        fld: 'campaign_members'
                    }
                },
                'top accounts by sales activity': {
                    query: {
                        cohort: 'quarter2Date',
                        account_filter: 'all',
                        withSalesActivity: $rootScope.orgConfig.salesActivity.enabled,
                        fld: 'sales_activity'
                    }
                },
            },
            salesLeader: {
                'attributed pipeline by quarter': {
                    query: {
                        modelType: 'even',
                        opptyType: 'oppty',
                        period: 'quarter',
                        type: 'actual'
                    }
                },
                'pipeline trend qtd': {
                    query: {
                        field: 'pipeline',
                        model: 'even',
                        cohort: 'quarter2Date',
                        grp: "no-list",
                        dataSet: "opptyCloseDate",
                        previous: true,
                        freqType: 'auto'
                    },
                    type: {
                        type: 'trend',
                    }
                },
                'attributed revenue by quarter': {
                    query: {
                        modelType: 'even',
                        opptyType: 'deal',
                        period: 'quarter',
                        type: 'actual'
                    }
                },
                'revenue trend qtd': {
                    query: {
                        field: 'revenue',
                        model: 'even',
                        cohort: 'quarter2Date',
                        grp: "no-list",
                        dataSet: "opptyCloseDate",
                        previous: true,
                        freqType: 'auto'
                    },
                    type: {
                        type: 'trend',
                    }
                },
                'top accounts by engagement': {
                    query: {
                        cohort: 'quarter2Date',
                        account_filter: 'all',
                        withSalesActivity: $rootScope.orgConfig.salesActivity.enabled,
                        fld: 'campaign_members'
                    }
                },
                'top accounts by sales activity': {
                    query: {
                        cohort: 'quarter2Date',
                        account_filter: 'all',
                        withSalesActivity: $rootScope.orgConfig.salesActivity.enabled,
                        fld: 'sales_activity'
                    }
                },
                'top opptys closed won this quarter': {
                    query: {
                        closedType: 'won',
                        cohort: 'quarter2Date',
                        dateType: 'closed',
                        model: 'even',
                        type: 'revenue'
                    }
                },
                'top opptys created this quarter': {
                    query: {
                        closedType: 'any',
                        cohort: 'quarter2Date',
                        dateType: 'closed',
                        model: 'even',
                        type: 'pipeline'
                    }
                },
                'top opptys forecasted this quarter': {
                    query: {
                        closedType: 'open',
                        cohort: 'quarter2Date',
                        dateType: 'closed',
                        model: 'even',
                        type: 'forecast'
                    }
                },
            }
        };
        map.digitalMarketing = angular.copy(map.demandGen);
        return map;
    };

    this.queryDefaultMap = {
        'cpList': {
            query: {
                cohort: 'months3',
                dataSet: 'membershipActivity',
                model: 'even',
                camp: 'groups',
                grp: 'no-list'
            }
        },
        'campaignsList': {
            query: {
                cohort: 'months3',
                dataSet: 'membershipActivity',
                model: 'even',
                camp: 'campaigns',
                grp: 'no-list'
            }
        },
        'ungatedCampaigns': {
            query: {
                cohort: 'months3',
                dataSet: 'membershipActivity',
                model: 'even',
                camp: 'campaigns',
                grp: 'no-list',
                field: "ranged.even.leads"
            }
        },
        'accountsList': {
            query: {
                cohort: 'months3',
                account_filter: 'all',
                withSalesActivity: $rootScope.orgConfig.salesActivity.enabled,
                dir: 'd',
                fld: 'campaign_members',
                pg: '1',
                ps: '13'
            }
        },
        'opptyAnalysisList': {
            query: {
                dateType: 'created',
                closedType: 'any',
                cohort: 'quarter2Date',
                type: 'revenue'
            }
        },
        'stagesSnapshot': {
            query: {
                cohort: 'quarter2Date'
            }
        },
        'leadsGenerated': {
            query: {
                field: 'leads',
                model: 'even',
                cohort: $filter('filter')(utilities.formatCohorts($rootScope.rawCohorts, ['quarter']), { scope: 'quarter' })[0].key,
                grp: "no-list",
                dataSet: "membershipActivity",
                previous: true,
                freqType: 'auto',
            },
            type: {
                type: 'benchmark'
            }
        },
        'marketingImpact': {
            query: {
                period: 'quarter',
                type: 'actual',
                opptyType: 'deal',
                modelType: 'even',
                plotPoints: 3,
            }
        },
        'velocityCampaign': {
            query: {
                cohort: 'months3',
                groupType: 'campaign',
            },
            data: {
                filterType: 'all',
                sortType: 'ltd',
                chart: {
                    scale: []
                }
            }
        },
        'topRevenue': {
            query: {
                cohort: $filter('filter')(utilities.formatCohorts($rootScope.rawCohorts, ['quarter']), { scope: 'quarter' })[0].key,
                grp: 'only',
                revenueType: 'revenue',
                dataSet: 'opptyCloseDate',
                model: 'even'
            },
            data: {
                model: 'even'
            }
        },
        'campaignSpecificTrending': {
            query: {
                modelType: 'even',
                field: 'leads',
                dataSet: 'membershipActivity',
                chartType: 'pipeline',
                cohort: '*NONE*'
            },
            data: {
                field: 'leads'
            }
        },
        /* ENGAGEMENT INSIGHTS WIDGET */
        'engagementInsights': {
            query: {
                cohort: '*NONE*',
                time_unit: 'auto',
                account_field: 'opptys',
                threshold: 1,
                metric_field: 'impressions',
                account_correlation: false
            },
            chart: {
                type: 'bar'
            }
        },
        'webActivityList': {
            query: {
                cohort: 'months3',
                dataSet: 'membershipActivity',
                model: 'even',
                camp: 'groups',
                grp: 'no-list'
            }
        },
        'channelAssetsList': {
            query: {
                cohort: 'months3',
                dataSet: 'membershipActivity',
                model: 'even',
                camp: 'campaigns',
                grp: 'no-list'
            }
        },
        'channelAssetSpecificTrendingTile': {
            query: {
                modelType: 'even',
                field: 'leads',
                dataSet: 'membershipActivity',
                chartType: 'pipeline',
                cohort: '*NONE*'
            },
            data: {
                field: 'leads'
            }
        },
        'webActivityTrendingTile': {
            query: {
                field: 'leads',
                model: 'even',
                cohort: $filter('filter')(utilities.formatCohorts($rootScope.rawCohorts, ['quarter']), { scope: 'quarter' })[0].key,
                grp: "no-list",
                dataSet: "membershipActivity",
                previous: true,
                freqType: 'auto',
            },
            type: {
                type: 'benchmark'
            }
        }
    };

}]);
