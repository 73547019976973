/* eslint-disable no-unused-vars */
app.controller('campaignPerformanceTrendingCtrl', ['$scope', 'cohorts', 'utilities', 'campaignPerformance', 'userModels', 'api', '_', '$state', '$filter', 'trend', '$templateCache', '$compile', '$timeout', 'colorMap', '$rootScope', 'widgets', 'filters', 'noty', '$b', 'influenceTypes', function ($scope, cohorts, utilities, campaignPerformance, userModels, api, _, $state, $filter, trend, $templateCache, $compile, $timeout, colorMap, $rootScope, widgets, filters, noty, $b, influenceTypes) {
    var dataCall,
        detailsCall,
        // eslint-disable-next-line no-unused-vars
        isInit;
    //------------------------ private methods ------------------------//
    //
    // eslint-disable-next-line no-unused-vars
    var loadTooltip = function (d, dt, dv, color) {
        $scope.data.tScope.$apply(function () {
            $scope.data.tScope.rows = d.map(function (r) {
                return {
                    cd: r,
                    d: $scope.data[r.id] ? $scope.data[r.id].data[r.index] : null,
                    frequency: $scope.data[r.id] ? $scope.data[r.id].frequency : null,
                    filter: _.find($scope.data.fields, { key: $scope.query.field }).filter
                };
            });

            $scope.data.tScope.health = $scope.data.tScope.rows[0].d ? $scope.data.tScope.rows[0].d.health : null;
        });
        return $scope.data.tooltip[0].outerHTML;
    };

    $scope.useNewInfluenceType = influenceTypes.useNewInfluenceType;

    $scope.typeAttrModelFilter = (scope) => (model) => {
        if (scope.userData.platform === 'full' && model.key !== 'last') {
            return true;
        }

        if (
            scope.userData.platform === 'campaign'
            && ['even', 'sourced'].indexOf(model.key) > -1
        ) {
            return true;
        }
        if (model.key !== 'custom' && scope.userData.platform === 'full') {
            return true;
        }

        if (scope.userData.platform === 'campaign') {
            if (
                (model.key === 'even' && $rootScope.orgConfig.features.model['influence_type_limited'] === 'canAccess')
                || (model.key !== 'custom' && $rootScope.orgConfig.features.model['influence_type'] === 'canAccess')
            ) {
                return true;
            }
        }
        return false;
    };

    function getData() {
        if (dataCall) { dataCall.abort(); }
        $scope.data.now = null;
        $scope.data.then = null;
        if ($state.params.goal) {
            utilities.queryString({ goal: null });
        }

        $state.current.data.loading = true;
        var current = angular.copy($scope.query);
        delete current.previous;

        var apiUrl = $state.current.data.pageType == 'web-activity' ? 'channels_trending_total' : 'campaigns_trending_total';

        if ($scope.query.type === 'trend' || $scope.query.type == 'goal') {
            (dataCall = api.get(apiUrl, current, true, 'now')).then(function (data) {
                prepData(data, 'now');
            });
        } else {
            (dataCall = api.get([{
                api: apiUrl,
                params: current,
                url: true,
                id: 'now',
                add_filters: true
            }, {
                api: apiUrl,
                params: $scope.query,
                url: false,
                id: 'then',
                add_filters: true
            }])).then(function (data) {
                prepData(data.now, 'now');
                prepData(data.then, 'then');
            });
        }
    }

    function prepData(data, type) {
        trend.convertDate(data.data);
        var t = 0;
        data.data.data = _.compact(data.data.data.map(function (v, i) {
            if ((!v.frequency || moment(new Date(v.frequency)).isAfter(new Date($scope.last_updated_date.last_update).getTime(), 'day')) && !v.totals) {
                return null;
            } else {
                v[type] = v.totals + t;
                v.x = (i + 1);
                v[type + 'Date'] = v.frequency;
                v[type + 'OldFrequency'] = v.old_frequency;
                delete v.frequency;
                delete v.old_frequency;
                delete v.totals;
                t = v[type];
                return v;
            }
        }));

        $scope.data[type] = data.data;
        makeChart();
        check();
    }

    function makeChart() {
        //default chart
        $scope.chart = {
            chart: {
                data: {
                    onclick: function (e) {
                        $scope.getDetails($scope.data.now.data[e.index], e.index);
                    }
                },
                title: {},
                size: {
                    height: 450
                },
                legend: {
                    show: false
                },
                point: {
                    r: 6
                },
                grid: {
                    x: {
                        show: false
                    },
                    y: {
                        show: true
                    }
                },
                axis: {
                    x: {
                        show: true,
                        label: {
                            position: 'outer-center'
                        }
                    },
                    y: {
                        show: true,
                        label: {
                            position: 'outer-middle'
                        },
                        tick: {
                            format: function (d) {
                                var f = $scope.query.field;
                                return trend.isDollarType(f) ? $filter('nrFormat')(Number(d), true) : $filter('number')(d);
                            }
                        }
                    }
                },
                color: {
                    pattern: ['#1f77b4', '#C5C5C5']
                },
                tooltip: {
                    contents: loadTooltip
                }
            }
        };

        if ($scope.query.type == 'trend' || $scope.query.type == 'goal') {
            $scope.chart.dimensions = {
                "x": {
                    "axis": "x"
                },
                "now": {
                    "type": "line"
                }
            };
            $scope.chart.chart.tooltip = {
                // eslint-disable-next-line no-unused-vars
                contents: function (d, dt, dv, color) {
                    $scope.data.tScope.rows = d.map(function (r) {
                        return {
                            cd: r,
                            d: $scope.data[r.id].data[r.index],
                            frequency: $scope.data[r.id].frequency,
                            filter: _.find($scope.data.fields, { key: $scope.query.field }).filter
                        };
                    });
                    return $scope.data.tScope.$apply(function () { return $scope.data.tooltip[0].outerHTML; });
                }
            };
        } else {
            $scope.chart.dimensions = {
                "x": {
                    "axis": "x"
                },
                "now": {
                    "type": "area"
                },
                "then": {
                    "type": "area"
                }
            };
        }
    }

    function check() {
        var cohortObj = _.find($scope.data.cohorts, { key: $scope.query.cohort }); //gets you the current cohort object with start and end times
        if ($scope.query.type == 'trend') { //trending graph
            $scope.chart.data = angular.merge($scope.data.now.data);
            $scope.data.currentPeriod = _.last(_.filter($scope.data.now.data, function (o) {
                return _.isDefined(o.now);
            }));
            $scope.data.nowTotal = $scope.data.currentPeriod.now;
        }
        else if ($scope.query.type == 'goal') { //just goal graph, with percentage differences
            $scope.chart.data = angular.merge($scope.data.now.data);
            $scope.data.currentPeriod = _.last(_.filter($scope.data.now.data, function (o) {
                return _.isDefined(o.now);
            }));
            $scope.data.nowTotal = $scope.data.currentPeriod.now;
            var goal = $scope.data.goals[$scope.query.field][$scope.query.cohort]; //gets you the goal
            // eslint-disable-next-line no-unused-vars
            _.each($scope.data.now.data, function (v, i) { //this creates health objects pertaining to each table cell
                var incrementalGoalProgress = utilities.health([goal, v.now], '<=', 5, 49),
                    currentDate = new Date();
                if (currentDate >= cohortObj.start_date && currentDate < cohortObj.end_date) {
                    var incrementalCohortProgress = utilities.cohortProgress(cohortObj.start_date, cohortObj.end_date, new Date($scope.data.currentPeriod.nowDate).getTime());
                    v.health = utilities.health([incrementalCohortProgress, parseFloat(incrementalGoalProgress.diff)], '<=', 0.5, 49);
                }
                else {
                    v.health = incrementalGoalProgress;
                }
                v.progressPercentage = 100 - parseFloat(utilities.diff(goal, v.now).replace(',', '')) + '%';
                //if progresspercentage is greater than or equal to 100, just make the health "good"
                if (parseFloat(v.progressPercentage) >= 100) {
                    v.health = {
                        label: 'good',
                        cls: 'success',
                        textCls: 'text-success',
                        arrowCircle: 'icon-arrow-circle-up',
                        arrow: 'icon-arrow-up',
                        bg: '#3c763d'
                    };
                }
            });
            if ($scope.data.goals && $scope.data.goals[$scope.query.field] && $scope.data.goals[$scope.query.field][$scope.query.cohort]) { //this is for the big graph on the page
                utilities.queryString({ goal: $scope.data.goals[$scope.query.field][$scope.query.cohort] });
                $scope.chart.chart.axis.y.max = $scope.data.currentPeriod.now < $scope.data.goals[$scope.query.field][$scope.query.cohort] ? _.ceil($scope.data.goals[$scope.query.field][$scope.query.cohort] * 1.05) : _.ceil($scope.data.currentPeriod.now);
                $scope.chart.chart.grid.y.lines = [
                    { value: $scope.data.goals[$scope.query.field][$scope.query.cohort], text: 'My Goal: ' + $filter('number')(Number($scope.data.goals[$scope.query.field][$scope.query.cohort])) },
                ];

                //Health color should be how far INTO your goal (% defined below as diff inside progress health object) relative to how far along you are in your cohort (cohortProgress % calculated below)
                //Example: If you're 55% of the way to your goal and your 50% of the way through your cohort then the color should be green because the 50% - 55% = 5% (100 - 5 = 95%)
                var currentDate = new Date(),
                    goalProgress = utilities.health([$scope.data.goals[$scope.query.field][$scope.query.cohort], $scope.data.nowTotal], '<=', 5, 49); //gets you the health of the goal (how far into your goal are you)
                //check if today is in the cohort selected, if so, use the cohort progress in your calculation
                if (currentDate >= cohortObj.start_date && currentDate < cohortObj.end_date) {
                    var cohortProgress = utilities.cohortProgress(cohortObj.start_date, cohortObj.end_date, new Date($scope.data.currentPeriod.nowDate).getTime()); //gets you the health of the cohort (how far into your cohort are you)
                    $scope.data.progressHealth = utilities.health([cohortProgress, parseFloat(goalProgress.diff)], '<=', 0.5, 49);
                } else { //otherwise, just use the regular goal progress
                    $scope.data.progressHealth = goalProgress;
                }
                if (goalProgress.cls == 'success' || parseFloat(goalProgress.diff) > 100) {
                    $scope.data.progressHealth = {
                        label: 'good',
                        cls: 'success',
                        textCls: 'text-success',
                        arrowCircle: 'icon-arrow-circle-up',
                        arrow: 'icon-arrow-up',
                        bg: '#3c763d'
                    };
                }
            }
        }
        else if ($scope.data.now && $scope.data.then) { //benchmarking graph, shows percentage difference
            $scope.chart.data = angular.merge($scope.data.now.data, $scope.data.then.data);
            // eslint-disable-next-line no-unused-vars
            _.each($scope.data.now.data, function (v, i) {
                v.health = utilities.health([v.now, v.then], '>', 0, null, true); //creates health objects pertaining to up or down arrows/green or red differences
            });
            $scope.data.currentPeriod = _.last(_.filter($scope.data.now.data, function (o) {
                return _.isDefined(o.now) && _.isDefined(o.then);
            })); //returns today's current date period object
            if ($scope.data.currentPeriod) {
                if ($scope.data.currentPeriod.now) {
                    $scope.data.nowTotal = $scope.data.currentPeriod.now;
                }
                if ($scope.data.currentPeriod.then) {
                    $scope.data.thenTotal = $scope.data.currentPeriod.then;
                }
            }
            if ($scope.data.nowTotal) {
                $scope.data.health = utilities.health([$scope.data.nowTotal, $scope.data.thenTotal], '>', 0, null, true);
            }
            // $scope.data.diff = parseFloat(utilities.diff($scope.data.nowTotal,$scope.data.thenTotal)); //don't think we need this anymore
        }
        $scope.data.details.selectedField = _.find($scope.data.fields, { key: $scope.query.field }).value;
        $scope.data.details.model = $scope.query.model == 'sourced' ? 'Sourced' : '';
        $scope.chart.chart.axis.y.label.text = $scope.data.details.selectedField;
        $scope.chart.chart.axis.x.label.text = $scope.data.now.frequency;
        if ($scope.query.type == 'goal' || $scope.query.type == 'trend') {
            $scope.chart.chart.title.text = _.startCase($scope.data.details.selectedField) + ' ' + _.startCase($scope.data.details.model) + ' in ' + _.find($scope.data.cohorts, { key: $scope.query.cohort }).value;
        }
        else {
            if ($scope.query.cohort == 'time') {
                var prev = $scope.query.previous,
                    range = moment(parseInt($scope.query.endDate)).diff(moment(parseInt($scope.query.startDate)), 'days'),
                    previousEndDate = prev == 'yoy' ? moment(parseInt($scope.query.endDate)).subtract(1, 'years') : moment(parseInt($scope.query.startDate)).subtract(1, 'days'),
                    previousStartDate = prev == 'yoy' ? moment(parseInt($scope.query.startDate)).subtract(1, 'years') : moment(previousEndDate).subtract(range + 1, 'days');
                $scope.chart.chart.title.text = _.startCase($scope.data.details.selectedField) + ' ' + _.startCase($scope.data.details.model) + ': ' + moment(parseInt($scope.query.startDate)).format('MM/DD/YY') + ' - ' + moment(parseInt($scope.query.endDate)).format('MM/DD/YY') + ' [vs] ' + moment(previousStartDate).format('MM/DD/YY') + ' - ' + moment(previousEndDate).format('MM/DD/YY');
            }
            else {
                $scope.chart.chart.title.text = _.titleCase($scope.data.details.selectedField + ' ' + $scope.data.details.model + ' - ' + trend.currentCohort($scope)) + ' vs ' + _.titleCase(trend.currentCohort($scope, $scope.query.previous));
            }
        }
        $scope.data.detailsTitle = _.titleCase($scope.data.now.frequency.slice(0, -1) + ' Details');
        $state.current.data.loading = false;
    }

    function configureCohorts() {
        var newCohorts = angular.copy(utilities.formatCohorts($rootScope.rawCohorts, ['custom', 'lastFull', 'toDate', 'ago', 'year', 'quarter'])),
            tempYearCohorts = $filter('filter')(newCohorts, function (cohort) {
                return cohort.scope == 'Year';
            }),
            tempQuarterCohorts = $filter('filter')(newCohorts, function (cohort) {
                return cohort.scope == 'Quarter';
            });

        _.remove(newCohorts, _.last(tempQuarterCohorts));
        _.remove(newCohorts, _.last(tempYearCohorts));
        return newCohorts;
    }

    //------------------------ scope methods ------------------------//
    $scope.runAllQuery = function () {
        getData();
    };

    $scope.registerChanges = function (nv, ov) { //run getData for when the goal is updated
        if (!angular.equals(nv.data.goals[$scope.query.field][$scope.query.cohort], ov.data.goals[$scope.query.field][$scope.query.cohort])) {
            getData();
        }
    };

    $scope.getCampaigns = function () {
        getData();
    };

    $scope.cohortChanged = function () {
        $scope.data.freqTypes = campaignPerformance.getFreqTypes($scope.query.cohort);
        $scope.query.freqType = 'auto';
        if ($scope.query.cohort != 'time') {
            getData();
        }
    };

    $scope.datesChanged = function () {
        getData();
    };

    $scope.highlight = function (r, i) {
        $timeout(function () {
            $scope.data.chartInstance.tooltip.show({ x: (i + 1) });
        }, 0, false);
    };

    $scope.unhighlight = function (ri, i) {
        $timeout(function () {
            $scope.data.chartInstance.tooltip.hide({ x: (i + 1) });
        }, 0, false);
    };

    $scope.childCols = function (row) {
        row.childCols = _.map(_.filter(_.keys(row.items[0]), function (k) {
            return !_.includes(k, 'id') && !_.includes(k, '$$hashKey');
        }), function (k) {
            var key = _.lowerCase(k);
            return {
                key: k,
                filter: (_.includes(key, 'date') ? 'date:shortDate' : _.includes(key, 'amount') ? 'bfCurrency' : null)
            };
        });
    };

    $scope.filterType = function (col) {
        var f;
        if (col) {
            f = (_.includes(col.field, 'sum') && trend.isDollarType($scope.query.field)) ? 'bfCurrency' : null;
        } else {
            f = trend.isDollarType($scope.query.field) ? 'bfCurrency' : 'number:0';
        }
        return f;
    };

    $scope.fieldChanged = function () {
        $scope.data.targetIcon = _.find($scope.data.fields, { key: $scope.query.field }).filter;
    };

    $scope.isDollarType = trend.isDollarType;

    $scope.getDetails = function (period, index) {
        var apiUrl;
        if ($scope.query.type == 'trend' || $scope.query.type == 'goal') {
            if ($scope.data.now) {
                $scope.data.activeTab = index;
                $scope.data.details.loading = true;
                var nowParams = angular.copy($scope.query);
                nowParams.freqType = $scope.data.now.frequency;
                nowParams.frequency = period.nowOldFrequency;
                nowParams.startDate = $scope.data.now.times.start;
                nowParams.endDate = $scope.data.now.times.end;
                apiUrl = $state.current.data.pageType == 'web-activity' ? 'channels_trending_group_details' : 'campaigns_trending_group_details';

                (detailsCall = api.getter([{
                    url: apiUrl,
                    params: nowParams,
                    key: 'now',
                    paramsNotToUpdate: "all"
                }])).then(function (data) {
                    var groups = [];
                    var nowTotal = 0;
                    // eslint-disable-next-line no-unused-vars
                    var thenTotal = 0;
                    data.data.list.forEach(function (item) {
                        var p = angular.copy(nowParams);
                        p.group = item.group;
                        nowTotal += item.count;
                        groups.push({ group: item.group, campaigns: null, sum: item.count, params: p });
                    });
                    $scope.data.details.now = groups;

                    $scope.data.details.nowTotal = nowTotal;

                    $scope.data.details.selected = period;
                    $scope.data.details.nowTimeFrame = _.singular($scope.data.now.frequency) + ' ' + ($scope.data.tableData.indexOf(period) + 1);

                    $scope.data.details.nowFrequencyTitle = 'Now (' + $scope.data.details.nowTimeFrame + ')';

                    var csvUrl = $state.current.data.pageType == 'web-activity' ? 'channelsTrendingDetails?' : 'campaignsTrendingDetails?';
                    $scope.data.details.nowCsv = 'export/' + csvUrl + _.queryString(nowParams);

                    $scope.data.details.loading = false;
                    isInit = true;
                });
            }
        } else if ($scope.data.now && $scope.data.then) {
            if (detailsCall) { detailsCall.abort(); }
            $scope.data.activeTab = index;
            $scope.data.details.loading = true;
            // eslint-disable-next-line no-redeclare
            var nowParams = angular.copy($scope.query),
                thenParams = angular.copy($scope.query);

            nowParams.freqType = $scope.data.now.frequency;
            nowParams.frequency = period.nowOldFrequency;
            nowParams.startDate = $scope.data.now.times.start;
            nowParams.endDate = $scope.data.now.times.end;

            thenParams.freqType = $scope.data.then.frequency;
            thenParams.frequency = period.thenOldFrequency;
            thenParams.startDate = $scope.data.then.times.start;
            thenParams.endDate = $scope.data.then.times.end;
            apiUrl = $state.current.data.pageType == 'web-activity' ? 'channels_trending_group_details' : 'campaigns_trending_group_details';

            (detailsCall = api.getter([{
                url: apiUrl,
                params: nowParams,
                key: 'now',
                paramsNotToUpdate: "all"
            }, {
                url: apiUrl,
                params: thenParams,
                key: 'then',
                paramsNotToUpdate: "all"
            }])).then(function (data) {
                var groups = [];
                var nowTotal = 0;
                var thenTotal = 0;
                data.now.data.list.forEach(function (item) {
                    var p = angular.copy(nowParams);
                    p.group = item.group;
                    nowTotal += item.count;
                    groups.push({ group: item.group, campaigns: null, sum: item.count, params: p });
                });
                $scope.data.details.now = groups;

                groups = [];
                data.then.data.list.forEach(function (item) {
                    var p = angular.copy(thenParams);
                    p.group = item.group;
                    thenTotal += item.count;
                    groups.push({ group: item.group, campaigns: null, sum: item.count, params: p });
                });
                $scope.data.details.then = groups;
                $scope.data.details.nowTotal = nowTotal;
                $scope.data.details.thenTotal = thenTotal;
                $scope.data.details.selected = period;
                $scope.data.details.nowTimeFrame = _.singular($scope.data.now.frequency) + ' ' + ($scope.data.tableData.indexOf(period) + 1);
                $scope.data.details.thenTimeFrame = _.singular($scope.data.then.frequency) + ' ' + ($scope.data.tableData.indexOf(period) + 1);
                $scope.data.details.nowFrequencyTitle = 'Now (' + $scope.data.details.nowTimeFrame + ')';
                $scope.data.details.thenFrequencyTitle = 'Then (' + $scope.data.details.thenTimeFrame + ')';
                var csvUrl = $state.current.data.pageType == 'web-activity' ? 'channelsTrendingDetails?' : 'campaignsTrendingDetails?';
                $scope.data.details.nowCsv = 'export/' + csvUrl + _.queryString(nowParams);
                $scope.data.details.thenCsv = 'export/' + csvUrl + _.queryString(thenParams);

                var types = ['now', 'then'];
                if (trend.isDollarType($scope.query.field)) { //we want to know the converted revenue/pipeline for each week so that we can put it in the details table callout
                    _.forEach(types, function (type) {
                        $scope.data.details.selected[type + 'ConversionData'] = {};
                        var k = type + 'Date';
                        angular.forEach($scope.data[type].data, function (value, i) {
                            if (angular.equals(value[k], $scope.data.details.selected[k])) {
                                $scope.data.details.selected[type + 'ConversionData']['converted' + _.startCase($scope.query.field)] = $scope.data[type].data[i - 1] ? value[type] - $scope.data[type].data[i - 1][type] : value[type];
                            }
                        });
                    });
                }
                $scope.data.details.loading = false;
                isInit = true;
            });
        }
    };

    function loadGroupData(row) {
        row.loading = true;
        var apiUrl = $state.current.data.pageType === 'web-activity' ? 'channels_trending_details' : 'campaigns_trending_details';
        var apiOptions = {
            url: apiUrl,
            params: row.params,
            paramsNotToUpdate: 'all'
        };
        api.getter(apiOptions).then(function (data) {
            row.campaigns = _.map(data.data.data, function (c) {
                if (trend.isDollarType($scope.query.field)) {
                    c.sum = 0;
                    for (var i = 0; i < c.items.length; i++) {
                        if (_.isDefined(c.items[i].amount)) {
                            c.sum += c.items[i].amount;
                        }
                    }
                }
                else {
                    c.sum = c.items.length;
                }
                return c;
            });
            row.loading = false;
        });
    }

    (function init() {
        if ($state.params.previous && $state.params.previous == 'true') {
            $state.params.previous = true;
        }
        $scope._ = _;
        $scope.colorMap = colorMap;
        $scope.data = {
            cohorts: configureCohorts(),
            dataSets: campaignPerformance.dataSets,
            fields: campaignPerformance.fields,
            tScope: $scope.$new(),
            models: userModels,
            activeTab: 0,
            goals: {},
            types: ["trend", "goal", "benchmark"],
            details: {
                columnsGroup: [{
                    label: $state.current.data.pageType == 'web-activity' ? campaignPerformance.tabMappings('channel_group') : 'Campaign Type',
                    field: 'group'
                }, {
                    label: 'Total',
                    field: 'sum',
                    filter: 'number:0'
                }],
                columnsCampaign: [{
                    label: $state.current.data.pageType == 'web-activity' ? 'Channel Asset' : 'Campaign Name',
                    field: 'name',
                    link: function (row) {
                        var pageUrl = $state.current.data.pageType == 'web-activity' ? 'app.analyze.webTracking.channelAssetSpecific.attribution.leads' : 'app.analyze.campaigns.campaignSpecific.attribution.leads';
                        return $scope.shref(pageUrl, {
                            name: row.name,
                            campaignId: row.id,
                            cohort: $scope.query.cohort,
                            dataSet: $scope.query.dataSet,
                            modelType: $scope.query.model
                        });
                    }
                }, {
                    label: 'Total',
                    field: 'sum',
                    filter: 'number:0'
                }]
            }
        };

        //so we can use the angular templating engine to power the c3 chart tooltip
        $scope.data.tooltip = $compile($templateCache.get('trendingTooltip.html'))($scope.data.tScope);
        // if user is coming from dashboard (dashboard is in the params)
        if ($state.params.dashboard) {
            $state.current.data.erasePrevious = true;
            widgets.getDashboards().then(function (dashes) {
                if (dashes.length) {
                    widgets.getTile($state.params.dashboard.split('_')[0], $state.params.dashboard.split('_')[1]).then(function (data) {
                        //copies of data from dashboard and tile
                        $scope.data.dashboard = angular.copy(data.dashboard);
                        $scope.data.tile = angular.copy(data.tile);
                        $scope.query = angular.copy(data.tile.query);
                        $scope.query.type = angular.copy(data.tile.type);
                        if (!_.isDefined(_.find($scope.data.models, { key: $scope.query.model }))) {
                            $scope.query.model = "even";
                        }
                        if (data.tile.type == 'goal') {
                            $scope.data.goals = angular.copy(data.tile.data.goals);
                            $scope.data.targetIcon = _.find($scope.data.fields, { key: $scope.query.field }).filter;
                        }
                        $scope.data.freqTypes = campaignPerformance.getFreqTypes($scope.query.cohort);
                        getData();
                    }, function () {
                        $b.confirm({
                            text: 'We can\'t find the tile you are to trying edit so we need to reload the page.',
                            title: 'Oh No!',
                            callback: function () {
                                $scope.$broadcast('reloadPage');
                            },
                            x: false,
                            buttons: [{
                                'text': 'ok',
                                'ng-click': '$modal.callback($modal)',
                                'class': 'btn btn-sm btn-primary pull-right'
                            }]
                        });
                    });
                } else {
                    $b.confirm({
                        text: 'We can\'t find the tile you are to trying edit so we need to reload the page.',
                        title: 'Oh No!',
                        callback: function () {
                            $scope.$broadcast('reloadPage');
                        },
                        x: false,
                        buttons: [{
                            'text': 'ok',
                            'ng-click': '$modal.callback($modal)',
                            'class': 'btn btn-sm btn-primary pull-right'
                        }]
                    });
                    $scope.$broadcast('reloadPage');
                }
            });
        } else {
            $scope.query = _.defaults($state.params, {
                field: 'leads',
                model: 'even',
                cohort: 'quarter2Date',
                grp: "no-list",
                dataSet: "membershipActivity",
                freqType: 'auto',
                previous: true,
                type: 'benchmark'
            });
            if ($scope.query.goal) {
                $scope.data.goals[$scope.query.field] = {};
                $scope.data.goals[$scope.query.field][$scope.query.cohort] = _.toNumber($scope.query.goal);
            }
            if (!_.isDefined(_.find($scope.data.models, { key: $scope.query.model }))) {
                $scope.query.model = "even";
            }
            $scope.data.freqTypes = campaignPerformance.getFreqTypes($scope.query.cohort);
            $scope.data.targetIcon = _.find($scope.data.fields, { key: $scope.query.field }).filter;
            getData();
        }

        if ($state.current.data.pageType == 'web-activity') {
            $scope.data.dataSets = _.filter($scope.data.dataSets, function (o) { return o.key !== 'campaignCreatedDate'; });
        }

        $scope.$on('updateTileFromPage', function () {
            widgets.getTile($state.params.dashboard.split('_')[0], $state.params.dashboard.split('_')[1]).then(function (data) {
                data.tile.query = $scope.query;
                data.tile.type = $scope.query.type;
                var fltrs = filters.currentFilters($scope.filters, $state.get($state.current.name));
                fltrs = _.omitBy(fltrs, function (o) {
                    return !o.length;
                });
                if (!_.isEmpty(fltrs)) {
                    data.tile.query = angular.extend(data.tile.query, fltrs);
                }
                //if its a goal tile, merge in the goals
                if ($scope.query.type == 'goal') {
                    if (!$scope.data.goals || !$scope.data.goals[$scope.query.field] || !$scope.data.goals[$scope.query.field][$scope.query.cohort]) {
                        $scope.data.goals[$scope.query.field] = {};
                        $scope.data.goals[$scope.query.field][$scope.query.cohort] = 0;
                    }
                    //last query and last goal
                    data.tile.data.goals = angular.merge(data.tile.data.goals, $scope.data.goals);
                }
                widgets.setDashboards(data.dashboard);
                noty.growl('Your tile has been updated.', 'success');
                $scope.$broadcast('resetPage');
            });
        });

        $scope.$on('resetPage', function () {
            $state.params = _.omit($state.params, ['dashboard']);
            utilities.queryString($state.params).then(function () {
                $state.go($state.current.name, { dashboard: null }, { reload: true });
            });
        });

        $scope.$on('rowExpanding', function (e, s) {
            if (!s.row.campaigns && s.row.params) {
                loadGroupData(s.row);
            }
        });

        $scope.$on('$windowResizeEnd', function (event, dimensions) {
            if($scope.data && $scope.data.chartInstance) {
                $scope.data.chartInstance.resize();
            }
        });

        $scope.$on('filtersChanged', getData);
        $scope.$on('$destroy', function () {
            if (dataCall) { dataCall.abort(); }
            $scope.data.tScope.$destroy();
            $state.current.data.erasePrevious = false;
        });
    })();
}]);
