import * as angular from 'angular';
import * as $ from 'jquery';

import { APP_BRIGHTFUNNEL } from '../../constants';
import * as CONSTANTS from '../trending-kpis/constants';
import { IDateCohort } from '../models/scorecard/date-cohort/model';

const app = angular.module(APP_BRIGHTFUNNEL);

app.directive('dashboardPopover', [
    '_',
    '$templateCache',
    '$compile',
    '$interpolate',
    function (
        _: any,
        $templateCache: any,
        $compile: any,
        $interpolate: any,
    ) {
        return {
            restrict: 'A',
            scope: false, // use parent scope
            templateUrl: '../../main/dashboard/dashboard-popover.html',
            link: function (scope) {
            /* variables */
                const template = $templateCache.get(scope.widget.settings.template);
                let temp = $(`<div>${ template }</div>`),
                    elems = [];
                scope.data.popoverVals = [];
                scope.queryFilters = [];
                _.forEach(scope.widget.query, (val, key) => {
                    if (key.indexOf('filter') !== -1) {
                        scope.queryFilters = scope.queryFilters.concat(val);
                    }
                });
                searchTemplate();
                temp = temp.html();

                const ngModels = $(temp).find('[ng-model]');

                ngModels.each(function () {
                    const o = {};
                    if ($(this).hasAttr('uib-btn-radio')) {
                        o['type'] = 'buttons';
                        o['elem'] = $(this).parents('div');
                    } else {
                        o['type'] = 'select';
                        o['elem'] = $(this).parents('label');
                    }
                    elems.push(o);
                });

                elems = _.uniqBy(elems, (o) => {
                    if (o.type === 'buttons') {
                        return $(o.elem).find('label').attr('ng-model');
                    } else {
                        return $(o.elem).find('ui-select').attr('ng-model');
                    }
                });

                if (elems && elems.length) {
                    _.forEach(elems, (val) => {
                        let name = '', label = '';
                        if (val.type === 'buttons') {
                            const btnAttr = [];
                            // get query value
                            const ngModel = $(val.elem).find('label');
                            const query = formatWidgetQueryKey($(ngModel).attr('ng-model'));
                            const value = _.get(scope, query);

                            _.forEach(ngModel, (_val) => {
                                btnAttr.push({ elem: _val, value: scope.$eval($(_val).attr('uib-btn-radio')) });
                            });

                            _.forEach(btnAttr, (_val) => {
                                if (_val.value === value) {
                                    name = $(_val.elem).text();
                                    if (_.contains(name, '{{') && _.contains(name, '}}')) {
                                        name = $interpolate(name)(scope);
                                    }
                                }
                            });

                            // get label
                            const labelTag = $(val.elem).closest('label');
                            if (!labelTag || !labelTag.length) {
                                label = name.includes('By') ? 'Time Units' : 'Data';
                            } else {
                                const l = labelTag.clone();
                                l.children().remove();
                                label = l.text().trim();
                            }

                        } else {
                            let query = formatWidgetQueryKey($(val.elem).find('ui-select').attr('ng-model'));

                            query = _.get(scope, query);

                            // gets {{$select.selected}} in ui-select-match
                            let format = $(val.elem).find('ui-select-match').text().split('{{$select.selected')[1];

                            // deal with other period split by dot -> splice off first dot
                            if (_.includes(format, '.')) {
                                const index = _.indexOf(format, '.');
                                format = format.slice(index + 1, format.length);
                            }

                            // get key for formatted value
                            format = _.replace(format, '}}', '');

                            // gets ng-repeat from template
                            let repeat = $(val.elem).children().find('ui-select-choices');

                            if (($(repeat).attr('repeat'))) {
                                repeat = ($(repeat).attr('repeat')).split('|');
                                repeat = repeat[0].trim();

                                // spilts repeat to get object key
                                const repeatParts = repeat.split(' in ');
                                repeat = repeatParts[1];
                                let k = repeatParts[0];
                                let keyParts;
                                if (_.includes(k, ' as ')) {
                                    keyParts = k.split(' as ');
                                    // handle case for multiple dots
                                    // h.b.c as h in i
                                    // replace h. in h.b.c with ''
                                    k = keyParts[0].replace(keyParts[1] + '.', '');
                                }

                                // get actual object in scope.data.
                                repeat = _.get(scope, repeat);

                                // find formatted name
                                const searchObj = {};
                                searchObj[k] = query;
                                const searchValue = _.find(repeat, searchObj);

                                if (!searchValue) {
                                    name = query;
                                } else {
                                    name = _.get(searchValue, format);
                                }
                                // get label
                                const l = $(val.elem).clone();
                                l.children().remove();
                                label = l.text().trim();
                                if (_.contains(label, '{{') && _.contains(label, '}}')) {
                                    label = $interpolate(label)(scope);
                                    if (_.contains(label, '...')) {
                                        label = label.replace('...', '');
                                    }
                                }
                            }
                        }

                        const obj = {
                            name: _.upperFirst(name),
                            label: _.upperFirst(label)
                        };

                        if (!!obj.name && !!obj.label) {
                            if (scope.userData.platform === 'full' || label !== 'Attribution Model') {
                                scope.data.popoverVals.push(obj);
                            }
                        }
                    });
                }

                if (scope.data.popoverVals && !scope.data.popoverVals.length) {
                    formatTrendingKpiQuery();
                }

                scope.data.popoverVals = _.filter(scope.data.popoverVals, (o) => !!o.name  && !!o.label);

                /* methods */
                function searchTemplate() {
                    temp.find('include').each(function () {
                        getTemplate($(this));
                    });
                }

                function getTemplate(val) {
                    let src = val.attr('src');
                    src = src.substring(1, src.length - 1);

                    const tCache = $templateCache.get(src);
                    temp.find(val).replaceWith($(tCache));
                    searchTemplate();
                }

                function formatWidgetQueryKey(query) {
                    return query && query.slice().split('.').length === 2 ? `widget.${query}` : query;
                }

                function isCustomRange(dateCohort: IDateCohort, customStart: number, customEnd: number) {
                    return dateCohort.startDate !== customStart && dateCohort.endDate !== customEnd;
                }

                function formatTrendingKpiQuery() {
                    const widgetQuery = scope.widget.query;
                    const isCustom = isCustomRange(widgetQuery.cohort, +(new Date(widgetQuery.customEndDate)), +(new Date(widgetQuery.customEndDate)));
                    const shouldShowChartStyle = widgetQuery.visualization === CONSTANTS.VISUALIZATION_TRENDING;
                    const emptyNameLabel = {
                        name: '',
                        label: '',
                    };

                    const formattedQuery = Object.keys(widgetQuery).map((queryKey) => {
                        switch (queryKey) {
                        case CONSTANTS.QUERY_KEY_ACCOUNT_LISTS:
                            return emptyNameLabel;

                        case CONSTANTS.QUERY_KEY_COHORT:
                            return {
                                name: _.upperFirst(
                                    _.toLower(
                                        isCustom ?
                                            'custom'
                                            : queryKey === CONSTANTS.QUERY_KEY_COHORT
                                                ? widgetQuery[queryKey].name
                                                : widgetQuery[queryKey]
                                    )
                                ),
                                label: CONSTANTS.QUERY_KEYS_HUMAN_READABLE_MAP[queryKey],
                            };

                        case CONSTANTS.QUERY_KEY_CUSTOM_START_DATE:
                            return emptyNameLabel;

                        case CONSTANTS.QUERY_KEY_CUSTOM_END_DATE:
                            return emptyNameLabel;

                        case CONSTANTS.QUERY_KEY_GROUP_BY:
                            return {
                                name: _.upperFirst(_.toLower(CONSTANTS.GROUPY_BY_HUMAN_READBLE_MAP[widgetQuery[queryKey]])),
                                label: CONSTANTS.QUERY_KEYS_HUMAN_READABLE_MAP[queryKey],
                            };

                        case CONSTANTS.QUERY_KEY_INTERVAL:
                            return {
                                name: _.upperFirst(_.toLower(CONSTANTS.INTERVAL_HUMAN_READABLE_MAP[widgetQuery[queryKey]])),
                                label: CONSTANTS.QUERY_KEYS_HUMAN_READABLE_MAP[queryKey],
                            };

                        case CONSTANTS.QUERY_KEY_KPI:
                            return {
                                name: _.upperFirst(_.toLower(CONSTANTS.KPI_HUMAN_READABLE_MAP[widgetQuery[queryKey]])),
                                label: CONSTANTS.QUERY_KEYS_HUMAN_READABLE_MAP[queryKey],
                            };

                        case CONSTANTS.QUERY_KEY_PROGRESSION:
                            return {
                                name: _.upperFirst(_.toLower(CONSTANTS.PROGERSSION_HUMAN_READABLE_MAP[widgetQuery[queryKey]])),
                                label: CONSTANTS.QUERY_KEYS_HUMAN_READABLE_MAP[queryKey],
                            };

                        case CONSTANTS.QUERY_KEY_TRENDING_CHART_STYLE:
                            return shouldShowChartStyle ?
                                {
                                    name: _.upperFirst(_.toLower(CONSTANTS.CHART_STYLE_HUMAN_READABLE_MAP[widgetQuery[queryKey]])),
                                    label: CONSTANTS.QUERY_KEYS_HUMAN_READABLE_MAP[queryKey],
                                }
                                : emptyNameLabel;

                        default:
                            return {
                                name: _.upperFirst(_.toLower(widgetQuery[queryKey])),
                                label: CONSTANTS.QUERY_KEYS_HUMAN_READABLE_MAP[queryKey],
                            };
                        }
                    });

                    scope.data.popoverVals = scope.data.popoverVals.concat(formattedQuery);
                }

                scope.filtersForPopover = function (filter) {
                    if (filter) {
                        const a = filter.split(':');
                        if (a[0]) {
                            return a[0];
                        }
                    }
                };
            }
        };
    }]);
