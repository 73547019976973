app.service("trend", ["utilities", "_", "$filter", "$rootScope", "$q", "$state", "$timeout", function (utilities, _, $filter, $rootScope, $q, $state, $timeout) {
    this.convertDate = function (data) {
        //We are now on a Monday-Monday cadence...so the first set of dates will be the times.start to the nearest Monday, and then weekly after that
        var firstMonday = moment(data.times.start).add(1, "weeks").day("Monday"), //this will be your new start
            startDate = moment.utc(new Date(data.times.start)),
            endDate = moment.utc(new Date(data.times.end)),
            today = moment.utc(new Date()),
            dates,
            isInRange = today.isBetween(startDate, endDate);

        //Check if today is inside the date range of the cohort selected via start and end times
        if (isInRange) {
            //If it is, then have the end date be today
            dates = utilities.getDates(moment.utc(new Date(firstMonday))._d, today._d, data.frequency);
            if (!dates[dates.length]) {
                //make the last frequency date today
                dates[dates.length] = moment.utc(today)._d;
            }
        } else {
            //otherwise, have the end date be the end date of the cohort selected
            dates = utilities.getDates(moment.utc(new Date(firstMonday))._d, moment.utc(new Date(data.times.end))._d, data.frequency);
        }

        angular.forEach(data.data, function (value, i) {
            value.old_frequency = value.frequency;
            data.data[i].frequency = dates[i];
        });
    };

    this.currentCohort = function ($scope, previous) {
        var query = $scope.widget ? $scope.widget.query : $scope.query,
            selected = query.cohort,
            fullCohorts = utilities.formatCohorts($rootScope.rawCohorts, ["custom", "lastFull", "toDate", "ago", "year", "quarter"]),
            cohortObj = _.find(fullCohorts, { key: selected }),
            scoped = $filter("filter")(fullCohorts, { scope: cohortObj.scope }),
            y = previous === "yoy" ? 1 : 0,
            yy = previous === "yoy" ? 0 : 1;
        if (cohortObj.scope == "Year" || cohortObj.scope == "Quarter") {
            var v = scoped[scoped.indexOf(cohortObj) + (previous === "yoy" && cohortObj.scope == "Quarter" ? 0 : 1)].value,
                year = v.split(" ")[1];
            return previous ? (cohortObj.scope == "Quarter" ? _.replace(v, year, parseInt(year) - y) : v) : cohortObj.value;
        } else if (cohortObj.scope == "To Date") {
            var unit = selected.split("2")[0];
            if (unit == "month") {
                //July
                //August
                return previous
                    ? moment(new Date())
                        .subtract(y, "year")
                        .subtract(yy, "month")
                        .format("MMMM YYYY")
                    : moment(new Date()).format("MMMM YYYY");
            } else if (unit == "week") {
                var m = previous
                    ? moment(new Date())
                        .subtract(y, "year")
                        .subtract(yy, "week")
                    : moment(new Date());
                //July, Week 2
                //July, Week 1
                return m.format("MMM YY") + ", Week " + utilities.weekOfMonth(m);
            } else if (unit == "quarter") {
                //Q2 2017
                //Q1 2017
                // eslint-disable-next-line no-redeclare
                var v = $filter("filter")(fullCohorts, { scope: "quarter" })[previous && previous != "yoy" ? 1 : 0].value,
                    // eslint-disable-next-line no-redeclare
                    year = v.split(" ")[1];

                return _.replace(v, year, parseInt(year) - y);
            }
        } else if (cohortObj.scope == "Last Full") {
            // eslint-disable-next-line no-redeclare
            var unit = _.lowerCase(selected.split("Full")[1]);
            if (unit == "month") {
                //July 2016
                //August 2015
                return previous
                    ? moment(new Date())
                        .subtract(y, "year")
                        .startOf("month")
                        .subtract(yy + 1, "months")
                        .format("MMMM YYYY")
                    : moment(new Date())
                        .startOf("month")
                        .subtract(1, "months")
                        .format("MMMM YYYY");
            } else if (unit == "week") {
                // eslint-disable-next-line no-redeclare
                var m = previous
                    ? moment(new Date())
                        .subtract(y, "year")
                        .startOf("week")
                        .subtract(yy + 1, "week")
                    : moment(new Date())
                        .startOf("week")
                        .subtract(1, "week");
                //July, Week 2
                //July, Week 1
                return m.format("MMM YY") + ", Week " + utilities.weekOfMonth(m);
            } else if (unit == "quarter") {
                //July 2016
                //August 2015
                var index = 2;
                if (previous == "yoy") {
                    index = 5;
                }
                var quarters = $filter("filter")(fullCohorts, { scope: "quarter" });
                return previous ? quarters[index].value : quarters[1].value;
            }
        } else if (cohortObj.scope == "Ago From Today") {
            var parts = selected.split("s"),
                // eslint-disable-next-line no-redeclare
                unit = selected == "year" ? "year" : parts[0],
                step = selected == "year" ? 1 : parts[1];
            // eslint-disable-next-line no-redeclare
            var m = moment(new Date()).subtract(parseInt(step), unit);
            //i.e. Sept 1 to today
            //i.e. Aug 25 to Sept 1
            return previous
                ? m
                    .clone()
                    .subtract(y, "year")
                    .subtract(parseInt(step), unit)
                    .format("MM/D/YY") +
                " - " +
                m.subtract(y, "year").format("MM/D/YY")
                : m.format("MM/D/YY") + " - Today";
        }
    };

    /**
     *
     * @param data
     * @param cohort
     * @param dateField - string; activity_date, created_date, close_date
     * @param counts - boolean; whether to show counts (true) or sum (false)
     * @param field - string; if showing sum, which field to sum
     * @returns {Array}
     */
    this.parseTrends = function (data, cohort, dateField, counts, field) {
        var startDate, endDate;

        if (_.isArray(cohort)) {
            startDate = cohort[0];
            endDate = cohort[1];
        } else {
            startDate = cohort.start_date;
            endDate = cohort.end_date;
        }

        // get bounds of selected cohort -- this is going to be your window
        var step = utilities.getFrequencyStepsFromDates(startDate, endDate),
            dates = utilities.getDates(startDate, endDate, step, "unix"),
            arr = [];

        angular.forEach(dates, function (interval) {
            var stepData = $filter("filter")(data, function (touch) {
                //if the touch occurred before this moment, return true to count it
                return moment(touch[dateField]).isBefore(moment(parseFloat(interval)));
            });
            if (counts) {
                arr.push({
                    moment: parseFloat(interval),
                    total: stepData.length
                });
            } else {
                // eslint-disable-next-line no-unused-vars
                var sum = utilities.sumObjects(stepData, function (obj, value, key) {
                    return value;
                });
                arr.push({
                    moment: parseFloat(interval),
                    total: sum[field] ? sum[field] : 0 //this sums the objects on whatever field you passed in
                });
            }
        });
        return arr;
    };

    this.getTrendData = function ($scope, dateField) {
        var defer = $q.defer();

        $timeout(function () {
            //------------ cohort isn't sent to get so we need to manually change it on the url -------------//
            utilities.queryString(angular.copy($scope.query), true);
            var cohortObj = _.find($scope.data.cohorts, { key: $scope.query.cohort }),
                cohortDistance = moment(cohortObj.end_date).diff(moment(cohortObj.start_date)),
                campaignDistance = moment(cohortObj.end_date).diff(moment(moment.utc($scope.data.data.campaign.created_date)._d.getTime())),
                sd,
                ed;

            if (cohortDistance >= campaignDistance) {
                sd = moment.utc($scope.data.data.campaign.created_date)._d.getTime();
                ed = cohortObj.end_date;
            }
            if (cohortDistance < campaignDistance) {
                sd = cohortObj.start_date;
                ed = cohortObj.end_date;
            }
            if (cohortObj.key == "time") {
                sd = $scope.query.startDate;
                ed = $scope.query.endDate;
            }

            $scope.data.trend = $scope.calc.compare($scope.data.data, [sd, ed], dateField);

            defer.resolve();
        }, 1000);

        return defer.promise;
    };

    /**
     *
     * @param $scope
     *          holds data.cohorts, data.campaign, widget.query
     * @param key
     *          normal: $scope.query.cohort
     *          dashboard: $scope.widget.query.cohort
     * @returns {*[]}
     */
    this.agoValue = function ($scope, key) {
        var cohortObj = _.find($scope.data.cohorts, { key: key }),
            sd,
            ed;

        if (cohortObj.key == "time") {
            sd = $scope.widget.query.startDate;
            ed = $scope.widget.query.endDate;
        } else {
            var cohortDistance = moment(cohortObj.end_date).diff(moment(cohortObj.start_date)),
                campaignDistance = moment(cohortObj.end_date).diff(moment($scope.data.campaign.campaign.created_date));

            if (cohortDistance >= campaignDistance) {
                sd = $scope.data.campaign.campaign.created_date;
                ed = cohortObj.end_date;
            } else if (cohortDistance < campaignDistance) {
                sd = cohortObj.start_date;
                ed = cohortObj.end_date;
            }
        }

        return [sd, ed];
    };

    this.dateFieldFromDataset = function (dataSet) {
        switch (dataSet) {
        case "membershipActivity":
            return "activity_date";
        case "opptyCloseDate":
            return "close_date";
        case "opptyCreatedDate":
        default:
            return "created_date";
        }
    };

    this.isDollarType = function(field) {
        return field.indexOf("revenue") > -1 || field.indexOf("pipeline") > -1;
    };
}]);
