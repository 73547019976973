app.controller('revByChannelAcrossQtrCtrl', ['$scope', '$rootScope', '$http', '$window', '$location', '$state', '$filter', '$timeout', 'api', 'utilities', 'colorMap', '_', 'cohorts', 'userModels', 'terminusHubService', function ($scope, $rootScope, $http, $window, $location, $state, $filter, $timeout, api, utilities, colorMap, _, cohorts, userModels, terminusHubService) {
    var campaignInfluenceDataCall;

    var getCampaignInfluencedRevenueData = function () {
        if (campaignInfluenceDataCall) { campaignInfluenceDataCall.abort(); }

        $scope.$broadcast('closeAllRows');
        $state.current.data.loading = true;
        $scope.campaign_influence = [];
        (campaignInfluenceDataCall = api.get('attribution_trending', angular.copy($scope.query))).then(function (data) {
            $scope.campaign_influence = data.data;
            $scope.campaign_influence.typesAll = $scope.campaign_influence.types;
            // eslint-disable-next-line no-unused-vars
            angular.forEach($scope.campaign_influence.byCampaign, function (type, i) {
                type.visible = true;
            });
            $scope.private.drawRcaqChart();
            $state.current.data.loading = false;
        });
    };
    var allZero = function (t) {
        var willShow = false;
        angular.forEach($scope.campaign_influence.cohorts, function (cohort) {
            if (getMetric(t, cohort) != 0) {
                willShow = true;
            }
        });
        return willShow;
    };

    var getMetric = function (group, cohort) {
        if (!$scope.campaign_influence.sums[group]) {
            return 0;
        }
        return $scope.campaign_influence.sums[group][cohort][$scope.query.model][$scope.query.metric];
    };

    $scope.runAllQuery = function () {
        getCampaignInfluencedRevenueData();
    };

    $scope.registerChanges = function () {
        $scope.private.drawRcaqChart();
    };

    $scope.getGroupColor = function (name) {
        return colorMap(name);
    };

    $scope.toggleGroup = function (row) {
        $scope.$broadcast('toggle-campaign', row);
    };

    $scope.selectAll = function () {
        $scope.$broadcast('reset-chart');

        angular.forEach($scope.campaign_influence.byCampaign, function (row) {
            row.visible = $scope.data.selectedAll;
            if (!$scope.data.selectedAll) {
                $scope.$broadcast('toggle-campaign', row);
            }
        });
    };

    $scope.showCampaigns = function (type) {
        var obj = $scope.data.campaignDetails[type],
            temp = [];
        if (!$scope.data.campaignDetailsTable[type]) {
            $scope.data.campaignDetailsTable[type] = { tableData: [] };
        }
        if (!obj) {
            obj = { type: type, campaigns: [] };
            obj.campaigns = Object.values($scope.campaign_influence.byCampaign[type]);
            _.forEach(obj.campaigns, function (value) {
                if (_.isActualObject(value)) {
                    temp.push(value);
                }
            });
            obj.campaigns = temp;
            $scope.data.campaignDetails[type] = obj;
        }
    };

    $scope.campaignLink = function (row) {
        var newRow = (row[_.keys(row)[0]]);
        var params = {
            name: row.name.split(" -")[0],
            cohort: _.find($scope.cohorts, { value: newRow.cohort }).key,
            campaignId: newRow.campaign_id,
            dataSet: $scope.query.type == 'pipeline' ? "opptyCreatedDate" : "opptyCloseDate",
            modelType: $scope.query.model,
            tab: $scope.query.type == 'pipeline' ? "opptys" : "deals"
        };
        return terminusHubService.getTerminusHubUrlByReportName('app.analyze.campaigns.campaignSpecific.attribution') + '?' +
            Object.keys(params).reduce((prev, key) => params[key] ? prev.concat(key + '=' + params[key]) : prev, []).join('&');
    };

    $scope.exportData = function () {
        $state.current.data.loading = true;

        var data = {
            headers: [''],
            rows: []
        };

        angular.forEach($scope.campaign_influence.types, function (type, i) {
            var row = [type];

            // eslint-disable-next-line no-unused-vars
            angular.forEach($scope.campaign_influence.cohorts, function (cohort, ii) {
                if (i === 0) {
                    data.headers.push(cohort);
                }
                var val = ($scope.getRevenue(type, cohort) || '$0').replace('.00', '');
                row.push(val);
            });
            data.rows.push(row);
        });

        $state.current.data.loading = false;
        return data;
    };

    $scope.private = {
        yAxisTitles: {
            revenue: {
                sourced: { count: "Deals Touches", value: "Revenue Sourced" },
                last: { count: "Deal Touches", value: "Revenue Created" },
                even: { count: "Deal Touches", value: "Revenue Attributed" },
                custom: { count: "Deal Touches", value: "Revenue Attributed" }
            },
            pipeline: {
                sourced: { count: "Opportunity Touches", value: "Pipeline Sourced" },
                last: { count: "Opportunity Touches", value: "Pipeline Created" },
                even: { count: "Opportunity Touches", value: "Pipeline Attributed" },
                custom: { count: "Opportunity Touches", value: "Pipeline Attributed" }
            }
        },

        xAxisEnd: {
            revenue: { count: "Deals", value: "Sourced" },
            pipeline: { count: "Opportunities", value: "Pipeline" }
        },

        drawRcaqChart: function () {
            $scope.campaign_influence.types = $filter('orderBy')($filter('filter')($scope.campaign_influence.typesAll, allZero), 'toString()');
            var options = {
                yAxisTitle: $scope.private.yAxisTitles[$scope.query.type][$scope.query.model][$scope.query.metric],
                title: $scope.private.yAxisTitles[$scope.query.type][$scope.query.model][$scope.query.metric] + " Quarter-Over-Quarter for a " +
                    $scope.orgConfig.attributionModels[$scope.query.model].custom_name + " Model",
                isStacked: true,
                vAxis: { format: "#,###" }
            };

            var chart_data = null;
            var stacks_labels = [];
            var types = null;
            var cohorts = $scope.campaign_influence.cohorts;
            var campaign_influence = $scope.campaign_influence;
            for (var i = 0; i < cohorts.length; i++) {
                var cohort = cohorts[i];
                if (cohort.substring(0, 2) != "FY") {
                    if (!chart_data) {
                        types = campaign_influence.types.slice();
                        stacks_labels = [];
                        types.sort(function (c1, c2) {
                            return getMetric(c1, cohort) - getMetric(c2, cohort);
                        });
                        chart_data = [];
                        types.forEach(function (t) {
                            stacks_labels.push(t);
                        });
                    }
                    var d = { title: $scope.campaign_influence.cohorts[i], values: [] };
                    for (var j = 0; j < types.length; j++) {
                        d.values.push({ amount: getMetric(types[j], cohort) || 0 });
                    }
                    chart_data.push(d);
                }
            }

            var data = {
                data: chart_data,
                stacks: stacks_labels,
                page_title: options.title,
                axis_title: options.yAxisTitle,
                colorMap: colorMap
            };

            var t = 0;
            data.stacks.forEach(function (i) {
                $scope.campaign_influence.byCampaign[i].id = t;
                t++;
            });

            data.currency = $scope.query.metric == "value";

            $scope.$broadcast('draw-rcbq-chart', data);
        }
    };

    $scope.sort = {
        column: _.curry(function (cohort, group) {
            return parseInt($scope.getRevenue(group, cohort).replace(/[^0-9]/g, ''));
        }),
        groups: function (group) {
            return group;
        },
        expanded: _.curry(function (cohort, value) {
            return parseInt($scope.getValue(value, cohort).replace(/[^0-9]/g, ''));
        })
    };

    $scope.getRevenue = function (type, cohort) {
        var f = $scope.query.metric == "count" ? "number" : "currency",
            d = getMetric(type, cohort) || 0.0;
        if (f == 'currency') {
            return $filter('bfCurrency')(d);
        } else if (f == 'number') {
            return $filter(f)(d, 0);
        }
    };

    $scope.getValue = function (campaign, cohort) {
        var f = $scope.query.metric == "count" ? "number" : "currency";
        var value = campaign[cohort] != null ? campaign[cohort][$scope.query.model][$scope.query.metric] : 0;
        if (f == 'currency') {
            return $filter('bfCurrency')(value);
        } else if (f == 'number') {
            return $filter(f)(value, 0);
        }
    };

    $scope.getTotal = function (c) {
        var total = 0;
        angular.forEach($scope.campaign_influence['sums'], function (t) {
            total += t[c][$scope.query.model][$scope.query.metric];
        });
        return $scope.query.metric == "count" ? $filter("number")(total) : $filter("bfCurrency")(total);
    };

    $scope.$on('filtersChanged', function () {
        getCampaignInfluencedRevenueData();
    });

    (function init() {
        $scope.cohorts = utilities.formatCohorts(cohorts, ['year', 'quarter', 'ago', 'toDate', 'lastFull']);
        $scope.options = { models: userModels };
        $scope.query = _.defaults($state.params, { type: 'revenue', metric: "value", model: "even" });
        $scope.init = false;
        $scope.statuses = {
            select_options: null,
            campaign_influence_data: null
        };
        $scope.data = {
            campaignDetails: {},
            campaignDetailsTable: {},
            tableData: [],
            selectedAll: true,
            expandedHeaders: [{
                displayName: 'Campaign Name',
                field: 'name',
                link: function (row) {
                    return $scope.shref('app.analyze.campaigns.campaignSpecific.attribution.leads', {
                        name: row.name,
                        campaignId: row.id,
                        cohort: $scope.query.cohort,
                        dataSet: $scope.revenueType == 'pipeline' ? "opptyCreatedDate" : "opptyCloseDate",
                        modelType: "even",
                        tab: $scope.revenueType == 'pipeline' ? "opptys" : "deals"
                    });
                }
            }]
        };

        $scope.chart = {
            config: {
                data: {
                    format: function (d) {
                        return $filter('nrFormat')(d, true);
                    }
                }
            }
        };

        //------------ override our defaults with the query params passed in -------------//
        angular.extend($scope.query, _.compactObject(angular.copy($state.params)));

        //fix for overriding old bad values stored in URL string on initial load
        if ($scope.query.metric != "value" && $scope.query.metric != "count") {
            $scope.query.metric = "value";
        }

        $scope.$on('$destroy', function () {
            if (campaignInfluenceDataCall) { campaignInfluenceDataCall.abort(); }
        });

        getCampaignInfluencedRevenueData();
    })();
}]);
