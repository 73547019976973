(function () {
    'use strict';

    app.factory('internalConfigSrvc', internalConfigSrvc);

    internalConfigSrvc.$inject = ['$http'];

    /**
     * Service to handle internal configs.
     */
    function internalConfigSrvc($http) {
        /**
         * This service
         * @type {{}}
         */
        var service = {};

        service.getInternalConfig = function() {
            return $http.get('admin/internal_config');
        };

        return service;
    }
})();
