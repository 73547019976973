/**
 * Controller for the filter directive.
 */
(function () {
    'use strict';

    angular
        .module('bombora')
        .directive('headerFilter', function () {
            return {
                template: require('./topic-filter.html'),
                controller: filterCtrl,
                controllerAs: "$ctrl",
                bindToController: true,
                scope: {
                    placeholder: '=',
                    items: '=',
                    onSelect: '&',
                }
            };
        });

    filterCtrl.$inject = ['$scope'];

    /**
     * Controller for the directive.
     */
    function filterCtrl($scope) {
        var ctrl = this;

        activate();

        /**
         * Standard method to execute when the controller starts
         */
        function activate() {
            ctrl.model = null;
        }

        /**
         * Monitor changes to the bindings.
         * We just want to set the model to null to set the default option
         * selected.
         */
        $scope.$watch('$ctrl.items', function() {
            ctrl.model = null;
        });
    }
})();
