import * as angular from 'angular';
import * as $ from 'jquery';

import * as TRENDING_KPI_TILE_HTML from '../../shared/trending-kpis/components/trending-kpi-tile/component.html';
import * as WIDGET_HTML from '../analyze/campaigns/widget.html';
import * as RENAME_DASHBOARD_HTML from './rename-dashboard.html';
import * as NEW_DASHBOARD_HTML from '../dashboard/new-dashboard.html';
import * as ADD_WIDGET_HTML from '../widgets/add.html';

import { APP_BRIGHTFUNNEL } from '../../constants';
import { CTRL_TRENDING_KPIS_WIDGET } from '../../shared/trending-kpis/components/trending-kpis-widget/component';
import { SRVC_WIDGET_QUERY_STATE } from '../../shared/trending-kpis/services/query.service';

const app = angular.module(APP_BRIGHTFUNNEL);

interface ICompare {
    label: string;
    value: string;
}

interface IWidget {
    state: string;
    title: string;
    name: string;
    layout?: string;
    template?: any;
    types?: string[];
    compare?: {
        benchmark?: Record<string, ICompare>;
        trend?: Record<string, ICompare>;
        goal?: any;
    };
    callout?: {
        text?: string;
        label?: string;
    };
    list?: {
        repeater?: string;
        left?: string;
        right?: string;
        value?: string;
        totalRecordCount?: string;
        headerLeft?: string;
        headerRight?: string;
        valueHeaders?: any;
    };
    colors?: any;
    settings?: {
        excludeGlobalFilters?: boolean;
        pullQueryFromService?: boolean;
        template?: string;
        scope?: {
            query?: string;
            getCampaigns?: string;
            chart?: string;
        };
        campaign_group?: string;
        hide_revenue_group?: boolean;
    };
    webActivity?: boolean;
    resizable?: boolean;
    link?: string;
    minItemCols?: number;
    maxItemCols?: number;
    minItemRows?: number;
    maxItemRows?: number;
}

app.service('widgets', [
    '$rootScope',
    'utilities',
    '$state',
    '$q',
    'api',
    '$b',
    '_',
    'personaMap',
    'campaignPerformance',
    'noty',
    'isFeatureEnabled',
    'filters',
    SRVC_WIDGET_QUERY_STATE,
    function (
        $rootScope,
        utilities,
        $state,
        $q,
        api,
        $b,
        _,
        personaMap,
        campaignPerformance,
        noty,
        isFeatureEnabled,
        filtersSrv,
        widgetQueryStateService,
    ) {
        let dashboards = [];
        let updateDashboardOrder = false;
        let resendDashboardOrder = false;
        const widgets: Record<string, IWidget> = {};
        const that = this;
        const orgLimit = 20;
        const privateLimit = 10;
        const NUM_CONCURRENT = 4;
        const limit = 12;
        const maxTitleLength = 64;
        const getPromises = [];
        // this map contains list style widget header and data type info
        // this is used for displaying the table inside the list widget
        const listTileHeaders = {
            accountsList: {
                contacts: { type: 'number', label: 'People' },
                influenced_contacts: { type: 'number', label: 'People' },
                campaign_members: { type: 'number', label: 'Respon.' },
                open_pipeline: { type: 'currency', label: 'Pipeline' },
                open_opptys: { type: 'number', label: 'Opptys.' },
                opptys: { type: 'number', label: 'Opptys.' },
                deals: { type: 'number', label: 'Deals' },
                pipeline: { type: 'currency', label: 'Pipeline' },
                revenue: { type: 'currency', label: 'Revenue' },
                sales_activity: { type: 'number', label: 'Count' },
                web_activity: { type: 'number', label: 'Count' },
                impressions: { type: 'number', label: 'Ad Imp.' },
                clicks: { type: 'number', label: 'Ad Clicks' },
                page_views: { type: 'number', label: 'Views' }
            },
            topRevenue: {
                revenue: { type: 'currency', label: 'Revenue' },
                pipeline: { type: 'currency', label: 'Pipeline' }
            },
            campaignsList: { // the field (key) of campaignList will be something like ranged.even.leads
                leads: { type: 'number', label: 'Respon.' },
                leads_unique: { type: 'number', label: 'People' },
                accounts_unique: { type: 'number', label: 'Accts.' },
                opptys_unique: { type: 'number', label: 'Opptys.' },
                deals_unique: { type: 'number', label: 'Deals' },
                opptys: { type: 'number', label: 'Touches' },
                deals: { type: 'number', label: 'Touches' },
                pipeline: { type: 'currency', label: 'Pipeline' },
                pipeline2: { type: 'currency', label: 'Pipeline' },
                revenue: { type: 'currency', label: 'Revenue' },
                revenue2: { type: 'currency', label: 'Revenue' },
                roi: { type: 'percentage', label: '% ROI' },
                total_influenced_amount: { type: 'currency', label: 'Pipeline' },
                total_influenced_revenue: { type: 'currency', label: 'Revenue' },
                roi_pipeline: { type: 'percentage', label: '% ROI' }
            },
            ungatedCampaigns: {
                leads: { type: 'number', label: 'Visits' },
                leads_unique: { type: 'number', label: 'People' }
            },
            webActivityList: { // the field (key) of webActivityList will be something like ranged.even.leads
                leads: { type: 'number', label: 'Respon.' },
                leads_unique: { type: 'number', label: 'People' },
                accounts_unique: { type: 'number', label: 'Accts.' },
                opptys_unique: { type: 'number', label: 'Opptys.' },
                deals_unique: { type: 'number', label: 'Deals' },
                opptys: { type: 'number', label: 'Touches' },
                deals: { type: 'number', label: 'Touches' },
                pipeline: { type: 'currency', label: 'Pipeline' },
                pipeline2: { type: 'currency', label: 'Pipeline' },
                revenue: { type: 'currency', label: 'Revenue' },
                revenue2: { type: 'currency', label: 'Revenue' },
                roi: { type: 'percentage', label: '% ROI' },
                total_influenced_amount: { type: 'currency', label: 'Pipeline' },
                total_influenced_revenue: { type: 'currency', label: 'Revenue' },
                roas: { type: 'percentage', label: '% ROAS' },
                roi_pipeline: { type: 'percentage', label: '% ROI' }
            }
        };

        widgets.trendingKpis = {
            title: 'Scorecard Trending',
            name: 'Scorecard Trending',
            template: TRENDING_KPI_TILE_HTML,
            state: 'app.analyze.accounts.scorecard.kpi',
            settings: {
                excludeGlobalFilters: true,
                pullQueryFromService: true,
                template: 'shared/trending-kpis/components/trending-kpis-widget/component.html',
            },
            link: CTRL_TRENDING_KPIS_WIDGET,
        };

        widgets.marketingImpact = {
            title: 'Marketing Influenced {{ data.defaultTitleData === "deal" ? "Revenue" : data.defaultTitleData === "oppty" ? "Pipeline" : "" }}',
            name: 'Marketing Influence',
            template: `
                <div class="max max-width padding">
                    <angular-chart instance="data.chartInstance" options="chart"></angular-chart>
                </div>`,
            state: $rootScope.userData.platform === 'full'
                ? 'app.discover.revenueAndPipeline.marketingImpact'
                : 'app.analyze.revenueAndPipeline.marketingImpact',
            colors: [],
            settings: {
                template: 'marketing-impact-widget-options.html',
                scope: {
                    query: 'widget.query'
                }
            },
            minItemCols: 1,
            maxItemCols: 3,
            minItemRows: 1,
            maxItemRows: 2,
            link: 'marketingImpactWidgetCtrl'
        };

        widgets.velocityCampaign = {
            // the title of the title
            title: 'Velocity of Campaign Group - {{::_.titleCase((_.find(data.cohorts,{key:widget.query.cohort}).value || "(selected cohort)"))}}',
            // title: 'Marketing Impact by Revenue/Pipeline',
            name: 'Velocity By Campaign Group',
            template: '<div class="max max-width padding"><angular-chart instance="data.chartInstance" options="chart"></angular-chart></div>',
            state: 'app.discover.stageProgression.velocityCampaignCategory',
            settings: {
                template: 'velocity-campaign-config-options.html',
                scope: {
                    chart: 'widget.data',
                    query: 'widget.query'
                }
            },
            minItemCols: 2,
            maxItemCols: 4,
            minItemRows: 1,
            maxItemRows: 2,
            link: 'velocityCampaignWidgetCtrl'
        };

        widgets.accountsList = {
            // the title of the title
            title: 'Top Accounts - {{targetField().label||"selected field"}}',
            // the widget name - different than the title - it's how it shows up in a widget menu
            name: 'Top Accounts',
            // i.e. comparison,list,
            layout: 'list',
            list: {
                repeater: 'data.data',
                left: 'row.account_name',
                right: '{{ _.get(row, widget.query.fld) | nrFormat:(widget.list.valueHeaders[widget.query.fld].type === \'currency\'):true:1 }}{{ widget.list.valueHeaders[widget.query.fld].type === \'percentage\' ? \'%\' : \'\' }}',
                value: '{{ _.get(row, widget.query.fld) | metafilter:targetField().filter }}',
                totalRecordCount: 'data.totals',
                headerLeft: '{{ \'Name\' }}',
                headerRight: '{{ $tile.getListTileValueHeaderLabel(widget.list.valueHeaders, widget.query.fld) }}',
                valueHeaders: listTileHeaders['accountsList']
            },
            state: 'app.analyze.accounts.attribution',
            settings: {
                template: 'account-widget-options.html',
                scope: {
                    query: 'widget.query'
                }
            },
            resizable: true,
            link: 'accountsAttributionWidgetCtrl'
        };

        if ($rootScope.userData.platform === 'full' || $rootScope.userData.platform === 'campaign') {
            widgets.leadsGenerated = {
                // the title of the title
                title:
                    '{{::_.find(data.fields,{key:widget.query.field}).value || "(selected field)"}} {{::_.find(data.cohorts, {key:widget.query.cohort}).value == "Custom Range" ? " - " : widget.type == \'goal\' ? "Goal - " : "Over"}} {{::_.find(data.cohorts,{key:widget.query.cohort}).value == "Custom Range" ? (widget.query.startDate | date:"shortDate") : _.find(data.cohorts,{key:widget.query.cohort}).value || "(selected cohort)"}} {{::_.find(data.cohorts,{key:widget.query.cohort}).value == "Custom Range" ? "to" : ""}} {{::_.find(data.cohorts,{key:widget.query.cohort}).value == "Custom Range" ? (widget.query.endDate | date:"shortDate") : ""}}',
                // the widget name - different than the title - it's how it shows up in a widget menu
                name: 'Campaign Trends',
                // i.e. comparison,list,
                layout: 'comparison',
                // define the content for the footer area
                template: '<div class="max max-width"><angular-chart class="{{data.progressHealth.label}} goal-line-chart" instance="data.chartInstance" options="chart"></angular-chart></div>',
                // dashboard types allowed
                types: ['trend', 'goal', 'benchmark'],
                // bind-height="chartHeight" bind-height-digest="true" bind-width="chartWidth" bind-width-digest="true"
                // get the template from url or cache and include instead of template
                // templateUrl: cacheBuster('partials/widgets/...',
                compare: {
                    benchmark: {
                        a: {
                            // label: '{{::data.currentPeriod.nowDate|date:"shortDate"}}',
                            label: '"Now"',
                            value: '{{::selectedCohort()}}'
                            // value: '{{::data.nowTotal|metafilter:_.find(data.fields,{key:widget.query.field}).filter}}',
                        },
                        b: {
                            // label: '{{::data.currentPeriod.thenDate|date:"shortDate"}}',
                            label: '"Then"',
                            value: '{{::selectedCohort(widget.query.previous)}}'
                            // value: '{{::data.thenTotal|metafilter:_.find(data.fields,{key:widget.query.field}).filter}}'
                        }
                    },
                    trend: {
                        a: {
                            label: '"Now"',
                            value: '{{::selectedCohort()}}'
                        }
                    }, // counter tile
                    goal: {}
                },
                callout: {
                    text:
                        '<span ng-class="data.progressHealth.textCls" class="space-right">{{data.nowTotal|metafilter:widget.data.currentFilter}} <span ng-if="widget.type == \'goal\'"> / {{ widget.data.goals[widget.query.field][widget.query.cohort]|metafilter:widget.data.currentFilter}}</span></span><span ng-if="widget.type == \'benchmark\'" ng-class="::data.health.textCls"> (<i class="icon-inherit space-right" ng-class="{\'icon-arrow-up\':data.diff > 0, \'icon-arrow-down\':data.diff < 0}"></i><div class="inline-block">{{::abs(data.diff) + \'%\'}}</div>)</span>',
                    label: '{{:: _.find(data.fields,{key:widget.query.field}).value === "Responses" ? "Responses" : _.upperFirst(_.find(data.fields,{key:widget.query.field}).value) + " " + _.upperFirst(widget.query.model == "sourced" ? "Sourced" : "Attributed")}}'
                },
                colors: ['#1f77b4', '#C5C5C5'],
                state: 'app.analyze.campaigns.trendingAnalysis',
                settings: {
                    template: 'campaign-performance-widget-options.html',
                    scope: {
                        query: 'widget.query'
                    }
                },
                resizable: true,
                link: 'campaignPerformanceTrendingWidgetCtrl'
            };
        }

        if ($rootScope.userData.platform === 'full') {
            widgets.campaignSpecificTrending = {
                // the title of the title
                title: '{{data.campaign.campaign.name}}',
                // the widget name - different than the title - it's how it shows up in a widget menu
                name: 'Campaign Details',
                // i.e. comparison,list,
                template: WIDGET_HTML,
                colors: ['#1f77b4'],
                state: 'app.analyze.campaigns.campaignSpecific.trending',
                settings: {
                    template: 'campaign-trending-widget-options.html',
                    scope: {
                        query: 'widget.query',
                        getCampaigns: 'getCampaign'
                    }
                },
                resizable: true,
                link: 'campaignSpecificTrendingWidgetCtrl'
            };
        }

        widgets.cpList = {
            // the title of the title
            title:
                'Top {{::_.titleCase((widget.query.camp == "groups" ? "Types" : "Campaigns")) +  " by " + _.find(data.fields,{field:widget.query.field}).label + " " + _.titleCase((_.find(data.cohorts,{key:widget.query.cohort}).value || "(selected cohort)"))}}',
            // the widget name - different than the title - it's how it shows up in a widget menu
            name: 'Top Campaign Types',
            // i.e. comparison,list,
            layout: 'list',
            list: {
                // in ctrl scope
                repeater: 'data.data',
                left: '{{ row.name }}',
                right: '{{ row.value | nrFormat:(widget.list.valueHeaders[widget.query.field.split(\'.\').pop()].type === \'currency\'):true:1 }}{{ widget.list.valueHeaders[widget.query.field.split(\'.\').pop()].type === \'percentage\' ? \'%\' : \'\' }}',
                value: '{{ row.value | metafilter:data.fieldInfo.filter }}',
                totalRecordCount: 'sortedData.length',
                headerLeft: '{{ \'Name\' }}',
                headerRight: '{{ $tile.getListTileValueHeaderLabel(widget.list.valueHeaders, widget.query.field) }}',
                valueHeaders: listTileHeaders['campaignsList']
            },
            state: 'app.analyze.campaigns.listAnalysis.campaignGroups',
            // options
            settings: {
                template: 'campaign-performance-list-widget-options.html',
                // rescope
                scope: {
                    query: 'widget.query'
                }
            },
            resizable: true,
            link: 'campaignPerformanceListWidgetCtrl'
        };

        widgets.campaignsList = {
            // the title of the title
            title:
                'Top {{::_.titleCase((widget.query.camp == "groups" ? "Types" : "Campaigns")) +  " by " + _.find(data.fields,{field:widget.query.field}).label + " " + _.titleCase((_.find(data.cohorts,{key:widget.query.cohort}).value || "(selected cohort)"))}}',
            // the widget name - different than the title - it's how it shows up in a widget menu
            name: 'Top Campaigns',
            // i.e. comparison,list,
            layout: 'list',
            list: {
                // in ctrl scope
                repeater: 'data.data',
                left: '{{ row.name }}',
                right: '{{ row.value | nrFormat:(widget.list.valueHeaders[widget.query.field.split(\'.\').pop()].type === \'currency\'):true:1 }}{{ widget.list.valueHeaders[widget.query.field.split(\'.\').pop()].type === \'percentage\' ? \'%\' : \'\' }}',
                value: '{{ row.value | metafilter:data.fieldInfo.filter }}',
                totalRecordCount: 'sortedData.length',
                headerLeft: '{{ \'Name\' }}',
                headerRight: '{{ $tile.getListTileValueHeaderLabel(widget.list.valueHeaders, widget.query.field) }}',
                valueHeaders: listTileHeaders['campaignsList']
            },
            state: 'app.analyze.campaigns.listAnalysis.campaigns',
            // options
            settings: {
                template: 'campaign-performance-list-widget-options.html',
                // rescope
                scope: {
                    query: 'widget.query'
                }
            },
            resizable: true,
            link: 'campaignPerformanceListWidgetCtrl'
        };

        if ($rootScope.userData.permissions.web_tracking && $rootScope.userData.platform === 'full') {
            widgets.ungatedCampaigns = {
                // the title of the title
                title: 'Top Pages by Known Visits - {{_.titleCase((_.find(data.cohorts,{key:widget.query.cohort}).value || "(selected cohort)"))}}',
                // the widget name - different than the title - it's how it shows up in a widget menu
                name: 'Ungated Touches',
                // i.e. comparison,list,
                layout: 'list',
                list: {
                    // in ctrl scope
                    repeater: 'data.data',
                    left: '{{ row.name }}',
                    right: '{{ row.value | nrFormat:(widget.list.valueHeaders[widget.query.field].type === \'currency\'):true:1 }}{{ widget.list.valueHeaders[widget.query.field].type === \'percentage\' ? \'%\' : \'\' }}',
                    value: '{{ row.value | metafilter:data.fieldInfo.filter }}',
                    totalRecordCount: 'sortedData.length',
                    headerLeft: '{{ \'Name\' }}',
                    headerRight: '{{ $tile.getListTileValueHeaderLabel(widget.list.valueHeaders, widget.query.field) }}',
                    valueHeaders: listTileHeaders['ungatedCampaigns']
                },
                state: 'app.analyze.campaigns.listAnalysis.campaigns',
                // options
                settings: {
                    template: 'campaign-performance-list-widget-options.html',
                    // rescope
                    scope: {
                        query: 'widget.query'
                    },
                    campaign_group: 'ungated web touch',
                    hide_revenue_group: true
                },
                resizable: true,
                link: 'campaignPerformanceListWidgetCtrl'
            };
        }

        /* ENGAGEMENT INSIGHTS WIDGET */
        const hasTerminusData = $rootScope.orgConfig['terminus_data'];

        if (hasTerminusData) {
            widgets.engagementInsights = {
                // customizable widget title
                title: '{{::widget.getWidgetTitle()}}',
                // the widget name - different than the title - it's how it shows up in a widget menu
                name: 'Engagement Insights',
                template: '<div class="max max-width padding" ng-class="{\'hide-y-axis\' : data.hideYAxis, \'hide-y2-axis\' : data.hideY2Axis}"><angular-chart instance="data.chartInstance" options="chart"></angular-chart></div>',
                state: 'app.analyze.accounts.attribution',
                colors: {
                    accounts: '#346CAE',
                    metric: '#00C085'
                },
                settings: {
                    template: 'engagement-insights-widget-options.html',
                    scope: {
                        query: 'widget.query'
                    }
                },
                minItemCols: 1,
                maxItemCols: 3,
                minItemRows: 1,
                maxItemRows: 2,
                link: 'accountsInsightsWidgetCtrl'
            };
        }

        // oppty analysis widget
        widgets.opptyAnalysisList = {
            // the title of the title
            title: 'Top Opptys {{_.find(data.options,{key:widget.query.type}).value}} {{(_.find(data.cohorts,{key:widget.query.cohort}).value || "(selected cohort)")}}',
            // the widget name - different than the title - it's how it shows up in a widget menu
            name: 'Top Opportunities',
            // i.e. list
            layout: 'list',
            list: {
                // in ctrl scope
                repeater: 'data.data',
                left: 'row.name', // left value
                right: '{{ row.value | nrFormat:true:true:1 }}', // right value
                value: '{{ row.value | metafilter:data.filter }}',
                totalRecordCount: 'data.totalRecordCount',
                headerLeft: '{{ \'Name\' }}',
                headerRight: '{{ \'Amount\' }}',
                valueHeaders: listTileHeaders['opptyAnalysisList']
            },
            state: 'app.analyze.opportunities.listAnalysis',
            // options
            settings: {
                template: 'oppty-analysis-widget-options.html',
                // rescope
                scope: {
                    query: 'widget.query'
                }
            },
            resizable: true,
            link: 'opptyAnalysisListWidgetCtrl'
        };

        if ($rootScope.userData.platform === 'full') {
            // stages snapshot widget
            widgets.stagesSnapshot = {
                // the title of the title
                title: 'Stages Snapshot - {{(_.find(select_options,{key:widget.query.cohort}).value || "(selected cohort)")}}',
                // title: 'Marketing Impact by Revenue/Pipeline',
                // the widget name - different than the title - it's how it shows up in a widget menu
                name: 'Stages Snapshot',
                // i.e. comparison,list,
                // layout: 'custom',
                // define the content for the footer area
                // templateUrl: cacheBuster('partials/widgets/custom.html',
                template: '<div class="max max-width padding"><angular-chart instance="data.chartInstance" options="chart"></angular-chart></div>',
                state: 'app.discover.stageProgression.stagesSnapshot',
                // get the template from url or cache and include instead of template
                // templateUrl: cacheBuster('partials/widgets/...'
                settings: {
                    template: 'stages-snapshot-widget-options.html',
                    scope: {
                        query: 'widget.query'
                    }
                },
                minItemCols: 2,
                maxItemCols: 4,
                minItemRows: 1,
                maxItemRows: 2,
                link: 'stagesSnapshotWidgetCtrl'
            };

            // web activity list
            widgets.webActivityList = {
                // the title of the title
                title:
                    'Top Digital Sources' +
                    '{{  " by " + _.find(data.fields,{field:widget.query.field}).label + " " + _.titleCase((_.find(data.cohorts,{key:widget.query.cohort}).value || "(selected cohort)"))}}',
                // the widget name - different than the title - it's how it shows up in a widget menu
                name: 'Top Digital Sources',
                // i.e. comparison,list,
                layout: 'list',
                list: {
                    // in ctrl scope
                    repeater: 'data.data',
                    left: '{{ row.name }}', // left value
                    right: '{{ row.value | nrFormat:(widget.list.valueHeaders[widget.query.field.split(\'.\').pop()].type === \'currency\'):true:1 }}{{ widget.list.valueHeaders[widget.query.field.split(\'.\').pop()].type === \'percentage\' ? \'%\' : \'\' }}', // right value
                    value: '{{ row.value | metafilter:data.fieldInfo.filter }}',
                    totalRecordCount: 'sortedData.length',
                    headerLeft: '{{ \'Name\' }}',
                    headerRight: '{{ $tile.getListTileValueHeaderLabel(widget.list.valueHeaders, widget.query.field) }}',
                    valueHeaders: listTileHeaders['webActivityList']
                },
                state: 'app.analyze.webTracking.webActivity.channel',
                // options
                settings: {
                    template: 'campaign-performance-list-widget-options.html',
                    // rescope
                    scope: {
                        query: 'widget.query'
                    }
                },
                resizable: true,
                webActivity: true,
                link: 'campaignPerformanceListWidgetCtrl'
            };

            widgets.channelAssetsList = {
                // the title of the title
                title:
                    'Top Digital Tactics' +
                    '{{  " by " + _.find(data.fields,{field:widget.query.field}).label + " " + _.titleCase((_.find(data.cohorts,{key:widget.query.cohort}).value || "(selected cohort)"))}}',
                // the widget name - different than the title - it's how it shows up in a widget menu
                name: 'Top Digital Tactics',
                // i.e. comparison,list,
                layout: 'list',
                list: {
                    // in ctrl scope
                    repeater: 'data.data',
                    left: '{{ row.name }}',
                    right: '{{ row.value | nrFormat:(widget.list.valueHeaders[widget.query.field.split(\'.\').pop()].type === \'currency\'):true:1 }}{{ widget.list.valueHeaders[widget.query.field.split(\'.\').pop()].type === \'percentage\' ? \'%\' : \'\' }}', // right value
                    value: '{{ row.value | metafilter:data.fieldInfo.filter }}',
                    totalRecordCount: 'sortedData.length',
                    headerLeft: '{{ \'Name\' }}',
                    headerRight: '{{ $tile.getListTileValueHeaderLabel(widget.list.valueHeaders, widget.query.field) }}',
                    valueHeaders: listTileHeaders['webActivityList']
                },
                state: 'app.analyze.webTracking.webActivity.channelAssets',
                // options
                settings: {
                    template: 'campaign-performance-list-widget-options.html',
                    // rescope
                    scope: {
                        query: 'widget.query'
                    }
                },
                resizable: true,
                webActivity: true,
                link: 'campaignPerformanceListWidgetCtrl'
            };

            // channel asset trending tile
            widgets.channelAssetSpecificTrendingTile = {
                // the title of the title
                title: '{{data.campaign.campaign.name}}',
                // the widget name - different than the title - it's how it shows up in a widget menu
                name: 'Digital Tactic Details',
                // i.e. comparison,list,
                template: WIDGET_HTML,
                colors: ['#1f77b4'],
                state: 'app.analyze.webTracking.channelAssetSpecific.trending',
                settings: {
                    template: 'campaign-trending-widget-options.html',
                    scope: {
                        query: 'widget.query',
                        getCampaigns: 'getCampaign'
                    }
                },
                resizable: true,
                webActivity: true,
                link: 'campaignSpecificTrendingWidgetCtrl'
            };

            // web activity trending tile
            widgets.webActivityTrendingTile = {
                // the title of the title
                title:
                    '{{::_.find(data.fields,{key:widget.query.field}).value || "(selected field)"}} {{::_.find(data.cohorts, {key:widget.query.cohort}).value == "Custom Range" ? " - " : widget.type == \'goal\' ? "Goal - " : "Over"}} {{::_.find(data.cohorts,{key:widget.query.cohort}).value == "Custom Range" ? (widget.query.startDate | date:"shortDate") : _.find(data.cohorts,{key:widget.query.cohort}).value || "(selected cohort)"}} {{::_.find(data.cohorts,{key:widget.query.cohort}).value == "Custom Range" ? "to" : ""}} {{::_.find(data.cohorts,{key:widget.query.cohort}).value == "Custom Range" ? (widget.query.endDate | date:"shortDate") : ""}}',
                // the widget name - different than the title - it's how it shows up in a widget menu
                name: 'Digital Conversion Trends',
                // i.e. comparison,list,
                layout: 'comparison',
                // define the content for the footer area
                template: '<div class="max max-width"><angular-chart class="{{data.progressHealth.label}} goal-line-chart" instance="data.chartInstance" options="chart"></angular-chart></div>',
                // dashboard types allowed
                types: ['trend', 'goal', 'benchmark'],
                // bind-height="chartHeight" bind-height-digest="true" bind-width="chartWidth" bind-width-digest="true"
                // get the template from url or cache and include instead of template
                // templateUrl: cacheBuster('partials/widgets/...',
                compare: {
                    benchmark: {
                        a: {
                            // label: '{{::data.currentPeriod.nowDate|date:"shortDate"}}',
                            label: '"Now"',
                            value: '{{::selectedCohort()}}'
                            // value: '{{::data.nowTotal|metafilter:_.find(data.fields,{key:widget.query.field}).filter}}',
                        },
                        b: {
                            // label: '{{::data.currentPeriod.thenDate|date:"shortDate"}}',
                            label: '"Then"',
                            value: '{{::selectedCohort(widget.query.previous)}}'
                            // value: '{{::data.thenTotal|metafilter:_.find(data.fields,{key:widget.query.field}).filter}}'
                        }
                    },
                    trend: {
                        a: {
                            label: '"Now"',
                            value: '{{::selectedCohort()}}'
                        }
                    }, // counter tile
                    goal: {}
                },
                callout: {
                    text:
                        '<span ng-class="data.progressHealth.textCls" class="space-right">{{data.nowTotal | metafilter:widget.data.currentFilter}} <span ng-if="widget.type == \'goal\'"> / {{ widget.data.goals[widget.query.field][widget.query.cohort] | metafilter:widget.data.currentFilter}}</span></span><span ng-if="widget.type == \'benchmark\'" ng-class="::data.health.textCls"> (<i class="icon-inherit space-right" ng-class="{\'icon-arrow-up\':data.diff > 0, \'icon-arrow-down\':data.diff < 0}"></i><div class="inline-block">{{::abs(data.diff) + \'%\'}}</div>)</span>',
                    label: '{{:: _.find(data.fields,{key:widget.query.field}).value === "Responses" ? "Responses" : _.upperFirst(_.find(data.fields,{key:widget.query.field}).value) + " " + _.upperFirst(widget.query.model == "sourced" ? "Sourced" : "Attributed")}}'
                },
                colors: ['#1f77b4', '#C5C5C5'],
                state: 'app.analyze.webTracking.webActivityTrending',
                settings: {
                    template: 'campaign-performance-widget-options.html',
                    scope: {
                        query: 'widget.query'
                    }
                },
                resizable: true,
                webActivity: true,
                link: 'campaignPerformanceTrendingWidgetCtrl'
            };
        }

        /**
         * Auxiliary function to solve for duplicates.
         * The behavior is to generate a new ID if a duplicate is found.
         * The array is changed in-place
         * @param _widgets
         */
        function solveDuplicates(_widgets) {
            const Ids = {};
            _widgets.forEach(function (widget) {
                if (Ids[widget.id]) {
                    widget.id = utilities.generateId();
                }
                Ids[widget.id] = true;
            });
        }

        this.__testonly__ = {
            solveDuplicates
        };

        // ------------------------ init keys ------------------------//
        _.forEach(widgets, function (v, k) {
            // remove web tracking tiles if they do not have the permission
            if (_.includes(v.state, 'app.analyze.webTracking') && !$rootScope.userData.permissions.web_tracking) {
                delete widgets[k];
            } else {
                v.key = k;
            }
        });

        /*==============================================
        methods
        =============================================*/

        this.get = function (value, field: string) {
            return field
                ? that.list().find(item => item[field] === value)
                : widgets[value];
        };

        this.unique = function (widget, copy) {
            const cp = copy === false ? widget : angular.copy(widget);
            cp.id = utilities.generateId();
            return cp;
        };

        this.flushQ = function () {
            angular.forEach(getPromises, function (promise) {
                promise.resolve(dashboards);
            });
        };

        this.getDashboards = function () {
            // ------------------------ one call that: returns data if it's already loaded, Loads data if there is none and then returns it, and can be called from multiple locations and has one promise ------------------------//
            const defer = $q.defer();
            getPromises.push(defer);
            if (!dashboards.length) {
                if (getPromises.length === 1) {
                    api.getter({
                        url: 'dashboard/get',
                        params: {},
                        paramsNotToUpdate: 'all',
                    }).then(function (data) {
                        const list = that.list();
                        dashboards = angular.extend(
                            dashboards,
                            _.map(data.data, function (o) {
                                solveDuplicates(o.widgets);
                                o.widgets = _.filter(o.widgets, 'key');
                                // checks if widget routes are available in nav
                                const key = 'state';
                                o.widgets = _.filter(o.widgets, function (w) {
                                    const widg = _.find(list, { key: w.key }); // look for the key because we never change that, we only change the name
                                    if (widg) {
                                        const state = widg[key];
                                        return $state.get(state);
                                    }
                                });
                                return o;
                            })
                        );
                        if ($rootScope.userData.isAdmin()) {
                            dashboards.forEach(function (dashboard) {
                                dashboard.is_mine = true;
                            });
                        }
                        if (!dashboards.length) {
                            const dashName =
                                $rootScope.userData && $rootScope.userData.marketingRole ? _.find(personaMap.personRoleMap, { name: $rootScope.userData.marketingRole }).dashName : 'My Dashboard';
                            that.makeDashboard(dashName).then(function () {
                                that.flushQ();
                            });
                        } else {
                            // that.setDashboards();
                            that.flushQ();
                        }
                    });
                }
            } else {
                that.flushQ();
            }
            return $q.all(_.map(getPromises, 'promise'));
            // return defer.promise;
        };

        this.dashboards = function () {
            return dashboards;
        };

        this.getConcurrent = function () {
            return NUM_CONCURRENT;
        };

        this.getTitleLength = function () {
            return maxTitleLength;
        };

        this.getLimit = function () {
            return limit;
        };

        this.togglePermission = function (dashboard) {
            $b.confirm({
                text:
                    'Are you sure you want to change ' +
                    '<strong>' +
                    dashboard.name +
                    '</strong>' +
                    ' from ' +
                    (dashboard.is_public ? 'public' : 'private') +
                    ' to ' +
                    (dashboard.is_public ? 'private' : 'public') +
                    '?',
                callback: function () {
                    const defer = $q.defer();
                    dashboard.is_public = !dashboard.is_public;
                    that.setDashboards(dashboard);
                    return defer.promise;
                }
            });
        };

        this.setDashboards = function (currentDash, saveTitle) {
            const defer = $q.defer(),
                k = _.remove(_.uniq(_.flatten(_.map(that.list(), _.keys))), function (n) {
                    return n !== 'name' && n !== 'key';
                });
            k.push('visible');

            if (saveTitle) {
                const i = _.indexOf(k, 'title');
                if (i !== -1) {
                    k.splice(i, 1);
                }
            }
            const d = _.map(dashboards, function (dashboard) {
                const dd = angular.copy(dashboard);
                dd.widgets = _.map(dd.widgets, function (widget) {
                    return _.omit(widget, k);
                });
                return dd;
            });
            currentDash = currentDash ? currentDash : _.find(dashboards, { id: $state.params.id });

            // A redirect to a new controller is going on. Just ignore this call.
            if (!currentDash) { return; }

            if (currentDash.name.length > maxTitleLength) { return; }

            solveDuplicates(currentDash.widgets);
            _.forEach(currentDash.widgets, function (widget) {
                widget.id = String(widget.id);
                if (widget.settings.pullQueryFromService && !!widgetQueryStateService.widgetQueries[widget.queryId]) {
                    widget.query = widgetQueryStateService.widgetQueries[widget.queryId].query;
                }
            });
            const params = {
                dashboard_id: currentDash.id,
                settings: angular.toJson(currentDash.widgets),
                is_public: currentDash.is_public,
                name: currentDash.name,
                dashboard_order: dashboards.indexOf(currentDash)
            };

            if (this.permissionToEditDashboard(currentDash)) {
                api.set('dashboard/update', params, false)
                    .success(function (data) {
                        currentDash.name = data;
                        $rootScope.$broadcast('$$rebind::dashboardUpdated');
                        $rootScope.$broadcast('$$rebind::widgetUpdated');
                        defer.resolve(d);
                    })
                    .error(function () {
                        defer.reject();
                    });
            } else {
                defer.resolve(d);
            }

            return defer.promise;
        };

        this.list = function (): IWidget[] {
            return _.values(widgets);
        };

        this.getDashboard = function (id) {
            return _.find(dashboards, { id: id });
        };

        this.returnDashName = function () {
            const id = $state.params.id;
            const dash = that.getDashboard(id);
            if (dash) {
                return dash.name;
            }
        };

        this.ctrl = function (fun, args) {
            $rootScope.$broadcast('widgetMessage', {
                fun: fun,
                args: args
            });
        };

        this.permissionToEditDashboard = function (dashboard) {
            return (dashboard && dashboard.is_mine) || $rootScope.userData.isAdmin();
        };

        this.makeDashboard = function (name, duplicateParams) {
            const defer = $q.defer();
            let nid,
                obj;

            if (!duplicateParams) {
                nid = utilities.generateId();

                if (_.isActualObject(name)) {
                    obj = name;
                    obj.id = nid;
                    obj.widgets = [];
                    obj.role = name.selectedRole.key;
                    if (obj.roles) {
                        obj = _.omit(obj, ['roles']);
                    }
                } else {
                    obj = {
                        id: nid,
                        name: name,
                        role: '',
                        widgets: [],
                        is_mine: true
                    };
                }
            } else {
                // duplicate dashboard
                obj = duplicateParams;
                nid = obj.id;
            }
            const params = {
                dashboard_id: obj.id,
                settings: angular.toJson(obj.widgets),
                is_public: obj.is_public,
                name: obj.name,
                dashboard_order: that.dashboards().length,
                role: obj.role
            };

            api
                .set('dashboard/create', params, false)
                .success(function (data) {
                    obj.name = data;
                    if (!duplicateParams) {
                        dashboards.splice(params.dashboard_order, 0, obj);
                    }
                    defer.resolve(nid);
                })
                .error(function () {
                    defer.reject();
                });
            return defer.promise;
        };

        this.duplicateDashboard = function (dashboard) {
            const new_dashboard = angular.copy(dashboard);
            new_dashboard.id = utilities.generateId();
            new_dashboard.is_mine = true;
            new_dashboard.is_public = false;
            that.renameDashboard(new_dashboard, true);
        };

        this.renameDashboard = function (dashboard, isDuplicate) {
            if (this.permissionToEditDashboard(dashboard)) {
                const new_dashboard = dashboard;
                const model = {
                    title: isDuplicate ? 'Duplicate Dashboard' : 'Rename Dashboard',
                    id: 'rename-dashboard',
                    template: RENAME_DASHBOARD_HTML,
                    width: '500px',
                    buttons: [
                        {
                            html: isDuplicate ? 'Create Dashboard' : 'Rename Dashboard',
                            'ng-click': 'finish()',
                            'ng-disabled': '!data.name || data.loading',
                            class: 'btn btn-sm btn-primary pull-right'
                        }
                    ],
                    x: true,
                    maxed: false,
                    controller: function ($scope) {
                        $scope.data = {};
                        $scope.finish = function () {
                            $scope.data.loading = true;
                            dashboard.name = $scope.data.name;
                            if (isDuplicate) {
                                that.makeDashboard(false, new_dashboard);
                                dashboards.push(new_dashboard);
                            }
                            $scope.data.loading = false;
                            $scope.$modal.closeModal();
                            that.setDashboards(dashboard);
                            if (isDuplicate) {
                                $state.go('app.dashboard', { id: new_dashboard.id });
                            }
                        };
                    }
                };
                $b.modal(model);
            } else {
                $b.alert('Only the owner of this dashboard can make changes to it.', 'Permission Needed');
            }
        };

        this.checkDashboards = function () {
            let orgCount = 0,
                privateCount = 0;
            angular.forEach(dashboards, function (dash) {
                if (dash.is_mine && !dash.is_public) {
                    // if a dashboard is private, add to private count
                    privateCount++;
                }
                if (!dash.is_mine && dash.is_public) {
                    // if a dashboard is public, add to public (org) count
                    orgCount++;
                }
            });
            return orgCount > orgLimit && privateCount > privateLimit; // check if private count is greater than 10 and public (org) count is greater than 20
        };

        this.newDashboard = function () {
            const limitReached = that.checkDashboards();
            if (!limitReached) {
                const model = {
                    title: 'New Dashboard',
                    id: 'add-dashboard',
                    template: NEW_DASHBOARD_HTML,
                    width: '500px',
                    buttons: [
                        {
                            html: 'Create Dashboard',
                            'ng-disabled': '!data.name || data.loading',
                            'ng-click': 'finish()',
                            class: 'btn btn-sm pull-right btn-primary'
                        }
                    ],
                    x: false,
                    maxed: false,
                    controller: ['$scope', 'api', 'utilities', 'navigation', 'widgets', '$state', 'personaMap', function (_$scope, _api, _utilities, navigation, _widgets, _$state, _personaMap) {
                        _$scope.data = {
                            is_public: false,
                            is_mine: true,
                            roles: angular.copy(_personaMap.personRoleMap),
                            order: that.dashboards().length
                        };

                        // if they don't have a role, don't put the me next to it
                        // if you have a marketing role
                        if ($rootScope.userData.marketingRole && _.find(_$scope.data.roles, { name: $rootScope.userData.marketingRole })) {
                            // find the role key
                            const myRoleObj = _.find(_$scope.data.roles, { name: $rootScope.userData.marketingRole });
                            // change the name of the role to have (me) after it
                            myRoleObj.name = myRoleObj.name + ' (Me)';
                            const roleIndex = _.findIndex(_$scope.data.roles, { key: myRoleObj.key });
                            const temp = _$scope.data.roles[1];
                            _$scope.data.roles[1] = _$scope.data.roles[roleIndex];
                            _$scope.data.roles[roleIndex] = temp;

                            // two arrays and concat it together
                            const tempArray = _$scope.data.roles.slice(0, 2);
                            let sortArray = _$scope.data.roles.slice(2, _$scope.data.roles.length);
                            sortArray = _.sortBy(sortArray, 'key');
                            _$scope.data.roles = tempArray.concat(sortArray);
                        }
                        _$scope.data.selectedRole = _personaMap.personRoleMap[0];

                        _$scope.finish = function () {
                            _$scope.data.loading = true;
                            _widgets.makeDashboard(_$scope.data).then(function (nid) {
                                $rootScope.$broadcast('$$rebind::dashboardUpdated');
                                $rootScope.$broadcast('$$rebind::widgetsUpdated');
                                _$scope.$modal.closeModal();
                                _$scope.data.loading = false;
                                _$state.go('app.dashboard', { id: nid });
                            });
                        };
                    }]
                };
                $b.modal(model);
            } else {
                $b.alert('You\'ve reached the maximum number of allowable dashboards.');
            }
        };

        this.deleteDashboard = function (id) {
            const dashboard = _.find(dashboards, { id: id });
            if (this.permissionToEditDashboard(dashboard)) {
                if (dashboards.length > 1) {
                    $b.confirm({
                        text: 'Are you sure you want to delete ' + '<strong>' + dashboard.name + '.</strong>' + ' Once deleted, it can\'t be recovered.',
                        callback: function () {
                            const defer = $q.defer(),
                                params = {
                                    dashboard_id: dashboard.id
                                },
                                i = dashboards.indexOf(dashboard);
                            dashboards.splice(i, 1);
                            $rootScope.$broadcast('$$rebind::dashboardUpdated');
                            api
                                .set('dashboard/delete', params, false)
                                .success(function () {
                                    that.changeDashboardOrder();
                                    defer.resolve();
                                })
                                .error(function () {
                                    defer.reject();
                                });
                            if ($state.current.name === 'app.dashboard') {
                                $state.go('app.dashboard', { id: dashboards[0].id });
                            }
                            return defer.promise;
                        }
                    });
                } else {
                    $b.alert('You must have at least one dashboard.', 'Dashboard Minimum');
                }
            } else {
                $b.alert('Only the owner of this dashboard can make changes to it.', 'Permission Needed');
            }
        };

        this.changeDashboardOrder = function () {
            if (!updateDashboardOrder) {
                const params = {};
                updateDashboardOrder = true;
                _.forEach(dashboards, function (dash, i) {
                    dash.order = i;
                    params[dash.id] = dash.order;
                });
                api.set('dashboard/updateOrder', { dashboards: JSON.stringify(params) }, false).success(function () {
                    updateDashboardOrder = false;
                    if (resendDashboardOrder) {
                        resendDashboardOrder = false;
                        that.changeDashboardOrder();
                    }
                    $rootScope.$broadcast('$$rebind::dashboardUpdated');
                });
            } else {
                resendDashboardOrder = true;
            }
        };

        this.addTile = function (id: string, queryParams?: Record<string, string>) {
            const dashboard = that.getDashboard(id);
            if (!this.permissionToEditDashboard(dashboard)) {
                $b.alert('Only the owner of this dashboard can make changes to it.', 'Permission Needed');
                return;
            }

            if (dashboard.widgets.length >= 12) {
                $b.alert('You\'ve reached the maximum number of allowable dashboard tiles.', 'Limit Reached');
                return;
            }

            const parent = dashboard;
            const model = {
                title: 'Add a Tile to "' + dashboard.name + '"',
                id: 'add-tile',
                template: ADD_WIDGET_HTML,
                width: '800px',
                buttons: [
                    {
                        html: 'Add Tile',
                        'ng-disabled': 'loading || (!widget || (widget.type == "goal" && !widget.data.goals)) || !widgetQueryStateService.allQueriesValid',
                        'ng-click': 'addWidget(widget)',
                        'ng-class': 'widget.key',
                        class: 'btn btn-primary btn-sm pull-right'
                    }
                ],
                x: true,
                maxed: false,
                controller: ['$scope', 'navigation', 'widgets', '$filter', '$interpolate', '$timeout', '_', '$b', '$state', SRVC_WIDGET_QUERY_STATE,
                    function (_$scope, _navigation, _widgets, _$filter, _$interpolate, _$timeout, _u, _$b, _$state, _widgetQueryStateService) {

                        _$scope.$parentState = parent;
                        _$scope.navigation = _navigation;
                        _$scope.loading = false;
                        _$scope.$state = _$state;
                        _$scope.data = {
                            sections: angular.copy(_$scope.$states[0].children)
                        };
                        _$scope.widgets = _widgets;
                        _$scope.widgetQueryStateService = _widgetQueryStateService;
                        _$scope.cohorts = parent.cohorts;
                        _$scope._ = _u;
                        _$scope.platform = $rootScope.userData.platform;

                        _$scope.$on('$apiStart', function () {
                            _$scope.apiLoading = true;
                        });

                        _$scope.$on('$apiFinish', function () {
                            _$scope.apiLoading = false;
                        });

                        // if user adds tile from terminus hub then there should be pre-selected widget
                        if (queryParams && queryParams.routeId) {
                            _$scope.widget = _$scope.widgets.get(queryParams.routeId, 'state');
                            // set global filters for selected widget from query params
                            if (queryParams.globalFilters) {
                                const filters = JSON.parse(queryParams.globalFilters);
                                _$scope.widget.query = _$scope.widget.query
                                    ? { ..._$scope.widget.query, ...filters }
                                    : { ...filters };
                            }
                            // set account lists from selected widget from query params
                            if (queryParams.accountLists) {
                                _$scope.widget.query = _$scope.widget.query
                                    ? { ..._$scope.widget.query, al: queryParams.accountLists }
                                    : { al: queryParams.accountLists };
                            }
                        }

                        _$scope.checkState = function (state) {
                            const value = state['state'];

                            return !!_$state.get(value);
                        };

                        _$scope.cleanTitle = function (title) {
                            return _$interpolate(title)(_$scope);
                        };

                        _$scope.previewTile = function ($tile) {
                            _$scope.$modal.mergeFilters().then(function () {
                                $tile.data.loading.failed = false;
                                $tile.data.noData = false;
                                _$scope.$broadcast('widgetUpdate');
                            });
                        };

                        _$scope.resizeText = function () {
                            _$scope.$broadcast('gridster-item-resize'); // resize the chart
                            _$timeout(
                                function () {
                                    _$scope.$broadcast('resizeText'); // if there's fittext involved, then it'll resize the text
                                },
                                0,
                                false
                            );
                        };

                        _$scope.subset = function (stateName) {
                            return _$filter('filter')(_widgets.list(), { state: stateName });
                        };

                        _$scope.hasWidgets = function (states) {
                            let show = 0;
                            angular.forEach(states, function (state) {
                                show += _$scope.subset(state.name).length;
                            });
                            return show;
                        };

                        _$scope.filterChildRoutes = function (widgetDef) {
                            const widgetRoute = _u.findDeep(_$scope.data.sections, { name: widgetDef.state });
                            return widgetRoute && widgetRoute.status ? widgetRoute.status !== 'removed' : true;
                        };

                        _$scope.select = function (w) {
                            if (!angular.equals(w, _$scope.widget)) {
                                _$scope.widget = null;
                                _$timeout(function () {
                                    _$scope.widget = angular.copy(w);
                                }, 0);
                            }
                        };

                        _$scope.addWidget = function (w) {
                            _$scope.loading = true;
                            _$scope.$modal.mergeFilters().then(() => {
                                if (dashboard.widgets.length < 12) {
                                    const cp = _widgets.unique(w, false);
                                    if (cp) {
                                        dashboard.widgets.push(cp);
                                        _widgets.setDashboards(dashboard).then(function () {
                                            _$scope.loading = false;
                                            _$scope.$modal.closeModal();
                                            $rootScope.$broadcast('$$rebind::dashboardUpdated');
                                            $rootScope.$broadcast('$$rebind::widgetUpdated');
                                        });
                                    } else {
                                        _$scope.loading = false;
                                        noty.growl('Oops, something went wrong', 'information', true);
                                        return;
                                    }
                                } else {
                                    _$scope.loading = false;
                                    _$b.alert('You\'ve reached the maximum number of allowable dashboard tiles.');
                                }
                            });
                        };

                    }]
            };

            $b.modal(model);
        };

        this.getTile = function (dashId, tileId) {
            const defer = $q.defer();
            const obj: any = {};
            const dashboard = that.getDashboard(dashId);
            obj.dashboard = dashboard;
            if (dashboard) {
                const tile = _.find(dashboard.widgets, function (w) {
                    return w.id === tileId;
                });
                if (tile) {
                    obj.tile = tile;
                    defer.resolve(obj);
                } else {
                    defer.reject();
                }
            } else {
                defer.reject();
            }
            return defer.promise;
        };

        this.changeDashboardName = function () {
            that.setDashboards();
            $rootScope.$broadcast('$$rebind::dashboardUpdated');
            $rootScope.$broadcast('$$rebind::widgetsUpdated');
        };

        this.resizeChart = function (instance) {
            try {
                const parent = $(instance.element).parent(),
                    size = { width: parent.width(), height: parent.height() };

                instance.resize(size);
            } catch (e) {
                return;
            }
        };

        // this returns a map of sfdc object type to what keys they are coming back as in the API
        this.nameKeyMap = function (key) {
            const nameMap = {
                    'campaigns': 'campaign_name',
                    'groups': 'revenue_group',
                    'opptys': 'name'
                },
                found = nameMap[key];

            if (found) { return found; } else { return null; }
        };

        this.getName = function (obj, key) {
            const nameKey = that.nameKeyMap(key);
            return obj[nameKey];
        };

        this.getListValue = function (obj, fieldInfo, currentModel) {
            let field, val;
            if (currentModel in obj) { // if the object has sourced/last/even/custom as a key
                field = fieldInfo.field.replace('ranged.' + currentModel + '.', ''); // extract the regular field key without model ranged prefix
                val = _.get(obj[currentModel], field);
            } else {
                const splitField = fieldInfo.field.split('.'),
                    checkFieldInfo = splitField.indexOf(currentModel) > -1; // check if the fieldinfo.field string has model in it

                field = checkFieldInfo ? fieldInfo.field.replace('ranged.' + currentModel + '.', '') : fieldInfo.field.replace('ranged.', '');
                val = _.get(obj, field);
            }
            return val ? val : 0;
        };

        this.sortListData = function (data, field) {
            return _.reverse(
                _.sortBy(data, [
                    function (o) {
                        return o[field];
                    }
                ])
            );
        };

        this.formatWidgetListData = function (data, field, key, $scope, gf, currentModel) {
            let d;
            // handles all non lazy load columns, or columns that are ignoreLazy (i.e. cost columns)
            if (!field.lazy_load || field.ignoreLazy) {
                d = _.map(_.values(data.data[key]), function (o) {
                    if (key === 'groups') {
                        return o.summary;
                    } else {
                        return o.campaign || o.oppty;
                    }
                });
                d = _.filter(d, function (row) {
                    return campaignPerformance.hasActivity(row, $scope);
                });
            } else if (field.lazy_group_only && key === 'campaigns') {
                d = _.map(_.values(data.data[key]), function (o) {
                    if (key === 'groups') {
                        return o.summary;
                    } else {
                        return o.campaign;
                    }
                });
            } else {
                d = _.map(_.values(data.data[key]), function (o) {
                    return o;
                });
            }

            if (gf) {
                // if you're filtering by a campaign group
                d = _.filter(d, function (campaign) {
                    return campaign.revenue_group === gf;
                });
            }
            const finalFormat = _.map(d, function (o) {
                return { name: that.getName(o, key), value: that.getListValue(o, field, currentModel) };
            });
            return finalFormat;
        };

        this.numberOfResults = function (data, widget) {
            let truncateData = [];
            if (widget.rows && widget.rows > 1) {
                truncateData = _.slice(data, 0, 10);
            } else {
                truncateData = _.slice(data, 0, 5);
            }
            return truncateData;
        };

        this.numberOfTopNResults = function (data, widget) {
            // each tile has a min size of 390 x 390 and each snap on the grid increases the its height by 400
            // for example, a tile occupying one row on the grid will have a height of 390
            // the same tile, when occupying 2 rows will have a height of 790 and so on so forth
            // content height is defined as tile overall height - tile title height - content header height - content footer height
            const rowCount = widget.rows || 1;
            // (tile height - tile title height - content header height) + (rowCount - 1 * 400) - content footer height
            const contentHeight = (390 - 52 - 32) + ((rowCount - 1) * 400) - 13;

            // each record has a fixed height of 28
            return _.slice(data, 0, Math.floor(contentHeight / 28));
        };

        this.addFilters = function (params, globalFilters, accountLists) {
            if (params) {
                params = utilities.removeFilterValues(params);
                const filterParams = filtersSrv.getFiltersAsParams(globalFilters);
                if (accountLists) {
                    const selectedLists = filtersSrv.getSelectedAccountLists(accountLists.folders);
                    if (selectedLists.length > 0) {
                        filterParams.al = selectedLists.join(',');
                    }
                }

                if (filterParams && Object.keys(filterParams).length) {
                    angular.extend(params, filterParams);
                }
            }
            return params;
        };
    }
]);
