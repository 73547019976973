import { module as angularModule } from 'angular';
import { APP_BRIGHTFUNNEL } from './constants';

((w, moduleName): void => {
    const injectables = ['ui.bootstrap', 'ui.select2', 'ui.select', 'ui.sortable', 'ui.router', 'bf.filters', 'core-directives',
        'core-services', 'ngAnimate', 'ngCsv', 'mgo-angular-wizard', 'notyModule', 'xeditable', 'ngCookies', 'toggle-switch',
        'ngStorage', 'ngMaterial', 'smart-table', 'angularChart', 'ui.event', 'lrDragNDrop', 'cfp.hotkeys', 'angular-gridster2',
        'angular-svg-round-progress', 'JSONedit', 'ds.objectDiff', 'angular.bind.notifier', 'ngFileSaver',
        'jwt', 'bombora', 'switchUser', 'sfdc', 'accountJourney', 'environment', 'ui.utils.masks'];

    if (navigator.userAgent.match(/iPad/i)) {
        injectables.push('ngTouch');
    }

    w.localStorage.removeItem('Terminus Hub');

    const app = angularModule(moduleName, injectables);

    // Expose the main application globally.
    w['app'] = app;
})(window, APP_BRIGHTFUNNEL);
