(function () {
    'use strict';

    app.directive('groupMultiSearch', function () {
        return {
            restrict: 'E',
            template: require('./group-multisearch.html'),
            controller: groupMultisearchCtrl,
            controllerAs: "$ctrl",
            bindToController: true,
            scope: {
                ngModel: "=",
                ngChange: "&",
                listValues: "=",
                placeholder: "=",
                params: "=",
                lazyLoad: '&'
            },
            transclude: true,
            link: groupMultiLnk
        };
    });

    groupMultisearchCtrl.$inject = ['lazyLoadSrvc'];

    /**
     * Controller for the directive.
     */
    function groupMultisearchCtrl(lazyLoadSrvc) {
        let ctrl = this;
        ctrl.loading = false;
        ctrl.lazyLoadSrvc = lazyLoadSrvc;
        ctrl.search = "";

        ctrl.updateSearch = function(searchValue) {
            ctrl.search = searchValue;
            if (ctrl.lazyLoad()) {
                ctrl.lazyLoad()({query: searchValue});
            }
        };
    }

    /**
     * Link function for the directive
     * @param scope
     * @param element
     * @param attrs
     */
    function groupMultiLnk(scope, element, attrs, ctrl) {
        var list = element[0].querySelector('.dropdown-menu');
        var lazyLoadProperties = {
            loading: ctrl.loading,
            endOfList: false,
            scope: scope,
            params: {query: ctrl.search}
        };

        list.addEventListener('scroll', function (event) {
            lazyLoadProperties.params.query = ctrl.search;
            lazyLoadProperties = ctrl.lazyLoadSrvc.lazyList(ctrl.lazyLoad, event, lazyLoadProperties);
        });
    }
})();
