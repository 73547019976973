app.factory('Scopes', function () {
    var mem = {};

    return {
        store: function (key, value) {
            mem[key] = value;
        },
        get: function (key) {
            return mem[key];
        }
    };
});

app.controller('accountsAttributionCtrl', ['$scope', 'api', 'utilities', 'account', '_', 'filters', '$window',
    '$rootScope', '$b', '$localStorage', 'Scopes', '$filter', 'noty', 'csvContextSrvc',
    function ($scope, api, utilities, accountsSv, _, filters, $window, $rootScope,
        $b, $storage, Scopes, $filter, noty, csvContextSrvc) {
        const maxPageSize = 130;
        var pipeCall;
        var manageModalListener;
        var acctAttr = this;

        //--------------------- PRIVATE FUNCTIONS --------------------------------//

        acctAttr.pageWindow = function (page, pageSize, windowSize) {
            return Math.floor(((page - 1) * pageSize / windowSize) + 1);
        };

        acctAttr.refreshData = function () {
            if ($scope.data.stTable) {
                $scope.data.stTable.controller.pipe($scope.data.stTable.controller.tableState());
                $scope.clearAllAccounts();
            }
        };

        //<editor-fold desc="Column Functions">
        acctAttr.shouldLoadColumn = function (col) {
            return col.visible && col.apiType && !$scope.data.loadedApis[col.apiType];
        };

        acctAttr.loadOneColumn = function (column, accountIds, accountChunks, params, destination) {
            $scope.data.loadedApis[column.apiType] = true;
            params.fieldType = column.apiType;
            params.accountIds = accountIds;
            params = accountsSv.removePagingAndSortingParams(params);
            api.set('account/load_list_data', params, null, true).then(function (results) {
                accountsSv.addResultsToAccountObject(accountChunks, destination, results.data.rows, results.data.defaults);
            });
        };

        acctAttr.loadOtherColumns = function (accountIds, accountChunks, columns, params, destination) {
            var fired = [params.fieldType];

            columns.forEach(function (col) {
                if (acctAttr.shouldLoadColumn(col, fired)) {
                    fired.push(col.apiType);
                    acctAttr.loadOneColumn(col, accountIds, accountChunks, params, destination);
                }
            });
        };

        acctAttr.resetColumns = function () {
            for (var n in $scope.data.loadedApis) {
                $scope.data.loadedApis[n] = false;
            }
        };

        function shouldResetTablePage(currentPage, previousPage) {
            return currentPage === previousPage;
        }
        //</editor-fold>

        //--------------------- SCOPE FUNCTIONS --------------------------------//
        $scope.updateTable = function () {
            $rootScope.$evalAsync(function () {
                $scope.resize();
            });
        };

        $scope.registerChanges = function (newValue, oldValue) {
            if (!angular.equals(newValue.query.metric, oldValue.query.metric)) {
                $scope.updateTable();
            }
        };

        $scope.pipeData = function (tableState) {
            if (pipeCall) { pipeCall.abort(); }

            $scope.loadingTable = true;

            var params = $scope.query,
                pagination = tableState.pagination,
                start = pagination.start || 0, // This is NOT the page number, but the index of item in the list that you want to use to display the table.
                pageSize = pagination.number || 10,
                field = tableState.sort.predicate,
                page = Math.ceil(start / pageSize) + 1;

            if (!field) {
                field = 'account_name';
            }
            var header = _.find($scope.data.columns, { 'field': field });

            params.ps = maxPageSize; // api supports server side pagination
            params.pg = acctAttr.pageWindow(page, pageSize, params.ps);
            params.fld = field;
            params.dir = !tableState.sort.reverse ? 'a' : 'd';
            params.fieldType = header.apiType;
            params.tbl = header.apiType;
            delete params.accountIds;

            acctAttr.resetColumns();

            $scope.data.loadedApis[header.apiType] = true;
            header.loaded = true;

            var currentParams = angular.extend(angular.copy(params), filters.getFiltersAsParams($rootScope.filters));
            var selectedLists = filters.getSelectedAccountLists($rootScope.accountLists.folders);
            if (selectedLists.length > 0) {
                currentParams.al = selectedLists.join(",");
            } else {
                delete currentParams.al;
            }

            var cached;
            if (angular.equals(currentParams, $scope.data.pageCache.query)) {
                cached = _.get($scope.data.pageCache.data, [page, params.fld, params.dir]);
            } else {
                $scope.clearPageCache();
            }

            /* we are only caching the latest API call.
          For example, if the first 130 results (10 pages), all the data is cached.
          As soon as you pass 130 results (page 11 + ), then it will fetch data and cache THAT api call

          If we wanted to implementing caching for all pages, we would need to store a key and the page.....
       */
            if (cached) {
                $scope.data.accounts = cached;
                $scope.data.csvUrl = accountsSv.getCsvUrl(currentParams, $scope.data.headerSubset);
                $scope.loadingTable = false;
                $scope.data.previousPage = page;
            } else {
                if (shouldResetTablePage(page, $scope.data.previousPage)) {
                    $scope.applySearch();
                    params.pg = 1;
                    pagination.start = 0;
                    page = 1;
                } else {
                    $scope.data.previousPage = page;
                }
                let apiParams = { url: 'account/load_list_data', params: params, paramsNotToUpdate: ['pg', 'ps', 'tbl', 'fld', 'dir'] };
                (pipeCall = api.getter(apiParams)).then(function (data) {

                    $scope.data.destination = [(((params.pg - 1) * (params.ps / pageSize)) + 1), params.fld, params.dir];

                    $scope.data.accountIds = [];
                    data.data.rows.forEach(function (row) {
                        $scope.data.accountIds.push(row.account_id);
                    });

                    $scope.data.pageCache.query = currentParams;
                    $scope.data.pageCache.numPages = Math.ceil(data.data.total_rows / pageSize);
                    $scope.data.pageCache.window = window;
                    $scope.data.csvUrl = accountsSv.getCsvUrl(currentParams, $scope.data.headerSubset);

                    utilities.chunkData($scope.data.pageCache.data, data.data.rows, pageSize, $scope.data.destination, 0);
                    $scope.data.accounts = _.get($scope.data.pageCache.data, [page, params.fld, params.dir]);
                    $scope.data.totals = data.data.total_rows;

                    tableState.pagination.numberOfPages = $scope.data.pageCache.numPages;
                    tableState.pagination.totalItemCount = data.data.total_rows;

                    acctAttr.loadOtherColumns($scope.data.accountIds, $scope.data.pageCache.data, $scope.data.columns, angular.copy(params), $scope.data.destination);
                }, function () {

                }).finally(function () {
                    $scope.loadingTable = false;
                });
            }
        };

        $scope.orderCols = function () {
            if (!$storage.columnData) {
                $storage.columnData = {};
            }

            $scope.data.columns.forEach(function (col) {
                if ($storage.columnData[col.field]) {
                    col.visible = $storage.columnData[col.field].visible;
                }
            });

            return _.sortBy($scope.data.columns,
                [function (o) { return $storage.columnData[o.field] ? $storage.columnData[o.field].order : o.order; }]);
        };

        $scope.resize = function () {
            $($window).resize();
        };

        $scope.clearPageCache = function () {
            $scope.data.pageCache = { data: {} };
        };

        //<editor-fold desc="Search Functions">
        $scope.applySearch = function () {
            $scope.activeSearch = $scope.query && $scope.query.search;
            if ($scope.activeSearch) {
                $scope.activeSearchTerm = $scope.query.search;

                $scope.clearAllAccounts();
            }
        };

        $scope.clearSearch = function () {
            $scope.activeSearch = false;
            $scope.activeSearchTerm = null;
            $scope.query.search = null;

            $scope.clearAllAccounts();

            acctAttr.refreshData();
        };
        //</editor-fold>

        //<editor-fold desc="Edit List Functions">
        $scope.doneListEdit = function () {
            $scope.cleanupListEditing();

            $scope.editingList = false;
            $scope.activeListEditing = {};

            $scope.clearSearch();
        };

        $scope.cleanupListEditing = function () {
            $rootScope.$broadcast('doneEditingList');

            if ($scope.editingList) {
                filters.clearAllLists($rootScope.accountLists.folders);
                filters.enableList($rootScope.accountLists.folders, $scope.activeListEditing.id);
                $rootScope.accountLists.selected = 0;
            }
        };
        //</editor-fold>

        //<editor-fold desc="List (Add & Remove) Checkbox Functions">
        $scope.showSelectAllAccountsOption = function () {
            return acctAttr.showSelectAllAccountsOption();
        };
        acctAttr.showSelectAllAccountsOption = function () {
            return $scope.isAllAccountsCheckboxChecked() &&
            $scope.data.stTable.controller.tableState().pagination.start === 0 &&
            $scope.selectedAccountsOption != 'All';
        };

        $scope.showClearAllAccountsOption = function () {
            return acctAttr.showClearAllAccountsOption();
        };
        acctAttr.showClearAllAccountsOption = function () {
            return $scope.isAllAccountsCheckboxChecked() || Object.keys($scope.selectedAccountsObj).length > 0;
        };

        $scope.isAllAccountsCheckboxChecked = function () {
            return acctAttr.isAllAccountsCheckboxChecked();
        };
        acctAttr.isAllAccountsCheckboxChecked = function () {
        //make sure there's data in the table that has come back from the server
            if ($scope.data.accounts && $scope.data.accounts.length > 0) {
                if ($scope.selectedAccountsOption === 'Partial') {
                    return $scope.getAccountsSelectedOnPage() === $scope.data.accounts.length;
                } else if ($scope.selectedAccountsOption === 'All') {
                    if (Object.keys($scope.selectedAccountsObj).length === 0 && $scope.selectedAccountsObj.constructor === Object) {
                        return true;
                    }
                }
            }

            return false;
        };

        $scope.isAccountCheckboxChecked = function (account_id) {
            return acctAttr.isAccountCheckboxChecked(account_id);
        };
        acctAttr.isAccountCheckboxChecked = function (account_id) {
            if ($scope.selectedAccountsOption === 'Partial') {
                if ($scope.selectedAccountsObj[account_id]) {
                    return true;
                }
            } else if ($scope.selectedAccountsOption === 'All') {
                if (!$scope.selectedAccountsObj[account_id]) {
                    return true;
                }
            }

            return false;
        };

        $scope.selectAllAccounts = function () {
            $scope.selectedAccountsOption = 'All';
            $scope.selectedAccountsObj = {};
        };

        $scope.clearAllAccounts = function () {
            $scope.selectedAccountsOption = 'Partial';
            $scope.selectedAccountsObj = {};
        };

        $scope.onAllAccountsClick = function () {
            acctAttr.onAllAccountsClick();
        };
        acctAttr.onAllAccountsClick = function () {
        //make sure there's data in the table that has come back from the server
            if ($scope.data.accounts && $scope.data.accounts.length > 0) {
                if ($scope.selectedAccountsOption === 'Partial') {
                    var selectionCount = $scope.getAccountsSelectedOnPage();

                    for (var i = 0; i < $scope.data.accounts.length; i++) {
                        if (selectionCount === $scope.data.accounts.length) {
                            delete $scope.selectedAccountsObj[$scope.data.accounts[i].account_id];
                        } else {
                            $scope.selectedAccountsObj[$scope.data.accounts[i].account_id] = true;
                        }
                    }
                } else if ($scope.selectedAccountsOption === 'All') {
                    if (Object.keys($scope.selectedAccountsObj).length === 0) {
                        $scope.selectedAccountsOption = 'Partial';
                    } else {
                        $scope.selectedAccountsObj = {};
                    }
                }
            }
        };

        $scope.onAccountClick = function (account_id) {
            acctAttr.onAccountClick(account_id);
        };
        acctAttr.onAccountClick = function (account_id) {
            if ($scope.selectedAccountsObj[account_id]) {
                delete $scope.selectedAccountsObj[account_id];
            } else {
                $scope.selectedAccountsObj[account_id] = true;

                //if every account is selected, change state to all, and reset obj map
                if (Object.keys($scope.selectedAccountsObj).length === $scope.data.totals) {
                    $scope.selectedAccountsOption = 'All';
                    $scope.selectedAccountsObj = {};
                }
            }
        };

        $scope.getAccountsSelectedOnPage = function () {
            return acctAttr.getAccountsSelectedOnPage();
        };
        acctAttr.getAccountsSelectedOnPage = function () {
            var selectionCount = 0;
            if ($scope.data.accounts) {
                for (var i = 0; i < $scope.data.accounts.length; i++) {
                    if ($scope.selectedAccountsObj[$scope.data.accounts[i].account_id]) {
                        selectionCount++;
                    }
                }
            }

            return selectionCount;
        };

        // Checks whether or not accounts has been selected prior to accessing the 'Add to List' button.
        $scope.canAddToList = function () {
            return acctAttr.canAddToList();
        };
        acctAttr.canAddToList = function () {
            return ($scope.selectedAccountsOption === 'All' || Object.keys($scope.selectedAccountsObj).length > 0) && !$scope.editingList;
        };

        // Checks whether or not accounts has been selected prior to accessing the 'Delete from List' button.
        $scope.canRemoveFromList = function () {
            return acctAttr.canRemoveFromList();
        };
        acctAttr.canRemoveFromList = function () {
            return ($scope.selectedAccountsOption === 'All' || Object.keys($scope.selectedAccountsObj).length > 0) && $scope.editingList;
        };

        // As we are handling cases differently whether or not the user selected all, the number of accounts selected
        // is calculated such as : if all accounts selected, then counts equals to all accounts minus the one manually selected
        // by the use, if not all is selected, then the counts equals to the accounts selected by the user.
        $scope.getTotalAccountsSelected = function () {
            return acctAttr.getTotalAccountsSelected();
        };
        acctAttr.getTotalAccountsSelected = function () {
            var selectedAccountsCount = Object.keys($scope.selectedAccountsObj).length;
            if ($scope.selectedAccountsOption === 'All')
                return $scope.data.totals - selectedAccountsCount;
            else {
                return selectedAccountsCount;
            }
        };

        $scope.getSelectionText = function () {
            var selectionCount = acctAttr.getTotalAccountsSelected();
            var areAllAccountsSelected = selectionCount === $scope.data.totals;
            var accountPluralizationText = $scope.getAccountPluralizationText(selectionCount);
            var prefixText = areAllAccountsSelected ? "All " : "";

            return prefixText + $filter('number')(selectionCount) + " " + accountPluralizationText + (selectionCount > 1 ? " are selected" : " is selected");
        };

        $scope.getAccountPluralizationText = function (count) {
            return count > 1 ? "accounts" : "account";
        };
        //</editor-fold>

        // Adds to list modal lets you select a list, create a new folder or update a list with the selected accounts.
        $scope.addToList = function () {
            $b.modal({
                title: 'Add Accounts to a List',
                id: 'add-lists-modal',
                template: require('./manage-modal.html'),
                width: '600px',
                buttons: [
                    {
                        'text': 'Add to List',
                        'class': 'btn btn-sm btn-primary pull-right somemargin-right',
                        'ng-click': 'addAccountsToList()',
                        'ng-disabled': 'data.loadingLists || !canEditListAndListExists(data.selected, data.selectedList)'
                    },
                    {
                        'text': 'Create New Folder',
                        'class': 'btn btn-sm btn-default pull-right somemargin-right',
                        'ng-click': 'createFolder()',
                        'ng-disabled': 'data.loadingLists'
                    },
                    {
                        'text': 'Create New List',
                        'class': 'btn btn-sm btn-default pull-right somemargin-right',
                        'ng-click': 'createList(true)',
                        'ng-disabled': 'data.loadingLists || !canEditFolderContents(data.selected)'
                    },
                ],
                controller: 'accountsAttributionModalCtrl'
            });
        };

        $scope.removeFromList = function () {
            var parent = $scope;
            var list = $scope.activeListEditing;

            $b.modal({
                title: 'Remove Accounts?',
                id: 'delete-confirmation-modal',
                template: require('./delete-confirmation-modal.html'),
                width: '400px',
                buttons: [
                    {
                        'text': 'Remove',
                        'class': 'btn btn-sm btn-warning pull-right',
                        'ng-click': 'deleteListMembers()',
                        'ng-disabled': 'data.loading'
                    },
                    {
                        'text': 'Cancel',
                        'ng-click': 'closeModal()',
                        'class': 'btn btn-sm btn-default pull-right somemargin-right',
                        'ng-disabled': 'data.loading'
                    }
                ],
                controller: function ($scope) {
                    $scope.data = {
                        delete_copy: 'Are you sure you wish to remove <b>' + $filter('number')(parent.getTotalAccountsSelected()) + '</b> total accounts from <b>"' + list.name + '"</b> list?'
                    };
                    $scope.error = {
                        message: null
                    };

                    $scope.closeModal = function () {
                        $scope.$modal.closeModal();
                    };

                    $scope.deleteListMembers = function () {
                        $scope.data.loading = true;

                        var url;
                        var params = {
                            list_id: list.id,
                        };

                        if (accountsSv.hasFilteringValues($scope.$state.params) && parent.selectedAccountsOption === 'All') {
                            params.excluded_account_ids = Object.keys(parent.selectedAccountsObj);
                            utilities.combine(params, $scope.$state.params);
                            url = 'accountsLists/removeSearchResultsFromList';
                        } else if (parent.selectedAccountsOption === 'All' &&
                        Object.keys(parent.selectedAccountsObj).length === 0 &&
                        !parent.activeSearchTerm) {
                            url = 'accountsLists/removeAllAccountsFromList';
                        } else {
                            params.account_ids = Object.keys(parent.selectedAccountsObj);
                            if (parent.selectedAccountsOption === 'All') {
                                params.invert_ids = true;
                            }
                            url = 'accountsLists/removeAccountsFromList';
                        }

                        api.set(url, params).success(function (data) {
                            parent.clearAllAccounts();
                            parent.clearPageCache();
                            parent.doneListEdit();
                            parent.showResultsOfAddOrRemove(list, data);
                            $scope.error.message = null;
                            $scope.closeModal();
                            noty.growl('Successfully removed accounts from list "' + list.name + '"', 'success');
                        }).error(function (data, status) {
                            $scope.error.message = api.handleErrorResponse(data, status);
                        }).finally(function () {
                            $scope.data.loading = false;
                        });
                    };
                }
            });
        };

        $scope.showResultsOfAddOrRemove = function (list, results) {
            var parent = $scope;
            $b.modal({
                title: 'List Summary',
                id: 'list-summary-modal',
                template: require('./summary-modal.html'),
                width: '600px',
                buttons: [
                    {
                        'text': 'Done',
                        'class': 'btn btn-sm btn-primary pull-right somemargin-right',
                        'ng-click': 'closeModal()'
                    },
                    {
                        'text': 'Manage Other Lists',
                        'class': 'btn btn-sm btn-default pull-right somemargin-right',
                        'ng-click': 'closeModal(); manageLists();'
                    }
                ],
                controller: function ($scope) {
                    $scope.list = list;
                    $scope.results = results;

                    $scope.isAdd = results.hasOwnProperty("new_members");

                    $scope.closeModal = function () {
                        $scope.$modal.closeModal();
                    };

                    $scope.manageLists = function () {
                        parent.manageLists();
                    };
                }
            });
        };

        $scope.manageLists = function () {
            let createListButton = null;
            if (csvContextSrvc.isCsvButtonEnabled()) {
                createListButton = {
                    'btn-group': {
                        'btn-text': 'Create New List',
                        'btn-class': 'btn btn-sm btn-primary',
                        'btn-click': 'createList(false)',
                        'btn-disabled': 'data.loadingLists',
                    },
                    'btn-menu': [
                        {
                            'btn-text': 'Use CSV',
                            'btn-click': 'createListFromCsv()',
                        },
                    ],
                };
            } else {
                createListButton = {
                    'text': 'Create New List',
                    'class': 'btn btn-sm btn-primary pull-right somemargin-right',
                    'ng-click': 'createList(false)',
                    'ng-disabled': 'data.loadingLists'
                };
            }

            $b.modal({
                title: 'Manage Account Lists',
                id: 'manage-lists-modal',
                template: require('./manage-modal.html'),
                width: '600px',
                buttons: [
                    createListButton,
                    {
                        'text': 'Create New Folder',
                        'class': 'btn btn-sm btn-default pull-right somemargin-right',
                        'ng-click': 'createFolder()',
                        'ng-disabled': 'data.loadingLists'
                    },
                    {
                        'text': 'Delete',
                        'class': 'btn btn-sm btn-warning pull-left somemargin-left',
                        'ng-click': 'deleteFolder()',
                        'ng-disabled': 'data.loadingLists || !canEditFolder(data.selected)',
                        'ng-if': "data.selectedList === null"
                    },
                    {
                        'text': 'Delete',
                        'class': 'btn btn-sm btn-warning pull-left somemargin-left',
                        'ng-click': 'deleteList()',
                        'ng-disabled': 'data.loadingLists || !canEditListAndListExists(data.selected, data.selectedList)',
                        'ng-if': "data.selected != null && data.selectedList != null"
                    },
                    {
                        'text': 'Edit Properties',
                        'class': 'btn btn-sm btn-default pull-left somemargin-left',
                        'ng-click': 'editFolder()',
                        'ng-disabled': 'data.loadingLists || !canEditFolder(data.selected)',
                        'ng-if': "data.selectedList === null"
                    },
                    {
                        'text': 'Edit Properties',
                        'class': 'btn btn-sm btn-default pull-left somemargin-left',
                        'ng-click': 'editList()',
                        'ng-disabled': 'data.loadingLists || !canEditListAndListExists(data.selected, data.selectedList)',
                        'ng-if': "data.selected != null && data.selectedList != null"
                    },
                    {
                        'text': 'Edit List',
                        'class': 'btn btn-sm btn-default pull-left somemargin-left',
                        'ng-click': 'viewList(data.selectedList.id)',
                        'ng-disabled': 'data.loadingLists || !canEditListAndListExists(data.selected, data.selectedList)',
                        'ng-if': "data.selected != null && data.selectedList != null"
                    }
                ],
                controller: 'accountsAttributionModalCtrl'
            });
        };

        (function init() {
            var dynamics365Enabled = $rootScope.orgConfig.organization_settings && $rootScope.orgConfig.organization_settings.dynamics365_enabled;
            var sfURL = dynamics365Enabled ? null : $rootScope.sfdcURL;

            var columns = accountsSv.listColumns($rootScope.userData.platform, sfURL);

            $scope.utils = utilities;

            $scope.editingList = false;
            $scope.activeListEditing = {};

            $scope.selectedAccountsOption = 'Partial';
            $scope.selectedAccountsObj = {};

            $scope.data = {
                init: false,
                pageCache: {
                    data: {}
                },
                columns: ($scope.orgConfig.salesActivity.enabled ? columns : _.remove(columns, function (n) {
                    return n.field != "sales_activity";
                })),
                headerSubset: [],
                chartTypes: {
                    percentage: {
                        "percentage engaged": {
                            type: "donut"
                        },
                        "not engaged": {
                            type: "donut"
                        }
                    },
                    average: {
                        "average percent engaged": {
                            type: "donut"
                        },
                        "average percent not engaged": {
                            type: "donut"
                        }
                    }
                },
                loadedApis: {}
            };

            $scope.data.headerSubset = $scope.orderCols();
            $scope.$broadcast('$$rebind::refreshHeaders');

            $scope.applySearch();

            $scope.$watch(function () { return $scope.data.headerSubset; },
            // eslint-disable-next-line no-unused-vars
                function (newValue, oldValue) {
                    if ($storage.columnData) {
                        var idx = 0;
                        newValue.forEach(function (col) {
                            if ($storage.columnData[col.field]) {
                                $storage.columnData[col.field].order = idx;
                            } else {
                                $storage.columnData[col.field] = { order: idx };
                            }
                            idx++;
                        });
                    }
                }, true);

            $scope.$watch(function () { return $scope.data.columns; },
                function (newValues, oldValues) {
                    $scope.data.csvUrl = accountsSv.getCsvUrl($scope.data.pageCache.query, newValues);
                    newValues.forEach(function (newHeader) {
                        $storage.columnData[newHeader.field] = { visible: newHeader.visible, order: newHeader.order };

                        if (acctAttr.shouldLoadColumn(newHeader)) {
                            var oldHeader = _.find(oldValues, { 'field': newHeader.field });
                            if (!oldHeader.visible) {
                                acctAttr.loadOneColumn(newHeader, $scope.data.accountIds, $scope.data.pageCache.data, angular.copy($scope.query),
                                    $scope.data.destination);
                            }
                        }
                    });
                }, true);

            $scope.$on('getData', acctAttr.refreshData);
            $scope.$on('filterData', acctAttr.refreshData);
            $scope.$on('filtersChanged', acctAttr.refreshData);
            $scope.$on('metricChanged', $scope.updateTable);
            $scope.$on('$windowFocused', $scope.resize);
            $scope.$on('$destroy', function () {
            //------------------------ clean up api calls ------------------------//
                if (pipeCall) { pipeCall.abort(); }

                //cleanup listener
                manageModalListener();

                //remove edit account list state
                $scope.cleanupListEditing();
            });

            manageModalListener = $rootScope.$on('manageLists', $scope.manageLists);
            Scopes.store('accountsAttributionCtrl', $scope);
        })();
    }]);

app.controller('accountsAttributionModalCtrl', ['$rootScope', '$scope', 'api', 'filters',
    '$b', 'noty', 'Scopes', 'utilities', '_', 'createAccountListModalSrvc',
    function ($rootScope, $scope, api, filters, $b, noty, Scopes, utilities, _, createAccountListModalSrvc) {
        $scope.data = {
            oneAtATime: true,
            selected: null
        };

        $scope.loadFolders = function (dontReloadFilters) {
            $scope.data.loadingLists = true;
            api.loadAccountFolders().then(function (folders) {
                $scope.data.folders = folders;
                $scope.data.selected = null;

                if (!dontReloadFilters) {
                    var selectedIds = filters.getSelectedAccountLists($rootScope.accountLists.folders);
                    $rootScope.accountLists.folders = angular.copy(folders);
                    filters.initializeFolders($rootScope.accountLists, selectedIds, "save");
                }
            }).finally(function () {
                $scope.data.loadingLists = false;
            });
        };

        $scope.canAddToList = function () {
            return Scopes.get('accountsAttributionCtrl').canAddToList();
        };

        $scope.canViewFolder = function (folder) {
            return folder && (folder.is_mine || folder.folder_permission === 'public' || folder.folder_permission === 'read-only');
        };

        $scope.canEditFolder = function (folder) {
            return folder && folder.is_mine;
        };

        $scope.canEditFolderContents = function (folder) {
            return folder && (folder.is_mine || folder.folder_permission === 'public');
        };

        $scope.canEditListAndListExists = function (folder, list) {
            return $scope.canEditFolderContents(folder) && list;
        };

        $scope.createFolder = function () {
            var parent = $scope;

            $b.modal({
                title: 'Create Folder',
                id: 'create-folder-modal',
                template: require('./create-folder-modal.html'),
                width: '400px',
                buttons: [
                    {
                        'text': 'Create New Folder',
                        'class': 'btn btn-sm btn-primary pull-right',
                        'ng-click': 'saveFolder()',
                        'ng-disabled': '!data.folder_name || data.loading'
                    }
                ],
                controller: function ($scope) {
                    $scope.data = {
                        folder_permission: 'public'
                    };
                    $scope.error = {
                        message: null
                    };

                    $scope.closeModal = function () {
                        $scope.$modal.closeModal();
                    };

                    $scope.saveFolder = function () {
                        $scope.data.loading = true;

                        var params = {
                            folder_name: $scope.data.folder_name,
                            folder_permission: $scope.data.folder_permission,
                            folder_type: 'account_list',
                        };

                        api.set('folder/createFolder', params).success(function (resp) {
                            parent.loadFolders();
                            $scope.error.message = null;
                            $scope.closeModal();
                            noty.growl('Successfully created folder ' + resp, 'success');
                        }).error(function (data, status) {
                            $scope.error.message = api.handleErrorResponse(data, status);
                        }).finally(function () {
                            $scope.data.loading = false;
                        });
                    };
                }
            });
        };

        $scope.addAccountsToList = function () {
            $scope.data.loadingLists = true;
            $scope.error = {
                message: null
            };

            var parentScope = Scopes.get('accountsAttributionCtrl');
            var list = $scope.data.selectedList;

            var url = 'accountsLists/addSearchResultsToList';
            var params = {
                list_id: list.id,
            };

            if (parentScope.selectedAccountsOption === 'All') {
                params.excluded_account_ids = Object.keys(parentScope.selectedAccountsObj);
                params.account_filter = parentScope.query.account_filter;

                if (parentScope.activeSearchTerm) {
                    params.search = parentScope.activeSearchTerm;
                }
            } else {
                url = 'accountsLists/addAccountsToList';
                params.account_ids = Object.keys(parentScope.selectedAccountsObj);
            }

            if (url === 'accountsLists/addSearchResultsToList') {
                utilities.combine(params, $scope.$state.params);
            }

            api.set(url, params).success(function (data) {
                $scope.$modal.closeModal();

                parentScope.clearAllAccounts();
                parentScope.clearPageCache();
                parentScope.showResultsOfAddOrRemove(list, data);
                $scope.loadFolders();
                $scope.error.message = null;
                noty.growl('Successfully added accounts to list "' + $scope.data.selectedList.name + '"', 'success');
            }).error(function (data, status) {
                $scope.error.message = api.handleErrorResponse(data, status);
            }).finally(function () {
                $scope.data.loadingLists = false;
            });
        };

        $scope.selectFolder = function (folder) {
            $scope.data.selected = folder && folder.open ? folder : null;
            $scope.data.selectedList = null;
        };

        $scope.selectList = function (list, event) {
            event.stopPropagation();
            $scope.data.selectedList = list;
        };

        $scope.editFolder = function () {
            var parent = $scope;
            var folder = parent.data.selected;

            $b.modal({
                title: 'Edit ' + folder.folder_name + ' Folder',
                id: 'edit-folder-modal',
                template: require('./edit-folder-modal.html'),
                width: '400px',
                buttons: [
                    {
                        'text': 'Save Changes',
                        'class': 'btn btn-sm btn-primary pull-right',
                        'ng-click': 'updateFolder()',
                        'ng-disabled': '!data.folder_name || data.loading'
                    }
                ],
                controller: function ($scope) {
                    $scope.data = {
                        loading: true,
                        folder_id: folder.folder_id,
                        folder_name: folder.folder_name,
                        owner: { email: folder.email, selected: { name: folder.email } },
                        folder_permission: folder.folder_permission
                    };
                    $scope.error = {
                        message: null
                    };

                    api.get('folder/getUsersForOrg', null, false, true).then(function (users) {
                        $scope.data.users = users.data;

                        var user = _.find(users.data, { email: folder.email });
                        $scope.data.owner.userId = user.userId;
                    }).finally(function () {
                        $scope.data.loading = false;
                    });

                    $scope.closeModal = function () {
                        $scope.$modal.closeModal();
                    };

                    $scope.updateFolder = function () {
                        $scope.data.loading = true;

                        var params = {
                            folder_id: $scope.data.folder_id,
                            folder_name: $scope.data.folder_name,
                            folder_permission: $scope.data.folder_permission,
                            folder_type: 'account_list',
                            owner_id: $scope.data.owner.userId,
                        };

                        api.set('folder/updateFolder', params).success(function (resp) {
                            parent.loadFolders();
                            $scope.error.message = null;
                            $scope.closeModal();
                            noty.growl('Successfully updated folder ' + resp, 'success');
                        }).error(function (data, status) {
                            $scope.error.message = api.handleErrorResponse(data, status);
                        }).finally(function () {
                            $scope.data.loading = false;
                        });
                    };
                }
            });
        };

        $scope.deleteFolder = function () {
            var parent = $scope;
            var folder = parent.data.selected;

            $b.modal({
                title: 'Delete "' + folder.folder_name + '"?',
                id: 'delete-confirmation-modal',
                template: require('./delete-confirmation-modal.html'),
                width: '400px',
                buttons: [
                    {
                        'text': 'Permanently Delete Folder',
                        'class': 'btn btn-sm btn-warning pull-right',
                        'ng-click': 'deleteFolder()',
                        'ng-disabled': 'data.loading'
                    },
                    {
                        'text': 'Cancel',
                        'ng-click': 'closeModal()',
                        'class': 'btn btn-sm btn-default pull-right somemargin-right',
                        'ng-disabled': 'data.loading'
                    }
                ],
                controller: function ($scope) {
                    $scope.data = {
                        folder_id: folder.folder_id,
                        folder_name: folder.folder_name,
                        delete_copy: "Are you sure you want to permanently delete folder and accounts lists within the folder? <b>Folders and their lists cannot be recovered once deleted.</b>"
                    };
                    $scope.error = {
                        message: null
                    };

                    $scope.closeModal = function () {
                        $scope.$modal.closeModal();
                    };

                    $scope.deleteFolder = function () {
                        $scope.data.loading = true;

                        var params = {
                            folder_id: $scope.data.folder_id,
                            folder_type: 'account_list',
                        };

                        // eslint-disable-next-line no-unused-vars
                        api.set('folder/deleteFolder', params).success(function (data) {
                            parent.loadFolders();
                            $scope.error.message = null;
                            $scope.closeModal();
                            noty.growl('Successfully deleted folder ' + $scope.data.folder_name, 'success');
                        }).error(function (data, status) {
                            $scope.error.message = api.handleErrorResponse(data, status);
                        }).finally(function () {
                            $scope.data.loading = false;
                        });
                    };
                }
            });
        };

        $scope.createList = function (addToListAfterwards) {
            var parent = $scope;

            $b.modal({
                title: 'Create a New Account List',
                id: 'create-list-modal',
                template: require('./create-list-modal.html'),
                width: '400px',
                buttons: [
                    {
                        'text': 'Create New List',
                        'class': 'btn btn-sm btn-primary pull-right',
                        'ng-click': 'saveList()',
                        'ng-disabled': '!data.name || !data.folder || data.loading'
                    }
                ],
                controller: function ($scope) {
                    var pre_selected_folder = null;
                    if (parent.data.selected && (parent.data.selected.is_mine || parent.data.selected.folder_permission === 'public')) {
                        pre_selected_folder = parent.data.selected;
                    }

                    $scope.data = {
                        folders: _.filter(parent.data.folders, function (folder) {
                            return folder.is_mine || folder.folder_permission === 'public';
                        }),
                        folder: pre_selected_folder
                    };
                    $scope.error = {
                        message: null
                    };

                    $scope.closeModal = function () {
                        $scope.$modal.closeModal();
                    };

                    $scope.saveList = function () {
                        $scope.data.loading = true;

                        var params = {
                            name: $scope.data.name,
                            folder_id: $scope.data.folder.folder_id,
                        };

                        api.set('accountsLists/createList', params).success(function (resp) {
                            $scope.error.message = null;
                            $scope.closeModal();
                            noty.growl('Successfully created list ' + resp.name, 'success');

                            if (addToListAfterwards) {
                                parent.data.selectedList = resp;
                                parent.addAccountsToList();
                            } else {
                                parent.loadFolders();
                            }
                        }).error(function (data, status) {
                            $scope.error.message = api.handleErrorResponse(data, status);
                        }).finally(function () {
                            $scope.data.loading = false;
                        });
                    };
                }
            });
        };

        $scope.createListFromCsv = () => {
            $scope.$modal.closeModal();
            createAccountListModalSrvc.showModal();
        };

        $scope.viewList = function (listId) {
            $scope.$modal.closeModal();
            var selectedList = {};

            filters.clearAllLists($rootScope.accountLists.folders);
            filters.disableList($rootScope.accountLists.folders, listId);

            $rootScope.accountLists.folders.forEach(function (folder) {
                folder.contents.forEach(function (list) {
                    if (list.id === listId) {
                        selectedList = list;
                        list.selected = true;
                        filters.selectList(list, folder, $rootScope.accountLists);
                        $rootScope.accountLists.selected = 1;
                    }
                });
            });

            $rootScope.$broadcast('editingList');

            var parentScope = Scopes.get('accountsAttributionCtrl');
            parentScope.editingList = true;
            parentScope.activeListEditing = selectedList;
            parentScope.clearSearch(); //will clear search bar and reload accounts list table
        };

        $scope.editList = function () {
            var parent = $scope;

            $b.modal({
                title: 'Edit Account List',
                id: 'create-list-modal',
                template: require('./create-list-modal.html'),
                width: '400px',
                buttons: [
                    {
                        'text': 'Save',
                        'class': 'btn btn-sm btn-primary pull-right',
                        'ng-click': 'updateList()',
                        'ng-disabled': '!data.name || !data.folder || data.loading'
                    }
                ],
                controller: function ($scope) {
                    var list = parent.data.selectedList;
                    $scope.data = {
                        name: parent.data.selectedList.name,
                        folder: parent.data.selected,
                        folders: _.filter(parent.data.folders, function (folder) {
                            return folder.is_mine || folder.folder_permission === 'public';
                        }),
                    };
                    $scope.error = {
                        message: null
                    };

                    $scope.closeModal = function () {
                        $scope.$modal.closeModal();
                    };

                    $scope.updateList = function () {
                        $scope.data.loading = true;

                        var params = {
                            name: $scope.data.name,
                            id: list.id,
                            folder_id: $scope.data.folder.folder_id,
                        };

                        api.set('accountsLists/updateList', params).success(function (resp) {
                            parent.loadFolders();
                            $scope.error.message = null;
                            $scope.closeModal();
                            noty.growl('Successfully updated list ' + resp, 'success');
                        }).error(function (data, status) {
                            $scope.error.message = api.handleErrorResponse(data, status);
                        }).finally(function () {
                            $scope.data.loading = false;
                        });
                    };
                }
            });
        };

        $scope.deleteList = function () {
            var parent = $scope;
            var list = parent.data.selectedList;
            var folder = parent.data.selected;

            $b.modal({
                title: 'Delete "' + list.name + '"?',
                id: 'delete-confirmation-modal',
                template: require('./delete-confirmation-modal.html'),
                width: '400px',
                buttons: [
                    {
                        'text': 'Permanently Delete List',
                        'class': 'btn btn-sm btn-warning pull-right',
                        'ng-click': 'deleteList()',
                        'ng-disabled': 'data.loading'
                    },
                    {
                        'text': 'Cancel',
                        'ng-click': 'closeModal()',
                        'class': 'btn btn-sm btn-default pull-right somemargin-right',
                        'ng-disabled': 'data.loading'
                    }
                ],
                controller: function ($scope) {
                    $scope.data = {
                        folder_id: folder.folder_id,
                        id: list.id,
                        delete_copy: "Are you sure you want to permanently delete this list? <b>Lists cannot be recovered once deleted.</b>"
                    };
                    $scope.error = {
                        message: null
                    };

                    $scope.closeModal = function () {
                        $scope.$modal.closeModal();
                    };

                    $scope.deleteList = function () {
                        $scope.data.loading = true;

                        var params = {
                            folder_id: folder.folder_id,
                            id: list.id,
                        };

                        // eslint-disable-next-line no-unused-vars
                        api.set('accountsLists/deleteList', params).success(function (data) {
                            parent.loadFolders();
                            $scope.error.message = null;
                            $scope.closeModal();
                            noty.growl('Successfully deleted list ' + list.name, 'success');
                        }).error(function (data, status) {
                            $scope.error.message = api.handleErrorResponse(data, status);
                        }).finally(function () {
                            $scope.data.loading = false;
                        });
                    };
                }
            });
        };

        $scope.loadFolders(true);
    }]);
