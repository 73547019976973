export const ACTIVE_ORGS_ONLY_LABEL = 'Active Orgs Only';
export const ORGANIZATION_LABEL = 'Organization';
export const INTEGRATIONS_LABEL = 'Integrations';
export const BUTTON_LABEL = 'Save';
export const TERMINUS_HUB = 'Terminus Hub';
export const NOTY_ERROR = 'error';
export const NOTY_SUCCESS = 'success';
export const NOTY_SUCCESS_MESSAGE = 'Visibility saved!';

export const TOKEN_MANAGER_DOMAIN_KEY = 'TOKEN_MANAGER_URL';
export const AUTHORIZE_URL = '/v1/authorize';
export const GET_VISIBILITY_URL = '/v1/get_integrations_visibility';
export const SET_VISIBILITY_URL = '/v1/set_integrations_visibility';
