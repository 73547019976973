app.controller('campaignSFDCCtrl', ['$scope', '$b', 'api', 'noty', '$state', function ($scope, $b, api, noty, $state) {
    var campaignSFDCcall;

    $scope.filterCampaignMember = function () {
        switch ($scope.data.filterType) {
        case 'responded':
            $scope.data.cmFilteredData = $scope.data.cmFilteredData.filter(function (row) {
                return row.is_responded;
            });
            break;
        case 'attributed':
            $scope.data.cmFilteredData = $scope.data.cmFilteredData.filter(function (row) {
                return row.is_attributed;
            });
            break;
        case 'all':
        default:
            $scope.data.cmFilteredData = $scope.data.campaign.campaign_members;
            break;
        }
    };

    (function init() {
        $state.current.data.loading = true;
        $scope.data = {
            opptyTableData: [],
            cmTableData: [],
            cmFilteredData: [],
            tabs: {
                cm: false,
                opptys: true
            },
            filterType: 'all'
        };

        (campaignSFDCcall = api.get('campaign_sfdc', { campaignId: $state.params.campaignId }, false, true)).then(function (data) {
            $scope.data.campaign = data.data;
            $state.current.data.title = data.data.name;
            $state.current.data.loading = false;
            $scope.data.filterType = 'all';
            $scope.data.cmFilteredData = $scope.data.campaign.campaign_members;
        });

        $scope.$on('$destroy', function () {
            if (campaignSFDCcall) { campaignSFDCcall.abort(); }
        });

    })();
}]);
