import {IScope} from 'angular';
import {
    ADD_HEADERS_LIST, CHUNK_TIME,
    CREATE_UPDATE_LIST,
    CSV_FILE_TYPE,
    DELETE_OPERATION,
    DELETED_MEMBERS_LABEL,
    DROP_FILES_LABEL,
    IGNORED_ACCOUNTS_LABEL,
    EMPTY_FILE_ERROR,
    ERROR_FOLDERS, FIRST_RADIO_LABEL,
    FOLDER_DROP_DOWN_TITLE,
    HEADER_CHECK,
    IMPORT_CSV_MODAL_SUBTITLE,
    INVALID_ACCOUNT_ID,
    INVALID_OPERATION, LIST_CHUNK_SIZE,
    LIST_DROP_DOWN_TITLE, LIST_UPDATED_LABEL, LOADER_MESSAGE, MAX_COLUMN_SIZE,
    MERGE_OPERATION,
    MERGE_REPLACE_DELETE_LIST,
    MISSING_ACCOUNT_IDS,
    MISSING_FOLDER,
    MISSING_LIST_NAME,
    MODAL_INPUT_TYPE,
    NEW_MEMBERS_LABEL,
    REPLACE_OPERATION,
    REPLACING_MEMBERS_WARNING,
    SELECT_FILE_LABEL,
    TOTAL_ACCOUNTS_LABEL
} from '../constants/constants';
import {AccountListsSrvc} from './account-lists.service';
import {RowList} from './row-list';
import {FolderSrvc} from './folder.service';
import {FolderItem} from './folder-item';
import {ListItem} from './list-item';
import {UpdateFiltersSrvc} from './update-filters.service';

export class ContextSrvc {

    static $inject: Array<string> = ['accountListsSrvc', 'folderSrvc',
        'csvUpdateGlobalFilters', 'isFeatureEnabled', '$state'];

    public constructor(private accountListsSrvc: AccountListsSrvc,
                       private folderSrvc: FolderSrvc,
                       private csvUpdateGlobalFilters: UpdateFiltersSrvc,
                       private isFeatureEnabled: any,
                       private $state: any,
    ) {
        return;
    }

    public setLoader(scope: IScope, value: number): void {
        scope.items.loader.visible = true;
        scope.items.loader.totalSeconds = value;
    }

    public stopLoader(scope: IScope): void {
        scope.items.loader.visible = false;
    }

    public initialize(scope: IScope): Promise<void> {
        scope.items = {
            loader: {
                visible: true,
                totalSeconds: CHUNK_TIME,
                message: LOADER_MESSAGE,
            },
            error: {
                text: null,
                level: null,
            },
            radio: {
                list: CREATE_UPDATE_LIST,
                selected: CREATE_UPDATE_LIST[0],
            },
            folder: {
                title: FOLDER_DROP_DOWN_TITLE,
                list: MODAL_INPUT_TYPE,
                selected: null,
            },
            newListName: {
                title: LIST_DROP_DOWN_TITLE,
                type: 'text',
                value: '',
            },
            listNames: {
                title: LIST_DROP_DOWN_TITLE,
                list: null,
                selected: null,
            },
            modifyBoxes: {
                list: MERGE_REPLACE_DELETE_LIST,
                selected: MERGE_REPLACE_DELETE_LIST[0],
            },
            fileUpload: {
                boxLabel: DROP_FILES_LABEL,
                buttonLabel: SELECT_FILE_LABEL,
                fileType: CSV_FILE_TYPE,
                rawFile: null,
                selected: null,
            },
            hasHeaderRow: {
                list: ADD_HEADERS_LIST,
                selected: {},
            },
            note: {
                text: REPLACING_MEMBERS_WARNING,
                level: 'warning',
            },
            subtitle: {
                text: IMPORT_CSV_MODAL_SUBTITLE,
            },
            table: {
                list: [],
                total: null,
            }
        };

        scope.validModal = false;
        scope.showUploadParams = true;
        scope.isNew = true;

        // Update the ignore row header
        scope.items.hasHeaderRow.list[0].label = `${FIRST_RADIO_LABEL}`;
        this.setLoader(scope, 10);

        return this.folderSrvc.getFolders().then((response: any) => {
            const list: FolderItem[] = this.folderSrvc.convertToFolderItems(response);
            scope.items.folder.list = list;

            if (list.length) {
                scope.items.folder.selected = list[0];
                scope.items.listNames.list = list[0].list;

                if (list[0].list.length) {
                    scope.items.listNames.selected = list[0].list[0];
                }
            }
            this.stopLoader(scope);
            return;
        }, () => {
            this.stopLoader(scope);
            throw ERROR_FOLDERS;
        });
    }

    public importChanged(scope: IScope, selected: any) {
        scope.isNew = CREATE_UPDATE_LIST[0].id === selected.id;
    }

    public folderChanged(scope: IScope, selected: FolderItem) {
        scope.items.listNames.list = selected.list;
        if (selected.list.length) {
            scope.items.listNames.selected = selected.list[0];
        }
    }

    public fileUploaded(scope: IScope, text: string): Promise<void> {
        this.setLoader(scope, 2);

        if (!text) {
            this.stopLoader(scope);
            return Promise.reject(EMPTY_FILE_ERROR);
        }

        scope.items.fileUpload.rawFile = text;
        let firstColumn: string = text.split(',')[0];
        if (firstColumn) {
            firstColumn = ` (${firstColumn.substr(0, MAX_COLUMN_SIZE)})`;
        }
        // Update the ignore row header
        scope.items.hasHeaderRow.list[0].label = `${FIRST_RADIO_LABEL}${firstColumn}`;

        this.stopLoader(scope);
        return Promise.resolve();
    }

    private validateFile(scope: IScope): Promise<void> {
        const lines: string[] = scope.items.fileUpload.rawFile.split('\n');
        if (scope.items.hasHeaderRow.selected[HEADER_CHECK]) {
            lines.splice(0,1);
        }

        const regExp = /^(?:['"`]?)([A-Za-z0-9-]{15,})(?:['"`]?)/;
        for (let line = 0; line < lines.length; line++) {
            const lineStr: string = lines[line].trim();
            if (!lineStr) {
                lines.splice(line--, 1);
                continue;
            }

            const matchGroups: any = lineStr.match(regExp);
            if (matchGroups) {
                lines[line] = matchGroups[1];
            } else {
                this.stopLoader(scope);
                return Promise.reject(INVALID_ACCOUNT_ID + lineStr);
            }
        }

        if (!lines) {
            return Promise.reject(MISSING_ACCOUNT_IDS);
        }

        scope.items.fileUpload.selected = lines;
        return Promise.resolve();
    }

    public validateFields(scope: IScope): Promise<void> {
        scope.items.error.text = null;
        if (scope.isNew &&
            !scope.items.newListName.value) {
            scope.validModal = false;
            return Promise.reject(MISSING_LIST_NAME);
        }

        if (!scope.isNew &&
            !scope.items.listNames.selected) {
            scope.validModal = false;
            return Promise.reject(MISSING_LIST_NAME);
        }


        if (!scope.items.folder.selected) {
            scope.validModal = false;
            return Promise.reject(MISSING_FOLDER);
        }

        if (!scope.items.fileUpload.rawFile) {
            scope.validModal = false;
            return Promise.reject(MISSING_ACCOUNT_IDS);
        }
        scope.validModal = true;
        return Promise.resolve();
    }

    public validate(scope: IScope): Promise<void> {
        return this.validateFields(scope).then(() => {
            return this.validateFile(scope).then(() => {
                scope.showUploadParams = false;
                return;
            });
        });
    }

    public showError(scope: IScope, message: string): void {
        scope.items.error.text = message;
        scope.items.error.level = 'error';
    }

    private accountListUpdateSuccessNote(scope: IScope, message: string): void {
        scope.items.error.text = message + LIST_UPDATED_LABEL;
        scope.items.error.level = 'success';
    }

    private addTolist(scope: IScope, listItem: ListItem): Promise<void> {
        this.setLoader(scope, (Math.round(scope.items.fileUpload.selected.length / LIST_CHUNK_SIZE) + 1)
            * CHUNK_TIME);
        return this.accountListsSrvc.addToList(
            new ListItem(listItem.id.toString(), listItem.label),
            scope.items.fileUpload.selected
        ).then((response: any) => {
            scope.items.table.list.push(new RowList(NEW_MEMBERS_LABEL, response.new_members));
            scope.items.table.list.push(new RowList(IGNORED_ACCOUNTS_LABEL, response.duplicate_members));
            scope.items.table.total = new RowList(TOTAL_ACCOUNTS_LABEL, response.total_members);
            this.accountListUpdateSuccessNote(scope, listItem.label);
            this.stopLoader(scope);
            return;
        }, (error) => {
            this.stopLoader(scope);
            throw error;
        });
    }

    public import(scope: IScope): Promise<void> {
        scope.items.table.list.length = 0;
        scope.items.table.total = null;
        this.setLoader(scope, CHUNK_TIME);

        if (scope.isNew) {
            return this.accountListsSrvc.createList(
                scope.items.folder.selected,
                scope.items.newListName.value).then((response: any) => {
                return this.csvUpdateGlobalFilters.updateAccountListsFilters().then(() => {
                    return this.addTolist(scope, new ListItem(response.id, response.name));
                });
            });
        } else {
            const operation: any = scope.items.modifyBoxes.selected;

            switch(operation.id) {
            case MERGE_OPERATION:
                return this.addTolist(scope, scope.items.listNames.selected);
            case REPLACE_OPERATION:
                return this.accountListsSrvc.removeAllFromList(
                    scope.items.listNames.selected
                ).then((response: any) => {
                    scope.items.table.list.push(new RowList(DELETED_MEMBERS_LABEL, response.removed_members));
                    return this.addTolist(scope, scope.items.listNames.selected);
                }, (error) => {
                    this.stopLoader(scope);
                    throw error;
                });
            case DELETE_OPERATION:
                this.setLoader(scope, (Math.round(scope.items.fileUpload.selected.length / LIST_CHUNK_SIZE) + 1)
                    * CHUNK_TIME);
                return this.accountListsSrvc.removeFromList(
                    scope.items.listNames.selected,
                    scope.items.fileUpload.selected
                ).then((response: any) => {
                    scope.items.table.list.push(new RowList(DELETED_MEMBERS_LABEL, response.removed_members));
                    scope.items.table.total = new RowList(TOTAL_ACCOUNTS_LABEL, response.total_members);
                    this.accountListUpdateSuccessNote(scope, scope.items.listNames.selected.label);
                    this.stopLoader(scope);
                    return;
                }, (error) => {
                    this.stopLoader(scope);
                    throw error;
                });
            default:
                this.stopLoader(scope);
                return Promise.reject(INVALID_OPERATION);
            }
        }
    }

    public isCsvButtonEnabled = () => {
        return this.$state.current.name === 'app.analyze.accounts.attribution';
    }
}
