(function () {
    'use strict';

    app.directive('horizRadio', function () {
        return {
            restrict: 'E',
            template: require('./horiz-radio.html'),
            controller: hRadioCtrl,
            controllerAs: "$ctrl",
            bindToController: true,
            scope: {
                ngModel: "=",
                ngChange: "&",
                params: "="
            },
            transclude: true
        };
    });

    hRadioCtrl.$inject = [];

    /**
     * Controller for the directive.
     */
    function hRadioCtrl() { }

})();
