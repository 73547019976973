app.controller('orgConfigCtrl', ['$scope', '$http', '$window', '$location', 'utilities', '$cookies', 'api', "$state", '_', 'noty', 'switchUserSrvc', 'SwitchUserConstants',
    function ($scope, $http, $window, $location, utilities, $cookies, api, $state, _, noty, switchUserSrvc, SwitchUserConstants) {
        $scope.cookies = $cookies;
        var loadLeadCompaniesCall;
        var loadLeadDomainsCall;
        var globalPropertyCall;
        var findOlfCountCall;
        var LoginConfigsCall;

        $scope.demo = $cookies.get('BrightFunnelDemo') ? angular.fromJson($cookies.get('BrightFunnelDemo')) : { companyName: 'Acme Inc.' };

        // eslint-disable-next-line no-unused-vars
        function hideFeaturesAndPagesInGroup() {
            for (var p in $scope.configs.page) {
                $scope.configs.page[p].show = true;
            }
            for (var f in $scope.configs.feature) {
                $scope.configs.feature[f].show = true;
            }
            angular.forEach($scope.data.featureGroups[$scope.data.selectedFeatureGroupKey], function (f) {
                $scope.configs[f.type][f.child].show = false;
            });
        }

        function refresh_organizations() {
            $http.get('admin/organizations').success(function (data) {
                $scope.orgs.organizations = data;
            });
        }

        $scope.loginAsOrg = function (loginOrg) {
            noty.growl(SwitchUserConstants.LABELS.LOGIN_AS.IN_PROGRESS, 'success');
            switchUserSrvc.loginAs({ username: "org" + loginOrg }).then(function(response) {
                if (response.data.error) {
                    noty.growl(response.data.error, 'error');
                } else {
                    noty.growl(SwitchUserConstants.LABELS.LOGIN_AS.OK, 'success');
                    switchUserSrvc.reloadAsUser(response.data, true);
                }
            }, function () {
                noty.growl(SwitchUserConstants.LABELS.LOGIN_AS.ERROR, 'error');
            });
        };

        function applyMask(tab) {
            if ((tab === "permission" || tab === "users" || tab === "organization") && window.location.href.indexOf("stage.brightfunnel") > 1) {
                $scope.data.mask[tab] = true;
            } else if ((tab === "dataCycle" || tab === "olf" || tab === "waterfall") && $scope.data.stageActive && window.location.href.indexOf("app.brightfunnel") > 1) {
                $scope.data.mask[tab] = true;
            }
        }

        $scope.clear_user_updates = function () {
            $scope.user_updates = {};
            $http.get('admin/users').success(function (data) {
                $scope.users = data;
                angular.forEach($scope.users, function (u) { u.updated_org_id = u.org_id; });
            });
        };

        $scope.addOrganization = function () {
            $http.post("admin/add_new_organization", { name: $scope.orgs.new_organization_name }).success(function () {
                refresh_organizations();
                noty.growl("Organization added successfully, marked as Inactive", 'information', true);
            });
        };

        (function init() {
            $state.current.data.loading = true;
            $scope.query = {
                orgId: 0,
                pageId: "app.campaigns.attribution",
                featureId: "ceff.model.toggle"
            };
            $scope.data = {
                featureGroups: {},
                selectedFeatureGroupKey: "",
                showEditFeatureGroup: false,
                mask: { permission: false, dataCycle: false, users: false, organization: false },
                stageActive: false,
                newAccount: "",
                ignoreType: "domain",
                ignoreOptions: ['domain', 'account', 'company'],
                domain: [],
                company: [],
                marketingRoles: [
                    { value: "", ui: "" },
                    { value: "demand generation", ui: "Demand Generation" },
                    { value: "marketing operations", ui: "Marketing Operations" },
                    { value: "field marketing", ui: "Field Marketing" },
                    { value: "sales manager", ui: "Sales Manager" },
                    { value: "executive", ui: "Executive" },
                    { value: "other marketing", ui: "Other Marketing" }
                ],
                activeOrgsOnly: true
            };

            $scope.configs = {
                page: {},
                feature: {
                    "ceff.model.toggle": { key: "ceff.model.toggle", name: "Campaign Effectiveness Model Toggle", show: true },
                    "tot.rev.predict": { key: "tot.rev.predict", name: "Total Revenue Predictions", show: true },
                    "waterfallStages": { key: "waterfallStages", name: "Waterfall Stages", show: true },
                    "disableInsights": { key: "disableInsights", name: "Insights", show: true },
                    "path2Sale.touchesByCamp": { key: "path2Sale.touchesByCamp", name: "Path To Sale Campaign Touches", show: true },
                    "dashboard beta": { key: "dashboard.beta", name: "dashboard beta", show: true }
                },
                org_influence_rules: { cio: [], lto: [] },
                defaultCampaignDateTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
                campaignDateTypes: ['Created Date', 'Last Modified Date', 'First Responded Date'],
                opptyAmountType: ['ARR', 'MRR'],
                pipePostTouchType: ['ON', 'OFF'],
                extraCampaignDateTypes: { 16: ["Last Member Status Change"] }
            };

            function reduce() {
                // eslint-disable-next-line no-unused-vars
                var m = _.flatten($scope.subnav.map(function (c) {
                    return c.children;
                }));
            }

            reduce();

            $scope.orgs = {
                new_organization_name: '',
                organization: []
            };

            angular.forEach(_.compact(_.flatten($scope.subnav.map(function (c) {
                return c.children;
            }))), function (s) {
                pushName(s);

                if (s.tabs) {
                    // eslint-disable-next-line no-unused-vars
                    angular.forEach(s.tabs, function (v, i) {
                        pushName(v);
                    });
                }

                function pushName(obj) {
                    $scope.configs.page[obj.name] = {
                        key: obj.name,
                        name: (obj.config.data.title ? obj.config.data.title : obj.config.data.staticTitle) + (obj.config.data.subtitle ? ': ' + obj.config.data.subtitle : ''),
                        show: true
                    };
                }
            });
            // adding on manually that is not in the subnav
            $scope.configs.page["app.campaign.sfdc"] = { key: "app.campaign.sfdc", name: "Campaign SFDC data", show: true };


            $http.get('admin/internal_config').success(function (data) {
                var active = [];
                data.orgs.forEach(function (item) {
                    if (item.active) {
                        active.push(item);
                    }
                });
                $scope.configs.orgs = [{ id: 0, name: "--Select an org--" }].concat(active);
                $scope.configs.orgsActive = [{ id: 0, name: "--Select an org--" }].concat(active);
                $scope.configs.orgsAll = [{ id: 0, name: "--Select an org--" }].concat(data.orgs);
                $scope.configs.influence_rules = data.influence_rules;
            });

            $scope.$on('$destroy', function () {
                if (loadLeadCompaniesCall) { loadLeadCompaniesCall.abort(); }
                if (loadLeadDomainsCall) { loadLeadDomainsCall.abort(); }
                if (findOlfCountCall) { findOlfCountCall.abort(); }
                if (globalPropertyCall) { globalPropertyCall.abort(); }
                if (LoginConfigsCall) { LoginConfigsCall.abort(); }
            });

            applyMask("organization");
            refresh_organizations();
            $scope.clear_user_updates();
            $scope.show_unassigned_users = true;
            $state.current.data.loading = false;
        })();
    }]);
