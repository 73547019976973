import {IScope} from 'angular';
import './component.less';
import {ContextSrvc} from '../../services/context.service';
import {FolderItem} from '../../services/folder-item';

export class ImportCsvModalCtrl {
    static $inject: Array<string> = ['$scope', 'csvContextSrvc'];

    public constructor(private $scope: IScope,
                       private contextSrvc: ContextSrvc) {
        this.initialize();
        return;
    }

    private ignoreFunc = () => { return; };

    public import = () => {
        this.contextSrvc.validate(this.$scope).then(() => {
            this.contextSrvc.import(this.$scope).catch(this.showError);
        }, this.showError);
    };

    public importChanged = (selected: any) => {
        this.contextSrvc.importChanged(this.$scope, selected);
        // Ignore errors
        this.contextSrvc.validateFields(this.$scope).catch(this.ignoreFunc);
    };

    public folderChanged = (selected: FolderItem) => {
        this.contextSrvc.folderChanged(this.$scope, selected);
    };

    public fileUploaded = (rawFile: any, error: string) => {
        if (error) {
            this.contextSrvc.showError(this.$scope, error);
            return;
        }
        this.contextSrvc.fileUploaded(this.$scope, rawFile).catch(this.showError);
        // Ignore errors
        this.contextSrvc.validateFields(this.$scope).catch(this.ignoreFunc);
    };

    public inputChanged = (text: string) => {
        this.$scope.items.newListName.value = text;
        this.contextSrvc.validateFields(this.$scope).catch(this.ignoreFunc);
    };

    public cancel = () => {
        this.$scope.$modal.closeModal();
    };

    public startOver = () => {
        this.contextSrvc.initialize(this.$scope).catch(
            (error: string) => {
                this.contextSrvc.showError(this.$scope, error);
            }
        );
    };

    private initialize(): void {
        this.contextSrvc.initialize(this.$scope).catch(
            (error: string) => {
                this.contextSrvc.showError(this.$scope, error);
            }
        );

        this.$scope.fn = {
            import: this.import,
            cancel: this.cancel,
            importChanged: this.importChanged,
            folderChanged: this.folderChanged,
            inputChanged: this.inputChanged,
            fileUploaded: this.fileUploaded,
            startOver: this.startOver,
        };
    }

    private showError = (error: string) => {
        if (error) {
            if (!this.$scope.$$phase) {
                this.$scope.$apply(() => {
                    this.contextSrvc.showError(this.$scope, error);
                });
            } else {
                this.contextSrvc.showError(this.$scope, error);
            }
        }
    };
}
